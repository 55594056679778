import { gql } from '@apollo/client';
import { apolloClient } from './graphql';

export enum PictureType {
  NO_TYPE,
  USER_PROFILE = 'USER_PROFILE',
  CHILD_PROFILE = 'CHILD_PROFILE',
  ITEM_PROFILE = 'ITEM_PROFILE',
  EVENT_PROFILE = 'EVENT_PROFILE',
  COMMUNITY_PROFILE = 'COMMUNITY_PROFILE',
  COMMONS_PROFILE = 'COMMONS_PROFILE',
  ECOREGION_PROFILE = 'ECOREGION_PROFILE',
  BADGE_PROFILE = 'BADGE_PROFILE',
  ATTACH_RESOURCE = 'ATTACH_RESOURCE'
}

const IMAGE_PUT = gql`
  mutation IMAGE_PUT(
    $pictureType: PictureType!
    $contentType: String!
    $extraInfo: String
    $resourceIdentifier: UUID
  ) {
    createUploadUrl(
      input: {
        contentType: $contentType
        pictureType: $pictureType
        extraInfo: $extraInfo
        resourceIdentifier: $resourceIdentifier
      }
    ) {
      uploadUrl
    }
  }
`;

const CHILD_IMAGE_PUT = gql`
  mutation CHILD_IMAGE_PUT($childId: UUID!) {
    createUploadUrl(input: { contentType: "image/jpeg", resourceIdentifier: $childId, pictureType: CHILD_PROFILE }) {
      uploadUrl
    }
  }
`;

const FORM_IMAGE_PUT = gql`
  mutation FORM_IMAGE_PUT($itemId: UUID!, $pictureType: PictureType!, $extraInfo: String, $contentType: String!) {
    createUploadUrl(
      input: {
        contentType: $contentType
        resourceIdentifier: $itemId
        extraInfo: $extraInfo
        pictureType: $pictureType
      }
    ) {
      uploadUrl
    }
  }
`;

 
export async function getUploadUrl(
  pictureType: PictureType,
  contentType: string = 'image/jpeg',
  extraInfo?: string,
  resourceId?: string,
): Promise<any> {
  const putPictureURLGql = await apolloClient.mutate({
    mutation: IMAGE_PUT,
    fetchPolicy: 'no-cache',
    variables: {
      pictureType,
      contentType,
      resourceIdentifier: resourceId,
      extraInfo
    }
  });
  const putPictureURL = (putPictureURLGql as any).data.createUploadUrl.uploadUrl;
  return putPictureURL;
}

 
export async function putFormPicture(
  itemId: string,
  pictureType: PictureType,
  contentType: string = 'image/jpeg',
  lang?: string
): Promise<any> {
  const putProfilePictureURLGql = await apolloClient.mutate({
    mutation: FORM_IMAGE_PUT,
    fetchPolicy: 'no-cache',
    variables: {
      itemId,
      pictureType,
      extraInfo: lang,
      contentType
    }
  });
  const putProfilePictureURL = (putProfilePictureURLGql as any).data.createUploadUrl.uploadUrl;
  return putProfilePictureURL;
}

 
export async function putChildProfilePicture(childId: string): Promise<any> {
  const putProfilePictureURLGql = await apolloClient.mutate({
    mutation: CHILD_IMAGE_PUT,
    fetchPolicy: 'no-cache',
    variables: {
      childId
    }
  });
  const putProfilePictureURL = (putProfilePictureURLGql as any).data.createUploadUrl.uploadUrl;
  return putProfilePictureURL;
}
