import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { UserContext } from 'contexts/user/UserContextProvider';
import { logoutMutation } from 'services/auth';
import { changeLocale, clickOnMobileMenu, setContainerClassnames } from '../../redux/actions';
import { localeOptions } from 'constants/defaultValues';
import MappingToolIcon from 'assets/icons/mapping-icons/MappingToolIcon';
import { MenuIcon, MobileMenuIcon } from 'components/svg';
import { gql, useMutation } from '@apollo/client';
import InfoModal from 'components/Modal/InfoModal';
import IntlMessages from 'helpers/IntlMessages';
import { getDirection, setDirection } from 'helpers/Utils';
import { isDev } from 'helpers/helper';
import TranslateIcon from 'mdi-react/TranslateIcon';

const UPDATE_USER_LANGUAGE = gql`
mutation updateUserLanguage(
  $id: UUID!
  $language: String!
) {
  updateUser(input: { patch: { language: $language }, id: $id }) {
    user {
      username
      language
    }
  }
}
`;

const RESEND_VERIFICATION_EMAIL = gql`
mutation MyMutation($emailId: UUID!) {
  resendEmailVerificationCode(input: {emailId: $emailId}) {
    success
  }
}`


const TopNav = ({
  history,
  containerClassnames,
  menuClickCount,
  selectedMenuHasSubItems,
  locale,
  setContainerClassnamesAction,
  clickOnMobileMenuAction,
  changeLocaleAction,
}) => {
  const { isAuthenticated, currentUser, refetchCurrentUser, unreadCount = 0 } = useContext(UserContext);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const currentLocale = locale;
  const [appLocaleOptions, setAppLocaleOptions] = useState({ id: null, name: locale, direction: null });
  const [verificationEmailResent, setVerificationEmailResent] = useState(false);
  const [verificationEmailResentError, setVerificationEmailResentError] = useState(false);
  const [updateUserLanguage] = useMutation(UPDATE_USER_LANGUAGE);
  const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL);

  const handleChangeLocale = () => {
    setShowInfoModal(true);
  };

  const handleLogout = async () => {
    try {
      await logoutMutation().then(() => {
        refetchCurrentUser();
      });
      history.push('/logout'); // first go to a non-private page, so that logout doesn't trigger a redirect to login
      window.location.href = isDev ? '/login' : '/page/about';
    } catch (e) {
      return console.error('Error on logging out:', e);
    }
  };

  const handleSettings = async () => {
    history.push('/profile/edit');
  };

  const handleProfile = () => {
    history.push('/profile');
  };

  const handleClickLogin = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/login',
      state: {
        from: history.location.pathname,
      }
    });
  };

  const handleAbout = () => {
    window.location.href = '/about';
  };

  const handleContact = () => {
    window.location.href = '/page/contact-us';
  };

  const menuButtonClick = (e, _clickCount, _conClassnames) => {
    e.preventDefault();

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);
    console.log(e, setContainerClassnamesAction, _conClassnames, selectedMenuHasSubItems);
    setContainerClassnamesAction(_clickCount + 1, _conClassnames, selectedMenuHasSubItems);
  };

  const mobileMenuButtonClick = (e, _containerClassnames) => {
    e.preventDefault();
    clickOnMobileMenuAction(_containerClassnames);
  };

  const handleConfirm = () => {
    setShowInfoModal(false);

    if (appLocaleOptions.id) {

      // for logged in user, store the language in the database
      if (isAuthenticated) {
        currentUser.id && updateUserLanguage({
          variables: {
            id: currentUser.id,
            language: appLocaleOptions.id,
          }
        }).then(() => {
          // only change the app language and save to local storage AFTER the mutation finishes,
          // otherwise the useEffect below would change it back to pre-mutation database value
          changeLocaleAction(appLocaleOptions.id);
          // reload the page, so that we are sure that all forms will be loaded/saved with correct language settings
          window.location.reload();
        });
      } else {
        // for non-logged in user
        changeLocaleAction(appLocaleOptions.id);
      }
    }

    const currentDirection = getDirection().direction;
    if (appLocaleOptions.direction) {
      if (appLocaleOptions.direction !== currentDirection) {
        setDirection(appLocaleOptions.direction);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  };

  const ChangeLanguageModalContent = () => {
    const intl = useIntl();

    return (
      <div className="text-center">
        <TranslateIcon
          size={100}
          className="mb-2 accent-color"
        />
        <div className="text-normal mb-4">
          <p>{intl.formatMessage({ id: 'nav.modal-about-change-language' }, { locale: appLocaleOptions.name?.split(' -')[0] })}</p>
          <p><b>{intl.formatMessage({ id: 'nav.modal-about-change-language-saved-changes' })}</b></p>
        </div>
      </div>
    );
  };


  /* set language */
  // if user.language is null, update the value according to currentLocale
  // if user.language is not null and the value is different from currentLocale, update currentLocale according to user.language
  useEffect(() => {
    if (currentUser?.language && currentUser?.language !== currentLocale) {
      changeLocaleAction(currentUser.language);
    } else if (currentUser?.id && !currentUser?.language) {
      updateUserLanguage({
        variables: {
          id: currentUser.id,
          language: currentLocale
        }
      });
    }
  }, [changeLocaleAction, updateUserLanguage, currentUser, currentLocale]);

  return (
    <>
      <nav className="navbar fixed-top">
        <div className="d-flex align-items-center navbar-left">
          <NavLink
            to="#"
            location={{}}
            className="menu-button d-none d-md-block"
            onClick={(e) => menuButtonClick(e, menuClickCount, containerClassnames)}
          >
            <MenuIcon />
          </NavLink>
          <NavLink
            to="#"
            location={{}}
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
            onClick={(e) => mobileMenuButtonClick(e, containerClassnames)}
          >
            <MobileMenuIcon />
          </NavLink>

          {/*  <div className="search">
          <Input
            name="searchKeyword"
            id="searchKeyword"
            placeholder={messages['menu.search']}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyPress={(e) => handleSearchInputKeyPress(e)}
          />
          <span className="search-icon" onClick={(e) => handleSearchIconClick(e)}>
            <i className="simple-icon-magnifier" />
          </span>
        </div>*/}
        </div>
        <a className="navbar-logo" href="/">
          <span className="logo d-none d-xs-block" />
          <span className="logo-mobile d-block d-xs-none" />
        </a>

        <div>


          {/* language dropdown */}
          <div className="d-inline-block mx-1 mx-lg-2">
            <UncontrolledDropdown>
              <DropdownToggle caret color="light" size="sm" className="language-button">
                <TranslateIcon className='color-theme-1' size='20' />
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                {localeOptions.map((l) => {
                  return (
                    <DropdownItem active={currentLocale === l.id} onClick={() => {
                      setAppLocaleOptions({ id: l.id, name: l.name, direction: l.direction });
                      handleChangeLocale();
                    }} key={l.id}>
                      {l.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          {/* mapping icon */}
          <Link className="d-inline-block mx-1 mx-lg-3" to='/map'>
            <MappingToolIcon size='30' id='mappingIconNavBar' />
          </Link>

          {/* login/profile manage dropdown */}
          <div className="user d-inline-block mx-1 mx-lg-2">
            {!isAuthenticated ? (
              <Link to="/login">
                <Button outline color="success" className="mr-3"
                  onClick={(e) => {
                    handleClickLogin(e);
                  }}
                >
                  <IntlMessages id="user.login-title" />
                </Button>
              </Link>
            ) : (
              <UncontrolledDropdown className="dropdown-menu-right mr-2">
                <DropdownToggle className="p-0" color="empty">
                  <span className="name mr-2">{currentUser?.name}</span>
                  <span>
                    <img
                      className='m-0'
                      alt="Profile"
                      src={currentUser?.avatarUrl ? currentUser.avatarUrl : '/assets/img/profiles/l-1.jpg'}
                    />
                    {unreadCount > 0 &&
                      <div className='badge btn-yellow badge-top-right-unread-messages'>&nbsp;
                        { /* unreadCount - we decided to only show a dot */ }
                      </div>
                    }
                  </span>
                </DropdownToggle>
                <DropdownMenu className="mt-3" right>
                  <DropdownItem onClick={() => handleProfile()}>
                    <IntlMessages id="menu.profile" />
                  </DropdownItem>
                  <DropdownItem onClick={() => history.push('/chat')}>
                    <IntlMessages id="chat" />
                    {unreadCount > 0 &&
                      <div className='badge btn-yellow badge-collapse-section'>
                        <span className='text-small'>
                          {unreadCount}
                        </span>
                      </div>
                    }
                  </DropdownItem>
                  <DropdownItem onClick={() => handleSettings()}>
                    <IntlMessages id="menu.settings" />
                  </DropdownItem>
                  <DropdownItem onClick={() => handleAbout()}>
                    <IntlMessages id="nav.about" />
                  </DropdownItem>
                  <DropdownItem onClick={() => handleContact()}>
                    <IntlMessages id="nav.contact" />
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => handleLogout()}>
                    <IntlMessages id="menu.sign-out" />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
        </div>
      </nav>
      {currentUser && !currentUser.isVerified &&
        <div className={`d-flex top-sticky-notification ${(verificationEmailResent || verificationEmailResentError) && 'disabled'}`} >
          {
            !verificationEmailResent && !verificationEmailResentError
              ?
              <div className='my-auto mx-auto' onClick={() => {
                resendVerificationEmail({
                  variables: {
                    emailId: currentUser.userEmails.nodes[0].id,
                  }
                }).then(() => {
                  setVerificationEmailResent(true);
                }).catch((error) => {
                  console.error('Failed to resend verification email: ', error);
                  setVerificationEmailResentError(true);
                })

              }}>
                <IntlMessages id='user.verify-e-mail-reminder' />
              </div>
              : verificationEmailResentError
                ?
                // error messages
                <div className='my-auto mx-auto'>
                  <IntlMessages id='general.loading-error' />
                </div>
                :
                // success message
                <div className='my-auto mx-auto'>
                  <IntlMessages id='user.verify-e-mail-resent' values={{ userEmail: currentUser.userEmails.nodes[0].email }} />
                </div>
          }

        </div>
      }
      <InfoModal
        description={ChangeLanguageModalContent()}
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

const mapStateToProps = ({ menu, settings }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
  const { locale } = settings;
  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    locale,
  };
};

TopNav.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.any,
  }),
};

export default injectIntl(
  connect(mapStateToProps, {
    setContainerClassnamesAction: setContainerClassnames,
    clickOnMobileMenuAction: clickOnMobileMenu,
    changeLocaleAction: changeLocale,
  })(TopNav)
);
