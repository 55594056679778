import React from 'react';
import IconProps from './IconProps';

const DietHealthIcon = ({ style, color = '#ee75a1', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="dietHealth" data-name="dietHealth" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <g>
          <g>
            <path d="M96.2,423.8C43.1,423.8,0,373,0,310.5V191.6c0-16.8,13.6-30.3,30.3-30.3s30.3,13.6,30.3,30.3v118.9
				c0,28.5,16.3,52.7,35.6,52.7c19.2,0,35.5-24.1,35.5-52.7V191.6c0-16.8,13.6-30.3,30.3-30.3s30.3,13.6,30.3,30.3v118.9
				C192.4,373,149.2,423.8,96.2,423.8z"/>
          </g>
          <g>
            <path d="M96.2,825.5c-16.8,0-30.3-13.6-30.3-30.3V190.8c0-16.8,13.6-30.3,30.3-30.3s30.3,13.6,30.3,30.3v604.4
				C126.5,811.9,113,825.5,96.2,825.5z"/>
          </g>
        </g>
        <g>
          <path d="M969.7,825.5c-16.8,0-30.3-13.6-30.3-30.3V526.4c-58.2-61.4-84.7-124.4-79-187.2
			c8.6-94.8,88.7-151.1,92.1-153.4l47.6-32.8v361c0,0.1,0,0.1,0,0.1v281C1000,811.9,986.4,825.5,969.7,825.5z M939.4,289.1
			c-9.3,15.8-16.8,34.8-18.7,56.1c-2.3,26,4,52.9,18.7,80.3V289.1z"/>
        </g>
        <g>
          <g>
            <path d="M513,846.9c-189.3,0-343.3-154-343.3-343.2c0-189.3,154-343.3,343.3-343.3c189.3,0,343.2,154,343.2,343.3
				C856.3,693,702.3,846.9,513,846.9z M513,221.1c-155.9,0-282.6,126.8-282.6,282.6c0,155.8,126.8,282.6,282.6,282.6
				c155.8,0,282.6-126.8,282.6-282.6C795.6,347.8,668.8,221.1,513,221.1z"/>
          </g>
          <g>
            <path d="M513,702.4L370.6,560c-21.9-21.8-33.9-50.9-33.9-81.9c0-63.9,52-115.8,115.8-115.8c21.7,0,42.4,5.9,60.5,17
				c18-11.1,38.8-17,60.5-17c63.9,0,115.8,52,115.8,115.8c0,30.9-12.1,60-33.9,81.9L513,702.4z M452.5,422.9
				c-30.4,0-55.2,24.8-55.2,55.2c0,14.7,5.7,28.6,16.1,39l99.5,99.5l99.5-99.5c10.4-10.4,16.2-24.3,16.2-39
				c0-30.4-24.8-55.2-55.2-55.2c-14.7,0-28.6,5.7-39,16.2L513,460.5l-21.4-21.4C481.2,428.6,467.3,422.9,452.5,422.9z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DietHealthIcon;
