import React from 'react';
import IconProps from './IconProps';

const NatureExplorationIcon = ({ style, color = '#009640', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="natureExplorationIcon" data-name="natureExplorationIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <path d="M506.3,361.8l-0.1-0.2c0-0.1,0-0.1,0-0.2C506.3,361.5,506.3,361.6,506.3,361.8z" />
      </g>
      <g>
        <path d="M591,657.5c-0.6-1.2-1.1-2.5-1.5-3.9C590,654.9,590.5,656.2,591,657.5z" />
        <path d="M648.5,634.6c-2.8-7.3-4.9-14.9-6.5-22.7L648.5,634.6z" />
      </g>
      <g>
        <path d="M936.7,436.3C936.6,436.3,936.6,436.2,936.7,436.3c-0.4-0.4-0.7-0.8-1.1-1.1L732.9,230.1
		c-2.6-2.6-5.4-4.6-8.6-6.1c-23.7-17.6-52.6-27.1-83-27.1C565,196.8,503,257.7,503,332.5c0,7.6,0.6,15,2.2,23.4
		c-1.7-0.1-3.4-0.1-5.1-0.1c-1.7,0-3.4,0-5.1,0.1c1.4-7.8,2.1-15.5,2.1-23.5c0-74.8-62.1-135.7-138.4-135.7
		c-30.4,0-59.3,9.5-83.1,27.2c-3.1,1.5-5.9,3.5-8.4,6.1L64.4,435.1c-0.3,0.4-0.7,0.7-1,1.1c0,0,0,0-0.1,0.1
		C24.3,473.9,0,526.2,0,584c0,114.2,94.8,207.2,211.4,207.2c101.9,0,187.2-71.1,207-165.3l27.5-96.5h0c12.8-6.6,31.2-13.2,54.1-13.2
		c22.4,0.1,41.3,6.6,54.2,13.2l27.4,96.1l7.9,27.7c0,0.1,0.1,0.2,0.1,0.3c0.5,1.3,1,2.6,1.5,3.9c0,0,0,0,0,0
		c30.3,78.1,107.5,133.7,197.6,133.7c116.5,0,211.4-93,211.4-207.2C1000,526.2,975.7,473.9,936.7,436.3z M357.9,611.9
		c-1.5,7.8-3.7,15.4-6.5,22.7c-21.1,55.8-75.9,95.8-140.1,95.8C129,730.4,62.1,664.7,62.1,584s67-146.3,149.3-146.3
		c82.3,0,149.3,65.7,149.3,146.3C360.6,593.5,359.7,602.9,357.9,611.9z M433.8,345.5l-0.6,2.1l-38.2,134
		c-36.4-62.5-105.1-104.8-183.7-104.8c-1,0-1.9,0-2.9,0l94.3-95.4c0.7-0.6,1.4-1.2,2.1-1.8c14.2-14.1,33.3-21.9,53.8-21.9
		c42.1,0,76.3,33.6,76.3,74.8C434.9,337.5,434.4,342.4,433.8,345.5z M500.3,455.4h-0.4c-12.1,0-23.4,1.2-33.9,3.2l10.6-37.3
		c6.2-2.5,14.1-4.6,23.3-4.6h0.1c9.2,0,17,2.1,23.2,4.6l10.6,37.3C523.6,456.7,512.3,455.5,500.3,455.4z M604.9,481.6l-38.5-135.2
		c-0.9-4.6-1.4-9.2-1.4-14c0-41.2,34.2-74.8,76.3-74.8c20.4,0,39.5,7.8,53.8,21.9c0.7,0.6,1.3,1.2,2,1.8l94.4,95.4c-1,0-1.9,0-2.9,0
		C710.1,376.8,641.4,419.1,604.9,481.6z M788.6,730.4c-64.2,0-119.1-39.9-140.1-95.8c-2.8-7.3-4.9-14.9-6.5-22.7
		c-1.8-9-2.7-18.3-2.7-27.9c0-80.7,67-146.3,149.3-146.3c82.3,0,149.3,65.7,149.3,146.3S870.9,730.4,788.6,730.4z"/>
      </g>
      <g>
        <path d="M357.9,611.9c-1.5,7.8-3.7,15.4-6.5,22.7L357.9,611.9z" />
      </g>
    </svg>
  );
};

export default NatureExplorationIcon;
