/* */
/* Allows to select an image, crop it, and immediately upload, or set as form value */
/* Contains following components: */
/* - CoverPictureUpload: grey square that opens Choose file dialog */
/* - ImageUpload: main component that decides if to show profile pic format / cover pic format, and includes image cropper */

import { FieldProps } from 'formik';
import CameraPlusOutlineIcon from 'mdi-react/CameraPlusOutlineIcon';
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useErrorHandler } from 'react-error-boundary';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { blobToDataURL, getScaledImg } from 'services/imageCropUpload';
import IntlMessages from '../../helpers/IntlMessages';
import { ExtraError } from '../../helpers/errors';
import { Colxx } from '../custom/CustomBootstrap';
import { ImageCropper } from './ImageCropper';

type CoverPictureUploadProp = {
  value: string;
  fileUpload: () => void;
  choosePicture: (_e: any) => void;
  fileInput: any;
  aspect: string;
}


/** Component that opens file dialog on click and continues to image cropping.
Shows grey background and + when no image yet, or a pencil + the image in background
if an image is alreadyy there */
const CoverPictureUpload = ({
  value,
  fileUpload,
  choosePicture,
  fileInput,
  aspect,
}: CoverPictureUploadProp): React.ReactElement => {
  return (
    <Row>
      <Colxx className="d-flex">
        {value ?
          <div
            className="form-img"
            style={{
              backgroundImage: `url(${value})`,
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              cursor: 'pointer',
              backgroundRepeat: 'no-repeat',
              aspectRatio: aspect,
            }}
          >
            <div className="d-none d-md-block">
              <Button className="float-right m-2 btn-yellow d-flex"
                onClick={fileUpload} size="sm">
                <div className="justify-content-center">
                  <EditOutlineIcon size={25} color='white' className="mr-1" />
                  <IntlMessages id="general.change-picture" />
                </div>
                <input type="file" ref={fileInput} accept="image/*" onChange={choosePicture} hidden={true} />
              </Button>
            </div>
            <div className="d-md-none">
              <Button className="float-right m-2 justify-content-center btn-yellow"
                onClick={fileUpload}>
                <EditOutlineIcon size={25} color='white' />
                <input type="file" ref={fileInput} accept="image/*" onChange={choosePicture} hidden={true} />
              </Button>
            </div>
          </div>
          :
          <Button className="btn-upload" onClick={fileUpload}>
            <input type="file" ref={fileInput} accept="image/*" onChange={choosePicture} hidden={true} />
            <PlusIcon size={60} color='white' />
          </Button>
        }
      </Colxx>
    </Row>
  );
};

/** Displays either profile picture (round) with change available on click, or "banner picture" upload area; lets user crop, and either uploads, or sets as form value */
const ImageUpload = ({
  value,
  pictureType,
  uploadPicture,
  className,
  form,
  field,
  aspect,
  width,
  height
}: {
  pictureType: string;
  value: string;
  uploadPicture: (_blob: Blob) => void;
  form?: FieldProps['form'];
  field?: FieldProps['field'];
  className?: string;
  aspect: number;
  width: number;
  height: number;
}) => {
  const [fileName, setFileName] = useState('');
  const [inputImg, setInputImg] = useState('');
  const [cropping, setCropping] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleError = useErrorHandler();

  // handle choosing a picture from the file dialog
  const choosePicture = (e: any) => {
    e && e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      async () => {
        if (reader.result && reader.result instanceof ArrayBuffer) {
          // first, we transform the ArrayBuffer into a Blob, so we can check the image dimensions and scale down if necessar
          const blob = new Blob([reader.result], { type: file.type });
          // scales image down in case it can't fit on the browser canvas (resolves black image issue on Safari)
          const resizedImg = await getScaledImg(blob);
          // further on, we need the image as data url (base64) -> transform it
          const dataURL = await blobToDataURL(resizedImg);
          // cropper takes the image from the inputImg state
          setInputImg(dataURL);
        }
      },
      false
    );
    if (file) {
      reader.readAsArrayBuffer(file); // read the file as an ArrayBuffer (that we transform into a blob)
      setFileName(file.name);
      setCropping(true); // open the cropper
    }
  };

  // handle confirming the cropped image: either directly upload, or set the value of the form field
  const handleSubmitImage = async (blob: Blob) => {

    if (!blob) {
      return;
    }

    // if component is not used in a form, upload the picture directly
    if (!field?.name) {
      try {
        console.log('Uploading: ', fileName); // we don't use the filename anywhere now
        await uploadPicture(blob);
      } catch (e) {
        if (e.toString().includes('verified')) {
          const err = new ExtraError((e as any).message, 'upload-verified');
          handleError(err as any);
        }
      }
    } else { // if component is used in a form, set the value of the form field; upload happens on submit
      const pictureUrl = URL.createObjectURL(blob);
      const name = field.name;
      if (name) form?.setFieldValue(name, pictureUrl);
    }
    setCropping(false); // close the cropper
  };

  // this is a hack to use a hidden <input> component (html standard component that can pick a file)
  // and click on it when the user is clicking on the non hidden Dropdown
  const fileInput = React.useRef(null);
  const fileUpload = () => {
    (fileInput as any).current.click();
  };

  /* */
  /* Render */
  /* */
  return (
    <div className={className}>
      {pictureType === 'profile' ? (

        // profile picture version
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle tag="span">
            <img
              src={value || '/assets/img/profiles/l-1.jpg'}
              className={`img-thumbnail card-img profile-image 
              ${className?.includes('social-profile-img') ? 'rounded-circle' : ''}`}
              alt="thumbnail"
              role="button"
              style={{ border: `${className?.includes('social-profile-img') ? '' : '3px solid white'}` }}
            />
          </DropdownToggle>
          <DropdownMenu className="profile-dropdown">
            <DropdownItem onClick={fileUpload}>
              <CameraPlusOutlineIcon />
              &nbsp;
              <IntlMessages id="general.click-to-update" />
              <input type="file" ref={fileInput} accept="image/*" onChange={choosePicture} hidden={true} />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        // cover picture version (big grey rectangle with + or pencil + image in background)
        <CoverPictureUpload
          value={value}
          fileUpload={fileUpload}
          choosePicture={choosePicture}
          fileInput={fileInput}
          aspect={aspect.toString()}
        />
      )}
      <ImageCropper
        showCropper={cropping}
        setShowCropper={setCropping}
        inputImg={inputImg}
        width={width}
        height={height}
        onSubmit={handleSubmitImage}
      />
    </div>
  );
};

export default ImageUpload;
