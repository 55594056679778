import React from 'react';
import IconProps from './IconProps';

const PlayIcon = ({ style, color = '#f8b133', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="PlayIcon" data-name="PlayIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <path d="M213.5,997.6c-30.3,0-58.4-18.8-68.6-48.6L3.9,542.4c-12.6-37.9,6.6-78,43.8-91.7L288,367.4l27,78.1L85.3,525
		l134.4,387.3L606.5,778l-33.6-97.4l78.1-26.9L688,760.7c6.4,17.8,5.4,37.7-3.2,55.3c-8.5,17.6-23.4,30.6-41.9,36.8L237,993.8
		C229.2,996.4,221.3,997.6,213.5,997.6z M223,922L223,922C223,922.1,223,922.1,223,922z M610,787.9c0,0.1,0.1,0.2,0.1,0.3L610,787.9
		z M616.3,774.6c-0.1,0-0.1,0-0.2,0.1L616.3,774.6z"/>
      </g>
      <g>
        <circle cx="627.5" cy="374.9" r="49.8" />
      </g>
      <g>
        <path d="M676,747.4c-14.6,0-29.4-4.4-42.1-13.6l-349-251.8c-15.6-11.2-25.9-27.9-29-47c-3.1-19.1,1.4-38.2,12.7-53.9
		L520.2,32.3c11.3-15.6,28-25.9,47-29c19.1-2.9,38.2,1.5,53.9,12.7l348.9,251.7c32.3,23.3,39.6,68.5,16.4,100.8l0,0L734.7,717.5
		C720.6,737,698.5,747.4,676,747.4z M341.6,421.1l332.1,239.6l239.6-332.2L581.2,89L341.6,421.1z M919.4,320.2L919.4,320.2
		L919.4,320.2z"/>
      </g>
      <g>
        <circle cx="653.5" cy="535.8" r="49.8" />
      </g>
      <g>
        <circle cx="627.5" cy="374.9" r="49.8" />
      </g>
      <g>
        <circle cx="601.4" cy="214" r="49.8" />
      </g>
      <g>
        <circle cx="274.9" cy="798.3" r="49.8" />
        <circle cx="383.8" cy="760.5" r="49.8" />
        <circle cx="492.7" cy="722.8" r="49.8" />
      </g>
      <g>
        <circle cx="199.4" cy="580.5" r="49.8" />
      </g>
      <g>
        <circle cx="308.3" cy="542.7" r="49.8" />
      </g>
    </svg>

  );
};

export default PlayIcon;
