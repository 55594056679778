import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Mercury = ({ style = { color: '#E04F21' }, size = 45, className, gradient }: IconProps): React.ReactElement => {
  return (
    <svg version="1.1"
      id={`mercury${gradient && '-showProgress'}`} // programmatically add -showProgress to id if gradient is passed so that the gradient can be applied even if there are more than one Mercury icon on the page
      xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" width={size} height={size} className={className} style={style}>

      <defs>
        <linearGradient id="mercuryGradient" x1='0' x2='0' y1='0' y2='100%'>
          {gradient && gradient.map((stopElement, index) => {
            return <stop key={index}
              offset={stopElement.offsetValue}
              stopColor={stopElement.stopColor || '#E04F21'}
              stopOpacity={stopElement.stopOpacity} />;
          })}
        </linearGradient>
      </defs>
      <style type="text/css">
        {/* programmatically add -showProgress to className if gradient is passed so that the gradient can be applied even if there are more than one Mercury icon on the page */}
        {`  .mercury-st0${gradient && '-showProgress'}{fill:#FFFFFF;}
            .mercury-st1${gradient && '-showProgress'}{fill:${gradient ? 'url("#mercuryGradient")' : style.color};}
            .mercury-st2${gradient && '-showProgress'}{fill:${style.color};}
        `}
      </style>
      <g>
        <g id="XMLID_1_">
          <g>
            <circle className={`mercury-st0${gradient && '-showProgress'}`} cx="508.6" cy="420.3" r="81.8" />
            <path className={`mercury-st1${gradient && '-showProgress'}`} d="M501.6,42c248.6,0,450.8,202.2,450.8,450.8S750.2,943.7,501.6,943.7S50.8,741.4,50.8,492.8S253,42,501.6,42z
				 M922.5,492.8c0-232-188.8-420.8-420.8-420.8C269.6,72,80.8,260.8,80.8,492.8s188.8,420.8,420.9,420.8
				C733.7,913.7,922.5,724.9,922.5,492.8z"/>
            <path className={`mercury-st0${gradient && '-showProgress'}`} d="M501.6,72c232,0,420.8,188.8,420.8,420.8S733.7,913.7,501.6,913.7c-232.1,0-420.9-188.8-420.9-420.8
				S269.6,72,501.6,72z M655.5,719.2c19.3,0,35-15.7,35-35c0-19.3-15.7-35-35-35H543.6v-81.1c66.9-15.8,116.8-76.1,116.8-147.8
				c0-46.9-21.4-89-55-116.8c33.6-27.9,55-69.9,55-116.8c0-19.3-15.7-35-35-35c-19.3,0-35,15.7-35,35c0,45.1-36.7,81.8-81.8,81.8h0
				c-45.1,0-81.8-36.7-81.8-81.8c0-19.3-15.7-35-35-35s-35,15.7-35,35c0,46.9,21.4,89,55,116.8c-33.6,27.9-55,69.9-55,116.8
				c0,71.7,49.9,131.9,116.8,147.8v81.1H361.7c-19.3,0-35,15.7-35,35c0,19.3,15.7,35,35,35h111.9V831c0,19.3,15.7,35,35,35
				c19.3,0,35-15.7,35-35V719.2H655.5z"/>
            <path className={`mercury-st2${gradient && '-showProgress'}`}
              d="M690.5,684.2c0,19.3-15.7,35-35,35H543.6v-70h111.9C674.9,649.2,690.5,664.9,690.5,684.2z" />
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M625.5,151.6c19.3,0,35,15.7,35,35c0,46.9-21.4,89-55,116.8c-26.3-21.8-60.1-35-96.9-35
				c45.1,0,81.8-36.7,81.8-81.8C590.4,167.3,606.1,151.6,625.5,151.6z"/>
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M605.5,303.5c33.6,27.9,55,69.9,55,116.8c0,71.7-49.9,131.9-116.8,147.8v-30.9c0-19.3-15.7-35-35-35
				c45.1,0,81.8-36.7,81.8-81.8s-36.7-81.8-81.8-81.8C545.4,338.5,579.2,325.3,605.5,303.5z"/>
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M543.6,719.2V831c0,19.3-15.7,35-35,35c-19.3,0-35-15.7-35-35V719.2H543.6z" />
            <rect x="473.6" y="649.2" className={`mercury-st2${gradient && '-showProgress'}`} width="70" height="70" />
            <path className={`mercury-st2${gradient && '-showProgress'}`}
              d="M543.6,568.1v81.1h-70v-81.1c11.2,2.7,23,4.1,35,4.1S532.4,570.8,543.6,568.1z" />
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M543.6,537.2v30.9c-11.2,2.7-23,4.1-35,4.1s-23.8-1.4-35-4.1v-30.9c0-19.3,15.7-35,35-35
				C527.9,502.1,543.6,517.8,543.6,537.2z"/>
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M508.6,268.5c36.8,0,70.6,13.2,96.9,35c-26.3,21.8-60.1,35-96.9,35s-70.6-13.2-96.9-35
				C438,281.6,471.8,268.5,508.6,268.5L508.6,268.5z"/>
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M508.6,502.1c-19.3,0-35,15.7-35,35v30.9c-66.9-15.8-116.8-76.1-116.8-147.8c0-46.9,21.4-89,55-116.8
				c26.3,21.8,60.1,35,96.9,35c-45.1,0-81.8,36.7-81.8,81.8S463.5,502.1,508.6,502.1z"/>
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M473.6,649.2v70H361.7c-19.3,0-35-15.7-35-35c0-19.3,15.7-35,35-35H473.6z" />
            <path className={`mercury-st2${gradient && '-showProgress'}`} d="M426.8,186.6c0,45.1,36.7,81.8,81.8,81.8c-36.8,0-70.6,13.2-96.9,35c-33.6-27.9-55-69.9-55-116.8
				c0-19.3,15.7-35,35-35S426.8,167.3,426.8,186.6z"/>
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Mercury;
