import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const CreativeHorse = ({ style, size = 45, className }: IconProps): React.ReactElement => {
	return (
		<svg version="1.1" id="creative_horse" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
			<style type="text/css">
				{`
            .circle-creative-horse{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-creative-horse{fill:#FBBF2D;} 
        `}
			</style>
			<circle className="circle-creative-horse" cx="16.3" cy="16.3" r="15.7" />
			<g>
				<path className="path-creative-horse" d="M27,22.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0.1-0.6,0-0.7-0.4c-0.1-0.2-0.2-0.4-0.3-0.4c-0.5-0.1-0.7-0.6-1-0.9
		c-0.6-0.6-1.2-1.2-1.8-1.8c-0.5-0.5-1-0.9-1.5-1.4c-0.2-0.2-0.5-0.2-0.7-0.3c0,0.1-0.1,0.1-0.1,0.2c0.2,0.5,0.4,0.9,0.7,1.4
		c0.6,1,0.6,1.1-0.4,1.7c-1.1,0.7-1.6,0.8-3.1,0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.1-0.1,0.3,0,0.5,0c0.2,0,0.4,0.1,0.5,0
		c0.5-0.2,1-0.4,1.6-0.7c-0.6-0.6-1.1-1-1.6-1.4c-0.3-0.3-0.6-0.6-0.9-0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-1.4,0-2.8-0.4-4.2-0.9
		c0,0.2-0.1,0.3-0.1,0.5c0,0.8-0.1,1.5-0.1,2.3c0,0.4,0.3,0.9,0.6,1.2c0.6,0.7,1.3,1.2,1.9,1.9c0.2,0.2,0.4,0.5,0.5,0.7
		c-0.6,0.3-1,0.2-1.4-0.3c-0.8-0.8-1.6-1.6-2.4-2.4c-0.3-0.2-0.4-0.4-0.4-0.8c0-0.6-0.1-1.3-0.2-2c-1.2,0.6-2.2,1-3.2,1.7
		c-0.5,0.3-0.8,0.9-1.2,1.4c-0.5,0.7-1.3,0.9-1.9,1.4c-0.3-0.8-0.1-1,0.6-1.2c0.2,0,0.3-0.1,0.4-0.2c0.5-0.6,0.9-1.3,1.3-2
		c0.2-0.4,0.5-0.7,0.9-0.8c0.9-0.3,1.1-0.5,1-1.3c0-0.2-0.1-0.4-0.1-0.6c-0.3-1.1-0.3-1.7,0.4-2.6c-0.4-0.4-1-0.4-1.6-0.1
		c-0.5,0.3-1,0.8-1.6,1.1c-1.1,0.6-2.7,0-3.1-1.2c1.2,0.7,2,0,2.8-0.6c1.3-0.9,2.3-0.9,3.5,0.1c0.3,0.3,0.6,0.4,1,0.1
		c0.9-0.5,1.9-0.3,2.9-0.2c1.4,0.1,2.8,0.1,4.1,0.1c0.1,0,0.3-0.1,0.4-0.1c-0.2-0.3-0.4-0.6-0.6-0.9c0,0,0.1,0,0.1-0.1
		c0.4,0.1,0.8,0.2,1.3,0.3c-0.1-0.4-0.1-0.8-0.2-1.1c0.8,0.5,1,0.4,1.6-0.2c-0.1-0.6-0.6-0.1-0.8-0.3c0.2-0.9,1.2-0.3,1.7-0.8
		c-0.1-0.1-0.2-0.3-0.3-0.4c0.4-0.3,1.1-0.3,1.6,0c0.4,0.2,0.7,0.1,0.9-0.3c0.1-0.2,0.3-0.4,0.6-0.6c-0.1,0.7,0.3,1,0.6,1.4
		c0.6,0.7,1.1,1.4,1.7,2.2c0,0.2,0,0.3,0,0.5c-0.4,0.7-0.6,0.8-1.3,0.3c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.5-1.5-0.4-1.9,0.5
		c-0.1,0.2-0.2,0.3-0.3,0.5c-0.4,0.7-0.6,1.4-0.3,2.2c0.1,0.3,0,0.6,0.1,0.9c0,0.1,0,0.3,0.1,0.4c0.6,1.3,1.4,2.5,2.5,3.6
		c0.7,0.6,1.4,1.3,2.1,1.9C27,22.4,27,22.5,27,22.5z"/>
			</g>
		</svg>
	);
};

export default CreativeHorse;
