import React from 'react';
import { Badge } from 'reactstrap';
import {
  AppropriateTechnologyIcon,
  EcosystemsEnvironmentalImpactIcon,
  PlayIcon,
  NatureExplorationIcon,
  CraftsmanshipDesignIcon,
  PerformingArtsIcon,
  DietHealthIcon,
  FarmingGardeningIcon,
  MentoringParentingIcon,
  MindfulnessCommunicationIcon,
  FineArtsIcon,
  OutdoorSkillsIcon,
  CriticalThinkingIcon,
  SustainableBuildingsIcon,
} from 'components/LearningPathsIcons';
import CloseIcon from 'mdi-react/CloseIcon';
import { Scalars } from 'services/graphqltypes';
import LeavesIcon from 'components/svg/LeavesIcon';

export type LearningPathTagProps = {
  learningPathName?: string;
  learningPathId: Scalars['UUID'];
  amount?: number | null;
  displayCredits?: boolean;
  /** Callback function that is executed onClick */
  onClick?: () => void;
  /** Additional classes passed to the rendered element */
  className?: string;
  deleteTag?: () => void;
  /** State of the checkmark inside */
  actionIcon?: 'CHECK' | 'UNCHECK';
  showLeavesIcon?: boolean;
  customFontSizeWithClassName?: string;
  customIconSize?: number;
}

export function getLearningPathIcon(learninPathId: string, color: string = 'white', size: number = 25): React.ReactNode {
  return {
    'f2a7ba06-96e7-44b3-93e3-083613629db3': <AppropriateTechnologyIcon size={size} color={color} />,
    '42276a34-89d7-4c84-bf08-8e0d33d364ad': <EcosystemsEnvironmentalImpactIcon size={size} color={color} />,
    '32a79bab-65e6-4d24-91bb-e19d23eff75e': <PlayIcon size={size} color={color} />,
    '1396b695-f152-43cf-8927-4e84e56979b4': <NatureExplorationIcon size={size} color={color} />,
    '02526e8c-576c-4c7d-b7ee-d1c677ff2dad': <CraftsmanshipDesignIcon size={size} color={color} />,
    '21180541-7727-4ba9-947c-14fcb7d98fa8': <PerformingArtsIcon size={size} color={color} />,
    'b0a781c9-a8c1-48fc-9042-d2ef13d0f6d1': <DietHealthIcon size={size} color={color} />,
    '61a17e9d-66b4-4f1b-97a0-0f95dabd5d3e': <FarmingGardeningIcon size={size} color={color} />,
    '42ae1763-b08b-4864-a6ed-11f1002acf23': <MentoringParentingIcon size={size} color={color} />,
    'd510edb7-1932-4d0a-b324-466e22370a8c': <MindfulnessCommunicationIcon size={size} color={color} />,
    '496c90a6-9096-4788-bbe6-fcb1eaa02636': <FineArtsIcon size={size} color={color} />,
    'cd8fdbce-581c-4571-9cdb-e6409719340e': <OutdoorSkillsIcon size={size} color={color} />,
    '3ff6ca8b-3264-4c17-a5fa-942ef4789f40': <CriticalThinkingIcon size={size} color={color} />,
    '5bb20ed8-1518-4058-b502-87136cdc62b6': <SustainableBuildingsIcon size={size} color={color} />,
    '8aa48c5a-1393-4bd6-b191-6660c1b91106': <NatureExplorationIcon size={size} color={color} />, // recycling the icon here
    '8f87868b-c47e-4f0c-b266-f49d2e3cc830': <CriticalThinkingIcon size={size} color={color} />, // recycling the icon here
    'e5a1286e-af04-4236-8f54-df78d4baf7a8': <SustainableBuildingsIcon size={size} color={color} />, // recycling the icon here
    '3f1c6998-1927-4f21-a520-92c054f1368f': <FineArtsIcon size={size} color={color} />, // recycling the icon here
  }[learninPathId];
}

export function getLearningPathIconColor(learningPathId: string): string {
  return {
    'f2a7ba06-96e7-44b3-93e3-083613629db3': '#1f71b8', // appropriate-technology
    '42276a34-89d7-4c84-bf08-8e0d33d364ad': '#06a099', // environmental-impact-ecosystem
    '32a79bab-65e6-4d24-91bb-e19d23eff75e': '#f8b133', // play
    '1396b695-f152-43cf-8927-4e84e56979b4': '#009640', // botany
    '02526e8c-576c-4c7d-b7ee-d1c677ff2dad': '#868686', // craftmanship-tinkering
    '21180541-7727-4ba9-947c-14fcb7d98fa8': '#a31b5c', // performing-arts
    'b0a781c9-a8c1-48fc-9042-d2ef13d0f6d1': '#ee75a1', // diet-health
    '61a17e9d-66b4-4f1b-97a0-0f95dabd5d3e': '#94c11f', // gardening-subsistence-farming
    '42ae1763-b08b-4864-a6ed-11f1002acf23': '#e8501d', // mentoring-parenting
    'd510edb7-1932-4d0a-b324-466e22370a8c': '#b59ac9', // mindfulness-meditation
    '496c90a6-9096-4788-bbe6-fcb1eaa02636': '#be1823', // fine-arts
    'cd8fdbce-581c-4571-9cdb-e6409719340e': '#c89d66', // outdoor-skills
    '3ff6ca8b-3264-4c17-a5fa-942ef4789f40': '#5A7D9A', // critical-thinking
    '5bb20ed8-1518-4058-b502-87136cdc62b6': '#7f4f25', // sustainable-buildings
    '8aa48c5a-1393-4bd6-b191-6660c1b91106': '#7f4f25', // zoology
    '8f87868b-c47e-4f0c-b266-f49d2e3cc830': '#86cfea', // ecological-psychology
    'e5a1286e-af04-4236-8f54-df78d4baf7a8': '#A9A9A9', // economics-ecology
    '3f1c6998-1927-4f21-a520-92c054f1368f': '#be1823', // culture-arts
  }[learningPathId];
}

export function getBadgeClassName(learninPathId: string): string {
  return {
    'f2a7ba06-96e7-44b3-93e3-083613629db3': 'badge-appropriate-technology',
    '42276a34-89d7-4c84-bf08-8e0d33d364ad': 'badge-environmental-impact-ecosystem',
    '32a79bab-65e6-4d24-91bb-e19d23eff75e': 'badge-play',
    '1396b695-f152-43cf-8927-4e84e56979b4': 'badge-botany',
    '02526e8c-576c-4c7d-b7ee-d1c677ff2dad': 'badge-craftmanship-tinkering',
    '21180541-7727-4ba9-947c-14fcb7d98fa8': 'badge-performing-arts',
    'b0a781c9-a8c1-48fc-9042-d2ef13d0f6d1': 'badge-diet-health',
    '61a17e9d-66b4-4f1b-97a0-0f95dabd5d3e': 'badge-gardening-subsistence-farming',
    '42ae1763-b08b-4864-a6ed-11f1002acf23': 'badge-mentoring-parenting',
    'd510edb7-1932-4d0a-b324-466e22370a8c': 'badge-mindfulness-meditation',
    '496c90a6-9096-4788-bbe6-fcb1eaa02636': 'badge-fine-arts',
    'cd8fdbce-581c-4571-9cdb-e6409719340e': 'badge-outdoor-skills',
    '3ff6ca8b-3264-4c17-a5fa-942ef4789f40': 'badge-critical-thinking',
    '5bb20ed8-1518-4058-b502-87136cdc62b6': 'badge-sustainable-buildings', 
    '8aa48c5a-1393-4bd6-b191-6660c1b91106': 'badge-zoology',
    '8f87868b-c47e-4f0c-b266-f49d2e3cc830': 'badge-ecological-psychology',
    'e5a1286e-af04-4236-8f54-df78d4baf7a8': 'badge-economics-ecology',
    '3f1c6998-1927-4f21-a520-92c054f1368f': 'badge-culture-arts',
  }[learninPathId];
}

const LearningPathTag = ({
  learningPathName,
  amount,
  displayCredits,
  onClick,
  className,
  learningPathId,
  deleteTag,
  showLeavesIcon,
  customFontSizeWithClassName,
  customIconSize,
}: LearningPathTagProps): React.ReactElement => {

  return (
    <Badge className={`badge-learning-path ${getBadgeClassName(learningPathId)} ${className}`} onClick={onClick} style={{ maxWidth: '100%', }}>
      <div className="d-flex justify-content-between">
        {/* Icon */}
        <div className="vertical-center">{getLearningPathIcon(learningPathId, undefined, customIconSize)}</div>
        {/* Name */}
        <div className={`mr-3 text-truncate w-100 mb-0 ${customFontSizeWithClassName}`} style={{ marginLeft: '35px' }}>
          {learningPathName}
        </div>
        {/* Impact Points & Leaves Icon*/}
        {displayCredits &&
          <div className={`font-weight-bold align-middle mb-0 ${customFontSizeWithClassName} ${showLeavesIcon && 'd-flex align-items-center'} ${learningPathName && showLeavesIcon && 'mx-md-4'} `}>
            {amount}
            {showLeavesIcon && <LeavesIcon color='white' size={customIconSize || 25} className='ml-1' />}
          </div>
        }
        {/* Delete Icon */}
        {deleteTag && <CloseIcon className="align-middle ml-1" size={18} onClick={deleteTag} />}
      </div>
    </Badge>
  );
};

export default LearningPathTag;
