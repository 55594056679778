import { useCreateChatClient } from 'stream-chat-react';
import { DefaultGenerics, Event, StreamChat, User as StreamUser } from 'stream-chat';
import { useMemo, useEffect } from 'react';
import { User } from 'services/graphqltypes';

interface StreamChatClientProps {
  currentUser: User;
  currentUserStreamToken: string;
  setStreamChatClient: (client: StreamChat<DefaultGenerics> | null) => void;
  setUnreadCount: (count: number) => void;
}

/** Extracted to a standalone component so that we can execute the useCreateChatClient hook only after we have user ID */
export const StreamChatClientComponent: React.FC<StreamChatClientProps> = ({
  currentUser,
  currentUserStreamToken,
  setStreamChatClient,
  setUnreadCount,
}) => {
  const apiKey = import.meta.env.VITE_STREAM_CHAT_API_KEY;

  const chatUser: StreamUser = useMemo(() => ({
    id: currentUser?.id,
    name: currentUser?.name,
    image: currentUser?.avatarUrl || '/assets/img/profiles/l-1.jpg',
  }), [currentUser?.id, currentUser?.name, currentUser?.avatarUrl]);

  const streamChatClient = useCreateChatClient({
    apiKey,
    tokenOrProvider: currentUserStreamToken,
    userData: chatUser,
  });

  useEffect(() => {
    if (streamChatClient) {
      setStreamChatClient(streamChatClient);

      const handleEvent = (event: Event) => {
        if (event.total_unread_count !== undefined) {
          setUnreadCount(event.total_unread_count);
        }
      };

      streamChatClient.on(handleEvent);

      // this is necessary to get proper unread count when first connecting
      streamChatClient.getUnreadCount(currentUser?.id).then((unreadCount) => {
        unreadCount.total_unread_count !== undefined && setUnreadCount(unreadCount.total_unread_count);
      });

      return () => {
        streamChatClient.off(handleEvent);
        setStreamChatClient(null);
      };
    }
  }, [streamChatClient, setStreamChatClient, setUnreadCount, currentUser?.id]);

  return null;
};
