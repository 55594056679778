// if user has avatar, show avatar, otherwise use abb. of their username.
// show mentor badge if any

import React, { CSSProperties, HTMLAttributes } from 'react';
import ThumbnailImage from 'components/custom/ThumbnailImage';
import { Community, User } from 'services/graphqltypes';
import { Link } from 'react-router-dom';
import IntlMessages from 'helpers/IntlMessages';
import ThumbnailLetters from 'components/custom/ThumbnailLetters';
import UserAvatarWithMentorBadge from 'components/User/UserAvatarWithMentorBadge';
import { getCommunityDisplayName } from 'services/community';


/* This component is used to list users (or communities) with profile pic, name, and number of IPs (or number of community members) */
const DesignByListItem = ({ user, community, currentLang, clickable = true, customStyle }: {
  user?: User;
  /** need to pass if it is designed by a community */
  community?: Community;
  /** need to pass if it is designed by a community */
  currentLang?: string;
  /** default value is true, on click will direct user to designer's profile page */
  clickable?: boolean;
  customStyle?: { nameStyle?: HTMLAttributes<CSSProperties>, impactPointStyle?: HTMLAttributes<CSSProperties> };
}): React.ReactElement => {
  const name = user ? user.name : getCommunityDisplayName(community, currentLang);
  const totalCredits = user ? user.totalCredits : community?.communityUsers.totalCount;
  const url = user ? `/user/${user?.username}` : `/community/${community?.slug}`;

  return (
    <Link to={url} style={{ pointerEvents: `${clickable ? 'auto' : 'none'}` }}>
      <div className="row no-gutters my-3" style={{ flexWrap: 'nowrap' }}>

        {user && <UserAvatarWithMentorBadge user={user} />}

        {/* Community Avatar */}
        {community &&
          <div className='col-auto align-self-center'>
            {community?.avatarUrl ? (
              <ThumbnailImage rounded small src={community?.avatarUrl} alt="Profile picture" className='mr-3' />
            ) : (
              <ThumbnailLetters rounded small text={name} className='mr-3' />
            )}
          </div>
        }

        {/* name + impact points */}
        <div className="col-7 align-self-center">
          {/* name */}
          <div className="m-0 w-100 text-truncate h5" style={customStyle?.nameStyle}>{name}</div>
          {/* impact points */}
          <div className="accent-color w-100 text-truncate" style={customStyle?.impactPointStyle}>
            {totalCredits ? totalCredits : '0'}
            <span className="ml-1">
              <IntlMessages id={user ? 'redeem.modal.impact-points' : 'library.community-members'} />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DesignByListItem;
