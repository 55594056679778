import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ThumbnailImage = ({ alt, src, className, rounded, small, style }) => {
  return (
    <img
      loading='lazy'
      style={style}
      alt={alt}
      src={src}
      className={`img-thumbnail list-thumbnail align-self-center ${className}  ${classnames({
        'rounded-circle': rounded,
        small,
      })}`}
    />
  );
};

ThumbnailImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.object,
};

export default React.memo(ThumbnailImage);
