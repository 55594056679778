import classNames from 'classnames';
import { ArrayFieldAttributes } from 'components/FormComponents/definitions';
import { FormikValues, } from 'formik'; // getIn
import IntlMessages from 'helpers/IntlMessages';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import ImageMultipleIcon from 'mdi-react/ImageMultipleIcon';
import MinusCircleOutlineIcon from 'mdi-react/MinusCircleOutlineIcon';
import React, { Fragment, SetStateAction, useRef } from 'react';
import { Card } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

interface SelectedImg {
  file: File;
  arrayHelperId?: string;
  type: string;
  /** used when loading from database */
  url?: string;
}

interface ImageDropzoneProps extends ArrayFieldAttributes {
  fieldValues: FormikValues;
  setShowImageDropzone?: React.Dispatch<SetStateAction<boolean>>;
  className?: string;
  maxImagePerRow?: 1 | 2 | 3 | 4 | 6 | 12;
  capital?: HTMLElement;
}

const ImageDropzone = ({
  arrayHelpers,
  fieldValues = [],
  setShowImageDropzone,
  className,
  maxImagePerRow = 3,
  capital
}: ImageDropzoneProps): React.ReactElement => {

  /* */
  /* opens the "Select file" dialog */
  /* */
  const dropzoneRefs = useRef();
  const openDialog = () => {
    if (dropzoneRefs.current) {
      (dropzoneRefs.current as any).open();
    }
  };

  /* */
  /* calculate bootstrap grid col size of each image based on desired max image per row */
  /* */

  const colLgSize = `col-lg-${12 / maxImagePerRow}`;


  return (
    <div className={className}>
      <Dropzone
        ref={dropzoneRefs}
        noClick noKeyboard
        onDrop={(acceptedFiles: File[]) => {
          acceptedFiles.length > 0 && acceptedFiles.forEach((file: File) => {
            arrayHelpers.push({
              arrayHelperId: uuidv4(),
              file: file,
              type: 'IMAGE',
            } as SelectedImg);
          });
        }}
        accept={['image/*']}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject }) => {
          return (
            <Card
              className={`dropzone text-center pt-1 px-2 ${classNames({
                'dropzone-active-border-color': isDragActive,
                'dropzone-accept-border-color': isDragAccept,
                'dropzone-reject-border-color': isDragReject,
              })}`}
            >
              {/* close button for dropzone */}
              {setShowImageDropzone &&
                <Card.Body className='p-0 w-100 d-flex'>
                  <div className='ml-auto' style={{ cursor: 'pointer' }} onClick={() => { setShowImageDropzone && setShowImageDropzone(false); }}>
                    <CloseCircleIcon size='20' color='grey' fillOpacity={'0.7'} />
                  </div>
                </Card.Body>
              }


              <Card.Body className='row no-gutters p-0 w-100 justify-content-start' {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                {/* gallery view of selected image  */}
                {fieldValues.map((img: SelectedImg, index: number) => {
                  return (
                    <div key={img.arrayHelperId} className={`${colLgSize} col-6`}>
                      {/* remove button */}
                      <div>
                        <MinusCircleOutlineIcon
                          size='1.8rem'
                          color='red'
                          style={{ position: 'absolute', right: '6%', top: '6%', backgroundColor: 'rgba(240, 240, 240, 0.5)', borderRadius: '50%', cursor: 'pointer' }}
                          onClick={() => { arrayHelpers.remove(index); }}
                        />
                        {/* img */}
                        <img
                          src={img.url || URL.createObjectURL(img.file)}
                          style={{ objectFit: 'cover', aspectRatio: '1/1', borderRadius: '5%', width: '100%' }}
                          className='p-1'
                          alt={`selected ${index}`}
                        />
                      </div>

                    </div>
                  );
                })}


                {/* add new image */}
                <div
                  onClick={() => openDialog()}
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  className={`${colLgSize} col-6 mx-auto d-flex flex-column align-items-center justify-content-center`}
                >
                  <div className='p-1 mt-2'>
                    <input {...getInputProps()} />
                    <div className='btn-upload d-flex justify-content-center align-items-center my-2 mx-auto'
                      style={{ height: '40px', width: '40px', borderRadius: '15%' }}>
                      <ImageMultipleIcon size={'25'} color='grey' style={{ display: 'inline-block' }} />
                    </div>
                    {capital ?
                      capital :
                      <Fragment>
                        <h5 className='mb-0'><IntlMessages id='general.add-image' /></h5>
                        <IntlMessages id='general.add-image-drag-drop' />
                      </Fragment>
                    }

                  </div>

                </div>

              </Card.Body>
            </Card>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default ImageDropzone;
