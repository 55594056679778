import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Yew = ({ style, size = 45, className }: IconProps): React.ReactElement=>{
  return (
    <svg version="1.1" id="yew" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`  
            .circle-yew{fill:#FFFFFF;stroke:#60B6AA;stroke-miterlimit:10;}
            .path-yew{fill:#60B6AA;}
        `}
      </style>
      <circle className="circle-yew" cx="16.3" cy="16.3" r="15.7" />
      <g>
        <path className="path-yew" d="M21.3,21.5c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.3,0.5,0.4c0.4-2,0.7-4.1,1.7-6c0,0,0.1,0,0.1,0
		c0.1,0.3,0.2,0.5,0.1,0.8c-0.1,0.8-0.2,1.6-0.4,2.3c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,1-0.8,1.6c0.2,0.2,0.5,0.4,0.9,0.7
		c0-0.3,0-0.6,0-0.8c0.2-0.7,0.3-1.4,0.5-2.1c0.1-0.3,0.1-0.7,0.2-1.1c0.1-0.4,0.3-0.8,0.7-1.2c0.1,0.4,0.2,0.7,0.2,1
		c0,0.3-0.1,0.6-0.2,1c-0.2,0.6-0.3,1.2-0.6,1.8c-0.2,0.4-0.4,0.8-0.7,1.1c-0.1,0.1-0.1,0.2-0.2,0.3c0.4,0.2,0.8,0.5,1.2,0.7
		c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.3,0.2-0.4,0.1c-0.6-0.3-1.1-0.6-1.7-0.9c-0.4-0.3-0.5-0.3-0.9,0.1
		c-0.2,0.2-0.5,0.5-0.8,0.6c-0.9,0.3-1.8,0.6-2.7,0.8c-0.6,0.1-0.9,0-1.3-0.3c0.3-0.3,0.7-0.5,1.2-0.7c0.8-0.2,1.5-0.4,2.3-0.6
		c0.4-0.1,0.9-0.1,1.3-0.2c0.2,0,0.3-0.1,0.5-0.1c-1.1-0.9-2.2-1.8-3.2-2.7c-1.6,1.1-3.5,1.6-5.3,2.4c-0.3,0.1-0.6,0.1-0.9,0.2
		c-0.4,0-0.8-0.1-1-0.5c0,0,0-0.1,0.1-0.1c1.1,0,2-0.7,3-1.1c0.8-0.3,1.6-0.6,2.4-0.9c0.4-0.1,0.8-0.1,1.1,0c0.1,0,0.2,0,0.4,0
		c-0.4-0.4-0.7-0.7-1-1c-0.5,0.4-1.1,0.5-1.6,0.6c-0.8,0.2-1.7,0.4-2.5,0.6c-0.9,0.2-1.8,0.3-2.7,0.4c-0.3,0-0.5-0.1-0.8-0.2
		c-0.1,0-0.2-0.2-0.3-0.3c0.7-0.2,1.3-0.3,1.9-0.4c1.1-0.2,2.1-0.5,3.2-0.7c0.9-0.2,1.7-0.3,2.7-0.1c-0.3-0.3-0.5-0.6-0.9-0.8
		c-0.1-0.1-0.3,0-0.4,0.1c-0.3,0.1-0.6,0.3-0.9,0.3c-1.6,0.2-3.1,0.5-4.7,0.7c-0.9,0.1-1.7,0-2.6-0.3c-0.2-0.1-0.3-0.1-0.5-0.2
		c0,0,0-0.1,0-0.1c0.1,0,0.2-0.1,0.3-0.1c1.6,0.2,3.2-0.4,4.7-0.6c0.8-0.1,1.7-0.2,2.5-0.3c0.3,0,0.6,0.2,0.9,0.2
		c0.1,0,0.2,0,0.4,0.1c-0.4-0.4-0.7-0.7-1-1c-0.7,0.5-1.5,0.4-2.2,0.5c-1.3,0.2-2.6,0.3-3.9,0.4c-0.6,0.1-1.2,0.1-1.7,0
		c-0.3,0-0.6-0.2-1-0.3c0,0,0-0.1,0-0.1c0.9-0.1,1.7-0.3,2.6-0.4c1.4-0.2,2.9-0.4,4.3-0.5c0.4,0,0.9,0.1,1.3,0.2c0.1,0,0.2,0,0.4,0
		c0,0,0-0.1,0-0.1c-0.3-0.3-0.7-0.7-1-1c-0.1-0.1-0.2-0.1-0.3,0c-0.4,0.3-0.9,0.5-1.4,0.5c-1.6,0.2-3.2,0.3-4.7,0.4
		c-0.6,0-1.2,0-1.8-0.1c-0.2,0-0.5-0.2-0.7-0.2c0,0,0-0.1,0-0.1c1.4-0.2,2.9-0.5,4.3-0.6c1.4-0.1,2.9-0.5,4.3,0c0,0,0-0.1,0-0.1
		c-0.2-0.2-0.5-0.5-0.7-0.7c-0.8,0.5-1.7,0.4-2.7,0.4c-1.5,0-3,0-4.5,0c-0.5,0-1.1-0.1-1.6-0.5c0.4,0,0.7-0.1,1-0.1
		C8.1,15.1,10,15,12,14.9c0.6,0,1.1,0.1,1.7,0.2c-0.2-0.2-0.4-0.4-0.6-0.6c0,0-0.2,0-0.3,0c-0.4,0.1-0.9,0.2-1.3,0.2
		c-1.5-0.1-2.9-0.3-4.4-0.5c-0.5-0.1-0.9-0.3-1.4-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c0,0,0-0.1,0-0.1c0.4,0,0.8,0,1.2,0.1
		c1.3,0.1,2.5,0.1,3.8,0.2c0.8,0.1,1.7,0.2,2.4,0.7c0,0,0.1,0,0.2,0c-0.4-0.4-0.7-0.8-1-1.1c-2.1,0.5-4,0-5.8-0.5
		c-0.4-0.1-0.7-0.3-1-0.7c2.2,0.3,4.5,0,6.6,1.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c-0.3-0.3-0.6-0.7-0.9-1
		c-0.1-0.1-0.2-0.2-0.4-0.1c-0.1,0.1-0.4,0.1-0.5,0C8.8,11.3,8,11,7.2,10.7c-0.3-0.2-0.6-0.5-0.9-0.8c1.3,0.1,2.4,0.6,3.6,1
		c-0.2-0.3-0.5-0.6-0.7-0.9C9.1,10,9.1,9.8,9.1,9.7c0.1,0,0.2,0.1,0.4,0.1C9.2,9,9,8.1,8.8,7.3C9.5,7.7,9.9,8.2,10,9
		c0.1,0.4,0.2,0.9-0.1,1.3c0,0.1,0.1,0.3,0.2,0.4c0.5,0.6,1.1,1.2,1.7,1.8c-0.1-0.8-0.3-1.5-0.4-2.2c-0.1-0.7,0-1.5,0-2.3
		c0-0.8,0-1.5,0.1-2.3c0.1,0.2,0.3,0.4,0.4,0.7c0.2,0.6,0.3,1.3,0.4,1.9c0.1,0.9,0.1,1.8,0.1,2.7c0,0.5-0.2,0.9-0.3,1.3
		c-0.1,0.2-0.1,0.3,0.1,0.4c0.3,0.3,0.6,0.6,1,1c0-0.2,0-0.3,0-0.4c-0.5-1.1-0.5-2.3-0.4-3.5c0-1.2,0.1-2.4,0.2-3.6
		c0-0.1,0-0.2,0-0.4c0,0,0.1,0,0.1,0c0.1,0.3,0.3,0.5,0.4,0.8c0.1,1,0.3,2,0.3,3c0.1,0.8,0,1.6,0.1,2.3c0,0.6,0,1.2-0.4,1.8
		c0,0.1-0.1,0.2,0,0.3c0.3,0.3,0.6,0.6,0.9,0.9c-0.5-2.9-0.2-5.7,0.1-8.5c0.1,0,0.1,0,0.2,0c0.1,0.4,0.3,0.8,0.4,1.2
		c0.1,1.3,0.1,2.6,0.1,3.9c0,0.6,0,1.2,0,1.8c0,0.6-0.1,1.2-0.4,1.8c0,0.1,0,0.3,0.1,0.3c0.2,0.2,0.4,0.4,0.6,0.7c0-0.2,0-0.4,0-0.5
		c-0.3-1-0.2-1.9,0-2.9c0.2-1.7,0.5-3.5,0.7-5.2c0-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.1,0c0.1,0.2,0.2,0.5,0.2,0.7c0,1.1,0,2.1,0,3.2
		c-0.1,1.1-0.2,2.1-0.3,3.2c-0.1,0.7-0.2,1.3-0.7,1.8c0.4,0.4,0.8,0.8,1.2,1.2c0,0,0.1,0,0.1,0c0-0.2,0-0.4-0.1-0.5
		c-0.3-0.6-0.2-1.2-0.2-1.8c0.3-2.1,0.6-4.2,0.8-6.3c0-0.1,0-0.2,0.1-0.3c0.3,0.4,0.4,0.8,0.4,1.2c0,0.8-0.1,1.6-0.1,2.3
		c-0.1,1.3-0.3,2.6-0.4,3.8c-0.1,0.5-0.1,1.1-0.5,1.5c0.3,0.3,0.7,0.7,1,1c0,0,0.1,0,0.1,0c0-0.2-0.1-0.3-0.1-0.5
		c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-1.3,0.3-2.6,0.5-3.9c0.2-1.1,0.5-2.2,0.3-3.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0.2-0.2
		c0.1,0.2,0.2,0.4,0.3,0.6c0.3,0.9,0.3,1.8,0.2,2.7c-0.2,1.5-0.4,2.9-0.6,4.4c-0.1,0.3-0.2,0.7-0.4,1c-0.1,0.3-0.2,0.4,0.1,0.6
		c0.2,0.1,0.3,0.3,0.6,0.6c0-0.2,0.1-0.3,0-0.4c-0.2-1-0.1-1.9,0.2-2.9c0.3-1.5,0.7-3,1-4.5c0-0.1,0-0.2,0.1-0.3c0,0,0.1,0,0.1-0.1
		c0.1,0.2,0.3,0.4,0.3,0.6c0.1,0.4,0.1,0.9,0,1.3c-0.1,0.9-0.3,1.7-0.5,2.6c-0.2,0.8-0.3,1.5-0.5,2.3c-0.1,0.5-0.3,0.9-0.5,1.4
		c0.3,0.3,0.7,0.6,1.1,1c0-0.2,0-0.4,0-0.5c-0.2-0.4-0.2-0.7-0.1-1.1c0.3-0.9,0.6-1.7,1-2.6c0.4-1,1-1.9,1-3c0.4,0.2,0.6,0.6,0.6,1
		c0,0.3-0.1,0.7-0.2,1c-0.7,1.6-1.3,3.2-2,4.7c-0.1,0.1-0.1,0.2-0.3,0.4C20.3,20.9,20.7,21.2,21.3,21.5
		C21.2,21.6,21.2,21.6,21.3,21.5L21.3,21.5z"/>
        <path className="path-yew" d="M21.1,22.6c-0.5,0.8-1.4,1.2-2.3,1.3c-1.1,0.2-2.1,0.4-3.2,0.5c-0.3,0-0.6-0.1-0.9-0.2c1-0.5,2-1,3.1-1.2
		C18.9,22.8,20,22.5,21.1,22.6z"/>
        <path className="path-yew" d="M21.3,21.5c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3c0.4-1.8,0.5-3.7,1.3-5.4c0,0,0-0.1,0.1-0.2
		c0.1,0.4,0.2,0.7,0.3,1c0.1,1.4-0.2,2.7-0.6,4C22.2,20.8,21.7,21.2,21.3,21.5C21.3,21.5,21.3,21.5,21.3,21.5z"/>
      </g>
    </svg>
  );
};

export default Yew;
