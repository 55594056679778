import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Jupiter = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="jupiter" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
                .jupiter-st0{fill:#FFFFFF;}
                .jupiter-st1{fill:#E14F22;}
                .jupiter-st2{fill:#E04F21;}
            `}
      </style>
      <g>
        <g id="XMLID_1_">
          <g>
            <path className="jupiter-st0" d="M499.6,94.2c223.5,0,405.4,181.9,405.4,405.4c0,223.6-181.9,405.5-405.4,405.5
				C276,905.1,94.2,723.2,94.2,499.6C94.2,276.1,276,94.2,499.6,94.2z M744.4,660.9c0-15.8-12.8-28.5-28.5-28.5h-93.1V237
				c0-15.8-12.8-28.5-28.5-28.5s-28.5,12.8-28.5,28.5v395.4H345.1l146.4-214.5c2.7-3.6,5.1-7.2,7.3-10.7l1.9-2.8
				c1.2-1.7,2.2-3.6,2.9-5.4c12.5-23.1,14.5-42.5,14.5-58.8c0-72.6-59-131.6-131.6-131.6s-131.6,59.1-131.6,131.6
				c0,37.1,15.8,72.7,43.4,97.7c5.5,4.9,12.3,7.4,19.1,7.4c7.8,0,15.5-3.2,21.2-9.4c10.6-11.7,9.7-29.7-2-40.3
				c-15.6-14.1-24.6-34.3-24.6-55.3c0-41.1,33.5-74.6,74.6-74.6S461,299,461,340.1c0,10-0.9,21-10.3,36.2l-6,8.8l-0.2,0.3
				l-177,259.4c-0.2,0.4-0.5,0.7-0.7,1.1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.4-0.4,0.7
				c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.6-0.6,1.1-0.8,1.7c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.2,0.5-0.3,0.7
				c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.2,0.7c-0.2,0.7-0.4,1.5-0.6,2.2c0,0.2-0.1,0.4-0.1,0.6
				c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.5-0.1,1-0.2,1.4c0,0.4-0.1,0.8-0.1,1.3c0,0.1,0,0.2,0,0.3c0,0.5,0,0.9,0,1.3c0,0,0,0,0,0
				c0,0.5,0,1,0,1.4c0,0,0,0,0,0c0,0.5,0.1,0.9,0.1,1.4c0,0,0,0,0,0.1c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2,0.1,0.3
				c0,0.2,0,0.3,0.1,0.5c0,0.2,0.1,0.4,0.1,0.6c0.1,0.5,0.2,0.9,0.3,1.4c0,0.3,0.1,0.5,0.2,0.8c0.1,0.5,0.3,1.1,0.5,1.6
				c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0.1,0,0.1c0.2,0.5,0.5,1,0.7,1.5
				c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0.1,0.1,0.1c0.2,0.3,0.4,0.6,0.5,0.9c0.5,0.8,1,1.6,1.6,2.4c0.2,0.3,0.5,0.6,0.8,1c0,0,0,0,0,0
				c0.3,0.3,0.6,0.7,0.9,1c0.3,0.3,0.6,0.6,0.9,0.9c0.4,0.3,0.7,0.7,1.1,1c0.3,0.3,0.6,0.5,0.9,0.8c0.3,0.2,0.6,0.5,0.9,0.7
				c0.2,0.1,0.3,0.2,0.5,0.4c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.2,0.6,0.4,0.9,0.6s0.6,0.4,0.9,0.6c0.2,0.1,0.5,0.3,0.7,0.4
				s0.5,0.2,0.7,0.4c0.2,0.1,0.3,0.2,0.5,0.2c0.3,0.2,0.7,0.3,1,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0.1,0,0.1,0.1,0.2,0.1
				c0.2,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.1,0.8,0.2,1.1,0.3c0.5,0.1,0.9,0.2,1.4,0.3c0.5,0.1,0.9,0.2,1.4,0.2
				s0.9,0.1,1.4,0.2c0,0,0.1,0,0.1,0c0.4,0,0.8,0.1,1.2,0.1c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0,1.3,0c0,0,0,0,0,0h274.6v72.8
				c0,15.8,12.8,28.5,28.5,28.5c15.8,0,28.5-12.8,28.5-28.5v-72.8h93.1C731.6,689.4,744.4,676.7,744.4,660.9z"/>
            <path className="jupiter-st1" d="M499.6,65.3C739.1,65.3,934,260.1,934,499.6S739.1,934,499.6,934S65.2,739.1,65.2,499.6
				S260.1,65.3,499.6,65.3z M905.1,499.6c0-223.6-181.9-405.4-405.4-405.4C276,94.2,94.2,276.1,94.2,499.6
				c0,223.6,181.9,405.5,405.5,405.5C723.2,905.1,905.1,723.2,905.1,499.6z"/>
            <path className="jupiter-st2"
              d="M715.9,632.4c15.8,0,28.5,12.8,28.5,28.5s-12.8,28.5-28.5,28.5h-93.1v-57.1H715.9z"/>
            <path className="jupiter-st2"
              d="M622.8,689.4v72.8c0,15.8-12.8,28.5-28.5,28.5c-15.8,0-28.5-12.8-28.5-28.5v-72.8H622.8z"/>
            <rect x="565.7" y="632.4" className="jupiter-st2" width="57.1" height="57.1"/>
            <path className="jupiter-st2"
              d="M622.8,237v395.4h-57.1V237c0-15.8,12.8-28.5,28.5-28.5S622.8,221.3,622.8,237z"/>
            <path className="jupiter-st2" d="M565.7,632.4v57.1H291.1c9.1,0,18-4.4,23.6-12.5l30.4-44.6H565.7z"/>
            <path className="jupiter-st2" d="M518.1,340.1c0,16.4-2,35.7-14.5,58.8c4.9-12.2,0.9-26.5-10.4-34.2c-13-8.9-30.8-5.5-39.6,7.5l-2.8,4.1
				c9.4-15.2,10.3-26.2,10.3-36.2c0-41.1-33.5-74.6-74.6-74.6s-74.6,33.5-74.6,74.6c0,21,9,41.2,24.6,55.3
				c11.7,10.6,12.6,28.6,2,40.3c-5.6,6.2-13.4,9.4-21.2,9.4c-6.8,0-13.7-2.4-19.1-7.4c-27.6-24.9-43.4-60.5-43.4-97.7
				c0-72.6,59.1-131.6,131.6-131.6S518.1,267.5,518.1,340.1z"/>
            <path className="jupiter-st2" d="M493.2,364.8c11.3,7.7,15.3,22,10.4,34.2c-1.5,2.7-3,5.4-4.8,8.2l-7.3,10.7c-0.1,0.1-0.2,0.3-0.3,0.5
				c-9.4,12.7-27.3,15.3-39.9,5.9c-12.3-9.1-15.1-26.2-6.7-38.8l0.2-0.3c0.2-0.3,0.4-0.6,0.6-0.8c2.1-2.8,3.9-5.4,5.4-8l2.8-4.1
				C462.4,359.2,480.2,355.9,493.2,364.8z"/>
            <path className="jupiter-st2"
              d="M503.6,399c-0.8,1.9-1.7,3.7-2.9,5.4l-1.9,2.8C500.6,404.4,502.1,401.6,503.6,399z"/>
            <path className="jupiter-st2" d="M491.5,417.9l7.3-10.7C496.6,410.7,494.2,414.2,491.5,417.9z"/>
            <path className="jupiter-st2" d="M491.5,417.9L345.1,632.4h-54c-9.8,0-18.4,4.9-23.6,12.4l177-259.4c-8.5,12.6-5.6,29.7,6.7,38.8
				c12.7,9.4,30.5,6.8,39.9-5.9C491.3,418.2,491.4,418,491.5,417.9z"/>
            <path className="jupiter-st2"
              d="M444.8,385.1l6-8.8c-1.6,2.5-3.4,5.2-5.4,8C445.1,384.6,444.9,384.9,444.8,385.1z"/>
            <path className="jupiter-st2" d="M345.1,632.4L314.7,677c-5.5,8.1-14.5,12.4-23.6,12.5c0,0,0,0,0,0c-0.4,0-0.9,0-1.3,0c-0.1,0-0.2,0-0.3,0
				c-0.4,0-0.8,0-1.2-0.1c0,0-0.1,0-0.1,0c-0.5,0-0.9-0.1-1.4-0.2s-0.9-0.2-1.4-0.2c-0.5-0.1-0.9-0.2-1.4-0.3
				c-0.4-0.1-0.8-0.2-1.1-0.3c-0.4-0.1-0.8-0.2-1.2-0.4c-0.3-0.1-0.5-0.2-0.8-0.3c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.6-0.2-0.9-0.4
				c-0.3-0.1-0.7-0.3-1-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.5-0.3-0.7-0.4c-0.3-0.2-0.6-0.4-0.9-0.6
				s-0.6-0.4-0.9-0.6c-0.3-0.2-0.5-0.4-0.8-0.5c-0.2-0.1-0.3-0.2-0.5-0.4c-0.3-0.2-0.6-0.5-0.9-0.7c-0.3-0.3-0.6-0.5-0.9-0.8
				c-0.4-0.3-0.7-0.7-1.1-1c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.6-0.7-0.9-1c0,0,0,0,0,0c-0.3-0.3-0.5-0.6-0.8-1
				c-0.6-0.8-1.1-1.6-1.6-2.4c-0.2-0.3-0.4-0.6-0.5-0.9c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.5-0.5-1-0.7-1.5
				c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.5-0.4-1.1-0.5-1.6
				c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.5-0.2-0.9-0.3-1.4c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2-0.1-0.3
				c0-0.3-0.1-0.6-0.1-0.9c0,0,0,0,0-0.1c0-0.5-0.1-0.9-0.1-1.4c0,0,0,0,0,0c0-0.5,0-1,0-1.4c0,0,0,0,0,0c0-0.5,0-0.9,0-1.3
				c0-0.1,0-0.2,0-0.3c0-0.4,0.1-0.9,0.1-1.3c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.1-0.7,0.2-1.1c0-0.2,0.1-0.4,0.1-0.6
				c0.1-0.8,0.4-1.5,0.6-2.2c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.5,0.3-0.7
				c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.6,0.5-1.1,0.8-1.7c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.4,0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.3
				c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.4,0.5-0.7,0.7-1.1c5.1-7.5,13.8-12.4,23.6-12.4H345.1z"/>
            <path className="jupiter-st2" d="M289.8,689.4c0.4,0,0.9,0,1.3,0C290.6,689.4,290.2,689.4,289.8,689.4z"/>
            <path className="jupiter-st2" d="M288.3,689.3c0.4,0,0.8,0.1,1.2,0.1C289,689.4,288.7,689.3,288.3,689.3z"/>
            <path className="jupiter-st2"
              d="M286.8,689.1c0.5,0.1,0.9,0.1,1.4,0.2C287.7,689.2,287.2,689.2,286.8,689.1z"/>
            <path className="jupiter-st2" d="M285.4,688.9c0.5,0.1,0.9,0.2,1.4,0.2C286.3,689,285.8,689,285.4,688.9z"/>
            <path className="jupiter-st2" d="M284,688.5c0.5,0.1,0.9,0.2,1.4,0.3C284.9,688.8,284.4,688.7,284,688.5z"/>
            <path className="jupiter-st2"
              d="M282.8,688.2c0.4,0.1,0.8,0.2,1.1,0.3C283.6,688.4,283.2,688.3,282.8,688.2z"/>
            <path className="jupiter-st2"
              d="M280.9,687.5c0.3,0.1,0.5,0.2,0.8,0.3C281.4,687.8,281.1,687.7,280.9,687.5z"/>
            <path className="jupiter-st2"
              d="M279.8,687.1c0.3,0.1,0.6,0.2,0.9,0.4C280.4,687.4,280.1,687.2,279.8,687.1z"/>
            <path className="jupiter-st2"
              d="M278.3,686.4c0.2,0.1,0.3,0.2,0.5,0.2C278.7,686.6,278.5,686.5,278.3,686.4z"/>
            <path className="jupiter-st2" d="M277.6,686c0.2,0.1,0.5,0.3,0.7,0.4C278.1,686.3,277.9,686.2,277.6,686z"/>
            <path className="jupiter-st2" d="M276,685.1c0.3,0.2,0.6,0.4,0.9,0.6C276.6,685.5,276.3,685.3,276,685.1z"/>
            <path className="jupiter-st2"
              d="M273.8,683.6c0.2,0.1,0.3,0.2,0.5,0.4C274.1,683.8,273.9,683.7,273.8,683.6z"/>
            <path className="jupiter-st2" d="M271.9,682c0.3,0.3,0.6,0.5,0.9,0.8C272.5,682.6,272.2,682.3,271.9,682z"/>
            <path className="jupiter-st2" d="M270,680.1c0.3,0.3,0.6,0.6,0.9,0.9C270.6,680.7,270.3,680.4,270,680.1z"/>
            <path className="jupiter-st2" d="M269.1,679.1c0.3,0.3,0.6,0.7,0.9,1C269.7,679.8,269.4,679.4,269.1,679.1z"/>
            <path className="jupiter-st2" d="M268.3,678.1c0.3,0.3,0.5,0.6,0.8,1C268.8,678.7,268.6,678.4,268.3,678.1z"/>
            <path className="jupiter-st2"
              d="M266.8,645.9c-0.1,0.2-0.2,0.4-0.4,0.6C266.6,646.3,266.7,646.1,266.8,645.9z"/>
            <path className="jupiter-st2"
              d="M266.2,674.8c0.2,0.3,0.4,0.6,0.5,0.9C266.5,675.4,266.3,675.1,266.2,674.8z"/>
            <path className="jupiter-st2" d="M266.3,646.9c-0.1,0.2-0.2,0.4-0.4,0.7C266,647.3,266.1,647.1,266.3,646.9z"/>
            <path className="jupiter-st2" d="M265.8,674c0.1,0.2,0.2,0.4,0.3,0.6C266,674.5,265.9,674.2,265.8,674z"/>
            <path className="jupiter-st2" d="M264.9,649.6c0.2-0.6,0.5-1.1,0.8-1.7C265.4,648.5,265.1,649,264.9,649.6z"/>
            <path className="jupiter-st2" d="M265,672.5c0.2,0.5,0.5,1,0.7,1.5C265.5,673.5,265.3,673,265,672.5z"/>
            <path className="jupiter-st2" d="M264.7,671.8c0.1,0.2,0.2,0.4,0.3,0.6C264.9,672.2,264.8,672,264.7,671.8z"/>
            <path className="jupiter-st2" d="M264.9,649.6c-0.1,0.2-0.2,0.4-0.2,0.6C264.7,650,264.8,649.8,264.9,649.6z"/>
            <path className="jupiter-st2"
              d="M264.7,650.2c-0.1,0.2-0.2,0.5-0.3,0.7C264.5,650.6,264.6,650.4,264.7,650.2z"/>
            <path className="jupiter-st2" d="M264.4,650.9c-0.1,0.2-0.1,0.3-0.2,0.5C264.3,651.2,264.3,651,264.4,650.9z"/>
            <path className="jupiter-st2"
              d="M264.1,670.1c0.1,0.3,0.2,0.5,0.3,0.8C264.3,670.6,264.2,670.4,264.1,670.1z"/>
            <path className="jupiter-st2"
              d="M264.2,651.3c-0.1,0.2-0.1,0.4-0.2,0.6C264.1,651.8,264.1,651.5,264.2,651.3z"/>
            <path className="jupiter-st2" d="M264,652c-0.1,0.2-0.2,0.4-0.2,0.7C263.9,652.4,263.9,652.2,264,652z"/>
            <path className="jupiter-st2" d="M264.1,670.1c-0.2-0.5-0.4-1.1-0.5-1.6C263.7,669,263.9,669.5,264.1,670.1z"/>
            <path className="jupiter-st2"
              d="M263.4,667.6c0.1,0.3,0.1,0.5,0.2,0.8C263.5,668.2,263.4,667.9,263.4,667.6z"/>
            <path className="jupiter-st2" d="M263.2,654.9c0,0.2-0.1,0.4-0.1,0.6C263.1,655.3,263.2,655.1,263.2,654.9z"/>
            <path className="jupiter-st2"
              d="M263.1,666.2c0.1,0.5,0.2,0.9,0.3,1.4C263.3,667.2,263.2,666.7,263.1,666.2z"/>
            <path className="jupiter-st2" d="M263.1,655.5c-0.1,0.4-0.1,0.7-0.2,1.1C263,656.2,263,655.8,263.1,655.5z"/>
            <path className="jupiter-st2" d="M263,665.6c0,0.2,0.1,0.4,0.1,0.6C263,666,263,665.8,263,665.6z"/>
            <path className="jupiter-st2" d="M262.9,665.1c0,0.2,0,0.3,0.1,0.5C262.9,665.5,262.9,665.3,262.9,665.1z"/>
            <path className="jupiter-st2" d="M262.9,656.6c-0.1,0.5-0.1,1-0.2,1.4C262.8,657.5,262.8,657,262.9,656.6z"/>
            <path className="jupiter-st2" d="M262.7,663.9c0,0.3,0.1,0.6,0.1,0.9C262.8,664.5,262.8,664.2,262.7,663.9z"/>
            <path className="jupiter-st2" d="M262.7,658c0,0.4-0.1,0.8-0.1,1.3C262.6,658.8,262.7,658.4,262.7,658z"/>
            <path className="jupiter-st2" d="M262.6,662.4c0,0.5,0.1,1,0.1,1.4C262.7,663.3,262.6,662.9,262.6,662.4z"/>
            <path className="jupiter-st2" d="M262.6,659.5c0,0.5,0,0.9,0,1.3C262.6,660.4,262.6,660,262.6,659.5z"/>
            <path className="jupiter-st2" d="M262.6,660.9c0,0.5,0,1,0,1.4C262.6,661.9,262.6,661.4,262.6,660.9z"/>
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Jupiter;
