import IconProps from 'components/LearningPathsIcons/IconProps';
import React from 'react';

const TreeMapIcon = ({ color, size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  const iconColor = color || '#6FBF87';
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 647.1 996.8"
      width={size}
      className={className}
    >
      <g style={{ pointerEvents: 'auto' }}> { /* important, otherwise the svg is not clickable */}
        <path style={{ fill: '#FFFFFF' }} d="M552.3,95.4C491.2,33.9,410,0,323.5,0C237.1,0,155.8,33.9,94.7,95.4C33.6,156.8,0,238.6,0,325.5
        c0,73.9,52.9,206.9,157.2,395.4c77.2,139.5,155.4,259.3,156.2,260.5l10.1,15.4l10.1-15.4c0.8-1.2,79-121,156.2-260.5
        c104.3-188.5,157.3-321.6,157.3-395.4C647.1,238.6,613.4,156.8,552.3,95.4z M323.5,24.1C488.6,24.1,623,159.3,623,325.5
        c0,150.2-246.2,543.7-299.5,627.1C270.2,869.2,24.1,475.7,24.1,325.5C24.1,159.3,158.4,24.1,323.5,24.1z"/>
        <path style={{ fill: iconColor }} d="M623,325.5c0,150.2-246.2,543.7-299.5,627.1C270.2,869.2,24.1,475.7,24.1,325.5
        c0-166.2,134.3-301.4,299.5-301.4C488.6,24.1,623,159.3,623,325.5z"/>
      </g>
      <path style={{ fill: '#FFFFFF' }} d="M168.3,458.6c-0.1,0-0.1,0-0.2,0C168.2,458.7,168.3,458.7,168.3,458.6c0.1,0,0.2,0,0.3-0.1
    C168.5,458.6,168.4,458.6,168.3,458.6z M168.3,458.6c-0.1,0-0.1,0-0.2,0C168.2,458.7,168.3,458.7,168.3,458.6c0.1,0,0.2,0,0.3-0.1
    C168.5,458.6,168.4,458.6,168.3,458.6z M323.5,72.1C188,72.1,78.1,181.9,78.1,317.5S188,562.8,323.5,562.8S568.9,453,568.9,317.5
    S459,72.1,323.5,72.1z M478.4,320.7h0.1c-0.2-0.1-0.4-0.2-0.5-0.3L478.4,320.7z M168.6,458.6c-0.1,0-0.2,0.1-0.3,0.1
    c-0.1,0-0.1,0-0.2,0c0.1,0,0.1,0,0.2,0C168.4,458.6,168.5,458.6,168.6,458.6z"/>
      <path style={{ fill: iconColor }} d="M471.7,256.2c-2.2,6.3-6.6,15.4-14.8,23.3c-0.4,0.4-0.8,0.7-1,1h0c-0.6,6.8-2.6,16.1-8.9,24.8
    C439.2,316,427.7,322,426,322c-0.2,0-1.2,0-1.7,0.7c-0.4,0.5-0.3,1.2-0.3,1.3c0,1.5-3.1,5.8-7.8,8.2c-7.3,3.8-16.1,2-18.4,0.6
    c0.6,0-0.2-0.1,0,0c-2.2-0.1-5.6,3.1-6.2,4.1c-0.3,0.5-1.1,0.8-2.4,1.1l0.1,0.6c-11.3,7-21,11.5-28,14.4c-11.9,5-19.1,6.5-28.2,13.5
    c0,0-7.3,5.6-13.6,14.2c-15.3,20.9-16,48.7-16.5,78.2c-0.6,42.3,6.4,53.3,9.8,57.9c6.1,8.3,13.7,13.2,19,16l-0.4,0.8
    c-7.6-2.5-19.9-5.4-35.1-4.4c-11.9,0.8-23.5,3-30.1,5.6h-0.3c-3.2-17.8-3.8-32.6-3.7-42.9c0.2-23.2,7.7-65,21.3-99
    c1.7-4,9.1-12.1,10.9-24.7c-0.1-0.1-0.1-0.1-0.1-0.2v0c-0.1-0.2-0.2-0.4-0.4-0.6c0.1,0.2,0.2,0.4,0.3,0.6l-2.5,0.1
    c-0.6,0.2-2.5,0.6-4.3-0.5c-0.4-0.3-2-1.2-2.5-2.9c-0.1-0.6-0.1-1.2-0.6-1.5c-0.5-0.3-1.1,0-1.3,0.1c-1.4,0.6-11.6,1.1-19.7-4
    c-8.9-5.5-12.6-16.1-11.7-18.7c0.1-0.2,0.6-1.4,0-2.4c-0.5-0.8-1.4-1-1.7-1.1c-2.4-0.7-16.1-6.2-22.8-12.4c-5.7-5.2-8-12.4-8.5-16.3
    c0,0,0,0,0,0c-0.2-1.2-0.2-2,0-2.4c0.1-0.2,0.4-1,0.1-1.6c-0.4-0.6-1.3-0.6-1.4-0.6c-2.4-0.1-14.8-5.8-18.7-8.4
    c-8.2-5.7-8.3-19.6-8.3-30c0,0-0.9-14.9,3.8-23.2c0.6-1.1,3.5-5.7,5-6.4c0.1-0.1,0.8-0.3,1.2-1c0.1-0.2,0.6-0.6,0.3-1.2
    c-5.5-14.3-0.3-22.7-0.3-22.7c-2.9-13.8,10-30.7,13.8-35.8c0,0,13.2-17.4,40.2-25.7c1.4-0.4,2.8-0.7,2.8-0.7
    c0.8,0.2,1.7,0.2,2.3-0.3c0.9-0.8,0.4-2.7,0.3-3c-0.8-3.5,4.4-22,20-34.1c12.8-9.9,26.2-10.9,31.7-11.1c15.4-0.8,26.5,4.7,30.7,7.5
    c6.4,4.2,9.2,10.4,9.3,11c0.2,0.7,0.4,1.4,0.4,1.4c0.2,0.6,0,0.9,0.2,1.1c0.3,0.4,1.1,0.1,1.3,0.1c1.8-0.6,11.5,1,16.3,3
    c6.5,2.7,9.8,8.5,13.6,15.2c0.9,1.5,1.9,3.5,2.9,5.9l1.4-0.2c2.1-1.2,6.9-3.7,12.7-2.9c8.6,1.2,15.9,8.8,17.7,19.2l-0.2,0.6
    c1.5,0.5,2.9,1.2,4.4,2c1.1,0.6,4.9,3.1,9,7.1c0.1,0.1,0.2,0.6,0.7,0.7c0.4,0.2,0.8-0.1,0.8-0.1c0.8-0.3,13.3-1.2,22.9,4.2
    c14.9,8.4,16.6,28.3,16.7,31.6c0.2,5-1.1,12.9-2.1,14c-0.1,0.1-0.7,0.7-0.5,1.2c0.1,0.3,0.5,0.4,0.6,0.4c0.6,0.2,3.8,3.5,4.6,10.6
    C476,244.2,471.7,256.2,471.7,256.2z"/>
    </svg>
  );
};

export default TreeMapIcon;
