import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ThumbnailLetters = ({ text, color, className, rounded, small, style }) => {
  let letters = '';
  if (text) {
    if (text.indexOf(' ') > -1) {
      text.split(' ').map((word) => {
        if (word.length > 1) letters += word.slice(0, 1);
        return '';
      });
    } else {
      letters += text.slice(0, 2);
    }
  }

  if (letters.length > 2) {
    letters = letters.slice(0, 2);
  }

  // if the letter has chinese letter (both simplified or traditional), only show the first letter
  if (/^[\u4e00-\u9fa5]{0,}$/.test(letters)) {
    letters = letters.slice(0, 1);
  }

  return (
    <div
      style={{ ...style, backgroundColor: color }}
      className={`align-self-center list-thumbnail-letters  ${className}  ${classnames({
        'rounded-circle': rounded,
        small,
      })}`}
      title={text}
    >
      {letters}
    </div>
  );
};

ThumbnailLetters.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.object,
};

export default React.memo(ThumbnailLetters);
