import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const SustainableCitiesCommunitiesIcon = ({ style, size = 100, className }: IconProps):
    React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="sustainableCommunities" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
           .sustainable-communities{fill - rule:evenodd;clip-rule:evenodd;fill:#F59C2C;}
           .st1{fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3-6" className="sustainable-communities" d="M13.4,1h48.2c6.8,0,12.3,5.5,12.3,12.3v48.3c0,6.8-5.5,12.3-12.3,12.3H13.4
	C6.6,74,1.1,68.5,1.1,61.6V13.3C1.1,6.5,6.6,1,13.4,1z"/>
      <g id="text6065-3-2-9-6-6-5-0-0-8-8">
        <path id="path2318" className="st1" d="M22.1,11.9c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2s0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5s-0.3-0.5-0.3-1V8.8c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1V9h-0.9V8.8c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1V12c0,0.2,0,0.4,0,0.5
		c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L22.1,11.9L22.1,11.9z"
        />
        <path id="path2320" className="st1" d="M27.4,12.8c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2C25.1,13,25,12.9,25,12.8c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4V7.3h0.9V12
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4V7.2h0.9
		v4.7c0,0.1,0,0.3,0,0.4C27.6,12.5,27.5,12.7,27.4,12.8L27.4,12.8z"/>
        <path id="path2322" className="st1" d="M29,11.9c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2s0-0.1,0-0.2s0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1V8.8c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4C31,8,31.1,8.4,31.1,8.8V9h-0.9V8.8c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2C29.1,8.5,29,8.6,29,8.8v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1V12c0,0.2,0,0.4,0,0.5
		c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2H29L29,11.9L29,11.9z"/>
        <path id="path2324" className="st1" d="M32.2,13.4V8.1h-1V7.3h2.9v0.8h-1v5.4L32.2,13.4L32.2,13.4z"/>
        <path id="path2326" className="st1" d="M33.7,13.4l1.4-6.1h0.7l1.4,6.1h-0.9L36,12.1h-1.1l-0.2,1.3H33.7z M35.1,11.3h0.8l-0.4-2.2
		L35.1,11.3z"/>
        <path id="path2328" className="st1" d="M37.7,13.4V7.3h0.9v6.1H37.7z"/>
        <path id="path2330" className="st1" d="M41.5,13.4l-1.4-3.7v3.7h-0.9V7.3h0.8l1.4,3.7V7.3h0.9v6.1H41.5z"/>
        <path id="path2332" className="st1" d="M42.4,13.4l1.4-6.1h0.7l1.4,6.1H45l-0.2-1.3h-1.1l-0.2,1.3H42.4z M43.8,11.3h0.8l-0.4-2.2
		L43.8,11.3z"/>
        <path id="path2334" className="st1" d="M47.7,7.3c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.2,0.5,0.4C49,8,49.1,8.3,49.1,8.7v0.5
		c0,0.5-0.2,0.8-0.5,1c0.4,0.2,0.5,0.5,0.5,1v0.7c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.3H47.7L47.7,7.3z
		 M48.3,9.1V8.7c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v1.7h0.5c0.2,0,0.3,0,0.4-0.2C48.2,9.5,48.3,9.3,48.3,9.1
		L48.3,9.1z M48.3,11.9v-0.7c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v2h0.5c0.2,0,0.3,0,0.4-0.2
		C48.2,12.2,48.3,12.1,48.3,11.9z"/>
        <path id="path2336" className="st1" d="M52.2,13.4h-2.6V7.3h0.9v5.3h1.7V13.4L52.2,13.4z"/>
        <path id="path2338" className="st1"
          d="M55.1,12.5v0.9h-2.7V7.3h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8L55.1,12.5z"/>
        <path id="path2340" className="st1" d="M59.1,11.9c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.1,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		s-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5V8.7c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1.1,0.4C59,8,59.1,8.3,59.1,8.8v0.3h-0.9V8.8c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2
		s0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9L59.1,11.9z"/>
        <path id="path2342" className="st1" d="M59.6,13.4V7.3h0.9v6.1H59.6z"/>
        <path id="path2344" className="st1" d="M61.9,13.4V8.1h-1V7.3h2.9v0.8h-1.1v5.4L61.9,13.4L61.9,13.4z"/>
        <path id="path2346" className="st1" d="M64.2,13.4V7.3h0.9v6.1H64.2z"/>
        <path id="path2348" className="st1"
          d="M68.2,12.5v0.9h-2.7V7.3h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8L68.2,12.5z"/>
        <path id="path2350" className="st1" d="M69.3,11.9c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2s0-0.1,0-0.2s0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1V8.8c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1V9h-0.9V8.8c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1V12c0,0.2,0,0.4,0,0.5
		c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L69.3,11.9L69.3,11.9z"
        />
        <path id="path2352" className="st1" d="M21.2,21.9l1.4-6.1h0.7l1.4,6.1h-0.9l-0.2-1.3h-1.1l-0.2,1.3H21.2z M22.5,19.8h0.8L23,17.6
		L22.5,19.8z"/>
        <path id="path2354" className="st1" d="M27.3,21.9l-1.4-3.7v3.7H25v-6.1h0.8l1.4,3.7v-3.7h0.9v6.1H27.3z"/>
        <path id="path2356" className="st1" d="M30,15.8c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		s-0.6,0.4-1.1,0.4h-1.4v-6.1H30L30,15.8z M30.6,20.4v-3.2c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.5H30
		C30.4,21.1,30.6,20.8,30.6,20.4L30.6,20.4z"/>
        <path id="path2358" className="st1" d="M35.8,20.4c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5s0-0.3,0-0.5v-3.1c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3H35v-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2s0.3,0,0.4-0.1
		c0-0.1,0.1-0.2,0.1-0.2s0-0.1,0-0.2v-0.3h0.9L35.8,20.4z"/>
        <path id="path2360" className="st1" d="M36.7,16.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		s0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C36.5,16.6,36.6,16.5,36.7,16.3
		L36.7,16.3z M38.4,16.8c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2
		v3.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2
		c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2v-3.2c0-0.1,0-0.2,0-0.2C38.4,17,38.4,16.9,38.4,16.8L38.4,16.8z"/>
        <path id="path2362" className="st1"
          d="M42.8,21.9v-3.7l-0.9,2.6h-0.4l-0.9-2.6v3.7h-0.9v-6.1h0.9l1.2,3.3l1.1-3.3h0.8v6.1H42.8z"/>
        <path id="path2364" className="st1"
          d="M47.1,21.9v-3.7l-0.9,2.6h-0.4l-0.9-2.6v3.7H44v-6.1h0.8l1.2,3.3l1.1-3.3h0.8v6.1H47.1z"/>
        <path id="path2366" className="st1" d="M51.3,21.3c-0.1,0.2-0.3,0.4-0.5,0.5C50.5,21.9,50.3,22,50,22c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.7h0.9
		v4.7c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2s0.2,0.1,0.4,0.1s0.3,0,0.4-0.2s0.1-0.3,0.1-0.4v-4.7h0.9v4.7
		c0,0.1,0,0.3,0,0.4C51.4,21,51.3,21.1,51.3,21.3L51.3,21.3z"/>
        <path id="path2368" className="st1" d="M54.2,21.9l-1.4-3.7v3.7h-0.9v-6.1h0.8l1.4,3.7v-3.7H55v6.1H54.2z"/>
        <path id="path2370" className="st1" d="M55.5,21.9v-6.1h0.9v6.1H55.5z"/>
        <path id="path2372" className="st1" d="M58,21.9v-5.4h-1v-0.8h2.9v0.8h-1.1V22L58,21.9L58,21.9z"/>
        <path id="path2374" className="st1" d="M60.2,21.9v-6.1H61v6.1H60.2z"/>
        <path id="path2376" className="st1"
          d="M64.2,21v0.9h-2.7v-6.1h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5V21H64.2L64.2,21z"/>
        <path id="path2378" className="st1" d="M65.2,20.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2s0-0.1,0-0.1v-0.1V20v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2s-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5s-0.1,0.3-0.4,0.6
		c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L65.2,20.4L65.2,20.4z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-0-9-4-2">
        <path id="path2381" className="st1"
          d="M8.4,21.8V9.3l-2.1,1.6V8.6l2.1-1.5h2.1v14.7C10.5,21.8,8.4,21.8,8.4,21.8z"/>
        <path id="path2383" className="st1"
          d="M14,21.8V9.3l-2.1,1.6V8.6L14,7.1H16v14.7C16,21.8,14,21.8,14,21.8z"/>
      </g>
      <path id="path7902-0" className="st1" d="M19.5,49.1l5.2,6.8c0.2,0.2,0.1,0.5-0.2,0.5H13.9c-0.4,0-0.4-0.3-0.2-0.5l5.2-6.8
	C19,48.8,19.3,48.8,19.5,49.1L19.5,49.1z M51.8,51.6h10.4c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4H51.8
	c-0.2,0-0.4-0.2-0.4-0.4V52C51.4,51.8,51.6,51.6,51.8,51.6z M51.8,55.1c-0.2,0-0.4,0.2-0.4,0.4v10.6c0,0.2,0.2,0.4,0.4,0.4h10.4
	c0.2,0,0.4-0.2,0.4-0.4V55.5c0-0.2-0.2-0.4-0.4-0.4H51.8z M53.3,56.7H56c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2.7
	c-0.2,0-0.4-0.2-0.4-0.4v-1.2C52.9,56.9,53.1,56.7,53.3,56.7z M58.2,56.7h2.7c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2.7
	c-0.2,0-0.4-0.2-0.4-0.4v-1.2C57.8,56.9,58,56.7,58.2,56.7z M53.3,60.3H56c0.2,0,0.4,0.2,0.4,0.4V62c0,0.2-0.2,0.4-0.4,0.4h-2.7
	c-0.2,0-0.4-0.2-0.4-0.4v-1.2C52.9,60.5,53.1,60.3,53.3,60.3z M58.2,60.3h2.7c0.2,0,0.4,0.2,0.4,0.4V62c0,0.2-0.2,0.4-0.4,0.4h-2.7
	c-0.2,0-0.4-0.2-0.4-0.4v-1.2C57.8,60.5,58,60.3,58.2,60.3z M26.7,29.7c-0.2,0-0.4,0.2-0.4,0.4v36c0,0.2,0.2,0.4,0.4,0.4h10.2
	c0.2,0,0.4-0.2,0.4-0.4v-36c0-0.2-0.2-0.4-0.4-0.4C36.9,29.7,26.7,29.7,26.7,29.7z M28.8,34.7h1.6c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C28.3,34.9,28.5,34.7,28.8,34.7z M33.5,34.7H35c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C33.1,34.9,33.3,34.7,33.5,34.7z M28.8,41h1.6c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C28.3,41.2,28.5,41,28.8,41z M33.5,41H35c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C33.1,41.2,33.3,41,33.5,41z M28.8,47.2h1.6c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C28.3,47.4,28.5,47.2,28.8,47.2z M33.5,47.2H35c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C33.1,47.4,33.3,47.2,33.5,47.2z M28.8,53.4h1.6c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C28.3,53.6,28.5,53.4,28.8,53.4z M33.5,53.4H35c0.2,0,0.4,0.2,0.4,0.4v1.9
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C33.1,53.6,33.3,53.4,33.5,53.4z M28.8,59.7h1.6c0.2,0,0.4,0.2,0.4,0.4V62
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C28.3,59.9,28.5,59.7,28.8,59.7z M33.5,59.7H35c0.2,0,0.4,0.2,0.4,0.4V62
	c0,0.2-0.2,0.4-0.4,0.4h-1.6c-0.2,0-0.4-0.2-0.4-0.4v-1.9C33.1,59.9,33.3,59.7,33.5,59.7z M39.1,50.4v15.8c0,0.2,0.2,0.4,0.4,0.4h10
	c0.2,0,0.4-0.2,0.4-0.4V37c0-0.2-0.3-0.6-0.7-0.1l-9.8,12.4C39.2,49.7,39.1,49.9,39.1,50.4L39.1,50.4z M48.5,40.5
	c0.2,0,0.2,0.4,0.2,0.6v2.7c0,0.2-0.2,0.4-0.4,0.4h-2.1c-0.2,0-0.7-0.1-0.4-0.4l2.5-3.1C48.4,40.5,48.5,40.5,48.5,40.5z M45.6,45.7
	h2.7c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2.7c-0.2,0-0.4-0.2-0.4-0.4v-1.2C45.2,45.9,45.4,45.7,45.6,45.7z M43.6,46.7
	c0.2,0,0.2,0.3,0.2,0.6v3.8c0,0.2-0.2,0.4-0.4,0.4h-2.5c-0.2,0-0.6-0.2-0.4-0.4l2.9-4.2C43.5,46.8,43.5,46.7,43.6,46.7z M45.6,49.3
	h2.7c0.2,0,0.4,0.2,0.4,0.4V51c0,0.2-0.2,0.4-0.4,0.4h-2.7c-0.2,0-0.4-0.2-0.4-0.4v-1.2C45.2,49.5,45.4,49.3,45.6,49.3z M40.8,52.9
	h2.5c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2.5c-0.2,0-0.4-0.2-0.4-0.4v-1.2C40.4,53.1,40.6,52.9,40.8,52.9z M45.6,52.9
	h2.7c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2.7c-0.2,0-0.4-0.2-0.4-0.4v-1.2C45.2,53.1,45.4,52.9,45.6,52.9z M40.8,56.7
	h2.5c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2.5c-0.2,0-0.4-0.2-0.4-0.4v-1.2C40.4,56.9,40.6,56.7,40.8,56.7z M45.6,56.7
	h2.7c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2.7c-0.2,0-0.4-0.2-0.4-0.4v-1.2C45.2,56.9,45.4,56.7,45.6,56.7z M40.8,60.3
	h2.5c0.2,0,0.4,0.2,0.4,0.4V62c0,0.2-0.2,0.4-0.4,0.4h-2.5c-0.2,0-0.4-0.2-0.4-0.4v-1.2C40.4,60.5,40.6,60.3,40.8,60.3z M45.6,60.3
	h2.7c0.2,0,0.4,0.2,0.4,0.4V62c0,0.2-0.2,0.4-0.4,0.4h-2.7c-0.2,0-0.4-0.2-0.4-0.4v-1.2C45.2,60.5,45.4,60.3,45.6,60.3z M13.8,57.4
	c-0.2,0-0.4,0.2-0.4,0.4v8.3c0,0.2,0.2,0.4,0.4,0.4h3.5v0v-4.8c0-0.4,0.3-0.7,0.7-0.7h2.3c0.4,0,0.7,0.3,0.7,0.7v4.8v0h3.3
	c0.2,0,0.4-0.2,0.4-0.4v-8.3c0-0.2-0.2-0.4-0.4-0.4H13.8z"/>
    </svg>
  );
};

export default SustainableCitiesCommunitiesIcon;
