/*                                                                                                        */
/* CommonsCard: a card used to show a single commons in the list of commons                               */
/*                                                                                                        */
import { useQuery } from '@apollo/client';
import SpecimensIcon from 'assets/icons/commonsIcon/specimens_icon.svg?react';
import CommunitiesIcon from 'assets/icons/menuIcon/communities.svg?react';
import MeIcon from 'assets/icons/menuIcon/me.svg?react';
import classNames from 'classnames';
import MultilingualText from 'components/Multilingual/MultilingualText';
import IntlMessages from 'helpers/IntlMessages';
import React, { HTMLAttributes, useMemo, } from 'react';
import { Badge, BadgeProps, Card, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EcoBackendEcoregion, ECOREGION_INFO } from 'services/bioregion';
import { ecoregionApolloClient } from 'services/graphql';
import { LifecycleStatus, Territory } from 'services/graphqltypes';

type ColorsForStatusTag = { [key in Exclude<LifecycleStatus, 'APPROVED_PUBLISHED' | 'ARCHIVED' | 'DRAFT'>]: BadgeProps['variant'] }

export const statusTagColorsForMentor: ColorsForStatusTag = {
  'NEEDS_IMPROVEMENT': 'warning',
  'UNDER_REVIEW': 'primary',
  'TO_REVIEW': 'danger',
}

export const statusTagColorsForLearner: ColorsForStatusTag = {
  'NEEDS_IMPROVEMENT': 'danger',
  'UNDER_REVIEW': 'primary',
  'TO_REVIEW': 'warning',
}

/** Renders a card that represents a single commons in a list of commons. Contains logo, name, number of specimen, communities, members, and ecoregion name. */
interface CommonsCardProps extends HTMLAttributes<HTMLElement> {
  commonsInfo: Territory, showTag?: boolean; mentorView?: boolean
}

const CommonsCard = ({ commonsInfo, showTag, mentorView, ...props }: CommonsCardProps): React.ReactElement => {

  // get teg color based on commons status and if user is mentor
  const tagColor = showTag && (mentorView ? statusTagColorsForMentor : statusTagColorsForLearner)[commonsInfo.status as Exclude<LifecycleStatus, 'APPROVED_PUBLISHED' | 'ARCHIVED' | 'DRAFT'>];
  // show different text if commons's status is 'TO_REVIEW'.
  const tagTranslationId = commonsInfo.status !== 'TO_REVIEW' ? `bioregion.specimen.${commonsInfo.status}` :
    // show 'to review' to mentor and 'waiting for review' to creator 
    `bioregion.specimen.${mentorView ? commonsInfo.status : 'waiting-for-review'}`

  // fetch ecoregion data
  const { loading: ecoregionLoading, data: ecoregionData } = useQuery(ECOREGION_INFO, {
    variables: {
      ecoregionGid: commonsInfo.bioregionGid,
    },
    client: ecoregionApolloClient,
  });
  const ecoregion: EcoBackendEcoregion = useMemo(() => {
    if (ecoregionLoading) {
      return null;
    }
    return ecoregionData?.ecoregion;
  }, [ecoregionLoading, ecoregionData]);

  return (
    <div {...props}>
      <Link
        to={`/commons/${commonsInfo.slug}`}
        style={{ pointerEvents: showTag ? 'none' : 'auto' }} // disable link if commons is not approved.
      >
        <Card className={`d-flex flex-row mb-3  ${classNames({ 'disabled-specimen': showTag && !mentorView })}`}>

          {/* commons logo */}
          <div className="position-relative">
            <img
              loading='lazy'
              alt="Commons Logo"
              src={commonsInfo.avatarUrl || '/logo512.png'}
              className="list-thumbnail responsive border-0 card-img-left"
            />
          </div>

          {/* status tag */}
          {showTag &&
            <Badge
              pill
              className={'d-flex align-items-center position-absolute badge-top-left justify-content-center'}
              style={{ height: '20px', top: '5px' }}
              variant={tagColor as BadgeProps['variant']}
            >
              <IntlMessages id={tagTranslationId} />
            </Badge>
          }
          {/* commons info */}
          <div className="d-flex flex-grow-1 min-width-zero">
            <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
              <div className="mb-1 w-40 w-sm-100 list-item-heading">
                <MultilingualText value={commonsInfo.name} />
              </div>

              <div className='d-flex flex-row w-40 w-sm-100  justify-content-start'>

                {/* No. of specimen */}
                <div className='mb-1 w-30 d-flex'>
                  <SpecimensIcon width={'1.25rem'} stroke='#60b6aa' strokeWidth='0.5px' />
                  <div className="text-muted ml-2">
                    {commonsInfo.specimen.totalCount}
                  </div>
                </div>

                {/* No. of communities */}
                <div className='mb-1 w-30 d-flex'>
                  <CommunitiesIcon fill='#60b6aa' width="1.5rem" />
                  <div className="text-muted ml-2">
                    {commonsInfo.communities.totalCount}
                  </div>
                </div>

                {/* No. of members */}
                <div className='mb-1 w-30 d-flex'>
                  <MeIcon fill='#60b6aa' width="1.25rem" />
                  <div className="text-muted ml-2">
                    {commonsInfo.usersByCurrentTerritoryId.totalCount}
                  </div>
                </div>


              </div>
              <div className="mb-1 w-40 w-sm-100 text-muted text-truncate">
                {ecoregion?.ecoName}
              </div>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

export default CommonsCard;
