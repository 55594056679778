import { gql, useMutation } from '@apollo/client';
import { Colxx } from 'components/custom/CustomBootstrap';
import IntlMessages from 'helpers/IntlMessages';
import { getCodeFromError, getFieldError } from 'helpers/errors';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($id: UUID!, $token: String!) {
    verifyEmail(input: { userEmailId: $id, token: $token }) {
      success
      query {
        currentUser {
          id
          isVerified
        }
      }
    }
  }
`;

/** Page that is shown both right after registration (we sent you a verification email) and after clicking the Verify button in that e-mail */
const Verify = (): React.ReactElement => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => { return new URLSearchParams(location.search); }, [location]);
  const verificationId = searchParams.get('id');
  const token = searchParams.get('token');

  /* */
  /* States */
  /* */
  const [state, setState] = useState<'LOADING' | 'ERROR' | 'SUCCESS'>();
  const [error, setError] = useState<Error | null>(null);

  /* */
  /* Queries and mutations */
  /* */
  const [verifyEmail] = useMutation(VERIFY_EMAIL);

  const redirectUrl = useMemo(() => {
    const redeemCode = new URLSearchParams(location.search).get('redeemCode');
    if (redeemCode) {
      return `/profile?redeemCode=${redeemCode}`;
    } else if (location.state && location.state['from']) {
      return location.state['from'];
    }
    return '/profile';
  }, [location]);

  /* */
  /* Effects */
  /* */
  useEffect(() => {
    if (verificationId && token) {
      setState('LOADING');
      verifyEmail({ variables: { id: verificationId, token } })
        .then((result: any) => {
          if (result?.data?.verifyEmail['success']) {
            setState('SUCCESS');
          } else {
            setState('ERROR');
            setError(new Error(intl.formatMessage({ id: 'login.verify-error-incorrect-token' })));
          }
        })
        .catch((e: Error) => {
          console.error(e);
          const code = getCodeFromError(e);
          const [fieldname, errMessage] = getFieldError(code);
          if (fieldname && errMessage) setError(new Error(errMessage));
          setState('ERROR');
        });
    }
  }, [verificationId, token, verifyEmail, intl]);

  /** Rendered when just arriving to /verify without any additional state -> just after registering (user can just continue) */
  const PleaseVerifyYourEmail = (): React.ReactElement => {
    return (
      <>
        <div className="text-center text-normal">
          <p><IntlMessages id="login.verify-description" /><b><IntlMessages id="login.verify-description-bold" /></b></p>
          <p><IntlMessages id="login.verify-lets-continue" /></p>
        </div>
        <Button variant="primary" type="submit" className="mt-2" onClick={() => history.push(redirectUrl)}>
          <IntlMessages id="general.continue" />
        </Button>
      </>
    );
  };

  const EmailSuccessfullyVerified = (): React.ReactElement => {
    return (
      <>
        <div className="text-center text-normal">
          <h1><IntlMessages id="login.verify-email-verified-description" /></h1>
        </div>
        <Button variant="primary" type="submit" className="mt-2" onClick={() => history.push(redirectUrl)}>
          <IntlMessages id="general.continue" />
        </Button>
      </>
    );
  };

  const ErrorVerifyingEmail = (): React.ReactElement => {
    return (
      <div className="text-center text-normal">
        <h1><IntlMessages id="login.verify-error-incorrect-token" /></h1>
        <div className="invalid-feedback">{error?.message || error}</div>
      </div>
    );
  };

  return (
    <Row className="h-100 justify-content-center align-items-center modal-dialog-centered">
      <Colxx xs="11" md="10" lg="6" xl="4">
        <Card>
          <Card.Body className="d-flex flex-column align-items-center pb-2">
            <img src='/assets/logos/ark-logo-cropped.svg'
              alt='ARK logo' width={150} height={65} className="mb-2" />
            {!verificationId &&
              <h2 className="accent-color text-uppercase font-weight-bolder text-center mt-2">
                <IntlMessages id="login.verify-title" />
              </h2>
            }
          </Card.Body>
          <img
            src="/assets/img/login/space-ark-cropped.jpeg"
            alt="Welcome on board!"
            className="mb-2"
          />
          <Card.Body className="pt-0 mt-3 text-center">
            {!verificationId && <PleaseVerifyYourEmail />}
            {state === 'LOADING' && <div className='loading' />}
            {state === 'SUCCESS' && <EmailSuccessfullyVerified />}
            {state === 'ERROR' && <ErrorVerifyingEmail />}
          </Card.Body>
        </Card>
      </Colxx>
    </Row>
  );
};

export default Verify;
