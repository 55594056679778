import { gql, useQuery } from '@apollo/client';
import { Option } from 'components/custom/CustomReactSelect';
import { getTranslatedString, getUiTranslation } from 'helpers/utils-typescript';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Territory } from 'services/graphqltypes';

export const LIST_OF_TERRITORIES = gql`
query listOfTerritoriesMinimal ($bioregionGid: Int) {
  territories(
    orderBy: NATURAL
    filter: { bioregionGid: { equalTo: $bioregionGid }, status: {equalTo: APPROVED_PUBLISHED} }
    ) {
    nodes {
      name
      id
    }
  }
}
`;

/* this hook returns a list of commons options for customReactSelect.*/
const useCommonsOptions = (bioregionGid?: number) => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const { data: commonsData, loading: commonsLoading, error: commonsError } = useQuery(LIST_OF_TERRITORIES, {
    variables: { bioregionGid } // if did't receive bioregionGid, will fetch all the commons
  });

  /* Options for Commons */
  const commonsOptions = useMemo(() => {
    const option: Option = { value: '', label: getUiTranslation('general.none', currentLang), tag: '' };
    if (commonsLoading) {
      option.label = 'Loading...';
      return [{ ...option }];
    }
    if (commonsError) {
      option.label = 'Loading failed. Check your connection and reload~';
      return [{ ...option }];
    }

    return commonsData?.territories?.nodes.map((territories: Territory) => {
      return {
        label: getTranslatedString(territories.name, currentLang),
        value: territories.id,
        tag: '',
      } as Option;
    });

  }, [currentLang, commonsData, commonsError, commonsLoading]);

  return { commonsData, commonsLoading, commonsError, commonsOptions };

};

export default useCommonsOptions;
