import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/custom/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';

 
const Footer = () => {
  return (
    <footer className="page-footer">
      <Row className="footer-content">
        <Colxx className="col align-self-center">
          <span className="mb-0 text-muted text-right px-0" style={{ 'fontSize': 'smaller' }}>
            <IntlMessages id="general.copyright" />
          </span>
        </Colxx>
        <Colxx className="col align-self-center">
          <ul className="nav justify-content-end list-inline" style={{ 'fontSize': 'smaller' }}>
            <li className="nav-item mb-0">
              <a className="btn-link text-muted px-2" href="/about">
                <IntlMessages id="footer.about" />
              </a>
            </li>
            <li className="nav-item mb-0">
              <a className="btn-link text-muted px-2" href="/page/contact-us">
                <IntlMessages id="nav.contact" />
              </a>
            </li>
          </ul>
        </Colxx>
      </Row>
    </footer>
  );
};

export default Footer;
