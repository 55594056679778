import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import { Form, Button, FormLabel, FormGroup, Row, Card } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import * as yup from 'yup';
import IntlMessages from '../../helpers/IntlMessages';
import { forgotPassword } from 'services/auth';
import { Colxx } from 'components/custom/CustomBootstrap';

const emailSchema = yup.object().shape({
  email: yup.string().email().required('Required'),
});

const Forgot = () => {
  const [successfulEmail, setSuccessfulEmail] = useState(null);

  async function handleSubmit(values, { setFieldError, setSubmitting, setFieldValue }) {
    try {
      const email = values.email;
      await forgotPassword(email);
      setSubmitting(false);
      // Success: refetch
      setSuccessfulEmail(email);
    } catch (e) {
      if (e.message) setFieldError('email', e.message);
      setFieldValue('email', '', false);
    }
  }
  return (
    <div style={{ marginTop: 70 }}>
      <div className="container">
        <Row>
          <Colxx xxs="12" md="10" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="position-relative image-side d-none d-lg-block">

              </div>
              <div className="form-side">
                <NavLink to="/" className="white d-flex justify-content-center">
                  <span className="logo-single" />
                </NavLink>
                <Card.Title className="mb-4">
                  <IntlMessages id="user.reset-password" />
                </Card.Title>
                <div className="container">
                  {successfulEmail && (
                    <div className="alert alert-info fade show" role="alert">
                      <div className="row align-items-center">
                        <div className="col-lg-7 col-sm-12 h6">
                          <IntlMessages id="user.forgot-email-sent" />
                        </div>
                        <div className="col-lg-3  col-sm-12 d-flex justify-content-lg-end justify-content-center mt-lg-0 mt-1">
                          <Link to="/login">
                            <Button size="sm" className="">
                              <IntlMessages id="user.login-title" />
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={emailSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="form-group has-float-label">
                        <FormLabel>
                          <IntlMessages id="user.email" />
                        </FormLabel>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter e-mail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={errors.email ? 'form-control is-invalid' : 'form-control'}
                        />
                        {touched.email && errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                      </FormGroup>
                      <div className="submit-container">
                        <div className="mb-3">
                          <Button block variant="primary" type="submit" disabled={isSubmitting}>
                            <span className="label">
                              <IntlMessages id="user.reset-password" />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </Colxx>
        </Row>
      </div>
    </div>
  );
};

export default Forgot;
