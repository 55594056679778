import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const BraveBear = ({ style, size = 45, className }: IconProps): React.ReactElement => {
	return (
		<svg version="1.1" id="brave_bear" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
			<style type="text/css">
				{`
            .circle-brave-bear{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-brave-bear{fill:#FBBF2D;}
        `}
			</style>
			<circle className="circle-brave-bear" cx="16.3" cy="16.3" r="15.7" />
			<path className="path-brave-bear" d="M13.3,23.6c0.6,0,1.3,0,1.9,0c0.1,0,0.1,0,0.2-0.1c0.7-0.1,1.2-0.3,1.6-0.9c0.3-0.5,0.5-1,0.6-1.5
	c0.2-0.8,0.4-1.6,0.6-2.5c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.3,0.1,0.7,0.1,1c0.1,0.6,0.1,1.2,0.1,1.8
	c0,0.2,0,0.4-0.3,0.4c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.3-0.2,0.5c0,0.1,0.3,0.3,0.4,0.3c0.8,0,1.7,0,2.5,0c0.6,0,0.9-0.3,1-1
	c0.1-0.7,0.3-1.5,0.7-2.1c0.1-0.1,0.1-0.2,0.2-0.3c0.6,0.8,1.2,1.5,2,2c0.1,0,0.1,0.1,0.2,0.2c0.3,0.2,0.2,0.5-0.1,0.6
	c-0.1,0.1-0.3,0.1-0.5,0.1c-0.4,0-0.6,0.2-0.7,0.5c-0.1,0.2,0,0.3,0.1,0.4c0.8,0.3,1.6,0.4,2.3-0.2c0.5-0.4,1-0.7,1.3-1.3
	c0-0.2,0-0.5,0-0.7c-0.1-0.4-0.2-0.7-0.4-1.1c-0.5-1.1-0.7-2.3-0.5-3.5c0.1-0.7,0.1-1.5-0.1-2.3c-0.3-1.8-1.4-3-2.9-4
	c-0.8-0.5-1.6-0.9-2.6-0.9c-1.3,0-2.6,0.1-3.9,0.2c-0.4,0-0.7,0.1-1.1,0c-0.4-0.1-0.8-0.3-1.3-0.5c-0.3-0.1-0.7-0.3-1-0.3
	c-0.7-0.1-1.2,0.4-1.7,0.8c-0.1,0.1-0.3,0.2-0.5,0.3c-0.4,0.1-0.9,0.1-1.3,0.3c-0.6,0.2-1.1,0.3-1.7,0.3c-0.2,0-0.3,0-0.4-0.2
	c-0.2-0.3-0.5-0.2-0.6,0.2c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3-0.2,0.4c-0.6,0.5-0.8,1.3-0.7,2.1c0.1,0.4,0,0.6-0.3,0.8
	c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2,0.5,1,0.7,1c0.5,0.1,1.1,0.1,1.6,0.2c0.3,0,0.4,0.2,0.3,0.4c-0.1,0.3-0.3,0.6-0.5,0.8
	c-0.7,0.7-1.1,1.5-1.6,2.2C6.1,19.5,6,19.7,6,19.8c-0.1,0.4-0.1,0.9-0.2,1.3c-0.2,0.5,0,1,0.2,1.5c0.1,0.2,0.3,0.3,0.5,0.3
	c0.6,0,1-0.4,0.8-1C7,21.2,7.4,20.7,8,20.6c0.6-0.1,1.2-0.3,1.7-0.6c0.6-0.3,1.1-0.7,1.4-1.4c0.3-0.6,0.8-0.9,1.4-1.1
	c0.1,0,0.2,0,0.3,0.1c0.2,0.2,0.4,0.4,0.6,0.5c0.3,0.3,0.7,0.6,0.9,0.9c0.5,0.9,0.7,2,0.5,3c0,0.3-0.2,0.5-0.6,0.5
	c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.4,0.3-0.5,0.6C12.9,23.5,13.1,23.5,13.3,23.6z"/>
		</svg>
	);
};

export default BraveBear;
