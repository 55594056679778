import React from 'react';
import IconProps from './IconProps';

const FarmingGardeningIcon = ({ style, color = '#94c11f', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="FarmingGardeningIcon" data-name="FarmingGardeningIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <path d="M841.8,421.6c17.6,0,35-4.6,50.7-13.5c23.8-13.6,40.9-35.6,48.1-62.1l55.7-203.8c7.2-26.4,3.7-54.1-9.9-77.9
	s-35.6-40.9-62.1-48.1l-11-3C858.8-1.7,802.2,30.5,787.3,85.1l-55.7,203.8c-12.6,46.1,8.7,93.4,48.6,116L751.4,510l-173.5-47.4
	l-76.2,278.7c-7.1,26.1-1.6,54.5,14.7,75.9l84.6,111.6H282.9V674c5.9,0.5,11.7,1.1,17.6,1.1c50.7,0,100.3-19.9,136.8-56.4
	c48.5-48.5,67.7-120,50-186.6l-4.6-17.3l-17.3-4.6c-66.7-17.8-138,1.4-186.6,49.9c-28.8,28.7-46.9,65.6-53.4,104.6
	c-48.5-48.3-119.7-67.3-186.3-49.7l-17.3,4.6L17.2,537c-17.8,66.6,1.4,138.1,49.9,186.6c36.5,36.5,86.1,56.4,136.8,56.4
	c5.7,0,11.5-0.6,17.2-1.1v149.8H30.9C13.8,928.7,0,942.6,0,959.6s13.8,30.9,30.9,30.9h926.3c17.1,0,30.9-13.8,30.9-30.9
	s-13.8-30.9-30.9-30.9H811.9l45.2-18.5h0c25-10.2,44.1-31.9,51.3-57.9l76.2-278.6L811,526.3l28.6-104.8
	C840.4,421.5,841.1,421.6,841.8,421.6z M322.4,503.8c28.6-28.6,68.7-42.3,108.6-37.5c4.7,39.7-8.9,80.1-37.5,108.6
	c-28.6,28.6-68.8,42.3-108.6,37.5C280.3,572.7,293.8,532.4,322.4,503.8z M219.4,717.4c-39.7,4.9-80.1-8.8-108.6-37.4
	C82.2,651.4,68.6,611,73.3,571.3c39.5-4.5,80.1,8.9,108.6,37.5S224.1,677.6,219.4,717.4z M908.7,617l-59.9,219
	c-2.1,7.8-7.6,14-15.1,17l-143.8,58.8c-10.7,4.4-23.2,1-30.2-8.3l-93.9-123.8c-4.9-6.4-6.5-14.6-4.3-22.3l59.9-219l113.9,31.1
	l-45.8,167.6c-4.5,16.5,5.2,33.4,21.7,37.9c2.7,0.7,5.5,1.1,8.2,1.1c13.6,0,26-9,29.8-22.7l45.8-167.6L908.7,617z M791.2,305.2
	l55.7-203.8c5-18.2,21.5-30.1,39.4-30.1c3.6,0,7.1,0.5,10.7,1.4l11,3c10.6,2.9,19.3,9.7,24.7,19.1c5.4,9.5,6.8,20.5,3.9,31.1
	L881,329.8c-2.9,10.5-9.7,19.3-19.1,24.7c-9.5,5.4-20.5,6.9-31.1,3.9l-11-3C798.1,349.5,785.2,326.9,791.2,305.2z"/>
    </svg>
  );
};

export default FarmingGardeningIcon;
