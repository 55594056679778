import { useMemo } from 'react';
import { Community, CommunityUser } from 'services/graphqltypes';
import { gql, useQuery } from '@apollo/client';

const CURRENT_USER_COMMUNITIES_PERMISSIONS = gql`
query currentUserCommunitiesPermissions {
  currentUser {
    communityUsers {
      nodes {
        id
        communityRolePermissions {
          communityId
          createEventCourse
        }
        community {
          id
          name
          avatarUrl
          aboutOneliner
          parentCommunity {
            id
            name
          }
        }
      }
    }
  }
}
`;

// this hook returns an array of community objects (Community[])
// of communities where current user has createEventCourse permission
const useLoadCommunitiesWithCreateActivityPermission = (): Community[] => {
  const { loading, error, data } = useQuery(CURRENT_USER_COMMUNITIES_PERMISSIONS);

  return useMemo(() => {
    const communitiesWithCreateActivityPermission: Community[] = [];
    if (loading || error || data.currentUser?.communityUsers?.nodes.length === 0) {
      return communitiesWithCreateActivityPermission;
    }
    // iterate through each element and check if the user has a create eventCourse permission in a community
    data.currentUser?.communityUsers?.nodes.forEach((communityUser: CommunityUser) => {
      if (communityUser.communityRolePermissions?.createEventCourse) {
        // add the community to the list of communities
        communitiesWithCreateActivityPermission.push(communityUser.community);
      }
    });
    return communitiesWithCreateActivityPermission;

  }, [loading, error, data]);
};

export default useLoadCommunitiesWithCreateActivityPermission;
