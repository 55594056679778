import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Maybe, Scalars } from 'services/graphqltypes';

type MultilingualTextProps = {
  value?: Maybe<Scalars['JSON']> | null | undefined;
};

// react-intl's FormattedMessage doesn't support fetching translations from an API, but it does support being fed the translation value through its 'values' parameter.
// translationId must be present in lang/locales/_locale_.js

// When constructing the translationObject, we take care of falling back to defaultLocale in case a translation for user's current language is not present
// Format of data in database: { "defaultLocale": "_language_code_", "it": "buongiorno", "en": "good morning", "fr": "bonjour" }

const MultilingualText = ({ value }: MultilingualTextProps): React.ReactElement => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const translationId = 'dynamicTranslationFromApi';

  // if no value is provided, return an empty string
  if (!value) { value = { empty: '', defaultLocale: 'empty' }; }

  const translationObject = {} as any;
  translationObject[translationId] = value[currentLang]
    ? value[currentLang]
    : value[value['defaultLocale']];
  return <FormattedMessage id={translationId} values={translationObject} />;
};

export default MultilingualText;
