import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Saturn = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="saturn" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {` 
            .saturn-st0{fill:#FFFFFF;}
            .saturn-st1{fill:#E14F22;}
            .saturn-st2{fill:#E04F21;}
        `}
      </style>
      <g>
        <g id="XMLID_1_">
          <g>
            <path className="saturn-st0" d="M491.3,87.1c232.8,0,422.3,189.4,422.3,422.3c0,232.9-189.4,422.3-422.3,422.3
				C258.4,931.7,69,742.2,69,509.4C69,276.5,258.4,87.1,491.3,87.1z M625.5,629.6c33.3-38.9,71-83.1,69-137.3
				c-2.7-73.8-51.6-121.5-124.5-121.5c-43.4,0-83.4,17.2-115.4,49.1v-81.7h70.1c18.1,0,32.7-14.6,32.7-32.7
				c0-18.1-14.6-32.7-32.7-32.7h-70.1v-67.9c0-18.1-14.6-32.7-32.7-32.7c-18.1,0-32.7,14.6-32.7,32.7v67.9h-70.1
				c-18.1,0-32.7,14.6-32.7,32.7c0,18.1,14.6,32.7,32.7,32.7h70.1v232.3v135.8c0,18.1,14.6,32.7,32.7,32.7
				c18.1,0,32.7-14.6,32.7-32.7v-135c0-0.3,0-0.6,0-0.8c0-37,35.5-134.3,115.4-134.3c37.8,0,57.7,19.7,59.1,58.5
				c1.1,28.7-27.8,62.6-53.3,92.4c-17.7,20.7-34.4,40.3-44.9,61c-22.9,45.2-25.6,133.7,29.4,175.4c16.1,12.2,37.2,19.4,58.7,19.4
				c23.2,0,46.9-8.4,65-27.9c12.3-13.2,11.5-33.9-1.7-46.2c-13.2-12.3-33.9-11.5-46.2,1.7c-10.8,11.6-29.3,6.2-36.3,0.9
				c-23.1-17.5-23-69.3-10.6-93.7C596.2,664,610.4,647.3,625.5,629.6z"/>
            <path className="saturn-st1" d="M491.3,57c249.5,0,452.4,202.9,452.4,452.4S740.8,961.8,491.3,961.8S38.9,758.8,38.9,509.4
				S241.9,57,491.3,57z M913.6,509.4c0-232.8-189.4-422.3-422.3-422.3C258.4,87.1,69,276.5,69,509.4
				c0,232.8,189.4,422.3,422.3,422.3C724.1,931.7,913.6,742.2,913.6,509.4z"/>
            <path className="saturn-st2" d="M694.5,492.3c2,54.2-35.7,98.4-69,137.3c-15.1,17.7-29.4,34.4-36.3,48.1c-12.4,24.5-12.6,76.2,10.6,93.7
				c7,5.3,25.5,10.7,36.3-0.9c12.3-13.2,33-14,46.2-1.7c13.2,12.3,14,33,1.7,46.2c-18.2,19.5-41.8,27.9-65,27.9
				c-21.5,0-42.7-7.2-58.7-19.4c-55-41.7-52.3-130.2-29.4-175.4c10.5-20.7,27.2-40.3,44.9-61c25.5-29.8,54.4-63.7,53.3-92.4
				c-1.4-38.8-21.3-58.5-59.1-58.5c-79.9,0-115.4,97.3-115.4,134.3c0,0.3,0,0.6,0,0.8V419.9c32-31.9,72-49.1,115.4-49.1
				C642.9,370.8,691.8,418.5,694.5,492.3z"/>
            <path className="saturn-st2"
              d="M557.4,305.5c0,18.1-14.6,32.7-32.7,32.7h-70.1v-65.4h70.1C542.8,272.8,557.4,287.5,557.4,305.5z"/>
            <path className="saturn-st2" d="M454.6,706.3c0,18.1-14.6,32.7-32.7,32.7c-18.1,0-32.7-14.6-32.7-32.7V570.5h0c0,18.1,14.6,32.7,32.7,32.7
				c17.8,0,32.2-14.2,32.7-31.9V706.3z"/>
            <path className="saturn-st2" d="M454.6,419.9v151.5c-0.5,17.7-14.9,31.9-32.7,31.9c-18.1,0-32.7-14.6-32.7-32.7c0-37.9,16.5-86,42-122.7
				C438.4,437.6,446.2,428.2,454.6,419.9z"/>
            <path className="saturn-st2"
              d="M454.6,338.2v81.7c-8.4,8.3-16.2,17.7-23.4,28c-25.5,36.6-42,84.8-42,122.7h0V338.2H454.6z"/>
            <rect x="389.2" y="272.8" className="saturn-st2" width="65.4" height="65.4"/>
            <path className="saturn-st2"
              d="M454.6,204.9v67.9h-65.4v-67.9c0-18.1,14.6-32.7,32.7-32.7C440,172.2,454.6,186.9,454.6,204.9z"/>
            <path className="saturn-st2"
              d="M389.2,272.8v65.4h-70.1c-18.1,0-32.7-14.6-32.7-32.7c0-18.1,14.6-32.7,32.7-32.7H389.2z"/>
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Saturn;
