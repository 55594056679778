import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const CompassionateDeer = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="compassionate_deer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {` 
            .circle-compassionate-deer{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-compassionate-deer{fill:#FBBF2D;} 
        `}
      </style>
      <circle className="circle-compassionate-deer" cx="16.3" cy="16.3" r="15.7"/>
      <path className="path-compassionate-deer" d="M26.5,25.1c-0.2,0-0.4,0-0.6,0c-0.2-0.1-0.3-0.2-0.5-0.3c-1-0.4-1.6-1.1-2.2-1.9c-0.6-0.8-1.3-1.2-2.3-1.4
	c-1.1-0.2-2.3-0.5-3.3-1.3c-0.1-0.1-0.2,0-0.3,0c-1.8,0.3-3.6,0.6-5.4,0.2c-0.1,0-0.4,0-0.5,0.1c-0.5,0.5-1.1,0.4-1.8,0.3
	c-0.6-0.1-1.2-0.2-1.9-0.3c0,0.1,0,0.1,0,0.2c0.6,0.2,1.2,0.4,1.8,0.6c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.4,0.1,0.5,0.1
	c0.5-0.2,1.2,0.3,1,0.9c-0.5,0-1,0-1.5,0c-0.6,0-1-0.2-1.5-0.4c-0.5-0.3-0.9-0.7-1.4-1c-0.9-0.6-1-0.9-0.7-1.9
	c0.7-0.1,1.4-0.1,2.1-0.2c0-0.6-0.1-1.2-0.1-1.7c0-1.4,0.1-2.8,0.6-4.1c0.1-0.4,0.1-0.6-0.3-0.7c-0.3-0.1-0.7-0.3-1-0.4
	c-0.4-0.1-0.5-0.3-0.5-0.6c0-0.2,0.3-0.4,0.5-0.5c0.3-0.1,0.6-0.1,0.9-0.2c0.6-0.2,1.1-0.5,1.7-0.6c0.3-0.1,0.5-0.2,0.4-0.6
	c-0.1-0.4,0-0.6,0.3-0.6c0,0.1,0,0.3,0,0.4c0,0,0.1,0,0.1,0c0.2-0.4,0.3-0.8,0.2-1.2c-0.2-0.7-0.8-1-1.3-1.4C9.7,6.7,9.4,6,9.6,5.3
	c0.2,0.4,0.4,0.8,0.6,1.2c0,0,0.1,0,0.1,0c0.1-0.3,0.2-0.5,0.3-0.8C10.8,6,11,6.2,11.1,6.5c0.1-0.1,0.2-0.2,0.3-0.3
	c0,0.2,0.1,0.4,0.1,0.6c0.1-0.3,0.2-0.5,0.3-0.7c0,0,0.1,0,0.1,0c0,0.3,0,0.6,0.1,0.9c0.3-0.9,0.4-1,0.9-1c-1,1.1,0.2,1.6,0.6,2.4
	c0.5-0.3,0.9-0.6,1.3-0.9c0-0.1-0.1-0.1-0.1-0.2c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.4-0.5,0.8-0.8,1.2c-0.3,0.4-0.6,0.9-0.8,1.2
	c0,0.2,0.1,0.4,0,0.5c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.8-0.2,1.6-0.3,2.4c-0.1,0.7,0,1.1,0.7,1.4
	c0.5,0.2,1.1,0.4,1.6,0.4c1.5,0.1,3,0,4.6,0c0.5,0,0.7-0.2,0.8-0.7c0.1-0.3,0.1-0.6,0.2-1c0.8,0.7,0.7,1.5-0.1,2.2
	c0.5,0.4,0.7,1,0.9,1.6c0.1,0.5,0.2,1,0.5,1.4c0.4,0.7,1.2,0.7,1.9,0.7c0.3,0,0.5,0.1,0.6,0.4c0.3,0.7,0.9,1.2,1.4,1.7
	c0.3,0.2,0.6,0.6,0.9,0.6c0.4,0,0.6,0.3,0.9,0.4C26.5,24.2,26.5,24.7,26.5,25.1z M12.2,10.3c1.1-0.6,1.1-1.6,0-2.4
	C12.2,8.7,12.2,9.5,12.2,10.3z"/>
    </svg>

  );
};

export default CompassionateDeer;
