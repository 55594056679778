import AdventurousWoodpecker from 'components/Badges/age/AdventurousWoodpecker';
import AwakeningDragonfly from 'components/Badges/age/AwakeningDragonfly';
import BraveBear from 'components/Badges/age/BraveBear';
import CompassionateDeer from 'components/Badges/age/CompassionateDeer';
import CreativeHorse from 'components/Badges/age/CreativeHorse';
import ExploringOtter from 'components/Badges/age/ExploringOtter';
import FarsightedEagle from 'components/Badges/age/FarsightedEagle';
import FriendlyWolf from 'components/Badges/age/FriendlyWolf';
import ListeningGoose from 'components/Badges/age/ListeningGoose';
import PeacefulTurtle from 'components/Badges/age/PeacefulTurtle';
import StrongBull from 'components/Badges/age/StrongBull';
import WiseOwl from 'components/Badges/age/WiseOwl';
import { ageRange, getAgeBagesArray } from 'helpers/utils-typescript';
import React, { CSSProperties } from 'react';
import { BadgeModalContent } from '../../Modal/InfoModal';

type AgeBadgeProps = {
  age: number;
  className?: string;
  size?: number | string;
  style?: CSSProperties;
  onClick?: () => void;
}


export function getAgeBadge(
  age: number,
  className?: string,
  size?: number | string,
  style?: CSSProperties): React.ReactElement {

  switch (true) {
    case ageRange(age, 1.5, 3):
      // we actually only get whole year as age -> this badge will show from 2 years old
      return <AwakeningDragonfly style={style} className={className} size={size} />;
    case ageRange(age, 3, 6):
      return <ExploringOtter style={style} className={className} size={size} />;
    case ageRange(age, 6, 9):
      return <AdventurousWoodpecker style={style} className={className} size={size} />;
    case ageRange(age, 9, 12):
      return <CompassionateDeer style={style} className={className} size={size} />;
    case ageRange(age, 12, 15):
      return <ListeningGoose style={style} className={className} size={size} />;
    case ageRange(age, 15, 18):
      return <BraveBear style={style} className={className} size={size} />;
    case ageRange(age, 18, 21):
      return <FriendlyWolf style={style} className={className} size={size} />;
    case ageRange(age, 21, 35):
      return <StrongBull style={style} className={className} size={size} />;
    case ageRange(age, 35, 50):
      return <FarsightedEagle style={style} className={className} size={size} />;
    case ageRange(age, 50, 65):
      return <CreativeHorse style={style} className={className} size={size} />;
    case ageRange(age, 65, 80):
      return <WiseOwl style={style} className={className} size={size} />;
    case age >= 80:
      return <PeacefulTurtle style={style} className={className} size={size} />;
    default:
      break;
  }


}

export const AgeBadgeModalContent = () => {
  return BadgeModalContent('badge.age-modal-title', 'badge.age-modal-subtitle', getAgeBagesArray());
};

const AgeBadge = ({ age, className, size, onClick, style }: AgeBadgeProps): React.ReactElement => {

  return (
    <div onClick={onClick}>
      {getAgeBadge(age, className, size, style)}
    </div>
  );
};

export default AgeBadge;
