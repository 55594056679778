import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Neptune = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="neptun" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {` 
            .neptune-st0{fill:#FFFFFF;}
            .neptune-st1{fill:#E14F22;}
            .neptune-st2{fill:#E04F21;} 
        `}
      </style>
      <g>
        <g id="XMLID_1_">
          <g>
            <path className="neptune-st0" d="M929.5,486.3c0,235.7-191.7,427.4-427.4,427.4S74.7,722,74.7,486.3S266.4,58.9,502.1,58.9
				S929.5,250.6,929.5,486.3z M708,245.9v-51.6c0-18.5-15-33.5-33.5-33.5s-33.5,15-33.5,33.5v51.6c0,65.1-45,119.9-105.5,134.9
				V196.4c0-18.5-15-33.5-33.5-33.5c-18.5,0-33.5,15-33.5,33.5v184.4c-60.5-15-105.5-69.8-105.5-134.9v-51.6
				c0-18.5-15-33.5-33.5-33.5c-18.5,0-33.5,15-33.5,33.5v51.6C296.2,348,371,433,468.6,449.1v160.7h-139c-18.5,0-33.5,15-33.5,33.5
				c0,18.5,15,33.5,33.5,33.5h139v138.8c0,18.5,15,33.5,33.5,33.5c18.5,0,33.5-15,33.5-33.5V676.8h139c18.5,0,33.5-15,33.5-33.5
				c0-18.5-15-33.5-33.5-33.5h-139V449.1C633.3,433,708,348,708,245.9z"/>
            <path className="neptune-st1" d="M502.1,28.5C754.6,28.5,960,233.9,960,486.3S754.6,944.2,502.1,944.2S44.2,738.8,44.2,486.3
				S249.6,28.5,502.1,28.5z M502.1,913.8c235.7,0,427.4-191.7,427.4-427.4S737.8,58.9,502.1,58.9S74.7,250.7,74.7,486.3
				S266.4,913.8,502.1,913.8z"/>
            <path className="neptune-st2" d="M708,194.2v51.6C708,348,633.3,433,535.6,449.1v-68.3c60.5-15,105.5-69.8,105.5-134.9v-51.6
				c0-18.5,15-33.5,33.5-33.5S708,175.7,708,194.2z"/>
            <path className="neptune-st2"
              d="M708,643.3c0,18.5-15,33.5-33.5,33.5h-139v-66.9h139C693,609.8,708,624.8,708,643.3z"/>
            <path className="neptune-st2"
              d="M535.6,676.8v138.8c0,18.5-15,33.5-33.5,33.5c-18.5,0-33.5-15-33.5-33.5V676.8H535.6z"/>
            <rect x="468.6" y="609.8" className="neptune-st2" width="66.9" height="66.9"/>
            <path className="neptune-st2"
              d="M535.6,449.1v160.7h-66.9V449.1c10.9,1.8,22.1,2.7,33.5,2.7C513.5,451.8,524.7,450.9,535.6,449.1z"/>
            <path className="neptune-st2" d="M535.6,380.8v68.3c-10.9,1.8-22.1,2.7-33.5,2.7c-11.4,0-22.6-0.9-33.5-2.7v-68.3c10.7,2.7,21.9,4.1,33.5,4.1
				C513.6,384.9,524.8,383.4,535.6,380.8z"/>
            <path className="neptune-st2" d="M535.6,196.4v184.4c-10.7,2.7-21.9,4.1-33.5,4.1c-11.5,0-22.7-1.4-33.5-4.1V196.4c0-18.5,15-33.5,33.5-33.5
				C520.6,163,535.6,177.9,535.6,196.4z"/>
            <path className="neptune-st2"
              d="M468.6,609.8v66.9h-139c-18.5,0-33.5-15-33.5-33.5c0-18.5,15-33.5,33.5-33.5H468.6z"/>
            <path className="neptune-st2" d="M468.6,380.8v68.3C371,433,296.2,348,296.2,245.9v-51.6c0-18.5,15-33.5,33.5-33.5c18.5,0,33.5,15,33.5,33.5
				v51.6C363.1,311,408.1,365.8,468.6,380.8z"/>
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Neptune;
