import { FieldInputProps, FormikProps } from 'formik';
import { gql, useLazyQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import AsyncSelect from 'react-select/async';
import IntlMessages from 'helpers/IntlMessages';
import { ActionMeta, components, ControlProps, IndicatorComponentType, MultiValueProps, OptionProps, OptionTypeBase, SingleValueProps, Styles } from 'react-select';
import ThumbnailImage from 'components/custom/ThumbnailImage';
import ThumbnailLetters from 'components/custom/ThumbnailLetters';
import { NoticeProps } from 'react-select/src/components/Menu';
import { useIntl } from 'react-intl';

// custom render selected option (only render label by default)
const CustomMultiValueWithIcon = (props: MultiValueProps<Option>) => {
  return <components.MultiValue {...props} className='d-flex align-items-center'>

    <span className="mr-1">
      {props.data.image ?
        <ThumbnailImage rounded small src={props.data.image} alt="Profile picture" style={{ height: '1.5rem' }} />
        :
        <ThumbnailLetters rounded small text={props.data.label} style={{ height: '1.5rem', fontSize: '0.8rem' }} />}
    </span>
    <span className="truncate">{props.data.label}</span>
  </components.MultiValue>;
};

const CustomSingleValueWithIcon = (props: SingleValueProps<Option>) => {
  return <components.SingleValue {...props} className='d-flex align-items-center'>
    <span className="mr-1 align-middle">
      {props.data.image ?
        <ThumbnailImage rounded small src={props.data.image} alt="Profile picture" style={{ height: '1.5rem' }} />
        :
        <ThumbnailLetters rounded small text={props.data.label} style={{ height: '1.5rem', fontSize: '0.8rem' }} />}
    </span>
    <span className="truncate">{props.data.label}</span>
    {props.data.tag && (
      <span className="font-weight-light text-semi-muted">&nbsp;&nbsp;&nbsp;{props.data.tag}</span>
    )}
  </components.SingleValue>;
};

// custom render options in dropdown
const SimpleCustomOption = (props: OptionProps<Option, true>) => {
  return (
    <components.Option {...props}>
      { /* to have a disabled option, set isDisabled to true in the option object */}
      <div className={`d-flex flex-noWrap align-items-center ${props.isDisabled && 'select__option--is-disabled'}`}>

        {/* render option icon  */}

        <span className="mr-1">
          {props.data.image ?
            <ThumbnailImage rounded small src={props.data.image} alt="Profile picture" style={{ height: '1.5rem', filter: props.isDisabled && 'grayscale(100%)' }} />
            : props.data.icon
              ? props.data.icon
              : <ThumbnailLetters rounded small text={props.data.label} style={{ height: '1.5rem', fontSize: '0.8rem', filter: props.isDisabled && 'grayscale(100%)' }} />
          }
        </span>

        <div className='text-wrap'>{props.data.label}</div>

        {props.data.tag && (
          <div className="font-weight-light text-semi-muted">&nbsp;&nbsp;&nbsp;{props.data.tag}</div>
        )}

      </div>
    </components.Option>
  );
};

// component shown when there are no options loaded or found
const NoOptionsMessage = (props: NoticeProps<Option, boolean>) => {
  const intl = useIntl();
  return (
    <components.NoOptionsMessage {...props}>
      <div>{intl.formatMessage({ id: 'general.not-found' })}</div>
    </components.NoOptionsMessage>
  );
};

export const ControlStyle = (baseStyles: React.CSSProperties, state: ControlProps<Option, boolean>) => {
  return {
    ...baseStyles,
    //if it is invalid formik value, change border color to red.
    // if it is valid formik value, default border color to grey, on focus, change border color to green
    borderColor: state.selectProps.isInvalid ? '#dc3545' : state.isFocused ? '#60b6aa' : '#d7d7d7',
    // default border width to 1px, on focus, change border width to 2px
    borderWidth: state.isFocused ? '2px' : '1px',
    '&:hover': {
      borderWidth: state.isFocused ? '2px' : '1px',
      borderColor: state.selectProps.isInvalid ? '#dc3545' : state.isFocused ? '#60b6aa' : '#cccccc'
    },
  }
}

const asyncSelectComponents = {
  Option: SimpleCustomOption,
  NoOptionsMessage,
  MultiValue: CustomMultiValueWithIcon,
  SingleValue: CustomSingleValueWithIcon,
  DropdownIndicator: null as IndicatorComponentType<Option, boolean>, // do not show the dropdown icon
}

const LOAD_USERS = gql`
query LoadUsersSearch($searchTerm: String!) {
  users(
    filter: {
      isArchived: { equalTo: false }
      or: [
        { name: { includesInsensitive: $searchTerm } }
        { username: { includesInsensitive: $searchTerm } }
      ]
    }
  ) {
    nodes {
      id
      name
      username
      avatarUrl
    }
  }
}
`;

type User = {
  id: string;
  name: string;
  username: string;
  avatarUrl?: string;
};

export interface Option extends OptionTypeBase {
  value: string;
  label: string;
  tag?: string;
  image?: string;
}


type SearchForUserProps = {
  isMulti?: boolean;
  placeholder?: string;
  styles?: Partial<Styles<Option, boolean>>;
  className?: string;

  // required for formik usage
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;

  // required for non-formik usage
  selected?: Option;
  onSelect?: (
    option: Option,
    actionMeta: ActionMeta<Option>
  ) => void;

};

/** React dropdown that allows searching for users */
const SearchForUsers = ({
  field,
  form,
  isMulti = false,
  onSelect,
  styles,
  selected,
  className
}: SearchForUserProps) => {

  const [loadUsers] = useLazyQuery(LOAD_USERS);

  const formatUserOptions = (users?: { users: { nodes: User[] } }): Option[] => {
    if (!users?.users.nodes) return [];

    return users.users.nodes.map((user: User) => ({
      value: user.id,
      label: user.name,
      tag: user.username,
      image: user.avatarUrl,
    }));
  };

  const debouncedLoadUsers = useDebouncedCallback((input: string, callback: (options: Option[]) => void) => {
    loadUsers({
      variables: {
        searchTerm: input
      }
    }).then((response) => {
      const options = formatUserOptions(response.data);
      callback(options);
    });
  }, 500);

  return (
    <>
      <AsyncSelect
        name={field?.name}
        value={field?.value ?? selected as Option} // put selected behind ?? to insure selected can be set to null manually from outside
        isInvalid={!!form?.touched[field?.name] && form?.errors[field?.name]}
        className={className ?? "flex-grow-1 search-user"}
        classNamePrefix={className ?? "select"}
        components={{
          ...asyncSelectComponents,
        }}
        styles={{
          ...styles,
          control: ControlStyle,
        }}
        loadOptions={debouncedLoadUsers}
        placeholder={<span><IntlMessages id='event.course-move-booking-type-to-search' /></span>}
        openMenuOnClick={false}
        isClearable
        isMulti={isMulti}
        hasIcon={true}
        onChange={(options, actionMeta) => {
          if (onSelect) { onSelect(options as Option, actionMeta) }
          else if (form && field) {
            form.setFieldValue(field.name, options);
          } else {
            throw Error('SearchForUsers: Neither onSelect nor Formik props provided');
          }
        }}
      />
    </>
  );
};

export default SearchForUsers;