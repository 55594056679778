import useDevelopmentBadge from 'components/BioRegion/useDevelopmentBadge';
import IntlMessages from 'helpers/IntlMessages';
import React, { SetStateAction } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { getAgeBadge } from '../Badges/age/AgeBadge';
import { getFacilitationBadge } from '../Badges/facilitation/FacilitationBadge';
import { getParticipationBadge } from '../Badges/participation/ParticipationBadge';
import { getLearningPathIcon, getLearningPathIconColor } from '../LearningPathsIcons/LearningPathTag';
import { getSdgIcon } from '../community/SdgIcon';

type InfoModalProps = {
  LearningPathSdgId?: string;
  LearningPathSdgName?: string;
  description: string | React.ReactNode;
  showInfoModal: boolean;
  setShowInfoModal: (_show: boolean) => void | React.Dispatch<SetStateAction<boolean>> | any;
  handleConfirm?: (_confirm: boolean) => void | any;
  showCloseButton?: boolean;
  customConfirmButtonText?: string;
  title?: string | React.ReactNode;
  size?: "sm" | "lg" | "xl" | "md";
}

const InfoModal = ({
  LearningPathSdgId,
  LearningPathSdgName,
  description,
  showInfoModal,
  setShowInfoModal,
  handleConfirm,
  showCloseButton = true,
  customConfirmButtonText,
  title,
  size = 'md',
}: InfoModalProps): React.ReactElement => {

  const getIcon = (id: string): React.ReactNode => {
    if (getLearningPathIcon(id, getLearningPathIconColor(id))) {
      return getLearningPathIcon(id, getLearningPathIconColor(id), 40);
    } else {
      return getSdgIcon(Number(id), undefined, 40);
    }
  };

  return (
    <Modal
      size={size !== 'md' ? size : null} // if there is no size passed, modal use default size
      show={showInfoModal}
      onHide={() => setShowInfoModal(false)}
      className="rounded-modal">
      <Modal.Header closeButton className="mr-3 mt-3 p-0" />
      <ModalBody>
        {(LearningPathSdgId || LearningPathSdgName) && <div className="d-flex align-items-center mt-3 mb-2">
          {LearningPathSdgId && <div className="mr-2 align-self-center">{getIcon(LearningPathSdgId)}</div>}
          {LearningPathSdgName && <h4 className="font-weight-bold h4 align-self-center mb-0">{LearningPathSdgName}</h4>}
        </div>}
        <div className='my-4 text-center'>
          {title}
        </div>
        <div className="text-normal mt-0 pt-0" style={{ whiteSpace: 'pre-wrap' }}>
          {description}
        </div>
        <div className="text-center mt-3">
          {showCloseButton && <button className="btn btn-outline-primary" onClick={() => setShowInfoModal(false)}>
            <IntlMessages id="general.close" />
          </button>
          }
          {handleConfirm && <button className="btn btn-yellow color-theme-1 ml-3" onClick={() => handleConfirm(true)}>
            {customConfirmButtonText ?? <IntlMessages id="general.confirm" />}
          </button>}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InfoModal;

export const BadgeModalContent = (
  modalTitle: string,
  modalSubTitle: string,
  badgeKeyValuePairArray: Array<{
    id?: string,
    value?: number,
    unit?: string,
    badgeSrc?: string,
    badgeName?: string,
    badgeCriteria?: object,
    badgeCriteriaMessage?: string
  }>,
  showModalTitle: boolean = true, // Modal title must be passed, as it is used to determine what content to show. Here you can force not to show the title.
): React.ReactElement => {
  const intl = useIntl();
  const size = 25;

  const GetBadge = ({ value }: { value: number }) => {
    const { obtainedBadge: obtainedDevelopmentBadge } = useDevelopmentBadge(value);
    if (modalTitle.includes('age')) {
      return getAgeBadge(value, undefined, size);
    } else if (modalTitle.includes('facilitation')) {
      return getFacilitationBadge(value, undefined, size);
    } else if (modalTitle.includes('participation')) {
      return getParticipationBadge(value, undefined, size);
    } else if (modalTitle.includes('development')) {
      return <img
        src={obtainedDevelopmentBadge.badgeSrc}
        alt={`development-badge-level${obtainedDevelopmentBadge.level}`}
        height={size}
      />;
    }
  };

  return (
    <div>
      {
        showModalTitle && <h2 className="font-weight-bolder text-center">{intl.formatMessage({ id: modalTitle })}</h2>
      }
      <div className="mt-3 text-normal text-center">{intl.formatMessage({ id: modalSubTitle }, { b: chunks => <b>{chunks}</b> })}</div>
      <div className="mt-3 d-flex">
        <div className='mx-auto'>

          {
            badgeKeyValuePairArray.map((badge, index) => {
              return (
                <div className="d-flex mb-1 align-items-center" key={index}>
                  {modalTitle.includes('bfg-guardian') ?
                    <img
                      src={badge.badgeSrc}
                      alt={`development-badge-level${index + 1}`}
                      height={size}
                    /> :
                    <GetBadge value={badge.value} />}

                  <span className="ml-2 align-self-center">
                    {modalTitle.includes('bfg-guardian')
                      ? <>{badge.badgeName} <i> {badge.badgeCriteriaMessage}</i></>
                      : <>{intl.formatMessage({ id: badge.id })} <i> {badge.value} {badge.unit ? badge.unit : ''}</i></>
                    }
                  </span>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};
