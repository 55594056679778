import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const ListeningGoose = ({ style, size = 45, className }: IconProps):React.ReactElement=>{
  return (
    <svg version="1.1" id="listening_goose" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7"  xmlSpace="preserve" height={size} className={className}  style={style}>
      <style type="text/css">
        {`
            .circle-listening-goose{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-listening-goose{fill:#FBBF2D;}
        `}
      </style>
      <circle className="circle-listening-goose" cx="16.3" cy="16.3" r="15.7"/>
      <path className="path-listening-goose" d="M25,26.1c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.2-0.3-0.4-0.5-0.6c0-0.1-0.1-0.1-0.2-0.1c0,0,0,0,0,0
	c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.3,1,0.4c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.2,0-0.3c0,0,0-0.1,0-0.1
	c0,0-0.1-0.1-0.2-0.1c0.1,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0-0.1c-0.3-0.2-0.6-0.4-0.9-0.5
	c-0.3-0.2-0.7-0.4-1-0.5c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3,0-0.5-0.2-0.7-0.4c-0.3-0.3-0.4-0.7-0.5-1c-0.1-0.2-0.1-0.3,0-0.5
	c0.1-0.2,0-0.4,0-0.5c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0,0.1-0.1c-0.1-0.2,0-0.4,0-0.6
	c0-0.1,0-0.3-0.1-0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.2,0-0.4,0-0.5
	c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.3-0.2c0-0.2,0-0.4,0.1-0.5c0,0,0-0.1,0.1-0.1
	c0.2-0.1,0.2-0.2,0.2-0.4c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0.2-0.3c0.2-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3
	c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.3,0-0.5c0-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.1-0.2,0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2
	c0,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0
	c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.2,0-0.3c-0.2-0.1-0.2-0.1-0.1-0.3
	c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0-0.2c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0
	c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0,0,0,0,0-0.1c0.1-0.2,0-0.3-0.2-0.2c-0.2,0.1-0.3,0.1-0.5,0.2c0,0-0.1,0-0.1,0
	c0-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2,0-0.4,0-0.6c0-0.1,0-0.1-0.1-0.1c-0.2,0.1-0.4,0.2-0.5,0.4
	c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.7c0-0.2,0-0.4,0-0.6C24.5,7,24.4,7,24.4,7.1
	c-0.2,0.2-0.4,0.4-0.7,0.7c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0,0,0c0.1-0.2,0.2-0.3,0.3-0.5C23.8,7.3,24,7,24.1,6.7c0-0.2,0-0.5,0-0.7
	c0,0,0,0-0.1-0.1C23.5,6.6,23,7.3,22.4,8c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.2-0.4,0.2-0.7
	c0-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.5-0.4,1-0.6,1.5
	c0,0,0,0.1-0.1,0.1c0-0.2,0.1-0.4,0.1-0.6C21.9,6.8,22,6.4,21.9,6c0-0.3,0-0.5-0.2-0.7c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
	c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.1,0.7-0.2,1c-0.2,0.5-0.4,1-0.6,1.4c-0.2,0.5-0.5,1-0.8,1.4
	c-0.2,0.3-0.4,0.7-0.6,1c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1,0.1-0.2,0.2-0.4,0.3c0,0-0.1,0-0.1,0.1c0-0.2,0-0.3,0-0.4
	c0-0.3,0-0.5-0.2-0.8c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.5c0-0.2,0-0.5-0.1-0.7
	c0-0.2-0.1-0.2-0.2,0c-0.2,0.2-0.3,0.4-0.5,0.7c0,0-0.1,0.1-0.1,0.1c0-0.2,0-0.4,0.1-0.6c0-0.2,0-0.4,0.1-0.5c0-0.1,0-0.2,0-0.4
	c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.4,0.6-0.5,0.9c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
	c0-0.2,0-0.3,0.1-0.5c0-0.2,0-0.4,0.1-0.6c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.3-0.2-0.5c0,0-0.1-0.1-0.1-0.1
	c-0.2,0.7-0.3,1.4-0.5,2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.7-0.4-0.9c0,0.1-0.1,0.2-0.1,0.3
	c0,0.3-0.1,0.6-0.1,0.9c0,0.3-0.1,0.6-0.1,0.8c0,0.3-0.1,0.6-0.1,1c0,0.4-0.1,0.8-0.1,1.1c0,0.4-0.1,0.8-0.1,1.2c0,0.5,0,0.9,0,1.4
	c0,0.3,0.1,0.6,0.2,0.9c0.2,0.3,0.3,0.6,0.3,0.9c0.1,0.4,0.1,0.8,0,1.2c0,0.1-0.2,0.2-0.2,0.2c-0.5,0-0.9,0.1-1.4,0
	c-0.6,0-1.1-0.2-1.6-0.3c-0.6-0.2-1.2-0.4-1.8-0.6c-0.5-0.2-1-0.4-1.5-0.6c-0.3-0.2-0.6-0.2-1-0.1c-0.2,0-0.3,0-0.5,0
	c-0.1,0-0.1,0-0.2,0.1c-0.3,0.2-0.5,0.3-0.8,0.5c-0.3,0.1-0.5,0.3-0.8,0.4c-0.3,0.1-0.5,0.3-0.8,0.4c-0.1,0-0.1,0.1-0.2,0.2
	c0,0.1,0,0.1,0,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0.1,1,0.1c0.6,0,1.1,0,1.7,0c0.4,0,0.8,0.1,1.2,0.2
	c0.6,0.2,1.2,0.5,1.8,0.8c0.4,0.2,0.8,0.4,1.2,0.8c0.3,0.2,0.5,0.5,0.8,0.7c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.2,0.6,0.5,0.9,0.7
	c0.4,0.2,0.8,0.5,1.2,0.7c0.7,0.4,1.4,0.7,2.2,0.9c0.5,0.1,0.9,0.2,1.4,0.3c0.3,0,0.6,0.1,0.9,0.1c0.4,0.1,0.9,0.1,1.3,0.1
	c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.2,0c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0,0.4,0.1,0.5,0.1
	c0.4,0.1,0.8,0.2,1.2,0.3c0.2,0,0.3,0.1,0.5,0.2C24.9,26.1,25,26.1,25,26.1z"/>
    </svg>
  );
};
export default ListeningGoose;
