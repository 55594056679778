import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const ZeroHungerIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="zeroHunger" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .zero-hunger{fill - rule:evenodd;clip-rule:evenodd;fill:#D3A12A;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528" className="zero-hunger" d="M13.2,0.3h48.6c6.8,0,12.5,5.6,12.5,12.5v48.7c0,6.8-5.6,12.5-12.5,12.5H13.2
	C6.4,74,0.8,68.4,0.8,61.5V12.8C0.8,5.9,6.4,0.3,13.2,0.3z"/>
      <g id="text6065-3">
        <path id="path1897" className="st1"
          d="M22,13.1v-0.8l1.7-4.6h-1.5V6.9h2.5v0.7l-1.6,4.6h1.6v0.9L22,13.1L22,13.1z"/>
        <path id="path1899" className="st1"
          d="M28.1,12.2v0.9h-2.7V6.9h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8L28.1,12.2z"/>
        <path id="path1901" className="st1" d="M28.6,13.1V6.9h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V9c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9L30,10.5h-0.5v2.7L28.6,13.1L28.6,13.1z M30.5,9.5c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V8.4c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v2H30C30.3,9.7,30.4,9.6,30.5,9.5L30.5,9.5z"/>
        <path id="path1903" className="st1" d="M32.3,7.5c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4C34.8,7.6,35,7.8,35,7.9c0,0.1,0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.1,0-0.4,0.1-0.6,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4V8.5c0-0.2,0-0.3,0-0.5C32.2,7.8,32.2,7.7,32.3,7.5L32.3,7.5
		z M34.1,8c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.2
		c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2V8.5c0-0.1,0-0.2,0-0.2C34.2,8.1,34.1,8.1,34.1,8L34.1,8z"/>
        <path id="path1905" className="st1"
          d="M22.2,21.7v-6.2h0.9v2.7h1.1v-2.6h0.9v6.2h-0.9v-2.8h-1.1v2.8L22.2,21.7L22.2,21.7z"/>
        <path id="path1907" className="st1" d="M28.8,21c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.8h0.9
		v4.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4v-4.8
		h0.9v4.8c0,0.1,0,0.3,0,0.4C28.9,20.8,28.8,20.9,28.8,21L28.8,21z"/>
        <path id="path1909" className="st1" d="M31.8,21.7l-1.4-3.8v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H31.8z"/>
        <path id="path1911" className="st1" d="M36.1,21c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
		c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.4,0.3-0.6
		c0.2-0.3,0.5-0.4,1.1-0.4c0.2,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5s0,0.3,0,0.5v0.2h-0.9v-0.2
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.5,0.2c0,0.1-0.1,0.2-0.1,0.3
		c0,0,0,0.1,0,0.2v3c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.5,0.2s0.4,0,0.4-0.2c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1V19h-0.7v-0.8h1.6v1.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2
		C36.1,20.9,36.1,21,36.1,21L36.1,21z"/>
        <path id="path1913" className="st1"
          d="M39.6,20.8v0.9h-2.7v-6.3h2.7v0.8h-1.8v1.9h1.5V19h-1.5v1.8H39.6L39.6,20.8z"/>
        <path id="path1915" className="st1" d="M40,21.7v-6.2h1.5c0.4,0,0.7,0.1,1,0.4s0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9
		L41.4,19h-0.5v2.7H40z M42,18.1c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3v-0.6c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6
		v1.9h0.6C41.7,18.2,41.8,18.2,42,18.1L42,18.1z"/>
      </g>
      <g id="text6075-0">
        <path id="path1918" className="st1" d="M15,21.4H8.7v-1.9l4-7.7c0.2-0.3,0.2-0.7,0.2-1.1V9.6c0-0.3-0.1-0.5-0.3-0.7
		c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.3H8.7v-1c0-0.6,0.2-1.3,0.6-2.1
		c0.4-0.8,1.3-1.2,2.7-1.2c0.8,0,1.5,0.3,2.1,0.9C14.7,8,15,8.8,15,9.6V11c0,0.6-0.1,1.2-0.5,1.8l-3.3,6.6H15L15,21.4z"/>
      </g>
      <path id="path6925" className="st2" d="M42.3,64.5c0.6,0,0.7-0.6,0.6-1c0-0.5,0.4-0.6,0.7-0.6c2.8-0.9,5.8-2.6,8.2-5.1
	c2.2-2.4,4-4.9,4.7-9c0.1-0.5-0.2-0.9-0.7-0.9H17.4c-0.5,0-0.8,0.4-0.7,0.9c0.6,4.1,2.4,6.6,4.7,9c2.3,2.5,5.3,4.2,8.2,5.1
	c0.2,0.1,0.7,0.1,0.7,0.6c0,0.4,0,1,0.6,1H42.3z M29.2,46.1h2.5c0,0,1.2-3.3-1.6-6.1c-0.9-0.9-1.4-2.2-1.4-3.5
	c0-2.4,1.1-3.8,1.4-4.3c0.8-1.3-1.1-2.2-2-1.2c-1.3,1.5-2,3.7-2,5.7c0,2.3,1.1,3.4,2.2,4.8C30.4,44.1,29.2,46.1,29.2,46.1L29.2,46.1
	z M44.5,46.1H47c0,0,1.1-3.3-1.6-6.1c-0.9-0.9-1.4-2.2-1.4-3.5c0-2.4,1.1-3.8,1.4-4.3c0.8-1.3-1.1-2.2-2-1.2c-1.3,1.5-2,3.7-2,5.7
	c0,2.3,1.1,3.4,2.2,4.8C45.7,44.1,44.5,46.1,44.5,46.1L44.5,46.1z M36.9,46.1h2.5c0,0,1.2-3.3-1.6-6.1c-0.9-0.9-1.4-2.2-1.4-3.5
	c0-2.4,1.1-3.8,1.4-4.3c0.8-1.3-1.1-2.2-2-1.2c-1.3,1.5-2,3.7-2,5.7c0,2.3,1.1,3.4,2.2,4.8C38.1,44.1,36.9,46.1,36.9,46.1L36.9,46.1
	z"/>
    </svg>
  );
};

export default ZeroHungerIcon;
