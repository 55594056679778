import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const ResponsibleConsumptionProductionIcon = ({ style, size = 100, className }: IconProps):
    React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="responsibleConsumptionProduction" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
           .responsible-consumption-production{fill - rule:evenodd;clip-rule:evenodd;fill:#D08F2C;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3" className="responsible-consumption-production" d="M13.2,1.4h47.7c6.7,0,12.2,5.5,12.2,12.2v47.8c0,6.7-5.5,12.2-12.2,12.2H13.2
	C6.5,73.7,1,68.3,1,61.5V13.6C1,6.9,6.5,1.4,13.2,1.4z"/>
      <g id="text6065-3-2-9-6-6-5-0-0-8-8-8">
        <path id="path2386" className="st1" d="M24.2,13.7V7.6h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.6h-0.5v2.6H24.2L24.2,13.7z M26.2,10.2c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3V9c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C25.9,10.4,26.1,10.3,26.2,10.2L26.2,10.2z"/>
        <path id="path2388" className="st1" d="M30.3,12.8v0.9h-2.6V7.6h2.6v0.8h-1.8v1.8H30V11h-1.5v1.8L30.3,12.8z"/>
        <path id="path2390" className="st1" d="M31.2,12.2c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1V9.1c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9V9.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3s0.1,0.1,0.3,0.2c0,0,0.1,0,0.2,0.1
		c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.3-0.4,0.6
		c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L31.2,12.2L31.2,12.2z"/>
        <path id="path2392" className="st1" d="M33.8,13.7V7.6h1.4l0,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.1,0.1,0.1,0.3,0.1,0.5v0.9v0.1c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.3,0.1-0.5,0.1
		h-0.1h-0.4v2.4H33.8z M35.6,10.3c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v2
		h0.4c0.1,0,0.2,0,0.3,0C35.5,10.5,35.5,10.4,35.6,10.3L35.6,10.3z"/>
        <path id="path2394" className="st1" d="M37.3,8.2c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2C37.1,13,37,12.8,37,12.7s0-0.2,0-0.4V9.2c0-0.2,0-0.3,0-0.5C37.1,8.5,37.2,8.4,37.3,8.2L37.3,8.2z
		 M39,8.7c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2s0,0.1,0,0.2v3.1c0,0,0,0,0,0.1
		c0,0,0,0,0,0.1s0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2
		V9.2c0-0.1,0-0.2,0-0.2C39,8.8,39,8.8,39,8.7L39,8.7z"/>
        <path id="path2396" className="st1" d="M42.8,13.7L41.4,10v3.7h-0.9V7.6h0.8l1.4,3.7V7.6h0.9v6.1H42.8z"/>
        <path id="path2398" className="st1" d="M44.8,12.2c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1V9.1c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3H46V9.1C46,9,46,9,46,9c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3s0.1,0.1,0.3,0.2c0,0,0.1,0,0.2,0.1
		c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.3-0.4,0.6
		c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L44.8,12.2L44.8,12.2z"/>
        <path id="path2400" className="st1" d="M47.3,13.7V7.6h0.9v6.1H47.3z"/>
        <path id="path2402" className="st1" d="M50.3,7.6c0.2,0,0.4,0,0.5,0.1C51,7.8,51.2,8,51.3,8.1c0.2,0.2,0.3,0.5,0.3,0.9v0.5
		c0,0.5-0.2,0.8-0.5,1c0.4,0.2,0.5,0.5,0.5,1v0.7c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.6H50.3L50.3,7.6z
		 M50.8,9.4V9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v1.7h0.5c0.2,0,0.3,0,0.4-0.2C50.7,9.8,50.8,9.7,50.8,9.4L50.8,9.4
		z M50.8,12.3v-0.7c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v1.9h0.5c0.2,0,0.3,0,0.4-0.2C50.7,12.6,50.8,12.4,50.8,12.3z
		"/>
        <path id="path2404" className="st1" d="M54.7,13.7h-2.6V7.6H53v5.2h1.7V13.7z"/>
        <path id="path2406" className="st1"
          d="M57.5,12.8v0.9h-2.6V7.6h2.6v0.8h-1.8v1.8h1.5V11h-1.5v1.8L57.5,12.8z"/>
        <path id="path2408" className="st1" d="M27,20.6c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.1c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4s0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9v-0.3c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3s0.2,0.2,0.4,0.2s0.3,0,0.4-0.1
		c0-0.1,0.1-0.2,0.1-0.2s0-0.1,0-0.2v-0.3H27L27,20.6z"/>
        <path id="path2410" className="st1" d="M28,16.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5s0,0.3,0,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.1c0-0.2,0-0.3,0-0.5C27.8,16.9,27.9,16.7,28,16.6
		L28,16.6z M29.7,17.1c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.1
		c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2
		c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2v-3.1c0-0.1,0-0.2,0-0.2C29.7,17.2,29.7,17.2,29.7,17.1L29.7,17.1z"/>
        <path id="path2412" className="st1" d="M33.5,22.1l-1.4-3.7v3.7h-0.9v-6.1h0.8l1.4,3.7v-3.7h0.9v6.1H33.5z"/>
        <path id="path2414" className="st1" d="M35.4,20.6c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2S35,19,34.8,18.7c-0.2-0.2-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5
		c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.8V20.6L35.4,20.6z"/>
        <path id="path2416" className="st1" d="M40.5,21.5c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1s-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.7h0.9v4.7
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4V16h0.9
		v4.7c0,0.1,0,0.3,0,0.4C40.6,21.3,40.6,21.4,40.5,21.5L40.5,21.5z"/>
        <path id="path2418" className="st1"
          d="M44,22.1v-3.7L43.2,21h-0.4l-0.9-2.6v3.7H41v-6.1h0.8l1.2,3.3l1.1-3.3h0.8v6.1H44z"/>
        <path id="path2420" className="st1" d="M45.5,22.1v-6.1H47l0,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.1,0.1,0.1,0.3,0.1,0.5v0.9v0.1c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3s-0.3,0.1-0.5,0.1h-0.1
		h-0.4v2.4L45.5,22.1L45.5,22.1z M47.3,18.7c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2v-0.9c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v2h0.4c0.1,0,0.2,0,0.3,0C47.2,18.8,47.3,18.8,47.3,18.7L47.3,18.7z"/>
        <path id="path2422" className="st1"
          d="M49.5,22.1v-5.3h-1V16h2.9v0.8h-1.1v5.3C50.4,22.1,49.5,22.1,49.5,22.1z"/>
        <path id="path2424" className="st1" d="M51.8,22.1v-6.1h0.9v6.1H51.8z"/>
        <path id="path2426" className="st1" d="M53.5,16.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.1c0-0.2,0-0.3,0-0.5C53.4,16.9,53.4,16.7,53.5,16.6
		L53.5,16.6z M55.2,17.1c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2
		v3.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0s0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		s0-0.1,0-0.2v-3.1c0-0.1,0-0.2,0-0.2C55.3,17.2,55.3,17.2,55.2,17.1L55.2,17.1z"/>
        <path id="path2428" className="st1" d="M59.1,22.1l-1.4-3.7v3.7h-0.9v-6.1h0.8l1.4,3.7v-3.7h0.9v6.1H59.1z"/>
        <path id="path2430" className="st1" d="M23.9,30.5l1.4-6.1H26l1.4,6.1h-0.9l-0.2-1.3h-1.1l-0.2,1.3H23.9z M25.2,28.4h0.8l-0.4-2.2
		L25.2,28.4z"/>
        <path id="path2432" className="st1" d="M30,30.5l-1.4-3.7v3.7h-0.9v-6.1h0.8l1.4,3.7v-3.7h0.9v6.1H30z"/>
        <path id="path2434" className="st1" d="M32.7,24.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V29c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4v-6.1H32.7L32.7,24.4z M33.3,29v-3.2c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.5h0.4
		C33.1,29.7,33.3,29.5,33.3,29L33.3,29z"/>
        <path id="path2436" className="st1" d="M35.6,30.5v-6.1H37l0,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.1,0.1,0.1,0.3,0.1,0.5v0.9v0.1c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.3,0.1-0.5,0.1H37
		h-0.4v2.4H35.6L35.6,30.5z M37.5,27.1c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2v-0.9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		h-0.5v2h0.4c0.1,0,0.2,0,0.3,0C37.3,27.3,37.4,27.2,37.5,27.1L37.5,27.1z"/>
        <path id="path2438" className="st1" d="M39,30.5v-6.1h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.6h-0.5v2.6H39L39,30.5z M40.9,27c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3v-0.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		h-0.6v1.9h0.6C40.6,27.2,40.8,27.1,40.9,27L40.9,27z"/>
        <path id="path2440" className="st1" d="M42.5,25c0.1-0.2,0.3-0.3,0.5-0.4s0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
		c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
		c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0s-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.2,0-0.4V26c0-0.2,0-0.3,0-0.5C42.4,25.3,42.4,25.2,42.5,25L42.5,25z M44.2,25.5
		c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.1c0,0,0,0,0,0.1
		c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.3s0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0s0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2V26
		c0-0.1,0-0.2,0-0.2C44.3,25.6,44.3,25.6,44.2,25.5L44.2,25.5z"/>
        <path id="path2442" className="st1" d="M47.2,24.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V29c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4v-6.1H47.2L47.2,24.4z M47.7,29v-3.2c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.5h0.4
		C47.5,29.7,47.7,29.5,47.7,29L47.7,29z"/>
        <path id="path2444" className="st1" d="M52,29.9c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.7h0.9
		v4.7c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4v-4.6h0.9
		v4.7c0,0.1,0,0.3,0,0.4C52,29.7,52,29.8,52,29.9L52,29.9z"/>
        <path id="path2446" className="st1" d="M55.6,29c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.1,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.1c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9v-0.3c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2
		c0.2,0,0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9L55.6,29z"/>
        <path id="path2448" className="st1" d="M56.8,30.5v-5.3h-1v-0.8h2.9v0.8h-1.1v5.3L56.8,30.5L56.8,30.5z"/>
        <path id="path2450" className="st1" d="M59.1,30.5v-6.1H60v6.1H59.1z"/>
        <path id="path2452" className="st1" d="M60.7,25c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2s0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.2,0-0.4V26c0-0.2,0-0.3,0-0.5C60.6,25.3,60.6,25.2,60.7,25L60.7,25z
		 M62.4,25.5c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.1
		c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.3s0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2V26c0-0.1,0-0.2,0-0.2C62.5,25.6,62.5,25.6,62.4,25.5L62.4,25.5z"/>
        <path id="path2454" className="st1" d="M66.2,30.5l-1.4-3.7v3.7H64v-6.1h0.8l1.4,3.7v-3.7h0.9v6.1H66.2z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-0-9-4-2-8">
        <path id="path2457" className="st1" d="M8.5,22V9.6l-2.1,1.6V8.9l2.1-1.5h2V22H8.5z"/>
        <path id="path2459" className="st1" d="M18.5,22h-6.2v-1.9l3.9-7.5c0.2-0.3,0.2-0.7,0.2-1.1v-1.1c0-0.3-0.1-0.5-0.3-0.7
		c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.3h-2.1v-1c0-0.6,0.2-1.3,0.6-2
		c0.4-0.8,1.3-1.2,2.6-1.2c0.8,0,1.5,0.3,2.1,0.9s0.9,1.4,0.9,2.2v1.4c0,0.6-0.1,1.2-0.5,1.8L14.8,20h3.7L18.5,22z"/>
      </g>
      <path id="path8194" className="st2" d="M34.4,50.8L28,52.9c-0.3,0.1-0.3,0.5-0.1,0.7l5.1,4.5c0.2,0.2,0.6,0.1,0.6-0.2l1.4-6.6
	C35,50.9,34.7,50.7,34.4,50.8L34.4,50.8z M48.4,36.5c-5.2,0-8.6,3.7-10.3,5.7c0,0,1.3,2.6,2,3c0,0,3.3-5.4,8.3-5.4
	c1.8,0,3.7,0.6,5.1,1.9c1.4,1.3,2.5,3.3,2.5,6.7c0,2-0.7,4-1.8,5.4c-1.1,1.4-2.7,2.3-4.9,2.3c-2.3,0-4.2-0.7-6-2.1
	c-1.8-1.4-3.5-3.7-5.1-6.8c-0.8-1.6-2.2-4.2-4.3-6.4s-5.1-4.2-9-4.2c-2.6,0-5.3,0.8-7.4,2.7c-2,1.9-3.4,5-3.4,8.9
	c0,6.6,5,11.1,10.8,11.1c4.1,0,6.9-2.5,6.9-2.5l-2.2-2.5c0,0-1.9,1.7-4.7,1.7c-4.1,0-7.5-2.7-7.4-7.7c0-3.3,1-5.2,2.3-6.5
	c1.4-1.3,3.2-1.9,5.1-1.9c2.8,0,4.8,1.3,6.5,3.2c1.7,1.8,2.9,4.1,3.7,5.7c1.8,3.4,3.7,6,6,7.8c2.3,1.9,5.1,2.8,8.1,2.8
	c3.2,0,5.7-1.4,7.5-3.5c1.7-2.1,2.6-4.8,2.6-7.5c0-4.1-1.5-7.3-3.7-9.2C53.6,37.2,50.9,36.5,48.4,36.5z"/>
    </svg>
  );
};

export default ResponsibleConsumptionProductionIcon;
