import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const GenderEqualityIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="genderEquality" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .gender-equality{fill - rule:evenodd;clip-rule:evenodd;fill:#E8432E;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2" className="gender-equality" d="M13.1,0.7h48.4c6.8,0,12.4,5.5,12.4,12.4v48.5c0,6.8-5.5,12.4-12.4,12.4H13.1
	C6.3,74,0.8,68.4,0.8,61.6V13.1C0.8,6.2,6.3,0.7,13.1,0.7z"/>
      <g id="text6065-3-2-9-6">
        <path id="path2005" className="st1" d="M25.3,12.8c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
		c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0-0.6V8.8c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.4,0.3-0.6
		c0.2-0.3,0.5-0.4,1.1-0.4c0.2,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3,0,0.5v0.2h-0.9V8.9
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.5,0.2c0,0.1-0.1,0.2-0.1,0.3
		c0,0,0,0.1,0,0.2v3.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.5,0.2s0.4,0,0.4-0.2c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0-0.1,0-0.1v-1.1H24V10h1.5v1.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2
		C25.4,12.6,25.4,12.7,25.3,12.8C25.3,12.7,25.3,12.7,25.3,12.8L25.3,12.8z"/>
        <path id="path2007" className="st1" d="M29,12.5v0.9h-2.7V7.2H29V8h-1.8v1.8h1.5v0.8h-1.5v1.8H29z"/>
        <path id="path2009" className="st1" d="M31.6,13.4l-1.4-3.8v3.8h-0.9V7.2h0.8l1.4,3.8V7.2h0.8v6.2H31.6z"/>
        <path id="path2011" className="st1" d="M34.4,7.2c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		s-0.6,0.4-1.1,0.4h-1.4V7.2H34.4z M35,11.9V8.7c0-0.2,0-0.3-0.2-0.4C34.7,8.1,34.6,8,34.4,8h-0.4v4.5h0.4
		C34.8,12.6,35,12.4,35,11.9L35,11.9z"/>
        <path id="path2013" className="st1" d="M39.2,12.5v0.9h-2.7V7.2h2.7V8h-1.8v1.8h1.5v0.8h-1.5v1.8H39.2z"/>
        <path id="path2015" className="st1" d="M39.6,13.4V7.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.7h-0.5v2.7H39.6L39.6,13.4z M41.6,9.8c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V8.7c0-0.2,0-0.3-0.1-0.4
		C41.5,8.1,41.3,8,41.2,8h-0.6v1.9h0.6C41.3,10,41.4,9.9,41.6,9.8L41.6,9.8z"/>
        <path id="path2017" className="st1"
          d="M25.2,21.1V22h-2.7v-6.2h2.7v0.8h-1.8v1.8H25v0.8h-1.5v1.8H25.2L25.2,21.1z"/>
        <path id="path2019" className="st1" d="M25.9,21.5c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.2,0-0.4,0-0.6v-3.1c0-0.4,0.1-0.8,0.4-1.1
		c0.3-0.3,0.6-0.4,1.1-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.2,0,0.3,0,0.4c0,0.1,0,0.2,0,0.2l0.6,0.5l-0.5,0.6
		l-0.5-0.4c-0.2,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.6-0.1C26.2,21.8,26,21.6,25.9,21.5L25.9,21.5z M27.4,20.1l0.3,0.2v-3.1
		c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.1,0.1
		c0,0,0,0.1,0,0.2v3.1v0c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.5,0.2c0,0,0.1,0,0.2,0L27,20.7L27.4,20.1z"/>
        <path id="path2021" className="st1" d="M32.1,21.3c-0.1,0.2-0.3,0.4-0.5,0.5C31.4,22,31.1,22,30.9,22c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.7h0.9
		v4.7c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4v-4.7h0.9
		v4.7c0,0.1,0,0.3,0,0.4C32.2,21.1,32.2,21.2,32.1,21.3L32.1,21.3z"/>
        <path id="path2023" className="st1" d="M32.5,22l1.4-6.2h0.7L36,22H35l-0.2-1.3h-1.1L33.4,22H32.5z M33.8,19.8h0.8l-0.4-2.2L33.8,19.8z
		"/>
        <path id="path2025" className="st1" d="M39.1,22h-2.6v-6.2h0.8v5.3h1.7L39.1,22L39.1,22z"/>
        <path id="path2027" className="st1" d="M39.4,22v-6.2h0.9V22H39.4z"/>
        <path id="path2029" className="st1" d="M41.7,22v-5.4h-1v-0.8h3v0.8h-1V22C42.6,22,41.7,22,41.7,22z"/>
        <path id="path2031" className="st1" d="M45,22v-2.7l-1.2-3.5h0.9l0.7,2.4l0.7-2.4h0.9l-1.2,3.5V22H45z"/>
      </g>
      <g id="text6075-0-6-9-2">
        <path id="path2034" className="st1" d="M12.7,12.2c0.3,0,0.7,0.1,1.1,0.3c0.4,0.2,0.6,0.4,0.8,0.7s0.3,0.6,0.4,0.9
		c0,0.3,0.1,0.6,0.1,0.9v3.3c0,0.3,0,0.6,0,0.8c0,0.2-0.1,0.5-0.2,0.9c-0.1,0.4-0.5,0.8-1.1,1.1c-0.5,0.3-1.1,0.5-1.8,0.5
		c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.5-1-0.9C9.2,20.2,9,19.9,9,19.5c-0.1-0.3-0.1-0.7-0.1-1.1V18H11v0.8c0,0,0,0.1,0,0.2
		c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.6-0.1,0.8-0.3c0.1-0.2,0.2-0.4,0.2-0.5
		c0-0.1,0-0.2,0-0.4v-3.1c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.2-0.2-0.3-0.2
		c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-0.8,0.2-1,0.8H9.1V6.8h6v2H11v4C11.5,12.4,12.1,12.2,12.7,12.2L12.7,12.2z"/>
      </g>
      <path id="rect7038" className="st2" d="M31.8,41.5v2.9h11.1v-2.9H31.8z M31.8,46.1v3.1h11.1v-3.1H31.8z M44.9,28.6v3.2h3.8l-4,4
	c-2.1-1.6-4.7-2.6-7.4-2.6c-6.6,0-11.9,5.3-11.9,11.9c0,6,4.4,11,10.4,11.8v3.5H32v3.5h3.8v3.5h3.5v-3.5h3.7v-3.5h-3.7v-3.6
	c5.8-0.9,10-5.9,10-11.8c0-2.5-0.7-4.8-2.2-6.9l4-4v3.5h3.3v-9.1H44.9z M37.2,36.5c4.8,0,8.6,3.9,8.6,8.6c0,4.8-3.9,8.7-8.6,8.7
	s-8.6-3.9-8.6-8.6l0,0C28.6,40.4,32.5,36.5,37.2,36.5z"/>
    </svg>
  );
};

export default GenderEqualityIcon;
