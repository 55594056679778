import SurfaceSealingIcon from 'assets/icons/mapping-icons/SurfaceSealingIcon';
import classNames from "classnames";
import MultilingualText from "components/Multilingual/MultilingualText";
import ThumbnailImage from "components/custom/ThumbnailImage";
import { GirthIcon } from 'components/svg';
import { UserContext } from "contexts/user/UserContextProvider";
import IntlMessages from "helpers/IntlMessages";
import { getPictureLocaleUrl } from "helpers/Utils";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import NavigationVariantIcon from "mdi-react/NavigationVariantIcon";
import { getOsmTagIconAndName, OsmTagType } from 'pages/Mapping/SelectPoi';
import { Fragment, SetStateAction, useContext } from "react";
import { Badge, BadgeProps, Card } from "react-bootstrap";
import { Link, RouteProps, useHistory, useLocation } from "react-router-dom";
import { Speciman } from "services/graphqltypes";

export interface SpecimanCardProp {
  speciman: Speciman;
  isCurrentUserMentor?: boolean;
  /** tag for specimen status */
  tag?: { show: boolean, colors: { [key: string]: string }, textOverride?: { [key: string]: string } };
  setShowInfoModal?: React.Dispatch<SetStateAction<boolean>>;
  setClickedSpecimenCard?: React.Dispatch<SetStateAction<Speciman | null>>;
  /** the url for goBack button in SpeciesDetailPage */
  goBackUrl?: RouteProps['location'];
  /** if true onClick of SpecimenCard opens editMapping */
  fromMapping?: boolean;
  /** if true show Open in map button, will open google map when clicked  */
  showOpenInMap?: boolean;
  /** if true show Navigation button, will redirect to full screen navigation page when clicked  */
  showNav?: boolean;
  /** for specimens that are imported and have no territoryId, we need to know territory slug to put into the url */
  territorySlug?: string;
  inBI?: boolean;
}

/** A single specimen card in list of specimens */
const SpecimenCard = (
  { speciman,
    tag = { show: false, colors: {} },
    isCurrentUserMentor,
    setShowInfoModal,
    setClickedSpecimenCard,
    goBackUrl,
    fromMapping,
    inBI,
    showOpenInMap,
    showNav,
    territorySlug,
  }: SpecimanCardProp): React.ReactElement => {
  const currentLocation = useLocation();
  const history = useHistory();
  const currentUser = useContext(UserContext)['currentUser'];
  const targetTerritorySlug = territorySlug || speciman.territory?.slug || speciman.territoriesByCoordinatesGeometry.nodes[0]?.slug;

  const currentUserHasAccess = (
    currentUser?.id === speciman.reviewer?.id // reviewer can access (when under review, only reviewer + author can access)
    || speciman.currentUserHasObserved // people who have observed + author can access (currentUserHasObserved return true for the creator of the specimen)
    || (!speciman.reviewer && isCurrentUserMentor) // mentors can access and pick it up for review
    || (speciman.status === 'APPROVED_PUBLISHED' && isCurrentUserMentor) // mentors can access all already published specimens
  );

  const picUrl = speciman.coverPictureUrl
    ? getPictureLocaleUrl(speciman.coverPictureUrl)
    : speciman.specimenAttachmentsBySpecimenId?.nodes[0]?.url
    ;

  const redirectPrepend = /^(\/common|\/ecoregion)/i.test(location.pathname) ? location.pathname ://if user is clicking SpecimenCard from commons/ecoregion page, redirect to commons/ecoregion specific SpecimenDetailPage/SpecimenNav url, 
    targetTerritorySlug ? `/commons/${targetTerritorySlug}` : '';  // or if specimen has a existing commons, redirect to commons specific url. otherwise use generic SpecimenDetailPage / SpecimenNav url

  return (

    <Card
      className={`h-100 ${classNames({ 'disabled-specimen': speciman.currentUserHasObserved ? false : !isCurrentUserMentor })}`}
    > {/* h-100 is needed for the card to be same height as other cards in the same row. */}
      <Link
        to={(location: any) => { /* if you know how to type this, please do it */

          if (fromMapping) {
            return { ...location, pathname: `/mapping/edit/${speciman.slug}`, state: { ...location.state, fromIntro: false }, hash: '' }; // keep passing location.state with updated location.state.fromIntro value
          } else if (inBI) {
            return { ...location, pathname: `/commons/${targetTerritorySlug}/specimen/${speciman.slug}`, state: { from: goBackUrl, speciman: speciman }, hash: '' }
          }
          else {
            // currentUser can only open the specimenCard if
            // [currentUser is the creator of the speciman ] || [currentUser is the reviewer for the speciman]
            //  || [the speciman doesn't have a reviewer and currentUser is a mentor]

            const nonMappingSpecimenUrl = tag.show
              ? `${redirectPrepend}/specimen/${speciman.slug}/review` : `${redirectPrepend}/specimen/${speciman.slug}`;// where will this card lead to: to review dashboard if it is my contribution; otherwise to the specimen page

            return currentUserHasAccess
              ? { ...location, pathname: nonMappingSpecimenUrl, state: { from: goBackUrl, speciman: speciman }, hash: '' }
              : { ...location, state: { from: goBackUrl, speciman: speciman }, hash: '' };
          }
        }}
        onClick={() => {
          if (setShowInfoModal) {
            setShowInfoModal(!currentUserHasAccess);
            setClickedSpecimenCard(speciman);
          }
        }}
        style={{ pointerEvents: !currentUserHasAccess ? 'none' : 'auto' }} // not clickable when not discovered
      >

        {/* cover picture url */}
        {picUrl && < div className="position-relative" >
          <Card.Img
            loading='lazy'
            src={picUrl}
            alt="Specimen"
            className="responsive border-0 card-img-top clickable"
            style={{ aspectRatio: '1/1', objectFit: 'cover', maxHeight: '100%' }}
          />
        </div>}

        {/* display tag */}
        {
          tag.show
          && <Badge
            variant={`${tag && tag.colors[speciman.status]}` as BadgeProps['variant']}
            pill
            className={`d-flex align-items-center position-absolute justify-content-center badge-top-left ${speciman.reviewer && 'pl-0 pt-0 pb-0'}`}
            style={{ height: '30px', minWidth: '8em' }}
          >
            {/* show reviewer avatar if there is one */}
            {speciman.reviewer &&
              <ThumbnailImage
                rounded
                // small
                src={speciman.reviewer.avatarUrl ? speciman.reviewer.avatarUrl : '/assets/img/profiles/l-1.jpg'}
                alt="reviewer avatar"
                className="mr-2 ml-0 img-thumbnail-badge"
              />}
            <IntlMessages id={tag.textOverride?.[speciman.status as string] ?? `bioregion.specimen.${speciman.status}`} />
          </Badge>
        }

        {
          speciman.isRestingInPeace
          && <Badge
            pill
            className={`d-flex align-items-center position-absolute justify-content-center ${tag.show ? 'badge-top-left-2' : 'badge-top-left'} badge-dark-gray`}
            style={{ height: '30px', minWidth: '8em' }}
          >
            RIP
          </Badge>
        }

        <Card.Body className="pb-xl-2 pb-md-3 pb-2 pt-xl-2 pt-md-3 pt-2 px-xl-2 px-md-3 px-1 ">
          <div className='d-flex mb-1'>
            <div className="font-weight-bold h4 flex-grow-1 mx-1 text-truncate onHoverYellow mb-0"
              style={{ maxWidth: '98%' }}>
              <MultilingualText value={speciman.name} />
            </div>
            {<div className='mx-1 mb-0' style={{ whiteSpace: 'nowrap', color: 'black' }}>
              {speciman.code}
            </div>}
          </div>

          {/* species name and scientific name */}
          <div className="ml-1 mt-1">

            {/* POI category (shows nothing for trees) */}
            <div className="d-flex flex-column">
              <div className='text-truncate w-100'>
                {speciman?.type === "POI" && getOsmTagIconAndName(speciman?.osmTags as OsmTagType, undefined, 'color-theme-1 ml-2').iconName}
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-md-center color-theme-1">

              <div className='text-truncate w-100'>
                {speciman?.species
                  ? <Fragment>
                    <MultilingualText value={speciman?.species?.name} />
                    <div className='d-none d-md-inline'>
                      &nbsp;&#8226;&nbsp;
                      {speciman?.species?.scientificName}
                    </div>
                  </Fragment>
                  : speciman?.type === 'POI'
                    ? <></>
                    : <IntlMessages id={'specimen.unknown-species'} />
                }
              </div>
              <i className='d-md-none d-block text-truncate w-100'>
                {speciman?.species?.scientificName}
              </i>
            </div>
          </div>

          {/* specimen location and girth */}
          <div className="ml-1 mt-1 d-flex flex-wrap">
            {/* specimen district*/}
            {speciman.district &&
              <div className="mr-3 align-self-center d-flex" style={{ minWidth: '0' }}>
                <div>
                  <MapMarkerIcon color="#60b6aa" size={15} className="mb-1 mr-1" style={{ marginLeft: '-0.1rem' }} />
                </div>
                <div className='text-truncate w-100' style={{ color: 'black' }}>
                  <MultilingualText value={speciman.district} />
                </div>
              </div>}
            {/* specimen girth */}
            {speciman?.type !== 'POI' &&
              <div className="align-self-center" style={{ color: 'black' }}>
                {/* girthCm ===0 can be treat as falsy value. */}
                <GirthIcon color="#60b6aa" size={12} className="mb-1 mr-1" />{speciman.girthCm || '?'}&nbsp;cm
              </div>
            }
            {/* specimen surfaceSealing */}
            {speciman.status === 'MAPPING_DRAFT' && speciman?.type !== 'POI' &&
              <div className="align-self-center" style={{ color: 'black' }}>
                {/* surfaceSealing can be 0, should not be treat as falsy value. */}
                <SurfaceSealingIcon size={15} className="mb-1 ml-3 mr-1" />{speciman.surfaceSealing === null ? '?' : speciman.surfaceSealing}&nbsp;%
              </div>
            }
          </div>

        </Card.Body>
      </Link>

      {/* "Open in map" button, show button if specified and coordinates information is available*/}
      {showOpenInMap && speciman.coordinates?.x && speciman.coordinates?.y &&
        <Card.Footer className='text-center'>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${speciman.coordinates.x}%2C${speciman.coordinates.y}`}
            className='onHoverGreen'
            target="_blank"  // open url in a new tab
            rel="noreferrer" // recommenced by lint for security reason
          >
            <IntlMessages id='location.open-in-map' />
          </a>
        </Card.Footer>
      }

      {/* "Navigate" button, shown if specified and coordinates information is available*/}
      {showNav && speciman.coordinates?.x && speciman.coordinates?.y &&
        <Card.Footer
          className='text-center d-flex justify-content-center clickable-button'
          onClick={() => {
            // For iOS devices, we need to request permission for device motion.
            // The .requestPermission() must be triggered by user action, that's why we do it already here
            if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
              (DeviceOrientationEvent as any).requestPermission().then(() => {
                return history.push({ pathname: `${redirectPrepend}/specimen/${speciman.slug}/nav`, state: { from: currentLocation.pathname } });
              })
            } else {
              history.push({ pathname: `${redirectPrepend}/specimen/${speciman.slug}/nav`, state: { from: currentLocation.pathname } });
            }
          }}
        >
          <NavigationVariantIcon color='#60b6aa' size='1.5rem' className='my-auto mr-2' />
          <div className='my-auto h3 color-theme-1'>
            <IntlMessages id='route.stop-navigate' />
          </div>
        </Card.Footer>
      }

    </Card >
  );
};

export default SpecimenCard;