import CustomSpinner from 'components/common/CustomSpinner';
import IntlMessages from 'helpers/IntlMessages';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { SpecimenSpeciesStatus } from 'services/graphqltypes';

interface BottomPanelCardProps {
  displaySpinner: boolean;
  setDisplaySpinner: Dispatch<SetStateAction<boolean>>;
  clickedSubmit: boolean;
  setClickedSubmit: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  previous?: Function;
  cancel?: Function;
  setClickedSubmitForReview?: Dispatch<SetStateAction<boolean>>;
  clickedPublish?: boolean;
  setClickedPublish?: Dispatch<SetStateAction<any>>;
  setClickedDone?: Dispatch<SetStateAction<any>>;
  isDirty?: boolean;
  setIsDirty?: Dispatch<SetStateAction<any>>;
  isPublished?: boolean;
  enableNextButton?: boolean;
  enableDoneButton?: boolean;
  setClickedSaveToDraft?: Dispatch<SetStateAction<boolean>>
  status?: SpecimenSpeciesStatus;
  hidePublishedButton?: boolean;
}

const BottomPanelCard = ({
  displaySpinner,
  setDisplaySpinner,
  clickedSubmit,
  setClickedSubmit,
  isValid,
  previous,
  clickedPublish,
  setClickedPublish,
  setClickedDone,
  setClickedSubmitForReview,
  cancel,
  isDirty,
  setIsDirty,
  isPublished,
  status,
  enableDoneButton,
  enableNextButton = true,
  setClickedSaveToDraft,
  hidePublishedButton,
}: BottomPanelCardProps): React.ReactElement => {


  return (
    <div className='form-actions'>
      <div className="card bg-light fixed-bottom" style={{ borderRadius: '0' }}>
        <div className="row no-gutters flex-nowrap w-lg-80 mx-md-auto" style={{ overflowX: 'auto' }}>
          { /* Cancel button */}
          {cancel &&
            <div className='col-auto px-2 py-2 mx-auto d-flex'>
              <Button
                className="mx-auto btn btn-danger btn-lg"
                disabled={false}
                variant="outline-danger"
                onClick={() => cancel()}
              >
                <IntlMessages id="general.cancel" />
              </Button>
            </div>
          }
          { /* Previous button */}
          {previous &&
            <div className='col-auto px-2 py-2 mx-auto d-flex'>
              <Button
                className="mx-auto btn btn-outline-primary btn-lg"
                disabled={false}
                variant="outline-primary"
                onClick={() => previous()}
              >
                <IntlMessages id="general.previous" />
              </Button>
            </div>
          }

          {/* Next button*/}
          {enableNextButton &&
            <div className='col-auto px-2 py-2 mx-auto d-flex'>
              <Button
                className={`mx-auto btn btn-primary btn-lg ${displaySpinner ? 'disabled' : ''}`}
                disabled={!clickedSubmit ? false : !isValid}
                type="submit"
                onClick={() => {
                  setIsDirty && setIsDirty(isDirty);
                  setClickedSubmit(true);
                  setDisplaySpinner(true);
                }
                }
              >
                {(displaySpinner && isValid) ?
                  <CustomSpinner />
                  :
                  <IntlMessages id="activity.form.next" />
                }
              </Button>
            </div>
          }

          {/* Done button*/}
          {enableDoneButton &&
            <div className='col-auto px-2 py-2 mx-auto d-flex'>
              <Button
                className={`mx-auto btn btn-primary btn-lg ${displaySpinner ? 'disabled' : ''}`}
                disabled={!clickedSubmit ? false : !isValid}
                type="submit"
                onClick={() => {
                  setIsDirty && setIsDirty(isDirty);
                  setClickedDone && setClickedDone(true);
                  setDisplaySpinner(true);
                  setClickedSubmit(true);// set clickedSubmit to true to show yup error message if there is any, meanwhile disable disable DoneButton and PublishButton together if  form value is not validated
                }
                }
              >
                {(displaySpinner && isValid) ?
                  <CustomSpinner />
                  :
                  <IntlMessages id="activity.form.done" />
                }
              </Button>
            </div>
          }

          {/* Save draft button for **specimen** */}
          {/* only show for drafts */}
          {setClickedSaveToDraft
            && ['DRAFT', 'TO_REVIEW', 'MAPPING_DRAFT'].includes(status)
            && <div className='col-auto px-2 py-2 mx-auto d-flex'>
              <Button
                className={`mx-auto btn btn-primary btn-lg ${displaySpinner ? 'disabled' : ''}`}
                disabled={!clickedSubmit ? false : !isValid}
                type="submit"
                onClick={() => {
                  setIsDirty && setIsDirty(isDirty);
                  setClickedSubmit(true);
                  setDisplaySpinner(true);
                  setClickedSaveToDraft(true);
                }
                }
              >
                {(displaySpinner && isValid) ?
                  <CustomSpinner />
                  :
                  <IntlMessages id="bioregion.form.save-as-draft" />
                }
              </Button>
            </div>
          }

          { /* Publish button for everything besides specimen*/}
          {(clickedPublish || setClickedPublish) && !(isPublished && hidePublishedButton) &&
            <div className='col-auto px-2 py-2 mx-auto d-flex'>
              <Button
                className="mx-auto btn btn-yellow btn-lg"
                disabled={(!clickedSubmit ? false : !isValid) || isPublished}
                type="submit"
                onClick={() => {
                  setIsDirty && setIsDirty(isDirty);
                  setClickedSubmit(true);
                  setClickedPublish(true);
                }
                }
              >
                {!isPublished ? <IntlMessages id="library.button.publish" /> : <IntlMessages id="general.published" />}
              </Button>
            </div>}

          { /*  Submit for review button for **specimen** */}
          {setClickedSubmitForReview
            && !['APPROVED_PUBLISHED', 'UNDER_REVIEW'].includes(status) /* already published specimen can't be sent for review */
            && <div className='col-auto px-2 py-2 mx-auto d-flex'>
              <Button
                className="mx-auto btn btn-yellow btn-lg"
                disabled={(!clickedSubmit ? false : !isValid) // disable if submitted and form is not valid
                  || status === 'UNDER_REVIEW'}
                type="submit"
                onClick={() => {
                  setIsDirty && setIsDirty(isDirty);
                  setClickedSubmit(true);
                  setClickedSubmitForReview(true);
                }
                }
              >
                {/* the logic need to change to isSubmitForReview or something similar after database is done */}
                {['DRAFT', 'MAPPING_DRAFT'].includes(status) && <IntlMessages id="bioregion.form.submit-for-review" />}
                {(status === 'TO_REVIEW' || status === 'NEEDS_IMPROVEMENT') && <IntlMessages id="bioregion.form.resubmit-for-review" />}
                {status === 'UNDER_REVIEW' && <IntlMessages id="bioregion.form.under-review" />}
              </Button>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default BottomPanelCard;
