import { gql } from '@apollo/client';
import { apolloClient } from './graphql';
import { USER_PROFILE_FRAGMENT } from './user';

const LOGOUT = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

export const LOGIN = gql`
  ${USER_PROFILE_FRAGMENT}
  mutation Login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      user {
        ...userProfileFragment
      }
    }
  }
`;

const FORGOT = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      clientMutationId
    }
  }
`;

const CHANGEPW = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(input: { oldPassword: $oldPassword, newPassword: $newPassword }) {
      success
    }
  }
`;

const RESETPASSWORD = gql`
  mutation ResetPassword($userId: UUID!, $token: String!, $password: String!) {
    resetPassword(input: { userId: $userId, resetToken: $token, newPassword: $password }) {
      success
    }
  }
`;

export const CURRENT_USER = gql`
  ${USER_PROFILE_FRAGMENT}
  query currentUser {
    currentUserGeoIPLocation {
      latitude
      longitude
    }
    currentUser {
      ...userProfileFragment
    }
    currentUserStreamToken
  }
`;

export const REGISTER = gql`
  ${USER_PROFILE_FRAGMENT}
  mutation Register($username: String!, $password: String!, $email: String!, $name: String) {
    register(input: { username: $username, password: $password, email: $email, name: $name }) {
      user {
        ...userProfileFragment
      }
    }
  }
`;

export async function logoutMutation(): Promise<string> {
  const userLogout = await apolloClient.mutate({
    mutation: LOGOUT,
  });
  console.log('[user is logged out]', userLogout);

  return (userLogout as any).data.logout.success;
}

export async function forgotPassword(email: string): Promise<string> {
  const result = await apolloClient.mutate({
    mutation: FORGOT,
    variables: {
      email,
    },
  });
  return result.data.forgotPassword.clientMutationId;
}

export async function changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
  const result = await apolloClient.mutate({
    mutation: CHANGEPW,
    variables: {
      oldPassword,
      newPassword,
    },
  });
  if (result.data.changePassword.success) {
    logoutMutation();
    return true;
  } else {
    return false;
  }
}

export async function resetPassword(userId: string, token: string, password: string): Promise<boolean> {
  const result = await apolloClient.mutate({
    mutation: RESETPASSWORD,
    variables: {
      userId,
      token,
      password,
    },
  });
  return result.data.resetPassword.success;
}
