import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const LifeBelowWaterIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="lifeBelowWater" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
           .life-below-water{fill - rule:evenodd;clip-rule:evenodd;fill:#087FBE;}
           .st1{fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3-6-0-4-3-6-3-7" className="life-below-water" d="M61.7,1.3c6.7,0,12.2,5.5,12.2,12.2v48c0,6.7-5.5,12.2-12.2,12.2H13.8
	C7,73.7,1.6,68.3,1.6,61.5v-48C1.6,6.7,7,1.3,13.8,1.3C13.8,1.3,61.7,1.3,61.7,1.3z"/>
      <g id="text6065-3-2-9-6-6-5-5-2">
        <path id="path2494" className="st1" d="M27.8,13.7h-2.6V7.6h0.9v5.2h1.7V13.7z"/>
        <path id="path2496" className="st1" d="M28.1,13.7V7.6H29v6.1H28.1z"/>
        <path id="path2498" className="st1"
          d="M29.7,13.7V7.6h2.6v0.8h-1.8v1.9h1.6v0.8h-1.5v2.6L29.7,13.7L29.7,13.7z"/>
        <path id="path2500" className="st1"
          d="M35.2,12.8v0.9h-2.6V7.6h2.6v0.8h-1.8v1.8H35V11h-1.5v1.8L35.2,12.8L35.2,12.8z"/>
        <path id="path2502" className="st1" d="M26.6,16.1c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.2,0.2,0.3,0.5,0.3,0.9V18
		c0,0.5-0.2,0.8-0.5,1c0.4,0.2,0.5,0.5,0.5,1v0.7c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4v-6.2H26.6z M27.2,17.9v-0.4
		c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2h-0.5v1.7h0.5c0.2,0,0.3,0,0.4-0.2C27.1,18.3,27.2,18.1,27.2,17.9L27.2,17.9z M27.2,20.7
		v-0.7c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2h-0.5v2h0.5c0.2,0,0.3,0,0.4-0.2C27.1,21,27.2,20.9,27.2,20.7L27.2,20.7z"/>
        <path id="path2504" className="st1" d="M31.5,21.3v0.9h-2.6v-6.1h2.6v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8H31.5z"/>
        <path id="path2506" className="st1" d="M34.5,22.2h-2.6v-6.1h0.9v5.2h1.7V22.2z"/>
        <path id="path2508" className="st1" d="M34.8,16.6c0.1-0.2,0.3-0.3,0.5-0.4s0.4-0.2,0.7-0.2s0.4,0,0.6,0.1s0.4,0.2,0.5,0.4
		c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.1c0,0.2,0,0.3,0,0.5s-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
		c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.3,0-0.5v-3.1c0-0.2,0-0.3,0-0.5C34.7,16.9,34.8,16.8,34.8,16.6L34.8,16.6z M36.5,17.1
		c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.1c0,0,0,0,0,0.1
		s0,0,0,0.1s0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2
		v-3.1c0-0.1,0-0.2,0-0.2C36.6,17.2,36.6,17.2,36.5,17.1L36.5,17.1z"/>
        <path id="path2510" className="st1" d="M41.1,22.2l-0.7-3.7l-0.7,3.7h-0.8L38,16.1h0.9l0.4,3.7l0.7-3.7h0.6l0.7,3.7l0.4-3.7h0.9
		l-0.9,6.1H41.1z"/>
        <path id="path2512" className="st1" d="M47,22.2l-0.7-3.8l-0.7,3.7h-0.8L43.9,16h0.9l0.4,3.7l0.7-3.7h0.6l0.7,3.7l0.4-3.7h0.9l-0.9,6.1
		L47,22.2L47,22.2z"/>
        <path id="path2514" className="st1" d="M48.5,22.2l1.4-6.1h0.7l1.4,6.1H51l-0.2-1.3h-1.1l-0.2,1.3H48.5z M49.9,20h0.8l-0.4-2.2L49.9,20
		z"/>
        <path id="path2516" className="st1" d="M52.7,22.2v-5.3h-1V16h2.9v0.8h-1v5.3H52.7z"/>
        <path id="path2518" className="st1" d="M57.8,21.3v0.9h-2.6v-6.1h2.6v0.8H56v1.8h1.5v0.8H56v1.8H57.8z"/>
        <path id="path2520" className="st1" d="M58.1,22.2v-6.1h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3
		l0.9,2.8h-0.9l-0.8-2.6h-0.5v2.6H58.1L58.1,22.2z M60,18.6c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3v-0.5c0-0.2,0-0.3-0.1-0.4
		s-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C59.8,18.8,59.9,18.7,60,18.6L60,18.6z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-1-4">
        <path id="path2523" className="st1"
          d="M8.7,22.2V9.8l-2.1,1.6V9.1l2.1-1.5h2.1v14.6C10.8,22.2,8.7,22.2,8.7,22.2z"/>
        <path id="path2525" className="st1"
          d="M16.3,22.2V20h-4.2v-2l3-10.5h2.2L14.3,18h2.1v-4.2h2.1V18h1v2h-1v2.2H16.3z"/>
      </g>
      <path id="path8226-6-0" className="st1" d="M42,45.7c-4.9,0.1-9.8,2.7-14.6,6.3c-2.1-3.6-4.9-6.2-9.3-6.3c4.3,6.3,3.4,12,0,17.2
	c4.4,0,7.2-2.6,9.3-6.3c9.6,7.1,19.4,10.5,29.7-2.3C52,48,46.9,45.6,42,45.7z M46.8,50.7c1.3,0,2.3,1.1,2.3,2.3l0,0
	c0,1.3-1.1,2.3-2.3,2.3c-1.3,0-2.3-1.1-2.3-2.3C44.4,51.8,45.5,50.7,46.8,50.7z M56.8,37.5l0.1,2C53.3,42,50.5,41,48,39
	c-3,2.2-6.4,2.9-10.5-0.1c-3,1.9-6.1,3.5-10.7,0c-2.4,1.9-4.8,3.7-8.6,0.7l-0.1-2.1c3.5,2.1,6.4,0.9,9-1.2c3.5,2.4,7,3.1,10.4,0.1
	c3.9,3.6,7.2,2.1,10.5-0.1C50.3,37.9,52.5,39.8,56.8,37.5L56.8,37.5z M56.8,31.5l0.1,2C53.3,36,50.5,35,48,33
	c-3,2.2-6.4,2.9-10.5-0.1c-3,1.9-6.1,3.5-10.7,0c-2.4,1.9-4.8,3.7-8.6,0.7l-0.1-2.1c3.5,2.1,6.4,0.9,9-1.2c3.5,2.4,7,3.1,10.4,0.1
	c3.9,3.6,7.2,2.1,10.5-0.1C50.3,31.9,52.5,33.8,56.8,31.5L56.8,31.5z"/>
    </svg>
  );
};

export default LifeBelowWaterIcon;
