import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
// #1 import quill-image-uploader
import ImageUploader from 'quill-image-uploader';
import { uploadAttachPicture } from '../../services/imageCropUpload';

// #2 register module
Quill.register('modules/imageUploader', ImageUploader);

class Editor extends Component {
  constructor(props) {
    super(props);
    this.updating = false;
    this.stackedContent = {
      delta: null,
    };
    this.state = {
      blobs: {},
    };
    this.value = props.value;
    this.formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image'
    ];
    // upload function
    this.modules = {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean']
      ],

      imageUploader: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            // postPicture url not able to upload on create new event
            uploadAttachPicture(file)
              .then((result) => {
                console.log(result);
                resolve(result);
              })
              .catch((error) => {
                reject('Upload failed');
                console.error('Error:', error);
              });
          });
        }
      }
    };
    this.handleChange = this.handleChange.bind(this);
  }
  addImgStyle() {
    const imgList = document.querySelectorAll('.ql-editor img');
    if (imgList.length > 0) {
      imgList.forEach((img) => {
        img.className = 'figure-img img-fluid img-thumbnail';
      });
    }
  }
  handleChange(content, delta, source, editor) {
    this.stackedContent = { delta };
    this.value = editor.getHTML();
    if (this.updating === false) {
      this.updating = true;
      setTimeout(() => {
        this.updating = false;
        const { delta } = this.stackedContent;
        if (delta !== null && delta.ops.some((ele) => ele.insert && ele.insert.image)) {
          this.addImgStyle();
        }
        this.props.form.setFieldValue(this.props.field.name, this.value);
      }, 500);
    }
  }

  render() {
    return (
      /* data-testid is used in plawright e2e tests to locate the editor */
      <div data-testid={this.props.field.name} className={this.props.className} onBlur={this.props.handleBlur}> { /* no luck with letting quill handle onBlur -> wrapping it in a div, so formik field is marked as 'touched' */}
        <ReactQuill
          theme="snow"
          modules={this.modules}
          formats={this.formats}
          value={this.value || ''}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

Editor.propTypes = {
  value: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  handleBlur: PropTypes.func,
  className: PropTypes.string,
};

export default Editor;
