import React from 'react';
import IconProps from './IconProps';

const MentoringParentingIcon = ({ style, color = '#e8501d', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="mentoringParenting" data-name="mentoringParenting" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
      style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <g>
          <g>
            <path id="Mentoring4_3_" d="M864.2,928.7H515.1V705.4c0-13.2,10.7-23.9,23.9-23.9s23.9,10.7,23.9,23.9v175.6h253.4
				V705.3c0-13.2,10.7-23.9,23.9-23.9s23.9,10.7,23.9,23.9V928.7z"/>
          </g>
        </g>
        <g>
          <g>
            <path id="Mentoring1_3_" d="M318.2,332.8c-72.1,0-130.8-58.7-130.8-130.8c0-72.1,58.7-130.8,130.8-130.8
				c72.1,0,130.8,58.7,130.8,130.8C449,274.2,390.3,332.8,318.2,332.8z M318.2,119.1c-45.7,0-82.9,37.2-82.9,83
				c0,45.7,37.2,82.9,82.9,82.9c45.8,0,83-37.2,83-82.9C401.1,156.3,363.9,119.1,318.2,119.1z"/>
          </g>
        </g>
        <g>
          <g>
            <path id="Mentoring3_3_" d="M672.3,383.2c-72.1,0-130.8-58.7-130.8-130.8c0-72.1,58.7-130.8,130.8-130.8
				c72.1,0,130.8,58.7,130.8,130.8C803.1,324.6,744.5,383.2,672.3,383.2z M672.3,169.5c-45.7,0-82.9,37.2-82.9,83
				c0,45.7,37.2,82.9,82.9,82.9c45.8,0,83-37.2,83-82.9C755.3,206.7,718.1,169.5,672.3,169.5z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path id="Mentoring2_3_" d="M1000,743v113.1c0,13.4-10.5,23.9-23.9,23.9c-13.2,0-23.9-10.5-23.9-23.9V743
					c0-71.8-28-139.2-78.7-189.9c-0.5-0.5-0.7-0.7-1.2-1c-121.2,34.7-160.5,40.7-295.9,30.4l-2.6-0.2l-81.1-15.8v248.7H143.5V591.9
					c0-13.2,10.8-23.9,23.9-23.9c13.4,0,23.9,10.8,23.9,23.9v175.6h253.5V508.6l136.6,26.3c131.8,9.8,162.2,4.5,288.5-31.8
					c24.2-6.9,34.4-14.8,40.7-20.8c10.3-10,11.7-23.2,10.7-32.3c-0.7-9.3-4.1-18.9-9.3-27l-22,5.5c-17.9,4.5-35.9,8.8-52.9,12.9
					c-101.9,24.4-205.5,29.2-308.1,13.9c-42.1-6.2-79.4-13.6-112.2-20.1c-43-8.6-77-15.3-100.5-15.3c-74.4,0-143.5,29.9-194.9,84
					c-47.4,50-73.7,118.9-73.7,193.7v104.3c0,13.2-10.5,23.9-23.9,23.9C10.8,825.8,0,815,0,801.9V697.6
					c0-87.3,30.9-167.7,86.8-226.7c60.5-63.6,142.1-98.8,229.6-98.8c28.2,0,64.1,7.2,109.8,16.3c32.3,6.2,68.9,13.6,110,19.6
					c96.4,14.4,193.7,10,289.7-12.9c17-4.1,34.7-8.6,52.4-12.9l50.5-12.4l9.6,9.8c17.5,17.7,28.7,42.1,30.9,66.5
					c2.2,27.5-6.7,52.6-25.1,70.6c-6.9,6.7-14.3,12.4-23.2,17.2C972.3,591.4,1000,665.3,1000,743z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MentoringParentingIcon;
