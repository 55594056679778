import { useState } from 'react';
import type { MouseEventHandler, PropsWithChildren } from 'react';
import { ChannelList, ChannelListProps, ChannelPreviewUIComponentProps, DefaultStreamChatGenerics } from 'stream-chat-react';
import MessagingChannelPreview from './MessagingChannelPreview';
import MeIcon from 'assets/icons/menuIcon/me.svg?react';
import CommunitiesIcon from 'assets/icons/menuIcon/communities.svg?react';

type MessagingSidebarProps = {
  initialChannelListOptions: {
    filters: ChannelListProps['filters'];
    sort: ChannelListProps['sort'];
    options: ChannelListProps['options'];
  };
  onClick: MouseEventHandler;
  onCreateChannel?: () => void;
  onPreviewSelect?: MouseEventHandler;
  channelId: string; // active channel id
  currentUserId: string;
};

const MessagingSidebar = ({
  initialChannelListOptions,
  onClick,
  channelId,
  currentUserId,
  // onCreateChannel,
  // onPreviewSelect,
}: MessagingSidebarProps) => {
  const [channelListOptions, setChannelListOptions] = useState(initialChannelListOptions);
  const [activeCategory, setActiveCategory] = useState<'messaging' | 'community'>('messaging');

  const handleCategoryChange = (category: 'messaging' | 'community') => {
    setActiveCategory(category);
    setChannelListOptions(prev => ({
      ...prev,
      filters: {
        type: category,
        members: { $in: [currentUserId] },
      },
    }));
  };

  const buttonStyle = {
    flex: 1,
    border: 'none',
    borderBottom: '1px solid #60b6aa',
    borderRadius: 0,
    padding: '10px',
    backgroundColor: 'transparent',
    // color: '#333',
    cursor: 'pointer',
    outline: 'none',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: 'white',
    borderBottom: '1px solid #60b6aa',
  };


  return (
    <div
      className='str-chat messaging__sidebar str-chat__theme-light'
      id='mobile-channel-list'
      role="complementary"
      aria-label="Chat channels"
      onClick={onClick}
    >
      <div style={{ display: 'flex' }}>
        <button
          style={activeCategory === 'messaging' ? activeButtonStyle : buttonStyle}
          onClick={() => handleCategoryChange('messaging')}
        >
          <MeIcon fill='#60b6aa' width="1.25rem" />
        </button>
        <div style={{ width: '1px', backgroundColor: '#60b6aa' }}></div>
        <button
          style={activeCategory === 'community' ? activeButtonStyle : buttonStyle}
          onClick={() => handleCategoryChange('community')}
        >
          <CommunitiesIcon fill='#60b6aa' width="1.25rem" />
        </button>
      </div>
      <ChannelList
        {...channelListOptions}
        customActiveChannel={channelId}
        Preview={(props: PropsWithChildren<ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>>) => <MessagingChannelPreview {...props} />}
      />
    </div>
  );
};

export default MessagingSidebar;