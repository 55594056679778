/*                                                                                                        */
/* A hook to get user development badge related info, which includes:                                     */
/*  obtainedBadge: { name: translated string, icon: src, level: number,                                   */
/*                   upcomingMessage: translated string }                                                 */
/*  nextBadge: { name: translated string, icon: src, level: number }                                      */
/*                                                                                                        */
import AmethystDevelopmentBadge from 'assets/icons/developmentBadges/amethyst.svg';
import CopperDevelopmentBadge from 'assets/icons/developmentBadges/copper.svg';
import MalachiteDevelopmentBadge from 'assets/icons/developmentBadges/malachite.svg';
import QuartzDevelopmentBadge from 'assets/icons/developmentBadges/quartz.svg';
import TopazDevelopmentBadge from 'assets/icons/developmentBadges/topaz.svg';
import TurquoiseDevelopmentBadge from 'assets/icons/developmentBadges/turquoise.svg';
import ZirconDevelopmentBadge from 'assets/icons/developmentBadges/zircon.svg';
import LeavesIcon from 'components/svg/LeavesIcon';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

const useDevelopmentBadge = (totalDevelopmentCredits: number) => {

  const intl = useIntl();

  const badges = {
    level0: {
      badgeName: '',
      badgeSrc: '',
      badgeThreshold: 0,
    },
    level1: {
      badgeName: intl.formatMessage({ id: 'badge.copper' }),
      badgeSrc: CopperDevelopmentBadge,
      badgeThreshold: 100,
    },
    level2: {
      badgeName: intl.formatMessage({ id: 'badge.malachite' }),
      badgeSrc: MalachiteDevelopmentBadge,
      badgeThreshold: 500,
    },
    level3: {
      badgeName: intl.formatMessage({ id: 'badge.amthyst' }),
      badgeSrc: AmethystDevelopmentBadge,
      badgeThreshold: 1000,
    },
    level4: {
      badgeName: intl.formatMessage({ id: 'badge.quartz' }),
      badgeSrc: QuartzDevelopmentBadge,
      badgeThreshold: 3000,
    },
    level5: {
      badgeName: intl.formatMessage({ id: 'badge.topaz' }),
      badgeSrc: TopazDevelopmentBadge,
      badgeThreshold: 5000,
    },
    level6: {
      badgeName: intl.formatMessage({ id: 'badge.zircon' }),
      badgeSrc: ZirconDevelopmentBadge,
      badgeThreshold: 7000,
    },
    level7: {
      badgeName: intl.formatMessage({ id: 'badge.turquoise' }),
      badgeSrc: TurquoiseDevelopmentBadge,
      badgeThreshold: 10000,
    }
  };

  const obtainedBadge = { badgeName: '', badgeSrc: '', level: 0, };
  let upcomingMessage = null as ReactNode;
  const updateObtainedBadge = (level: number) => {
    obtainedBadge.badgeName = badges[`level${level}`].badgeName;
    obtainedBadge.badgeSrc = badges[`level${level}`].badgeSrc;
    obtainedBadge.level = level;
    upcomingMessage = level < 7 && intl.formatMessage(
      { id: 'badge.development-upcomingLevel-message' },
      {
        creditsWithUnits: <span>{badges[`level${level + 1}`].badgeThreshold - totalDevelopmentCredits}<LeavesIcon color='grey' size={20} className='mb-2 onHoverGreen' /></span>,
        nextBadge: badges[`level${level + 1}`].badgeName
      }
    );
  };


  switch (true) {
    case (totalDevelopmentCredits >= badges.level7.badgeThreshold):
      updateObtainedBadge(7);
      break;
    case (totalDevelopmentCredits >= badges.level6.badgeThreshold):
      updateObtainedBadge(6);
      break;
    case (totalDevelopmentCredits >= badges.level5.badgeThreshold):
      updateObtainedBadge(5);
      break;
    case (totalDevelopmentCredits >= badges.level4.badgeThreshold):
      updateObtainedBadge(4);
      break;
    case (totalDevelopmentCredits >= badges.level3.badgeThreshold):
      updateObtainedBadge(3);
      break;
    case (totalDevelopmentCredits >= badges.level2.badgeThreshold):
      updateObtainedBadge(2);
      break;
    case (totalDevelopmentCredits >= badges.level1.badgeThreshold):
      updateObtainedBadge(1);
      break;
    default:
      updateObtainedBadge(0);
  }


  return {
    obtainedBadge,
    nextBadge: { ...badges[`level${obtainedBadge.level + 1}`], upcomingMessage },
  };
};

export default useDevelopmentBadge;
