import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { EventCourse, Ticket } from 'services/graphqltypes';

// load course sessions tickets
const COURSE_SESSION_TICKETS = gql`
    query courseSessionsTickets($courseId: UUID!) {
    eventCourse(id: $courseId) {
      eventCoursesByCourseId(orderBy: START_TIME_ASC) {
        nodes {
          id
          name
          startTime
          locationName
          locationAddress
          locationCoordinates { x, y }
          locationGeojson
          tickets(orderBy: POSITION_ASC, filter: {isRedeem: {distinctFrom: true}, isSessionHelper: {distinctFrom: true}}) {
            nodes {
              id
              name
              priceAmount
              eventCourse{
                name
                id
              }
              currencyByCurrencyCode {
                id
                code
              }
              isParticipant
              isRedeem
              hasName
              position
              description
              availableAmount
              remainingAmount
              bookingTickets {
              nodes {
                id
                booking {
                  id
                  code
                  orderState
                  totalPrice
                }
                amount
              }
            }
            }
          }
        }
      }
    }
  }
  `;

type useCourseSessionTicketsProps = {
    courseId: string;
}

const useCourseSessionTickets = ({ courseId } : useCourseSessionTicketsProps) =>{
  const { loading, data, error } = useQuery(COURSE_SESSION_TICKETS, {
    variables: {
      courseId: courseId
    },
    skip: !courseId,
  });

  const { courseSessionTickets, sessionTicketsData } = React.useMemo(() => {
    if (loading || error ||  !data?.eventCourse?.eventCoursesByCourseId?.nodes) {
      return  { courseSessionTickets: [], sessionTicketsData: [] };
    }

    const sessionTickets: Ticket[] = [];
        data?.eventCourse?.eventCoursesByCourseId?.nodes?.forEach((eventCourse: EventCourse)=>{
            eventCourse?.tickets?.nodes?.forEach((ticket: Ticket)=>{
              sessionTickets.push({ ...ticket });
            });
        });

        return  {
          courseSessionTickets: sessionTickets,
          sessionTicketsData: data?.eventCourse?.eventCoursesByCourseId?.nodes,
        };
  }, [data, error, loading]);

  return { loading, error, courseSessionTickets, sessionTicketsData };
};

export default useCourseSessionTickets;
