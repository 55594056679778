import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const AffordableCleanEnergyIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="AffordableCleanEnergy" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .affordable-clean-energy{fill - rule:evenodd;clip-rule:evenodd;fill:#FBB824;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
         `}
      </style>
      <path id="rect8528-63-2-4-3-6-0-4-3-6" className="affordable-clean-energy" d="M13.4,0.7h48.7c6.9,0,12.5,5.6,12.5,12.5V62c0,6.9-5.6,12.5-12.5,12.5H13.4
	C6.5,74.5,0.9,68.9,0.9,62V13.2C0.9,6.3,6.5,0.7,13.4,0.7z"/>
      <g id="text6065-3-2-9-6-6-5">
        <path id="path2087" className="st1" d="M21.8,13.5l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3H23l-0.2,1.3H21.8z M23.1,11.3H24L23.6,9
		L23.1,11.3z"/>
        <path id="path2089" className="st1"
          d="M25.7,13.5V7.2h2.6v0.8h-1.8V10h1.5v0.8h-1.5v2.7L25.7,13.5L25.7,13.5z"/>
        <path id="path2091" className="st1"
          d="M28.8,13.5V7.2h2.6v0.8h-1.8V10h1.5v0.8h-1.5v2.7L28.8,13.5L28.8,13.5z"/>
        <path id="path2093" className="st1" d="M31.9,7.8c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5s-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.2,0-0.4V8.8c0-0.2,0-0.3,0-0.5C31.7,8.1,31.8,7.9,31.9,7.8z M33.6,8.3
		C33.5,8.1,33.3,8,33.1,8c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2V12c0,0,0,0,0,0.1
		c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2V8.8c0-0.1,0-0.2,0-0.2C33.7,8.4,33.7,8.4,33.6,8.3L33.6,8.3z"/>
        <path id="path2095" className="st1" d="M35.4,13.5V7.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.7h-0.5v2.7H35.4z M37.4,9.8c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V8.6c0-0.2,0-0.3-0.1-0.4C37.3,8.1,37.1,8,36.9,8h-0.6
		v1.9h0.6C37.1,10,37.2,9.9,37.4,9.8L37.4,9.8z"/>
        <path id="path2097" className="st1" d="M40.3,7.2c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.2H40.3L40.3,7.2z M40.9,11.9V8.7c0-0.2,0-0.3-0.2-0.4C40.6,8.1,40.5,8,40.3,8h-0.4v4.6h0.4
		C40.7,12.6,40.9,12.4,40.9,11.9z"/>
        <path id="path2099" className="st1" d="M42.2,13.5l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1l-0.2,1.3H42.2z M43.6,11.3h0.8L44,9
		L43.6,11.3z"/>
        <path id="path2101" className="st1" d="M47.7,7.2c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.2,0.2,0.3,0.5,0.3,0.9v0.5
		c0,0.5-0.2,0.8-0.5,1c0.4,0.2,0.5,0.5,0.5,1V12c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.2H47.7z M48.2,9.1V8.7
		c0-0.2,0-0.3-0.1-0.4C48,8.1,47.8,8,47.6,8h-0.5v1.7h0.5c0.2,0,0.3,0,0.4-0.2C48.2,9.5,48.2,9.3,48.2,9.1z M48.2,11.9v-0.7
		c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v2h0.5c0.2,0,0.3,0,0.4-0.2C48.2,12.3,48.2,12.1,48.2,11.9L48.2,11.9z"/>
        <path id="path2103" className="st1" d="M52.5,13.5h-2.6V7.2h0.9v5.3h1.7V13.5L52.5,13.5z"/>
        <path id="path2105" className="st1"
          d="M55.4,12.6v0.9h-2.7V7.2h2.7V8h-1.7v1.8h1.5v0.8h-1.5v1.8H55.4L55.4,12.6z"/>
        <path id="path2107" className="st1" d="M56.4,13.5l1.4-6.2h0.7l1.4,6.2H59l-0.2-1.3h-1.1l-0.2,1.3H56.4z M57.7,11.3h0.8L58.2,9
		L57.7,11.3z"/>
        <path id="path2109" className="st1" d="M62.7,13.5l-1.4-3.8v3.8h-0.9V7.2h0.8l1.4,3.8V7.2h0.9v6.2H62.7z"/>
        <path id="path2111" className="st1" d="M65.5,7.2c0.4,0,0.7,0.1,1,0.4C66.9,8,67,8.3,67,8.7v3.2c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.2H65.5L65.5,7.2z M66.1,11.9V8.7c0-0.2,0-0.3-0.2-0.4C65.8,8.1,65.7,8,65.5,8H65v4.6h0.4
		C65.9,12.6,66.1,12.4,66.1,11.9z"/>
        <path id="path2113" className="st1" d="M25,20.5c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2S24.1,22,24,22c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.2c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9v-0.3c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2
		c0.2,0,0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2s0-0.1,0-0.2v-0.3h0.9L25,20.5z"/>
        <path id="path2115" className="st1" d="M28.3,22h-2.6v-6.2h0.9v5.3h1.7V22z"/>
        <path id="path2117" className="st1"
          d="M31.1,21.1V22h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8H31.1L31.1,21.1z"/>
        <path id="path2119" className="st1" d="M31.3,22l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1L32.2,22H31.3z M32.6,19.9h0.8l-0.4-2.2
		L32.6,19.9z"/>
        <path id="path2121" className="st1" d="M37.6,22l-1.4-3.8V22h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9V22H37.6z"/>
        <path id="path2123" className="st1"
          d="M42.6,21.1V22h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8H42.6L42.6,21.1z"/>
        <path id="path2125" className="st1" d="M45.2,22l-1.4-3.8V22h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9V22H45.2z"/>
        <path id="path2127" className="st1"
          d="M49.2,21.1V22h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8H49.2L49.2,21.1z"/>
        <path id="path2129" className="st1" d="M49.6,22v-6.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.7h-0.5V22H49.6L49.6,22z M51.6,18.4c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3v-0.6c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C51.3,18.6,51.5,18.5,51.6,18.4L51.6,18.4z"/>
        <path id="path2131" className="st1" d="M55.9,21.4c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
		c-0.2-0.1-0.4-0.2-0.5-0.4s-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.4,0.3-0.6
		c0.2-0.3,0.5-0.4,1.1-0.4c0.2,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3,0,0.5v0.2h-0.9v-0.2
		c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0,0,0.1,0,0.2v3.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.5,0.2c0.2,0,0.4,0,0.4-0.2
		c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v-1.1h-0.7v-0.8h1.6v1.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2
		C56.1,21.3,56.1,21.3,55.9,21.4C56,21.3,56,21.3,55.9,21.4L55.9,21.4z"/>
        <path id="path2133" className="st1" d="M57.5,22v-2.7l-1.2-3.5h0.9l0.7,2.4l0.7-2.4h0.9l-1.2,3.5V22H57.5z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9">
        <path id="path2136" className="st1"
          d="M10.4,10.6H8.5V6.9h6.4v1.9l-3.5,12.9H9.1l3.6-12.9h-2.3V10.6L10.4,10.6z"/>
      </g>
      <path id="rect7141-6-0-9-8-6-8-6-9-7" className="st2" d="M22.9,37.3l4,2.3c0.4,0.2,0.6,0.8,0.3,1.3c-0.2,0.4-0.8,0.6-1.3,0.3L22,39
	c-0.4-0.2-0.6-0.8-0.3-1.3C21.9,37.2,22.5,37.1,22.9,37.3z M29.9,31.2l2.3,4c0.2,0.4,0.1,1-0.3,1.3c-0.4,0.2-1,0.1-1.3-0.3l-2.3-4
	c-0.2-0.4-0.1-1,0.3-1.3C29.1,30.6,29.6,30.7,29.9,31.2L29.9,31.2z M39,29.3v4.6c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9v-4.6
	c0-0.5,0.4-0.9,0.9-0.9C38.5,28.3,39,28.7,39,29.3z M47.8,32.1l-2.3,4c-0.2,0.4-0.8,0.6-1.3,0.3c-0.4-0.2-0.6-0.8-0.3-1.3l2.3-4
	c0.2-0.4,0.8-0.6,1.3-0.3C47.9,31.2,48.1,31.7,47.8,32.1z M54,39.1l-4,2.3c-0.4,0.2-1,0.1-1.3-0.3c-0.2-0.4-0.1-1,0.3-1.3l4-2.3
	c0.4-0.2,1-0.1,1.3,0.3C54.6,38.3,54.5,38.9,54,39.1L54,39.1z M55.9,48.2h-4.6c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h4.6
	c0.5,0,0.9,0.4,0.9,0.9C56.8,47.8,56.4,48.2,55.9,48.2L55.9,48.2z M53,57l-4-2.3c-0.4-0.2-0.6-0.8-0.3-1.3c0.2-0.4,0.8-0.6,1.3-0.3
	l4,2.3c0.4,0.2,0.6,0.8,0.3,1.3C54,57.1,53.5,57.3,53,57z M46.1,63.3l-2.3-4c-0.2-0.4-0.1-1,0.3-1.3c0.4-0.2,1-0.1,1.3,0.3l2.3,4
	c0.2,0.4,0.1,1-0.3,1.3C46.9,63.9,46.3,63.7,46.1,63.3L46.1,63.3z M37,65.1v-4.6c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v4.6
	c0,0.5-0.4,0.9-0.9,0.9S37,65.6,37,65.1L37,65.1z M28.1,62.2l2.3-4c0.2-0.4,0.8-0.6,1.3-0.3c0.4,0.2,0.6,0.8,0.3,1.3l-2.3,4
	c-0.2,0.4-0.8,0.6-1.3,0.3C28,63.3,27.9,62.7,28.1,62.2z M21.9,55.3l4-2.3c0.4-0.2,1-0.1,1.3,0.3s0.1,1-0.3,1.3l-4,2.3
	c-0.4,0.2-1,0.1-1.3-0.3C21.3,56.1,21.5,55.6,21.9,55.3L21.9,55.3z M20,46.2h4.6c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H20
	c-0.5,0-0.9-0.4-0.9-0.9C19.1,46.6,19.5,46.2,20,46.2L20,46.2z M38,36.3c-6,0-10.9,4.9-10.9,10.9S31.9,58.1,38,58.1
	s10.9-4.9,10.9-10.9C48.8,41.2,44,36.3,38,36.3L38,36.3z M37.9,40c0.3,0,0.6,0.3,0.6,0.6v3.7c0,0.3-0.3,0.6-0.6,0.6
	c-0.3,0-0.6-0.3-0.6-0.6v-3.7C37.3,40.3,37.6,40,37.9,40z M36.3,41.9v1.9c-1.3,0.6-2.1,2-2.1,3.4c0,2.1,1.7,3.8,3.8,3.8
	c2.1,0,3.8-1.7,3.8-3.8l0,0c0-1.4-0.8-2.7-2.1-3.4v-1.9c2.3,0.7,3.8,2.9,3.8,5.3c0,3.1-2.5,5.6-5.6,5.6l0,0c-3.1,0-5.6-2.5-5.6-5.6
	C32.4,44.8,33.9,42.6,36.3,41.9L36.3,41.9z"/>
    </svg>
  );
};

export default AffordableCleanEnergyIcon;
