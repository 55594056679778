import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { ImageCrop, getCroppedImg } from 'services/imageCropUpload';
import IntlMessages from '../../helpers/IntlMessages';

export const ImageCropper = ({
  showCropper,
  setShowCropper,
  inputImg,
  onSubmit,
  width,
  height
}: {
  showCropper: boolean;
  setShowCropper: (_e: any) => void;
  onSubmit: (blob: Blob) => void;
  inputImg: string;
  width: number;
  height: number;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // we used to show a preview
  // now we directly pass it with onSubmit
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        inputImg,
        (croppedAreaPixels as unknown) as ImageCrop,
        rotation,
        width,
        height
      );
      onSubmit(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, inputImg, width, height, onSubmit]);

  return (
    <div>
      <Modal className="rounded-modal" show={showCropper} onHide={() => setShowCropper(false)}>
        <Modal.Header>
          <h2>
            <IntlMessages id="profile.crop" />
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="cropContainer">
            <Cropper
              image={inputImg}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={width / height}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="container">
            <div className="row mt-3">
              <div className="sliderContainer">
                <h5 className="col-3">
                  <IntlMessages id="profile.crop-zoom" />
                </h5>
                <div className="col-9">
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(zoom) => setZoom(zoom as number)}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="sliderContainer">
                <h5 className="col-3">
                  <IntlMessages id="profile.crop-rotation" />
                </h5>
                <div className="col-9">
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    onChange={(rotation) => setRotation(rotation as number)}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <Button onClick={showCroppedImage} className="d-flex mx-auto">
                <IntlMessages id="profile.crop-button-preview" />
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
