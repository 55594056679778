import React from 'react';
import IconProps  from './IconProps';

const EcosystemsEnvironmentalImpactIcon = ({ style, color = '#06a099', size, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg id="ecosystemsEnvironmentalImpactIcon" data-name="ecosystemsEnvironmentalImpactIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.04 24.29" style={style} fill={color} width={size} height={size} className={className}>
      <path className="cls-1"
        d="M371.6,449.24c-1,0-1.83-1.14-1.83-2.6s.8-2.61,1.83-2.61,1.83,1.15,1.83,2.61S372.62,449.24,371.6,449.24Zm0-4.16c-.37,0-.79.66-.79,1.56s.42,1.56.79,1.56.78-.67.78-1.56S372,445.08,371.6,445.08Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M367.81,448.71c-.84,0-1.49-.91-1.49-2.07s.65-2.08,1.49-2.08,1.5.91,1.5,2.08S368.65,448.71,367.81,448.71Zm0-3.11c-.16,0-.45.4-.45,1s.29,1,.45,1,.46-.39.46-1S368,445.6,367.81,445.6Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M364.6,449.69c-.82,0-1.46-.89-1.46-2s.64-2,1.46-2,1.47.88,1.47,2S365.42,449.69,364.6,449.69Zm0-3c-.14,0-.41.38-.41,1s.27,1,.41,1,.42-.38.42-1S364.75,446.69,364.6,446.69Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M361.91,451.56c-.77,0-1.37-.82-1.37-1.87s.6-1.87,1.37-1.87,1.37.82,1.37,1.87S362.68,451.56,361.91,451.56Zm0-2.69c-.08,0-.32.29-.32.82s.24.83.32.83.32-.3.32-.83S362,448.87,361.91,448.87Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M359.7,454c-.74,0-1.31-.78-1.31-1.77s.57-1.77,1.31-1.77,1.3.78,1.3,1.77S360.43,454,359.7,454Zm0-2.5a.92.92,0,0,0-.26.73.41.41,0,1,0,.53,0A.85.85,0,0,0,359.69,451.53Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M366.77,468.32a5.9,5.9,0,0,1-2.38-.56,10.11,10.11,0,0,1-4-13.07,11.51,11.51,0,0,1,4.8-4.32,9.13,9.13,0,0,1,5.85-.7,1.22,1.22,0,0,1,.73.6c.31.66-.29,1.14-1,1.69a5.91,5.91,0,0,0-2.37,3.12c-.45,1.79.31,3.83,1,5.62.2.53.39,1,.56,1.36a4.6,4.6,0,0,1,.6,2.91,4.31,4.31,0,0,1-1.93,2.9A3.61,3.61,0,0,1,366.77,468.32Zm2.64-17.83a9.33,9.33,0,0,0-3.82.83,10.33,10.33,0,0,0-4.34,3.9,9.1,9.1,0,0,0,3.56,11.58,3.51,3.51,0,0,0,3.26.16h0a3.22,3.22,0,0,0,1.41-2.17,3.71,3.71,0,0,0-.52-2.31c-.18-.4-.38-.86-.59-1.42-.69-1.84-1.54-4.13-1-6.23a7,7,0,0,1,2.74-3.69,5.45,5.45,0,0,0,.59-.5A4.12,4.12,0,0,0,369.41,450.49Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M367.59,467.93a.5.5,0,0,1-.37-.16c-1.07-1.11-4.47-5.14-3.64-10.2a8.75,8.75,0,0,1,2.52-5.16,8.42,8.42,0,0,1,4.41-2.17.52.52,0,0,1,.17,1,7.36,7.36,0,0,0-3.86,1.89,7.78,7.78,0,0,0-2.21,4.57c-.75,4.59,2.38,8.3,3.36,9.32a.52.52,0,0,1,0,.74A.5.5,0,0,1,367.59,467.93Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M364.52,462.47h-.12a.53.53,0,0,1-.39-.63,11.81,11.81,0,0,1,1-2.51,9.65,9.65,0,0,1,2.3-2.87.53.53,0,0,1,.74.06.52.52,0,0,1-.06.74,8.49,8.49,0,0,0-2.06,2.56,11.19,11.19,0,0,0-.86,2.26A.52.52,0,0,1,364.52,462.47Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M364.4,456.76a.5.5,0,0,1-.34-.13.51.51,0,0,1-.05-.73,8.86,8.86,0,0,1,2.5-2,9.48,9.48,0,0,1,1.64-.69.52.52,0,1,1,.32,1,8.21,8.21,0,0,0-1.46.62,7.74,7.74,0,0,0-2.21,1.76A.53.53,0,0,1,364.4,456.76Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M366.28,465.9h-.1a.51.51,0,0,1-.41-.61,7.5,7.5,0,0,1,.64-2,7.92,7.92,0,0,1,1.94-2.52.53.53,0,0,1,.74.06.51.51,0,0,1-.06.73,6.76,6.76,0,0,0-1.68,2.19,6.58,6.58,0,0,0-.56,1.69A.51.51,0,0,1,366.28,465.9Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M365.86,466a.43.43,0,0,1-.19,0c-.59-.23-1.2-.44-1.81-.63a23.25,23.25,0,0,0-2.36-.59.52.52,0,0,1,.2-1c.83.16,1.66.37,2.47.61.63.2,1.27.42,1.89.67a.52.52,0,0,1,.29.67A.52.52,0,0,1,365.86,466Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M364.38,462.54a.51.51,0,0,1-.44-.24,8.68,8.68,0,0,0-2.1-2.3,9.06,9.06,0,0,0-2-1.15.52.52,0,1,1,.41-1,10.53,10.53,0,0,1,2.21,1.28,9.87,9.87,0,0,1,2.34,2.57.52.52,0,0,1-.16.72A.48.48,0,0,1,364.38,462.54Z"
        transform="translate(-358.39 -444.03)"/>
      <path className="cls-1"
        d="M364.38,456.42a.51.51,0,0,1-.5-.38,5.7,5.7,0,0,1-.16-.78,3.22,3.22,0,0,0-.23-.95c-.11-.28-.31-.67-.48-1l-.29-.56a.52.52,0,0,1,.95-.44c.07.14.16.33.26.52.19.37.4.78.53,1.11a4.6,4.6,0,0,1,.3,1.18,4.53,4.53,0,0,0,.12.65.52.52,0,0,1-.36.65Z"
        transform="translate(-358.39 -444.03)"/>
    </svg>

  );
};

export default EcosystemsEnvironmentalImpactIcon;
