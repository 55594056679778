import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const CleanWaterSanitationIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="CleanWaterSanitation" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .clean-water-sanitation{fill - rule:evenodd;clip-rule:evenodd;fill:#0EAFD9;}
           .st1{fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4" className="clean-water-sanitation" d="M13,1.2h48.4c6.8,0,12.4,5.5,12.4,12.4v48.5c0,6.8-5.5,12.4-12.4,12.4H13
	C6.2,74.6,0.6,69,0.6,62.2V13.6C0.6,6.8,6.1,1.2,13,1.2z"/>
      <g id="text6065-3-2-9-6-6">
        <path id="path2037" className="st1" d="M24.8,12.4c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		C22.1,13.1,22,13,22,12.8c0-0.1,0-0.3,0-0.5V9.2c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3H24V9.2c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3s0.2,0.2,0.4,0.2s0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2
		s0-0.1,0-0.2v-0.3h0.9L24.8,12.4z"/>
        <path id="path2039" className="st1" d="M28.1,14h-2.6V7.8h0.9V13h1.7V14z"/>
        <path id="path2041" className="st1" d="M31,13.1V14h-2.7V7.8H31v0.8h-1.8v1.8h1.5v0.8h-1.5V13L31,13.1z"/>
        <path id="path2043" className="st1" d="M31.2,14l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1L32.2,14H31.2z M32.6,11.8h0.8L33,9.6
		L32.6,11.8z"/>
        <path id="path2045" className="st1"
          d="M37.4,14L36,10.2V14h-0.9V7.8h0.8l1.4,3.8V7.8h0.9v6.2L37.4,14L37.4,14z"/>
        <path id="path2047" className="st1" d="M42.7,14L42,10.2l-0.7,3.8h-0.8l-0.9-6.2h0.9l0.4,3.8l0.7-3.8h0.6l0.7,3.8l0.4-3.8h0.9l-0.9,6.2
		L42.7,14L42.7,14z"/>
        <path id="path2049" className="st1" d="M44.1,14l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1L45.1,14H44.1z M45.5,11.8h0.8l-0.4-2.2
		L45.5,11.8z"/>
        <path id="path2051" className="st1" d="M48.3,14V8.6h-1V7.8h3v0.8h-1V14C49.2,14,48.3,14,48.3,14z"/>
        <path id="path2053" className="st1"
          d="M53.4,13.1V14h-2.7V7.8h2.7v0.8h-1.7v1.8h1.5v0.8h-1.5V13L53.4,13.1z"/>
        <path id="path2055" className="st1" d="M53.9,14V7.8h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.7h-0.5v2.7L53.9,14L53.9,14z M55.8,10.3c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V9.2c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2
		h-0.6v1.9h0.6C55.6,10.5,55.7,10.5,55.8,10.3z"/>
        <path id="path2057" className="st1" d="M21.8,22.5l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1l-0.2,1.3H21.8z M23.1,20.3H24l-0.4-2.2
		L23.1,20.3z"/>
        <path id="path2059" className="st1" d="M27.9,22.5l-1.4-3.8v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H27.9z"/>
        <path id="path2061" className="st1" d="M30.7,16.3c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4v-6.2H30.7L30.7,16.3z M31.3,20.9v-3.2c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.5h0.4
		C31.1,21.6,31.3,21.4,31.3,20.9L31.3,20.9z"/>
        <path id="path2063" className="st1" d="M34.7,20.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2s0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2s-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3H36v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.2v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1V21c0,0.2,0,0.4,0,0.5
		c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.3-1.1,0.3c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L34.7,20.9L34.7,20.9z"
        />
        <path id="path2065" className="st1" d="M37.1,22.5l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1L38,22.5H37.1z M38.5,20.3h0.8l-0.4-2.2
		L38.5,20.3z"/>
        <path id="path2067" className="st1" d="M43.3,22.5L42,18.7v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H43.3z"/>
        <path id="path2069" className="st1" d="M44.7,22.5v-6.2h0.9v6.2H44.7z"/>
        <path id="path2071" className="st1" d="M47.1,22.5v-5.4h-1v-0.8h3v0.8H48v5.4H47.1z"/>
        <path id="path2073" className="st1" d="M48.8,22.5l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3H50l-0.2,1.3H48.8z M50.1,20.3H51l-0.4-2.2
		L50.1,20.3z"/>
        <path id="path2075" className="st1" d="M53.1,22.5v-5.4h-1v-0.8h3v0.8h-1.1v5.4H53.1z"/>
        <path id="path2077" className="st1" d="M55.5,22.5v-6.2h0.9v6.2H55.5z"/>
        <path id="path2079" className="st1" d="M57.4,16.9c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		s0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5V21c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C57.2,17.1,57.3,17,57.4,16.9L57.4,16.9z
		 M59.1,17.4c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2V21
		c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2v-3.2c0-0.1,0-0.2,0-0.2C59.2,17.5,59.2,17.4,59.1,17.4L59.1,17.4z"/>
        <path id="path2081" className="st1" d="M63.1,22.5l-1.4-3.8v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H63.1z"/>
      </g>
      <g id="text6075-0-6-9-2-5">
        <path id="path2084" className="st1" d="M14.6,14.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0,0.5,0,0.8v2.8c0,0.3,0,0.7-0.1,1
		c0,0.3-0.3,0.8-0.6,1.4c-0.4,0.6-1.1,0.9-2.2,1c-0.6,0-1.3-0.1-1.8-0.4c-0.5-0.3-0.9-0.6-1.1-1c-0.2-0.4-0.3-0.7-0.4-1
		c0-0.3,0-0.6,0-0.9v-2.2c0-0.3,0-0.5,0-0.7c0-0.1,0-0.3,0.1-0.6l2.9-7.9H14l-2.4,6.2c0.2-0.1,0.6-0.1,0.9-0.1h0.2
		C13.6,13.6,14.2,14,14.6,14.6L14.6,14.6z M12.6,15.8c-0.2-0.3-0.5-0.4-0.9-0.4c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.9
		v2.3c0,0.3,0,0.6,0.2,0.9c0.1,0.2,0.4,0.4,0.8,0.4h0.1c0.4,0,0.6-0.1,0.7-0.4s0.2-0.5,0.2-0.9v-2.3c0-0.2,0-0.3,0-0.4
		C12.8,16,12.7,15.9,12.6,15.8z"/>
      </g>
      <path id="path7044-1" className="st1" d="M24.2,30.8c-0.3,0-0.7,0.3-0.6,0.8l4.3,26.2c0,0.3,0.2,0.6,0.6,0.6h5.2l0.4,4.3h-2.2l5.3,5.3
	l5.3-5.3h-2.2l0.4-4.3H46c0.3,0,0.5-0.3,0.6-0.6l4.3-26.2c0.1-0.4-0.2-0.8-0.6-0.8H24.2L24.2,30.8z M25.1,32.1h24.4l-0.7,4.4
	c-2.8-0.3-7.1,3.3-11.9,0.9c-5.9-3-6,0.2-11,0L25.1,32.1z M37.2,41.3c2.3,4,4.2,5.5,4.2,8.3c0,1.8-1.1,4.2-4.2,4.2S33,51.3,33,49.5
	C33,46.8,34.9,45.3,37.2,41.3z"/>
    </svg>
  );
};

export default CleanWaterSanitationIcon;
