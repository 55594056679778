import { getParticipationBadgesArray, impactPointsRange } from 'helpers/utils-typescript';
import React from 'react';
import { BadgeModalContent } from '../../Modal/InfoModal';
import Jupiter from './Jupiter';
import Mars from './Mars';
import Mercury from './Mercury';
import Neptune from './Neptune';
import Saturn from './Saturn';
import Uranus from './Uranus';
import Venus from './Venus';

type ParticipationBadgeProps = {
  totalCredits: number;
  className?: string;
  size?: number;
  /* if set to true, for user who has no badge. show first badge in gradient corresponding to user's progress to obtain it*/
  showProgress?: boolean;
  /* if set to true, for user who has no badge. show first badge in gray to user's progress to obtain it*/
  showNonObtainedBadgeInGray?: boolean;
  onClick?: () => void;
}

export const getParticipationBadge = (totalCredits: number,
  className?: string,
  size?: number | string,
  showProgress?: boolean,
  showNonObtainedBadgeInGray?: boolean,
): React.ReactElement => {

  switch (true) {
    case impactPointsRange(totalCredits, 0, 99):
      return (showProgress
        // if user don't have any participationBadges, show the first Badge with the out circle fill up to the percentage the user has obtained, inner icon remain grey
        ? <Mercury className={className} size={size} style={{ color: 'RGBA(224, 79, 33, 0.2)' }}
          gradient={[
            { offsetValue: `${(100 - totalCredits)?.toString()}%`, stopOpacity: '0.2' },
            { offsetValue: `${totalCredits?.toString()}%`, stopOpacity: '1' },

          ]}
        />
        : showNonObtainedBadgeInGray ? <Mercury className={className} size={size} style={{ color: 'RGBA(224, 79, 33)', filter: 'grayscale(100%)' }} /> : null); // color: 'gray'
    case impactPointsRange(totalCredits, 100, 299):
      return <Mercury className={className} size={size} />;
    case impactPointsRange(totalCredits, 300, 999):
      return <Venus className={className} size={size} />;
    case impactPointsRange(totalCredits, 1000, 2999):
      return <Mars className={className} size={size} />;
    case impactPointsRange(totalCredits, 3000, 4999):
      return <Jupiter className={className} size={size} />;
    case impactPointsRange(totalCredits, 5000, 6999):
      return <Saturn className={className} size={size} />;
    case impactPointsRange(totalCredits, 7000, 9999):
      return <Uranus className={className} size={size} />;
    case impactPointsRange(totalCredits, 10000, 9999999999):
      return <Neptune className={className} size={size} />;
    default:
      break;
  }
};



export const ParticipationBadgeModalContent = () => {
  return BadgeModalContent('badge.participation-modal-title',
    'badge.participation-modal-subtitle', getParticipationBadgesArray().slice(1)); // the first one from the BadgesArray is a badge to show progress from 0 to first badge, should not show in badge modal
};

const ParticipationBadge = ({ totalCredits, className, size, onClick, showProgress, showNonObtainedBadgeInGray }
  : ParticipationBadgeProps): React.ReactElement => {

  return (
    <div onClick={onClick}>
      {getParticipationBadge(totalCredits, className, size, showProgress, showNonObtainedBadgeInGray)}
    </div>
  );
};

export default ParticipationBadge;
