import { UserContext } from 'contexts/user/UserContextProvider';
import { useContext, useEffect, useState } from 'react';
import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { Chat, Channel, ChatView } from 'stream-chat-react';
// import type { Channel as StreamChannel } from 'stream-chat';
import { EmojiPicker } from 'stream-chat-react/emojis';
import { init, SearchIndex } from 'emoji-mart';
import data from '@emoji-mart/data';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useMobileView } from './hooks/useMobileView';
import MessagingSidebar from './components/MessagingSidebar';
import ChannelInner from './components/ChannelInner';

import 'stream-chat-react/dist/css/v2/index.css';
import './styles/index.css';
import { Card } from 'react-bootstrap';

// emoji config
init({ data });

const SECOND_PARTY_USER_ID = gql`
  query userByUsername($username: String!) {
    userByUsername(username: $username) {
      id
      name
      avatarUrl
    }
  }
`

const CREATE_STREAM_CHAT_USER = gql`
  mutation CreateStreamChatUser($userId: UUID!) {
    createStreamChatUser(userId: $userId) {
      success
    }
  }
`;

/** Full screen chat interface */
const ChatFullscreen = () => {
  const { username } = useParams<{ username: string }>();
  const { currentUser, streamChatClient } = useContext(UserContext);

  const toggleMobile = useMobileView();

  /* */
  /* States */
  /* */
  const [channelId, setChannelId] = useState<string>();

  /* */
  /* Queries and mutations */
  /* */
  const [createStreamChatUser] = useMutation(CREATE_STREAM_CHAT_USER);
  const { data, loading } = useQuery(SECOND_PARTY_USER_ID, { variables: { username }, skip: !username });
  const secondPartyUserId = data?.userByUsername?.id;

  /* */
  /* Chat config */
  /* */
  const sort: ChannelSort = { last_message_at: -1 };
  const filters: ChannelFilters = {
    type: 'messaging',
    members: { $in: [currentUser?.id] },
  };
  const options: ChannelOptions = {
    limit: 10,
  };
  const channelListOptions = {
    filters,
    sort,
    options,
  };

  /* */
  /* Effects */
  /* */
  useEffect(() => {
    if (!streamChatClient || !username || !secondPartyUserId) return;

    const initializeChannel = async () => {
      try {
        const channel = streamChatClient.channel('messaging', {
          members: [currentUser?.id, secondPartyUserId],
        });

        if (channel.id) {
          setChannelId(channel.id);
        } else {
          await channel.create();
          setChannelId(channel.id);
        }
      } catch (error) {
        console.error('Error creating channel:', error);

        try {
          const { data } = await createStreamChatUser({ variables: { userId: secondPartyUserId } });

          if (data.createStreamChatUser.success) {
            const channel = streamChatClient.channel('messaging', {
              members: [currentUser?.id, secondPartyUserId],
            });
            await channel.create();
            setChannelId(channel.id);
          } else {
            console.error('Failed to create Stream Chat user');
          }
        } catch (mutationError) {
          console.error('Error creating Stream Chat user:', mutationError);
        }
      }
    };

    initializeChannel();
  }, [streamChatClient, username, secondPartyUserId, currentUser?.id, createStreamChatUser]);

  /* */
  /* Render */
  /* */
  if (!streamChatClient || loading || (username && !channelId)) return <div>Loading</div>;
  return (
      <Card className='d-flex full-height-for-chat' style={{ overflow: 'hidden' }}>
        <Chat client={streamChatClient} theme="messaging str-chat__theme-light" >
          <ChatView>
            <ChatView.Channels>
              <MessagingSidebar
                initialChannelListOptions={channelListOptions}
                onClick={toggleMobile}
                channelId={channelId}
                currentUserId={currentUser?.id}
              />
              <Channel
                maxNumberOfFiles={10}
                multipleUploads={true}
                TypingIndicator={() => null}
                EmojiPicker={EmojiPicker as React.ComponentType<{}>}
                emojiSearchIndex={SearchIndex}
                enrichURLForPreview
              >
                {/* {isCreating && ( // might be useful if we want to create a new channel on click
                <CreateChannel toggleMobile={toggleMobile} onClose={() => setIsCreating(false)} />
              )} */}
                <ChannelInner theme="str-chat__theme-light" toggleMobile={toggleMobile} />
              </Channel>
            </ChatView.Channels>
          </ChatView>
        </Chat>
      </Card>
  )
}

export default ChatFullscreen;