import React from 'react';
import IconProps from './IconProps';

const MindfulnessCommunicationIcon = ({ style, color = '#b59ac9', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="mindfulnessCommunicationIcon" data-name="mindfulnessCommunicationIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <path d="M500,340.4c73,0,132.3-59.4,132.3-132.3c0-73-59.4-132.3-132.3-132.3s-132.4,59.4-132.4,132.3
		C367.6,281.1,427,340.4,500,340.4z M500,138.3c38.5,0,69.8,31.3,69.8,69.8c0,38.5-31.3,69.8-69.8,69.8s-69.9-31.3-69.9-69.8
		C430.1,169.6,461.5,138.3,500,138.3z"/>
        <path d="M973.8,438.8c-29.5-29.5-75.6-34.4-112.2-11.6c-27.2,16.9-58.8,25.9-91.4,25.9c-44.4,0-86.5-16.7-118.8-47
		c-36.3-34.1-74.8-54.9-114.5-61.8c-67.8-11.6-134.6,8.9-183.2,56.6c-20.6,20.2-38.6,32.8-56.8,39.6
		C242.3,461,184.2,456,137.6,426.6c-35.8-22.5-81.6-17.6-111.3,12.1C6.9,458-2.5,484.7,0.6,512c3.1,27.3,18.1,51.5,41.4,66.2
		c81.8,51.6,185.3,66.9,278.2,42.3v92.8h-93.9c-52.3,0-94.8,42.5-94.8,94.8v21.5c0,52.3,42.5,94.8,94.8,94.8h291.1
		c8,0,15.7-1.3,23.1-3.2c7.4,1.9,15.1,3.2,23.1,3.2h210.1c52.3,0,94.8-42.5,94.8-94.8V808c0-52.2-42.5-94.8-94.8-94.8h-93.9v-92.8
		c93,24.7,196.5,9.3,278.3-42.4c23.3-14.7,38.3-38.8,41.4-66.1C1002.5,484.8,993.1,458.1,973.8,438.8z M549.6,829.5
		c0,17.8-14.5,32.3-32.3,32.3H226.3c-17.8,0-32.3-14.5-32.3-32.3V808c0-17.8,14.5-32.3,32.3-32.3h93.9h197.2
		c17.8,0,32.3,14.5,32.3,32.3V829.5z M773.6,775.7c17.8,0,32.3,14.5,32.3,32.3v21.5c0,17.8-14.5,32.3-32.3,32.3H606.1
		c3.7-10.1,6-20.9,6-32.3V808c0-11.4-2.3-22.1-6-32.3h73.7H773.6z M937.3,505.1c-0.9,8.3-5.5,15.7-12.6,20.1
		C847,574.3,745.6,583,660.4,548.1l-43.1-17.6v182.7h-53.7h-46.1H382.7V530.5l-43.1,17.6c-85.3,34.9-186.6,26.2-264.3-22.9
		c-7.1-4.5-11.7-11.9-12.7-20.2c-0.5-4.9-0.2-14.2,7.8-22.2c8.9-8.9,22.8-10.3,33.8-3.4c63.3,39.8,141.6,46.9,214.7,19.5
		c26.7-10,51.6-27,78.6-53.6c34-33.4,80.8-47.9,128.8-39.6c27.4,4.7,55.1,20.1,82.3,45.7c43.9,41.3,101.3,64,161.6,64
		c44.3,0,87.2-12.2,124.4-35.3c11.8-7.3,26.1-6.2,35,2.7C937.6,490.9,937.9,500.2,937.3,505.1z"/>
      </g>
    </svg>
  );
};

export default MindfulnessCommunicationIcon;
