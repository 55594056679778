import { gql, useMutation, useQuery } from '@apollo/client';
import ScrollToError from 'components/FormComponents/ScrollToError';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import BottomPanelCard from 'components/common/BottomPanelCard';
import ContextHelp from 'components/common/ContextHelp';
import ImageDropzone from 'components/common/ImageDropzone';
import CustomReactSelect, { Option } from 'components/custom/CustomReactSelect';
import { UserContext } from 'contexts/user/UserContextProvider';
import { FastField, Field, FieldArray, Formik, FormikValues } from 'formik';
import IntlMessages from 'helpers/IntlMessages';
import { updateMultilingualJson } from 'helpers/Utils';
import { detectUpdatedData, getTranslatedString, getUiTranslation, uploadUrlEncodedBlob } from 'helpers/utils-typescript';
import { useSpeciesesOptions } from 'hooks';
import { FormikSpecimenAttachment, FormikSpecimenAttachmentWithPosition } from 'pages/Mapping/CreateEditMapping';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AttachmentType, LearningPath, SpecimenAttachment } from 'services/graphqltypes';
import { uploadAttachFile, uploadAttachPicture } from 'services/imageCropUpload';
import * as Yup from 'yup';
import { CreateEditSpecimenProps } from './CreateEditSpecimen';
import { LEARNING_PATH } from 'services/learningPath';
import { getLearningPathIcon, getLearningPathIconColor } from 'components/LearningPathsIcons/LearningPathTag';
import { usePoisOptions } from 'pages/Mapping/SelectPoi';

export const SPECIMEN_FRAGMENT = gql`
fragment specimanFragment on Speciman {
  __typename
  id
  ageYears
  name
  code
  slug
  bioregionGid
  biodiversityRichness
  biodiversityRichnessMicrohabitats
  condition
  crownLightExposure
  learningPath {
    id
    name
  }
  osmTags
  territoryId
  territory {
    name
    slug
    id
    isCurrentUserMentor
    avatarUrl
  }
  territoriesByCoordinatesGeometry {
    nodes {
      id
      slug
    }
  }
  coordinates { x, y }
  cultureDescription
  district
  girthCm
  surfaceSealing
  notes
  condition
  coverPictureUrl
  coverPictureWidth
  coverPictureHeight
  natureDescription
  lastMeasuredAt
  isRestingInPeace
  isNatureMonument
  itreeApiJson
  speciesId
  type
  createdAt
  createdByUserId
  createdByUser {
    id
    name
    totalCredits
    isTerritoryMentor
    username
    avatarUrl
  }
  updatedAt
  reviewerId
    reviewer {
      id
      name
      totalCredits
      isTerritoryMentor
      username
      avatarUrl
    }
  status
  currentUserHasObserved
  species {
    __typename
    id
    slug
    name
    scientificName
    family
    treeType
    barkPictureUrl
    barkType
    leafPictureUrl
    leafType
    leafShape
    leafMargin
    needleType
    coneType
    conePictureUrl
  }
  updatedByUser {
    id
    name
  }
  specimenUploadedResourcesBySpecimenId {
    __typename
    nodes {
    __typename
      id
      name
      uploadedResourceId
      type
      uploadedResource {
        id
        url
      }
    }
  }
  specimenAttachmentsBySpecimenId(
    orderBy: POSITION_ASC
    filter: {type: {equalTo: IMAGE}}
  ) {
    nodes {
      id
      url
      position
    }
  }
  routeStopsBySpecimenId {
    nodes {
      id
    }
  }
 }`;

export const SPECIMEN_BY_SLUG = gql`
${SPECIMEN_FRAGMENT}
query specimanBySlug($slug: String!) {
  specimanBySlug(slug: $slug) {
    ...specimanFragment
  }
}`;

const UPDATE_SPECIMEN = gql`
${SPECIMEN_FRAGMENT}
mutation updateSpecimanBySlug(
  $name: JSON
  $slug: String!
  $code: String
  $speciesId: UUID
  $learningPathId: UUID
  $osmTags: String
  $coverPictureUrl: JSON
  $coverPictureWidth: Int
  $coverPictureHeight: Int
  $type: SpecimenType!
  $status: SpecimenSpeciesStatus
) {
  updateSpecimanBySlug(
    input: {
      patch: {
        name: $name
        code: $code
        speciesId: $speciesId
        learningPathId: $learningPathId
        osmTags: $osmTags
        slug: $slug
        coverPictureUrl: $coverPictureUrl
        coverPictureWidth: $coverPictureWidth
        coverPictureHeight: $coverPictureHeight
        type: $type
        status: $status
      }
      slug: $slug
    }
  ) {
    clientMutationId
    speciman {
      ...specimanFragment
    }
  }
}`;

const CREATE_SPECIMEN = gql`
${SPECIMEN_FRAGMENT}
mutation CreateSpeciman(
  $coverPictureUrl: JSON
  $coverPictureWidth: Int
  $coverPictureHeight: Int
  $name: JSON!
  $speciesId: UUID
  $learningPathId: UUID
  $osmTags: String
  $code: String
  $type: SpecimenType!
  $territoryId: UUID
  $bioregionGid: Int
) {
  createSpeciman(
    input: {
      speciman: {
        speciesId: $speciesId
        learningPathId: $learningPathId
        osmTags: $osmTags
        type: $type
        coverPictureUrl: $coverPictureUrl
        coverPictureWidth: $coverPictureWidth
        coverPictureHeight: $coverPictureHeight
        code: $code
        name: $name
        territoryId: $territoryId
        bioregionGid: $bioregionGid
      }
    }
  ) {
    speciman {
      ...specimanFragment
    }
  }
}`;

export const CREATE_SPECIMEN_ATTACHMENT = gql`
${SPECIMEN_FRAGMENT}
mutation createSpecimenAttachment(
  $position: Int!
  $specimenId: UUID!
  $url: String!
) {
  createSpecimenAttachment(
    input: {
      specimenAttachment: {
        specimenId: $specimenId
        position: $position
        url: $url
        type: IMAGE
      }
    }
  ) {
    specimen {
      ...specimanFragment
    }
  }
}
`;

export const UPDATE_SPECIMEN_ATTACHMENT = gql`
${SPECIMEN_FRAGMENT}
mutation updateSpecimenAttachment($id: UUID!, $position: Int!) {
  updateSpecimenAttachment(input: { patch: { position: $position }, id: $id }) {
    specimen {
      ...specimanFragment
    }
  }
}
`;

export const DELETE_SPECIMEN_ATTACHMENT = gql`
${SPECIMEN_FRAGMENT}
mutation deleteSpecimenAttachment($id: UUID!) {
  deleteSpecimenAttachment(input: {id: $id}) {
    specimen {
      ...specimanFragment
    }
  }
}
`;

/* */
/* First step of create/edit specimen form: Identification */
/* */
const SpecimenIdentification = ({
  specimen,
  species,
  type,
  setType,
  osmTags,
  territoryId,
  ecoregionGid,
  loading,
  next,
  setSlug,
  setSavedNotification
}: CreateEditSpecimenProps): React.ReactElement => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const { currentUser } = useContext(UserContext);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const { pathname } = useLocation();

  const [updateSpecimen] = useMutation(UPDATE_SPECIMEN);
  const [createSpecimen] = useMutation(CREATE_SPECIMEN);
  const [createSpecimenAttachment] = useMutation(CREATE_SPECIMEN_ATTACHMENT);
  const [updateSpecimenAttachment] = useMutation(UPDATE_SPECIMEN_ATTACHMENT);
  const [deleteSpecimenAttachment] = useMutation(DELETE_SPECIMEN_ATTACHMENT);

  const { data: learningPathsList, loading: loadingLearningPaths, error: learningPathsError } = useQuery(LEARNING_PATH);

  /* cover picture resolution */
  const coverPicWidth = 1440;
  const coverPicHeight = 1920;

  /* */
  /* Options for osmTags dropdown */
  /* */
  const poisOptions = usePoisOptions('all', false);

  /* */
  /* Options for species dropdown */
  /* */
  const { speciesesOptions, speciesesLoading } = useSpeciesesOptions(ecoregionGid || currentUser?.bioregionGid); // load species according to which commons the specimen is being created in; default to current user's

  /* */
  /* Options for learning paths dropdown */
  /* */
  const learningPaths = useMemo<Option[]>(() => {
    if (loadingLearningPaths) {
      return [{ value: '', label: getUiTranslation('general.none', currentLang) }];
    }
    if (learningPathsError) {
      return [{ value: '', label: getUiTranslation('error.missing-learningPath', currentLang) }];
    }
    const learningPathsOptions = learningPathsList.learningPaths.nodes.map((learningPath: LearningPath) => {
      return {
        value: learningPath.id,
        label: getTranslatedString(learningPath.name, currentLang),
        icon: getLearningPathIcon(learningPath.id, getLearningPathIconColor(learningPath.id), 20),
      };
    });
    return learningPathsOptions;
  }, [loadingLearningPaths, learningPathsError, learningPathsList, currentLang]);

  /* initial values */
  const initialValues = useMemo(() => {
    return {
      coverPictureUrl: getTranslatedString(specimen?.coverPictureUrl, currentLang),
      images: specimen?.specimenAttachmentsBySpecimenId.nodes.map((attachment: SpecimenAttachment) => {
        return {
          arrayHelperId: attachment.id,
          url: attachment.url,
          type: 'IMAGE' as AttachmentType,
        };
      }),
      name: getTranslatedString(specimen?.name, currentLang),
      code: specimen?.code,
      species: speciesesOptions?.find((speciesOption: Option) => {
        return [specimen?.speciesId, species?.id].includes(speciesOption.value);
      }),
      type: specimen?.type || type,
      osmTags: specimen?.osmTags || osmTags,
      learningPath: specimen?.learningPath?.id && {
        value: specimen.learningPath.id,
        label: getTranslatedString(specimen?.learningPath?.name, currentLang),
        icon: getLearningPathIcon(specimen.learningPath.id, getLearningPathIconColor(specimen.learningPath.id), 20),
      } as Option,
    };
  }, [currentLang, specimen, species, speciesesOptions, osmTags, type]);

  /* validation schema */
  const validationSchema = Yup.object().shape(
    {
      coverPictureUrl: Yup.string().nullable(),
      name: Yup.string().required(getUiTranslation('general.required', currentLang)),
      code: Yup.string().when('type', {
        is: (type) => type === 'TREE' || type === 'GROUP_OF_TREES',
        then: Yup.string().required(intl.formatMessage({ id: 'general.required' })),
      }),
      species: Yup.object().when('type', {
        is: (type) => type === 'TREE' || type === 'GROUP_OF_TREES',
        then: Yup.object().shape({
          value: Yup.string().required(intl.formatMessage({ id: 'general.required' })),
          label: Yup.string(),
          tag: Yup.string(),
        }),
      }).nullable(),
      type: Yup.string().required(getUiTranslation('general.required', currentLang)),
      osmTags: Yup.string().when('type', {
        is: (type) => type === 'POI',
        then: Yup.string().required(intl.formatMessage({ id: 'general.required' })),
      }).nullable(),
      learningPath: Yup.object().when('type', {
        is: (type) => type === 'POI',
        then: Yup.object().shape({
          value: Yup.string().required(intl.formatMessage({ id: 'general.required' })),
          label: Yup.string(),
          tag: Yup.string(),
        }),
      }).nullable(),
    });

  /* todo: add error handling */
  /* handles creation of a specimen */
  const handleSubmitFormCreate = useCallback((values: FormikValues) => {
    const { coverPictureUrl, name, code, type, species, images, learningPath, osmTags } = values;
    // process picture upload
    uploadUrlEncodedBlob(coverPictureUrl, uploadAttachPicture)
      .then((pictureUrl: string) => {
        return createSpecimen({
          variables: {
            name: updateMultilingualJson({}, name, currentLang),
            code: code,
            coverPictureUrl: updateMultilingualJson({}, pictureUrl, currentLang),
            coverPictureWidth: coverPicWidth,
            coverPictureHeight: coverPicHeight,
            type: type,
            osmTags: osmTags,
            speciesId: species?.value || null, // null will delete the original value if there was one before
            territoryId: territoryId,
            bioregionGid: ecoregionGid,
            learningPathId: learningPath?.value || null,
          }
        });
      }).then((result) => {
        if (result?.data) {
          const uploadAttachmentsPromises = images?.length > 0
            ? [...images].map((specimenAttachment: FormikSpecimenAttachment, index: number) => {
              return uploadAttachFile(specimenAttachment.file).then((url: string) => {
                return createSpecimenAttachment({
                  variables: {
                    url,
                    position: index,
                    specimenId: result.data.createSpeciman.speciman.id,
                    type: 'IMAGE'
                  }
                });
              });
            })
            : [];
          Promise.all([...uploadAttachmentsPromises]).then(() => {
            setSlug(result.data.createSpeciman.speciman.slug); // -> data is immediately queried by useQuery
            next();
            setSavedNotification(true);
          });

        }
      });
  }, [createSpecimen, currentLang, next, setSavedNotification, setSlug, territoryId,
    createSpecimenAttachment, ecoregionGid]);

  /* todo: add error handling */
  /* handles editing of a specimen */
  const handleSubmitFormEdit = useCallback((values: FormikValues) => {
    const { coverPictureUrl, name, code, type, species, images, learningPath, osmTags } = values;
    if (isDirty) {
      const UpdateSpecimenMutationWtCoverPic = uploadUrlEncodedBlob(coverPictureUrl, uploadAttachPicture).then(
        (pictureUrl: string) => {
          return updateSpecimen({
            variables: {
              slug: specimen.slug,
              name: updateMultilingualJson(specimen?.name, name, currentLang),
              code: code,
              coverPictureUrl: pictureUrl
                ? updateMultilingualJson(specimen?.coverPictureUrl, pictureUrl, currentLang) // only update coverPictureUrl if there was a new pic uploaded
                : undefined,
              coverPictureWidth: coverPicWidth,
              coverPictureHeight: coverPicHeight,
              type: type,
              osmTags: osmTags,
              speciesId: species?.value || null, // null will delete the original value if there was one before
              learningPathId: learningPath?.value || null,
              status: specimen.status === 'MAPPING_DRAFT' ? 'DRAFT' : specimen.status, // if the specimen draft came from the Mapping Tool ('MAPPING_DRAFT'), here it becomes a normal 'DRAFT' -> is removed from the list of current mappings
            }
          });
        });

      const initialPostImagesWtPosition = initialValues.images.map(
        (initialImage: FormikSpecimenAttachment, index: number) => { return { ...initialImage, position: index }; }
      );
      const submittedImagesWtPosition = images.map(
        (submitImage: FormikSpecimenAttachment, index: number) => { return { ...submitImage, position: index }; }
      );
      const {
        added: imageToAdd,
        updated: imageToUpdate,
        removed: imageToRemove,
      } = detectUpdatedData(initialPostImagesWtPosition, submittedImagesWtPosition, (initialImage, correspondingSubmitImage) => { return (initialImage.position !== correspondingSubmitImage.position); }, 'arrayHelperId');
      const imageToAddMutation = imageToAdd?.length > 0 ? (
        [...imageToAdd].map((specimenAttachment: FormikSpecimenAttachmentWithPosition) => {
          return uploadAttachFile(specimenAttachment.file).then((url: string) => {
            return createSpecimenAttachment({
              variables: {
                url,
                position: specimenAttachment.position,
                specimenId: specimen.id,
                type: 'IMAGE'
              }
            });
          });
        })
      ) : [];
      const imageToUpdateMutation = imageToAdd?.length > 0 ? (
        [...imageToUpdate].map((specimenAttachment: FormikSpecimenAttachmentWithPosition) => {
          return updateSpecimenAttachment({
            variables: {
              position: specimenAttachment.position,
              id: specimenAttachment.arrayHelperId,
            }
          });
        })
      ) : [];
      const imageToRemoveMutation = imageToRemove?.length > 0 ? (
        [...imageToRemove].map((specimenAttachment: FormikSpecimenAttachmentWithPosition) => {
          return deleteSpecimenAttachment({
            variables: {
              id: specimenAttachment.arrayHelperId,
            }
          });
        })
      ) : [];

      Promise.all([
        UpdateSpecimenMutationWtCoverPic,
        ...imageToAddMutation,
        ...imageToUpdateMutation,
        ...imageToRemoveMutation,
      ]).then((result) => {
        if (result) {
          setSavedNotification(true);
          next();
        }
      });
    } else {
      next();
    }
  }, [currentLang, isDirty, next, setSavedNotification, updateSpecimen, specimen,
    createSpecimenAttachment, deleteSpecimenAttachment, initialValues, updateSpecimenAttachment]);

  if (loading || speciesesLoading || loadingLearningPaths) {
    return <div className="loading" />;
  }

  return (
    <div className="mt-4">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(!specimen?.slug) ? handleSubmitFormCreate : handleSubmitFormEdit}
      >
        {({ errors, handleChange, handleBlur, handleSubmit, isValid, dirty, values, setFieldValue }) => (
          <Form className="av-tooltip tooltip-label-right" onSubmit={handleSubmit}>
            <h1 className="color-theme-1 font-weight-bold">
              {intl.formatMessage({ id: 'specimen.identification' })}
            </h1>

            {/* Single/group/POI radiobutton */}
            <FormGroup>
              <h5 className="font-weight-bold">
                Type
              </h5>
              { // validation error message
                clickedSubmit && errors.type &&
                <div className="form-field-error" style={{ 'color': 'red' }}>{errors.type}</div>
              }
              <label>
                <FastField
                  name="type"
                  type="radio"
                  value="TREE"
                  className="mr-1 ml-3"
                  onClick={() => { setType('TREE'); }}
                />
                {intl.formatMessage({ id: 'specimen.single-tree' })}
              </label>

              <label>
                <FastField
                  name="type"
                  type="radio"
                  value="GROUP_OF_TREES"
                  className="mr-1 ml-3"
                  onClick={() => { setType('GROUP_OF_TREES'); }}
                />
                {intl.formatMessage({ id: 'specimen.group-of-trees' })}
              </label>

              <label>
                <FastField
                  name="type"
                  type="radio"
                  value="POI"
                  className="mr-1 ml-3"
                  onClick={() => { setType('POI'); }}
                />
                POI
              </label>
            </FormGroup>

            { /* Cover Picture */}
            <FormGroup>
              <h5 className="font-weight-bold">
                <IntlMessages id="activity.form.cover-picture" />
              </h5>
              <FastField
                name="coverPictureUrl"
                component={ImageUpload}
                value={values.coverPictureUrl}
                aspect={
                  (specimen?.coverPictureWidth && !values.coverPictureUrl.includes('blob:'))
                    ? specimen?.coverPictureWidth / specimen?.coverPictureHeight // if we loaded aspect ratio from db, respect it
                    : 3 / 4} // if we are showing newly uploaded pic (url has blob:) or there was nothing loaded, default to portrait
                width={coverPicWidth}
                height={coverPicHeight}
              />
              {
                clickedSubmit && errors.coverPictureUrl &&
                <div className="form-field-error" style={{ 'color': 'red' }}>
                  {errors.coverPictureUrl}
                </div>
              }
            </FormGroup>

            <FormGroup>
              <h5 className="font-weight-bold">
                <IntlMessages id="general.gallery" />
              </h5>
              <FieldArray
                name='images'
                render={(arrayHelpers: any) => {
                  return (
                    <Field
                      maxImagePerRow={4}
                      fieldName='images'
                      component={ImageDropzone}
                      fieldValues={values['images']}
                      arrayHelpers={arrayHelpers}
                    />
                  );
                }}
              />
            </FormGroup>

            {/* Name */}
            <FormGroup>
              <h5 className="font-weight-bold">
                {intl.formatMessage({ id: 'specimen.name' })}*
              </h5>
              { // validation error message
                clickedSubmit && errors.name &&
                <div className="form-field-error" style={{ 'color': 'red' }}>{errors.name}</div>
              }
              <Field
                name="name"
                type="text"
                className="form-control"
                placeholder="Theseus Temple Plane"
                onChange={handleChange}
                onBlur={handleBlur('name')}
              />
            </FormGroup>

            {/* osmTags (only for POI) */}
            {values.type === 'POI' &&
              <FormGroup>
                <h5 className="font-weight-bold">
                  {intl.formatMessage({ id: 'general.category' })}*
                  <ContextHelp contextHelpId={'28bece11-edd2-4112-b8d1-ae5b2eaf90e6'} />
                </h5>

                { // validation error message
                  clickedSubmit && errors.osmTags &&
                  <div className="form-field-error" style={{ 'color': 'red' }}>{errors.osmTags}</div>
                }

                <Field
                  name="osmTags"
                  component={CustomReactSelect}
                  options={poisOptions}
                  isMulti={false}
                  isClearable={true}
                  hasIcon={true}
                  selected={poisOptions.find((option) => option.value === values.osmTags)}
                  placeholder={getUiTranslation('general.select-or-type-to-search', currentLang)}
                  handleChange={(option: Option) => { setFieldValue('osmTags', option?.value); }}
                />
              </FormGroup>
            }

            {/* Learning Path (only for POI) */}
            {values.type === 'POI' &&
              <FormGroup>
                <h5 className="font-weight-bold">
                  Learning Path
                </h5>
                <label>
                  Select a Learning Path most related to this POI.
                  After users observe this place in a Quest, they will receive Impact Points in the relevant Learning Path.
                </label>
                { // validation error message
                  clickedSubmit && errors.learningPath &&
                  <div className="form-field-error" style={{ 'color': 'red' }}>{errors.learningPath['value']}</div>
                }
                <FastField
                  name="learningPath"
                  component={CustomReactSelect}
                  options={learningPaths}
                  selected={values.learningPath}
                  isMulti={false}
                  hasIcon={true}
                  isClearable={true}
                  placeholder={getUiTranslation('general.select-or-type-to-search', currentLang)}
                  handleChange={(option: Option[]) => {
                    setFieldValue('learningPath', option);
                  }}
                />
              </FormGroup>
            }

            {/* Code */}
            <FormGroup>
              <h5 className="font-weight-bold">
                {intl.formatMessage({ id: 'specimen.code' })}
                {values.type !== 'POI' && '*' /* only compulsory for trees */}
                <ContextHelp contextHelpId={'bbc67637-ca37-4794-9f4a-411fe44ad75d'} />
              </h5>

              {/* Explanation text for POIs */}
              {values.type === 'POI' && <label>If you have a code system, you can give the POI a code.</label>}

              { // validation error message
                clickedSubmit && errors.code &&
                <div className="form-field-error" style={{ 'color': 'red' }}>{errors.code}</div>
              }
              <Field
                name="code"
                type="text"
                className="form-control"
                placeholder="e.g. FS38"
                onChange={handleChange}
                onBlur={handleBlur('code')}
              />
            </FormGroup>

            {/* Species dropdown */}
            <FormGroup>
              <h5 className="font-weight-bold">
                {intl.formatMessage({ id: 'bioregion.species' })}{values.type !== 'POI' && '*' /* required for trees */}
              </h5>

              {/* Explanation text for POIs */}
              {values.type === 'POI' &&
                <label>
                  If there is a species related to this POI, like an animal, that can often be observed here, you can select it here.
                </label>
              }

              { // validation error message
                clickedSubmit && errors.species &&
                <div className="form-field-error" style={{ 'color': 'red' }}>{errors.species['value']}</div>
              }
              <Field
                name="species"
                component={CustomReactSelect}
                options={speciesesOptions}
                isMulti={false}
                isClearable={true}
                hasIcon={true}
                selected={values.species}
                placeholder={getUiTranslation('general.select-or-type-to-search', currentLang)}
                handleChange={(option: Option[]) => {
                  if (option) {
                    setFieldValue('species', option);
                    history.replace({
                      pathname,
                      state: {
                        species: option,
                      }
                    })
                  } else {
                    setFieldValue('species', null);
                    history.replace({
                      pathname,
                      state: {
                        species: null,
                      }
                    })
                  }
                }}
              />
            </FormGroup>

            { /* bottom panel */}
            <BottomPanelCard
              displaySpinner={displaySpinner}
              setDisplaySpinner={setDisplaySpinner}
              clickedSubmit={clickedSubmit}
              setClickedSubmit={setClickedSubmit}
              isValid={isValid}
              isDirty={dirty}
              setIsDirty={setIsDirty}
            />
            <ScrollToError
              hasSubmit={clickedSubmit}
              isValid={isValid}
              setDisplaySpinner={setDisplaySpinner}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SpecimenIdentification;
