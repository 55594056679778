import React from 'react';
import IconProps from 'components/LearningPathsIcons/IconProps';

const SurfaceSealingIcon = ({ style, color = '#60b6aa', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 19 13"
      style={style}
      height={size}
      className={className}
      width={size}
      xmlSpace="preserve">
      <style type="text/css">
        {`
          .surface-sealing-1{fill:none;stroke:${color};stroke-width:2;}
          .surface-sealing-2{fill:none;stroke:${color};stroke-width:2;stroke-linecap:round;stroke-miterlimit:11.4737;}
          .surface-sealing-3{fill:none;stroke:${color};stroke-width:2;stroke-linecap:round;}
          `}
      </style>
      <line className="surface-sealing-1" x1="0" y1="12" x2="19" y2="12" />
      <path className="surface-sealing-2" d="M5,1v8" />
      <path className="surface-sealing-2" d="M15,1v8" />
      <line className="surface-sealing-3" x1="2.4" y1="7" x2="5" y2="9.6" />
      <line className="surface-sealing-3" x1="12.4" y1="7" x2="15" y2="9.6" />
      <line className="surface-sealing-3" x1="5" y1="9.6" x2="7.6" y2="7" />
      <line className="surface-sealing-3" x1="15" y1="9.6" x2="17.6" y2="7" />
    </svg>
  );
};


export default SurfaceSealingIcon;
