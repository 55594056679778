import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const QualityEducationIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=> {
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="qualityEducation" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .quality-education{fill - rule:evenodd;clip-rule:evenodd;fill:#C52234;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63" className="quality-education" d="M12.9,0.3h49.1c6.9,0,12.6,5.6,12.6,12.6v49.2c0,6.9-5.6,12.6-12.6,12.6H12.9
	C6,74.7,0.4,69,0.4,62.1V12.9C0.4,5.9,6,0.3,12.9,0.3z"/>
      <g id="text6065-3-2-9">
        <path id="path1969" className="st1" d="M22.5,12.8c-0.2-0.2-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.6V8.6c0-0.5,0.2-0.9,0.5-1.2
		c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5s0.5,0.7,0.5,1.1v3.3c0,0.2,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3l0.6,0.5l-0.5,0.6
		L24.7,13c-0.3,0.3-0.6,0.4-1,0.4c-0.2,0-0.4-0.1-0.6-0.2C22.8,13,22.6,12.9,22.5,12.8L22.5,12.8z M24,11.4l0.3,0.3V8.6
		c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.2
		c0,0.1,0,0.1,0,0.2v3.1l0,0c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1L23.6,12L24,11.4z"/>
        <path id="path1971" className="st1" d="M28.8,12.6c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2,0.2-0.5,0.2-0.7,0.2c-0.2,0-0.3,0-0.4-0.1
		c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2-0.1-0.3-0.1-0.4V6.9
		h0.9v4.8c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2C28,12,28,11.9,28,11.7V6.9
		h0.9v4.8c0,0.2,0,0.3-0.1,0.5C28.9,12.3,28.8,12.5,28.8,12.6L28.8,12.6z"/>
        <path id="path1973" className="st1" d="M29.4,13.2l1.4-6.3h0.8l1.5,6.3h-1l-0.3-1.4h-1.2l-0.3,1.4H29.4z M30.8,11.1h0.9l-0.4-2.3
		L30.8,11.1z"/>
        <path id="path1975" className="st1" d="M36.1,13.2h-2.7V6.9h0.9v5.4h1.8V13.2z"/>
        <path id="path1977" className="st1" d="M36.4,13.2V6.9h0.9v6.3H36.4z"/>
        <path id="path1979" className="st1" d="M38.9,13.2V7.8h-1.1V6.9h3v0.9h-1.1v5.4H38.9z"/>
        <path id="path1981" className="st1" d="M42.2,13.2v-2.7L41,6.9h1l0.8,2.5l0.8-2.5h1l-1.3,3.6v2.7H42.2z"/>
        <path id="path1983" className="st1"
          d="M24.9,21v0.9h-2.7v-6.3h2.7v0.8h-1.8v1.9h1.6v0.9h-1.6V21H24.9L24.9,21z"/>
        <path id="path1985" className="st1" d="M27,15.6c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.6,0.5,1v3.3c0,0.5-0.2,0.9-0.4,1.1
		c-0.3,0.3-0.7,0.5-1.1,0.5h-1.4v-6.3H27L27,15.6z M27.6,20.3v-3.3c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2h-0.5V21h0.5
		C27.4,21,27.6,20.8,27.6,20.3L27.6,20.3z"/>
        <path id="path1987" className="st1" d="M31.9,21.2c-0.2,0.2-0.3,0.4-0.6,0.5c-0.2,0.2-0.5,0.2-0.7,0.2c-0.2,0-0.3,0-0.4-0.1
		c-0.1-0.1-0.3-0.1-0.4-0.2s-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2-0.1-0.3-0.1-0.4v-4.8h0.9
		v4.8c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
		c0.1-0.2,0.2-0.3,0.2-0.5v-4.8h0.9v4.8c0,0.2,0,0.3-0.1,0.5C32.1,21,32,21.1,31.9,21.2L31.9,21.2z"/>
        <path id="path1989" className="st1" d="M35.8,20.3c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.4-0.3-0.5-0.5
		c-0.1-0.2-0.2-0.4-0.2-0.5s-0.1-0.3-0.1-0.5V17c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.1-0.3,0.3-0.5c0.1-0.2,0.3-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.7-0.2c0.5,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1v0.4h-0.9v-0.4c0-0.2-0.1-0.3-0.2-0.5
		c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v3.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.2,0.5,0.2
		c0.2,0,0.4-0.1,0.4-0.2c0.1-0.1,0.1-0.2,0.1-0.3s0-0.2,0-0.3v-0.3h0.9L35.8,20.3z"/>
        <path id="path1991" className="st1" d="M36.1,21.9l1.4-6.3h0.8l1.4,6.3h-1l-0.3-1.4h-1.2L37,21.9H36.1z M37.5,19.7h0.9L38,17.4
		L37.5,19.7z"/>
        <path id="path1993" className="st1"
          d="M40.5,21.9v-5.5h-1.1v-0.9h3v0.9h-1.1v5.5C41.4,21.9,40.5,21.9,40.5,21.9z"/>
        <path id="path1995" className="st1" d="M42.9,21.9v-6.3h0.9v6.3H42.9z"/>
        <path id="path1997" className="st1" d="M44.9,16.2c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.1,0.5-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2
		s0.4,0.2,0.5,0.4c0.2,0.2,0.3,0.4,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6v3.2c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.1,0.3-0.3,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.2-0.3-0.3
		c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4v-3.2c0-0.2,0-0.4,0.1-0.5
		C44.7,16.5,44.8,16.3,44.9,16.2L44.9,16.2z M46.6,16.7c-0.1-0.2-0.3-0.3-0.5-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.2v3.2c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1s0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.2,0.5,0.2
		c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0-0.2,0-0.3v-3.2c0-0.1,0-0.2,0-0.3
		C46.7,16.8,46.7,16.8,46.6,16.7L46.6,16.7z"/>
        <path id="path1999" className="st1" d="M50.6,21.9l-1.4-3.8v3.8h-0.9v-6.3h0.9l1.4,3.8v-3.8h0.9v6.3H50.6z"/>
      </g>
      <g id="text6075-0-6-9">
        <path id="path2002" className="st1"
          d="M12.3,21.6v-2.3H8.1v-2l3.1-10.7h2.3l-3.2,10.7h2.1V13h2.2v4.3h1.1v2h-1.1v2.3H12.3z"/>
      </g>
      <path id="path7004" className="st2" d="M57,30.4c-0.8,0-1.5,0.4-1.5,1.3v2.3h3.1v-2.3C58.5,30.8,57.7,30.4,57,30.4z M55.4,34.8v22.3
	l1.5,5l1.5-5V34.8H55.4z M20.1,32.6v24.7l13.1,4V40.2L20.1,32.6z M33,64.2l-15.8-4.5V33.7l1.4-1.1v26.1L33.4,63h1.2l14.8-4.3V32.6
	l1.4,1.1v26.1l-15.8,4.5H33z M47.9,32.6v24.7l-13.1,4V40.2L47.9,32.6z"/>
    </svg>
  );
};

export default QualityEducationIcon;
