import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Venus = ({ style, size = 45, className, color = 'white' }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="venus" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" width={size} height={size} className={className} style={style} color={color}>
      <style type="text/css">
        {`
             .stvenus0{fill:#FFFFFF;}
             .stvenus1{fill:#E14F22;}
             .stvenus2{fill:#E04F21;}
        `}
      </style>
      <g>
        <g id="XMLID_1_">
          <g>
            <path className="stvenus0" d="M484.1,228.4c56.8,0,103.1,46.2,103.1,103.1s-46.2,103-103.1,103c-56.8,0-103.1-46.2-103.1-103
				S427.3,228.4,484.1,228.4z"/>
            <path className="stvenus0" d="M484.1,76.4c235.2,0,426.5,191.4,426.5,426.5c0,235.2-191.3,426.5-426.5,426.5
				C248.9,929.5,57.6,738.1,57.6,503C57.6,267.8,248.9,76.4,484.1,76.4z M688.9,639.5c0-18.4-14.9-33.3-33.3-33.3H517.4V497.8
				c77.6-15.5,136.3-84.2,136.3-166.3c0-93.5-76.1-169.6-169.6-169.6S314.5,238,314.5,331.5c0,82.1,58.7,150.8,136.3,166.3v108.4
				H312.7c-18.4,0-33.3,14.9-33.3,33.3c0,18.4,14.9,33.3,33.3,33.3h138.2v138c0,18.4,14.9,33.3,33.3,33.3
				c18.4,0,33.3-14.9,33.3-33.3v-138h138.2C674,672.7,688.9,657.8,688.9,639.5z"/>
            <path className="stvenus1" d="M484.1,46c252,0,456.9,205,456.9,457s-205,456.9-456.9,456.9s-457-205-457-456.9S232.2,46,484.1,46z
				 M910.7,503c0-235.2-191.3-426.5-426.5-426.5C248.9,76.4,57.6,267.8,57.6,503c0,235.2,191.3,426.5,426.5,426.5
				C719.3,929.5,910.7,738.1,910.7,503z"/>
            <path className="stvenus2"
              d="M655.6,606.2c18.4,0,33.3,14.9,33.3,33.3c0,18.4-14.9,33.3-33.3,33.3H517.4v-66.5H655.6z"/>
            <path className="stvenus2" d="M484.1,161.9c93.5,0,169.6,76.1,169.6,169.6c0,82.1-58.7,150.8-136.3,166.3v-30c0-18.4-14.9-33.3-33.3-33.3
				c56.8,0,103.1-46.2,103.1-103S541,228.4,484.1,228.4c-56.8,0-103.1,46.2-103.1,103.1s46.2,103,103.1,103
				c-18.4,0-33.3,14.9-33.3,33.3v30c-77.6-15.5-136.3-84.2-136.3-166.3C314.5,238,390.6,161.9,484.1,161.9z"/>
            <path className="stvenus2"
              d="M517.4,672.7v138c0,18.4-14.9,33.3-33.3,33.3c-18.4,0-33.3-14.9-33.3-33.3v-138H517.4z"/>
            <rect x="450.9" y="606.2" className="stvenus2" width="66.5" height="66.5"/>
            <path className="stvenus2"
              d="M517.4,497.8v108.4h-66.5V497.8c10.8,2.2,21.9,3.3,33.3,3.3C495.5,501.1,506.6,500,517.4,497.8z"/>
            <path className="stvenus2" d="M484.1,434.6c18.4,0,33.3,14.9,33.3,33.3v30c-10.8,2.2-21.9,3.3-33.3,3.3c-11.4,0-22.5-1.1-33.3-3.3v-30
				C450.9,449.5,465.8,434.6,484.1,434.6z"/>
            <path className="stvenus2"
              d="M450.9,606.2v66.5H312.7c-18.4,0-33.3-14.9-33.3-33.3c0-18.4,14.9-33.3,33.3-33.3H450.9z"/>
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Venus;
