import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const PeacefulTurtle = ({ style, size = 45, className }: IconProps): React.ReactElement=>{
  return (
    <svg version="1.1" id="peaceful_turtle" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" height={size} className={className}  style={style}>
      <style type="text/css">
        {`
              .circle-peaceful-turtle{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
              .path-peaceful-turtle{fill:#FBBF2D;}
        `}
      </style>
      <circle className="circle-peaceful-turtle" cx="16.3" cy="16.3" r="15.7"/>
      <g>
        <path className="path-peaceful-turtle" d="M24.7,15.3c-0.2,0.2-0.5,0.4-0.7,0.6c0,0,0,0.1,0,0.1c0.2,0,0.4-0.1,0.7-0.1c0.8-0.1,1.5-0.2,2.3-0.2
		c1.2,0,1.5,0.7,1.4,1.8c0,0.3-0.3,0.5-0.7,0.5c-0.9,0-1.7,0-2.6,0c-0.3,0-0.7,0-1,0c-0.3,0-0.6,0.1-0.8,0.4
		c0.2,0.2,0.5,0.3,0.7,0.5c0.6,0.4,0.7,0.9,0.3,1.5c-0.3,0.4-0.6,0.8-0.9,1.2c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.9-0.1-1.3-0.2
		c-0.2-0.1-0.3-0.3-0.1-0.5c0.2-0.3,0.5-0.5,0.7-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.2-0.4-0.1-0.6c-0.2-0.1-0.3-0.2-0.5-0.3
		c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.4-1.2,0.5-1.8,0.5c-2.2,0-4.3,0.1-6.5,0c-0.7,0-1.4-0.2-2-0.3c-0.1,0-0.3,0-0.4,0
		c-0.4,0.2-0.8,0.3-1.2,0.5c-0.5,0.2-0.8,0.6-1,1.1C7.5,21.9,7.4,22,6.6,22c-0.3,0-0.6,0-0.9-0.1c-0.4,0-0.6-0.4-0.3-0.7
		c0.8-1,1.4-2.1,2.7-2.4c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0.1-0.1c-1.4,0-2.7,0.2-4-0.1c0,0,0-0.1,0-0.1c1.1-0.2,2.2-0.4,3.3-0.6
		c0,0,0-0.1,0-0.1c-0.1-0.2-0.3-0.3-0.4-0.4c1.2-1.5,2.4-3.1,3.5-4.6c0.7-0.9,1.6-1.7,2.6-2.2C14.7,10,16,9.9,17.3,10
		c1.9,0.2,3.4,1,4.6,2.4C22.8,13.4,23.7,14.3,24.7,15.3z M27,16.2c-0.1,0.2-0.2,0.3-0.2,0.4c0,0.1,0.2,0.2,0.2,0.2
		c0.1,0,0.2-0.1,0.2-0.2C27.3,16.4,27.1,16.3,27,16.2z"/>
      </g>
    </svg>
  );
};

export default PeacefulTurtle;
