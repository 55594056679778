import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { NotificationContainer } from './components/common/react-notifications';
import UserContextProvider from './contexts/user/UserContextProvider';
import { getDirection } from './helpers/Utils';
import availableLanguages from 'lang/availableLanguages';
import RouterMap from './router';
import * as Sentry from "@sentry/react";
import { transformMessages } from './helpers/utils-typescript';

// the following fixes the problem that we have with react-image-lightbox (global is not defined)
// after the package is removed, this can probably too be removed
window.global ||= window;

interface RootState {
  settings: {
    locale: string;
  };
}

class App extends React.Component<PropsFromRedux> {
  constructor(props: PropsFromRedux) {
    super(props);
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }
  }

  render() {
    const { locale } = this.props;
    const currentLanguageTranslations = availableLanguages[locale] || availableLanguages['en'];
    return (
      <UserContextProvider>
        <IntlProvider 
          locale={locale} 
          defaultLocale="en"
          messages={transformMessages(currentLanguageTranslations)}
          onError={(err) => {
            console.warn('IntlProvider error:', err);
          }}
        >
          <NotificationContainer />
          <RouterMap />
        </IntlProvider>
      </UserContextProvider>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { locale } = state.settings;
  return { locale };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Sentry.withProfiler(App));