// see Sidebar.js
// svgIcon must be imported there as a component
// cssIcon must be available as a css class

const data = [
  {
    id: 'home',
    svgIcon: 'MeIcon',
    label: 'menu.home',
    to: '/profile',
  },
  {
    id: 'communities',
    svgIcon: 'CommunitiesIcon',
    label: 'menu.communities',
    to: '/communities',
  },
  {
    id: 'commons',
    svgIcon: 'CommonsIcon',
    label: 'bioregion.commons-plural',
    to: '/commons'
  },
  {
    id: 'ecoregions',
    svgIcon: 'EcoregionIcon',
    label: 'menu.ecoregions',
    to: '/ecoregions'
  },
  {
    id: 'events',
    svgIcon: 'ActivitiesIcon',
    label: 'menu.events',
    to: '/events',
  },
  {
    id: 'library',
    svgIcon: 'LibraryIcon',
    label: 'menu.library',
    to: '/library',
  },
  {
    id: 'leaderboard',
    svgIcon: 'LeaderboardIcon',
    label: 'menu.leaderboard',
    to: '/leaderboard',
  },
];
export default data;
