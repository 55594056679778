import 'bootstrap-social/bootstrap-social.css';
import { getAuthorizeURI, setLoginPayload } from 'helpers/helper';
import GithubIcon from 'mdi-react/GithubIcon';
import GoogleIcon from 'mdi-react/GoogleIcon';
import WechatIcon from 'mdi-react/WechatIcon';
import MicrosoftIcon from 'mdi-react/MicrosoftIcon';
import { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import UnsupportedWechatModal from './UsupportedWechatModal';
import './login.scss';
import { useIntl } from 'react-intl';

const useRedirectURLfromRedeemCode = () => {
  const history = useHistory() as any;
  const location = useLocation();

  const redeemCode = new URLSearchParams(location.search).get('redeemCode');
  if (redeemCode) {
    return `/profile?redeemCode=${redeemCode}`;
  }
  if (history.location.state?.from) {
    return history.location.state.from;
  }
  return '/profile';
};

const WechatLoginButton = ({ isMobileBrowserChecker, isWechatChecker }: SocialLoginOptionsProps) => {
  // login from redeemQRCode
  const redirectURL = useRedirectURLfromRedeemCode();
  const intl = useIntl();

  // a modal to tell mobile users to open ark in wechat application in order to use wechat login
  const [showWechatModal, setShowWechatModal] = useState<boolean>(false);

  return (
    /* Wechat: Only if it is enabled in .env */
    import.meta.env.VITE_WECHAT_LOGIN_ENABLED === 'true' && // environment variables are always strings
    <Fragment>
      <Button
        block
        className="btn-wechat text-one text-center"
        href={
          isMobileBrowserChecker
            ? isWechatChecker
              ? getAuthorizeURI('wechat', redirectURL)
              : ''
            : getAuthorizeURI('wechat', redirectURL)
        }
        onClick={isMobileBrowserChecker && !isWechatChecker ? () => setShowWechatModal(true) : () => { setLoginPayload('social-login'); }}
      >
        <WechatIcon size='28' className='my-auto  mr-2' />
        {intl.formatMessage({ id: 'user.login-with' }, { loginProviderName: 'WeChat' })}
      </Button>
      <UnsupportedWechatModal showModal={showWechatModal} setShowWechatModal={setShowWechatModal} />
    </Fragment>
  );
};

const GoogleLoginButton = () => {
  const redirectURL = useRedirectURLfromRedeemCode();
  const intl = useIntl();

  return (
    /* Google: Only if it is enabled in .env */
    import.meta.env.VITE_GOOGLE_LOGIN_ENABLED === 'true' && // environment variables are always strings
    <Button
      block
      className="btn-google text-one text-center"
      href={getAuthorizeURI('google', redirectURL)}
      onClick={() => { setLoginPayload('social-login'); }}
    >
      <GoogleIcon size='26' className='my-auto  mr-2' />
      {intl.formatMessage({ id: 'user.login-with' }, { loginProviderName: 'Google' })}
    </Button>
  );
};

const MicrosoftLoginButton = () => {
  const redirectURL = useRedirectURLfromRedeemCode();
  const intl = useIntl();

  return (
    <Button
      block
      className="btn-microsoft text-one text-center"
      href={getAuthorizeURI('microsoft', redirectURL)}
      onClick={() => { setLoginPayload('social-login'); }}
    >

      <MicrosoftIcon size='26' className='my-auto mr-2' />
      {intl.formatMessage({ id: 'user.login-with' }, { loginProviderName: 'Microsoft' })}
    </Button>
  );
};

const GitHubLoginButton = () => {
  const redirectURL = useRedirectURLfromRedeemCode();
  const intl = useIntl();

  return (
    /* Github: Only if it is enabled in .env */
    import.meta.env.VITE_GITHUB_LOGIN_ENABLED === 'true' && // environment variables are always strings
    <Button
      block
      className="btn-social btn-github text-center"
      href={getAuthorizeURI('github', redirectURL)}
      onClick={() => { setLoginPayload('social-login'); }}
    >
      <GithubIcon size='28' className='my-auto  mx-auto' />
      {intl.formatMessage({ id: 'user.login-with' }, { loginProviderName: 'Github' })}
    </Button>
  );
};

interface SocialLoginOptionsProps {
  isMobileBrowserChecker: boolean;
  isWechatChecker: boolean;
}

const SocialLoginOptions = ({
  isMobileBrowserChecker,
  isWechatChecker,
}: SocialLoginOptionsProps) => {

  return (
    <Fragment>
      <div className="d-flex flex-column">
        <WechatLoginButton isMobileBrowserChecker={isMobileBrowserChecker} isWechatChecker={isWechatChecker} />
        <GoogleLoginButton />
        <MicrosoftLoginButton />
        <GitHubLoginButton />
      </div>

    </Fragment>
  );
};

export default SocialLoginOptions;
