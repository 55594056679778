import { gql } from '@apollo/client';
import { User } from 'services/graphqltypes';
import { apolloClient, ecoregionApolloClient } from './graphql';

/** For data fetched from Ecoregions Backend. Different from our own "Ecoregion" type */
export interface EcoBackendEcoregion {
  gid: number;
  ecoName: string;
  biomeName: string;
  realm: string;
  geom: {
    geojson: JSON;
  }
}

interface UserBioregion {
  id: string;
  username: string;
  bioregionGid: number;
}

export const ECOREGION_INFO = gql`
  query ecoregionInfo($ecoregionGid: Int!) {
    ecoregion(gid: $ecoregionGid) {
      ecoName
      biomeName
      realm
      gid
    }
  }
`;

export const ECOREGION_INFO_WITH_GEOJSON = gql`
  query ecoregionInfoWithGeojson($ecoregionGid: Int!) {
    ecoregion(gid: $ecoregionGid) {
      gid
      ecoId
      ecoName
      biomeName
      realm
      geom {
        geojson
      }
    }
  }
`;

export const BIOREGION_OF_POINT = gql`
  query BioregionOfPointLatLon($lat: Float!, $lon: Float!) {
    ecoregions(filter: { geom: { contains: { type: "Point", coordinates: [$lon, $lat] } } }) {
      nodes {
        gid
        ecoName
        biomeName
        realm
      }
    }
  }
`;

const UPDATE_USER_BIOREGION = gql`
  mutation UpdateUserBioregion($userId: UUID!, $bioregionId: Int!) {
    updateUser(input: { id: $userId, patch: { bioregionGid: $bioregionId } }) {
      clientMutationId
      user {
        id
        username
        bioregionGid
      }
    }
  }
`;

export async function ecoregionInfo(ecoregionGid: number): Promise<EcoBackendEcoregion> {
  const bioregionInfoGql = await ecoregionApolloClient.query({
    query: ECOREGION_INFO,
    variables: {
      ecoregionGid,
    },
  });

  const bioregionInfo = (bioregionInfoGql as any).data.ecoregion;
  return bioregionInfo;
}

async function bioregionFromGpsCoordinates(lat: number, lon: number): Promise<EcoBackendEcoregion> {
  const bioregionGql = await ecoregionApolloClient.query({
    query: BIOREGION_OF_POINT,
    variables: {
      lat,
      lon,
    },
  });


  const bioregions = (bioregionGql as any).data.ecoregions.nodes;
  if (bioregions.length !== 1) {
    throw new Error(`No bioregion corresponding to coordinates lat: ${lat} lon: ${lon}`);
  }
  return bioregions[0];
}

export async function updateUserBioregion(userId: string, bioregionId: number): Promise<UserBioregion> {
  const userBioregionGql = await apolloClient.mutate({
    mutation: UPDATE_USER_BIOREGION,
    variables: {
      userId,
      bioregionId,
    },
  });

  const userBioregion = (userBioregionGql as any).data.updateUser.user;
  return userBioregion;
}

export async function locateCurrentPosition(currentUser: User): Promise<EcoBackendEcoregion> {
  return new Promise<EcoBackendEcoregion>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async (position: any) => {
        const bioregion = await bioregionFromGpsCoordinates(position.coords.latitude, position.coords.longitude);
        updateUserBioregion(currentUser.id, bioregion.gid).then((userBioregion) => {
          console.log('User bioregion', userBioregion);
        });
        resolve(bioregion);
      },
      reject,
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 1000,
      }
    );
  });
}
