import WelcomeOnBoard from 'components/onboarding/WelcomeOnBoard';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { EcoBackendEcoregion } from 'services/bioregion';
import { User, UserLearningPath } from 'services/graphqltypes';

export type OnboardingProps = {
  userInfo?: {
    currentUser?: User,
  }
  modalState?: {
    showModal?: boolean,
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>,
  }
  bioregionInfo?: {
    currentBioregion: EcoBackendEcoregion,
    setCurrentBioregion: React.Dispatch<React.SetStateAction<EcoBackendEcoregion>>,
  }
  userLearningPaths?: UserLearningPath[],
  setStepId?: React.Dispatch<React.SetStateAction<number>>,
  userObject?: User,
}

/** A modal that shows on first log-in, lets user set their display name */
const Onboarding = ({ userInfo }: OnboardingProps) => {
  const [showModal, setShowModal] = useState(true);

  return (
    <Modal
      show={showModal}
      backdrop="static" /* prevent closing modal when clicked outside it*/
      keyboard={false} /* prevent closing the modal using Keyboard events */
      size="lg"
      backdropTransition={{ timeout: 400 }}
      className='rounded-modal'>
      <Modal.Body className="px-3">
        <WelcomeOnBoard userInfo={userInfo} modalState={{ setShowModal }} />
      </Modal.Body>
    </Modal>
  );
};

export default Onboarding;
