import React from 'react';
import IconProps from './IconProps';

const CriticalThinkingIcon = ({ style, color = '#7f4f25', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="criticalThinking" data-name="criticalThinking" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
      style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <g>
          <g>
            <path d="M430.2,978.7c-16.9,0-30.7-13.7-30.7-30.7v-35.9H117.2V677.5H0l112.8-387.8
				c54.4-106.2,157.3-177.8,275.3-191.8c17.1-1.7,32.1,10,34.1,26.9c2,16.8-10.1,32.1-26.9,34.1c-96.7,11.4-181.2,69.7-226.6,156.2
				l-87.1,301h96.9v234.7h282.3V948C460.9,965,447.2,978.7,430.2,978.7z"/>
          </g>
        </g>
        <g>
          <path d="M826.4,814.4c-12.2,0-23.8-7.4-28.6-19.5L570.4,216.1c-6.2-15.8,1.6-33.6,17.3-39.8
			c15.7-6.1,33.6,1.6,39.8,17.3L855,772.5c6.2,15.8-1.6,33.6-17.3,39.8C834,813.7,830.2,814.4,826.4,814.4z"/>
        </g>
        <g>
          <path d="M551.3,689.6c-8.9,0-17.7-3.8-23.7-11.2c-10.7-13.1-8.8-32.4,4.3-43.2l282.7-232c13.1-10.7,32.4-8.8,43.2,4.3
			c10.7,13.1,8.8,32.4-4.3,43.2l-282.7,232C565,687.3,558.2,689.6,551.3,689.6z"/>
        </g>
        <g>
          <path d="M856.5,814.4c-0.7,0-1.4,0-2.2-0.1c-16.9-1.2-29.6-15.8-28.4-32.8l23-328.7c1.2-16.9,15-29.2,32.8-28.4
			c16.9,1.2,29.6,15.8,28.4,32.8l-23,328.7C886,802,872.5,814.4,856.5,814.4z"/>
        </g>
        <g>
          <path d="M411,495.2c-14.6,0-27.5-10.4-30.2-25.3c-3-16.7,8.1-32.6,24.8-35.6L811,362c16.9-2.8,32.6,8.1,35.6,24.8
			c3,16.7-8.1,32.6-24.8,35.6l-405.4,72.3C414.6,495,412.8,495.2,411,495.2z"/>
        </g>
        <g>
          <path d="M378.2,445c-5.8,0-11.7-1.7-17-5.2c-14.1-9.4-17.9-28.4-8.5-42.5L500.9,175c9.4-14.1,28.4-17.9,42.5-8.5
			c14.1,9.4,17.9,28.4,8.5,42.5L403.8,431.3C397.9,440.2,388.1,445,378.2,445z"/>
        </g>
        <g>
          <path d="M568.8,235.6c-59.1,0-107.1-48.1-107.1-107.1S509.8,21.3,568.8,21.3S676,69.3,676,128.4
			S627.9,235.6,568.8,235.6z M568.8,82.6c-25.2,0-45.8,20.5-45.8,45.8s20.5,45.8,45.8,45.8c25.2,0,45.8-20.5,45.8-45.8
			S594.1,82.6,568.8,82.6z"/>
        </g>
        <g>
          <path d="M892.9,485.7c-59.1,0-107.1-48.1-107.1-107.1s48.1-107.1,107.1-107.1c59.1,0,107.1,48.1,107.1,107.1
			S951.9,485.7,892.9,485.7z M892.9,332.8c-25.2,0-45.8,20.5-45.8,45.8s20.5,45.8,45.8,45.8c25.2,0,45.8-20.5,45.8-45.8
			S918.1,332.8,892.9,332.8z"/>
        </g>
        <g>
          <path d="M335.8,585.1c-59.1,0-107.1-48.1-107.1-107.1s48.1-107.1,107.1-107.1S443,418.9,443,477.9
			S394.9,585.1,335.8,585.1z M335.8,432.2c-25.2,0-45.8,20.5-45.8,45.8s20.5,45.8,45.8,45.8s45.8-20.5,45.8-45.8
			S361.1,432.2,335.8,432.2z"/>
        </g>
        <g>
          <path d="M492.4,814.4c-59.1,0-107.1-48.1-107.1-107.1s48.1-107.1,107.1-107.1s107.1,48.1,107.1,107.1
			S551.5,814.4,492.4,814.4z M492.4,661.5c-25.2,0-45.8,20.5-45.8,45.8s20.5,45.8,45.8,45.8s45.8-20.5,45.8-45.8
			S517.6,661.5,492.4,661.5z"/>
        </g>
        <g>
          <path d="M856.5,967.3c-59.1,0-107.1-48.1-107.1-107.1c0-59.1,48.1-107.1,107.1-107.1c59.1,0,107.1,48.1,107.1,107.1
			C963.6,919.3,915.6,967.3,856.5,967.3z M856.5,814.4c-25.2,0-45.8,20.5-45.8,45.8c0,25.2,20.5,45.8,45.8,45.8
			c25.2,0,45.8-20.5,45.8-45.8C902.3,835,881.7,814.4,856.5,814.4z"/>
        </g>
      </g>
    </svg>
  );
};

export default CriticalThinkingIcon;
