import { useMemo } from 'react';
import { CommunityUser } from 'services/graphqltypes';
import { gql, useQuery } from '@apollo/client';

const CURRENT_USER_COMMUNITIES_PERMISSIONS = gql`
query currentUserCommunitiesPermissions {
  currentUser {
    communityUsers {
      nodes {
        id
        communityRolePermissions {
          communityId
          createEventCourse
          updateCommunityProfile
        }
      }
    }
  }
}
`;


/**
 * this hook checks if current user has the 'createEventCourse' permission in at least one community,
 * @returns an object with 2 properties
 * 1. hasCreateActivityPermission: boolean (is false if still loading)
 * 2. createActivityPermissionLoading: boolean (enables you to wait with rendering if you render something when user doesn't have the permission)
 *
 * Very short while after log in, the currentUser query doesn't work yet -> this function will return undefined (just as it returns for non-logged-in)
 *
 */
const useHasCreateActivityPermission = () => {
  const { loading: createActivityPermissionLoading, error, data } = useQuery(CURRENT_USER_COMMUNITIES_PERMISSIONS);
  return useMemo(() => {
    let hasCreateActivityPermission;
    if (error || createActivityPermissionLoading || data?.currentUser?.communityUsers?.nodes.length === 0) {
      hasCreateActivityPermission = false;
    } else {
      hasCreateActivityPermission = data?.currentUser?.communityUsers?.nodes.some((communityUser: CommunityUser) => {
        return communityUser.communityRolePermissions.createEventCourse === true; // check if createEventCourse is true in at least one community
      });
    }
    return { createActivityPermissionLoading, hasCreateActivityPermission };
  }, [createActivityPermissionLoading, error, data]);
};

export default useHasCreateActivityPermission;
