import React from 'react';
import HtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { getTranslatedString } from 'helpers/utils-typescript';
import { Scalars } from 'services/graphqltypes';

type MultilingualHtmlParserProps = {
  multilingualHtml: Scalars['JSON'];
};

/**
 * Takes a multilingual html object and returns the html in the current language
 */
const MultilingualHtmlParser = ({ multilingualHtml }: MultilingualHtmlParserProps): React.ReactElement => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  return <div className="html-parser">{HtmlParser(getTranslatedString(multilingualHtml, currentLang))}</div>;
};

export default MultilingualHtmlParser;
