/*                                                                             */
/* This component renders the User's Avatar with Mentor Badge                  */
/* *if user has avatar, show avatar, otherwise use abb. of their username.     */
/* *show mentor badge if any                                                   */
/*                                                                             */
/* Currently used in:                                                          */
/* * DesignByListItem                                                          */
/* * CreatePostCard                                                            */
/*                                                                             */


import ThumbnailImage from 'components/custom/ThumbnailImage';
import ThumbnailLetters from 'components/custom/ThumbnailLetters';
import { Badge } from 'react-bootstrap';
import { User } from 'services/graphqltypes';

type UserAvatarWithMentorBadge = {
  user: User,
  /** for customize avatar size e.g 2.5rem */
  avatarHeight?: string,
  /** for mentor badge size  e.g 1.25rem */
  mentorBadgeHeight?: string,
  /** additional classnames that will be added to the outermost div returned by this component */
  className?: string,
}

const UserAvatarWithMentorBadge = ({ user, avatarHeight, mentorBadgeHeight, className }: UserAvatarWithMentorBadge) => {
  const avatarUrl = user?.avatarUrl;
  const isTerritoryMentor = user?.isTerritoryMentor;
  const isEcoregionMentor = user?.isEcoregionMentor;
  const name = user?.name;

  return (
    <div className={`${className} col-auto align-self-center px-0`}>
      {/* user avatar */}
      {
        avatarUrl ? (
          <ThumbnailImage rounded small src={avatarUrl} alt="Profile picture" className={isTerritoryMentor ? 'mr-2' : 'mr-3'} style={{ height: avatarHeight }} />
        ) : (
          <ThumbnailLetters rounded small text={name} className={isTerritoryMentor ? 'mr-2' : 'mr-3'} style={{ height: avatarHeight }} />
        )
      }
      {/* ecoregion mentor badge */}
      {
        isEcoregionMentor &&
        <Badge
          className={'position-relative badge-bottom-right-user-card pl-0 pt-0 pb-0'}
        >
          <ThumbnailImage
            rounded
            src='/assets/img/profiles/ecoregion-mentor-badge.png'
            alt="Ecoregion mentor badge"
            className="mr-2 img-thumbnail-badge position-absolute "
            style={{ border: '1px solid #dee2e6', borderRadius: '.25rem', height: mentorBadgeHeight, }}
          />
        </Badge>
      }

      {/* mentor badge - only show if user is not an ecoregion mentor */}
      {
        isTerritoryMentor && !isEcoregionMentor &&
        <Badge
          className={'position-relative badge-bottom-right-user-card pl-0 pt-0 pb-0'}
        >
          <ThumbnailImage
            rounded
            src='/assets/img/profiles/commons-mentor-badge.png'
            alt="Commons mentor badge"
            className="mr-2 img-thumbnail-badge position-absolute "
            style={{ border: '1px solid #dee2e6', borderRadius: '.25rem', height: mentorBadgeHeight, }}
          />
        </Badge>
      }
    </div >

  );
};

export default UserAvatarWithMentorBadge;
