import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const GoodHealthWellBeingIcon = ({ style, size = 100, className }: IconProps):React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="goodHealth" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .good-health{fill - rule:evenodd;clip-rule:evenodd;fill:#279B48;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <g id="rect8528-6">
        <path className="good-health" d="M13,0.4H62c6.9,0,12.5,5.6,12.5,12.5v49c0,6.9-5.6,12.5-12.5,12.5H13C6.1,74.5,0.5,68.9,0.5,62v-49
		C0.5,6,6.1,0.4,13,0.4z"/>
      </g>
      <g id="text6065-3-2">
        <g id="path1921">
          <path className="st1" d="M25.1,12.6c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
			c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5V8.6c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.4,0.3-0.6
			c0.2-0.3,0.5-0.4,1.1-0.4c0.2,0,0.5,0,0.7,0.2s0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3,0,0.5V9h-0.9V8.7
			c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.2C24.2,8.1,24.1,8,23.9,8c-0.2,0-0.4,0-0.5,0.2c0,0.1-0.1,0.2-0.1,0.3
			c0,0,0,0.1,0,0.2v3c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.5,0.2s0.4,0,0.4-0.2c0,0,0-0.1,0.1-0.1
			c0,0,0-0.1,0-0.1s0,0,0-0.1s0-0.1,0-0.1v-1.1h-0.7V9.8h1.6v1.9c0,0.1,0,0.2,0,0.3s0,0.1,0,0.2c0,0,0,0.1,0,0.2
			C25.2,12.5,25.2,12.5,25.1,12.6L25.1,12.6z"/>
        </g>
        <g id="path1923">
          <path className="st1" d="M26.3,7.6c0.1-0.2,0.3-0.3,0.5-0.4C27.1,7.1,27.3,7,27.6,7s0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
			S29,7.9,29,8c0,0.1,0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2
			c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4
			c0-0.1,0-0.2,0-0.4V8.7c0-0.2,0-0.3,0-0.5C26.2,7.9,26.2,7.8,26.3,7.6L26.3,7.6z M28.1,8.1c-0.1-0.2-0.3-0.2-0.5-0.2
			c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0,0.1,0.1,0.3
			c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.6c0-0.1,0-0.2,0-0.2
			C28.2,8.3,28.1,8.2,28.1,8.1L28.1,8.1z"/>
        </g>
        <g id="path1925">
          <path className="st1" d="M30.1,7.6c0.1-0.2,0.3-0.3,0.5-0.4C30.9,7.1,31.1,7,31.3,7s0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
			c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0s-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.2,0-0.4V8.7c0-0.2,0-0.3,0-0.5C30,7.9,30,7.8,30.1,7.6L30.1,7.6z M31.8,8.1
			c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1
			c0,0,0,0,0,0.1s0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
			c0,0,0-0.1,0-0.2V8.6c0-0.1,0-0.2,0-0.2C31.9,8.3,31.9,8.2,31.8,8.1L31.8,8.1z"/>
        </g>
        <g id="path1927">
          <path className="st1" d="M35.1,7c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1s-0.6,0.4-1.1,0.4h-1.4V7H35.1z
			 M35.7,11.7V8.5c0-0.2,0-0.3-0.2-0.4C35.4,8,35.2,7.9,35,7.9h-0.4v4.6H35C35.4,12.4,35.7,12.2,35.7,11.7z"/>
        </g>
        <g id="path1929">
          <path className="st1" d="M38.2,13.3V7h0.9v2.7h1.1V7.1h0.9v6.2h-0.9v-2.8h-1.1v2.8H38.2z"/>
        </g>
        <g id="path1931">
          <path className="st1" d="M44.7,12.4v0.9H42V7h2.7v0.8h-1.8v1.9h1.5v0.8h-1.5v1.8L44.7,12.4z"/>
        </g>
        <g id="path1933">
          <path className="st1"
            d="M44.8,13.3l1.4-6.2H47l1.4,6.2h-0.9l-0.2-1.3H46l-0.2,1.3H44.8z M46.2,11.1H47l-0.4-2.2L46.2,11.1z"/>
        </g>
        <g id="path1935">
          <path className="st1" d="M51.5,13.3h-2.6V7h0.9v5.3h1.7V13.3L51.5,13.3z"/>
        </g>
        <g id="path1937">
          <path className="st1" d="M51.9,13.3V7.9h-1V7h3v0.8h-1.1v5.4C52.8,13.3,51.9,13.3,51.9,13.3z"/>
        </g>
        <g id="path1939">
          <path className="st1" d="M54.4,13.3V7h0.9v2.7h1.1V7.1h0.9v6.2h-0.9v-2.8h-1.1v2.8H54.4z"/>
        </g>
        <g id="path1941">
          <path className="st1"
            d="M21.9,21.9l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1l-0.2,1.3H21.9z M23.3,19.7h0.8l-0.4-2.2L23.3,19.7z"/>
        </g>
        <g id="path1943">
          <path className="st1" d="M28.3,21.9l-1.4-3.8v3.8H26v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H28.3z"/>
        </g>
        <g id="path1945">
          <path className="st1" d="M31.4,15.6c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4
			h-1.4v-6.2H31.4L31.4,15.6z M32,20.3v-3.2c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4V21h0.4C31.8,21,32,20.8,32,20.3z"/>
        </g>
        <g id="path1947">
          <path className="st1"
            d="M37.5,21.9l-0.7-3.8l-0.7,3.8h-0.8l-0.9-6.2h0.9l0.4,3.8l0.7-3.8h0.6l0.7,3.8l0.4-3.8h0.9l-0.8,6.2H37.5z"/>
        </g>
        <g id="path1949">
          <path className="st1" d="M42.4,21v0.9h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5V21L42.4,21L42.4,21z"/>
        </g>
        <g id="path1951">
          <path className="st1" d="M45.4,21.9h-2.6v-6.2h0.9V21h1.7V21.9z"/>
        </g>
        <g id="path1953">
          <path className="st1" d="M48.3,21.9h-2.6v-6.2h0.9V21h1.7V21.9z"/>
        </g>
        <g id="path1955">
          <path className="st1" d="M51.3,15.6c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.2,0.2,0.3,0.5,0.3,0.9v0.5c0,0.5-0.2,0.8-0.5,1
			c0.4,0.2,0.5,0.5,0.5,1v0.7c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4v-6.3H51.3z M51.8,17.5v-0.4c0-0.2,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v1.7h0.5c0.2,0,0.3,0,0.4-0.2C51.7,17.9,51.8,17.7,51.8,17.5z M51.8,20.4v-0.7c0-0.2,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v2h0.5c0.2,0,0.3,0,0.4-0.2C51.7,20.7,51.8,20.5,51.8,20.4L51.8,20.4z"/>
        </g>
        <g id="path1957">
          <path className="st1" d="M56,21v0.9h-2.7v-6.2H56v0.8h-1.8v1.8h1.5v0.8h-1.5V21L56,21L56,21z"/>
        </g>
        <g id="path1959">
          <path className="st1" d="M56.4,21.9v-6.2h0.9v6.2H56.4z"/>
        </g>
        <g id="path1961">
          <path className="st1" d="M60.4,21.9L59,18.1v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H60.4z"/>
        </g>
        <g id="path1963">
          <path className="st1" d="M64.4,21.2c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
			c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.2-0.5s0-0.3,0-0.5v-3.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.4,0.3-0.6
			c0.2-0.3,0.5-0.4,1.1-0.4c0.2,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3,0,0.5v0.2h-0.9v-0.3
			c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
			s0,0.1,0,0.2v3.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.3C62.8,21,63,21,63.2,21s0.4,0,0.4-0.2c0,0,0-0.1,0.1-0.1
			c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v-1.1h-0.7v-0.8h1.6v1.9c0,0.1,0,0.2,0,0.3s0,0.1,0,0.2c0,0,0,0.1-0.1,0.2
			C64.5,21.1,64.5,21.2,64.4,21.2L64.4,21.2z"/>
        </g>
      </g>
      <g id="text6075-0-6">
        <g id="path1966">
          <path className="st1" d="M15.1,18.2v0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.5c-0.2,0.7-0.6,1.3-1.2,1.7c-0.5,0.4-1.2,0.6-2,0.6
			c-0.6,0-1.1-0.2-1.7-0.5c-0.5-0.3-0.9-0.7-1-1.1c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4
			c0-0.1,0-0.3,0-0.6v-0.8h2.1v1.2c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.4,0.4,0.8,0.4s0.6-0.1,0.8-0.4c0.1-0.2,0.2-0.6,0.2-1v-2.3
			c0-0.3,0-0.5-0.2-0.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0h-0.4v-1.9h0.4c0.9,0,1.3-0.4,1.3-1.2
			V9.8c0-0.2,0-0.3,0-0.4c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.4-0.1
			c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v1.1H8.7V9.8c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.3-0.9,2.3-0.9
			c0.5,0,1,0.1,1.5,0.4c0.5,0.2,0.8,0.6,1,0.9c0.2,0.3,0.4,0.7,0.4,1s0.1,0.6,0.1,1v1.7c0,0.9-0.4,1.7-1.2,2.2c0.5,0.3,0.8,0.7,1,1
			c0.2,0.3,0.3,0.7,0.3,1.3L15.1,18.2z"/>
        </g>
      </g>
      <g id="path6921">
        <path className="st1" d="M40.8,63.1C40.8,63.1,40.8,63.1,40.8,63.1c-0.4,0-0.8-0.3-0.8-0.7l-2.7-15.1l-4,7.1c-0.2,0.3-0.5,0.5-0.9,0.4
		c-0.4-0.1-0.6-0.4-0.7-0.7L30,42.5l-3.1,6.8c-0.1,0.3-0.4,0.5-0.8,0.5H13.9c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h11.7
		l4.1-8.9c0.2-0.3,0.5-0.5,0.9-0.5c0.4,0.1,0.7,0.3,0.7,0.7l1.8,11.9l3.9-7c0.2-0.3,0.5-0.5,0.9-0.4c0.4,0.1,0.6,0.3,0.7,0.7l2.5,14
		l8.9-29.3c0.1-0.3,0.4-0.6,0.7-0.6c0.3,0,0.7,0.2,0.8,0.5l4.6,9.4c0.2,0.4,0,0.9-0.4,1.1c-0.4,0.2-0.9,0-1.1-0.4l-3.6-7.5
		l-9.3,30.6C41.5,62.8,41.2,63.1,40.8,63.1z"/>
      </g>
      <g id="path6945">
        <path className="st2" d="M56.9,54.3c0,0,3.3-3.4,5-5.1c0.5-0.5,0.9-1.3,0.9-2.3c0-1.7-0.7-3.9-3.2-3.9c-1.4,0-2.6,1.4-2.7,1.5
		c-0.2,0.2-0.4,0.2-0.6,0c-0.1-0.1-1.3-1.5-2.7-1.5c-2.5,0-3.2,2.2-3.2,3.9c0,0.9,0.4,1.7,0.9,2.3c1.7,1.7,5,5.1,5,5.1
		C56.5,54.5,56.7,54.5,56.9,54.3L56.9,54.3z"/>
      </g>
    </svg>

  );
};

export default GoodHealthWellBeingIcon;
