import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Option } from '../components/custom/CustomReactSelect';
import { getUiTranslation } from '../helpers/utils-typescript';
import { ecoregionApolloClient } from '../services/graphql';

const ECOREGIONS = gql`
  query bioregions {
    ecoregions {
      nodes {
        ecoName
        biomeName
        realm
        gid
      }
    }
  }
`;

/* */
/* Options for ecoregion dropdown */
/* */
const useEcoregionOptions = (allowNone = true) => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const { loading: ecoregionOptionsLoading, data: ecoregionData, error: ecoregionError } = useQuery(ECOREGIONS, {
    client: ecoregionApolloClient,
  });

  const ecoregionOptions = useMemo<Option[]>(() => {
    const option: Option = allowNone ? { value: '', label: getUiTranslation('general.none', currentLang) } : {} as Option;
    if (ecoregionOptionsLoading) {
      return [{ ...option }];
    }
    if (ecoregionError) {
      option.label = getUiTranslation('error.missing-location', currentLang);
      return [{ ...option }];
    }
    const ecoregionOptions = ecoregionData.ecoregions.nodes.map((ecoregion: any) => {
      return {
        value: ecoregion.gid,
        label: ecoregion.ecoName,
      };
    });
    return [{ ...option }, ...ecoregionOptions];
  }, [ecoregionData, ecoregionOptionsLoading, ecoregionError, currentLang, allowNone]);

  return { ecoregionOptions, ecoregionOptionsLoading };
};

export default useEcoregionOptions;
