import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const StrongBull = ({ style, size = 45, className }: IconProps): React.ReactElement=>{
  return (
    <svg version="1.1" id="strong_bull" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" height={size} className={className}  style={style}>
      <style type="text/css">
        {`
               .circle-strong-bull{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
               .path-strong-bull{fill:#FBBF2D;}
        `}
      </style>
      <circle className="circle-strong-bull" cx="16.3" cy="16.3" r="15.7"/>
      <g>
        <g>
          <path className="path-strong-bull" d="M9.6,18c0.2,0.2,0.3,0.3,0.5,0.4c0.7,0.8,1.6,1.1,2.6,1.1c1.1,0,2.2,0,3.3,0c0.4-0.8-0.3-1.6-0.2-2.5
			c0,0,0.1,0,0.1,0c0.1,0.7,0.3,1.5,0.4,2.2c0.1,0.6-0.1,1.3,0,2c0.1,0.7-0.1,1.4-0.4,2.1c-0.1,0.3-0.4,0.6,0.1,0.8c0,0,0,0,0.1,0.1
			c0.1,0.2,0.1,0.5,0.2,0.6c0.4,0.1,0.7,0.1,1.2,0.2c-0.1-0.2-0.1-0.4-0.1-0.4c-0.5-0.7-0.5-1.5-0.2-2.1c0.5-1.1,0.9-2.3,1.3-3.5
			c0,0,0-0.1,0.1-0.1c0,0.2-0.1,0.5-0.1,0.8c0.7-0.3,1.2-0.6,1.5-1.3c0.2-0.4,0.4-0.7,1-0.7c0.4,0,0.8-0.3,1.1-0.5
			c-0.5-0.9-1-1.7-1.5-2.5c-0.8,0.2-2-0.4-2.3-1.2c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.2,0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.6-0.2-1.1-0.5-1.5-1.1c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.6,0.1-0.9,0.2c-1.4,0.7-2.7,0.4-4.1,0c-0.4-0.1-0.8-0.1-1.2,0
			c-0.7,0.2-1.4,0.3-2.1,0.5C7.6,12,7.3,12.1,7,12.3c-0.2,0.1-0.4,0.3-0.5,0.5c-0.5,1-0.6,2-0.5,3.1c0.1,0.7,0.1,1.4,0.1,2.1
			c0,0.1,0,0.2,0.1,0.3c0.4-0.5,0.6-1.1,0.4-1.7c-0.2-0.7-0.3-1.5-0.1-2.3c0.1,0.1,0.1,0.2,0.1,0.3c0.4,1.6,0.8,3.3,1.1,4.9
			c0,0.2,0.1,0.4,0,0.6c-0.2,0.5,0,0.8,0.3,1.2c0.4,0.6,0.7,1.3,1.1,2c0.1,0.1,0.1,0.2,0.1,0.3c-0.2,0.4-0.1,0.7,0.2,1
			c0.4,0.4,0.9,0.6,1.4,0.4c0.3-0.1,0.2-0.3,0.1-0.5c-0.9-0.9-1.1-2.1-1.2-3.3C9.5,20.2,9.6,19.2,9.6,18z"/>
        </g>
        <g>
          <path className="path-strong-bull" d="M22.7,11.5c0.3,0,0.5,0,0.8,0c0.1,0,0.3,0.1,0.3,0.2c0.4,0.7,0.7,1.3,0.5,2.2c-0.2,0.6-0.3,1.3,0,2
			c0.1,0.2-0.1,0.5-0.2,0.7c-0.5,0.5-1.8,0.3-2.2-0.2c-0.4-0.6-0.6-1.2-0.9-1.9c-0.1-0.3-0.3-0.4-0.5-0.3c-0.8,0.4-1.3-0.1-1.8-0.6
			c-0.1-0.1,0-0.4,0.1-0.4c0.5-0.1,0.9-0.3,1.4-0.2c0.8,0.1,1,0,1.2-0.7c0.1-0.6,0.5-0.7,1-0.7C22.5,11.6,22.6,11.5,22.7,11.5
			C22.7,11.5,22.7,11.5,22.7,11.5z M22.8,15.5c-0.3,0-0.3,0.1-0.2,0.4c0.1,0.4,0.5,0.4,0.9,0.4c0.4,0,0.5-0.3,0.5-0.8
			C23.6,15.5,23.2,15.5,22.8,15.5z"/>
        </g>
        <g>
          <path className="path-strong-bull" d="M18.1,7.5c-0.9,0.7-1.4,1.5-1.3,2.5c0.1,1,0.7,1.6,1.7,1.9c0.5,0.2,1.1,0.3,1.6,0.4c0.7,0.2,0.8,0.1,1-0.6
			c0-0.1,0-0.2,0.1-0.4c-0.6-0.1-1.2-0.2-1.8-0.3c-0.2,0-0.3-0.1-0.5-0.1c-1.1-0.3-1.4-0.9-1.2-2C17.8,8.6,18,8.1,18.1,7.5z"/>
        </g>
        <g>
          <path className="path-strong-bull" d="M6.9,17.7c-0.3,0.7-0.7,1.4-1,2.1c-0.1,0.2-0.1,0.4,0,0.6c0.3,0.8,0.2,1.7,0.1,2.5c-0.1,1.1,0.3,1.5,1.5,1.3
			c-0.9-0.8-0.7-1.7-0.6-2.7c0-0.4,0.2-0.7,0.3-1.1C7.5,19.5,7.1,18.6,6.9,17.7z"/>
        </g>
        <g>
          <path className="path-strong-bull" d="M24.1,11.5c0.2,0.4,0.4,0.7,0.5,1c1.1-0.2,2-1.2,2.1-2.2C26.9,9,26.2,8,24.9,7.5c0.6,0.8,1.1,1.4,1,2.4
			C25.8,10.9,25,11.3,24.1,11.5z"/>
        </g>
        <g>
          <path className="path-strong-bull" d="M19.6,19.3c-0.4,0.2-0.7,0.4-1,0.7c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.3,0,0.6,0,0.9c0,0.6,0.2,1.1,0.1,1.7
			c-0.1,0.5,0.3,0.8,0.4,1.2c0.1,0.2,0.7,0.2,1,0.3c0,0,0.1-0.1,0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.4-0.5-0.5-1.1-0.3-1.8
			c0.1-0.6,0.2-1.3,0.2-1.9C19.6,19.7,19.6,19.5,19.6,19.3z"/>
        </g>
        <g>
          <path className="path-strong-bull" d="M24.7,13c0,0.2-0.1,0.4-0.1,0.6c0,0.5,0.4,0.9,0.9,0.7c0.4-0.1,0.9-0.2,1.4-0.4C26.2,13.2,25.5,13.1,24.7,13z
			"/>
        </g>
      </g>
    </svg>
  );
};
export default StrongBull;
