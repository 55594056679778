import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

const graphqlURL = import.meta.env.VITE_GRAPHQL || 'http://localhost:3000/graphql';
export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: graphqlURL,
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      EventCourse: {
        fields: {
          agenda: {
            // Somehow Apollo cache also cares about the content stored inside of the agenda field, even thou it is an array stored in the database.
            // This way we tell it to always replace the old content of agenda queried on the same EventCourse.
            // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
            merge: false,
          },
        },
      },
    },
  }),
  credentials: 'include',
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
    watchQuery: {
      nextFetchPolicy: 'cache-and-network',
    },
  },
});

const bioregionGraphqlURL = import.meta.env.VITE_BIOREGION_GRAPHQL || 'http://localhost:3000/bioregion-graphql';
export const ecoregionApolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: bioregionGraphqlURL,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});
