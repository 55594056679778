import { Colxx } from 'components/custom/CustomBootstrap';
import { UserContext } from 'contexts/user/UserContextProvider';
import IntlMessages from 'helpers/IntlMessages';
import { isWechat } from 'helpers/helper';
import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { NavLink, Redirect } from 'react-router-dom';
import { Card, Row } from 'reactstrap';
import RegisterForm from './components/RegisterForm';
import SocialLoginOptions from './components/SocialLoginOptions';

const Register = () => {
  const { isAuthenticated } = useContext(UserContext);
  return !isAuthenticated ? (
    <div className="modal-dialog-centered mt-2 mb-2">
      <div className="container">
        <Row>
          <Colxx xxs="12" md="10" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="position-relative image-side d-none d-lg-block">
              </div>
              <div className="form-side">
                <NavLink to="/" className="white ml-5 mb-4 d-flex justify-content-center">
                  <span className="logo-single" />
                </NavLink>
                <SocialLoginOptions isMobileBrowserChecker={isMobile} isWechatChecker={isWechat} />
                <h2 className="mt-5 mb-4">
                  <IntlMessages id="user.register-title" />
                </h2>
                <RegisterForm />
              </div>
            </Card>
          </Colxx>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to={'/profile'} />
  );
};

export default Register;
