import canvasSize from 'canvas-size';
import Compressor from 'compressorjs';
import { PictureType, getUploadUrl, putChildProfilePicture, putFormPicture } from './image';
import { updateProfilePicture } from './user';

export type ImageCrop = {
  unit?: 'px' | '%' | undefined;
  aspect?: number;
  width: number;
  height: number;
  x: number;
  y: number;
};

// create the image with a src of the base64 string
const createImage = (url: string): Promise<ImageBitmap> =>

  new Promise((resolve: any, reject: any) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));

    image.addEventListener('error', (error: any) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

function getRadianAngle(degreeValue: number): number {
  return (degreeValue * Math.PI) / 180;
}


async function uploadFile(uploadUrl: any, file: File) {
  const fields = uploadUrl.fields;
  const form = new FormData();
  Object.keys(fields).forEach((key) => {
    form.append(key, fields[key]);
  });
  form.append('file', file);
  const response = await fetch(uploadUrl.url, { method: 'POST', body: form });
  if (!response.ok) {
    const errorText = await parseUploadError(response);
    throw new Error(errorText);
  }
  return `/${uploadUrl.fields.bucket}/${uploadUrl.fields.key}`;
}


export async function uploadFormPicture(
  itemId: string,
  blob: Blob,
  picType: PictureType,
  currentLanguage?: string,
): Promise<any> {
  const uploadUrl = await putFormPicture(itemId, picType, blob.type, currentLanguage);
  const file = new File([blob], 'random_file_name', { type: blob.type });
  const uploadedImageUrl = await uploadFile(uploadUrl, file);
  return uploadedImageUrl;
}

export async function uploadAttachFile(file: File): Promise<string> {
  const uploadUrl = await getUploadUrl(PictureType.ATTACH_RESOURCE, file.type);
  const uploadedResourceUrl = await uploadFile(uploadUrl, file);
  return uploadedResourceUrl;
}

export async function uploadAttachPicture(blob: Blob): Promise<string> {
  const uploadUrl = await getUploadUrl(PictureType.ATTACH_RESOURCE, blob.type);
  const file = new File([blob], 'random_file_name', { type: blob.type });
  const uploadedResourceUrl = await uploadFile(uploadUrl, file);
  return uploadedResourceUrl;
}


export async function uploadProfilePicture(blob: Blob, userId: string, extraInfo?: string): Promise<string> {
  const uploadUrl = await getUploadUrl(PictureType.USER_PROFILE, blob.type, extraInfo);
  const file = new File([blob], `${userId}_profile`, { type: blob.type });
  const uploadedResourceUrl = await uploadFile(uploadUrl, file);
  return updateProfilePicture(userId, uploadedResourceUrl);
}


export async function uploadChildProfilePicture(childId: string, blob: Blob): Promise<string> {
  const uploadUrl = await putChildProfilePicture(childId);
  const file = new File([blob], `${childId}_profile`, { type: blob.type });
  const uploadedResourceUrl = await uploadFile(uploadUrl, file);
  return updateProfilePicture(childId, uploadedResourceUrl);
}


export async function uploadPicture(blob: Blob, pictureType: PictureType, resourceId: string): Promise<string> {
  const uploadUrl = await getUploadUrl(pictureType, blob.type, null, resourceId);
  const file = new File([blob], `${resourceId}`, { type: blob.type });
  const uploadedResourceUrl = await uploadFile(uploadUrl, file);
  return uploadedResourceUrl;
}

async function parseUploadError(response: any): Promise<string> {
  const message = await response.body.getReader().read();
  const decodedMessage = new TextDecoder('utf-8').decode(message.value);
  const errorMessage = new DOMParser().parseFromString(decodedMessage, 'text/xml');
  const errorText = errorMessage.getElementsByTagName('Message')[0].innerHTML;
  return errorText;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: ImageCrop,
  rotation: number = 0,
  width: number,
  height: number
): Promise<Blob> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas!.getContext('2d');

  const maxSize = Math.max((image.width as number) as number, (image.height as number) as number);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx!.translate(safeArea / 2, safeArea / 2);
  ctx!.rotate(getRadianAngle(rotation));
  ctx!.translate(-safeArea / 2, -safeArea / 2);

  // transparent parts -> white
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // draw rotated image and store data.
  ctx!.drawImage(image, safeArea / 2 - (image.width as number) * 0.5, safeArea / 2 - (image.height as number) * 0.5);
  const data = ctx!.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx!.putImageData(
    data,
    Math.round(0 - safeArea / 2 + (image.width as number) * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + (image.height as number) * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');
  const resize_canvas = document.createElement('canvas');
  resize_canvas.width = width;
  resize_canvas.height = height;
  const img = await createImage(canvas.toDataURL('image/jpeg'));
  resize_canvas.getContext('2d')!.drawImage(img, 0, 0, width, height);


  return new Promise((resolve: any) => {
    resize_canvas.toBlob((blob: Blob | null) => {
      resolve(blob);
    }, 'image/jpeg');
  });
};

/** resizes the image so it is up to maxImageDimension px big */
function compressImage(imgObjectUrl: Blob | File, maxImageDimension: number): Promise<File | Blob> {
  return new Promise((resolve, reject) => {
    new Compressor(imgObjectUrl, {
      maxWidth: maxImageDimension,
      maxHeight: maxImageDimension,
      success(normalizedFile) {
        resolve(normalizedFile);
      },
      error(error) {
        reject(error);
      }
    });
  });
}

/** Get image dimensions in pixels */
function getImageDimensions(file: File | Blob): Promise<{ width: number, height: number }> {
  const imgObjectUrl = URL.createObjectURL(file);
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      URL.revokeObjectURL(imgObjectUrl);
      resolve({ width, height });
    };

    img.onerror = reject;

    img.src = imgObjectUrl;
  });
}

/** Scale image down in case it is larger than maximum canvas size */
export async function getScaledImg(file: Blob | File): Promise<File | Blob> {
  let fileToUse = file;

  const imageDimensions = await getImageDimensions(file);
  const maxCanvasSize = await canvasSize.maxArea({
    usePromise: true,
    useWorker: true
  });

  // Reverse of safeArea = 2 * ((maxSize / 2) * Math.sqrt(2)) in getCroppedImg.js
  const maxImageDimension = Math.floor(
    Math.max(maxCanvasSize.width, maxCanvasSize.height) / Math.sqrt(2)
  );

  if (
    imageDimensions.width > maxImageDimension ||
    imageDimensions.height > maxImageDimension
  ) {
    fileToUse = await compressImage(file, maxImageDimension);
  }

  return fileToUse;
}

/** Transforms blob to base64 encoded data url */
export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
