import { ReactNode } from 'react';

const IframeLayout = ({ children }: { children: ReactNode }) => {
  // set transparent background
  document.body.classList.add('bg-transparent');
  return (
    <div className="container-fluid">
      {children}
    </div>
  );
};

export default IframeLayout;
