export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 768;
export const menuHiddenBreakpoint = 576;
export const defaultAppLocale = 'en';
export const localeOptions = [
  { id: 'de', name: 'Deutsch - DE', direction: 'ltr' },
  { id: 'en', name: 'English - EN', direction: 'ltr' },
  { id: 'cs', name: 'Čeština - CS', direction: 'ltr' },
  { id: 'it', name: 'Italiano - IT', direction: 'ltr' },
  { id: 'ko', name: '한국어 - KO', direction: 'ltr' },
  { id: 'ru', name: 'Ру́сски - RU', direction: 'ltr' },
  { id: 'tr', name: 'Türk - TR', direction: 'ltr' },
  { id: 'zh-CN', name: '中文 - CN', direction: 'ltr' },
  { id: 'es', name: 'Español - ES', direction: 'ltr' },
  { id: 'fr', name: 'Français - FR', direction: 'ltr' },
  { id: 'nl', name: 'Nederlands - NL', direction: 'ltr' },
  { id: 'ka', name: 'ქართული - KA', direction: 'ltr' },
  { id: 'el', name: 'Νέα Ελληνικά - EL', direction: 'ltr' },
];

export const defaultDirection = 'ltr';

export enum joinCommunityStates {
  REQUESTED_JOIN = 'REQUESTED_JOIN',
  BANNED = 'BANNED',
  INVITED = 'INVITED',
  JOINED = 'JOINED',
}

// Hardcoded to enable wechat pay only for Greensteps Shanghai when creating an activity
export const GS_SH_SLUG = 'green-steps';
