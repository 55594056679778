import { gql } from '@apollo/client';
import { COMMUNITY_OVERVIEW_FRAGMENT } from './community';
import { apolloClient } from './graphql';
import { User } from './graphqltypes';

export const LOAD_USERS = gql`
  query LoadUsers {
    users(filter: { isArchived: { equalTo: false } }) {
      nodes {
        id
        name
        username
        wechatId
        totalCredits
        avatarUrl
      }
    }
  }
`;

export const USER_PROFILE_FRAGMENT = gql`
  ${COMMUNITY_OVERVIEW_FRAGMENT}
  fragment userProfileFragment on User {
    about
    avatarUrl
    bannerImageUrl
    bioregionGid
    currentTerritoryId
    dateOfBirth
    id
    isAdmin
    isTerritoryMentor
    isEcoregionMentor
    isVerified
    language
    name
    phoneNumber
    totalCredits
    totalDevelopmentCredits
    totalFacilitationCredits
    totalKgTrashCollected
    totalParticipationCredits
    username
    wechatId
    wechatOaOpenid
    currentTerritory {
      name
      id
      isCurrentUserMentor
      slug
      avatarUrl
      locationCoordinates { x, y }
    }
    communityUsers {
      nodes {
        id
        communityId
        status
        community {
          ...communityOverviewFragment
        }
        user {
          id
        }
        communityRole {
          id
          name
        }
        communityRolePermissions {
          id
          createEventCourse
          createItem
          viewItem
          updateCommunityProfile
        }
      }
    }
    childConnectedAccounts {
      nodes {
        id
        connectedUser {
          about
          avatarUrl
          dateOfBirth
          id
          isBuddy
          name
          phoneNumber
          totalCredits
          username
        }
      }
    }
    userLearningPaths {
      nodes {
        id
        learningPath {
          id
          name
          description
        }
      }
    }
    userBadges {
      nodes {
        id
        badge {
          id
          name
          iconUrl
          description
        }
        eventCourse {
          id
          type
          name
          slug
        }
        status
        createdAt
      }
    }
    userEmails {
      nodes {
        id
        email
        isVerified
      }
    }
  }
`;



export const USER_PROFILE = gql`
  ${USER_PROFILE_FRAGMENT}
  query userByUsername($username: String!) {
    userByUsername(username: $username) {
      ...userProfileFragment
    }
    currentUser {
      id
      name
      childConnectedAccounts {
        nodes {
          connectedUser {
            id
          }
        }
      }
    }
  }
`;

export const CURRENT_USER_PROFILE = gql`
  ${USER_PROFILE_FRAGMENT}
  query currentUser {
    currentUser {
      ...userProfileFragment
    }
  }
`;

export const CREATE_BUDDY_USER = gql`
  ${USER_PROFILE_FRAGMENT}
  mutation createBuddy(
    $buddyName: String!
    $buddyEmail: String
    $childDateOfBirth: Datetime
    $phoneNumber: String
    $username: String!
  ) {
    createBuddyUser(
      input: { name: $buddyName, email: $buddyEmail, dateOfBirth: $childDateOfBirth, phoneNumber: $phoneNumber }
    ) {
      user {
        ...userProfileFragment
      }
      query {
        userByUsername(username: $username) {
          ...userProfileFragment
        }
      }
    }
  }
`;

export const EDIT_USER_PROFILE_NAME = gql`
  ${USER_PROFILE_FRAGMENT}
  mutation UPDATE_PROFILE_NAME($name: String!, $id: UUID!) {
    updateUser(input: { patch: { name: $name }, id: $id }) {
      user {
        ...userProfileFragment
      }
    }
  }
`;


export const UPDATE_USER_ACCOUNT = gql`
${USER_PROFILE_FRAGMENT}
mutation updateUserAccount(
  $id: UUID!
  $username: String
  $about: String
  $name: String
  $wechatId: String
  $phoneNumber: String
  $avatarUrl: String
  $bannerImageUrl: String
  $dateOfBirth: Datetime
) {
  updateUser(
    input: {
      patch: {
        about: $about
        avatarUrl: $avatarUrl
        name: $name
        username: $username
        wechatId: $wechatId
        phoneNumber: $phoneNumber
        bannerImageUrl: $bannerImageUrl
        dateOfBirth: $dateOfBirth
      }
      id: $id
    }
  ) {
    user {
      ...userProfileFragment
    }
  }
}
`;

export async function loadUsers(): Promise<User[]> {
  const users = await apolloClient.query({
    query: LOAD_USERS,
  });
  return users.data.users.nodes;
}

const UPDATE_PROFILE_PICTURE = gql`
  mutation updateUserProfilePicture($userId: UUID!, $url: String!) {
    updateUser(input: { id: $userId, patch: { avatarUrl: $url } }) {
      user {
        id
        avatarUrl
      }
    }
  }
`;


export async function updateProfilePicture(userId: string, url: string): Promise<any> {
  const userProfilePictureUpdateGql = await apolloClient.mutate({
    mutation: UPDATE_PROFILE_PICTURE,
    variables: {
      userId,
      url,
    },
  });
  return userProfilePictureUpdateGql.data.updateUser.user.avatarUrl;
}

export async function updateUserAccount(user: User): Promise<User> {
  const updatedConnectedAccountGql = await apolloClient.mutate({
    mutation: UPDATE_USER_ACCOUNT,
    variables: {
      id: user.id,
      username: user.username,
      about: user.about,
      avatarUrl: user.avatarUrl,
      bannerImageUrl: user.bannerImageUrl,
      name: user.name,
      phoneNumber: user.phoneNumber,
      dateOfBirth: user.dateOfBirth,
      wechatId: user.wechatId
    },
  });
  return updatedConnectedAccountGql.data.updateUser.user;
}
