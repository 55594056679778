import { gql } from '@apollo/client';

export const LEARNING_PATH = gql`
  query LearningPaths {
    learningPaths(orderBy: SLUG_ASC) {
      nodes {
        id
        name
      }
    }
  }
`;
