import { Form, Button } from 'react-bootstrap';
import React, { useContext, useMemo } from 'react';
import { Label, FormGroup } from 'reactstrap';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import IntlMessages from 'helpers/IntlMessages';
import { getCodeFromError, getFieldError } from 'helpers/errors';
import { useHistory } from 'react-router';
import './login.scss';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN } from 'services/auth';
import { UserContext } from 'contexts/user/UserContextProvider';

const LoginSchema = yup.object().shape({
  username: yup.string().required('Required'),
  password: yup.string().required('Required'),
});


const LoginForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const { refetchCurrentUser, setShowOverlayNotificationModal } = useContext(UserContext);

  /**
   * redirectUrl: route to which to go after login
   * locationStateToPass: whatever location state that we need to pass to component at "redirectUrl" - usually "where to go next after redirectUrl"
   */
  const { redirectUrl, locationStateToPass } = useMemo(() => {
    const redeemCode = new URLSearchParams(location.search).get('redeemCode');
    let redirectUrl = '/profile' //default redirect path
    const locationStateToPass = { ...history.location.state };
    delete locationStateToPass.from; // remove from in state, which will not be useful after login
    if (redeemCode) {
      redirectUrl = `/profile?redeemCode=${redeemCode}`;
    }
    if (history.location.state?.from) {
      redirectUrl = history.location.state.from;
    }
    return { redirectUrl, locationStateToPass };
  }, [location, history]);

  /* */
  /* Queries and mutations */
  /* */
  const [login] = useMutation(LOGIN);

  async function handleSubmit(values, { setFieldError, setSubmitting, setFieldValue }) {
    try {
      await login({ variables: { username: values.username, password: values.password } });
      refetchCurrentUser().then(() => {
        setSubmitting(false);
        history.push({ pathname: redirectUrl, state: locationStateToPass });
        setShowOverlayNotificationModal(true); // update state in UserContext to show success notification after redirect
      });
    } catch (e) {
      console.error(e);
      const code = getCodeFromError(e);
      const [fieldname, messageId] = getFieldError(code);
      const errMessage = intl.formatMessage({ id: messageId });
      if (errMessage) setFieldError(fieldname, errMessage);
      setFieldValue('password', '', false);
    }
  }

  /* */
  /* Render */
  /* */
  return (
    <div>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup className="form-group has-float-label">
              <Label>
                <IntlMessages id="user.email" />
              </Label>
              <Field
                type="text"
                name="username"
                id="username"
                placeholder="Your username or e-mail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                className={errors.username ? 'form-control is-invalid' : 'form-control'}
              />
              {touched.username && errors.username ? <div className="invalid-feedback">{errors.username}</div> : null}
            </FormGroup>

            <FormGroup className="form-group has-float-label">
              <Label>
                <IntlMessages id="user.password" />
              </Label>
              <Field
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={errors.password ? 'form-control is-invalid' : 'form-control'}
              />
              {touched.password && errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
            </FormGroup>
            <div>
              <div className="float-right text-link mb-2">
                <Link
                  to={{
                    pathname: '/password/new',
                    state: { from: history.location.state?.['from'] },
                  }}
                >
                  <IntlMessages id="user.forgot-password-question" />
                </Link>
              </div>
            </div>
            <div className="submit-container">
              <div className="mb-3">
                <Button block variant="primary" className="text-one" type="submit" disabled={isSubmitting}>
                  <span className="label">
                    <IntlMessages id="user.login-button" />
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="text-center text-link">
        <p>
          <IntlMessages id="user.no-account" />
          <span> </span>
          <Link
            to={{
              pathname: '/register',
              state: { from: history.location.state?.['from'] },
            }}
          >
            <IntlMessages id="user.register-title" />
          </Link>
        </p>
      </div>
    </div>
  );
};
export default LoginForm;
