import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';


const Colxx = (props) => <Col {...props} widths={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']} />;
const Separator = ({ className }) => <div className={`separator ${className}`} />;

Separator.propTypes = {
  className: PropTypes.string,
};

export { Colxx, Separator };
