import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const AwakeningDragonfly = ({ style, size = 45, className }: IconProps): React.ReactElement => {
	return (
		<svg version="1.1" id="awakening_dragon_fly" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
			<style type="text/css">
				{`  
            .circle-awakening-dragon-fly{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
	        .path-awakening-dragon-fly{fill:#FBBF2D;}
	    `}
			</style>
			<circle className="circle-awakening-dragon-fly" cx="16.3" cy="16.3" r="15.7" />
			<g>
				<path className="path-awakening-dragon-fly" d="M17.1,8.9c0.5,0.4,0.4,0.8,0.2,1.3c0.7-0.1,1.3-0.2,2-0.3c1.5-0.1,2.9-0.1,4.4-0.1c0.9,0,1.8,0.2,2.7,0.4
		c0.7,0.1,1.2,0.5,1.5,1.1c0,0.2,0,0.4,0,0.6c-0.4,0.3-0.7,0.8-1.1,0.9c-1.3,0.4-2.7,0.3-4.1,0.2c-0.5,0-1.1-0.1-1.7-0.2
		c0.1,0.1,0.1,0.2,0.2,0.2c0.7,0.6,1.5,1.1,2.1,1.7c0.2,0.2,0.4,0.7,0.4,1c-0.1,0.6-0.7,0.9-1.5,0.6c-0.7-0.3-1.5-0.6-2.1-1
		c-1.1-0.7-2-1.5-3.2-2.4c0,1.6,0,3.1,0,4.6c0,1.9-0.1,3.8-0.1,5.6c0,0.7-0.1,1.4-0.1,2.1c0,0.2-0.1,0.3-0.2,0.5
		c-0.2-0.2-0.3-0.4-0.3-0.6c-0.1-1.5-0.2-3.1-0.2-4.7c-0.1-2.3-0.1-4.7-0.1-7c0-0.2,0-0.4,0-0.6c-0.6,0.5-1.2,1-1.8,1.5
		c-1,0.8-2.1,1.6-3.4,1.9c-0.5,0.1-1,0.2-1.4-0.3c-0.3-0.5-0.1-1,0.3-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0.7-0.5,1.3-1,2-1.6
		c-0.6,0.1-1.1,0.1-1.6,0.2c-1.4,0.1-2.7,0.2-4.1-0.2c-0.3-0.1-0.7-0.3-0.9-0.6C4.6,11.5,5,10.6,6,10.3C7,10.1,7.9,10,8.9,9.8
		c1.6-0.3,3.2-0.1,4.8,0.1c0.6,0.1,1.2,0.2,1.8,0.2c-0.3-0.5-0.4-1,0.2-1.3C16.1,8.9,16.6,8.9,17.1,8.9z M22,15.7
		c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4,0,0.6-0.1c-0.1-0.2-0.1-0.4-0.3-0.5c-0.9-0.7-1.7-1.4-2.7-1.9c-0.9-0.5-1.9-0.9-2.9-1.4
		c0,0.1-0.1,0.1-0.1,0.2C18.4,13.5,20.1,14.8,22,15.7z M10,15.2c-0.1,0.1-0.2,0.3-0.3,0.5c0.2,0,0.4,0.1,0.6,0.1
		c0.7-0.3,1.5-0.5,2.1-1c1.1-0.8,2.1-1.7,3.1-2.6c0.1-0.1,0.1-0.1,0.3-0.3C13.6,12.6,11.6,13.5,10,15.2z"/>
			</g>
		</svg>

	);
};

export default AwakeningDragonfly;
