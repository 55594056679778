import React from 'react';
import { Button } from 'react-bootstrap';
import IntlMessages from 'helpers/IntlMessages';
import CustomSpinner from './CustomSpinner';

const NextPreviousButton = ({
  onClick,
  className,
  buttonTextId,
  disabled,
  submit,
  displaySpinner,
  variant,
} : {
    onClick?: (event: any)=> void,
    className?: string;
    buttonTextId: string;
    disabled?: boolean;
    submit?: boolean;
    displaySpinner?: boolean;
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'
    | 'dark' | 'light' | 'link' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger'
    | 'outline-warning' | 'outline-info' | 'outline-dark' | 'outline-light';
}): React.ReactElement =>{
  return (
    <Button
      onClick={onClick}
      className ={className}
      variant={variant}
      disabled={disabled}
      type={submit ?  'submit' : 'button'}
    >
      {displaySpinner ? <CustomSpinner/> : <IntlMessages id={buttonTextId} />}
    </Button>
  );
};

export default NextPreviousButton;
