import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { isProd } from 'helpers/helper';
import { UserContext } from 'contexts/user/UserContextProvider';

const Home = (): React.ReactElement => {
  const { isAuthenticated } = useContext(UserContext);
  const history = useHistory();
  const landingPage = isProd ? '/about' : '/login';
  isAuthenticated ? history.push('/profile') : (window.location.href = landingPage);
  return <div className="loading" />;
};

export default Home;
