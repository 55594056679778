import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const AdventurousWoodpecker = ({ style, size = 45, className }: IconProps): React.ReactElement => {
  return (
    <svg version="1.1" id="adventurous_woodpecker" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32.7 32.7"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
	        .circle-adventurous-woodpecker{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-adventurous-woodpecker{fill:#FBBF2D;} 
	    `}
      </style>
      <circle className="circle-adventurous-woodpecker" cx="16.4" cy="16.5" r="14.5"/>
      <path className="path-adventurous-woodpecker" d="M11,23.9c0.5-2.2,0.9-4.4,1.4-6.6c0.3-1.2,0.7-2.3,1.7-3.2c-0.3-0.7-0.2-1.6-1-2.2c-0.4-0.3-0.5-0.9-0.7-1.3
	c0.5,0,1-0.1,1.5-0.1c0.8-0.1,1.6,0,2.1,0.6c0.6,0.7,1.3,0.9,2.2,1c0-0.6,0-1.1,0-1.7c0-0.1-0.1-0.3-0.2-0.4
	c-1.2-1.2-2.4-2.3-3.6-3.5c-0.1-0.1-0.2-0.2-0.2-0.3c0.4-0.4,0.7-0.7,1.1-1.1c0.9,0.8,1.8,1.7,2.9,2.7c0-1.1,0-2.2,0-3.2
	c1,0,1.9,0,2.8,0c0,6.5,0,13.1,0,19.7c-0.9,0-1.8,0-2.8,0c0-1.7,0-3.4,0-5.3c-0.2,0.1-0.4,0.2-0.4,0.3c-0.5,0.9-1.3,1.4-2.1,1.7
	c-1,0.4-1.5,1.4-2.1,2.2c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0.1-0.1,0.1c-0.1,1.4-0.5,2.7-1.3,3.9c-0.1,0.1-0.1,0.2-0.2,0.4
	c-0.5,0-1.1,0-1.6,0c0-0.4,0-0.8,0-1.2C10.4,25.4,10.8,24.7,11,23.9z M15.9,12.9c0.5,1.1,1,2,1.5,3c1.5-0.6,0.5-2,0.9-3.2
	C17.3,12.8,16.5,12.9,15.9,12.9z M17.6,18.1c0.6,0,0.6,0,0.5-1.3C17.1,16.9,17.7,17.6,17.6,18.1z"/>
    </svg>
  );
};

export default AdventurousWoodpecker;
