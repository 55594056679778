import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Fir = ({ style, size = 45, className }: IconProps): React.ReactElement => {
	return (
		<svg version="1.1" id="fir" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
			<style type="text/css">
				{`
	        .circle-fir{fill:#FFFFFF;stroke:#60B6AA;stroke-miterlimit:10;}
            .path-fir{fill:#60B6AA;
        `}
			</style>
			<circle className="circle-fir" cx="16.3" cy="16.3" r="15.7" />
			<path className="path-fir" d="M15.8,8.7c0.1-0.3,0-0.5-0.3-0.7c-0.5-0.3-0.9-0.8-1.1-1.4c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.2,0.1
	c0.2,0.3,0.5,0.7,0.7,1c0.1,0.1,0.3,0.2,0.5,0.4C15.9,7.5,16,7.3,15.7,7c-0.3-0.2-0.5-0.7-0.8-1c0-0.1,0-0.2,0-0.3
	c0.1,0,0.2,0.1,0.2,0.2c0.2,0.4,0.4,0.8,0.9,1c0-0.4,0.1-0.8,0.1-1.2c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0.1,0.2,0.1,0.3
	c0,0.4,0,0.7,0,1.1c0.6-0.2,0.6-0.9,1.2-1.1c-0.2,0.5-0.4,1-0.9,1.3c-0.3,0.2-0.3,0.4-0.2,0.8c0.2-0.2,0.5-0.4,0.7-0.6
	c0.2-0.2,0.3-0.5,0.5-0.8c0-0.1,0.1-0.1,0.2-0.1c0,0.1,0.1,0.2,0,0.2c-0.3,0.7-0.8,1.3-1.6,1.6c0,0.2,0,0.3,0,0.5
	c0.4-0.1,0.7-0.4,1-0.7c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3c-0.4,0.5-0.8,1-1.4,1.3
	c-0.4,0.2-0.4,0.2-0.4,0.8c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.1,0.3-0.3,0.4-0.4c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.2-0.1,0.3
	c-0.4,0.3-0.7,0.7-1.1,0.9c-0.4,0.2-0.5,0.4-0.5,0.7c0,0.1,0,0.1,0,0.2c0.6-0.2,1-0.5,1.4-0.9c0,0,0.1-0.1,0.1-0.1
	c0.1,0,0.2,0,0.3-0.1c0,0.1,0,0.2-0.1,0.3c-0.4,0.4-0.9,0.7-1.3,1c-0.4,0.3-0.5,0.2-0.4,0.8c0.5-0.1,0.9-0.5,1.2-0.8
	c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0,0.3-0.1,0.3c-0.4,0.5-0.9,0.8-1.5,1c0,0.3,0,0.6,0,1c0.3-0.3,0.6-0.5,0.9-0.7
	c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0,0.2,0,0.3c-0.4,0.5-0.8,0.9-1.3,1.2c-0.2,0.1-0.3,0.3-0.3,0.5
	c0,0.2,0,0.4,0,0.6c0,0,0.1,0,0.1,0c0.3-0.3,0.5-0.6,0.8-0.9c0.2-0.3,0.4-0.6,0.7-0.9c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3
	c-0.4,0.5-0.8,1.1-1.2,1.5c-0.4,0.4-0.4,0.4-0.3,1.1c0.3-0.3,0.5-0.5,0.7-0.7c0.3-0.4,0.6-0.8,0.9-1.1c0.1-0.1,0.2-0.1,0.3-0.1
	c0,0.1,0,0.2,0,0.3c-0.6,0.7-1.1,1.3-1.7,2c-0.1,0.2-0.2,0.5-0.2,0.7c0,0,0.1,0,0.1,0.1c0.1-0.1,0.3-0.1,0.4-0.2
	c0.4-0.4,0.8-0.8,1.2-1.1c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0,0.2-0.1,0.3c-0.5,0.5-1,1.1-1.6,1.4c-0.1,0.1-0.2,0.4-0.2,0.5
	c0,0,0.1,0.1,0.1,0.1c0.2-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.2-0.1c0,0.1,0,0.2,0,0.2
	c-0.3,0.2-0.5,0.5-0.8,0.7c-0.4,0.2-0.4,0.4-0.3,0.8c0.8-0.2,1.4-0.5,2-1c-0.1-0.4-0.1-0.7-0.2-1.1c0-0.1,0.1-0.2,0.1-0.3
	c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.1,0.6,0.2,0.9c0.2-0.1,0.3-0.2,0.4-0.3c-0.3-0.5-0.5-1-0.4-1.5c0-0.1,0.1-0.2,0.2-0.2
	c0,0.1,0.1,0.2,0.1,0.3c0,0.5,0,0.9,0.3,1.3c0.2-0.1,0.2-0.2,0.2-0.4c-0.1-0.6-0.1-1.3-0.2-1.9c0-0.1,0.1-0.2,0.2-0.3
	c0,0.1,0.1,0.2,0.1,0.3c0,0.6,0,1.2,0.2,1.8c0.3-0.2,0.4-0.3,0.3-0.7c-0.1-0.6-0.1-1.2-0.2-1.9c0-0.1,0.1-0.1,0.1-0.2
	c0,0.1,0.1,0.2,0.1,0.2c0.1,0.5,0.1,1.1,0.1,1.6c0,0.1,0.1,0.2,0.1,0.4c0.5-0.3,0.5-0.4,0.4-0.8c-0.1-0.5-0.2-1-0.2-1.5
	c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.5,0.2,1.1,0.2,1.6c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.2,0.4-0.4
	c-0.5-0.6-0.5-1.3-0.6-2c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.2,0.4,0.3,0.6
	c0.3-0.2,0.4-0.4,0.3-0.8c-0.2-0.5-0.3-1.1-0.4-1.7c0-0.1,0.1-0.2,0.1-0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.4,0.2,0.9,0.3,1.3
	c0,0.2,0.1,0.3,0.2,0.5c0.3-0.3,0.3-0.5,0.2-0.9c-0.2-0.5-0.2-1.1-0.3-1.7c0-0.1,0.1-0.2,0.1-0.2c0.1,0.1,0.1,0.2,0.2,0.2
	c0.1,0.4,0.1,0.8,0.2,1.3c0,0.2,0.1,0.4,0.2,0.6c0.3-0.2,0.4-0.4,0.2-0.8c-0.2-0.5-0.2-1.1-0.2-1.7c0-0.1,0.1-0.1,0.1-0.2
	c0,0.1,0.1,0.1,0.1,0.2c0.1,0.5,0.1,0.9,0.2,1.4c0,0.1,0.1,0.3,0.1,0.5c0.2-0.3,0.3-0.4,0.2-0.8c-0.1-0.5-0.1-1-0.2-1.5
	c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.5,0.1,1,0.2,1.5c0.2-0.3,0.5-0.5,0.3-0.9c-0.1-0.4-0.1-0.9-0.1-1.3
	C23.9,9.1,24,9.1,24,9c0,0.1,0.1,0.2,0.1,0.2c0,0.4,0,0.8,0,1.3c0.4-0.3,0.4-0.8,0.8-1c0,0.6-0.5,0.9-0.6,1.5c0.5,0,1,0,1.5,0
	c0,0.3-0.1,0.3-0.3,0.3c-0.5,0-1,0-1.5-0.1c0,0.1-0.1,0.3-0.2,0.4c0.2,0,0.3,0.1,0.5,0.1c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0.1,0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0-1.1,0-1.6-0.1c0,0,0-0.1,0-0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0.2,0.1,0.5,0.2,0.7,0.2
	c0.4,0.1,0.7,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.6,0-1.3,0-1.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.4
	c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.7,0,1,0.1c0.1,0,0.2,0.1,0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.7,0-1.3-0.1-2-0.4
	c-0.1,0.1-0.2,0.2-0.3,0.3c0.4,0.1,0.8,0.2,1.2,0.3c0.3,0,0.5,0,0.8,0.1c0.1,0,0.1,0.1,0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1
	c-0.7,0-1.4-0.1-2.1-0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0.5,0.3,1,0.4,1.5,0.5c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c-0.6-0.2-1.3-0.3-1.9-0.5c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.4c0.5,0.3,1,0.4,1.6,0.4c0,0,0.1,0,0.1,0
	c0.1,0,0.1,0.1,0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.6-0.1-1.1-0.3-1.7-0.4c-0.1,0-0.1,0-0.2-0.1c-0.2,0.2-0.3,0.3-0.5,0.6
	c0.5,0.3,1,0.3,1.5,0.4c0.2,0,0.4-0.1,0.5,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.6-0.1-1.1-0.2-1.7-0.3c-0.1,0.1-0.2,0.2-0.4,0.4
	c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.7,0,1.1,0.1c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.6-0.1-1.3-0.1-1.9-0.2
	c-0.5-0.1-0.5-0.1-0.8,0.3c0.6,0.2,1.2,0.2,1.8,0.2c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.2
	c-0.7,0-1.4,0-2.1-0.2c-0.1,0-0.3,0-0.4,0.1c0.4,0.4,0.8,0.5,1.3,0.5c0.1,0,0.2,0,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.2
	c-0.6,0.1-1.1-0.1-1.6-0.5c-0.1,0.1-0.2,0.2-0.4,0.3c0,0,0,0.1,0,0.1c0.3,0.1,0.6,0.1,1,0.2c0.1,0,0.2,0.1,0.3,0.1
	c-0.1,0-0.2,0.2-0.3,0.1c-0.4-0.1-0.7-0.2-1.1-0.3c-0.1,0-0.3,0-0.4,0c-0.6,0.3-1.2,0.6-1.8,0.9c-0.1,0-0.2,0.2-0.2,0.2
	c0,0.9,0.1,1.9,0.1,2.9c0.8-0.1,1.5-0.2,2.2-0.3c0-0.5,0.1-0.9,0.1-1.2c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3
	c0,0.3-0.1,0.7-0.1,1.1c0.2-0.1,0.4-0.1,0.5-0.2C19,20.6,19,20,19.4,19.4c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.3c-0.2,0.5-0.2,0.9,0,1.4
	c0.3,0,0.2-0.1,0.3-0.3c0.1-0.6,0.2-1.3,0.4-1.9c0-0.1,0.2-0.1,0.2-0.2c0,0.1,0,0.2,0,0.3c-0.2,0.6-0.4,1.2-0.3,1.9
	c0.3-0.1,0.5-0.2,0.5-0.6c0-0.6,0.2-1.3,0.3-1.9c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.5-0.2,1-0.3,1.5
	c0,0.2,0,0.3,0,0.5c0.5-0.2,0.6-0.2,0.6-0.7c0-0.5,0.1-1,0.2-1.4c0-0.1,0.1-0.2,0.2-0.2c0,0.1,0.1,0.2,0,0.3c-0.1,0.5-0.1,1-0.2,1.6
	c0.4-0.2,0.5-0.3,0.5-0.7c0-0.5,0.1-1.1,0.1-1.6c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.5-0.1,1.1-0.1,1.6
	c0.3-0.1,0.5-0.3,0.5-0.7c0-0.4,0-0.8,0.1-1.2c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.4-0.1,0.7-0.1,1.2
	c0.3-0.2,0.5-0.4,0.5-0.8c0-0.4,0.1-0.8,0.2-1.2c0-0.1,0.1-0.1,0.2-0.2c0,0,0.1,0,0.1,0c-0.1,0.4-0.2,0.8-0.2,1.2c0,0,0.1,0,0.1,0
	c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.4,0.5-0.6,0.8c0,0,0,0.1,0,0.1
	c0.3-0.1,0.5-0.3,0.8-0.4c0.1,0,0.2,0,0.3-0.1c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.3-0.7,0.4-1.1,0.4c-0.1,0-0.3,0.1-0.5,0.2
	c0.5,0.3,1,0.4,1.5,0.3c0.1,0,0.2,0,0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0-0.8,0-1.2-0.1c-0.1,0-0.2-0.1-0.3-0.1
	c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.3,0.1,0.3c0.3,0.2,0.7,0.4,1,0.5c0.1,0,0.2,0.1,0.4,0.1c-0.3,0.3-1.2-0.1-1.8-0.7
	c-0.4,0.3-0.4,0.4,0,0.7c0.4,0.2,0.8,0.4,1.2,0.6c0.1,0,0.1,0.2,0.2,0.2c-0.1,0-0.2,0.1-0.3,0c-0.8-0.3-1.3-0.6-1.7-1.1
	c-0.2,0.2-0.4,0.3-0.6,0.5c0.4,0.4,0.9,0.6,1.4,0.8c0.1,0.1,0.3,0.2,0.4,0.3c-0.2,0-0.3,0.1-0.5,0c-0.5-0.2-1-0.5-1.6-0.8
	c0,0,0-0.1,0-0.1c-0.2,0.1-0.4,0.2-0.6,0.4c0.3,0.2,0.5,0.3,0.8,0.4c0.4,0.2,0.8,0.3,1.2,0.4c0.1,0,0.1,0.1,0.2,0.2
	c-0.1,0-0.2,0.1-0.3,0.1c-0.6-0.2-1.2-0.4-1.7-0.7c-0.3-0.2-0.5-0.3-0.9,0.1c0.4,0.2,0.9,0.4,1.3,0.5c0.2,0.1,0.4,0.1,0.6,0.2
	c0.1,0,0.1,0.1,0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.6-0.2-1.3-0.4-1.9-0.7c-0.2-0.1-0.4-0.2-0.6,0c0.3,0.4,0.7,0.6,1.2,0.7
	c0.1,0,0.2,0.1,0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.5-0.1-0.9-0.3-1.2-0.6c-0.3-0.3-0.3-0.3-0.7,0c0.3,0.2,0.6,0.3,0.9,0.4
	c0.1,0,0.1,0.1,0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.1-0.6-0.3-0.9-0.5c-0.1-0.1-0.3-0.2-0.5-0.1c-0.6,0.1-1.3,0.3-2,0.4
	c0,0.5,0.1,1,0.2,1.4c0.2,1.1,0.5,2.2,0.7,3.3c0,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.4-0.1,0.5c-0.1,0-0.3-0.1-0.3-0.3
	c-0.5-1.1-0.8-2.3-0.9-3.6c0-0.3-0.1-0.4-0.4-0.4c-0.6,0-1.1-0.2-1.7-0.3c-0.1,0-0.3,0-0.4,0.1c-0.3,0.2-0.6,0.3-1,0.5
	c-0.1,0-0.1,0-0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.1,0.6-0.3,0.9-0.4c-0.3-0.2-0.5-0.3-0.7,0c-0.3,0.4-0.8,0.6-1.2,0.7
	c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.5-0.1,0.9-0.3,1.2-0.7c-0.2-0.2-0.3-0.1-0.6,0c-0.6,0.3-1.3,0.5-1.9,0.7
	c-0.1,0-0.1-0.1-0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.1,0.8-0.3,1.3-0.4c0.2-0.1,0.4-0.2,0.6-0.3c-0.3-0.3-0.5-0.3-0.8-0.1
	c-0.5,0.3-1.1,0.5-1.7,0.7c-0.1,0-0.2,0-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2c0.3-0.1,0.7-0.2,1.1-0.4c0.3-0.1,0.5-0.3,0.9-0.4
	c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.4,0c-0.5,0.3-1,0.5-1.5,0.8c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.1-0.2,0.2-0.2
	c0.6-0.2,1.1-0.4,1.6-0.9c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4,0.6-1.1,0.9-1.8,1.1c-0.1,0-0.2,0-0.2-0.1c0-0.1,0.1-0.2,0.1-0.2
	c0.3-0.1,0.6-0.3,0.9-0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.4-0.4-0.1-0.7c-0.3,0.5-0.8,0.7-1.4,0.9c-0.1,0-0.1,0.1-0.2,0
	c-0.1,0-0.1-0.1-0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.2C8.5,21.2,8.7,21,9,20.8c0,0,0-0.1-0.1-0.1
	c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.6,0.2-0.8,0.2c-0.2,0-0.4,0.1-0.5,0c-0.1,0-0.3-0.2-0.4-0.3c0.1,0,0.3,0,0.4,0
	c0.4,0,0.9-0.1,1.3-0.2c-0.1-0.3-0.3-0.4-0.5-0.4c-0.4-0.1-0.7-0.3-1.1-0.5C7,19.6,7,19.5,7,19.4c0.1,0,0.2,0,0.3,0
	c0.3,0.1,0.5,0.3,0.8,0.4c-0.1-0.4-0.6-0.7-0.6-1.1c0,0,0.1,0,0.1-0.1c0.3,0.3,0.5,0.6,0.8,1c0,0,0.1,0,0.1,0
	c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.1,0-0.2,0-0.3c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.4,0.1,0.9,0.2,1.3c0,0.2,0.2,0.4,0.3,0.6
	c0,0,0.1,0,0.1,0c0-0.4,0-0.7-0.1-1.1c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.1,0.2,0.2,0.2c0,0.3,0.1,0.7,0.1,1c-0.1,0.4,0.1,0.6,0.5,0.7
	c0-0.5-0.1-1-0.1-1.6c0-0.1,0-0.2,0.1-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0,0.6,0,1.1,0.1,1.7c0,0.2,0.2,0.3,0.3,0.5c0.1,0,0.1,0,0.2-0.1
	c-0.1-0.4-0.1-0.9-0.2-1.3c0-0.2-0.2-0.3,0-0.5c0,0,0.2,0.3,0.2,0.4c0.1,0.4,0.2,0.8,0.1,1.1c-0.1,0.5,0.2,0.7,0.7,0.9
	c-0.1-0.5-0.1-1-0.2-1.4c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.2,0.1-0.2c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.6,0.3,1.3,0.3,2
	c0,0.4,0.1,0.5,0.5,0.6c-0.1-0.5-0.1-0.9-0.2-1.4c0-0.2-0.1-0.4-0.2-0.6c0-0.1,0-0.2,0-0.3c0.1,0.1,0.2,0.1,0.2,0.2
	c0.2,0.7,0.3,1.3,0.4,2c0,0.1,0.1,0.2,0.2,0.3c0.2-0.5,0.2-0.9,0.1-1.4c0-0.1,0-0.2,0-0.3c0.1,0.1,0.2,0.1,0.2,0.2
	c0.2,0.5,0.2,1,0,1.6c0.1,0.1,0.3,0.1,0.5,0.2c0-0.5-0.1-0.9-0.1-1.3c0.3,0,0.3,0,0.3,1.4c0.7,0.1,1.4,0.2,2.2,0.4
	c0-0.5-0.1-0.8-0.1-1.2c-0.1-1.2-0.1-2.3-0.2-3.5c0-0.1-0.1-0.3-0.2-0.3c-0.5-0.3-1.1-0.5-1.6-0.8c-0.2-0.1-0.4-0.1-0.6,0
	c-0.3,0.1-0.7,0.2-1,0.2c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.7-0.1,1-0.2c0,0,0-0.1,0-0.1
	c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5,0.4-1,0.6-1.6,0.5c-0.1,0-0.2-0.1-0.3-0.2c0.1,0,0.2-0.1,0.3-0.1c0.5,0,0.9-0.1,1.4-0.4
	c-0.1-0.2-0.3-0.2-0.5-0.1c-0.7,0.2-1.4,0.3-2.1,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0.7,0,1.4,0,2-0.2
	c-0.2-0.4-0.3-0.4-0.7-0.3c-0.7,0.1-1.3,0.2-2,0.2c-0.1,0-0.1-0.1-0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0.4,0,0.9,0,1.4-0.1
	c0.2,0,0.5-0.1,0.7-0.2c-0.2-0.5-0.4-0.5-0.8-0.4C10,15.8,9.5,15.9,9,16c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.2-0.1,0.2-0.1
	c0.6,0,1.1-0.1,1.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.5c-0.6,0.1-1.2,0.3-1.8,0.4c-0.1,0-0.3-0.1-0.4-0.1C8.2,15.1,8.4,15,8.5,15
	c0.4-0.1,0.7-0.1,1.1-0.2c0.1,0,0.3-0.1,0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0,0.1,0,0.1c-0.6,0.1-1.3,0.3-1.9,0.4
	c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.1,0.9-0.2,1.3-0.3c0.1,0,0.3-0.1,0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
	C8.7,13.8,8,14,7.4,14c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.5-0.1,1-0.2,1.5-0.2c0.1,0,0.2-0.1,0.4-0.1
	c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6,0.3-1.3,0.4-2,0.4c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.6-0.1,1.2,0,1.8-0.4
	c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6,0.3-1.2,0.3-1.8,0.3c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.4-0.1,0.8-0.1,1.2-0.1
	c0.2,0,0.3-0.1,0.5-0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.6,0.3-1.2,0.2-1.8,0.1c0,0-0.1-0.1-0.2-0.2c0.2,0,0.4,0,0.6,0
	c0.3,0,0.5,0,0.8,0c0.4,0,0.4-0.1,0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c-0.5,0-1,0.1-1.5,0.1c-0.1,0-0.2-0.1-0.3-0.2C6,11,6.1,11,6.2,11
	c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.4-0.1c-0.2-0.4-0.4-0.8-0.6-1.2c0-0.1,0-0.2,0-0.3C6.9,9.5,7,9.5,7.1,9.6c0.2,0.3,0.3,0.6,0.5,0.9
	c0,0,0.1,0,0.1,0c0-0.4,0-0.8,0-1.2c0-0.1,0.1-0.2,0.1-0.3C7.9,9.1,8,9.2,8,9.3c0,0.5-0.1,1-0.1,1.4c0,0.2,0.2,0.5,0.3,0.7
	c0,0,0.1,0,0.1,0c0.1-0.6,0.1-1.1,0.2-1.7c0,0,0.1,0,0.1,0c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.5-0.2,1.1-0.2,1.6c0,0.2,0.1,0.4,0.2,0.5
	C9,11.7,9,11.2,9,10.6c0-0.1,0.1-0.3,0.2-0.4c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.6-0.2,1.1-0.3,1.7c0,0.2,0.1,0.4,0.2,0.6
	c0.3-0.5,0.4-1.1,0.4-1.7c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.6-0.2,1.2-0.3,1.7
	c-0.1,0.4,0,0.6,0.2,0.8c0.3-0.6,0.4-1.1,0.5-1.7c0-0.1,0.1-0.2,0.2-0.3c0,0.8-0.1,1.6-0.5,2.3c0.1,0.1,0.2,0.3,0.4,0.5
	c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.1-0.7,0.2-1c0-0.1,0.1-0.2,0.1-0.2c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.7-0.1,1.4-0.6,2
	c0.1,0.1,0.3,0.2,0.5,0.5c0.1-0.5,0.2-1,0.3-1.5c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0.1,0.2,0.1,0.2
	c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.5-0.2,0.5,0.4,1c0.1-0.7,0.1-1.3,0.2-1.9c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.2
	c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.8-0.1,1.2c0,0.2,0,0.5-0.1,0.7c-0.2,0.3,0,0.5,0.3,0.7c0-0.5,0.1-0.9,0.1-1.4c0-0.2,0-0.4,0-0.6
	c0-0.1,0.1-0.2,0.1-0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.6-0.1,1.3-0.1,1.9c0,0.2,0,0.3,0.2,0.4c0.3-0.4,0.3-0.8,0.3-1.2
	c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.5-0.1,1-0.4,1.5c0.1,0.1,0.2,0.2,0.4,0.3c0.1-0.4,0.2-0.8,0.2-1.2
	c0,0,0.1,0,0.1,0c0,0.1,0.1,0.2,0.1,0.3c0,0.3-0.1,0.6-0.2,0.9c0,0.1,0,0.3,0.1,0.3c0.6,0.3,1.2,0.6,1.8,1c0.1-0.4,0-0.6-0.3-0.8
	c-0.3-0.2-0.6-0.4-0.8-0.7c0,0,0-0.2,0-0.3c0.1,0,0.2,0,0.2,0.1c0.3,0.2,0.6,0.5,0.9,0.7c0.1-0.3,0.1-0.6-0.3-0.8
	c-0.5-0.4-1-0.9-1.4-1.3c-0.1-0.1,0-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0.1c0.3,0.3,0.6,0.6,1,0.9c0.1,0.1,0.3,0.2,0.5,0.4
	c0.1-0.4,0.1-0.6-0.2-0.9c-0.5-0.5-1-1.2-1.5-1.7c-0.1-0.1,0-0.2,0-0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.4,0.7,0.9,1.1,1.4
	c0.1,0.1,0.2,0.3,0.4,0.4c0.1-0.4,0.1-0.7-0.3-1.1c-0.5-0.4-0.9-1-1.3-1.6c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.3,0.1
	c0.3,0.4,0.6,0.8,0.9,1.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0.1-0.1,0.1-0.1c0-0.3,0-0.6,0-0.9c0-0.1-0.1-0.2-0.2-0.2
	c-0.5-0.4-0.9-0.8-1.4-1.3c-0.1-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0.3,0.6,0.6,0.9,0.9c0.1,0.1,0.3,0.2,0.5,0.3
	c0-0.4,0-0.7,0-1c-0.6-0.2-1.1-0.5-1.5-1c-0.1-0.1-0.1-0.2-0.2-0.4c0.1,0.1,0.3,0.1,0.3,0.2c0.4,0.4,0.8,0.7,1.3,0.9
	c0-0.2,0-0.3,0-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.5-0.3-1-0.7-1.4-1c-0.1-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0.1,0.3,0.1
	c0.4,0.4,0.8,0.8,1.4,1c0-0.2,0-0.4,0-0.6c0-0.1-0.1-0.2-0.2-0.2c-0.4-0.3-0.9-0.6-1.3-0.9c-0.1-0.1-0.1-0.2-0.1-0.3
	c0.1,0,0.2,0,0.3,0.1c0.4,0.3,0.7,0.6,1.1,0.9c0.3-0.4,0.2-0.6-0.1-0.8c-0.6-0.3-1-0.8-1.4-1.3c0-0.1,0-0.2,0-0.3
	c0.1,0,0.2,0.1,0.2,0.1c0.3,0.3,0.6,0.7,0.9,1C15.5,8.5,15.7,8.6,15.8,8.7z"/>
		</svg>
	);
};

export default Fir;
