import { useCallback, useEffect, useState } from 'react';

/*
Comment from Rabbit: https://gitlab.com/greensteps/ark/-/merge_requests/1283#note_2269888378

Avoid direct DOM manipulation and add cleanup

The current implementation has several potential issues:

* Direct DOM manipulation should be avoided in React. Consider using refs or CSS-in-JS solutions.
* Global document.body style modifications could affect other components.
* Hard-coded selector could be fragile.
*/

/**
 * Internal, handles the mobile/responsive view adjustments.
 */
export const useMobileView = () => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  useEffect(() => {
    const mobileChannelList = document.querySelector('#mobile-channel-list');
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add('show');
      document.body.style.overflow = 'hidden';
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove('show');
      document.body.style.overflow = 'auto';
    }
    // cleanup
    return () => {
      document.body.style.overflow = 'auto';
    };  
  }, [isMobileNavVisible]);

  return useCallback(() => {
    setIsMobileNavVisible((isMobile) => !isMobile);
  }, []);
};
