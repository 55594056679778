import React from 'react';
import {
  NoPovertyIcon,
  ZeroHungerIcon,
  GoodHealthWellBeingIcon,
  QualityEducationIcon,
  GenderEqualityIcon,
  CleanWaterSanitationIcon,
  AffordableCleanEnergyIcon,
  IndustryInnovationInfraIcon,
  DecentWorkEconomicGrowthIcon,
  ReducedInequalitiesIcon,
  SustainableCitiesCommunitiesIcon,
  ResponsibleConsumptionProductionIcon,
  ClimateActionIcon,
  LifeBelowWaterIcon,
  LifeOnLandIcon,
  PeaceJusticeStrongInstitutionIcon,
  PartnershipForTheGoalsIcon
} from '../SdgIcons';

type SdgIconProps = {
    iconId: number;
    onClick?: ()=>void;
    className?: string;
    size?: number;
}

export function getSdgIcon(id: number, className?: string, size?: number): React.ReactNode {
  return {
    1: <NoPovertyIcon className={className} size={size}/>,
    2: <ZeroHungerIcon className={className} size={size}/>,
    3: <GoodHealthWellBeingIcon className={className} size={size}/>,
    4: <QualityEducationIcon className={className} size={size}/>,
    5: <GenderEqualityIcon className={className} size={size}/>,
    6: <CleanWaterSanitationIcon className={className} size={size}/>,
    7: <AffordableCleanEnergyIcon className={className} size={size}/>,
    8: <DecentWorkEconomicGrowthIcon className={className} size={size}/>,
    9: <IndustryInnovationInfraIcon className={className} size={size}/>,
    10: <ReducedInequalitiesIcon className={className} size={size}/>,
    11: <SustainableCitiesCommunitiesIcon className={className} size={size}/>,
    12: <ResponsibleConsumptionProductionIcon className={className} size={size}/>,
    13: <ClimateActionIcon className={className} size={size}/>,
    14: <LifeBelowWaterIcon className={className} size={size}/>,
    15: <LifeOnLandIcon className={className} size={size}/>,
    16: <PeaceJusticeStrongInstitutionIcon className={className} size={size}/>,
    17: <PartnershipForTheGoalsIcon className={className} size={size}/>,
  }[id];
}

const SdgIcon = ({ iconId, onClick, className, size }: SdgIconProps): React.ReactElement=>{
  return  (
    <div onClick={onClick}>
      {getSdgIcon(iconId, className, size)}
    </div>
  );
};

export default SdgIcon;
