import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client';
import { ReactNode, createContext, useState } from 'react';
import { CURRENT_USER } from 'services/auth';
import { CommunityUser, ConnectedAccount, Scalars, User, GeoIpLocation } from 'services/graphqltypes';
import * as Sentry from "@sentry/react";
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { StreamChatClientComponent } from './StreamChatClientComponent';

export type UserPermissions = {
  communityId: Scalars['UUID'];
  communityName: Scalars['JSON'];
  communitySlug: string;
  role: Scalars['JSON'];
  permissions: string[];
};

export type UserContextValues = {
  loading: boolean,
  isAuthenticated: boolean,
  currentUser: User,
  refetchCurrentUser: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>,
  currentUserPermissions: UserPermissions[],
  currentUserChildren: User[],
  streamChatClient: StreamChat<DefaultGenerics>,
  unreadCount: number,
  currentUserGeoIPLocation: GeoIpLocation,
  setShowOverlayNotificationModal: React.Dispatch<React.SetStateAction<boolean>>,
  showOverlayNotificationModal: boolean,
};

export const UserContext = createContext<UserContextValues>(null);

function UserContextProvider({ children }: { children: ReactNode }) {

  const { data, refetch: refetchCurrentUser, loading } = useQuery(CURRENT_USER);

  const currentUser = data?.currentUser as User;
  const currentUserStreamToken = data?.currentUserStreamToken as string;
  const currentUserGeoIPLocation = data?.currentUserGeoIPLocation?.latitude && data?.currentUserGeoIPLocation as GeoIpLocation; // leave undefined if we don't get it (not using the error field)

  Sentry.setUser({ id: currentUser?.id, username: currentUser?.username });

  const isAuthenticated = !!currentUser?.id;

  const currentUserPermissions = currentUser?.communityUsers?.nodes.map((communityUser: CommunityUser): UserPermissions => {
    const communityRolePermissions = communityUser.communityRolePermissions;
    let permissions = [] as any;
    if (communityRolePermissions) {
      permissions = Object.keys(communityRolePermissions).filter((key) => {
        return communityRolePermissions[key] === true;
      });
    }
    return {
      communityId: communityUser.communityId,
      communityName: communityUser.community.name,
      communitySlug: communityUser.community.slug,
      role: communityUser.communityRole.name,
      permissions,
    };
  });

  const currentUserChildren = currentUser?.childConnectedAccounts?.nodes.map((connectedAccount: ConnectedAccount) => {
    return connectedAccount.connectedUser;
  });

  // getstream chat
  const [unreadCount, setUnreadCount] = useState<number>();
  const [streamChatClient, setStreamChatClient] = useState<StreamChat<DefaultGenerics>>();

  const [showOverlayNotificationModal, setShowOverlayNotificationModal] = useState<boolean>(false); // manage OverlayNotificationModal

  const userContextValues = {
    loading,
    isAuthenticated,
    currentUser,
    currentUserGeoIPLocation,
    refetchCurrentUser,
    currentUserPermissions,
    currentUserChildren,
    streamChatClient,
    unreadCount,
    showOverlayNotificationModal,
    setShowOverlayNotificationModal
  };

  return (
    <UserContext.Provider value={{ ...userContextValues }}>
      { // only render StreamChatClientComponent if we have a currentUser
        currentUser?.id && <StreamChatClientComponent
          currentUser={currentUser}
          currentUserStreamToken={currentUserStreamToken}
          setStreamChatClient={setStreamChatClient}
          setUnreadCount={setUnreadCount}
        />
      }
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
