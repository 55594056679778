import './MessagingChannelPreview.css';
import {
  ChannelPreviewUIComponentProps,
  DefaultStreamChatGenerics,
  useChatContext,
} from 'stream-chat-react';
import AvatarGroup from './AvatarGroup';

import type { PropsWithChildren } from 'react';
import type { Channel, ChannelMemberResponse } from 'stream-chat';
// import type { StreamChatGenerics } from '../../types';

const getTimeStamp = (channel: Channel) => {
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes: string | number | undefined = channel.state.last_message_at?.getMinutes();
  let half = 'AM';

  if (lastHours === undefined || lastMinutes === undefined) {
    return '';
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = 'PM';
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = 'PM';

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`;
  }

  return `${lastHours}:${lastMinutes} ${half}`;
};

const DEFAULT_USER_NAME = 'Anonymous User';

const getChannelName = (members: ChannelMemberResponse[]) => {
  if (!members.length) return DEFAULT_USER_NAME;
  
  const names = members
    .slice(0, 3)
    .map(member => member.user?.name || DEFAULT_USER_NAME);
    
  if (members.length > 3) {
    return `${names.join(', ')} and ${members.length - 3} others`;
  }
  
  return names.join(', ');
};

const MessagingChannelPreview = (props: PropsWithChildren<ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>>) => {
  const { channel, setActiveChannel, latestMessage, } = props;
  const { channel: activeChannel, client } = useChatContext();

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user?.id !== client.userID,
  );

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? 'channel-preview__container selected'
          : 'channel-preview__container'
      }
      onClick={() => {
        setActiveChannel?.(channel);
      }}
    >
      <AvatarGroup members={members} />
      <div className='channel-preview__content-wrapper'>
        <div className='channel-preview__content-top'>
          <div>
            <span className='channel-preview__content-name mr-1'>
              {channel.data?.name || getChannelName(members)}
            </span>
            {channel.countUnread() > 0 &&
              <span className='badge badge-danger badge-user-card-unread-messages'>{channel.countUnread()}</span>
            }
          </div>
          <p className='channel-preview__content-time'>{getTimeStamp(channel)}</p>
        </div>
        <div className='channel-preview__content-message'>{latestMessage}</div>
      </div>
    </div>
  );
};

export default MessagingChannelPreview;
