import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Mars = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="mars" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000"  xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
             .mars-st0{fill:#FFFFFF;}
             .mars-st1{fill:#E14F22;}
             .mars-st2{fill:#E04F21;} 
        `}
      </style>
      <g>
        <g id="XMLID_1_">
          <g>
            <path className="mars-st0" d="M460.3,521.1c49.2,49.2,49.2,129.3,0,178.6c-49.2,49.2-129.3,49.2-178.6,0c-49.2-49.2-49.2-129.3,0-178.6
				c24.6-24.6,57-36.9,89.3-36.9C403.3,484.2,435.7,496.5,460.3,521.1z"/>
            <path className="mars-st0" d="M489.4,86c233.5,0,423.4,190,423.4,423.5c0,233.5-190,423.4-423.4,423.4c-233.5,0-423.5-190-423.5-423.4
				C65.9,276,255.9,86,489.4,86z M731.2,433.5c18.3,0,33.2-14.9,33.2-33.2v-149c0-6.9-2.1-13.3-5.7-18.6c0,0,0,0,0,0
				c-4.4-7.7-12.3-13.1-21.5-14.1c-2-0.4-4-0.5-6-0.5c-1.7,0-3.4,0.1-5.1,0.4H585.2c-15.6,0-28.2,12.6-28.2,28.2
				s12.6,28.2,28.2,28.2h74.3L481.8,452.7c-75.1-52.8-179.9-45.7-247,21.5c-75.1,75.1-75.1,197.4,0,272.5
				C272.3,784.2,321.7,803,371,803s98.7-18.8,136.3-56.4c67.1-67.1,74.3-171.9,21.5-247L698,330.4v69.9
				C698,418.6,712.9,433.5,731.2,433.5z"/>
            <path className="mars-st1" d="M489.4,55.8C739.5,55.8,943,259.3,943,509.5S739.5,963.1,489.4,963.1S35.7,759.6,35.7,509.5
				S239.2,55.8,489.4,55.8z M912.8,509.5C912.8,276,722.8,86,489.4,86C255.9,86,65.9,276,65.9,509.5c0,233.5,190,423.4,423.5,423.4
				C722.8,932.9,912.8,742.9,912.8,509.5z"/>
            <path className="mars-st2" d="M764.4,400.3c0,18.3-14.9,33.2-33.2,33.2c-18.3,0-33.2-14.9-33.2-33.2v-69.9l44.1-44.1
				c4.8-4.8,7.9-10.8,9.1-17.1c6.8-5.1,11.2-13.3,11.2-22.5c0-5.1-1.3-9.8-3.7-14c3.6,5.3,5.7,11.7,5.7,18.6V400.3z"/>
            <path className="mars-st2" d="M758.7,232.7c2.4,4.1,3.7,8.9,3.7,14c0,9.2-4.4,17.4-11.2,22.5c2-10.5-1-21.8-9.1-29.9
				c-10.5-10.5-26.4-12.5-38.9-5.9c5-7.9,13.3-13.5,22.9-14.9h8.1c1,0,2,0.1,3,0.2C746.1,220.2,753.8,225.4,758.7,232.7
				C758.7,232.7,758.7,232.7,758.7,232.7z"/>
            <path className="mars-st2"
              d="M758.7,232.7c-4.9-7.2-12.6-12.4-21.5-14.1C746.4,219.6,754.3,225,758.7,232.7z"/>
            <path className="mars-st2" d="M742.1,239.3c8.1,8.1,11.2,19.4,9.1,29.9c-4.7,3.6-10.6,5.7-17,5.7H698v-23.6c0-6.6,1.9-12.7,5.2-17.9
				C715.7,226.8,731.6,228.8,742.1,239.3z"/>
            <path className="mars-st2"
              d="M751.2,269.2c-1.2,6.3-4.3,12.2-9.1,17.1L698,330.4v-55.5h36.2C740.6,274.9,746.5,272.8,751.2,269.2z"/>
            <path className="mars-st2"
              d="M737.2,218.6c-1-0.1-2-0.2-3-0.2h-8.1c1.6-0.2,3.3-0.4,5.1-0.4C733.2,218.1,735.2,218.3,737.2,218.6z"/>
            <path className="mars-st2" d="M585.2,218.4h140.9c-9.6,1.5-17.9,7.1-22.9,14.9c-2.9,1.5-5.6,3.5-8.1,5.9l-35.6,35.6h-74.3
				c-15.6,0-28.2-12.6-28.2-28.2S569.6,218.4,585.2,218.4z"/>
            <path className="mars-st2"
              d="M703.2,233.4c-3.3,5.2-5.2,11.3-5.2,17.9v23.6h-38.5l35.6-35.6C697.6,236.9,700.3,234.9,703.2,233.4z"/>
            <path className="mars-st2"
              d="M698,274.9v55.5L528.7,499.7c-6.3-9-13.5-17.5-21.5-25.5c-8-8-16.5-15.2-25.5-21.5l177.8-177.8H698z"/>
            <path className="mars-st2" d="M528.7,499.7c52.8,75.1,45.7,179.9-21.5,247C469.7,784.2,420.4,803,371,803s-98.7-18.8-136.3-56.4
				c-75.1-75.1-75.1-197.4,0-272.5c67.1-67.1,171.9-74.3,247-21.5l-17.9,17.9c-13,13-13,34,0,47c6.5,6.5,15,9.7,23.5,9.7
				s17-3.2,23.5-9.7L528.7,499.7z M460.3,699.7c49.2-49.2,49.2-129.3,0-178.6c-24.6-24.6-57-36.9-89.3-36.9
				c-32.3,0-64.7,12.3-89.3,36.9c-49.2,49.2-49.2,129.3,0,178.6C331,748.9,411.1,748.9,460.3,699.7z"/>
            <path className="mars-st2" d="M528.7,499.7l-17.9,17.9c-6.5,6.5-15,9.7-23.5,9.7s-17-3.2-23.5-9.7c-13-13-13-34,0-47l17.9-17.9
				c9,6.3,17.5,13.4,25.5,21.5C515.3,482.2,522.4,490.7,528.7,499.7z"/>
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>

  );
};

export default Mars;
