import React from 'react';
import IconProps from './IconProps';

const FineArtsIcon = ({ style, color = '#be1823', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="fineArtsIcon" data-name="fineArtsIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
      style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <path d="M607.6,1000c-113.1,0-233-34-333.2-80.3l26.4-57.2c166.6,76.9,390.8,118.6,520.6,4.9
		C982,726.9,935.5,469.6,865,331c-11.8-23.7-25-45.5-40.4-67l51.3-36.7c17.3,24.2,32.2,48.9,45.5,75.3
		c79.3,156,129.3,448-58.3,612.2C793.3,975.9,702.8,1000,607.6,1000z"/>
      </g>
      <g>
        <path d="M101.5,808c-77.2-75.7-111-171.5-84.1-238.2c26-64.8,100.1-55.6,159.7-48.3c55,6.7,93.7,9.7,111.4-10.6
		c-14.4-22.4-74.8-75.1-138.6-111.2C72.3,355.7,33.4,273.5,53.3,195.1c14.4-56.7,81.8-189.3,396.9-195
		C588.1-2.6,722,55.3,819.7,158.4l-45.8,43.4C688.4,111.5,569.5,61,451.3,63.2C262.9,66.6,137,121.7,114.5,210.6
		c-12.8,50.5,14,104.4,66.6,134.2c2.1,1.2,205.7,117.3,168.1,188.6c-36.1,68.5-115.7,58.7-179.7,50.8c-51.7-6.3-86.1-9.1-93.5,9.3
		c-14.6,36.1,4.8,105.9,69.8,169.7L101.5,808z"/>
      </g>
      <g>
        <path d="M796.8,638.9c-51.8,0-94-42.1-94-94s42.2-94,94-94c51.8,0,94,42.1,94,94S848.6,638.9,796.8,638.9z M796.8,514
		c-17,0-30.9,13.8-30.9,30.9c0,17,13.9,30.9,30.9,30.9s30.9-13.8,30.9-30.9C827.7,527.9,813.8,514,796.8,514z"/>
      </g>
      <g>
        <path d="M536.1,303.8c-51.8,0-94-42.1-94-94s42.2-94,94-94c51.8,0,94,42.1,94,94S587.9,303.8,536.1,303.8z M536.1,179
		c-17,0-30.9,13.8-30.9,30.9s13.9,30.9,30.9,30.9s30.9-13.8,30.9-30.9S553.1,179,536.1,179z"/>
      </g>
      <g>
        <path d="M287.6,315.9c-51.8,0-94-42.1-94-94s42.2-94,94-94c51.8,0,94,42.1,94,94S339.4,315.9,287.6,315.9z M287.6,191
		c-17,0-30.9,13.8-30.9,30.9s13.9,30.9,30.9,30.9c17,0,30.9-13.8,30.9-30.9S304.6,191,287.6,191z"/>
      </g>
      <g>
        <path d="M686.7,866.1c-51.8,0-94-42.1-94-94c0-51.8,42.2-94,94-94c51.8,0,94,42.1,94,94
		C780.6,824,738.5,866.1,686.7,866.1z M686.7,741.3c-17,0-30.9,13.8-30.9,30.9c0,17,13.9,30.9,30.9,30.9c17,0,30.9-13.8,30.9-30.9
		C717.5,755.1,703.7,741.3,686.7,741.3z"/>
      </g>
      <g>
        <path d="M426.9,697.5c-19.3,0-37.4-7.5-51-21.1L360,660.5c-13.8-13.8-21.3-32.1-21.1-51.6c0.2-19.8,8.1-38.1,22.3-51.9
		l469.8-453.9c28.4-27.4,74-27.1,101.8,0.6c27.8,27.8,28,73.4,0.6,101.8L479.4,675.2c-13.8,14.2-32.2,22.1-51.9,22.3
		C427.3,697.5,427.1,697.5,426.9,697.5z M881.6,146c-2.4,0-4.8,0.9-6.6,2.7L405.2,602.6c-2.5,2.5-3,5.3-3,6.8c0,1.7,0.4,4.1,2.5,6.3
		l15.9,15.9c2.1,2.1,3.5,2.5,6.3,2.5c1.5,0,4.4-0.4,6.8-3l453.8-469.8c3.6-3.7,3.6-9.4,0.2-12.8C886.2,146.8,883.9,146,881.6,146z"
        />
      </g>
      <g>

        <rect x="312.9" y="627.8" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 146.1517 1407.0884)" width="103.2" height="90.9" />
        <path d="M368.8,786.1L251.7,668.9l108.5-108.4l117.2,117.2L368.8,786.1z M340,668.9l28.8,28.8l20.1-20.1l-28.8-28.8
		L340,668.9z"/>
      </g>
      <g>
        <path d="M164.3,963.6c-20.4,0-41-2.6-61.2-8l-17.6-4.7l-4.7-17.6c-21.8-82,1.7-170,61.5-229.7
		c59.7-59.7,147.7-83.3,229.7-61.5l17.7,4.7l4.7,17.7c21.8,82-1.7,170-61.5,229.7C287.8,939.1,226.7,963.6,164.3,963.6z
		 M137.8,898.5c54.7,8.4,110.9-9.6,150.4-49c39.4-39.4,57.4-95.6,49-150.4c-54.6-8.3-111,9.6-150.4,49
		C147.4,787.6,129.4,843.8,137.8,898.5z"/>
      </g>
    </svg>
  );
};

export default FineArtsIcon;
