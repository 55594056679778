/*                                                                                                           */
/* Layout for full screen navigation to destination                                                          */
/* Currently used in SpecimenNav, RouteStopNav                                                               */
/*                                                                                                           */
import { viewport } from "@placemarkio/geo-viewport";
import turfBbox from '@turf/bbox';
import { AllGeoJSON } from '@turf/helpers';
import turfDistance from '@turf/distance';
import MapAttribution from "components/Map/MapAttribution";
import CustomSpinner from "components/common/CustomSpinner";
import NotificationManager from "components/common/react-notifications/NotificationManager";
import { UserContext } from "contexts/user/UserContextProvider";
import IntlMessages from "helpers/IntlMessages";
import { getCurrentOrientation, handleOrientation, roundIfFloat } from "helpers/utils-typescript";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import CircleSlice8Icon from "mdi-react/CircleSlice8Icon";
import CrosshairsGpsIcon from "mdi-react/CrosshairsGpsIcon";
import DirectionsWalkIcon from 'mdi-react/DirectionsWalkIcon';
import NavigationIcon from "mdi-react/NavigationIcon";
import { Line } from "pages/Commons/Quest/RouteMap";
import { Map, Marker, Overlay, Point } from 'pigeon-maps';
import { maptiler } from 'pigeon-maps/providers';
import { Fragment, ReactElement, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import * as geolocated from 'react-geolocated';
import { useIntl } from "react-intl";
import { Link, useHistory } from 'react-router-dom';

type MapNavLayoutProps = {
  arrivalModalPicUrl: 'string';
  destinationCoords: Point;
  /**
  * markerOffset: values depends on the size of marker, to keep the marker from drifting when zooming out
  */
  destinationMarker: {
    marker: ReactElement,
    markerOffset?: [number, number],
  };
  destinationName: string;
  setConfirmObserved: React.Dispatch<SetStateAction<boolean>>;
  defaultGoBackUrl: string;
}

/**
 * Fullscreen map, a destination (stop/specimen), current user location, connected with a line, and distance
 * @param arrivalModalPicUrl url of picture that will be shown to user when arriving, allowing to confirm that she sees the specimen
 * @param setConfirmObserved set state function that is called when user confirms that she can see the specimen
 *  */
const MapNavLayout = ({ arrivalModalPicUrl, destinationCoords, destinationMarker, destinationName, setConfirmObserved, defaultGoBackUrl }: MapNavLayoutProps) => {
  const maptilerProvider = maptiler(import.meta.env.VITE_MAPTILER_API_KEY, 'topo');

  const intl = useIntl();
  const history = useHistory();
  const { isAuthenticated } = useContext(UserContext);

  /* */
  /* States */
  /* */
  const [mapInitialCenterZoom, setMapInitialCenterZoom] = useState({ center: [null, null], zoom: null });
  const [mapCenterZoom, setMapCenterZoom] = useState({ center: [null, null], zoom: null });
  const [getCurrentLocationButtonClicked, setGetCurrentLocationButtonClicked] = useState(false);
  const [distance, setDistance] = useState(null);
  const [offsetForDistanceOverlay, setOffsetForDistanceOverlay] = useState([null, null]);
  const [showConfirmObservationModal, setShowConfirmObservationModal] = useState(false);
  const [showLogObservationButton, setShowLogObservationButton] = useState(false);
  const [compass, setCompass] = useState(null);

  /* */
  /* Get device current location and orientation */
  /* */
  const { coords, isGeolocationAvailable, isGeolocationEnabled, positionError } = geolocated.useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 120000, // 120 s
    },
    watchPosition: true
  });



  useEffect(() => {
    getCurrentOrientation(setCompass, handleOrientation);

    return () => {
      window.removeEventListener('deviceorientation', (event: any) => handleOrientation(event, setCompass));
      window.removeEventListener('deviceorientationabsolute', (event: any) => handleOrientation(event, setCompass));
    };
  }, []);

  // onClick of the location button, trigger map recenter on current location 
  useEffect(() => {
    if (getCurrentLocationButtonClicked) {
      if (coords) {
        setMapCenterZoom((preState) => { return { ...preState, center: [coords.latitude, coords.longitude] }; });
      }
      setGetCurrentLocationButtonClicked(false);
    }
  }, [intl,
    coords,
    setGetCurrentLocationButtonClicked,
    getCurrentLocationButtonClicked,
  ]);

  /* */
  /* Location error message */
  /* */
  useEffect(() => {
    if (!isGeolocationAvailable) {
      NotificationManager.error(
        intl.formatMessage({ id: 'Geolocation service not available' }),
        null,
        3000,
        null,
        null,
        'filled'
      );
    }
    if (!isGeolocationEnabled) {
      NotificationManager.error(
        intl.formatMessage({ id: 'bioregion.error-access-denied' }),
        null,
        3000,
        null,
        null,
        'filled'
      );
    }
    if (positionError) {
      console.error(`Problem with geolocation: ${positionError.message} (${positionError.code})`)
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, positionError, intl]);

  /* */
  /* Calculate distance on a sphere */
  /* */
  useEffect(() => {
    (coords && destinationCoords) && setDistance(
      turfDistance(
        [coords?.latitude, coords?.longitude],
        destinationCoords,
        { units: 'kilometers' }
      ));
  }, [coords, destinationCoords]);

  useEffect(() => {
    (distance !== null && distance <= 0.01) &&
      // don't show the "confirm visual contact" modal if the [keep searching] button was clicked (showLogObservationButton===true)
      !showLogObservationButton && setShowConfirmObservationModal(true)
  }, [distance, showLogObservationButton]);

  /* */
  /* Map center and zoom based on the line between the destination and current location */
  /* */
  const setWidthHeightZoom = useCallback(node => {
    if (node !== null && destinationCoords && coords) {
      const mapWidth = node.clientWidth as number;
      const mapHeight = node.clientHeight as number;
      const verticalOffset = isAuthenticated ? 20 : 50; // there is the "pls sign in to save your progress" yellow bar when not signed in
      setOffsetForDistanceOverlay([mapWidth / 2, verticalOffset]);
      const allStopsGeojson = {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [coords?.latitude, coords?.longitude]
            }
          },
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': destinationCoords
            }
          },
        ]
      } as AllGeoJSON;
      const locationBoundingBox = turfBbox(allStopsGeojson);
      const { center, zoom } = viewport(locationBoundingBox as any, [mapWidth, mapHeight])
      setMapInitialCenterZoom({ center, zoom });
    }
  }, [destinationCoords, coords, isAuthenticated]);

  /* */
  /* Render */
  /* */
  return (
    <div style={{ position: 'fixed', top: '60px', left: 0, width: '100%' }} >


      {/* Buttons and invitation to sing in*/}
      <div
        className='d-flex flex-column p-2'
        style={{ position: 'absolute', top: '0', left: '0', width: '100%' }}
      >
        {!isAuthenticated &&
          <div
            className='w-100 mt-n2 p-1 d-flex clickable'
            style={{ backgroundColor: '#EFB700', zIndex: '1', left: '0', position: 'absolute', lineHeight: '1rem', height: '2.5rem' }}
            onClick={() => {
              history.push({
                pathname: '/login',
                state: {
                  from: history.location.pathname,
                }
              });
            }}
          >
            <div className='my-auto mx-auto text-black-50'><IntlMessages id='route.quiz.map.invite-log-in' /></div>
          </div>
        }

        {/* Buttons*/}
        <div className='d-flex justify-content-between' style={!isAuthenticated ? { top: '2.5rem', position: 'relative' } : {}}>

          {/* Back button */}
          <Link
            to={history.location.state?.['from'] || defaultGoBackUrl}
            className='btn btn-xs btn-shadow rounded-circle p-0 mb-auto d-flex'
            style={{ width: '3rem', height: '3rem', backgroundColor: 'rgba(245,245,245,0.8)', borderWidth: '2px', zIndex: '1' }}
          >
            <ChevronLeftIcon size={'1.75rem'} color='#60b6aa' strokeWidth={'2%'} stroke={'#60b6aa'} className='my-auto mx-auto' />
          </Link>

          <div className='d-flex flex-column mb-auto'>
            {/* Get current location button */}
            <div
              className='btn btn-sm btn-shadow rounded-circle p-0 d-flex'
              style={{ width: '3rem', height: '3rem', backgroundColor: 'rgba(245,245,245,0.8)', borderWidth: '2px', zIndex: '1' }}
              onClick={() => {
                getCurrentOrientation(setCompass, handleOrientation); // give ios ppl one more chance to allow access to compass
                setGetCurrentLocationButtonClicked(true);
              }}
            >
              <CrosshairsGpsIcon size={'1.5rem'} color='#60b6aa' className='my-auto mx-auto' />
            </div>
            {/* "Open in external map" button */}
            <a
              className='btn btn-sm btn-shadow rounded-circle p-0 d-flex mt-2'
              style={{ width: '3rem', height: '3rem', backgroundColor: 'rgba(245,245,245,0.8)', borderWidth: '2px', zIndex: '1' }}
              onClick={() => { setGetCurrentLocationButtonClicked(true); }}
              href={`https://www.google.com/maps/search/?api=1&query=${destinationCoords[0]}%2C${destinationCoords[1]}`}
              target="_blank"  // open url in a new tab
              rel="noreferrer" // recommenced by lint for security reason
            >
              <DirectionsWalkIcon size={'1.8rem'} color='#60b6aa' className='my-auto mx-auto' />
            </a>
          </div>
        </div>
      </div>

      <div
        className='top-nav-full-screen d-flex'
        style={{ overflow: 'hidden' }}
        ref={setWidthHeightZoom}
      >

        {mapInitialCenterZoom.zoom ?
          <Map
            maxZoom={20}
            provider={maptilerProvider}
            attributionPrefix={false} // hide "Pigeon" text
            attribution={<MapAttribution />}
            dprs={[1, 2]}
            center={[mapCenterZoom.center[0], mapCenterZoom.center[1]]}
            zoom={mapCenterZoom.zoom}
            defaultCenter={[mapInitialCenterZoom.center[0], mapInitialCenterZoom.center[1]]}
            defaultZoom={mapInitialCenterZoom.zoom - 1} // because of invisible map overflow, the calculated zoom may be too close; but seems to be OK like this on mobile
            onBoundsChanged={({ center, zoom, initial }) => {
              !initial && setMapCenterZoom({ center, zoom });
            }}
          >

            {/* Distance & accuracy overlay */}
            <Overlay
              style={{ transform: `translate(${offsetForDistanceOverlay[0]}px, ${offsetForDistanceOverlay[1]}px)` }}
            >
              <div className="text-center mb-0"
                style={{ transform: 'translateX(-50%)' }}
              >
                {coords ?
                  <Fragment>
                    <div className="h2 font-weight-bolder">
                      {/* if distance is smaller than 1km, show distance in meter */}
                      {distance > 1 ? `${distance.toFixed(2)} km` : `${(distance * 1000).toFixed(2)} m`}
                    </div>
                    <div className="h3">{`[+/-${roundIfFloat(coords.accuracy, 1)} m]`}</div>
                  </Fragment>
                  :
                  positionError ?
                    <div className="h2 font-weight-bolder">
                      Couldn't get your current location.
                    </div>
                    :
                    <div className="h2 font-weight-bolder">
                      Waiting for location...
                    </div>
                }
              </div>
            </Overlay>

            {/* Line that represents the navigation */}
            <Line
              coordsArray={[[coords?.latitude, coords?.longitude], destinationCoords]}
              style={{ stroke: '#60b6aa', strokeWidth: 4 }}
            />

            { /* Device current position marker - without compass */}
            {coords && !compass &&
              <Marker
                anchor={[coords.latitude, coords.longitude]}
                offset={[-5, -20]} // this offset seems to put center of the marker right onto the anchor coordinates
              >
                <CircleSlice8Icon size='20px' color="#3988f3" style={{ zIndex: 3 }} />
              </Marker>
            }

            { /* Device current position marker - with compass */}
            {coords && compass &&
              <Marker
                anchor={[coords.latitude, coords.longitude]}
                offset={[0, -15]} // this offset seems to put center of the marker right onto the anchor coordinates
              >
                <NavigationIcon size='30px' color="#3988f3" style={{ zIndex: 3, transform: `rotate(${compass}deg)` }} />
              </Marker>
            }

            {/* Destination Marker */}
            <Marker
              offset={destinationMarker.markerOffset}
              width={30} anchor={destinationCoords}
              onClick={() => {
                setMapCenterZoom(() => {
                  return {
                    ...mapInitialCenterZoom,
                    center: [destinationCoords[0] - 0.00025, destinationCoords[1]]
                  };
                });
              }}
            >
              {destinationMarker.marker}
            </Marker>

            {/* linear gradient background to make overlay and on map more visible */}
            <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: '0', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3) 2%, transparent 20%,  transparent 80%, rgba(0, 0, 0, 0.3) 98%)' }} />
          </Map >
          :
          <div className="mx-auto d-flex flex-column justify-content-center align-items-center">
            <div className="h2 font-weight-bolder text-muted mb-4">Waiting for location</div>
            <CustomSpinner iconStyle="grow" spinnerSize="default" classNameForIndividualSpinner="mx-2 text-muted" className='mt-4' />
          </div>
        }
      </div >

      {/* "Do you see x now" Modal */}
      <Collapse in={showConfirmObservationModal}>
        <Card
          className=''
          id="arriveModal"
          style={{
            zIndex: 2,
            position: 'absolute', bottom: '0', left: '0', right: '0',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }}>
          <div
            className="h3 text-center py-3 px-2 mb-0 color-theme-1 font-weight-bolder"
          >
            {intl.formatMessage({ id: 'route.stop-nav.arrive-title' }, { specimenName: destinationName })}
          </div>

          {/* use div with background instead of img so when the pic is loading, the card is already expend to correct height */}
          <div
            style={{
              backgroundSize: 'cover',
              height: '60vh',
              borderRadius: 0,
              backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0.8) 10%, rgba(255,255,255,0) 30%,rgba(255,255,255,0)), url(${arrivalModalPicUrl})`
            }}
          />

          <div className="w-100 d-flex" style={{ position: 'absolute', bottom: '1rem' }}>
            <button
              className="btn btn-outline-primary w-40 mx-auto"
              style={{ background: 'white' }}
              onClick={() => { setShowConfirmObservationModal(false); setShowLogObservationButton(true); }}
            >
              <IntlMessages id={'route.stop-nav.keep-searching'} />
            </button>
            <button
              className="btn btn-yellow w-40 mx-auto"
              onClick={() => { setConfirmObserved(true); }}
            >
              <IntlMessages id={'general.yes'} />
            </button>
          </div>

        </Card>
      </Collapse>

      {/* Log observation button */}
      {/* only show [log observation] button if the distance to the stop is smaller than 10m */}
      {
        distance !== null && distance <= 0.01 &&
        showLogObservationButton &&
        <button
          className="btn btn-yellow btn-lg d-flex mx-auto mb-auto text-center"
          style={{
            position: 'absolute',
            bottom: '5vh',
            left: '50%',
            transform: 'translate(-50%)'
          }}
          onClick={() => { setShowConfirmObservationModal(true); setShowLogObservationButton(false); }}
          aria-expanded={showConfirmObservationModal} aria-controls="collapseQrScanner"
        >
          <IntlMessages id={'route.stop-nav.log-observation'} />
        </button>
      }

    </div >

  )
}

export default MapNavLayout;
