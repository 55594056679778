import { BadgeModalContent } from 'components/Modal/InfoModal';
import { getFacilitationBadgesArray, impactPointsRange } from 'helpers/utils-typescript';
import React from 'react';
import Elm from './Elm';
import Fir from './Fir';
import Ginko from './Ginko';
import Oak from './Oak';
import Poplar from './Poplar';
import Willow from './Willow';
import Yew from './Yew';

type FacilitationBadgeProps = {
  facilitationImpactPoints: number;
  className?: string;
  size?: number | string;
  /* if set to true, for user who has no badge. show first badge in gradient corresponding to user's progress to obtain it*/
  showProgress?: boolean;
  /* if set to true, for user who has no badge. show first badge in gray to user's progress to obtain it*/
  showNonObtainedBadgeInGray?: boolean,
  onClick?: () => void;
}

export const getFacilitationBadge = (
  facilitationImpactPoints: number,
  className?: string,
  size?: number | string,
  showProgress?: boolean,
  showNonObtainedBadgeInGray?: boolean,
): React.ReactElement => {
  switch (true) {
    // if user don't have any facilitationBadges, show the first Badge with the out circle fill up to the percentage the user has obtained, inner icon remain grey
    case impactPointsRange(facilitationImpactPoints, 0, 99):
      return (showProgress
        ? <Poplar className={className} size={size} style={{ color: 'RGBA(96, 182, 170, 0.3)' }}
          gradient={[
            { offsetValue: `${(100 - facilitationImpactPoints)?.toString()}%`, stopOpacity: '0.3' },
            { offsetValue: `${facilitationImpactPoints?.toString()}%`, stopOpacity: '1' },

          ]}
        />
        : showNonObtainedBadgeInGray ? <Poplar className={className} size={size} style={{ color: 'RGBA(96, 182, 170)', filter: 'grayscale(100%)' }} /> : null);
    case impactPointsRange(facilitationImpactPoints, 100, 299):
      return <Poplar className={className} size={size} />;
    case impactPointsRange(facilitationImpactPoints, 300, 999):
      return <Yew className={className} size={size} />;
    case impactPointsRange(facilitationImpactPoints, 1000, 2999):
      return <Elm className={className} size={size} />;
    case impactPointsRange(facilitationImpactPoints, 3000, 4999):
      return <Fir className={className} size={size} />;
    case impactPointsRange(facilitationImpactPoints, 5000, 6999):
      return <Willow className={className} size={size} />;
    case impactPointsRange(facilitationImpactPoints, 7000, 9999):
      return <Oak className={className} size={size} />;
    case impactPointsRange(facilitationImpactPoints, 10000, 9999999999):
      return <Ginko className={className} size={size} />;
    default:
      break;
  }
};


export const FacilitationBadgeModalContent = () => {
  return BadgeModalContent('badge.facilitation-modal-title', 'badge.facilitation-modal-subtitle', getFacilitationBadgesArray().slice(1));  // the first one from the BadgesArray is a badge to show progress from 0 to first badge, should not show in badge modal
};

const FacilitationBadge = (
  {
    facilitationImpactPoints,
    className,
    size,
    onClick,
    showProgress,
    showNonObtainedBadgeInGray
  }: FacilitationBadgeProps): React.ReactElement => {

  return (
    <div onClick={onClick}>
      {getFacilitationBadge(facilitationImpactPoints, className, size, showProgress, showNonObtainedBadgeInGray)}
    </div>
  );
};
export default FacilitationBadge;
