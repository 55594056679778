import { speciesIconSrc } from 'hooks/useSpeciesCategoryOptions';
import useSpecieses from 'hooks/useSpecieses';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Species, SpeciesType } from 'services/graphqltypes';
import { Option } from '../components/custom/CustomReactSelect';
import { getTranslatedString, getUiTranslation } from '../helpers/utils-typescript';

/* this hook returns a list of specieses options, optionally filtered by ecoregion gid, as options that can be used in React Select */
const useSpeciesesOptions = (
  /** pass an ecoregionGid to only load species from a specific ecoregion */
  ecoregionGid?: number,
  /** Fetch species from a specific category. If not passed, fetches all categories of species. */
  category?: SpeciesType,
  /** If true, query is skipped (not executed) */
  skip?: boolean,
  includingDraftSpecieses?: boolean
) => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const {
    loading: speciesesLoading,
    error: speciesesError,
    specieses,
    refetch
  } = useSpecieses(
    ecoregionGid,
    category,
    includingDraftSpecieses ? null : true,
    skip
  );

  /* Options for Specieses */
  const speciesesOptions = useMemo(() => {
    const option: Option = { value: '', label: getUiTranslation('general.none', currentLang), tag: '' };
    if (skip) {
      return;
    }
    if (speciesesLoading) {
      option.label = 'Loading...';
      return [{ ...option }];
    }
    if (speciesesError) {
      option.label = 'Loading failed. Check your connection and reload~';
      return [{ ...option }];
    }

    return specieses.map((species: Species) => {
      return {
        label: getTranslatedString(species.name, currentLang),
        value: species.id,
        tag: species.scientificName,
        image: speciesIconSrc[species.category.toLowerCase()],
      } as Option;
    });

  }, [currentLang, specieses, speciesesError, speciesesLoading, skip]);

  return { specieses, speciesesLoading, speciesesError, speciesesOptions, refetch };

};

export default useSpeciesesOptions;
