import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const PeaceJusticeStrongInstitutionIcon = ({ style, size = 100, className }: IconProps):
    React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="PeaceJusticeStrongInstitution" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
           .peace-justice-strong-institution{fill - rule:evenodd;clip-rule:evenodd;fill:#01578E;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3-6-0-4-3-6-3-7-4-9" className="peace-justice-strong-institution" d="M62.2,0.5c6.8,0,12.2,5.5,12.2,12.2v49.4c0,6.8-5.5,12.2-12.2,12.2
	H12.9c-6.8,0-12.2-5.5-12.2-12.2V12.9c0-6.9,5.6-12.4,12.4-12.4L62.2,0.5L62.2,0.5z"/>
      <g id="text6065-3-2-9-6-6-5-5-2-9-7">
        <path id="path2554" className="st1" d="M24.1,13.2V7h1.4l0,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		C26.9,8,26.9,8.1,27,8.3v0.9v0.1c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.4,0.3c-0.2,0-0.3,0.1-0.5,0.1h-0.1H25v2.4
		H24.1L24.1,13.2z M26,9.8c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H25v2.1h0.4
		c0.1,0,0.2,0,0.3,0C25.8,9.9,25.9,9.8,26,9.8L26,9.8z"/>
        <path id="path2556" className="st1"
          d="M30.3,12.3v0.9h-2.7V7h2.7v0.8h-1.8v1.8H30v0.9h-1.5v1.8L30.3,12.3L30.3,12.3z"/>
        <path id="path2558" className="st1" d="M30.5,13.2L31.9,7h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1l-0.2,1.3H30.5z M31.9,11h0.8l-0.4-2.2
		L31.9,11z"/>
        <path id="path2560" className="st1" d="M37.3,11.6c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5s0-0.3,0-0.5V8.4c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9V8.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.4-0.1
		c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9L37.3,11.6z"/>
        <path id="path2562" className="st1"
          d="M40.6,12.3v0.9h-2.7V7h2.7v0.8h-1.8v1.8h1.5v0.9h-1.5v1.8L40.6,12.3L40.6,12.3z"/>
        <path id="path2564" className="st1" d="M41.1,13.9v-1.5H42v0.9L41.1,13.9z"/>
        <path id="path2566" className="st1" d="M46,12.6c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2s-0.4,0-0.6-0.1
		c-0.2-0.1-0.4-0.2-0.5-0.4l0.7-0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4,0,0.5-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.2V7h0.9v4.6
		c0,0.3,0,0.5,0,0.6C46.2,12.3,46.1,12.4,46,12.6z"/>
        <path id="path2568" className="st1" d="M49.8,12.5c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4V7h0.9v4.8
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1s0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.2C49,12,49,11.8,49,11.7V6.9h0.9v4.8
		c0,0.1,0,0.3,0,0.4C49.8,12.3,49.8,12.4,49.8,12.5L49.8,12.5z"/>
        <path id="path2570" className="st1" d="M51.4,11.6c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3
		c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.6-0.2S51,10,50.8,9.8c-0.2-0.2-0.3-0.5-0.3-1V8.5c0-0.4,0.1-0.8,0.4-1.1
		C51.3,7.1,51.6,7,52,7c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9V8.5c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
		c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3V9c0,0.1,0,0.2,0,0.3
		c0,0,0.1,0.1,0.3,0.2c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5
		c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2
		L51.4,11.6L51.4,11.6L51.4,11.6z"/>
        <path id="path2572" className="st1" d="M54.7,13.2V7.8h-1V7h3v0.8h-1v5.4L54.7,13.2L54.7,13.2z"/>
        <path id="path2574" className="st1" d="M57.2,13.2V7h0.9v6.2H57.2z"/>
        <path id="path2576" className="st1" d="M61.7,11.6c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.1,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5V8.4c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1.1,0.4s0.4,0.6,0.4,1.1v0.3h-0.9V8.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.1,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2s0.3,0,0.4-0.1
		c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9L61.7,11.6z"/>
        <path id="path2578" className="st1"
          d="M65,12.3v0.9h-2.7V7H65v0.8h-1.8v1.8h1.5v0.9h-1.5v1.8L65,12.3L65,12.3z"/>
        <path id="path2580" className="st1" d="M23.8,21.8l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3H25l-0.2,1.3H23.8z M25.1,19.6H26l-0.4-2.2
		L25.1,19.6z"/>
        <path id="path2582" className="st1" d="M30.1,21.8L28.7,18v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H30.1z"/>
        <path id="path2584" className="st1" d="M33,15.6c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4v-6.2H33z M33.5,20.2V17c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.6h0.4
		C33.3,20.9,33.5,20.7,33.5,20.2L33.5,20.2z"/>
        <path id="path2586" className="st1" d="M36.9,20.2c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3
		c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1
		c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
		c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2C37,16.8,37,16.9,37,17.1v0.3v0.1c0,0.1,0,0.2,0,0.3
		s0.1,0.1,0.3,0.2c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5s0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5
		c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L36.9,20.2L36.9,20.2z"
        />
        <path id="path2588" className="st1"
          d="M40.3,21.8v-5.4h-1v-0.8h3v0.8h-1v5.4C41.2,21.8,40.3,21.8,40.3,21.8z"/>
        <path id="path2590" className="st1" d="M42.6,21.8v-6.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3
		l0.9,2.8h-0.9l-0.8-2.7h-0.5v2.7H42.6L42.6,21.8z M44.6,18.2c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3V17c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C44.3,18.3,44.5,18.3,44.6,18.2L44.6,18.2z"/>
        <path id="path2592" className="st1" d="M46.5,16.2c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2s0.4,0,0.6,0.1s0.4,0.2,0.5,0.4
		c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
		c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C46.3,16.4,46.4,16.3,46.5,16.2L46.5,16.2z M48.2,16.6
		c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1
		c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0s0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2
		v-3.2c0-0.1,0-0.2,0-0.2C48.3,16.8,48.2,16.7,48.2,16.6L48.2,16.6z"/>
        <path id="path2594" className="st1" d="M52.2,21.8L50.8,18v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8H53v6.2H52.2z"/>
        <path id="path2596" className="st1" d="M56.4,21.1c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
		c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.4,0.3-0.6
		c0.2-0.3,0.5-0.4,1.1-0.4c0.2,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3,0,0.5v0.2h-0.9v-0.2
		c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0,0,0.1,0,0.2v3.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.5,0.2s0.4,0,0.4-0.2c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v-1.1H55v-0.8h1.6v1.9c0,0.1,0,0.2,0,0.3s0,0.1,0,0.2C56.6,21,56.5,21.1,56.4,21.1
		C56.4,21,56.4,21.1,56.4,21.1L56.4,21.1z"/>
        <path id="path2598" className="st1" d="M24.1,30.3v-6.2H25v6.2H24.1z"/>
        <path id="path2600" className="st1" d="M28,30.3l-1.4-3.8v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H28z"/>
        <path id="path2602" className="st1" d="M30.1,28.8c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2s0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5s-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
		C31.2,25.1,31,25,30.8,25s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.2V26c0,0.1,0,0.2,0,0.3s0.1,0.1,0.3,0.2c0,0,0.1,0,0.2,0.1
		c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5s0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.3-0.4,0.6
		c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L30.1,28.8L30.1,28.8z"/>
        <path id="path2604" className="st1" d="M33.5,30.3V25h-1v-0.8h3V25h-1v5.4L33.5,30.3L33.5,30.3z"/>
        <path id="path2606" className="st1" d="M35.9,30.3v-6.2h0.9v6.2H35.9z"/>
        <path id="path2608" className="st1" d="M38.3,30.3V25h-1v-0.8h3V25h-1v5.4L38.3,30.3L38.3,30.3z"/>
        <path id="path2610" className="st1" d="M43.4,29.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.8h0.9
		v4.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1s0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4v-4.8h0.9
		v4.8c0,0.1,0,0.3,0,0.4C43.5,29.5,43.4,29.6,43.4,29.7L43.4,29.7z"/>
        <path id="path2612" className="st1" d="M45,30.3V25h-1v-0.8h3V25h-1.1v5.4L45,30.3L45,30.3z"/>
        <path id="path2614" className="st1" d="M47.4,30.3v-6.2h0.9v6.2H47.4z"/>
        <path id="path2616" className="st1" d="M49.4,24.7c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2s0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C49.2,25,49.3,24.9,49.4,24.7
		L49.4,24.7z M51.1,25.2C51,25,50.8,25,50.6,25c-0.1,0-0.2,0-0.3,0.1s-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.2
		c0,0,0,0,0,0.1s0,0,0,0.1s0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2v-3.2c0-0.1,0-0.2,0-0.2C51.2,25.3,51.1,25.3,51.1,25.2L51.1,25.2z"/>
        <path id="path2618" className="st1" d="M55,30.3l-1.4-3.8v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H55z"/>
        <path id="path2620" className="st1" d="M57.2,28.8c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2s0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
		C58.2,25.1,58,25,57.8,25c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.2V26c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5s0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.3-0.4,0.6
		c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L57.2,28.8L57.2,28.8z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-1-4-7-6">
        <path id="path2623" className="st1" d="M8.7,21.8V9.2l-2.1,1.6V8.5L8.7,7h2.1v14.9H8.7z"/>
        <path id="path2625" className="st1" d="M18.6,14.2c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0,0.5,0,0.8v2.8c0,0.3,0,0.7-0.1,1
		c0,0.3-0.3,0.8-0.6,1.4c-0.4,0.6-1.1,0.9-2.2,1c-0.6,0-1.3-0.1-1.8-0.4c-0.5-0.3-0.9-0.6-1.1-1c-0.2-0.4-0.3-0.7-0.4-1
		c0-0.3,0-0.6,0-0.9v-2.3c0-0.3,0-0.5,0-0.7c0-0.1,0-0.3,0.1-0.6l2.9-8H18l-2.4,6.2c0.2-0.1,0.6-0.1,0.9-0.1h0.2
		C17.7,13.2,18.3,13.6,18.6,14.2L18.6,14.2z M16.6,15.4c-0.2-0.3-0.5-0.4-0.9-0.4c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.9
		v2.3c0,0.3,0,0.6,0.2,0.9c0.1,0.2,0.4,0.4,0.8,0.4h0.1c0.4,0,0.6-0.1,0.7-0.4c0.1-0.2,0.2-0.5,0.2-0.9v-2.3c0-0.2,0-0.3,0-0.4
		C16.8,15.6,16.7,15.5,16.6,15.4L16.6,15.4z"/>
      </g>
      <path id="rect8292-7-8" className="st2" d="M54.3,33.6c-1.7,0-1.9,0.9-2,2.1C54,35.7,54.3,34.7,54.3,33.6z M49.8,33.8
	c-1,1.4-0.3,2.1,0.6,2.8C51.4,35.3,50.7,34.5,49.8,33.8z M47.8,35.9c-1,1.4-0.3,2.1,0.6,2.8C49.4,37.3,48.7,36.6,47.8,35.9z
	 M51.7,36.2c0,0-0.1,0-0.1,0c-1.3,1.2-2.4,2.5-3.1,4c-0.1,0.3-0.2,0.7-0.3,1c-0.9-0.9-1.2-1.8-2.3-1.8c-2.7,0-4.1,4.3-7.9,4.3
	c-5.3,0-4.9-6.4-14.9-6.5c6,4.8-0.1,9.2,8.8,12.6c-3.8,0.7-3.9,3.9-8.9,2.3c2.3,0.9,4.8,3.8,9.5,4v3.5c-5.3-0.1-10.4-0.4-10.4-0.4
	v2.2c0,0,12.4-0.5,18.7-0.5c2,0,3.8,0.4,4.5,0.6V63c0,0.5,0.4,0.9,0.9,0.9h5.2c0.5,0,0.9-0.4,0.9-0.9v-5.2c0-0.5-0.4-0.9-0.9-0.9
	h-5.2c-0.5,0-0.9,0.4-0.9,0.9v1.4c-0.7,0.2-2.4,0.6-4.5,0.6c-1.4,0-3.1,0-4.9,0v-3.7c9.1-1,8.8-6.9,8.8-8.2
	c-0.1-4.4,1.9-5.4,3.6-5.6c0,0.9,0.1,1.8,0.5,2.9c0,0.1,0.2,0.1,0.3,0.1S49,45.1,49,45l0,0c-0.4-1-0.5-1.9-0.5-2.7
	c0.4,0,0.7,0,1-0.1c-0.4-0.1-0.7-0.3-0.9-0.5c0.1-0.4,0.2-0.8,0.4-1.2c0.6-1.4,1.7-2.6,3-3.8c0.1-0.1,0.1-0.2,0-0.3
	C51.9,36.2,51.8,36.2,51.7,36.2z M53,37.1c-0.5,0-1,0.4-1.4,0.8c1.2,1.1,2.1,0.6,2.9-0.2C53.9,37.2,53.4,37,53,37.1z M51.2,39.2
	c-0.5,0-0.9,0.2-1.4,0.5c1,1.4,1.9,1,2.8,0.3C52.2,39.5,51.7,39.2,51.2,39.2z M45.4,54.4c-0.5,0-0.9,0.4-0.9,0.9v0.2
	c0,0.5,0.4,0.9,0.9,0.9h6.5c0.5,0,0.9-0.4,0.9-0.9v-0.2c0-0.5-0.4-0.9-0.9-0.9H45.4z M34.6,56.2v3.6c-0.4,0-0.7,0-1.2,0v-3.5
	C33.9,56.2,34.3,56.2,34.6,56.2z M20.1,58.7c-0.5,0-0.9,0.4-0.9,0.9v1.6c0,0.5,0.4,0.9,0.9,0.9h0.2c0.5,0,0.9-0.4,0.9-0.9v-1.6
	c0-0.5-0.4-0.9-0.9-0.9H20.1z M45.4,64.4c-0.5,0-0.9,0.4-0.9,0.9v0.2c0,0.5,0.4,0.9,0.9,0.9h6.5c0.5,0,0.9-0.4,0.9-0.9v-0.2
	c0-0.5-0.4-0.9-0.9-0.9H45.4z"/>
    </svg>
  );
};

export default PeaceJusticeStrongInstitutionIcon;
