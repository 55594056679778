import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const DecentWorkEconomicGrowthIcon = ({ style, size = 100, className }: IconProps):
    React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="DecentWorkEconomicGrowth" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .decent-work-economic-growth{fill - rule:evenodd;clip-rule:evenodd;fill:#911A3A;}
           .st1{fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3-6-0-4-3" className="decent-work-economic-growth" d="M13.1,0.6h48.8c6.9,0,12.5,5.6,12.5,12.5v48.9c0,6.9-5.6,12.5-12.5,12.5H13.1
	c-6.9,0-12.5-5.6-12.5-12.5V13.1C0.6,6.1,6.2,0.6,13.1,0.6z"/>
      <g id="text6065-3-2-9-6-6-5-0">
        <path id="path2139" className="st1" d="M21.9,7.1c0.4,0,0.7,0.1,1,0.4s0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.1H21.9L21.9,7.1z M22.5,11.8V8.5c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.6h0.4
		C22.3,12.5,22.5,12.3,22.5,11.8z"/>
        <path id="path2141" className="st1"
          d="M26.8,12.4v0.9h-2.7V7.1h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8L26.8,12.4L26.8,12.4z"/>
        <path id="path2143" className="st1" d="M30.1,11.7c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5s0-0.3,0-0.5V8.5c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		C28.2,7.1,28.4,7,28.6,7c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9V8.5c0-0.2,0-0.3-0.1-0.4C29,8,28.8,7.9,28.7,7.9
		c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4V12c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.4-0.1
		c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9L30.1,11.7z"/>
        <path id="path2145" className="st1"
          d="M33.4,12.4v0.9h-2.7V7.1h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8L33.4,12.4L33.4,12.4z"/>
        <path id="path2147" className="st1" d="M36.2,13.3l-1.4-3.8v3.8h-0.9V7.1h0.8l1.4,3.8V7.1H37v6.2H36.2z"/>
        <path id="path2149" className="st1" d="M38.2,13.3V7.9h-1V7.1h3v0.8h-1v5.4L38.2,13.3L38.2,13.3z"/>
        <path id="path2151" className="st1" d="M44.6,13.3l-0.7-3.8l-0.7,3.8h-0.8l-0.9-6.2h0.9l0.4,3.8l0.7-3.8h0.6l0.7,3.8l0.4-3.8h0.9
		l-0.9,6.2H44.6z"/>
        <path id="path2153" className="st1" d="M46.8,7.7c0.1-0.2,0.3-0.3,0.5-0.4C47.6,7.1,47.8,7,48.1,7s0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.2,0-0.4V8.7c0-0.2,0-0.3,0-0.5C46.7,7.9,46.7,7.8,46.8,7.7L46.8,7.7z
		 M48.5,8.2c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.1
		c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2V8.6c0-0.1,0-0.2,0-0.2C48.6,8.3,48.6,8.2,48.5,8.2L48.5,8.2z"/>
        <path id="path2155" className="st1" d="M50.3,13.3V7.1h1.5c0.4,0,0.7,0.1,1,0.4s0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9
		l-0.8-2.7h-0.5v2.7L50.3,13.3z M52.3,9.7c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3V8.5c0-0.2,0-0.3-0.1-0.4C52.2,8,52,7.9,51.8,7.9
		h-0.6v1.9h0.6C52,9.9,52.2,9.8,52.3,9.7L52.3,9.7z"/>
        <path id="path2157" className="st1" d="M56.2,13.3l-1-2.8l-0.5,0.9v1.8h-0.9V7.1h0.9V10l1.3-2.9H57l-1.2,2.5l1.4,3.7L56.2,13.3
		L56.2,13.3z"/>
        <path id="path2159" className="st1" d="M58.1,13.3l1.4-6.2h0.7l1.4,6.2h-0.9L60.4,12h-1.1L59,13.3H58.1z M59.4,11.1h0.8l-0.4-2.2
		L59.4,11.1z"/>
        <path id="path2161" className="st1" d="M64.3,13.3l-1.4-3.8v3.8H62V7.1h0.8l1.4,3.8V7.1h0.9v6.2H64.3z"/>
        <path id="path2163" className="st1" d="M67.2,7.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.1H67.2L67.2,7.1z M67.8,11.8V8.5c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.6h0.4
		C67.6,12.5,67.8,12.3,67.8,11.8z"/>
        <path id="path2165" className="st1"
          d="M23.2,21v0.9h-2.7v-6.3h2.7v0.8h-1.8v1.8H23v0.8h-1.5V21L23.2,21L23.2,21z"/>
        <path id="path2167" className="st1" d="M26.4,20.3c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5s0-0.3,0-0.5v-3.2c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4s0.2-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.6-0.2
		c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9v-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2
		c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3s0.2,0.2,0.4,0.2s0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9
		L26.4,20.3z"/>
        <path id="path2169" className="st1" d="M27.3,16.2c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5s-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C27.1,16.5,27.2,16.4,27.3,16.2
		L27.3,16.2z M29,16.7c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.2
		c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0s0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2v-3.2c0-0.1,0-0.2,0-0.2C29.1,16.9,29.1,16.8,29,16.7L29,16.7z"/>
        <path id="path2171" className="st1" d="M33.1,21.9l-1.4-3.8v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.8v6.2H33.1z"/>
        <path id="path2173" className="st1" d="M34.7,16.2c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5s0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5s-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
		C36.5,21.9,36.2,22,36,22c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C34.5,16.5,34.6,16.4,34.7,16.2L34.7,16.2z M36.5,16.7
		c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1
		s0,0,0,0.1c0,0,0,0.1,0.1,0.3s0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2v-3.2
		c0-0.1,0-0.2,0-0.2C36.5,16.9,36.5,16.8,36.5,16.7L36.5,16.7z"/>
        <path id="path2175" className="st1"
          d="M41.2,21.9v-3.8l-0.9,2.6h-0.4l-0.9-2.6v3.8H38v-6.2h0.8l1.2,3.3l1.1-3.3H42v6.2H41.2z"/>
        <path id="path2177" className="st1" d="M42.6,21.9v-6.2h0.9v6.2H42.6z"/>
        <path id="path2179" className="st1" d="M47.2,20.3c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.1,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		s-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.2c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9v-0.3c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2
		c0.2,0,0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9L47.2,20.3z"/>
        <path id="path2181" className="st1" d="M51.6,21.2c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
		c-0.2-0.1-0.4-0.2-0.5-0.4s-0.2-0.3-0.2-0.5s0-0.3,0-0.5v-3.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.4,0.3-0.6c0.2-0.3,0.5-0.4,1.1-0.4
		c0.2,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5s0,0.3,0,0.5v0.2h-0.9v-0.2c0,0,0-0.1,0-0.1s0-0.1,0-0.1
		c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.2v3.1c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.3s0.2,0.2,0.5,0.2s0.4,0,0.4-0.2c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0-0.1,0-0.1
		v-1.2h-0.7v-0.8h1.6v1.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2C51.8,21,51.7,21.1,51.6,21.2C51.6,21.1,51.6,21.2,51.6,21.2
		L51.6,21.2z"/>
        <path id="path2183" className="st1" d="M52.4,21.9v-6.2H54c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.7h-0.5v2.7H52.4L52.4,21.9z M54.4,18.2c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3v-0.6c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C54.1,18.4,54.3,18.4,54.4,18.2L54.4,18.2z"/>
        <path id="path2185" className="st1" d="M56.2,16.2c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2s0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5S59,17,59,17.2v3.2c0,0.2,0,0.3,0,0.5s-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
		C58,21.9,57.8,22,57.5,22c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
		C56.1,21.1,56,21,56,20.9c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C56,16.5,56.1,16.4,56.2,16.2L56.2,16.2z M57.9,16.7
		c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1
		s0,0,0,0.1c0,0,0,0.1,0.1,0.3s0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0s0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2v-3.2
		c0-0.1,0-0.2,0-0.2C58,16.9,58,16.8,57.9,16.7L57.9,16.7z"/>
        <path id="path2187" className="st1" d="M62.6,21.9l-0.7-3.8l-0.7,3.8h-0.8l-0.9-6.2h0.9l0.4,3.8l0.7-3.8h0.6l0.7,3.8l0.4-3.8h0.9
		l-0.9,6.2H62.6z"/>
        <path id="path2189" className="st1" d="M65.5,21.9v-5.4h-1v-0.8h3v0.8h-1.1v5.4L65.5,21.9L65.5,21.9z"/>
        <path id="path2191" className="st1"
          d="M67.9,21.9v-6.2h0.9v2.6h1.1v-2.6h0.9v6.2h-0.9v-2.8h-1.1v2.8H67.9z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-0">
        <path id="path2194" className="st1" d="M14.7,18.5v0.1c0,0.3,0,0.6-0.2,1c-0.1,0.3-0.3,0.7-0.6,1c-0.2,0.3-0.6,0.5-0.9,0.7
		c-0.3,0.2-0.7,0.3-1.1,0.3h0c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.8-0.3s-0.5-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.4-0.6
		c-0.2-0.4-0.3-0.7-0.3-1c0-0.3,0-0.5,0-0.8v-2.6c0-0.7,0.3-1.4,1-1.9c-0.7-0.5-1-1.2-1-2.2v-2c0-0.3,0-0.6,0.1-0.8
		C8.6,8.4,8.8,8,9.3,7.5c0.4-0.5,1.2-0.8,2.3-0.8c0.4,0,0.8,0.1,1.2,0.2c0.3,0.2,0.8,0.5,1.2,1c0.4,0.4,0.7,1.1,0.7,1.9v1.9
		c0,0.8-0.3,1.5-1,2c0.7,0.5,1,1.2,1,2.1L14.7,18.5z M11.5,8.7c-0.3,0-0.5,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.9
		c0,0.3,0.1,0.6,0.2,0.9c0.2,0.2,0.4,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.2-0.5,0.2-0.9V9.8c0-0.3-0.1-0.5-0.3-0.7
		C12,8.8,11.8,8.7,11.5,8.7z M11.5,14.6c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v2.6c0,0.4,0.1,0.7,0.2,0.9
		c0.2,0.2,0.4,0.3,0.8,0.3c0.4,0,0.6-0.1,0.8-0.4c0.1-0.2,0.2-0.6,0.2-0.9v-2.6c0-0.3-0.1-0.6-0.3-0.8
		C12.1,14.7,11.8,14.6,11.5,14.6L11.5,14.6z"/>
      </g>
      <path id="path7328" className="st1" d="M55,27.6C55,27.6,55,27.6,55,27.6l-6.2,1.6c-0.2,0-0.3,0.4-0.1,0.5l1.3,1.3l-12.9,13L31.9,39
	c-0.5-0.5-1.3-0.5-1.7,0L19.7,49.4l1.7,1.7l9.6-9.6l5.2,5.2c0.5,0.5,1.3,0.5,1.7,0l13.9-13.9l1.4,1.4c0,0,0.1,0.1,0.2,0.1
	c0.1,0,0.3-0.1,0.3-0.2l1.6-6.1C55.4,27.8,55.3,27.6,55,27.6L55,27.6z M51.8,36.4l-8.2,8.2v20c0,0.5,0.4,0.9,0.9,0.9h7
	c0.5,0,0.9-0.4,0.9-0.9V37.1L51.8,36.4z M30.7,45.4l-8.9,8.9v10.4c0,0.5,0.4,0.9,0.9,0.9h7c0.5,0,0.9-0.4,0.9-0.9V45.4z M41.6,46.6
	l-3.7,3.7c-0.5,0.5-1.3,0.5-1.7,0l-3.4-3.4v17.8c0,0.5,0.4,0.9,0.9,0.9h7c0.5,0,0.9-0.4,0.9-0.9V46.6z"/>
      <path id="path7328-8" className="st1" d="M55,27.6C55,27.6,55,27.6,55,27.6l-6.2,1.6c-0.2,0-0.3,0.4-0.1,0.5l1.3,1.3l-12.9,13L31.9,39
	c-0.5-0.5-1.3-0.5-1.7,0L19.7,49.4l1.7,1.7l9.6-9.6l5.2,5.2c0.5,0.5,1.3,0.5,1.7,0l13.9-13.9l1.4,1.4c0,0,0.1,0.1,0.2,0.1
	c0.1,0,0.3-0.1,0.3-0.2l1.6-6.1C55.4,27.8,55.3,27.6,55,27.6L55,27.6z"/>
    </svg>
  );
};

export default DecentWorkEconomicGrowthIcon;
