import React, { SetStateAction, useEffect } from 'react';

type ScrollToErrorProps = {
  hasSubmit: boolean;
  isValid: boolean;
  setDisplaySpinner?: React.Dispatch<SetStateAction<boolean>>;
}

// This component is used to scroll to the error message element when a form is submitted and there is an error message at one of the fields.
// It is used inside of forms.
// It scrolls to the first occassion of an element with class `form-field-error`.
// How to use: https://gitlab.com/greensteps/ark/-/wikis/home#scroll-into-error

const ScrollToError = ({ hasSubmit, isValid, setDisplaySpinner }: ScrollToErrorProps): React.ReactElement => {

  useEffect(() => {
    if (hasSubmit && !isValid) {
      setDisplaySpinner && setDisplaySpinner(false);
      const errorEl = document.querySelector('.form-field-error') || document.querySelector('.invalid-feedback');
      const errorInputEl = errorEl?.parentElement?.querySelector('input') || errorEl?.parentElement?.querySelector('textarea');
      errorInputEl?.parentElement.className!=='select__input' && errorInputEl?.select(); // select the input field, if the error message is related to an input field, and if it is not a react-select. select() the input element in react-select will break the component.
      (errorEl as HTMLElement)?.focus();  // it is said  'focus should be set to this error message to allow screen reader and keyboard users to immediately access the error message'
      (errorEl?.parentElement ?? errorEl as HTMLElement)?.scrollIntoView({ block: 'center', inline: 'nearest' }); // scroll to the error message element's parent element, of the error message element itself (if it has no parent element, it is the error message element)
      // scrollIntoView({ behavior: 'smooth'}) doesn't work in some cases
    }
  }, [hasSubmit, isValid, setDisplaySpinner]);

  return null;

};

export default ScrollToError;
