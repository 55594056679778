import { isMobile } from 'react-device-detect';

const SESS_KEY = 'auth_payload';
export const isProd = import.meta.env.PROD;
export const isDev = import.meta.env.DEV;

// Login
export const setLoginPayload = (payload) => {
  window.localStorage.setItem(SESS_KEY, payload ?? '');
};

export function getAuthorizeURI(service, redirectURL) {
  service = service === 'wechat' && isWechat ? 'wechatOA' : service;
  return `${import.meta.env.VITE_BACKEND_URL}/auth/${service}?next=${encodeURIComponent(redirectURL)}`;
}

// Client Detection
const wechatIndex = window.navigator.userAgent.indexOf('MicroMessenger');
export const isWechat = wechatIndex !== -1;
export const wechatVersion = parseInt(window.navigator.userAgent.charAt(wechatIndex + 15));

// Payment
export const tradeType = isMobile ? (isWechat ? 'JSAPI' : 'MWEB') : 'NATIVE';
export const JSAPICall = (data, apiCall) => {
  if (typeof WeixinJSBridge == 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', apiCall, false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', apiCall);
      document.attachEvent('onWeixinJSBridgeReady', apiCall);
    }
  } else {
    if (data.package) {
      apiCall(data);
    }
  }
};
