/*                                                                             */
/* This component renders a customized version of bootstrap Spinner            */
/* can render border spinner(rotating circle) or grow spinner (grow dot)       */
/* can customize the number of spinner rendered                                */
/*                                                                             */
import React from 'react';
import { Spinner } from 'react-bootstrap';

type CustomSpinnerProps = {
  numberOfSpinners?: number;
  iconStyle?: 'border' | 'grow';
  classNameForIndividualSpinner?: string;
  spinnerSize?: 'default' | 'small';
  className?: string;
}
/**
 *
 * @param numberOfSpinners number of Spinners return by the component, default is 3
 * @param iconStyle 'border' | 'grow' , border is rotating circle, grow is grow dot
 * @returns customized bootstrap Spinner
 */
const CustomSpinner = ({ numberOfSpinners = 3, iconStyle = 'grow', classNameForIndividualSpinner, spinnerSize = 'small', className }: CustomSpinnerProps): React.ReactElement => {

  const spinners = (): JSX.Element[] => {
    const spinners = [];
    for (let i = 0; i < numberOfSpinners; i++) {
      spinners.push(<Spinner
        as="span"
        animation={iconStyle}
        size={spinnerSize === 'small' ? "sm" : undefined}
        role="status"
        aria-hidden="true"
        key={i}
        className={classNameForIndividualSpinner}
      />);
    }
    return spinners;
  };

  return (
    <div className={`d-flex ${className ? `${className}` : ''}`}>
      {spinners()}
    </div>
  );
};

export default CustomSpinner;
