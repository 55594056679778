import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

 

const Poplar = ({ style = { color: '#60B6AA' }, size = 45, className, gradient }: IconProps): React.ReactElement => {

	return (
		<svg version="1.1" id="poplar" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className} style={style}>

			<defs>
				<linearGradient id="poplarGradient" x1='0' x2='0' y1='0' y2='100%'>
					{gradient && gradient.map((stopElement, index) => {
						return <stop key={index}
							offset={stopElement.offsetValue}
							stopColor={stopElement.stopColor || '#60B6AA'}
							stopOpacity={stopElement.stopOpacity} />;
					})}
				</linearGradient>
			</defs>

			<style type="text/css">
				{/* programmatically add -showProgress to id if gradient is passed so that the gradient can be applied even if there are more than one Poplar icon on the page */}
				{`
             .circle-poplar${gradient && '-showProgress'}{fill:#FFFFFF;stroke:${gradient ? 'url("#poplarGradient")' : style.color};stroke-miterlimit:10;}
             .path-poplar${gradient && '-showProgress'}{fill:${style.color};}
        `}
			</style>
			<circle className={`circle-poplar${gradient && '-showProgress'}`} cx="16.3" cy="16.3" r="15.7" />
			<path className={`path-poplar${gradient && '-showProgress'}`} d="M13.5,27.1c0-0.1-0.1-0.2-0.2-0.2c-0.4-0.1-0.8-0.3-1.1-0.5c-0.2-0.2-0.5-0.4-0.7-0.5c-0.1,0-0.1-0.1-0.2-0.1
			c0.1-0.2-0.1-0.4-0.7-1c0.1-0.3-0.2-0.6-0.6-1.1c0-0.1,0.1-0.2,0-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.4-0.3-0.6
			c0-0.3-0.1-0.7-0.3-1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.3-0.2-0.6-0.1-0.9c0,0,0-0.2,0-0.2
			L8.7,19c-0.1-0.4-0.2-0.8-0.2-1.2c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.2c0-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.1-0.3,0-0.5
			c0-0.2,0-0.3,0-0.5c-0.1-0.3,0-0.6,0-0.9l0-0.2c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.5-0.1-0.9-0.3-1.3
			C7.8,11,7.4,10,7.1,9.1C7,9,7,8.9,7,8.8C7,8.8,7.3,9,7.5,9c0.3,0.1,0.7,0.3,1,0.4c0.5,0.2,1,0.3,1.5,0.4c0.1,0,0.1,0,0.2,0
			c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.2,0l0.1,0c0.2,0,0.4,0,0.5-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0
			c0.2,0,0.3,0,0.5-0.1c0.5-0.2,0.9-0.3,1.3-0.3c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.2,0l0.2,0
			c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.2c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.5,0,0.9,0,1.4-0.2c0.5,0.2,0.7,0.3,0.9,0.3
			c0.1,0,0.2,0,0.3-0.1l0.2,0.1c0.3,0.1,0.7,0.2,1,0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0,0,0.1,0,0.2,0c0,0,0.1,0.1,0.2,0.1
			c0.2,0.1,0.5,0.2,0.8,0.2c0.2,0,0.5,0.1,0.7,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0.1
			c0,0,0.1,0.1,0.1,0.1l0.3,0.2c0.2,0.1,0.4,0.3,0.7,0.4c0,0,0,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2
			c0.1,0.2,0.3,0.4,0.6,0.5l0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0,0.1c0.1,0.3,0.1,0.5,0.2,0.8c0,0.2-0.1,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.5,0,0.8c0,0,0,0,0,0c0,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.3,0.8-0.6,1.1c-0.2,0.2-0.4,0.4-0.5,0.7
			c-0.1,0.3-0.4,0.5-0.6,0.8c-0.2,0.2-0.4,0.4-0.5,0.7c-0.2,0.3-0.3,0.6-0.4,0.9c-0.1,0.2-0.2,0.4-0.3,0.6c0,0-0.1,0.1-0.1,0.1
			c0,0-0.1,0.1-0.1,0.1l-0.4,0.5c-0.2,0.3-0.5,0.6-0.7,0.9c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2
			c0.1-0.3,0.2-0.5,0.2-0.8c0.8-2,1-3.7,0.8-5.4c0-0.3-0.1-0.5-0.1-0.8c0-0.2-0.1-0.3-0.1-0.5l-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.2,0,0.2c0,0.3,0.1,0.5,0.1,0.8c0.1,0.5,0.2,1.1,0.2,1.7c0,1.5-0.4,2.9-0.9,4.2
			c-0.1,0.3-0.2,0.7-0.4,1l-0.6-0.6c-1-0.9-2-1.8-3-2.7c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1l0.1-0.8c0.1-0.6,0.2-1.2,0.2-1.8
			c0.1-0.9-0.1-2-0.3-2.4c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1l0,0c0,0,0,0.1,0,0.1c0.2,1,0.3,2.1,0.1,3.4
			c0,0.3-0.1,0.6-0.1,1l0,0.4l0,0.1L17,16.6c-1.2-1-2.5-2-3.7-3c0,0-0.1-0.1-0.1-0.2c0-0.7-0.1-1.4-0.5-2.1c0,0-0.1-0.1-0.1-0.1
			c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.2c0,0.2,0.1,0.3,0.1,0.5c0.1,0.2,0.1,0.4,0.1,0.5c0,0.2,0.1,0.5,0.1,0.7l0,0.1l-3.7-2.8
			c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0,0c0,0.1-0.1,0.2,0,0.2l1.2,0.9c1,0.7,1.9,1.5,2.9,2.2c-0.2,0-0.4,0.1-0.7,0.1
			c-0.5,0.1-1,0.2-1.4,0.2c-0.3,0-0.6,0-0.8-0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0.1,0.2c0.4,0.1,0.7,0.2,1.1,0.2
			c0.7,0,1.4-0.2,2-0.4c0,0,0.1,0,0.1-0.1c2.8,2.2,5.6,4.5,8.2,7l-5.3,2.7C16,23.9,16,24,16,24.1c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0
			c0.1,0,0.2,0,0.2-0.1l0.5-0.2c0.6-0.3,1.3-0.6,1.9-0.9c0.4-0.2,0.9-0.4,1.3-0.7c0.4-0.2,0.9-0.4,1.3-0.6c0,0,0,0,0,0L20,22.9
			l-0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0.1
			c-0.3,0.3-0.7,0.7-1,1l-0.1,0.1c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0-0.5,0
			c-0.4,0-0.7,0.1-1,0.1L13.5,27.1z M18.9,19.8C18.9,19.8,18.9,19.8,18.9,19.8c-1.8,0.9-3.6,1.7-5.6,2c-0.1,0-0.1,0.1-0.1,0.2
			c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c1.8-0.2,3.5-0.8,5.6-2c0.1,0,0.1-0.1,0.1-0.2C19,19.9,18.9,19.8,18.9,19.8z M17.5,17.9
			c-0.5,0.1-1,0.3-1.5,0.5c-1,0.3-2,0.7-3,0.8c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c1.4-0.1,2.8-0.6,4.1-1.1
			l0.5-0.2c0.1,0,0.1-0.1,0.1-0.2C17.6,18,17.6,17.9,17.5,17.9C17.5,17.9,17.5,17.9,17.5,17.9z M20.4,13.6c-0.1,0-0.1,0.1-0.1,0.2
			c0.1,1-0.1,2-0.4,3c-0.1,0.6-0.3,1.2-0.3,1.9c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.1-0.1c0.1-0.4,0.2-0.9,0.3-1.3
			c0.3-1.2,0.6-2.4,0.4-3.6C20.5,13.6,20.5,13.6,20.4,13.6C20.4,13.6,20.4,13.6,20.4,13.6z M11.5,16.6c-0.1,0-0.1,0.1-0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.1l0.2,0c1.1,0,2.3-0.2,3.7-0.6c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.1-0.1C15.4,16.1,11.5,16.6,11.5,16.6z
			M15.3,12.4C15.3,12.4,15.3,12.4,15.3,12.4c-0.1,0.1-0.1,0.1-0.1,0.2l0.3,2.7c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1l-0.3-2.7c0,0,0-0.1-0.1-0.1C15.5,12.4,15.5,12.4,15.3,12.4C15.4,12.4,15.3,12.4,15.3,12.4z"
			/>
		</svg>
	);
};
 

export default Poplar;
