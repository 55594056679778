import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Willow = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7"  xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
              .circle-willow{fill:#FFFFFF;stroke:#60B6AA;stroke-miterlimit:10;}
              .path-willow{fill:#60B6AA;} 
        `}
      </style>
      <circle className="circle-willow" cx="16.3" cy="16.3" r="15.7" />
      <path className="path-willow" d="M7,8c0.2,0.1,0.3,0.1,0.3,0.2C7.5,8.8,8,9,8.4,9.1c0.4,0.1,0.8,0.2,1.2,0.3c0.9,0.3,1.8,0.6,2.6,1
	c1,0.5,1.9,1.2,2.8,1.7c0.9,0.5,1.6,1.3,2.2,2c0.6,0.7,1.1,1.4,1.7,2c0.7,0.8,0.9,1.8,1.4,2.6c0.7,1.2,1.5,2.4,2.2,3.6
	c0.2,0.3,0.4,0.7,0.6,1.1c0.2,0.5,0.6,0.9,1.1,1.1c0.2,0.1,0.5,0.2,0.7,0.4c0.1,0.1,0.3,0.3,0.3,0.5c-0.1,0.5-0.3,0.6-0.7,0.2
	c-0.4-0.4-0.9-0.6-1.5-0.5c-0.6,0.1-1.1-0.3-1.6-0.6c-0.5-0.3-0.9-0.6-1.3-0.9c-0.1-0.1-0.3-0.2-0.4-0.3c-1.4-0.5-2.4-1.6-3.4-2.6
	c-0.5-0.5-1-0.9-1.4-1.4c-0.2-0.2-0.4-0.5-0.6-0.8c-0.2-0.3-0.5-0.6-0.8-0.9c-0.3-0.3-0.5-0.7-0.7-1.1c-0.1-0.2-0.2-0.3-0.3-0.5
	c-0.3-0.5-0.7-1-1.1-1.5c-0.2-0.3-0.6-0.6-0.8-1c-0.5-1.2-1.5-2-2.3-3c-0.4-0.5-0.8-0.9-1.1-1.4C7.2,9.1,7.1,8.8,7,8.4
	C6.9,8.4,7,8.2,7,8z M19,19.9c0,0,0.1,0,0.1,0c0.1-0.6,0.2-1.1,0.3-1.7c0.1-0.5-0.1-0.9-0.5-1.3c-0.1,0.8-0.2,1.5-0.2,2.3
	C18.7,19.4,18.9,19.6,19,19.9z M18.1,19.5C18.1,19.4,18.1,19.4,18.1,19.5c-0.1-0.2-0.2-0.4-0.4-0.4c-0.8,0-1.6,0.1-2.4,0.1
	c0,0.1,0,0.1,0,0.2c0.3,0.1,0.6,0.4,0.9,0.4C16.8,19.7,17.4,19.6,18.1,19.5z M13.2,16.4C13.2,16.5,13.2,16.5,13.2,16.4
	c0.1,0.3,0.2,0.5,0.3,0.7c0-0.1,0-0.1,0-0.2c0.7,0.1,1.5,0.1,2.2,0.2c0-0.1,0-0.1,0-0.2c-0.2-0.1-0.5-0.2-0.7-0.3
	C14.5,16.6,13.9,16.5,13.2,16.4z M16.7,18C16.7,17.9,16.7,17.9,16.7,18c-0.1-0.2-0.3-0.4-0.5-0.4c-0.6-0.1-1.3-0.1-1.9-0.1
	c-0.1,0-0.3,0-0.4,0.1c0.2,0.4,0.5,0.5,0.9,0.4C15.4,17.9,16,18,16.7,18z M17.3,18.6C17.4,18.6,17.4,18.6,17.3,18.6
	c-0.9-0.4-1.8-0.3-2.7-0.2c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.2,0.1,0.2,0.1C15.8,18.8,16.6,18.7,17.3,18.6z M17.5,18.3
	c0,0,0.1,0,0.1,0c0.1-0.8,0.2-1.7,0.3-2.5c0-0.2-0.1-0.3-0.2-0.5c0,0-0.1,0-0.1,0c-0.1,0.8-0.2,1.5-0.3,2.3
	C17.3,17.8,17.5,18,17.5,18.3z M15.2,12.8C15.2,12.8,15.2,12.8,15.2,12.8c-0.2,0.8-0.2,1.6-0.3,2.4c0,0.2,0.1,0.4,0.1,0.6
	c0,0,0.1,0,0.1,0c0.3-0.8,0.3-1.7,0.3-2.6C15.4,13,15.3,12.9,15.2,12.8z M14.9,16.2c-0.4-0.6-1.6-0.8-2.3-0.5
	c0.3,0.4,0.3,0.4,0.8,0.4C13.9,16.1,14.4,16.2,14.9,16.2z M18.7,20.2c0-0.1,0-0.1,0-0.2c-0.8-0.3-1.5,0.2-2.3,0.2
	c0.1,0.1,0.3,0.3,0.5,0.3C17.4,20.5,18,20.3,18.7,20.2z M17,20.9c0.2,0.4,0.4,0.4,0.7,0.4c0.5-0.1,1-0.1,1.5-0.5
	C18.4,20.5,17.7,21,17,20.9z M16.8,17.5c0,0,0.1,0,0.1,0c0.1-0.8,0.2-1.6,0.3-2.5c0-0.3-0.1-0.4-0.3-0.5c-0.1,0.9-0.1,1.7-0.2,2.5
	C16.7,17.2,16.8,17.3,16.8,17.5z M13.1,14.1c0.3-0.8,0.5-1.6,0.3-2.4c0-0.1-0.2-0.2-0.3-0.4C13.1,12.2,13,13,13,13.8
	C13,13.9,13.1,14,13.1,14.1z M13.8,14.6c0,0,0.1,0,0.1,0c0.2-0.8,0.3-1.6,0.3-2.4c0-0.1-0.1-0.2-0.2-0.4c0,0.6-0.1,1-0.1,1.4
	c0,0.3-0.1,0.6-0.1,1C13.7,14.3,13.7,14.5,13.8,14.6z M18.2,18.9c0.1-0.8,0.2-1.6,0.3-2.4c0-0.1-0.1-0.3-0.1-0.5c0,0-0.1,0-0.1,0
	C18.2,17,18,17.9,18.2,18.9z M15.6,16.3c0.1,0,0.1,0,0.2,0c0.1-0.9,0.2-1.8,0.3-2.7c0-0.1-0.1-0.2-0.2-0.3c-0.1,0.8-0.2,1.6-0.3,2.4
	C15.5,15.9,15.6,16.1,15.6,16.3z M19.7,20.6c0.1-0.5,0.2-0.9,0.3-1.3c0.1-0.4,0-0.7-0.2-1C19.5,19.1,19.4,19.8,19.7,20.6z M12.5,11
	c-0.1,0.6-0.1,1.2-0.1,1.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0,0.1,0,0.2,0c0-0.7,0-1.4-0.1-2.1C12.7,11.1,12.5,11.1,12.5,11z M14.6,12.4
	c-0.1,0.8-0.2,1.6-0.3,2.3c0,0.2,0.1,0.3,0.1,0.5c0,0,0.1,0,0.1,0C14.6,14.2,14.8,13.3,14.6,12.4z M16.5,14C16.5,14,16.5,14,16.5,14
	c-0.2,0.8-0.3,1.7-0.4,2.5c0,0.1,0.1,0.2,0.1,0.3c0,0,0.1,0,0.1,0C16.4,15.9,16.5,15,16.5,14z M20.8,22.6c-0.3-0.3-1-0.2-1.8,0.2
	C19.7,22.9,20.2,22.8,20.8,22.6z M11.2,13.8c0.3,0.6,0.8,0.3,1.2,0.3C12.2,13.6,12.1,13.5,11.2,13.8z M11.9,10.7c-0.1,0-0.1,0-0.2,0
	c0.2,0.6-0.2,1.2,0.2,1.7C12.2,11.9,12,11.3,11.9,10.7z M20.3,21.9c-0.1,0-0.2-0.1-0.3-0.1c-0.5,0.1-1,0.3-1.5,0.4
	C18.6,22.4,18.6,22.4,20.3,21.9z M17.8,21.8c0.2,0.1,1.3-0.1,2-0.4c-0.2-0.1-0.4-0.2-0.5-0.1C18.8,21.4,18.3,21.6,17.8,21.8z
	 M11.8,13.2c-0.2-0.4-0.3-0.4-1-0.2c0.1,0.1,0.1,0.2,0.2,0.3C11.3,13.3,11.6,13.2,11.8,13.2z M21.3,23.2c-0.4-0.3-0.8,0.1-1.2,0.1
	C20.5,23.5,20.9,23.2,21.3,23.2z M12.2,15c0.1,0.3,0.3,0.3,1.3,0.1C13.1,14.8,12.7,15,12.2,15z M20.6,21.7c0-0.4,0.3-0.9,0.2-1.3
	C20.6,20.8,20.4,21.2,20.6,21.7z M22.5,24.3c0,0,0-0.1,0-0.1c-0.3,0-0.6,0-1,0C22,24.6,22,24.6,22.5,24.3z M21.9,23.7
	c-0.4-0.2-0.7,0-1.1,0C21.2,24.1,21.6,23.7,21.9,23.7z M20,20.8c0.4-0.7,0.5-1,0.2-1.3C20.2,20,20.1,20.4,20,20.8z M11.2,10.4
	c0,0.5,0,1,0,1.5c0,0,0.1,0,0.1,0C11.4,11.4,11.4,10.9,11.2,10.4z M11.9,14.5c0,0.1,0,0.1,0,0.2c0.4,0,0.8-0.1,1.3-0.1
	c0,0,0-0.1,0-0.1C12.7,14.4,12.3,14.5,11.9,14.5z M10.3,12.4c0.3,0.4,0.6,0.1,0.9,0.1C11,12.2,10.7,12.4,10.3,12.4z M21.3,21.1
	c-0.1,0-0.1,0-0.2,0c0,0.3-0.1,0.6-0.1,0.9c0,0,0.1,0,0.1,0C21.2,21.8,21.3,21.4,21.3,21.1z M9.9,11.8c0,0,0,0.1,0,0.1
	c0.3,0,0.5,0,0.8,0.1C10.5,11.6,10.2,11.7,9.9,11.8z M21.8,21.9c0,0-0.1,0-0.1,0c0,0.3-0.1,0.6-0.1,0.9c0,0,0.1,0,0.1,0
	C21.7,22.5,21.7,22.2,21.8,21.9z"/>
    </svg>
  );
};

export default Willow;
