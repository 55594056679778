import IntlMessages from 'helpers/IntlMessages';
import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const history = useHistory();
  const intl = useIntl();
  const [showErrorModal, setShowErrorModal] = useState(true);
  const handleClose = () => {
    setShowErrorModal(false);
    resetErrorBoundary && resetErrorBoundary();
    error.reload && history.go(0);
  };
  const redirectId =
    error.redirect && !intl.formatMessage({ id: `redirect.${error.redirect}` }).includes('.') ? `redirect.${error.redirect}` : 'redirect.back';
  const handleRedirect = () => {
    const redirectUrl = `/${error.redirect}`;
    error.reload ? (window.location.href = redirectUrl) : history.push(redirectUrl);
  };

  return (
    <Modal show={showErrorModal} onHide={handleClose}>
      <Alert style={{ margin: 0 }} variant="danger">
        <Alert.Heading>
          <IntlMessages id="error.error-title" />
        </Alert.Heading>
        <div style={{ 'textAlign': 'justify', whiteSpace: 'pre-wrap' }}>
          {error.messageId ? <IntlMessages id={`error.${error.messageId}`} /> : error.message}
        </div>
        <hr />
        <div className="d-flex justify-content-end">
          {error.redirect && (
            <Button className="mr-2" onClick={handleRedirect}>
              <IntlMessages id={redirectId} />
            </Button>
          )}
          &nbsp;
          <Button className="mr-2" variant="outline-danger" onClick={handleClose}>
            <IntlMessages id="general.close" />
          </Button>
        </div>
      </Alert>
    </Modal>
  );
}
