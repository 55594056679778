import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Option } from '../components/custom/CustomReactSelect';
import { getTranslatedString, getUiTranslation } from '../helpers/utils-typescript';
import { Speciman, Scalars } from 'services/graphqltypes';
import { useSelector } from 'react-redux';


const LOAD_SPECIMENS = gql`
query specimen($territoryId: UUID) {
  specimen(filter: {
    territoryId: {equalTo: $territoryId}
    status: {equalTo: APPROVED_PUBLISHED}
  }) {
    nodes {
      __typename
      id
      name
      code
      type
      osmTags
      species {
        scientificName
      }
    }
  }
}
`;

type useSpecimenOptionsProps = {
  territoryId: Scalars['UUID']
}

/* this hook returns a list of specimen options that belongs to the same bioregion.*/
const useSpecimenOptions = ({ territoryId }: useSpecimenOptionsProps) => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const { data: specimenData, loading: specimenLoading, error: specimenError } = useQuery(LOAD_SPECIMENS, {
    variables: {
      territoryId
    }, skip: !territoryId
  });

  /* Options for Specimen */
  const specimenOptions = useMemo(() => {
    const option: Option = { value: '', label: getUiTranslation('general.none', currentLang), tag: '' };
    if (specimenLoading) {
      option.label = 'Loading...';
      return [{ ...option }];
    }
    if (specimenError) {
      option.label = 'Loading failed. Check your connection and reload~';
      return [{ ...option }];
    }

    return specimenData?.specimen?.nodes.map((specimen: Speciman) => {
      const tagContent = specimen.type === 'POI' ? specimen.osmTags : specimen.species?.scientificName;
      return {
        label: getTranslatedString(specimen.name, currentLang),
        value: specimen.id,
        tag: `${specimen.code}\u00A0 | \u00A0${tagContent}`,
      } as Option;
    });

  }, [currentLang, specimenData, specimenError, specimenLoading]);

  return { specimenData, specimenLoading, specimenError, specimenOptions };

};

export default useSpecimenOptions;
