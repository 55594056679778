import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const FriendlyWolf = ({ style, size = 45, className }: IconProps): React.ReactElement=>{
  return (
    <svg version="1.1" id="friendly_wolf" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" height={size} className={className}  style={style}>
      <style type="text/css">
        {`
            .circle-friendly-wolf{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-friendly-wolf{fill:#FBBF2D;}
        `}
      </style>
      <circle className="circle-friendly-wolf" cx="16.3" cy="16.3" r="15.7"/>
      <g>
        <path className="path-friendly-wolf" d="M25.7,23.2c0.3-0.9,0.5-1.9,0.1-2.8c-0.2-0.6-0.4-1.1-0.3-1.7c0.1-0.5,0.2-1,0.2-1.4c0-1.1-0.1-2.2-0.2-3.3
		c-0.1-0.5-0.3-1-0.5-1.5c0-0.1-0.1-0.2-0.2-0.2c-0.7-0.3-1.4-0.7-2.1-1c-1-0.3-1.9-0.6-2.9-0.9c-0.8-0.2-1.5-0.4-2.3-0.8
		c-0.3-0.2-0.7-0.2-1.2-0.3c-0.1-0.1-0.3-0.3-0.6-0.5c0.3-0.4,0.3-0.4-0.2-0.7c-0.4-0.2-0.7-0.6-1.1-0.9c-0.3-0.2-0.6-0.4-0.9-0.7
		c-0.2-0.2-0.4-0.5-0.6-0.8c-0.2-0.2-0.2-0.7-0.6-0.5C12.3,5.3,12.1,5.7,12,6c-0.1,0.1,0,0.3,0,0.4c-0.3,0-0.5,0-0.7,0
		c0.1,0.2,0.2,0.5,0.3,0.7C11.7,7.4,11.9,7.7,12,8c0.1,0.1,0,0.3,0,0.5c0,0.3-0.1,0.6-0.1,0.8c0.1,0.5,0.2,1,0.3,1.5
		c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.4,0.2,0.9,0.5,1.3c0.4,0.7,0.7,1.4,1.3,1.9c0.1,0.1,0.2,0.2,0.2,0.3c0.5,1.5,0.8,3,0.4,4.5
		c-0.1,0.4-0.8,0.8-1.2,0.6c-0.1,0-0.2-0.1-0.3-0.1c-0.7-0.3-0.7-0.3-1.3,0.1c-0.2,0.2-0.4,0.3-0.5,0.5c-0.3,0.3-0.7,0.5-1.1,0.5
		c-0.4,0-0.6,0.1-0.7,0.5c-0.2,0.5-0.6,0.9-1.2,1.1C7.8,23,6.9,23.4,6,23.8c0,0,0,0.1,0,0.1c6.9,0,13.8,0,20.9,0
		C26.4,23.6,26,23.4,25.7,23.2z M21.7,21.2c-0.3,0.4-1.1,0.5-1.5,0.4c-0.4-0.2-0.8-0.3-1.2-0.4c-0.8-0.1-1.5-0.1-2.3-0.1
		c-0.3,0-0.7-0.1-1-0.1c0.6-1.8,0.7-3.7,1-5.5c1.1,0.1,2.2,0.1,3.3,0.2c0,0.7,0.3,1.3,0.7,1.9c0.2,0.3,0.4,0.5,0.6,0.8
		C22,19.4,22.5,20.1,21.7,21.2z M24.8,22.4c-0.3,0.2-0.5,0.3-0.7,0.4c-0.6-0.2-1.2-0.4-1.8-0.7c0.3-0.4,0.6-0.8,0.9-1.2
		c0.4-0.5,0.4-1.2,0.1-1.8c-0.2-0.4-0.4-0.9-0.6-1.3c0.1,0,0.1-0.1,0.2-0.1c0.4,0.5,0.8,1,1.1,1.5c0.1,0.1,0.1,0.4,0.1,0.6
		c0.1,0.4,0.2,0.9,0.3,1.4c0.2-0.2,0.3-0.3,0.4-0.5C24.8,21.4,24.8,21.9,24.8,22.4z"/>
      </g>
    </svg>

  );
};

export default FriendlyWolf;
