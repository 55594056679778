/*                                                                           */
/* Renders a modal that allows to search through all species on the ARK,     */
/* select one, and head to the Create Specimen form with this species        */
/* preselected. "Add Species to Ecoregion" modal can be opened from here,    */
/* if the species user is searching for is not added yet.                    */
/*                                                                           */

import { AddSpeciesModal } from 'components/BioRegion/AddSpeciesModal';
import CustomSpinner from 'components/common/CustomSpinner';
import CustomReactSelect, { Option } from 'components/custom/CustomReactSelect';
import { Field, Formik } from 'formik';
import IntlMessages from 'helpers/IntlMessages';
import { getUiTranslation } from 'helpers/utils-typescript';
import useSpeciesesOptions from 'hooks/useSpeciesesOptions';
import EditIcon from 'mdi-react/EditIcon';
import SelectPoi, { getOsmTagIconAndName, OsmTagType } from 'pages/Mapping/SelectPoi';
import React, { SetStateAction, useCallback, useState } from 'react';
import { Form, FormGroup, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Species, SpecimenType } from 'services/graphqltypes';
import * as Yup from 'yup';

type CustomFormikValues = {
  species: Species,
  type: SpecimenType,
  osmTags: OsmTagType,
}


/** Renders a modal that allows to search through all species on the ARK and continue to the Create Specimen form. */
// add a prop key={showCreateSpecimenModal ? 'modal-open' : 'modal-closed'} when using the modal, will force Modal remount when its visibility changes, all the state inside and child component will rerender
export const AddSpecimenModal = ({
  showAddSpecimenModal,
  setShowAddSpecimenModal,
  currentLang,
  territoryId,
  learningSpaceName,
  ecoregionGid,
  ecoregionName,
}: {
  showAddSpecimenModal: boolean,
  setShowAddSpecimenModal: React.Dispatch<SetStateAction<boolean>>
  currentLang: string;
  /** territoryId of the commons the specimen will be created in, required for using this modal to add specimen in commons */
  territoryId?: string;
  /** Name of the commons is shown in the title of the modal */
  learningSpaceName: string | React.ReactElement;
  /** ecoregionGid of the ecoregion whose species will be available in the dropdown */
  ecoregionGid: number;
  /** Name of the ecoregion is passed to the Add Species modal, which is opened in case user can't find species she's looking for */
  ecoregionName: string | React.ReactElement;
}): React.ReactElement => {
  const intl = useIntl();
  const history = useHistory();

  /* */
  /* Options for Select species */
  /* */
  const { specieses, speciesesOptions, refetch } = useSpeciesesOptions(ecoregionGid, 'TREE', !showAddSpecimenModal); // load species from this ecoregion, trees only

  /* */
  /* States */
  /* */
  const [showCreateSpeciesModal, setShowCreateSpeciesModal] = useState(false); // if user clicks the Create new button in the dropdown when searching species, the Add Species modal shows
  const [showPoiCategories, setShowPoiCategories] = useState(true);

  /* */
  /* Formik */
  /* */

  /* Formik validation schema */
  const validationSchema = Yup.object().shape(
    {
      species: Yup.object().nullable().when('osmTags', {
        is: 'natural=tree',
        then: Yup.object().shape({
          id: Yup.string().required(getUiTranslation('general.required', currentLang)),
        }),
        otherwise: Yup.object().nullable(),
      }),
      type: Yup.string(),
      osmTags: Yup.string().required(getUiTranslation('general.required', currentLang)),
    }
  );

  /* Formik submit function*/
  const handleSubmitForm = useCallback((values: CustomFormikValues) => {
    history.push({
      pathname: '/commons/specimen/new',
      state: {
        species: values.osmTags === 'natural=tree' && values.species, // re-set species to null on change of osmTags to non-tree from tree
        type: values.type,
        osmTags: values.osmTags,
        territoryId: territoryId,
        ecoregionGid: ecoregionGid,
        from: history.location
      }
    });
  }, [ecoregionGid, history, territoryId]);


  /* */
  /* Render */
  /* */
  return (
    <>
      <Modal
        show={showAddSpecimenModal}
        onHide={() => setShowAddSpecimenModal(false)}
        className="rounded-modal"
        backdrop="static" /* prevent closing modal when clicked outside it*/
      >
        <Modal.Header closeButton className="mr-3 mt-3 p-0" />
        <Modal.Body className='pb-3'>
          <h3 className="font-weight-bolder color-theme-1">
            {intl.formatMessage({
              id: 'specimen.new-poi-heading'
            }, {
              learningSpaceName: learningSpaceName
            })}
          </h3>
        </Modal.Body>
        <Modal.Body>
          <Formik
            initialValues={{
              species: undefined,
              type: undefined,
              osmTags: undefined,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {({ errors, handleSubmit, setFieldValue, values, isSubmitting, touched }) => (
              <Form onSubmit={handleSubmit}>
                {/* Category */}
                <FormGroup>
                  <h5>
                    {intl.formatMessage({ id: 'specimen.choose-category' })}*
                  </h5>
                  {touched.osmTags && errors.osmTags &&
                    <div className='invalid-feedback d-flex my-1'>{errors.osmTags}</div>
                  }
                  {showPoiCategories ?
                    <SelectPoi triggerValidateOnChange setShowPoiCategories={setShowPoiCategories} className='mx-n3 mx-md-0' />
                    :
                    <div className='clickable d-flex h5' onClick={() => { setShowPoiCategories(true) }} >
                      {getOsmTagIconAndName(values.osmTags, undefined, 'color-theme-1 ml-2').iconName}
                      <EditIcon
                        size={20}
                        className="clickable accent-color ml-3"
                      />
                    </div>
                  }
                </FormGroup>

                {/* Species dropdown */}
                {values.osmTags === 'natural=tree' &&
                  <FormGroup>
                    <h5>
                      {intl.formatMessage({ id: 'specimen.choose-species' })}{values.type !== 'POI' && '*' /* required for trees */}
                    </h5>

                    <Field
                      name="species"
                      component={CustomReactSelect}
                      options={speciesesOptions}
                      isMulti={false}
                      isClearable={true}
                      hasIcon={true}
                      placeholder={getUiTranslation('general.select-or-type-to-search', currentLang)}
                      handleChange={(option: Option) => { setFieldValue('species', specieses?.find((species: Species) => species.id === option?.value)); }}
                    />

                    {touched.species && errors.species &&
                      <div className='invalid-feedback d-block'>{errors.species.id}</div>
                    }
                  </FormGroup>
                }

                {/* Buttons */}
                <div className='mt-5 d-flex justify-content-around'>

                  {/* Cancel */}
                  <button
                    className=" mx-auto btn btn-lg btn-outline-primary mb-3"
                    type='reset'
                    onClick={() => {
                      setShowAddSpecimenModal(false);
                    }}
                  >
                    <IntlMessages id={'general.cancel'} />
                  </button>

                  {/* Continue */}
                  <button
                    className=" mx-auto btn btn-lg btn-primary mb-3"
                    disabled={isSubmitting}
                    type='submit'
                  >
                    {isSubmitting ?
                      <CustomSpinner />
                      :
                      <IntlMessages id={'general.continue'} />
                    }
                  </button>
                </div>
              </Form>)}
          </Formik>


        </Modal.Body>
      </Modal >

      {/* Add Species modal: opened in case user can't find species she's looking for */}
      <AddSpeciesModal
        showAddSpeciesModal={showCreateSpeciesModal}
        setShowAddSpeciesModal={setShowCreateSpeciesModal}
        currentLang={currentLang}
        ecoregionGid={ecoregionGid}
        ecoregionName={ecoregionName}
        refetch={refetch}
      />
    </>

  );
};
