import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const ReducedInequalitiesIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="reducedInequalities" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .reduced-inequalities{fill - rule:evenodd;clip-rule:evenodd;fill:#DF1F85;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3-6-0" className="reduced-inequalities" d="M13.1,0.7h48.7c6.9,0,12.5,5.6,12.5,12.5V62c0,6.9-5.6,12.5-12.5,12.5H13.1
	C6.2,74.5,0.6,69,0.6,62V13.2C0.6,6.3,6.2,0.7,13.1,0.7z"/>
      <g id="text6065-3-2-9-6-6-5-0-0-8">
        <path id="path2273" className="st1" d="M24.3,13.3V7.1h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8
		h-0.9l-0.8-2.6h-0.5v2.7H24.3L24.3,13.3z M26.3,9.6c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3V8.5c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C26.1,9.8,26.2,9.8,26.3,9.6L26.3,9.6z"/>
        <path id="path2275" className="st1" d="M30.7,12.4v0.9H28V7.1h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.9H30.7z"/>
        <path id="path2277" className="st1" d="M32.5,7.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		c-0.3,0.3-0.6,0.4-1.1,0.4h-1.4V7.1H32.5L32.5,7.1z M33,11.8V8.5c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.6h0.4
		C32.8,12.5,33,12.2,33,11.8L33,11.8z"/>
        <path id="path2279" className="st1" d="M37.5,12.6c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4V7.1h0.9v4.8
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4V7h0.9v4.8
		c0,0.1,0,0.3,0,0.4C37.7,12.4,37.6,12.5,37.5,12.6L37.5,12.6z"/>
        <path id="path2281" className="st1" d="M41.3,11.7c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5V8.5c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		C39.5,7.1,39.7,7,39.9,7c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9V8.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4V12c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2s0.3,0,0.4-0.1
		c0-0.1,0.1-0.2,0.1-0.2s0-0.1,0-0.2v-0.3h0.9L41.3,11.7z"/>
        <path id="path2283" className="st1" d="M44.7,12.4v0.9H42V7.1h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.9H44.7z"/>
        <path id="path2285" className="st1" d="M46.5,7.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1
		s-0.6,0.4-1.1,0.4h-1.4V7.1H46.5L46.5,7.1z M47.1,11.8V8.5c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H46v4.6h0.4
		C46.9,12.5,47.1,12.2,47.1,11.8L47.1,11.8z"/>
        <path id="path2287" className="st1" d="M24.5,21.9v-6.2h0.9v6.2H24.5z"/>
        <path id="path2289" className="st1" d="M28.4,21.9L27,18.1v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8h0.9v6.2H28.4z"/>
        <path id="path2291" className="st1"
          d="M32.4,21v0.9h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5V21H32.4L32.4,21z"/>
        <path id="path2293" className="st1" d="M33.1,21.4c-0.1-0.1-0.2-0.3-0.3-0.5s0-0.4,0-0.6v-3.1c0-0.4,0.1-0.8,0.4-1.1
		c0.3-0.3,0.6-0.4,1.1-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.2,0,0.3,0,0.4c0,0.1,0,0.2,0,0.2l0.6,0.5l-0.5,0.6
		l-0.5-0.4c-0.2,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.6-0.1C33.4,21.7,33.2,21.5,33.1,21.4L33.1,21.4z M34.5,20l0.3,0.2v-3.1
		c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0.1-0.2,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.1v0
		c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.5,0.2c0,0,0.1,0,0.2,0l-0.4-0.3L34.5,20z"/>
        <path id="path2295" className="st1" d="M39.2,21.2c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.8h0.9
		v4.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1s0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4v-4.8h0.9
		v4.8c0,0.1,0,0.3,0,0.4C39.2,21,39.2,21.1,39.2,21.2L39.2,21.2z"/>
        <path id="path2297" className="st1" d="M39.7,21.9l1.4-6.2h0.7l1.4,6.2h-0.9L42,20.5h-1.1l-0.2,1.3H39.7z M41,19.7h0.8l-0.4-2.2
		L41,19.7z"/>
        <path id="path2299" className="st1" d="M46.4,21.9h-2.6v-6.2h0.9V21h1.7V21.9L46.4,21.9z"/>
        <path id="path2301" className="st1" d="M46.6,21.9v-6.2h0.9v6.2H46.6z"/>
        <path id="path2303" className="st1" d="M49,21.9v-5.4h-1v-0.8h3v0.8h-1.1v5.4H49z"/>
        <path id="path2305" className="st1" d="M51.3,21.9v-6.2h0.9v6.2H51.3z"/>
        <path id="path2307" className="st1"
          d="M55.6,21v0.9h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5V21H55.6L55.6,21z"/>
        <path id="path2309" className="st1" d="M56.7,20.3c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2s0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5s-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
		c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
		c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5s-0.1,0.3-0.4,0.6
		c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L56.7,20.3L56.7,20.3z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-0-9-4">
        <path id="path2312" className="st1" d="M7.8,21.8V9.1l-2.1,1.6V8.4l2.1-1.5h2.1v14.9H7.8z"/>
        <path id="path2314" className="st1" d="M15.1,21.8c-1.6-0.3-2.5-1.2-2.7-2.7V9.5c0.2-1.2,0.7-1.9,1.5-2.3c0.5-0.2,1.1-0.4,1.7-0.4
		c0.3,0,0.7,0,1.1,0.1c0.5,0.3,0.9,0.7,1.3,1c0.3,0.3,0.5,0.9,0.6,1.5V19c-0.2,1.4-1,2.4-2.3,2.8h-0.4
		C15.9,21.8,15.6,21.8,15.1,21.8L15.1,21.8z M15.3,19.7c0.6,0,1-0.3,1.2-0.9V9.8c-0.1-0.3-0.2-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3
		c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.2-0.3,0.4-0.4,0.7v8.9C14.6,19.2,14.9,19.5,15.3,19.7z"/>
      </g>
      <path id="rect7477-8-0" className="st2" d="M30.7,40.4v4.7h14v-4.7H30.7L30.7,40.4z M30.7,49.1v4.8h14v-4.8H30.7z M37.7,27.7l-7,7h13.9
	L37.7,27.7z M25.3,40.1l-7,7l7,7V40.1z M50,40.1v13.8L57,47L50,40.1z M30.8,59.5l6.8,6.8l6.8-6.8H30.8z"/>
    </svg>
  );
};

export default ReducedInequalitiesIcon;
