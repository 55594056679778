import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const GirthIcon = ({ style, size = 10, className, color = '#60B6AA', strokeWidth = '2' }: IconProps): React.ReactElement => {
  return (
    <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M7.47486 3.15367C7.96062 3.8176 8.20313 4.62846 8.16166 5.45008C8.12018 6.27169 7.79725 7.05402 7.24709 7.66565C6.69693 8.27729 5.95306 8.68098 5.14041 8.80893C4.32776 8.93689 3.49583 8.78131 2.78434 8.36832C2.07285 7.95533 1.52515 7.3101 1.23321 6.54098C0.941267 5.77186 0.922877 4.92571 1.18113 4.14463C1.43937 3.36355 1.95853 2.69513 2.6514 2.25162C3.34427 1.80811 4.16866 1.61653 4.9861 1.70906" stroke={color} strokeWidth={strokeWidth} />
      <line x1="5.95895" y1="4.64645" x2="8.95895" y2="1.64645" stroke={color} />
      <line x1="4.8125" y1="4" x2="4.8125" y2="-2.18561e-08" stroke={color} />
    </svg>
  );
};

export default GirthIcon;
