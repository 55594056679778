import { gql } from '@apollo/client';
import { getTranslatedString } from 'helpers/utils-typescript';
import { SchoolClass } from 'pages/Community/SchoolClass/SingleClass';
import { apolloClient } from './graphql';
import {
  CommunitiesConnection,
  Community,
  CommunityLearningPath,
  CommunitySdgsConnection,
  CommunityType,
  CommunityUser,
  CommunityUsersConnection,
  EventCourse,
  Scalars,
  Territory,
} from './graphqltypes';
import { MINIMAL_ROUTE_FRAGMENT } from 'pages/Commons/Quest/queries';

export const SCHOOL_CLASSES_LIST_FRAGMENT = gql`
fragment listOfSchoolClassesFragment on Community {
  communitiesByParentCommunityId(filter: {isDisabled: {notEqualTo: true}}) {
    nodes {
      slug
      parentCommunity {
        slug
      }
      totalCredits
      name
      id
      avatarUrl
      mainTeachers: communityUsers(
        filter: {
          communityRoleId: { equalTo: "985c8829-bd53-4cf6-93bc-ff4f7e20f76d" }
        }
      ) {
        nodes {
          id
          status
          user {
            isTerritoryMentor
            id
            name
            username
            avatarUrl
            totalCredits
            totalParticipationCredits
            totalFacilitationCredits
            about
          }
          communityRole {
            slug
            id
            name
          }
        }
      }
      additionalTeachers: communityUsers(
        filter: {communityRoleId: {equalTo: "b6158bc8-0efa-4fc1-8b4e-ad8a094ff4f4"}}
      ) {
          nodes {
            id
            status
            userId
            user {
              isTerritoryMentor
              id
              name
              username
              avatarUrl
              totalCredits
              totalParticipationCredits
              totalFacilitationCredits
              about
            }
            communityRole {
              slug
              id
              name
            }
          }
      }
      students: communityUsers(
        filter: {
          communityRoleId: { equalTo: "6e90756a-9f73-46b4-9890-1ef5f7c248d2" }
        }
      ) {
        nodes {
          id
          status
          user {
            isTerritoryMentor
            id
            name
            username
            avatarUrl
            totalCredits
            totalParticipationCredits
            totalFacilitationCredits
            about
          }
          communityRole {
            slug
            id
            name
          }
        }
      }
      eventCoursesByOrganizerCommunity(filter: {isPublished: {equalTo: true}}) {
        totalCount
      }
    }
  }
}`;

// used in list of communities: only limited info is needed
export const COMMUNITY_OVERVIEW_FRAGMENT = gql`
  fragment communityOverviewFragment on Community {
    id
    name
    slug
    avatarUrl
    aboutOneliner
    totalKgTrashCollected
    type
    locationName
    locationAddress
    locationCoordinates { x, y }
    locationGeojson
    parentCommunity {
      id
      name
      locationCoordinates { x, y }
      locationGeojson
      territory {
        id
        isCurrentUserMentor
      }
    }
    communitySdgs(orderBy: SUSTAINABLE_DEVELOPMENT_GOAL_BY_SDG_ID__NUMBER_ASC) {
      nodes {
        sdg {
          id
          number
          name
          description
        }
      }
    }
  }
`;

// all the info about a community you need
// must be combined with the above "communityOverviewFragment"
export const COMMUNITY_PROFILE_FRAGMENT = gql`
  fragment communityProfileFragment on Community {
    about
    totalCredits
    totalCreditsWithSubcommunities
    bannerImageUrl
    bioregionGid
    commons
    communityUsers {
      totalCount
      nodes {
        status
        id
        user {
          id
          name
          username
          avatarUrl
          totalCredits
          totalParticipationCredits
          totalFacilitationCredits
          about
        }
        communityRole {
          id
          name
          slug
        }
        communityRolePermissions {
          isTeamMember
        }
      }
    }
    communityMentors: communityUsers(
      filter: {
        communityRoleId: { equalTo: "43d163d5-64ad-48bc-92cb-776e2a7c142f" }
      }
    ) {
      nodes {
        id
        user {
          id
          name
          username
          avatarUrl
          totalCredits
          totalParticipationCredits
          totalFacilitationCredits
          about
          isTerritoryMentor
        }
        communityRole {
          slug
          id
          name
        }
      }
    }
    communityFacilitators: communityUsers(
      filter: {
        communityRoleId: { equalTo: "b6158bc8-0efa-4fc1-8b4e-ad8a094ff4f4" }
      }
    ) {
      nodes {
        id
        user {
          id
          name
          username
          avatarUrl
          totalCredits
          totalParticipationCredits
          totalFacilitationCredits
          about
          isTerritoryMentor
        }
        communityRole {
          slug
          id
          name
        }
      }
    }
    currentUserStatus
    currentUserRole
    facilityDescription
    facilityAmenities
    facilityImageUrl
    communityLearningPaths {
      nodes {
        learningPath {
          id
          name
          description
        }
      }
    }
    email
    phone
    website
    isShopEnabled
    territory {
      id
      description
      name
      slug
      status
      isCurrentUserMentor
    }
  }
`;

export const ROUTES_MANAGED_BY_COMMUNITY = gql`
${MINIMAL_ROUTE_FRAGMENT}
query routesManagedByCommunity($communitySlug: String!) {
  publishedRoutes: routes(
    filter: {
      routeManagers: {
        some: {
          community: {
            or: [
              { parentCommunity: { slug: { equalTo: $communitySlug } } },
              { slug: { equalTo: $communitySlug } }
            ]
          }
        }
      }
      status: { equalTo: APPROVED_PUBLISHED }
    }
  ) {
    nodes {
      ...minimalRouteFragment
    }
  }
  draftRoutes: routes(
    filter: {
      routeManagers: {
        some: {
          community: {
            or: [
              { parentCommunity: { slug: { equalTo: $communitySlug } } },
              { slug: { equalTo: $communitySlug } }
            ]
          }
        }
      }
      status: { equalTo: DRAFT }
    }
  ) {
    nodes {
      ...minimalRouteFragment
    }
  }
  draftRoutesWithStops: routes(
    filter: {
      routeManagers: {
        some: {
          community: {
            or: [
              { parentCommunity: { slug: { equalTo: $communitySlug } } },
              { slug: { equalTo: $communitySlug } }
            ]
          }
        }
      }
      status: { equalTo: DRAFT }
      routeStopsExist: true
    }
  ) {
    nodes {
      ...minimalRouteFragment
    }
  }
}
`;

export const LOAD_COMMUNITY_PROFILE = gql`
  ${COMMUNITY_OVERVIEW_FRAGMENT}
  ${COMMUNITY_PROFILE_FRAGMENT}
  ${SCHOOL_CLASSES_LIST_FRAGMENT}
  query LoadCommunityProfile($slug: String!, $todayLocalMidnight: Datetime) {
    communityBySlug(slug: $slug) {
      ...communityOverviewFragment
      ...communityProfileFragment
      eventCoursesByOrganizerCommunity(
        orderBy: START_TIME_ASC
        filter: {
          endTime: { greaterThanOrEqualTo: $todayLocalMidnight }
          isPublished: { equalTo: true }
          isUnlisted: { equalTo: false }
          or: [
            { isCourse: { equalTo: true } }, 
            { isEvent: { equalTo: true } }
          ]
        }
      ) {
        nodes {
          id
          name
          pictureUrl
          startTime
          isCourse
          slug
          eventCourseSpecimen {
            nodes {
              __typename
              id
              position
              specimen {
                __typename
                id
                name
              }
          }
        }
        }
      }
      ...listOfSchoolClassesFragment
    }
  }
`;

const COMMUNITY_MEMBERS_FRAGMENT = gql`
  fragment communityMembersFragment on Community {
    id
    slug
    type
    communityUsers {
      totalCount
      nodes {
        id
        joinedAt
        status
        user {
          avatarUrl
          totalCredits
          totalFacilitationCredits
          totalParticipationCredits
          name
          username
          about
          id
        }
        communityRole {
          name
          id
        }
      }
    }
  }
`;

// loads community members and roles, omitting the Main Class Teacher role, which is only used in Edit School Class modal
export const LOAD_COMMUNITY_MEMBERS_AND_ROLES = gql`
  ${COMMUNITY_MEMBERS_FRAGMENT}
  query LoadCommunityMembersAndRoles(
    $slug: String!
  ) {
    communityBySlug(slug: $slug) {
      ...communityMembersFragment
    }
    communityRoles(
      filter: { id: { distinctFrom: "985c8829-bd53-4cf6-93bc-ff4f7e20f76d" } }
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

export const UPDATE_COMMUNITY_USER = gql`
  ${COMMUNITY_MEMBERS_FRAGMENT}
  mutation updateCommunityUser($id: UUID!, $communityRoleId: UUID!, $status: CommunityUserStatus) {
    updateCommunityUser(input: { patch: { communityRoleId: $communityRoleId, status: $status }, id: $id }) {
      community {
        ...communityMembersFragment
      }
    }
  }
`;

const DELETE_COMMUNITY_USER_BY_COMMUNITY_ID_AND_USER_ID = gql`
  ${COMMUNITY_MEMBERS_FRAGMENT}
  mutation deleteCommunityUserByUserIdAndCommunityId($communityId: UUID!, $userId: UUID!) {
  deleteCommunityUserByUserIdAndCommunityId(
    input: { userId: $userId, communityId: $communityId }
  ) {
    community {
      ...communityMembersFragment
    }
  }
}
`;

export const DELETE_COMMUNITY_USER_BY_COMMUNITY_USER_ID = gql`
  ${COMMUNITY_MEMBERS_FRAGMENT}
  mutation deleteCommunityUserByCommunityUserId($communityUserId: UUID!) {
    deleteCommunityUser(input: { id: $communityUserId }) {
      community {
        ...communityMembersFragment
      }
    }
  }
`;

const CREATE_COMMUNITY_USER = gql`
  mutation createCommunityUser($communityId: UUID, $userId: UUID) {
    createCommunityUser(input: { communityUser: { communityId: $communityId, userId: $userId } }) {
      community {
        id
      }
    }
  }
`;
interface SchoolClassCommunitiesConnection extends CommunitiesConnection {
  nodes: SchoolClass[];
}
export interface CommunityWithExtraAliases extends Community {
  communityMentors: CommunityUsersConnection;
  communityFacilitators: CommunityUsersConnection;
  mainTeachers: CommunityUsersConnection;
  additionalTeachers: CommunityUsersConnection;
  communitiesByParentCommunityId: SchoolClassCommunitiesConnection;
}

export interface CommunityProfileType {
  id: string;
  slug: string;
  name: Record<string, string>;
  aboutOneliner: Record<string, string>;
  avatarUrl: string;
  bannerImageUrl: string;
  bioregionGid: number;
  commons: Record<string, string>;
  nbUsers: number;
  communityUsers: CommunityUser[];
  totalCredits: number;
  totalCreditsWithSubcommunities: number;
  about: Scalars['JSON'];
  locationPictureUrl: string;
  locationAddress: Record<string, string>;
  facilityDescription: Record<string, string>;
  facilityAmenities: Record<string, string>;
  facilityImageUrl: string;
  events: EventCourse[];
  totalKgTrashCollected: number;
  communityLearningPaths: Array<CommunityLearningPath>;
  communitySdgs: CommunitySdgsConnection;
  email: string;
  phone: string;
  website: string;
  isShopEnabled: boolean;
  territory: Territory;
  type: CommunityType;
  communitiesByParentCommunityId: CommunitiesConnection;
  communityMentors: CommunityUsersConnection;
  communityFacilitators: CommunityUsersConnection;
  mainTeachers: CommunityUsersConnection;
  additionalTeachers: CommunityUsersConnection;
}

export async function deleteCommunityUserByCommunityIdAndUserId(communityId: string, userId: string) {
  const deleteCommunityUser = await apolloClient.mutate({
    mutation: DELETE_COMMUNITY_USER_BY_COMMUNITY_ID_AND_USER_ID,
    variables: {
      communityId,
      userId
    }
  });
  return (deleteCommunityUser as any).data.deleteCommunityUserByUserIdAndCommunityId.community;
}

export async function createCommunityUser(communityId: string, userId: string) {
  const createCommunityUser = await apolloClient.mutate({
    mutation: CREATE_COMMUNITY_USER,
    variables: {
      communityId,
      userId
    }
  });
  return (createCommunityUser as any).data.createCommunityUser.community;
}

const UPDATE_COMMUNITY_AVATAR = gql`
  mutation updateCommunityAvatar($communityId: UUID!, $url: String!) {
    updateCommunity(input: { patch: { avatarUrl: $url }, id: $communityId }) {
      community {
        id
        avatarUrl
      }
    }
  }
`;

export async function updateCommunityAvatar(communityId: string, url: string): Promise<string> {
  const updateGql = await apolloClient.mutate({
    mutation: UPDATE_COMMUNITY_AVATAR,
    variables: {
      communityId,
      url
    }
  });
  return updateGql.data.updateCommunity.community.avatarUrl;
}

/** If there is a parrent community (= school), display its name in brackets after the class community name */
export function getCommunityDisplayName(community: Community, currentLang: string): string {
  const displayName = community.parentCommunity?.name // if there is a parrent community (= school), display its name in brackets
    ? `${getTranslatedString(community.name, currentLang)} (${getTranslatedString(community.parentCommunity.name, currentLang)})`
    : getTranslatedString(community.name, currentLang);
  return displayName || '';
}
