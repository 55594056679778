import { gql, useQuery } from '@apollo/client';
import { AddSpecimenModal } from 'components/BioRegion/AddSpecimenModal';
import JoinACommonsModal from 'components/BioRegion/JoinACommonsModal';
import MultilingualText from 'components/Multilingual/MultilingualText';
import { UserContext } from 'contexts/user/UserContextProvider';
import IntlMessages from 'helpers/IntlMessages';
import { SPECIMEN_FRAGMENT } from 'pages/Bioregion/CreateEditSpecimenForm/SpecimenIdentification';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { User } from 'services/graphqltypes';
import { USER_PROFILE } from 'services/user';
import SpecimensTabLayout from './SpecimensTabLayout';

export const LIST_OF_SPECIMENS_FRAGMENT = gql`
${SPECIMEN_FRAGMENT}
fragment listOfSpecimensFragment on SpecimenConnection {
  nodes {
    ...specimanFragment
  }
}`;

export const SPECIMENS_TAB = gql`
${LIST_OF_SPECIMENS_FRAGMENT}
query commonsSpecimensTab($territoryId: UUID!, $userId: UUID!) {
  territory(id: $territoryId) {
    id
    slug
    name
    bioregionGid
    isCurrentUserMentor
    locationName
    locationGeojson
    locationBoundingBox

    observedSpecimens: specimensByTerritoryIdOrLocationGeometry(
      orderBy: [USER_SPECIMEN_BY_SPECIMEN_ID_MAX_UPDATED_AT_DESC, SLUG_ASC]
      filter: {
        userSpecimenBySpecimenId: {some: {userId: {equalTo: $userId}}}
        status: { equalTo: APPROVED_PUBLISHED }
      }
    ) {
      ...listOfSpecimensFragment
    }

    nonObservedSpecimens: specimensByTerritoryIdOrLocationGeometry(
      orderBy: [SLUG_ASC]
      filter: {
        userSpecimenBySpecimenId: {every: {userId: {distinctFrom: $userId}}}
        status: { equalTo: APPROVED_PUBLISHED }
      }
    ) {
      ...listOfSpecimensFragment
    }

    draftSpecimens: specimensByTerritoryIdOrLocationGeometry(
      filter: {
        status: { in: [MAPPING_DRAFT,DRAFT] }
        createdByUserId: { equalTo: $userId }
      }
      orderBy: UPDATED_AT_DESC
    ) {
      ...listOfSpecimensFragment
    }

    myContributionSpecimens: specimensByTerritoryIdOrLocationGeometry(
      filter: {
        status: { in: [TO_REVIEW, UNDER_REVIEW, NEEDS_IMPROVEMENT] }
        createdByUserId: { equalTo: $userId }
      }
      orderBy: UPDATED_AT_DESC
    ) {
      ...listOfSpecimensFragment
    }

    reviewPendingSpecimens: specimensByTerritoryIdOrLocationGeometry(
      filter: {
        status: { in: [TO_REVIEW, UNDER_REVIEW, NEEDS_IMPROVEMENT] }
      }
      orderBy: UPDATED_AT_DESC
    ) {
      ...listOfSpecimensFragment
    }
  }
}
`;

/** Renders content of the Specimens tab in a Commons profile, Ecoregion profile and in user's BI page */
const SpecimensTab = ({ user, commonsTerritoryId, ecoregionName, inBI }:
  {
    user: User | null,
    commonsTerritoryId?: string,
    ecoregionName: string,
    inBI?: boolean,
  }): React.ReactElement => {
  const currentLang = useSelector((state: any) => state.settings.locale);

  // get current user
  const currentUser = useContext(UserContext)['currentUser'];

  /* */
  /* States */
  /* */
  const [showJoinACommonsModal, setShowJoinACommonsModal] = useState(false);
  const [showCreateSpecimenModal, setShowCreateSpecimenModal] = useState(false);

  /* */
  /* Effects */
  /* */
  // Show "Join a Commons" modal if user is viewing his own BI page and he has no territoryId
  useEffect(() => {
    (currentUser.id === user.id) && !user.currentTerritory && setShowJoinACommonsModal(true);  // currentTerritoryId in UserContext doesn't refresh.
  }, [user, currentUser]);

  /* */
  /* Queries, data processing */
  /* */
  const { data, loading, error } = useQuery(SPECIMENS_TAB, {
    variables: {
      territoryId: commonsTerritoryId || user?.currentTerritory?.id,
      userId: user?.id,
    },
    fetchPolicy: 'network-only', // disabling cache because after changing specimen status, it doesn't move to correct section
    skip: !commonsTerritoryId && !user?.currentTerritory?.id,
  });

  /* */
  /* Render */
  /* */
  if (loading) {
    return <div className="loading" />;
  }

  if (error && user?.currentTerritory) {
    return <h1><IntlMessages id='general.loading-error' /></h1>;
  }

  if (!data) {
    return (
      <Fragment>
        <h1><IntlMessages id='general.loading-no-data' /></h1>

        {/* display Join a common modal */}
        <JoinACommonsModal
          showJoinACommonsModal={showJoinACommonsModal}
          setShowJoinACommonsModal={setShowJoinACommonsModal}
          refetchQuery={
            {
              queryConfig: { query: USER_PROFILE, variables: { username: currentUser.username } },
              queryName: 'RefetchUserBioregionInfo'
            } // the query needs to refetch after change territory id.
          }
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SpecimensTabLayout
        draftSpecimens={data?.territory?.draftSpecimens?.nodes}
        myContributionSpecimens={data?.territory?.myContributionSpecimens?.nodes}
        nonObservedSpecimens={data?.territory?.nonObservedSpecimens?.nodes}
        observedSpecimens={data?.territory?.observedSpecimens?.nodes}
        reviewPendingSpecimens={data?.territory?.reviewPendingSpecimens?.nodes}
        setShowCreateSpecimenModal={setShowCreateSpecimenModal}
        territorySlug={data?.territory?.slug}
        user={user}
        isCurrentUserMentor={data?.territory?.isCurrentUserMentor}
        locationBoundingBox={data?.territory?.locationBoundingBox}
        locationGeojson={data?.territory?.locationGeojson}
        inBI={inBI}
        variant={inBI ? 'my-bioregional-identity' : 'commons'}
        // different context help in My BI and in Commons Profile
        contextHelpId={inBI ? '84a38330-563c-428e-b681-7e7db727b2b3' : '70e053f6-687e-4e11-b833-42c249a14b39'}
      />

      <AddSpecimenModal
        showAddSpecimenModal={showCreateSpecimenModal}
        setShowAddSpecimenModal={setShowCreateSpecimenModal}
        territoryId={data?.territory?.id}
        learningSpaceName={<MultilingualText value={data?.territory?.name} />}
        ecoregionGid={data?.territory?.bioregionGid}
        ecoregionName={ecoregionName}
        currentLang={currentLang}
        // key will change when showCreateSpecimenModal changes, which will trigger Modal remount, all the state inside and child component will rerender
        key={showCreateSpecimenModal ? 'modal-open' : 'modal-closed'}
      />

    </Fragment >
  );
};

export default SpecimensTab;
