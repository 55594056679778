import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const LifeOnLandIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="lifeOnLand" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
           .life-on-land{fill - rule:evenodd;clip-rule:evenodd;fill:#46AE4C;}
           .st1{fill:#FFFFFF;}
           .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3-6-0-4-3-6-3-7-4" className="life-on-land" d="M61.9,1.6c6.8,0,12.3,5.5,12.3,12.3v48.2c0,6.8-5.5,12.3-12.3,12.3
	H13.8C7,74.3,1.5,68.8,1.5,62.1V13.8C1.5,7.1,7,1.6,13.8,1.6C13.8,1.6,61.9,1.6,61.9,1.6z"/>
      <g id="text6065-3-2-9-6-6-5-5-2-9">
        <path id="path2528" className="st1" d="M27.5,14.1h-2.6V7.9h0.9v5.3h1.7V14.1z"/>
        <path id="path2530" className="st1" d="M27.7,14.1V7.9h0.9v6.1H27.7z"/>
        <path id="path2532" className="st1" d="M29.1,14.1V7.9h2.6v0.8h-1.8v1.9h1.5v0.8h-1.5v2.7H29.1L29.1,14.1z"/>
        <path id="path2534" className="st1"
          d="M34.6,13.2v0.9H32V7.9h2.7v0.8h-1.8v1.8h1.5v0.9h-1.5v1.8L34.6,13.2L34.6,13.2z"/>
        <path id="path2536" className="st1" d="M25.1,17c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5s0,0.3,0,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		C25.1,22.2,25,22.1,25,22c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.4,0-0.5v-3.1c0-0.2,0-0.3,0-0.5C24.9,17.3,25,17.1,25.1,17L25.1,17z
		 M26.7,17.5c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1s-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.1c0,0,0,0,0,0.1
		s0,0,0,0.1c0,0,0,0.1,0.1,0.3s0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2V18
		c0-0.1,0-0.2,0-0.2C26.8,17.6,26.8,17.6,26.7,17.5L26.7,17.5z"/>
        <path id="path2538" className="st1" d="M30.5,22.6l-1.3-3.7v3.7h-0.9v-6.1h0.8l1.4,3.7v-3.7h0.9v6.1H30.5z"/>
        <path id="path2540" className="st1" d="M36.5,22.6h-2.6v-6.1h0.9v5.3h1.7V22.6z"/>
        <path id="path2542" className="st1" d="M36.5,22.6l1.4-6.1h0.7l1.4,6.1h-0.9l-0.2-1.3h-1.1l-0.2,1.3H36.5z M37.9,20.4h0.8l-0.4-2.2
		L37.9,20.4z"/>
        <path id="path2544" className="st1" d="M42.5,22.6l-1.4-3.7v3.7h-0.9v-6.1h0.8l1.4,3.7v-3.7h0.9v6.1H42.5z"/>
        <path id="path2546" className="st1" d="M45.4,16.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V21c0,0.4-0.1,0.8-0.4,1.1
		c-0.2,0.2-0.6,0.4-1.1,0.4H44v-6.1H45.4z M45.9,21v-3.2c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.5h0.4
		C45.7,21.7,45.9,21.5,45.9,21L45.9,21z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-1-4-7">
        <path id="path2549" className="st1" d="M8.8,22.6V10.1l-2.1,1.6V9.5l2.1-1.5h2.1v14.7H8.8z"/>
        <path id="path2551" className="st1" d="M16.3,13.2c0.3,0,0.7,0.1,1.1,0.3c0.4,0.2,0.6,0.4,0.8,0.7s0.3,0.6,0.4,0.9
		c0,0.3,0.1,0.6,0.1,0.9v3.3c0,0.3,0,0.6,0,0.8c0,0.2-0.1,0.5-0.2,0.9c-0.1,0.4-0.5,0.8-1.1,1.1c-0.5,0.3-1.1,0.5-1.8,0.5
		c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.5-1-0.9c-0.2-0.3-0.4-0.7-0.5-1.1c-0.1-0.3-0.1-0.7-0.1-1.1v-0.4h2.2v0.8c0,0,0,0.1,0,0.2
		c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.6-0.1,0.8-0.3c0.1-0.2,0.2-0.4,0.2-0.5
		c0-0.1,0-0.2,0-0.4v-3c0,0,0-0.1,0-0.2s0-0.1,0-0.2c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.2-0.2-0.3-0.2
		c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-0.8,0.2-1,0.8h-1.9V7.9h6v2h-4.1v3.9C15.1,13.4,15.6,13.2,16.3,13.2L16.3,13.2z"/>
      </g>
      <path id="path8267" className="st1" d="M25.5,35.6c-5.5,0.4-4.9,5.7-2.3,7.2c-2.3,3.5,0,6.9,2.7,6.9h8.8c4.9,0,8.2-8.2,0.7-11.6
	C38.2,28.5,25.4,29.1,25.5,35.6L25.5,35.6z"/>
      <path id="path8269" className="st1" d="M43.6,45.8c2.5-1.1,5.4,1.4,5.4,1.4s2.6-2.4,5.3-1.4c0.7,0.2,0.7-0.5,0.4-0.6c-1.4-1-3.2-2-5.7,0
	c-3-1.9-4.7-1.1-5.9,0C42.9,45.4,43,46.1,43.6,45.8z"/>
      <path id="path8269-7" className="st1" d="M39.8,36.9c2-1.1,4.6,0.7,4.6,0.7s2-2.2,4.3-1.5c0.6,0.1,0.5-0.4,0.3-0.6
	c-1.3-0.7-2.8-1.4-4.7,0.4c-2.7-1.4-3.9-0.5-4.9,0.4C39.2,36.7,39.4,37.2,39.8,36.9L39.8,36.9z"/>
      <path id="path8269-3" className="st1" d="M46.8,31.6c1.8-0.2,3.2,1.9,3.2,1.9s2.1-1,3.7,0.1c0.4,0.3,0.5-0.2,0.4-0.3
	c-0.7-0.9-1.7-1.9-3.6-1.1c-1.6-1.8-2.8-1.6-3.7-1.1C46.5,31.3,46.4,31.7,46.8,31.6L46.8,31.6z"/>
      <path id="rect8292" className="st2" d="M30.3,51h1.8c0.2,0,0.4,0.2,0.4,0.4v4.9c0,0.2-0.2,0.4-0.4,0.4h-1.8c-0.2,0-0.4-0.2-0.4-0.4v-4.9
	C29.9,51.2,30.1,51,30.3,51z"/>
      <path id="rect8292-7" className="st2" d="M20.6,58.9h34.8c0.2,0,0.4,0.2,0.4,0.4V61c0,0.2-0.2,0.4-0.4,0.4H20.6c-0.2,0-0.4-0.2-0.4-0.4
	v-1.7C20.2,59.1,20.4,58.9,20.6,58.9z"/>
      <path id="rect8292-7-1" className="st2" d="M20.6,63.1h34.8c0.2,0,0.4,0.2,0.4,0.4v1.7c0,0.2-0.2,0.4-0.4,0.4H20.6
	c-0.2,0-0.4-0.2-0.4-0.4v-1.7C20.2,63.3,20.4,63.1,20.6,63.1z"/>
    </svg>
  );
};

export default LifeOnLandIcon;
