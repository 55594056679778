import React, { useEffect, useState } from 'react';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import './MessagingChannelHeader.css';
import AvatarGroup from './AvatarGroup';
import { TypingIndicator } from '../components/TypingIndicator';
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";

type Props = {
  theme: string;
  toggleMobile: () => void;
};

const MessagingChannelHeader = (props: Props) => {
  const { theme, toggleMobile } = props;
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const [title, setTitle] = useState('');

  const channelName = channel.data?.name;

  const members = Object.values(channel.state?.members ?? {}).filter(
    (member) => member?.user?.id !== client?.user?.id,
  );


  useEffect(() => {
    if (!channelName) {
      setTitle(
        members.map((member) => member.user?.name || member.user?.id || 'Unnamed User').join(', '),
      );
    }
  }, [channelName, members]);

  return (
    <div className='messaging__channel-header' aria-label='Chat channel header'>
      <div id='mobile-nav-icon' className={`${theme}`} onClick={() => toggleMobile()} aria-label='Toggle mobile navigation'>
        <ChevronLeftIcon className='my-auto mx-auto' size={30} color='#60b6aa' />
      </div>
      <AvatarGroup members={members} />
      <div className='channel-header__name'>{channelName || title}</div>
      <div className='messaging__channel-header__right'>
        <TypingIndicator />
      </div>
    </div>
  );
};

export default React.memo(MessagingChannelHeader);
