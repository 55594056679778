import React, { useState } from 'react';
import { Row, Card, Alert } from 'react-bootstrap';
import { Colxx } from 'components/custom/CustomBootstrap';
import { NavLink } from 'react-router-dom';
import IntlMessages from 'helpers/IntlMessages';
import ResetForm from './components/ResetForm';

const Reset = () => {
  const [show, setShow] = useState(false);
  const [alertId, setAlert] = useState('');
  return (
    <div style={{ marginTop: 70 }}>
      <div className="container">
        <Row>
          <Colxx xxs="12" md="10" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="position-relative image-side d-none d-lg-block">
              </div>
              <div className="form-side">
                <div className="alert">
                  <Alert variant="danger" show={show} onClose={() => setShow(false)} dismissible>
                    <IntlMessages id={alertId} />
                  </Alert>
                </div>
                <NavLink to="/" className="white d-flex justify-content-center">
                  <span className="logo-single" />
                </NavLink>
                <Card.Title className="mb-4">
                  <IntlMessages id="user.reset-password" />
                </Card.Title>
                <ResetForm setAlert={setAlert} setShow={setShow} />
              </div>
            </Card>
          </Colxx>
        </Row>
      </div>
    </div>
  );
};

export default Reset;
