import React from 'react';
import IconProps from './IconProps';

const PerformingArtsIcon = ({ style, color = '#a31b5c', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="performingArtsIcon" data-name="performingArtsIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <path d="M696.8,998.5c-167.2,0-303.2-136-303.2-303.2v-352H1000v352C1000,862.5,864,998.5,696.8,998.5z M456.7,406.3
		v289c0,132.4,107.7,240.1,240.1,240.1S937,827.7,937,695.3v-289H456.7z"/>
      </g>
      <g>
        <path d="M696.8,869.5c-83.5,0-151.4-67.9-151.4-151.4v-31.5h302.8v31.5C848.2,801.5,780.3,869.5,696.8,869.5z
		 M614.3,749.6c12.7,33.2,44.9,56.8,82.6,56.8c37.6,0,69.8-23.6,82.6-56.8H614.3z"/>
      </g>
      <g>
        <path d="M234,484.6c-1.5,0-3.1-0.1-4.6-0.3c-17.2-2.5-29.1-18.6-26.6-35.8L267.4,8.8l35.4,11.3
		c1,0.4,111.9,34.3,220-15.7c15.9-7.3,34.5-0.3,41.8,15.4c7.3,15.8,0.4,34.5-15.4,41.8C458,103.8,367.7,97.1,319.4,88.5l-54.2,369.2
		C262.9,473.3,249.4,484.6,234,484.6z"/>
      </g>
      <g>
        <path d="M132.7,587.1C59.5,587.1,0,527.5,0,454.3s59.5-132.7,132.7-132.7s132.7,59.5,132.7,132.7
		S205.9,587.1,132.7,587.1z M132.7,384.6c-38.4,0-69.7,31.3-69.7,69.7s31.3,69.7,69.7,69.7s69.7-31.3,69.7-69.7
		S171.2,384.6,132.7,384.6z"/>
      </g>
    </svg>
  );
};

export default PerformingArtsIcon;
