import { gql, useMutation, useQuery } from '@apollo/client';
import InfoIcon from 'components/svg/InfoIcon';
import { UserContext } from 'contexts/user/UserContextProvider';
import { Field, Formik, FormikValues } from 'formik';
import IntlMessages from 'helpers/IntlMessages';
import { updateMultilingualJson } from 'helpers/Utils';
import { getTranslatedString } from 'helpers/utils-typescript';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import LifebuoyIcon from 'mdi-react/LifebuoyIcon';
import React, { SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { Form, Modal, ModalBody } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import MultilingualHtmlParser from '../Multilingual/MultilingualHtmlParser';
import Editor from '../TxtEditor/Editor';
import NextPreviousButton from './NextPreviousButton';

export const CONTEXT_HELP_ENTRY = gql`
query contextHelpEntry($id: UUID!) {
  contextHelpEntry(id: $id) {
    __typename
    id
    content
  }
}
`;

export const CREATE_CONTEXT_HELP_ENTRY = gql`
mutation createContextHelpEntry($id: UUID!, $content: JSON) {
  createContextHelpEntry(
    input: { contextHelpEntry: { id: $id, content: $content } }
  ) {
    contextHelpEntry {
      __typename
      id
      content
    }
  }
}
`;

export const UPDATE_CONTEXT_HELP_ENTRY = gql`
mutation updateContextHelpEntry($id: UUID!, $content: JSON) {
  updateContextHelpEntry(
    input: { patch: { content: $content, id: $id }, id: $id }
  ) {
    contextHelpEntry {
      __typename
      id
      content
    }
  }
}
`;

export type FormInitialValues = {
  content: string;
}

export interface ContextHelpModalProps {
  showContextHelpModal: boolean;
  setShowContextHelpModal: React.Dispatch<SetStateAction<boolean>>;
  currentLang: string;
  contextHelpId: string;
}

/** Icon that opens context help modal. Variant 'circle' is the small 'i' (used inline), variant 'outline' is big size*/
const ContextHelp = (
  {
    contextHelpId,
    variant = 'circle'
  }: {
    contextHelpId: string,
    variant?: 'circle' | 'outline'
  }
): React.ReactElement => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const [showContextHelpModal, setShowContextHelpModal] = useState(false);

  const HelpIcon = variant === 'circle' ? InfoIcon : InformationOutlineIcon;
  const helpIconSize = variant === 'circle' ? 15 : 32;

  return (
    <React.Fragment>
      <span onClick={() => { setShowContextHelpModal(!showContextHelpModal); }}>
        { /* The 'color' and 'onHoverGreen' don't have any effect on the circle (full color, small) variant*/}
        <HelpIcon size={helpIconSize} color='grey' className="ml-1 clickable onHoverGreen" />
      </span>
      <ContextHelpModal
        contextHelpId={contextHelpId}
        showContextHelpModal={showContextHelpModal}
        setShowContextHelpModal={setShowContextHelpModal}
        currentLang={currentLang} />
    </React.Fragment>
  );
};


const ContextHelpModal = ({
  showContextHelpModal,
  setShowContextHelpModal,
  currentLang,
  contextHelpId,
}: ContextHelpModalProps): React.ReactElement => {
  const { currentUser } = useContext(UserContext);
  const [hasSubmit, setHasSubmit] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [createContextHelpEntry] = useMutation(CREATE_CONTEXT_HELP_ENTRY);
  const [updateContextHelpEntry] = useMutation(UPDATE_CONTEXT_HELP_ENTRY);
  const [displaySpinner, setDisplaySpinner] = useState(false);

  const onCloseModal = () => {
    setShowContextHelpModal(false);
  };

  /* don't make a query unless the modal is open, and if we don't have an id */
  const { loading, error, data, refetch } =
    useQuery(CONTEXT_HELP_ENTRY, { variables: { id: contextHelpId }, skip: (!contextHelpId || !showContextHelpModal) });

  const content = useMemo(() => {
    if (loading || !data || error) {
      return null;
    }
    return data?.contextHelpEntry?.content;
  }, [loading, error, data]);

  const initialValues = useMemo(() => {
    const formInitialValues: FormInitialValues = { content: '' };
    if (!content) {
      return formInitialValues;
    }
    formInitialValues.content = getTranslatedString(content, currentLang);
    return formInitialValues;
  }, [content, currentLang]);

  const handleSubmit = useCallback((values: FormikValues) => {
    if (isDirty) {
      setDisplaySpinner(true);
      const { content: formContent } = values;
      const updatedFormContent = updateMultilingualJson(content, formContent, currentLang);
      if (!content) {
        createContextHelpEntry({
          variables: {
            id: contextHelpId,
            content: updatedFormContent,
          }
        }).then(() => {
          refetch();
          setDisplaySpinner(false);
          setShowContextHelpModal(false);
        }).catch((error) => {
          console.log(error);
          setDisplaySpinner(false);
        });
      } else {
        updateContextHelpEntry({
          variables: {
            id: contextHelpId,
            content: updatedFormContent,
          }
        }).then((result) => {
          const updatedContent = result?.data?.updateContextHelpEntry?.contextHelpEntry.content;
          if (updatedContent) {
            initialValues.content = getTranslatedString(updatedContent, currentLang);
            setDisplaySpinner(false);
            setShowContextHelpModal(false);
          }
        }).catch((error) => {
          console.log(error);
          setDisplaySpinner(false);
        });
      }
    } else {
      setShowContextHelpModal(false);
    }

  }, [
    content,
    createContextHelpEntry,
    currentLang,
    contextHelpId,
    isDirty,
    updateContextHelpEntry,
    setShowContextHelpModal,
    initialValues,
    refetch,
  ]);


  const validationSchema = Yup.object().shape({
    content: Yup.string().nullable(),
  });

  return (
    <Modal
      show={showContextHelpModal}
      onHide={() => setShowContextHelpModal(!showContextHelpModal)}
      className="rounded-modal">

      <Modal.Header closeButton className="ml-3 mr-3 mt-3 mb-0 p-0">
        { /* "create/edit context help entry" heading for admin */}
        {(currentUser?.isAdmin) && (
          (!content)
            ? <h2><LifebuoyIcon className="orange-color" size="35" /> <IntlMessages id='context-help-modal.title-new' /></h2>
            : <h2><LifebuoyIcon className="orange-color" size="35" /> <IntlMessages id='context-help-modal.title-edit' /></h2>
        )}
      </Modal.Header>
      <ModalBody className="pl-3 pr-3 pb-3 ">

        {(loading)
          ? /* show a spinner while loading */
          <>
            <div className="d-flex justify-content-center">
              <LifebuoyIcon size={90} className="orange-color mb-4" />
            </div>
            <div className="d-flex justify-content-center">
              <div className="loading-relative-position mt-2 mb-2" />
            </div>
          </>
          :
          <>
            { /* "no help here yet" for non-admin */}
            {!content && !currentUser?.isAdmin &&
              <div className="text-center mb-5 ml-4 mr-4">
                <LifebuoyIcon size={90} className="orange-color mb-4" />
                <h3><IntlMessages id='context-help-modal.no-content' /></h3>
              </div>
            }

            { /* render html content for non-admin */}
            {content && !currentUser?.isAdmin &&
              <div className="html-parser card-full-width-img mb-4">
                <div className="text-center mb-4">
                  <LifebuoyIcon size={90} className="orange-color" />
                </div>
                <MultilingualHtmlParser multilingualHtml={content} />
              </div>
            }

            { /* close button for non-admin */}
            {!currentUser?.isAdmin &&
              <div className="text-center">
                <NextPreviousButton
                  onClick={onCloseModal}
                  buttonTextId='general.close'
                />
              </div>
            }

            { /* html editor for admin */}
            {currentUser?.isAdmin &&
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={handleSubmit}
              >
                {({ values, handleSubmit, isValid, dirty }) => (
                  <Form className="av-tooltip tooltip-label-right" onSubmit={handleSubmit}>
                    <Field
                      name="content"
                      className='form-control-editor'
                      component={Editor}
                      value={values.content}
                    />

                    { /* close/save buttons for admin */}
                    <div className="d-flex row justify-content-center mt-3">
                      <NextPreviousButton
                        onClick={onCloseModal}
                        buttonTextId='general.close'
                        className="mr-2" />
                      <NextPreviousButton
                        buttonTextId='general.save'
                        className={`ml-2 btn-yellow ${displaySpinner ? 'disabled' : ''}`}
                        submit
                        disabled={!hasSubmit ? false : !isValid}
                        displaySpinner={displaySpinner}
                        onClick={() => {
                          setHasSubmit(true);
                          setIsDirty(dirty);
                        }} />
                    </div>
                  </Form>
                )}
              </Formik>
            }
          </>
        }
      </ModalBody>
    </Modal>
  );
};

export default ContextHelp;
