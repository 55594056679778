import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Uranus = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="uranus" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000"  xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {` 
            .uranus-st0{fill:#FFFFFF;}
            .uranus-st1{fill:#E14F22;}
            .uranus-st2{fill:#E04F21;} 
        `}
      </style>
      <g>
        <g id="XMLID_1_">
          <g>
            <path className="uranus-st0" d="M496.7,60.4c236.4,0,428.7,192.3,428.7,428.7S733.1,917.8,496.7,917.8C260.4,917.8,68,725.5,68,489.1
				S260.4,60.4,496.7,60.4z M790.4,228.1c0-17.2-13.9-31.1-31.1-31.1c-66.1,0-119.5,62.5-130.6,147.3H527.4V215.1
				c0-17.2-13.9-31.1-31.1-31.1c-17.2,0-31.1,13.9-31.1,31.1v129.2H364.8C353.7,259.5,300.3,197,234.2,197
				c-17.2,0-31.1,13.9-31.1,31.1s13.9,31.1,31.1,31.1c37.1,0,69.8,54,70.5,116.1c0,0.7,0,1.4,0,2.1c0,63-32.9,118.2-70.5,118.2
				c-17.2,0-31.1,13.9-31.1,31.1S217,558,234.2,558c67.2,0,121.2-64.5,131.1-151.5h99.8V508c-72.7,14.5-127.6,78.8-127.6,155.7
				c0,87.5,71.2,158.7,158.7,158.7c87.5,0,158.7-71.2,158.7-158.7c0-76.9-54.9-141.1-127.6-155.7V406.5h100.7
				c9.9,86.9,64,151.5,131.1,151.5c17.2,0,31.1-13.9,31.1-31.1s-13.9-31.1-31.1-31.1c-37.6,0-70.5-55.2-70.5-118.2
				c0-0.7,0-1.4,0-2.1c0.7-62.1,33.4-116.1,70.5-116.1C776.5,259.3,790.4,245.3,790.4,228.1z"/>
            <circle className="uranus-st0" cx="496.3" cy="663.6" r="96.4"/>
            <path className="uranus-st1" d="M956,489.1c0,253.2-206,459.3-459.3,459.3S37.5,742.3,37.5,489.1s206-459.3,459.3-459.3S956,235.9,956,489.1
				z M925.4,489.1c0-236.4-192.3-428.7-428.7-428.7C260.4,60.4,68,252.7,68,489.1s192.3,428.7,428.7,428.7
				C733.1,917.8,925.4,725.5,925.4,489.1z"/>
            <path className="uranus-st2" d="M759.3,197c17.2,0,31.1,13.9,31.1,31.1s-13.9,31.1-31.1,31.1c-37.1,0-69.8,54-70.5,116.1l-0.9,0
				c0-17.2-13.9-31.1-31.1-31.1h-28.1C639.8,259.5,693.2,197,759.3,197z"/>
            <path className="uranus-st2" d="M656.8,406.5c17.2,0,31.1-13.9,31.1-31.1l0.9,0c0,0.7,0,1.4,0,2.1c0,63,32.9,118.2,70.5,118.2
				c17.2,0,31.1,13.9,31.1,31.1S776.5,558,759.3,558c-67.2,0-121.2-64.5-131.1-151.5H656.8z"/>
            <path className="uranus-st2" d="M687.9,375.4c0,17.2-13.9,31.1-31.1,31.1h-28.6c-1.1-9.4-1.6-19.1-1.6-29c0-11.4,0.7-22.5,2.2-33.2h28.1
				C674,344.3,687.9,358.2,687.9,375.4z"/>
            <path className="uranus-st2"
              d="M527.4,344.3h101.2c-1.4,10.8-2.2,21.9-2.2,33.2c0,9.9,0.6,19.6,1.6,29H527.4V344.3z"/>
            <path className="uranus-st2" d="M527.4,508V536c0,17.2-13.9,31.1-31.1,31.1c-17.2,0-31.1-13.9-31.1-31.1V508c10.1-2,20.5-3.1,31.1-3.1
				S517.4,505.9,527.4,508z"/>
            <path className="uranus-st2"
              d="M527.4,406.5V508c-10.1-2-20.5-3.1-31.1-3.1s-21.1,1.1-31.1,3.1V406.5H527.4z"/>
            <rect x="465.1" y="344.3" className="uranus-st2" width="62.3" height="62.3"/>
            <path className="uranus-st2"
              d="M527.4,215.1v129.2h-62.3V215.1c0-17.2,13.9-31.1,31.1-31.1C513.5,184,527.4,197.9,527.4,215.1z"/>
            <path className="uranus-st2" d="M465.1,536c0,17.2,13.9,31.1,31.1,31.1c-53.2,0-96.5,43.3-96.5,96.5s43.3,96.5,96.5,96.5
				c53.2,0,96.4-43.3,96.4-96.5s-43.3-96.5-96.4-96.5c17.2,0,31.1-13.9,31.1-31.1V508C600.1,522.5,655,586.7,655,663.6
				c0,87.5-71.2,158.7-158.7,158.7c-87.5,0-158.7-71.2-158.7-158.7c0-76.9,54.9-141.1,127.6-155.7V536z"/>
            <path className="uranus-st2"
              d="M465.1,344.3v62.3h-99.8c1.1-9.4,1.6-19.1,1.6-29c0-11.4-0.7-22.5-2.2-33.2H465.1z"/>
            <path className="uranus-st2" d="M367,377.5c0,9.9-0.6,19.6-1.6,29h-29.5c-17.2,0-31.1-13.9-31.1-31.1c0-17.2,13.9-31.1,31.1-31.1h29
				C366.2,355,367,366.1,367,377.5z"/>
            <path className="uranus-st2" d="M364.8,344.3h-29c-17.2,0-31.1,13.9-31.1,31.1h0c-0.7-62.1-33.4-116.1-70.5-116.1
				c-17.2,0-31.1-13.9-31.1-31.1S217,197,234.2,197C300.3,197,353.7,259.5,364.8,344.3z"/>
            <path className="uranus-st2" d="M304.7,375.4c0,17.2,13.9,31.1,31.1,31.1h29.5c-9.9,87-64,151.5-131.1,151.5c-17.2,0-31.1-13.9-31.1-31.1
				s13.9-31.1,31.1-31.1c37.6,0,70.5-55.2,70.5-118.2C304.7,376.8,304.7,376.1,304.7,375.4L304.7,375.4z"/>
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Uranus;
