import React from 'react';
import IconProps from './IconProps';

const OutdoorSkillsIcon = ({ style, color = '#c89d66', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="outdoorSkills" data-name="outdoorSkills" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <path d="M500,0C224.3,0,0,224.3,0,500s224.3,500,500,500s500-224.3,500-500S775.7,0,500,0z M500,937.5
		C258.7,937.5,62.5,741.2,62.5,500S258.7,62.5,500,62.5S937.5,258.8,937.5,500S741.3,937.5,500,937.5z"/>
        <path d="M607.3,542.4l178.1-276.7c7.9-12.3,6.2-28.4-4-38.8s-26.4-12.4-38.7-4.6L460.4,397.1
		c-13.9,5.4-26.9,13.5-38.2,24.5c-2,1.9-3.4,4.2-5.2,6.2c-1.1,1.2-2.6,2.1-3.6,3.5l-2.2,3.4c-2.9,3.8-5.4,7.8-7.7,11.9l-189,293.7
		c-7.9,12.3-6.2,28.4,4,38.8c6,6.1,14.1,9.3,22.3,9.3c5.7,0,11.4-1.5,16.5-4.7L557.1,598c3.9-2.2,7.8-4.5,11.5-7.1l3.7-2.3
		c1.5-0.9,2.4-2.3,3.6-3.6c2-1.7,4.3-3.1,6.2-5C593.2,569.2,601.6,556.3,607.3,542.4z M552.9,500.9c0,13-5.2,25.3-14.6,34.6
		c-19.9,19.5-52.3,19.5-72.3,0c-9.4-9.3-14.6-21.6-14.6-34.6c0-13.1,5.2-25.4,14.6-34.6c10-9.8,23.1-14.6,36.1-14.6
		c13.1,0,26.2,4.9,36.1,14.6C547.7,475.5,552.9,487.8,552.9,500.9z M597.9,441.5c-4.5-7.1-9.6-13.8-15.8-19.9
		c-5.8-5.7-12.2-10.6-18.8-14.8l94.6-58.6L597.9,441.5z M405.7,558.9c4.7,7.6,10,14.8,16.5,21.2c6.2,6.1,13,11.1,20.2,15.5
		l-100.3,62.2L405.7,558.9z"/>
        <path d="M500,260.4c17.3,0,31.2-14,31.2-31.2V127.8c0-17.3-14-31.2-31.2-31.2s-31.2,14-31.2,31.2v101.3
		C468.8,246.4,482.7,260.4,500,260.4z"/>
        <path d="M500,729.9c-17.3,0-31.2,14-31.2,31.2v101.3c0,17.3,14,31.2,31.2,31.2s31.2-14,31.2-31.2V761.2
		C531.2,743.9,517.3,729.9,500,729.9z"/>
        <path d="M874.3,463.9H771.1c-17.3,0-31.2,14-31.2,31.2s14,31.2,31.2,31.2h103.3c17.3,0,31.2-14,31.2-31.2
		S891.6,463.9,874.3,463.9z"/>
        <path d="M260.2,495.2c0-17.3-14-31.2-31.2-31.2H125.7c-17.3,0-31.2,14-31.2,31.2s14,31.2,31.2,31.2h103.3
		C246.2,526.4,260.2,512.5,260.2,495.2z"/>
      </g>
    </svg>
  );
};

export default OutdoorSkillsIcon;
