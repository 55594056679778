
import { viewport } from '@placemarkio/geo-viewport';
import turfBbox from '@turf/bbox';
import MapAttribution from 'components/Map/MapAttribution';
import MultilingualText from 'components/Multilingual/MultilingualText';
import NotificationManager from 'components/common/react-notifications/NotificationManager';
import ThumbnailImage from 'components/custom/ThumbnailImage';
import ThumbnailLetters from 'components/custom/ThumbnailLetters';
import IntlMessages from 'helpers/IntlMessages';
import { getPictureLocaleUrl } from 'helpers/Utils';
import { getCurrentOrientation, handleOrientation } from 'helpers/utils-typescript';
import CircleSlice8Icon from 'mdi-react/CircleSlice8Icon';
import NavigationIcon from 'mdi-react/NavigationIcon';
import NavigationVariantIcon from 'mdi-react/NavigationVariantIcon';
import { GeoJson, Map, Marker, Overlay, Point } from 'pigeon-maps';
import { maptiler } from 'pigeon-maps/providers';
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import * as geolocated from 'react-geolocated';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RouteStop, Scalars, User } from 'services/graphqltypes';

const maptilerProvider = maptiler(import.meta.env.VITE_MAPTILER_API_KEY, 'topo');

/**
 * Get geojson for all stops, so that we can calculate bounding box -> map center and zoom
 * @param {Array} routeStops array of route stops
 * @returns {Object} geojson
 */
export const getAllStopsGeojson = (routeStops: RouteStop[]) => {
  const geojson = { 'type': 'FeatureCollection', 'features': [] } as any;
  routeStops.forEach((stop) => {
    geojson.features.push({
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [stop.specimen.coordinates.x, stop.specimen.coordinates.y]
      },
    });
  });
  return geojson;
};

/** Draw svg line between points on the map - from https://github.com/mariusandra/pigeon-maps/issues/31 */
// it can also be replaced with following component from Pigeon, see in RouteList
// <GeoJson
//   data ={
//     {
//     type: 'FeatureCollection',
//     features: [{
//       'type': 'Feature',
//       'geometry': {
//         'type': 'Polygon',
//         'coordinates': [
//           route.routeStops.nodes.map((stop: RouteStop) => {
//             return [stop.specimen.coordinates.y, stop.specimen.coordinates.x];
//           })
//         ]
//       }
//     }]
//   }
//   }
// /> 
export const Line = ({ mapState: { width, height }, latLngToPixel, coordsArray, style = { stroke: '#60b6aa', strokeWidth: 2, } }: any) => {
  if (coordsArray.length < 2) {
    return null;
  }

  const lines = [];
  let pixel = latLngToPixel(coordsArray[0]);

  for (let i = 1; i < coordsArray.length; i++) {
    const pixel2 = latLngToPixel(coordsArray[i]);
    lines.push(<line key={i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} {...style} />);
    pixel = pixel2;
  }

  return (
    <div style={{ position: 'absolute', top: 0, left: 0 }} > {/* add wrapper div and style so it is consistent with GeoJson component from Pigeon */}
      <svg width={width} height={height} fill='none'>
        {lines}
      </svg>
    </div>
  );
};

interface MapMarkerNumberProps {
  number: number | React.ReactElement;
  color: string;
  borderColor?: string;
  fontColor?: string;
  width?: string;
  height?: string;
  blackAndWhite?: boolean;
}

export const MapMarkerNumber: FC<MapMarkerNumberProps> = ({ number, color, borderColor, fontColor, width, height, blackAndWhite }: MapMarkerNumberProps) => {
  const markerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: width || '30px',
    height: height || '30px',
    borderRadius: '50%',
    backgroundColor: color, // Customize the marker background color
    pointerEvents: 'auto',
    border: `2px solid ${borderColor || color}`, // Customize the marker border color
  };

  const numberStyle: React.CSSProperties = {
    fontSize: '14px', // Customize the number font size
    fontWeight: 'bold',
    color: fontColor || '#ffffff', // Customize the number color
    filter: blackAndWhite ? 'grayscale(100%)' : 'none',
  };

  return (
    <div style={markerContainerStyle}>
      <div style={markerStyle}>
        <span style={numberStyle}>{number}</span>
      </div>
    </div>
  );
};

const markerContainerStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px', // Adjust the marker size as desired
};


/**
 * Renders map of a route, with stops completed/not yet completed visualized.
 * Used in the "About page" of the route, and in the Map view when playing.
*/
const RouteMap = ({
  routeStops,
  isCurrentUserMentor,
  style,
  type,
  showCurrentLocation,
  getCurrentLocationButtonClicked,
  setGetCurrentLocationButtonClicked,
  routeColor,
  className,
  customTrackGeojson,
  multiplayerGameId,
  multiplayerList,
}:
  {
    routeStops: RouteStop[],
    isCurrentUserMentor: boolean,
    style?: React.CSSProperties,
    /** cover version has smaller popovers when tapping on a stop */
    type: 'cover' | 'fullscreen',
    showCurrentLocation?: boolean,
    getCurrentLocationButtonClicked?: boolean,
    setGetCurrentLocationButtonClicked?: React.Dispatch<SetStateAction<boolean>>,
    routeColor: string,
    className?: string,
    customTrackGeojson: JSON,
    multiplayerGameId?: Scalars['UUID'],
    multiplayerList?: User[],
  }) => {

  const currentLocation = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { questSlug, commonsSlug } = useParams() as any;

  const popoverHeight = type === 'cover' ? '150px' : '300px';
  const popoverOffset: Point = type === 'cover' ? [-20, 70] : [150, -20]; // on cover version, popover is on the right side of the marker, on fullscreen version, it's on the bottom
  const popoverMapCenterOffset = type === 'cover' ? 0 : 0.00025; // in fullscreen version, we want the stop to be slightly above the center of the map when popover is open
  const clickToCenterZoom = 20;

  /* */
  /* States */
  /* */
  const [mapCenterZoom, setMapCenterZoom] = useState({ center: [null, null], zoom: null });
  const [popoverPayload, setPopoverPayload] = useState<any>();
  const [compass, setCompass] = useState(null);

  /* */
  /* Map center and zoom based on the shape polygon size */
  /* */

  const setWidthHeightZoom = React.useCallback(node => {
    if (node !== null) {
      const mapWidth = node.clientWidth as number;
      const mapHeight = node.clientHeight as number;
      const allStopsGeojson = getAllStopsGeojson(routeStops);
      const locationBoundingBox = turfBbox(allStopsGeojson);
      setMapCenterZoom(viewport(locationBoundingBox as any, [mapWidth, mapHeight]));
    }
  }, [routeStops]);

  /* */
  /* Get device current location */
  /* */
  const { coords, isGeolocationAvailable, isGeolocationEnabled, positionError, getPosition } = showCurrentLocation
    ? geolocated.useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 120000, // 120 s
      },
      watchPosition: true
    }) : { coords: null, isGeolocationAvailable: null, isGeolocationEnabled: null, positionError: null, getPosition: null };

  useEffect(() => {
    showCurrentLocation && getCurrentOrientation(setCompass, handleOrientation);

    return () => {
      window.removeEventListener('deviceorientation', (event: any) => handleOrientation(event, setCompass));
      window.removeEventListener('deviceorientationabsolute', (event: any) => handleOrientation(event, setCompass));
    };
  }, [showCurrentLocation, setCompass]);

  useEffect(() => {
    // get position if get current location button is clicked
    if (getCurrentLocationButtonClicked) {
      getPosition();
      if (coords) {
        setMapCenterZoom({ zoom: clickToCenterZoom, center: [coords.latitude, coords.longitude] });
      } else if (!isGeolocationAvailable) {
        NotificationManager.error(
          intl.formatMessage({ id: 'Geolocation service not available' }),
          null,
          3000,
          null,
          null,
          'filled'
        );
      } else if (!isGeolocationEnabled) {
        NotificationManager.error(
          intl.formatMessage({ id: 'bioregion.error-access-denied' }),
          null,
          3000,
          null,
          null,
          'filled'
        );
      } else if (positionError) {
        NotificationManager.error(
          `Problem with geolocation: ${positionError.message} (${positionError.code})`,
          null,
          3000,
          null,
          null,
          'filled'
        );
      }
      setGetCurrentLocationButtonClicked(false);
    }
  }, [intl,
    coords,
    setGetCurrentLocationButtonClicked,
    getPosition,
    setMapCenterZoom,
    getCurrentLocationButtonClicked,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError]);

  /* */
  /* Render */
  /* */
  return (
    <div
      className={`${className}`}
      style={{ overflow: 'hidden', ...style }}
      ref={setWidthHeightZoom}
    >

      <Map
        maxZoom={20}
        provider={maptilerProvider}
        attributionPrefix={false} // hide "Pigeon" text
        attribution={<MapAttribution />}
        dprs={[1, 2]}
        center={[mapCenterZoom.center[0], mapCenterZoom.center[1]]}
        zoom={mapCenterZoom.zoom}
        onBoundsChanged={({ center, zoom }) => {
          setMapCenterZoom({ center, zoom });
        }}
        onClick={() => setPopoverPayload(null)} // close popover when clicking on map
      >
        {/* Line that represents the track between stops */}
        {customTrackGeojson ?
          <GeoJson
            data={customTrackGeojson}
            svgAttributes={{
              stroke: routeColor || '#60b6aa',
              strokeWidth: 2,
            }}
          />
          :
          <Line
            coordsArray={
              routeStops.map((routeStop: RouteStop) => {
                const speciman = routeStop.specimen;
                return ([speciman.coordinates?.x, speciman.coordinates?.y]);
              })}
            style={{ stroke: routeColor || '#60b6aa', strokeWidth: 2, strokeDasharray: '5,5' }}  // default color green
          />
        }

        {/* Marker for stops */}
        {routeStops.map((routeStop: RouteStop) => {
          const speciman = routeStop.specimen;
          const color = popoverPayload?.payload?.id === speciman.id
            ? '#efb701' // if the popover is open, highlight the marker
            : routeStop.currentUserHasCompleted ? (routeColor || '#60b6aa') : 'grey'; // if the user has completed the stop, color it routeColor (set to default color green if there is no color set for the route), otherwise grey
          const specimanObserved = routeStop.currentUserHasCompleted || routeStop.specimen.currentUserHasObserved; // logged in user's status is saved under routeStop.specimen.currentUserHasObserved, non logged in user's status is saved under routeStop.currentUserHasCompleted
          const routeStopSlug = routeStop.slug
          return (
            <Marker
              offset={[0, -20]}
              key={speciman.id}
              anchor={[speciman.coordinates?.x, speciman.coordinates?.y]}
              onClick={({ anchor }) => {
                setPopoverPayload({ payload: { ...speciman, currentUserHasObserved: specimanObserved, routeStopSlug }, anchor });
                setMapCenterZoom(() => {
                  return {
                    zoom: clickToCenterZoom,
                    center: [speciman.coordinates?.x - popoverMapCenterOffset, speciman.coordinates?.y],
                  };
                });
              }}
            >
              <MapMarkerNumber number={routeStop.position} color={color} />
            </Marker>
          );
        })}

        { /* Device current position marker - without compass */}
        {coords && !compass &&
          <Marker
            anchor={[coords.latitude, coords.longitude]}
            offset={[-5, -20]} // this offset seems to put center of the marker right onto the anchor coordinates
          >
            <CircleSlice8Icon size='20px' color="#3988f3" />
          </Marker>
        }

        { /* Device current position marker - with compass */}
        {coords && compass &&
          <Marker
            anchor={[coords.latitude, coords.longitude]}
            offset={[0, -15]} // this offset seems to put center of the marker right onto the anchor coordinates
          >
            <NavigationIcon size='30px' color="#3988f3" style={{ transform: `rotate(${compass}deg)` }} />
          </Marker>
        }

        { /* Profile pics of people who play a multiplayer game together */}
        {multiplayerList?.length > 0 &&
          <div
            style={{ position: 'absolute', bottom: '2rem' }}
            className='px-1'
            onClick={() => history.push(`/quest/${questSlug}/multiplayer/${multiplayerGameId}/manage`)}
          >
            {multiplayerList.map((user, index: number) => {
              return (
                user.avatarUrl ? (
                  <ThumbnailImage rounded small src={user.avatarUrl} alt="Profile picture" className="border-0 rounded-circle list-thumbnail small" style={{ zIndex: 1, marginLeft: `${index === 0 ? '' : '-12px'}`, }} />
                ) : (
                  <ThumbnailLetters rounded small text={user.name} className="border-0 rounded-circle list-thumbnail small" style={{ zIndex: 1, marginLeft: `${index === 0 ? '' : '-12px'}`, }} />
                )
              );
            })}
          </div>
        }

        { /* render popover with the tree info */}
        {popoverPayload &&
          <Overlay anchor={popoverPayload.anchor} offset={popoverOffset}>
            <Card
              style={{ height: popoverHeight }}
              className={(!isCurrentUserMentor && !popoverPayload.payload.currentUserHasObserved) && 'disabled-specimen'}
            >
              <Card.Body className='p-0' style={{ height: 'calc(100% - 3rem)' }}>  {/* 2rem =  1.5rem (line height of button text) +  1.5rem (padding of footer) */}
                <Link
                  className='d-flex flex-column h-100'
                  to={{ pathname: `/commons/${commonsSlug}/specimen/${popoverPayload.payload.slug}`, state: { from: currentLocation, speciman: popoverPayload, breadcrumbsTranslationId: 'route.quest' } }}
                >
                  {/* Cover pic */}
                  <img
                    src={popoverPayload.payload.coverPictureUrl
                      ? getPictureLocaleUrl(popoverPayload.payload.coverPictureUrl)
                      : popoverPayload.payload.specimenAttachmentsBySpecimenId?.nodes[0]?.url}
                    alt="RouteStop"
                    className="responsive border-0 card-img-top clickable card-img-top"
                    style={{ aspectRatio: '1/1', objectFit: 'cover', maxHeight: 'calc(100% - 1.5rem)' }}
                  />
                  {/* Specimen name */}
                  <div className='text-center w-100' style={{ height: '1.5rem', fontSize: '1rem', color: 'black' }}>
                    <MultilingualText value={popoverPayload.payload.name} />
                  </div>
                </Link>
              </Card.Body>

              {/* Nav button */}
              <Card.Footer
                className='text-center d-flex justify-content-center clickable-button'
                onClick={() => { history.push({ pathname: `/quest/${questSlug}/${popoverPayload.payload.routeStopSlug}/nav`, state: { from: currentLocation } }) }}
              >
                <NavigationVariantIcon color='#60b6aa' size='1.5rem' className='my-auto mr-2' />
                <div className='my-auto h3 color-theme-1'>
                  <IntlMessages id='route.stop-navigate' />
                </div>
              </Card.Footer>
            </Card>
          </Overlay>
        }
      </Map>
    </div >
  );
};

export default RouteMap;
