import { ActivityType } from 'components/common/DropdownFilters/ActivityTypeDropdownFilter';
import NotificationManager from 'components/common/react-notifications/NotificationManager';
import { UserPermissions } from 'contexts/user/UserContextProvider';
import availableLanguages, { TranslationStrings } from 'lang/availableLanguages';
import { SessionClick } from 'pages/EventCourse/PageLayout';
import { SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { EventCourse, EventCourseSpecies, EventCourseSpeciman, Maybe, Point, Route, Scalars, Speciman, User } from 'services/graphqltypes';
import { updateMultilingualJson } from './Utils';
import { RouteWithCompletedRouteStops } from 'pages/Commons/Quest/useLocalStorageRouteData';

export function getAge(dateString: Date): number {
  if (dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return null;
}

// use in a react component, get current user like this:
// const currentUser = useContext(UserContext)['currentUser'];
export function isFacilitatorInCommunity(eventCommunityId: string, userPermissions: UserPermissions[]): boolean {
  const perm = userPermissions?.find((permission: any) => permission.communityId === eventCommunityId);
  return perm?.permissions.includes('createEventCourse');
}

export function hasUpdateCommunityProfilePermission(communityId: string, userPermissions: UserPermissions[]): boolean {
  const perm = userPermissions?.find((permission: any) => permission.communityId === communityId);
  return perm?.permissions.includes('updateCommunityProfile');
}

/**
 * Checks if the current user has permissions to edit the route or see a draft route.
 * 
 * @param route - The route object to check permissions for.
 * @param currentUser - The current user object returned by UserContext.
 * @param currentUserPermissions - An array of the current user's permissions returned by UserContext.
 * @returns A boolean indicating whether the user has permission to edit the route or see the draft route.
 *          Returns true if the user is a route manager, creator, or a mentor/facilitator of the manager's community (including parent community and commons of manager's community).
 */
export const checkEditRouteUserPermissions = (route: RouteWithCompletedRouteStops | Route, currentUser: User, currentUserPermissions: UserPermissions[]) => {
  //ensure routeManagers.nodes is in query
  if (!('routeManagers' in route && 'nodes' in route.routeManagers)) {
    throw Error('route.routeManagers.nodes is missing in route, double check your graphql query');
  }
  // ensure createdByUserId is not null
  if (!route.createdByUserId) {
    throw Error('route.createdByUserId is missing in route, double check your graphql query');
  }
  // ensure isCurrentUserMentor is in query
  if (!('territory' in route)) {
    throw Error('route.territory is missing in route, double check your graphql query');
  }
  if (route.territory && !('isCurrentUserMentor' in route.territory)) {
    throw Error('route.territory.isCurrentUserMentor is missing in route.territory, double check your graphql query');
  }

  const userIsTerritoryMentor = route.territory?.isCurrentUserMentor;
  const userIsRouteManager = route.routeManagers.nodes.some((manager) => manager.user?.id === currentUser?.id);
  const userIsCreator = route.createdByUserId === currentUser?.id;
  const userIsMentorOrFacilitatorOfManagerCommunity = route.routeManagers.nodes.some((manager) => {
    if (!manager.community) return false;
    return (
      isFacilitatorInCommunity(manager.community.id, currentUserPermissions) ||
      isFacilitatorInCommunity(manager.community.parentCommunity?.id, currentUserPermissions) ||
      manager.community.parentCommunity?.territory?.isCurrentUserMentor ||
      manager.community.territory?.isCurrentUserMentor
    )
  }

  );
  return userIsRouteManager || userIsCreator || userIsMentorOrFacilitatorOfManagerCommunity || userIsTerritoryMentor;
}

export const getTranslatedString = (multilingualJson: Maybe<Scalars['JSON']> | null | undefined, currentLanguage: string): string => {
  if (multilingualJson) {
    const translatedString = multilingualJson[currentLanguage]
      ? multilingualJson[currentLanguage]
      : multilingualJson[multilingualJson['defaultLocale']];
    return translatedString;
  } else {
    return '';
  }
};

/** Takes message from Format.JS format and transforms them into react-intl digestible format */
export const transformMessages = (messages: TranslationStrings): { [key: string]: string } => {
  // Object.entries(messages) converts the messages object into an array of [key, value] pairs
  return Object.entries(messages).reduce<{ [key: string]: string }>(
    (acc, [key, value]) => {
      // acc is the accumulator, initially an empty object {}
      // [key, value] is the current [key, value] pair from Object.entries(messages)
      acc[key] = value.defaultMessage; // Assign the defaultMessage to the accumulator
      return acc; // Return the accumulator for the next iteration
    },
    {} // Initial value for the accumulator is an empty object
  );
};

/** use it instead of intl.formatMessage when need to be used outside of a react component  */
export const getUiTranslation = (id: string, currentLanguage: string): string => {
  const translatedString = availableLanguages[currentLanguage][id]
    ? availableLanguages[currentLanguage][id].defaultMessage
    : availableLanguages['en'][id]
      ? availableLanguages['en'][id].defaultMessage
      : id; // return the string of the identifier if we don't have this translation at all
  return translatedString;
};

export const setPageFromLocation = (searchParams: URLSearchParams, setCurrentPage: (pageNumber: number) => void) => {
  if (searchParams.get('page')) {
    setCurrentPage(parseInt(searchParams.get('page')));
  }
};

export const setTabFromLocation = (hash: string, tabs: string[], setActiveTab: (tabName: any) => void) => {
  for (let i = 0; i < tabs.length; i++) {
    if (hash.includes(tabs[i])) {
      setActiveTab(tabs[i]);
    }
  }
};

/** Scrolls active tab into view if width of all tabs is more than screen width */
export const scrollTabToHorizontalViewFromLocation = (hash: string) => {
  const tabToScroll = document.querySelector(`[data-rb-event-key=${hash.slice(1)}]`);
  // tabToScroll?.scrollIntoView({ inline: 'center', behavior: 'smooth' });
  if (tabToScroll) {
    const toLeft = (tabToScroll as HTMLElement).offsetLeft;
    tabToScroll.parentElement.scrollLeft = toLeft;
  }
};

export const setTimeFilterByFromLocation = (searchParams: URLSearchParams, setFilterBy: (filterBy: string) => void) => {
  if (searchParams.get('timeFilter')) {
    setFilterBy(searchParams.get('timeFilter'));
  }
};

export const setLayoutFromLocation = (searchParams: URLSearchParams, possibleValues: string[], setDisplayMode: (layout: any) => void) => {
  const passedValue = searchParams.get('layout');
  if (passedValue && possibleValues.includes(passedValue)) {
    setDisplayMode(passedValue);
  }
};

export const setActivityTypeFromLocation = (searchParams: URLSearchParams,
  setActivityTypeDropdown: (activityTypeDropdown: ActivityType) => void) => {
  if (searchParams.get('activityType')) {
    setActivityTypeDropdown(searchParams.get('activityType') as ActivityType);
  }
};

export const setBestPracticeTypeFromLocation = (searchParams: URLSearchParams,
  setBestPracticeTypeDropdown: (bestPracticeTypeDropdown: ActivityType) => void) => {
  if (searchParams.get('type')) {
    setBestPracticeTypeDropdown(searchParams.get('type') as ActivityType);
  }
};

export const setFocusFromLocation = (searchParams: URLSearchParams,
  setFilterByLearningPath: (filterByLearningPath: string) => void) => {
  if (searchParams.get('focus')) {
    setFilterByLearningPath(searchParams.get('focus'));
  }
};

export const setAgeGroupFromLocation = (searchParams: URLSearchParams,
  setAgeGroup: (ageGroup: ageRangeGroup) => void, setFilterByAgeGroup: (filterByAgeGroup: string) => void) => {
  if (searchParams.get('age')) {
    setAgeGroup(getAgeGroup(searchParams.get('age')));
    setFilterByAgeGroup(searchParams.get('age'));
  }
};

export const todayLocalMidnight = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return now.toISOString();
};

// group sessions starting on a same day, so they can be displayed in
// an expandable section
// used in course details page and course dashboard
export const groupSessionsByStartingDate = (sessions: Array<EventCourse>) => {
  const sessionsDateSorting = sessions.reduce((sortDates, eventCourse: EventCourse) => {
    const date = eventCourse?.startTime?.split('T')[0];

    // check if a particular date array is already defined
    if (date !== undefined && !sortDates[date]) {
      sortDates[date] = [];
    }


    if (date) {
      sortDates[date].push(eventCourse);
    } else {
      // check if the without dates array is already defined
      if (!sortDates['withoutDates']) {
        sortDates['withoutDates'] = [];
      }
      sortDates['withoutDates'].push(eventCourse);
    }
    return sortDates;
  }, {});

  return Object.keys(sessionsDateSorting).sort().map((key: string) => {
    const keyValuePair: Record<string, string> = {};

    if (new Date(key) instanceof Date && !key.includes('without')) {
      keyValuePair['date'] = key;
    } else {
      keyValuePair[key] = 'without_date';
    }

    return {
      ...keyValuePair,
      sessions: sessionsDateSorting[key].sort((firstSession: EventCourse, secondSession: EventCourse) => {
        return firstSession?.startTime?.localeCompare(secondSession?.startTime);
      }),

    } as unknown as {
      date?: Date | string,
      sessions: EventCourse[]
    };
  });

};

export const displaySessionCards = (trackSessionClicks: SessionClick[], index: number): boolean => {
  if (trackSessionClicks[index]) {
    if (trackSessionClicks[index].key === index && trackSessionClicks[index].displaySessions === true) {
      return true;
    } else {
      return false;
    }
  }
};

export const trackAndSetSessionClicks = (trackSessionClicks: SessionClick[], index: number,
  setTrackSessionClicks: React.Dispatch<React.SetStateAction<SessionClick[]>>) => {
  if (trackSessionClicks.length > 0) {
    if (trackSessionClicks[index]) {
      if (trackSessionClicks[index].key === index) {
        if (trackSessionClicks[index].displaySessions === true) {
          trackSessionClicks[index].displaySessions = false;
        } else {
          trackSessionClicks[index].displaySessions = true;
        }
        setTrackSessionClicks([...trackSessionClicks]);
      }
    } else {
      const trackSession = {} as SessionClick;
      trackSession.key = index;
      trackSession.displaySessions = true;
      trackSessionClicks[index] = trackSession;
      setTrackSessionClicks([...trackSessionClicks]);
    }
  } else {
    const trackSession = {} as SessionClick;
    trackSession.key = index;
    trackSession.displaySessions = true;
    trackSessionClicks[index] = trackSession;
    setTrackSessionClicks([...trackSessionClicks]);
  }
};

export const impactPointsRange = (impactPoints: number, min: number, max: number): boolean => {
  return impactPoints >= min && impactPoints <= max;
};

export const ageRange = (age: number, minAge: number, maxAge: number): boolean => {
  return age as number >= minAge && age as number < maxAge;
};

export const getAgeBagesArray = (): Array<{ id: string, value: number }> => {
  return [
    { id: 'badge.awakening-dragonfly', value: 1.5 }, { id: 'badge.exploring-otter', value: 3 },
    { id: 'badge.adventurous-woodpecker', value: 6 }, { id: 'badge.compassionate-deer', value: 9 },
    { id: 'badge.listening-goose', value: 12 }, { id: 'badge.brave-bear', value: 15 },
    { id: 'badge.friendly-wolf', value: 18 }, { id: 'badge.strong-bull', value: 21 }, { id: 'badge.sharp-eagle', value: 35 },
    { id: 'badge.creative-horse', value: 50 }, { id: 'badge.wise-owl', value: 65 }, { id: 'badge.peaceful-turtle', value: 80 }
  ];
};

export const getFacilitationBadgesArray = (): Array<{
  id: string, value: number, unit: string, descriptionId?: string
}> => {
  return [
    { id: 'badge.polar', descriptionId: 'badge.polar-to-get', value: 0, unit: 'IP' },
    { id: 'badge.polar', value: 100, unit: 'FIP' }, { id: 'badge.yew', value: 300, unit: 'FIP' },
    { id: 'badge.elm', value: 1000, unit: 'FIP' }, { id: 'badge.fir', value: 3000, unit: 'FIP' },
    { id: 'badge.willow', value: 5000, unit: 'FIP' }, { id: 'badge.oak', value: 7000, unit: 'FIP' },
    { id: 'badge.ginko', value: 10000, unit: 'FIP' }
  ];
};

export const getParticipationBadgesArray = (): Array<{
  id: string, value: number, unit: string, descriptionId?: string
}> => {
  return [
    { id: 'badge.mercury', descriptionId: 'badge.mercury-to-get', value: 0, unit: 'IP' },
    { id: 'badge.mercury', value: 100, unit: 'IP' }, { id: 'badge.venus', value: 300, unit: 'IP' },
    { id: 'badge.mars', value: 1000, unit: 'IP' }, { id: 'badge.jupiter', value: 3000, unit: 'IP' },
    { id: 'badge.saturn', value: 5000, unit: 'IP' }, { id: 'badge.uranus', value: 7000, unit: 'IP' },
    { id: 'badge.neptune', value: 10000, unit: 'IP' }
  ];
};


export const getDevelopmentBadgesArray = (): Array<{
  id: string, value: number, unit: string, descriptionId?: string
}> => {
  return [
    { id: 'badge.copper', value: 100, unit: 'DIP' }, { id: 'badge.malachite', value: 500, unit: 'DIP' },
    { id: 'badge.amthyst', value: 1000, unit: 'DIP' }, { id: 'badge.quartz', value: 3000, unit: 'DIP' },
    { id: 'badge.topaz', value: 5000, unit: 'DIP' }, { id: 'badge.zircon', value: 7000, unit: 'DIP' },
    { id: 'badge.turquoise', value: 10000, unit: 'DIP' }
  ];
};

export const getRangeOfYears = () => {
  const startYear = 1900;
  const currentYear = (new Date()).getFullYear();
  return Array.from({ length: (currentYear - startYear) }, (_, i) => startYear + i).reverse();
};


export interface StuffWithId {
  id: string;
}

type Changes = {
  updated: any;
  added: any;
  removed: any;
}

// This function compares the "before" and the "after" list and returns three lists
// The before and after lists must contain objects that have at least an "id" field
// The updated list will contain all the objects that were in both lists
// The added list will contain all the objects that are only in the "after" list
// The removed list will contain all the objects that are only in the "before" list
export const detectUpdatedData = (
  beforeUpdate: any,
  afterUpdate: any,
  effectiveUpdate?: (bUpdate: any, aUpdate: any) => boolean,
  idName = 'id',  // if unique identifier is called differently than id, pass its name in the idName argument
): Changes => {
  const updated = [] as any[];
  const removed = [] as any[];



  beforeUpdate.forEach((before: any) => {
    const correspondingAfter = afterUpdate.find((after: any) => after[idName] === before[idName]);
    if (correspondingAfter === undefined) {
      removed.push(before);
    } else {
      if (effectiveUpdate === undefined || effectiveUpdate(before, correspondingAfter) === true) {
        updated.push(correspondingAfter);
      }
    }
  });
  const added = afterUpdate.filter((after: any) =>
    beforeUpdate.find((before: any) => after[idName] === before[idName]) === undefined);

  return {
    added,
    updated,
    removed,
  } as Changes;
};

/** Takes an image blob that is encoded into url, and returns a Blob type, that can be uploaded. Returns null if url doesn't contain a blob. */
const blobFromString = async (imageUrl: string): Promise<Blob | null> => {
  // process picture upload only if a new blob is set (otherwise there is an url to the previously uploaded picture, which doesn't contain 'blob:' in the url)
  if (!imageUrl || !imageUrl.includes('blob:')) {
    return null;
  }
  return fetch(imageUrl).then(res => res.blob()).catch((err: Error) => {
    console.log('blobFromString could not get blob from string', imageUrl, err);
    return null;
  });
};

/** Takes a blob that is encoded into url, makes a Blob type out of it, and uploads it using the provided uploadFunction  */
export const uploadUrlEncodedBlob = (pictureBlobUrl: string, uploadFunction: Function, ...args: any[]): Promise<string> => {
  return blobFromString(pictureBlobUrl).then((blob: Blob | null): Promise<string> => {
    if (blob === null) {
      return new Promise<string>((resolve: any) => resolve(''));
    }
    return new Promise<string>((resolve: any, reject: any) => {
      uploadFunction(blob, ...args)
        .then((pictureUrl: string) => resolve(pictureUrl))
        .catch((err: Error) => {
          console.log(err);
          reject(new Error(`Upload failed: ${err.message}`));
        });
    });
  });
};

export interface StuffWithPosition {
  position: number;
}

// For agenda and equipment fields, we use the same data format:
// [ { position: 1, name: { en: 'xxx', defaultLocale: 'en' } }, ... ]
// loadSortedStuffIntoForm helps to
// 1. sort the items
// 2. return multilingual fields in current language
// The second argument is an array of keys that contain a multilingual json. Example (in initialValues):
// agenda: loadSortedStuffIntoForm(
//   data.bestPracticeBySlug.facilitationSchedule, ['time', 'title', 'description'], currentLang),
export const loadSortedStuffIntoForm = (
  rawStuff: StuffWithPosition[], multilingualFields: string[], currentLang: string): StuffWithPosition[] => {
  if (!rawStuff) {
    return [];
  }
  const sortedStuff = rawStuff
    .map((plumbus: any) => plumbus) // clone the array so we can manipulate it
    .sort((a: StuffWithPosition, b: StuffWithPosition) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    })
    .map((item: StuffWithPosition, idx: number) => {
      const output = {
        ...item,
        position: idx,
      };
      multilingualFields.forEach((field: string) => {
        output[`${field}Original`] = output[field];
        output[field] = getTranslatedString(output[field], currentLang);
      });
      return output;
    });
  return sortedStuff;
};

// updateSortedStuffFromForm is the inverse of loadSortedStuffIntoForm
// It takes the form data and updates the original data fetched from api
// or creates them in proper structure if they don't exist yet
export const updateSortedStuff = (
  formFieldValue: StuffWithPosition[],
  originalData: StuffWithPosition[],
  multilingualFields: string[],
  currentLang: string): StuffWithPosition[] => {
  console.log('TODO: check if the original data is not really needed anymore: ', originalData);

  const updatedSortedStuff = [] as StuffWithPosition[];
  formFieldValue.forEach((plainTextItem: StuffWithPosition, idx: number) => {
    // const originalItem = originalData.find((originalItem: StuffWithPosition) => originalItem.id === plainTextItem.id) || {};
    const updatedItem = {
      ...plainTextItem,
      position: idx,
    };
    multilingualFields.forEach((field: string) => {
      updatedItem[field] = updateMultilingualJson(updatedItem[`${field}Original`], updatedItem[field], currentLang);
      delete updatedItem[`${field}Original`];
    });
    updatedSortedStuff.push(updatedItem);
  });
  return updatedSortedStuff;
};

interface ageRangeGroup {
  ageRangeFrom: number | null;
  ageRangeTo: number | null;
  ageEqualTo: number | null;
}

// update the ageGroup state based on value selected in the age filter dropdown
export const getAgeGroup = (ageGroup: string) => {
  const ageRangeGroup = { ageRangeFrom: null, ageRangeTo: null, ageEqualTo: null } as ageRangeGroup;
  const ageRanges = ageGroup.split('-');
  const ageRangeFrom = parseFloat(ageRanges[0]);
  const ageRangeTo = parseFloat(ageRanges[1]);

  if (isNaN(ageRangeFrom)) { // all ages
    ageRangeGroup.ageRangeFrom = 18;
    ageRangeGroup.ageRangeTo = -1;
  } else if (isNaN(ageRangeTo) && ageRangeFrom === 18) { // 18+
    ageRangeGroup.ageEqualTo = 18;
  } else if (ageRangeFrom >= 0 && ageRangeTo >= 0) { // specific age range
    ageRangeGroup.ageRangeFrom = ageRangeFrom;
    ageRangeGroup.ageRangeTo = ageRangeTo;
  }

  return ageRangeGroup;
};

// sort by name
export const sortAlphabetically = (firstName: string, secondName: string) => {
  if (firstName?.toUpperCase() < secondName?.toUpperCase()) {
    return -1;
  }
  if (firstName?.toUpperCase() > secondName?.toUpperCase()) {
    return 1;
  }
  return 0;
};


/* */
/* Generate graphql mutation to mass-award species and specimens to all participants */
/* */

/** Generate a graphql mutation that will award passed species and specimens to passed users */
export function createUserSpecimenSpeciesMutationGenerator(
  /** ID of the event the species/specimens are being awarded in */
  eventId: Scalars['UUID'],
  /** Array of user IDs to award to */
  usersIds: Scalars['UUID'][],
  /** Array of specimens to award */
  routeSpecimen?: EventCourseSpeciman[],
  /** Array of species to award */
  routeSpecieses?: EventCourseSpecies[],
): string {
  let createUserSpecimenGql = [] as string[];
  let createUserSpeciesesGql = [] as string[];

  if (routeSpecimen?.length > 0) {
    createUserSpecimenGql = usersIds.flatMap((userId: Scalars['UUID'], userIdx: number) => {
      return routeSpecimen.map((singleRouteSpecimen: EventCourseSpeciman, itemIdx: number) => {
        const subQuery = `
        y${userIdx}z${itemIdx}: createUserSpeciman(
          input: {
            userSpeciman: {
              userId: "${userId}"
              eventCourseId: "${eventId}"
              specimenId: "${singleRouteSpecimen.specimen.id}"
            }
          }
        ) {
          clientMutationId
        }`;
        return subQuery;
      });
    });
  }

  if (routeSpecieses?.length > 0) {
    createUserSpeciesesGql = usersIds.flatMap((userId: Scalars['UUID'], userIdx: number) => {
      return routeSpecieses.map((singleRouteSpecies: EventCourseSpecies, itemIdx: number) => {
        const subQuery = `
        x${userIdx}y${itemIdx}: createUserSpecies(
          input: {
            userSpecies: {
              userId: "${userId}"
              eventCourseId: "${eventId}"
              speciesId: "${singleRouteSpecies.species.id}"
            }
          }
        ) {
          clientMutationId
        }`;
        return subQuery;
      });
    });
  }

  if (createUserSpecimenGql?.length === 0 && createUserSpeciesesGql?.length === 0) {
    return 'mutation dummy { dummyMutation }';
  }
  const mutation = `mutation createAllParticipantsSpecimenSpecieses { ${createUserSpecimenGql?.join('\n')} \n ${createUserSpeciesesGql?.join('\n')} }`;
  return mutation;
}

/** Returns results from nominatim (geocoding) to be used in a react-select dropdown */
export const nominatimSearchReactSelect = (
  /** string to search for */
  input: string,
  /** callback provided by react-select to set the results */
  reactSelectCallback: (results: Array<{ value: string; label: string }>) => void,
  /** ask nominatim to provide a polygon shape of the result */
  getPolygonGeojson?: boolean,
) => {
  if (input.length === 0) return;
  const url = `https://nominatim.openstreetmap.org/search?format=json&q=${input}&addressdetails=1${getPolygonGeojson && '&polygon_geojson=1'}`;
  fetch(url)
    .then(response => response.json())
    .then((data) => {
      const parsedData = data.map((singleResult: any) => {
        return {
          label: singleResult.display_name,
          value: singleResult,
        };
      });
      reactSelectCallback(parsedData);
    })
    .catch(err => console.warn(err));
};
/** Looks at location name and address and returns a string suitable to display. Used in list of communities, community profile page. */
export const parseNominatimLocationName = (
  locationName: string,
  locationAddress: { [key: string]: any },
  locationCoordinates: Point,
  showFallbackCoordinates = true,
  showTownInLocationName = true, // by default, show location name + town together
): string => {
  /* In the data that we get from Nominatim, in the `display_name` property, _usually_ the name of the place is present before the first comma, followed by the whole address.  */
  /* If there is no place name, we want to avoid showing a part of the address (for example just the house number) as a place name -> we take the content of the "before the first comma", */
  /* and if that's present in the `address` object, we don't show the name at all (and show only the address from the `address` object). */
  const fallbackToCoordinates = !locationAddress && !locationName;
  const locationNameTillFirstComma = locationName?.split(',')[0]?.trim();
  const locationAddressValues = [
    locationAddress?.road,
    locationAddress?.house_number,
    locationAddress?.town,
    locationAddress?.country
  ];

  if (fallbackToCoordinates) { // no address, no location name
    return showFallbackCoordinates ? `${locationCoordinates?.x.toFixed(5)}, ${locationCoordinates?.y.toFixed(5)}` : '';
  }

  if (locationNameTillFirstComma && !locationAddressValues.includes(locationNameTillFirstComma)) {
    return `
      ${locationNameTillFirstComma}
      ${(locationAddress?.town && showTownInLocationName) ? ', ' : ''}${(locationAddress?.town && showTownInLocationName) ? locationAddress?.town : ''}`;
  }

  // returning a suburb (if present) and town
  return locationAddress
    ? `${locationAddress?.suburb ? `${locationAddress.suburb}` : ''}
      ${locationAddress?.suburb && locationAddress?.town ? ', ' : ''}
      ${locationAddress?.town ? `${locationAddress.town}` : ''}`
    : null;
};

/** Checks if a number is a float */
export function isFloat(value: number) {
  return typeof value === 'number' &&
    !Number.isNaN(value) &&
    !Number.isInteger(value);
}

/** if a number is a floating point number, round it up to `fractionDigits` digits after the floating point */
export const roundIfFloat = (number: number, fractionDigits: number) => {
  return isFloat(number) ? number.toFixed(fractionDigits) : number;
};

/** if a number is a floating point number,  round it up to `fractionDigits` digits after the floating point, if it is larger than 1000, show number with unit K and round it up to `fractionDigits` digits */
export const roundToThousands = (number: number, fractionDigits = 0) => {
  if (number < 1000) return roundIfFloat(number, fractionDigits);
  if (number === null) return null;
  return `${roundIfFloat(number / 1000, fractionDigits)}k`;
};

/** Format a name of a specimen as "Name (Code)" */
export const getSpecimenName = (specimen: Speciman, currentLang: string): string => {
  const specimenName = getTranslatedString(specimen.name, currentLang);
  const specimenCode = specimen?.code;
  if (specimenName && specimenCode) {
    return `${specimenName} (${specimenCode})`;
  } else if (specimenName) {
    return specimenName;
  } else if (specimenCode) {
    return specimenCode;
  }
};

/** handle errors in a form submission, use together with useState to store error message and display in relevant place, and reset submit button state to allow resubmit */
export const handleSubmitError = (
  intl: IntlShape,
  error: any,
  setClickedSubmit?: React.Dispatch<SetStateAction<boolean>>,
  setSubmitError?: React.Dispatch<SetStateAction<string>>,
) => {
  // display error message in relevant place if setSubmitError is provided, otherwise log the error
  setSubmitError ? setSubmitError(error.message) : console.error('error', error);

  NotificationManager.error(
    `Error: ${intl.formatMessage({ id: 'error.try-again' })}`,
    null,
    3000,
    null,
    null,
    'filled'
  );
  setClickedSubmit && setClickedSubmit(false); // let the user try again
};

/** Returns ID of participation/age/facilitation badge to be displayed */
export const getBadgeId = (
  badgeName: string,
  user: User,
  /* if true, return id for translation: {creditsWithUnits} away from {the name of first badge} */
  showProgressDescription?: boolean): string => {
  if (badgeName === 'participation') {
    const badgeParticipation = getParticipationBadgesArray().find((obj, index) => {
      return impactPointsRange(user.totalParticipationCredits,
        obj?.value, (getParticipationBadgesArray()[index + 1]?.value - 1));
    });
    return (showProgressDescription && (user.totalParticipationCredits < getParticipationBadgesArray()[1].value))
      ? badgeParticipation?.descriptionId
      : badgeParticipation?.id;
  } else if (badgeName === 'age') {
    const badgeAge = getAgeBagesArray().find((obj, index) => {
      return ageRange(getAge(user.dateOfBirth),
        obj?.value, (getAgeBagesArray()[index + 1]?.value));
    });
    return badgeAge?.id;
  } else if (badgeName === 'facilitation') {
    const badgeFacilitation = getFacilitationBadgesArray().find((obj, index) => {
      return impactPointsRange(user.totalFacilitationCredits,
        obj?.value, (getFacilitationBadgesArray()[index + 1]?.value - 1));
    });
    return (showProgressDescription && (user.totalFacilitationCredits < getFacilitationBadgesArray()[1].value))
      ? badgeFacilitation?.descriptionId
      : badgeFacilitation?.id;
  }

};


/* */
/* Device orientation stuff */
/* */

export interface DeviceOrientationEventApple extends DeviceOrientationEvent {
  requestPermission?: () => Promise<'granted' | 'denied'>;
}

export const handleOrientation = (event: any, setCompass: any) => {
  const { alpha, webkitCompassHeading } = event;
  const heading = webkitCompassHeading || Math.abs(alpha - 360);
  setCompass(roundIfFloat(heading, 0));
};

export const getCurrentOrientation = (setCompass: any, handleOrientation: any) => {

  // For iOS devices, we need to request permission for device motion
  if (typeof (DeviceOrientationEvent as unknown as DeviceOrientationEventApple).requestPermission === 'function') {
    (DeviceOrientationEvent as unknown as DeviceOrientationEventApple).requestPermission()
      .then((permissionState) => {
        if (permissionState === 'granted') {
          window.addEventListener('deviceorientation', (event: any) => handleOrientation(event, setCompass), true);
        } else {
          alert("You didn't allow access to device orientation sensor, compass will not work.");
        }
      })
      .catch((error) => {
        alert("There was a problem getting access to device orientation sensor, compass will not work.");
        console.error('Error requesting device orientation permission:', error);
      });
  } else {
    // For other devices, use the 'deviceorientationabsolute' event
    window.addEventListener('deviceorientationabsolute', (event: any) => handleOrientation(event, setCompass), true);
  }
};

