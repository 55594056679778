import React from 'react';
import IconProps from './IconProps';

const AppropriateTechnologyIcon = ({ style, color = '#1f71b8', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="appropriateTechnology" data-name="appropriateTechnology" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <g>
        <g>
          <path d="M507.1,733.6c-65.9,0-119.5-53.6-119.5-119.5s53.6-119.5,119.5-119.5S626.6,548.2,626.6,614
			S573,733.6,507.1,733.6z M507.1,557.1c-31.4,0-57,25.6-57,57c0,31.4,25.6,57,57,57c31.4,0,57-25.6,57-57
			C564.1,582.6,538.6,557.1,507.1,557.1z"/>
        </g>
        <g>
          <path d="M719.8,1000H294.4v-31.2c0-117.3,95.4-212.7,212.7-212.7s212.7,95.4,212.7,212.7V1000z M360.2,937.5H654
			c-14.4-67.9-74.8-118.9-146.9-118.9S374.6,869.6,360.2,937.5z"/>
        </g>
        <g>
          <path d="M723.1,459.2c-8,0-16-3.1-22.1-9.2c-106.9-106.9-280.9-106.9-387.8,0c-12.2,12.2-32,12.2-44.2,0
			s-12.2-32,0-44.2c131.3-131.3,344.9-131.3,476.2,0c12.2,12.2,12.2,32,0,44.2C739.1,456.1,731.1,459.2,723.1,459.2z"/>
        </g>
        <g>
          <path d="M815,337.4c-8,0-16-3.1-22.1-9.2c-76.4-76.4-177.9-118.4-285.8-118.4s-209.5,42.1-285.8,118.4
			c-12.2,12.2-32,12.2-44.2,0s-12.2-32,0-44.2c88.1-88.2,205.4-136.7,330-136.7S749,195.9,837.1,284c12.2,12.2,12.2,32,0,44.2
			C831,334.3,823,337.4,815,337.4z"/>
        </g>
        <g>
          <path d="M919.2,233.2c-8,0-16-3.1-22.1-9.2C793,119.9,654.5,62.5,507.1,62.5s-285.8,57.4-390,161.6
			c-12.2,12.2-32,12.2-44.2,0s-12.2-32,0-44.2C188.9,63.9,343.1,0,507.1,0s318.2,63.9,434.2,179.9c12.2,12.2,12.2,32,0,44.2
			C935.2,230.2,927.2,233.2,919.2,233.2z"/>
        </g>
      </g>
    </svg>

  );
};

export default AppropriateTechnologyIcon;
