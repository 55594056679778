import MagnifyIcon from 'mdi-react/MagnifyIcon';
import React, { HTMLAttributes, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

type SearchInputFieldProps = {
  showSearchField: boolean,
  screenSize: 'mobile' | 'desktop' | 'both',
  setDebouncedSearchFieldValue: React.Dispatch<SetStateAction<string>>,
  className?: string,
  customPlaceholderTranslationId?: string
}
/**
 * Reusable React component that renders a search input field.
 * Currently used in
 * 1. InputSearchFilter to render input field for desktop
 * 2. BestPracticeList, SpeciesTab, SpecimensTab, SelectStops to render search input field for mobile
 * @param showSearchField: determine the visibility and styling of the input field
 * @param screenSize: determine the style of the input field
 * @param setDebouncedSearchFieldValue: pass the debounced value to the parent component
 * @param className: overwrites the default style for input filed
 */
export const SearchInputField = ({
  showSearchField,
  screenSize,
  setDebouncedSearchFieldValue,
  className,
  customPlaceholderTranslationId,
}: SearchInputFieldProps) => {
  const intl = useIntl();
  let consolidatedClassName;

  switch (screenSize) {
    case 'desktop':
      consolidatedClassName = `${className ? className : 'form-control-sm  mr-2 my-auto'} d-none d-sm-block`;
      break;
    case 'mobile':
      consolidatedClassName = `${className ? className : 'form-control mt-2'} d-sm-none`;
      break;
    case 'both':
      consolidatedClassName = `${className ? className : 'form-control-md-sm'}`;
      break;
  }

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [debouncedSearchFieldValue] = useDebounce(searchFieldValue, 500);

  useEffect(() => {
    setDebouncedSearchFieldValue(debouncedSearchFieldValue);
  }, [debouncedSearchFieldValue, setDebouncedSearchFieldValue]);

  return (showSearchField &&
    <input
      autoFocus
      className={consolidatedClassName}
      type="text"
      name="keyword"
      id="search"
      placeholder={intl.formatMessage({ id: customPlaceholderTranslationId || 'event.course-move-booking-type-to-search' })}
      value={searchFieldValue}
      onChange={(e) => {
        setSearchFieldValue((e.target as HTMLInputElement).value);
      }}
    />
  );
};

interface InputSearchFilter extends HTMLAttributes<HTMLDivElement> {
  setDebouncedSearchFieldValue: React.Dispatch<SetStateAction<string>>,
  showSearchField: boolean,
  setShowSearchField: React.Dispatch<SetStateAction<boolean>>,
  inputFieldClassName?: string,
  screenSize?: 'mobile' | 'desktop' | 'both',
  setIsFiltersOpen?: React.Dispatch<SetStateAction<boolean>>,
  customPlaceholderTranslationId?: string
}

/**
 * A React Component that provides a debounced search input functionality. It includes a magnifying glass icon that toggles the visibility of the search input field for desktop
 * currently used in BestPracticeList,SpeciesTab, SpecimensTab, SelectStops
 * @param setDebouncedSearchFieldValue: pass the debounced value to the parent component
 */
const InputSearchFilter = ({ customPlaceholderTranslationId, setDebouncedSearchFieldValue, showSearchField, setShowSearchField, inputFieldClassName, screenSize = 'desktop', setIsFiltersOpen, ...props }: InputSearchFilter) => {

  return (
    <div {...props} className={`d-flex ${screenSize === 'both' && 'flex-grow-1'}`}>  {/* flex-grow-1 will help searchInputFiled expand to w-100 on small screen */}
      <MagnifyIcon
        className='clickable onHoverGreen mr-2 my-auto '
        size={30}
        color={showSearchField ? '#60B6AA' : 'grey'}
        onClick={() => {
          setShowSearchField((preState) => !preState);
          setDebouncedSearchFieldValue(''); // reset searchInput when close the input field
          setIsFiltersOpen && setIsFiltersOpen(false) // close filter field
        }}
      />

      {/* search input for screen bigger than 576px*/}
      <SearchInputField
        screenSize={screenSize}
        setDebouncedSearchFieldValue={setDebouncedSearchFieldValue}
        showSearchField={showSearchField}
        className={inputFieldClassName}
        customPlaceholderTranslationId={customPlaceholderTranslationId}
      />
    </div>

  );
};

export default InputSearchFilter;
