import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { UserContext } from 'contexts/user/UserContextProvider';

interface PrivateRouteProps extends RouteProps {
  layout?: React.ComponentType<any>;
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> =
  ({ layout: Layout, component: Component, ...otherProps }: PrivateRouteProps) => {
    const { currentUser, loading } = useContext(UserContext);
    return (
      <Route
        {...otherProps}
        render={(props) =>
          loading
            ? <div className="loading" />
            : currentUser
              ? Layout
                ?
                <Layout {...props}>
                  <Component {...Object.assign({}, props)} />
                </Layout>
                : <Component {...Object.assign({}, props)} />
              :
              <Redirect
                to={{
                  pathname: (otherProps as any).redirect ? (otherProps as any).redirect : '/login',
                  state: { from: otherProps.location?.pathname, alertId: 'user.access-denial' },
                }}
              />
        }
      />
    );
  };

export default PrivateRoute;
