/*                                                                                                                        */
/* A page: Full screen map view which helps user to navigate to the Specimen.                                             */
/* Used only for navigation to a single specimen (not in a quest; for quests, see RouteStopNav).                          */
/* When the distance between user and the stop is less than 10 m, user is asked to confirm visual contact                 */
/* After confirming observation:                                                                                          */
/* >if there is no questStops related to this specimen, the specimen is awarded to the user                               */
/* >if there is questStops related to this specimen,                                                                      */
/*  >>and there is at least one questStop user has not completed, user will be redirect to SpecimenQuiz of that routeStop */
/*  >>and all are completed by the user, user will be redirect to SpecimenQuiz of an random routeStop of this specimen    */

import { gql, useMutation, useQuery } from "@apollo/client";
import TreeMapIcon from "assets/icons/mapping-icons/TreeMapIcon";
import MapNavLayout from "components/Map/MapNavLayout";
import NotificationManager from "components/common/react-notifications/NotificationManager";
import { UserContext } from "contexts/user/UserContextProvider";
import { getPictureLocaleUrl } from "helpers/Utils";
import { getTranslatedString } from "helpers/utils-typescript";
import { SPECIMEN_FRAGMENT } from 'pages/Bioregion/CreateEditSpecimenForm/SpecimenIdentification';
import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { SPECIMENS_TAB } from "../SpecimensTab";
import { MapMarkerNumber } from "pages/Commons/Quest/RouteMap";
import { getOsmTagIconAndName, OsmTagType } from "pages/Mapping/SelectPoi";

const SPECIMEN_BY_SLUG_NAV = gql`
${SPECIMEN_FRAGMENT}
query specimenBySlugNav($slug: String!) {
  specimanBySlug(slug: $slug) {
    ...specimanFragment
  }
  unCompletedRouteStops: routeStops(
    filter: {routeStopUserStatuses: {none: {hasCompleted: {equalTo: true}}}, specimen: {slug: {equalTo: $slug}}}
    first: 1
  ) {
    nodes {
      id
      slug
    }
  }
  allRouteStops:routeStops(
    filter: {specimen: {slug: {equalTo: $slug}}}
  ) {
    nodes {
      id
      slug
    }
    totalCount
  }
}
`;

export const CREATE_USER_SPECIMAN = gql`
mutation createUserSelfObservedSpeciman(
  $specimenId: UUID!
  $userId: UUID!
) {
  createUserSpeciman(
    input: {
      userSpeciman: {
        userId: $userId
        specimenId: $specimenId
        isSelfObserved: true
      }
    }
  ) {
    userSpeciman {
      id
      isSelfObserved
      userId
      specimenId
    }
  }
}
`;

/** Full screen map view which helps user to navigate to a Specimen. Used only for navigation to a single specimen (not in a quest; for quests, see RouteStopNav). */
const SpecimenNav = () => {
  const currentLang = useSelector((state: any) => state.settings.locale);
  const intl = useIntl();
  const history = useHistory();
  const { currentUser } = useContext(UserContext);
  const { slug, commonsSlug } = useParams() as any;
  const { isAuthenticated } = useContext(UserContext);

  /* */
  /* Queries */
  /* */
  const { loading, error, data } = useQuery(SPECIMEN_BY_SLUG_NAV, {
    variables: {
      slug: slug,
      commonsSlug: commonsSlug
    }
  });

  const specimenPicUrl = data?.specimanBySlug.coverPictureUrl
    ? getPictureLocaleUrl(data.specimanBySlug.coverPictureUrl)
    : data?.specimanBySlug.specimenAttachmentsBySpecimenId?.nodes[0]?.url;

  // if user has completed all the quest stops related to this specimen, redirect user to a random quest stop related to this specimen, otherwise redirect user to the first uncompleted quest stop from the query
  const specimenQuizSlug = data?.unCompletedRouteStops.nodes.length === 0
    ? data?.allRouteStops.nodes[Math.floor(Math.random() * data?.allRouteStops.totalCount)]?.slug
    : data?.unCompletedRouteStops.nodes[0]?.slug

  const specimanTerritoryId = data?.territoryBySlug?.id || data?.territoriesByCoordinatesGeometry?.nodes[0]?.id;

  /* */
  /* Mutation */
  /* */
  const [createUserSpeciman] = useMutation(CREATE_USER_SPECIMAN, {
    refetchQueries: specimanTerritoryId && [
      {
        query: SPECIMENS_TAB, variables: {
          territoryId: specimanTerritoryId,
          userId: currentUser.id,
        }
      },
    ]
  });

  /* */
  /* State */
  /* */
  const [confirmObserved, setConfirmObserved] = useState(false);

  /* */
  /* Handle confirm observed */
  /* */
  useEffect(() => {
    if (confirmObserved) {
      const specimenId = data?.specimanBySlug.id;
      // if the specimen is part of >1 route, go to its quiz
      if (data?.allRouteStops.nodes.length !== 0) {
        history.push({ pathname: `/commons/${commonsSlug}/specimen/${slug}/quiz/${specimenQuizSlug}`, state: history.location.state }) // pass along the state 
      }
      else if (isAuthenticated) {// otherwise award immediately for authenticated user
        createUserSpeciman({
          variables: {
            specimenId: specimenId,
            userId: currentUser.id,
            // isSelfObserved: true, // this is hardcoded in the query above; only with isSelfObserved true can a user create a userSpeciman
          }
        }).then(() => {
          NotificationManager.success(
            intl.formatMessage({ id: 'general.awarded' }),
            null,
            3000,
            null,
            null,
            'filled'
          );
          history.push({ pathname: `/commons/${commonsSlug}/specimen/${slug}`, state: history.location.state }) // pass along the state 
        })
      } else { // if specimen is not part of any route, and user is not authenticated, store the observed specimen id in local storage
        const localStorageSpecimenObserved = JSON.parse(localStorage.getItem('specimenObserved') || '[]');
        if (!localStorageSpecimenObserved.includes(specimenId)) {
          localStorageSpecimenObserved.push(specimenId);
        }
        localStorage.setItem('specimenObserved', JSON.stringify(localStorageSpecimenObserved));
        history.push({ pathname: `/specimen/${slug}`, state: history.location.state }) // pass along the state 
      }
    }
  }, [
    confirmObserved, data, commonsSlug, createUserSpeciman,
    currentUser, history, intl, slug, specimenQuizSlug, isAuthenticated
  ])

  /* */
  /* Render */
  /* */
  if (loading) {
    return <div className="loading" />;
  }

  if (error) {
    return <h2>{`ARK had a problem loading data: ${error.message}`}</h2>;
  }

  const markerAndOffset = data.specimanBySlug.type !== 'POI'
    ? {
      marker: <TreeMapIcon size={30} color={data.specimanBySlug.currentUserHasObserved ? '' : 'grey'} />,
      offset: [0, 12] as [number, number]
    }
    : {
      marker: <MapMarkerNumber // this is not really working out with the offset here; the offset should be added as LocationMapCard component
        number={getOsmTagIconAndName(data.specimanBySlug.osmTags as OsmTagType, '30px').icon}
        color='white'
        borderColor='#60b6aa' // ARK green
        width='40px'
        height='40px'
      />,
      offset: [0, -20] as [number, number]
    };

  return (
    <MapNavLayout
      defaultGoBackUrl={`/commons/${commonsSlug}#specimens`}
      destinationCoords={[data.specimanBySlug.coordinates.x, data.specimanBySlug.coordinates.y]}
      destinationMarker={{ marker: markerAndOffset.marker, markerOffset: markerAndOffset.offset }}
      destinationName={getTranslatedString(data.specimanBySlug.name, currentLang)}
      arrivalModalPicUrl={specimenPicUrl}
      setConfirmObserved={setConfirmObserved}
    />
  )
}

export default SpecimenNav;
