import { Fragment } from 'react';

/** Attribution text in corner of the Pigeon Map */
const MapAttribution = () => {
  return (
    <Fragment>
      <a href="https://www.maptiler.com/copyright/" rel="noreferrer" target="_blank">&copy; MapTiler</a> | <a href="https://www.openstreetmap.org/copyright" rel="noreferrer" target="_blank">&copy; OpenStreetMap contributors</a>
    </Fragment>
  );
};

export default MapAttribution;
