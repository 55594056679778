import * as React from "react";
const SvgWaterfall = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:dc": "http://purl.org/dc/elements/1.1/", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:svg": "http://www.w3.org/2000/svg", xmlns: "http://www.w3.org/2000/svg", id: "svg2", viewBox: "0 0 14 14.5", ...props }, /* @__PURE__ */ React.createElement("metadata", { id: "metadata8" }, /* @__PURE__ */ React.createElement("rdf:RDF", null, /* @__PURE__ */ React.createElement("cc:Work", { "rdf:about": "" }, /* @__PURE__ */ React.createElement("dc:format", null, "image/svg+xml"), /* @__PURE__ */ React.createElement("dc:type", { "rdf:resource": "http://purl.org/dc/dcmitype/StillImage" }), /* @__PURE__ */ React.createElement("dc:title", null)))), /* @__PURE__ */ React.createElement("defs", { id: "defs6" }), /* @__PURE__ */ React.createElement("rect", { style: {
  fill: "none",
  stroke: "none",
  visibility: "hidden"
}, id: "canvas", y: 0, x: 0, height: 14, width: 14 }), /* @__PURE__ */ React.createElement("path", { d: "m1 11c0.641 0 1.829 1 2.4 1 0.535 0 1.795-1 2.4-1s1.866 1 2.4 1c0.642 0 1.825-1 2.399-1 0.641-0.029 1.902 1 2.4 1v2c-0.604 0-1.83-1-2.4-1-0.569 0-1.795 1-2.399 1-0.574 0-1.795-1-2.4-1-0.609 0-1.829 1-2.4 1-0.64 0-1.794-1-2.4-1zm0-8c1.094 0 2.001 0.3 2.001 1.75l-1e-3 2.5c0 2 1.5 3 2.5 3 0 0-0.5-1-0.5-3v-2.5c0-0.915-0.649-1.308-1.001-1.75 1.094 0 2.001 0.3 2.001 1.75v2.5c0 2 1.5 3 2.5 3 0 0-0.5-1-0.5-3l0.1-2.5c0.036571-0.91427-0.749-1.308-1.1-1.75 1.093 0 2 0.3 2 1.75v2.5c0 2 1.5 3 2.5 3 0 0-0.5-1-0.5-3v-2.5c0-3-3-3.75-4-3.75h-7l5e-4 2z", style: {
  fill: "#4D80B3",
  fillOpacity: 1,
  stroke: "none"
} }));
export default SvgWaterfall;
