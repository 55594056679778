import { ControlStyle } from 'components/common/SearchForUsers';
import ThumbnailImage from 'components/custom/ThumbnailImage';
import ThumbnailLetters from 'components/custom/ThumbnailLetters';
import { FieldInputProps, FormikProps } from 'formik';
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Select, { MenuPlacement, MenuPosition, MultiValueProps, OptionProps, OptionTypeBase, OptionsType, SingleValueProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { MenuListComponentProps, NoticeProps } from 'react-select/src/components/Menu';

// we could also be using OptionTypeBase everywhere
export interface Option extends OptionTypeBase {
  label: string;
  value: any;
  tag?: string;
  image?: string;
  isDisabled?: boolean;
  icon?: React.ReactNode;
}

interface CustomSelectProps {
  className?: string;
  name: string;
  options?: OptionsType<Option>;
  /** when present, async react-select is used, CustomReactSelect handel debounce internally */
  loadOptions?: OptionsType<Option>;
  /** when present, async react-select is used, handel debounce outside CustomReactSelect */
  debouncedLoadOptions?: any;
  placeholder?: string | React.ReactNode;
  hasIcon?: boolean;
  styles?: any;
  isMulti?: boolean;
  selected?: Option;
  handleChange: Function;
  action?: Function;
  editAction?: Function;
  isDisabled?: boolean;
  menuPlacement?: MenuPlacement;
  menuPortalTarget?: HTMLElement;
  defaultValue?: Option;
  menuPosition?: MenuPosition;
  noOptionsMessageTranslationKey?: string; // translation ID of message that is shown when there are no options to be shown
  isClearable?: boolean; // whether the select can be cleared with a little x button; still need to manually setFieldValue to null
  isCreatable?: boolean;
  onCreateOption?: Function;
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  autoFocus?: boolean;
}

const CustomReactSelect = ({
  className,
  name,
  options,
  debouncedLoadOptions, // when present, async react-select is used
  loadOptions, // when present, async react-select is used
  placeholder,
  styles,
  hasIcon,
  isMulti,
  handleChange,
  selected,
  action,
  editAction,
  isDisabled,
  menuPlacement = 'auto',
  defaultValue,
  menuPortalTarget,
  menuPosition,
  noOptionsMessageTranslationKey, // translation ID of message that is shown when there are no options to be shown
  isClearable = false,
  isCreatable = false,
  onCreateOption,
  form,
  field,
  autoFocus = false,
}: CustomSelectProps): React.ReactElement => {

  const intl = useIntl();

  // filters available options: label must contain typed-in characters
  const filterSelect = (inputValue: string) => {
    return loadOptions?.filter((i) => i.label?.toLowerCase().includes(inputValue.toLowerCase()));
  };

  // seems to be used only to filter options for the async version of react-select
  const promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterSelect(inputValue));
      }, 1000);
    });


  // component shown when there are no options loaded or found
  const NoOptionsMessage = (props: NoticeProps<Option, boolean>) => {
    return (
      <components.NoOptionsMessage {...props}>
        <div>{intl.formatMessage({ id: noOptionsMessageTranslationKey ?? 'general.not-found' })}</div>
      </components.NoOptionsMessage>
    );
  };

  const CustomSelectInput = (props: any) => {
    const customProps = { ...props };
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;
    return <components.Input {...props} />;
  };

  // custom render selected option (only render label by default)
  const CustomSingleValue = (props: SingleValueProps<Option>) => {

    /* if image or icon is present, render image/icon , render initials */
    const imageIconToRender = useMemo(
      () => {
        if (props.data.image
        ) {
          return <ThumbnailImage rounded small src={props.data.image} alt="Thumbnail" style={{ height: '1.5rem' }} />;
        } else if (props.data.icon) {
          return props.data.icon;
        } else { return <ThumbnailLetters rounded small text={props.data.label} style={{ height: '1.5rem', fontSize: '0.8rem' }} />; }
      },
      [props.data.icon, props.data.image, props.data.label]
    );

    return <components.SingleValue {...props} className='d-flex align-items-center'>
      {hasIcon &&
        <span className="mr-1 align-middle">
          {imageIconToRender}
        </span>}
      <span className="truncate">{props.data.label}</span>
      {props.data.tag && (
        <span className="font-weight-light text-semi-muted">&nbsp;&nbsp;&nbsp;{props.data.tag}</span>
      )}
    </components.SingleValue>;
  };

  // custom render selected option (only render label by default)
  const customMultiValue = (props: MultiValueProps<Option>) => {
    return <components.MultiValue {...props} className='d-flex align-items-center'>
      {hasIcon &&
        <span className="mr-1">
          {props.data.image ?
            <ThumbnailImage rounded small src={props.data.image} alt="Profile picture" style={{ height: '1.5rem' }} />
            :
            <ThumbnailLetters rounded small text={props.data.label} style={{ height: '1.5rem', fontSize: '0.8rem' }} />}
        </span>}
      <span className="truncate">{props.data.label}</span>
    </components.MultiValue>;
  };

  // custom render options in dropdown
  const customOption = (props: OptionProps<Option, boolean>) => {
    return (
      <components.Option {...props}>
        { /* to have a disabled option, set isDisabled to true in the option object */}
        <div className={`d-flex flex-noWrap align-items-center ${props.isDisabled && 'select__option--is-disabled'}`}>

          {/* render option icon if specified, for new option, only render plus icon */}
          {isCreatable && props.data.__isNew__
            ? <span className="mr-1"><PlusCircleIcon color='#EFB700' size={30} /></span>
            : hasIcon &&
            <span className="mr-1">
              {/* for new option, render plus icon*/}
              {props.data.value === 'loading'
                ?
                <div className="loading-relative-position my-2 mr-2" />

                : props.data.image ?
                  <ThumbnailImage rounded small src={props.data.image} alt="Profile picture" style={{ height: '1.5rem', filter: props.isDisabled && 'grayscale(100%)' }} />
                  : props.data.icon
                    ? props.data.icon
                    : <ThumbnailLetters rounded small text={props.data.label} style={{ height: '1.5rem', fontSize: '0.8rem', filter: props.isDisabled && 'grayscale(100%)' }} />
              }
            </span>}
          <div className='text-wrap'>{props.data.label}</div>
          {props.data.tag && (
            <div className="font-weight-light text-semi-muted">&nbsp;&nbsp;&nbsp;{props.data.tag}</div>
          )}
          {editAction ? (
            <span className='ml-auto'>
              <Button
                className="inline float-right btn-yellow d-flex"
                size="sm"
                onClick={() => {
                  props.data.value && editAction(props.data.value);
                }}
              >
                <EditOutlineIcon size={10} color="white" className="mr-1" />
              </Button>
            </span>
          ) : null}
        </div>
        {/* </div> */}

      </components.Option>
    );
  };
  const MenuList = (props: MenuListComponentProps<Option, boolean>) => {
    return (
      <components.MenuList {...props} >
        {props.children}
        {action && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="primary" className="mt-2 mb-2" onClick={() => action()}>
              ＋ {intl.formatMessage({ id: 'general.create-new' })}
            </Button>
          </div>
        )}
      </components.MenuList>
    );
  };

  // search filter apply to both tag and label in dropdown option.
  const filterOptions = (option: Option, rawInput: string) => {
    if (option.data.label?.toLowerCase().includes(rawInput.toLowerCase())
      || option.data.tag?.toLowerCase().includes(rawInput.toLowerCase())) {
      return true;
    } else { return false; }
  };

  return (
    <>
      {(loadOptions || debouncedLoadOptions) ? (
        isCreatable ?
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions={options}
            loadOptions={debouncedLoadOptions || promiseOptions}
            components={
              {
                Option: customOption,
                NoOptionsMessage,
                Input: CustomSelectInput,
                MenuList: MenuList,
              }
            }
            className={`select ${className}`}  // there is another class 'react-select' that can be used
            classNamePrefix="select"
            isMulti={isMulti}
            styles={{
              ...styles,
              control: ControlStyle,
            }}
            isInvalid={!!(form?.touched[field.name] && form?.errors[field.name])} // used in ControlStyle
            name={name}
            isClearable={isClearable}
            placeholder={placeholder}
            onChange={(select, actionMeta) => handleChange(select, actionMeta)}
            value={selected}
            isDisabled={isDisabled}
            defaultValue={defaultValue}
            menuPortalTarget={menuPortalTarget}
            menuPosition={menuPosition}
            onCreateOption={(inputValue: string) => onCreateOption(inputValue)}
            blurInputOnSelect={true}
          /> :
          <AsyncSelect
            cacheOptions
            defaultOptions={options}
            loadOptions={debouncedLoadOptions || promiseOptions}
            components={
              {
                Option: customOption,
                NoOptionsMessage,
                Input: CustomSelectInput,
                MenuList: MenuList,
              }
            }
            className={`select ${className}`}  // there is another class 'react-select' that can be used
            classNamePrefix="select"
            isMulti={isMulti}
            styles={{
              ...styles,
              control: ControlStyle,
            }}
            isInvalid={!!(form?.touched[field.name] && form?.errors[field.name])} // used in ControlStyle
            name={name}
            isClearable={isClearable}
            placeholder={placeholder}
            onChange={(select, actionMeta) => handleChange(select, actionMeta)}
            value={selected}
            isDisabled={isDisabled}
            defaultValue={defaultValue}
            menuPortalTarget={menuPortalTarget}
            menuPosition={menuPosition}
            blurInputOnSelect={true}
          />
      ) : (
        isCreatable ?
          <CreatableSelect
            components={
              {
                Option: customOption,
                NoOptionsMessage,
                Input: CustomSelectInput,
                MenuList: MenuList,
                SingleValue: CustomSingleValue,
                MultiValue: customMultiValue,
              }
            }
            className={`select ${className}`}  // there is another class 'react-select' that can be used
            classNamePrefix="select"
            options={options}
            isMulti={isMulti}
            styles={{
              ...styles,
              control: ControlStyle,
            }}
            isInvalid={!!(form?.touched[field.name] && form?.errors[field.name])} // used in ControlStyle
            name={name}
            value={selected}
            isClearable={isClearable}
            placeholder={placeholder}
            onChange={(select, actionMeta) => handleChange(select, actionMeta)}
            isDisabled={isDisabled}
            menuPlacement={menuPlacement}
            filterOption={filterOptions}
            defaultValue={defaultValue}
            menuPortalTarget={menuPortalTarget}
            menuPosition={menuPosition}
            onCreateOption={(inputValue: string) => onCreateOption(inputValue)}
            blurInputOnSelect={true}
          />
          : <Select
            components={
              {
                Option: customOption,
                NoOptionsMessage,
                Input: CustomSelectInput,
                MenuList: MenuList,
                SingleValue: CustomSingleValue,
                MultiValue: customMultiValue,
              }
            }
            className={`select ${className}`}  // there is another class 'react-select' that can be used
            classNamePrefix="select"
            options={options}
            isMulti={isMulti}
            styles={{
              ...styles,
              control: ControlStyle,
            }}
            isInvalid={!!(form?.touched[field.name] && form?.errors[field.name])} // used in ControlStyle
            name={name}
            isClearable={isClearable}
            placeholder={placeholder}
            onChange={(select, actionMeta) => handleChange(select, actionMeta)}
            blurInputOnSelect={true}
            value={selected}
            isDisabled={isDisabled}
            menuPlacement={menuPlacement}
            filterOption={filterOptions}
            defaultValue={defaultValue}
            menuPortalTarget={menuPortalTarget}
            menuPosition={menuPosition}
            autoFocus={autoFocus}
          />
      )}
    </>
  );
};

export default CustomReactSelect;
