import React from 'react';
import IconProps from 'components/LearningPathsIcons/IconProps';

const MappingToolIcon = ({ id, style, size, className, colorsCombo = ['#60B6AA', '#1E525C'] }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      height={size}
      width={size}
      className={className}
      id={id}
    >
      <style type="text/css">
        {`
        .mappingToolIcon-outerInnerLayer-${id}{fill:${colorsCombo[0]};}
        .mappingToolIcon-middleLayer-${id}{fill:${colorsCombo[1]};}`
        }
      </style>
      <g>
        <g>
          <path className={`mappingToolIcon-middleLayer-${id}`} d="M969.7,470.4H719.3c-0.4-2.7-0.8-5.4-1.2-8c-0.5-2.7-0.9-5.3-1.5-8c-1-5-2.2-9.9-3.6-14.8
			c-10.1-36-29.4-69.1-56.6-96.2c-27.8-27.8-61.7-47.3-98.7-57.3c-4.1-1.1-8.2-2.1-12.3-2.9c-2.6-0.6-5.3-1.1-8-1.5
			c-2.6-0.5-5.3-0.9-8-1.2V30c0-16.3-13.3-29.6-29.6-29.6C483.3,0.4,470,13.6,470,30v250.4c-2.7,0.4-5.4,0.8-8,1.2
			c-2.7,0.5-5.4,0.9-8,1.5c-4.1,0.9-8.2,1.8-12.3,2.9c-37,9.9-70.9,29.4-98.7,57.3c-27.2,27.2-46.4,60.2-56.6,96.2
			c-1.4,4.9-2.6,9.8-3.6,14.8c-0.6,2.7-1.1,5.3-1.5,8c-0.5,2.7-0.9,5.3-1.2,8H29.7C13.3,470.4,0,483.6,0,500
			c0,16.4,13.3,29.7,29.7,29.7H280c0.3,2.7,0.8,5.3,1.2,8c0.5,2.7,1,5.3,1.5,8c0.7,3.3,1.5,6.6,2.3,9.8c9.7,38,29.5,72.7,57.9,101.2
			c27.8,27.8,61.7,47.3,98.7,57.3c4.1,1.1,8.2,2.1,12.3,2.9c2.6,0.6,5.3,1.1,8,1.5c2.6,0.5,5.3,0.9,8,1.2V970
			c0,16.3,13.3,29.7,29.7,29.7c16.3,0,29.6-13.3,29.6-29.7V719.6c2.7-0.3,5.4-0.8,8-1.2c2.7-0.5,5.4-1,8-1.5
			c4.1-0.9,8.2-1.8,12.3-2.9c37-9.9,70.9-29.5,98.7-57.3c28.4-28.4,48.2-63.2,57.9-101.2c0.8-3.2,1.6-6.5,2.3-9.8
			c0.6-2.7,1.1-5.3,1.5-8c0.5-2.7,0.9-5.3,1.2-8h250.4c16.4,0,29.7-13.3,29.7-29.7C999.3,483.6,986,470.4,969.7,470.4z M557.6,643
			c-11.5,4.7-23.6,8-36.3,9.8c-7.1,1-14.3,1.5-21.7,1.5c-7.4,0-14.6-0.5-21.7-1.5c-12.7-1.8-24.8-5.1-36.3-9.8
			c-39.3-16-70.6-47.8-86-87.5c-4.4-11.4-7.5-23.4-9.1-35.9c-0.8-6.4-1.2-12.9-1.2-19.6c0-6.6,0.4-13.2,1.2-19.5
			c1.8-14.3,5.6-28,11.1-40.9c16-37.4,46.3-67.2,84-82.5c11.9-4.9,24.6-8.2,37.8-10c6.6-0.9,13.3-1.3,20.2-1.3
			c6.8,0,13.6,0.4,20.2,1.3c13.2,1.7,25.9,5.1,37.8,10c37.7,15.3,68,45.2,83.9,82.5c3.9,9.2,7,18.8,9.1,28.8
			c0.7,3.4,1.3,6.9,1.8,10.4c0.9,6.9,1.4,14,1.4,21.2s-0.5,14.3-1.4,21.2c-0.5,3.5-1.1,7-1.8,10.4c-1.7,8.2-4.1,16.2-7.1,23.9
			C628.2,595.2,597,626.9,557.6,643z"/>
        </g>
      </g>
      <g>
        <g>
          <path className={`mappingToolIcon-outerInnerLayer-${id}`} d="M441.7,123.6v57.6c0,4.5-3.1,8.3-7.5,9.2c-121.2,25.6-217,120.8-243.6,241.7c-1,4.3-4.8,7.4-9.2,7.4h-57.7
			c-5.9,0-10.3-5.4-9.3-11.2c5.1-27.7,13.2-54.7,24.3-80.9c19.7-46.6,48-88.5,83.9-124.5c36-36,77.8-64.2,124.5-83.9
			c27-11.4,54.9-19.7,83.4-24.8C436.4,113.3,441.7,117.8,441.7,123.6z"/>
          <path className={`mappingToolIcon-outerInnerLayer-${id}`} d="M875.6,439.6h-57.7c-4.4,0-8.3-3.1-9.2-7.4c-26.5-120.9-122.3-216.1-243.5-241.7c-4.4-0.9-7.5-4.8-7.5-9.2
			v-57.6c0-5.9,5.3-10.3,11.1-9.3c28.6,5.1,56.5,13.3,83.4,24.8c46.6,19.7,88.5,48,124.5,83.9c36,36,64.2,77.8,83.9,124.5
			c11.1,26.2,19.2,53.2,24.3,80.9C885.9,434.2,881.5,439.6,875.6,439.6z"/>
        </g>
      </g>
      <g>
        <g>
          <path className={`mappingToolIcon-outerInnerLayer-${id}`} d="M557.6,876.4v-57.6c0-4.5,3.1-8.3,7.5-9.2c121.2-25.6,217-120.8,243.6-241.7c1-4.3,4.8-7.4,9.2-7.4h57.7
			c5.9,0,10.3,5.4,9.3,11.2c-5.1,27.7-13.2,54.7-24.3,80.9c-19.7,46.6-48,88.5-83.9,124.5c-36,36-77.8,64.2-124.5,83.9
			c-27,11.4-54.9,19.7-83.4,24.8C562.9,886.7,557.6,882.2,557.6,876.4z"/>
          <path className={`mappingToolIcon-outerInnerLayer-${id}`} d="M123.7,560.4h57.7c4.4,0,8.3,3.1,9.2,7.4C217.1,688.7,313,783.9,434.2,809.5c4.4,0.9,7.5,4.8,7.5,9.2v57.6
			c0,5.9-5.3,10.3-11.1,9.3c-28.6-5.1-56.5-13.3-83.4-24.8c-46.6-19.7-88.5-48-124.5-83.9c-36-36-64.2-77.8-83.9-124.5
			c-11.1-26.2-19.2-53.2-24.3-80.9C113.4,565.8,117.8,560.4,123.7,560.4z"/>
        </g>
      </g>
      <g>
        <g>
          <path className={`mappingToolIcon-outerInnerLayer-${id}`} d="M547.8,500c0,26.5-21.6,48.1-48.1,48.1s-48.1-21.6-48.1-48.1c0-26.5,21.6-48.1,48.1-48.1
			S547.8,473.5,547.8,500z"/>
        </g>
      </g>
    </svg>
  );
};

export default MappingToolIcon;
