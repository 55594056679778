import amphibian from 'assets/icons/commonsIcon/speciesLogo/amphibian.svg';
import bird from 'assets/icons/commonsIcon/speciesLogo/bird.svg';
import centipede from 'assets/icons/commonsIcon/speciesLogo/centipede.svg';
import crustacean from 'assets/icons/commonsIcon/speciesLogo/crustacean.svg';
import fish from 'assets/icons/commonsIcon/speciesLogo/fish.svg';
import fungus from 'assets/icons/commonsIcon/speciesLogo/fungus.svg';
import grass from 'assets/icons/commonsIcon/speciesLogo/grass.svg';
import herb from 'assets/icons/commonsIcon/speciesLogo/herb.svg';
import insect from 'assets/icons/commonsIcon/speciesLogo/insect.svg';
import mammal from 'assets/icons/commonsIcon/speciesLogo/mammal.svg';
import mollusk from 'assets/icons/commonsIcon/speciesLogo/mollusk.svg';
import reptile from 'assets/icons/commonsIcon/speciesLogo/reptile.svg';
import shrub from 'assets/icons/commonsIcon/speciesLogo/shrub.svg';
import spider from 'assets/icons/commonsIcon/speciesLogo/spider.svg';
import tree from 'assets/icons/commonsIcon/speciesLogo/tree.svg';
import worm from 'assets/icons/commonsIcon/speciesLogo/worm.svg';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUiTranslation } from '../helpers/utils-typescript';

enum SpeciesType {
  AMPHIBIAN = 'AMPHIBIAN',
  BIRD = 'BIRD',
  CENTIPEDE = 'CENTIPEDE',
  CRUSTACEAN = 'CRUSTACEAN',
  FISH = 'FISH',
  FUNGUS = 'FUNGUS',
  GRASS = 'GRASS',
  HERB = 'HERB',
  INSECT = 'INSECT',
  MAMMAL = 'MAMMAL',
  MOLLUSK = 'MOLLUSK',
  REPTILE = 'REPTILE',
  SHRUB = 'SHRUB',
  SPIDER = 'SPIDER',
  TREE = 'TREE',
  WORM = 'WORM'
}

export const speciesIconSrc = {
  'amphibian': amphibian,
  'bird': bird,
  'centipede': centipede,
  'crustacean': crustacean,
  'fish': fish,
  'fungus': fungus,
  'grass': grass,
  'herb': herb,
  'insect': insect,
  'mammal': mammal,
  'mollusk': mollusk,
  'reptile': reptile,
  'shrub': shrub,
  'spider': spider,
  'tree': tree,
  'worm': worm,
};

export type SpeciesCategoryOption = {
  label: string;
  value: SpeciesType;
  image: any;
};

const useSpeciesCategoryOptions = (showAllCategory = false) => {
  const currentLang = useSelector((state: any) => state.settings.locale);

  /* */
  /* Options for Species Category dropdowns */
  /* */
  const speciesCategoryOptions = useMemo(() => {
    const defaultCategoryList = Object.values(SpeciesType).map(
      (species) => ({
        label: getUiTranslation(`species.${species}`, currentLang),
        value: species,
        image: speciesIconSrc[species.toLowerCase()],
      } as SpeciesCategoryOption)
    );

    if (showAllCategory) {
      return [{ 'label': getUiTranslation('species.ALL', currentLang), 'value': null, 'image': null }, ...defaultCategoryList];
    }
    return defaultCategoryList;
  }, [currentLang, showAllCategory]);

  return speciesCategoryOptions;
};

export default useSpeciesCategoryOptions;
