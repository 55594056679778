import { FetchResult, gql, useMutation, useQuery } from '@apollo/client';
import SpecimensIcon from 'assets/icons/commonsIcon/specimens_icon.svg?react';
import MultilingualText from 'components/Multilingual/MultilingualText';
import NextPreviousButton from 'components/common/NextPreviousButton';
import LeavesIcon from 'components/svg/LeavesIcon';
import { UserContext } from 'contexts/user/UserContextProvider';
import { CREATE_USER_SPECIMAN } from 'pages/Bioregion/Specimen/SpecimenNav';
import { CREATE_ROUTE_STOP_USER_STATUS, UPDATE_ROUTE_STOP_USER_STATUS } from 'pages/Commons/Quest/queries';
import { QuizProgressLocalStorage, QuizProgressLocalStorageRoute } from 'pages/Commons/Quest/useLocalStorageRouteData';
import { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Route } from 'services/graphqltypes';

/** Checks if there are self-guided routes stops statuses saved in local storage; if yes, store to database, and show info modal about it. */
const RoutesProgressSaved = () => {
  const { currentUser } = useContext(UserContext);

  const localStorageQuizProgress = useRef(JSON.parse(localStorage.getItem('quizProgress')) ?? [] as QuizProgressLocalStorage);
  const effectHasRun = useRef(false); // flag to prevent useEffect from running again when routesUserStatusData is updated by cache

  /* */
  /* State */
  /* */
  const [showModal, setShowModal] = useState(true);
  const [updatedRoutes, setUpdatedRoutes] = useState<{ // each object in the array represents a single route whose stops were present in local storage and have been saved to the database
    routeId: string,
    specimenCount: number,
    impactPointsCount: number,
    stopsId: string[],
    routeName: JSON,
  }[]>([]);

  /* */
  /* Query */
  /* */
  // create query string based on the routes in localStorage
  // query for each route in localStorage, so that we know what mutations have to run
  const ROUTES_USER_STATUS = () => {
    if (localStorageQuizProgress.current.length === 0) {
      return gql`
      query DummyQuery {
        __typename
      }`;
    }

    return gql`
      query routesUserStatus {
        ${localStorageQuizProgress.current.map((route: QuizProgressLocalStorageRoute, index: number) => {
      return `route_${index}:route(id: "${route.routeId}") {
            id
            name
            routeStops {
              nodes {
                id
                currentUserHasCompleted
                currentUserTries
                impactPoints
                specimenId
              }
            }
          }`;
    })}
      }
    `;
  };

  const { loading, data: routesUserStatusData } = useQuery(ROUTES_USER_STATUS(), { skip: (localStorageQuizProgress.current.length === 0) });

  /* */
  /* Mutations */
  /* */
  const [createRouteStopUserStatus] = useMutation(CREATE_ROUTE_STOP_USER_STATUS);
  const [updateRouteStopUserStatus] = useMutation(UPDATE_ROUTE_STOP_USER_STATUS);
  const [createUserSpeciman] = useMutation(CREATE_USER_SPECIMAN);

  // for each route stop status in local storage, 
  //     run a createRouteStopUserStatus mutation if it is not yet saved in the db
  //     run an updateRouteStopUserStatus mutation if it has been saved in the db
  // for each specimen in localStorageSpecimenObserved,
  //     run a createUserSpeciman mutation if userSpeciman will not be created by the RouteStopUserStatus Mutation above.
  useEffect(() => {
    if (!loading && !effectHasRun.current) {
      effectHasRun.current = true
      const localStorageSpecimenObserved = (JSON.parse(localStorage.getItem('specimenObserved')) ?? []) as string[];

      // create array of mutations promises
      const createRouteStopUserStatusMutations: Promise<FetchResult<any>>[] = [];
      const updateRouteStopUserStatusMutations: Promise<FetchResult<any>>[] = [];
      const createUserSpecimanMutation: Promise<FetchResult<any>>[] = [];

      routesUserStatusData && localStorageQuizProgress.current.forEach(
        (localStorageRoute: QuizProgressLocalStorageRoute) => {
          // find the corresponding route in the query result
          const routeFromQuery = Object.values(routesUserStatusData).find((route: any) =>
            route.id === localStorageRoute.routeId) as Route;

          localStorageRoute.stopsStatus.forEach((stopStatus) => {
            const stopFromQuery = routeFromQuery.routeStops.nodes.find((stop) => stop.id === stopStatus.stopId);
            if (stopStatus.status) {
              // remove the specimen from localStorageSpecimenObserved array, so that we don't double create userSpecimen
              localStorageSpecimenObserved.splice(localStorageSpecimenObserved.indexOf(stopFromQuery.specimenId), 1);
              // if user has not completed this stop and saved in the database in the past, add into create mutation array
              if (!stopFromQuery.currentUserHasCompleted) {
                // preparing date to show in Modal
                setUpdatedRoutes((prevState) => {
                  const updatedState = [...prevState];
                  const routeToUpdateIndex = updatedState.findIndex((route) => route.routeId === localStorageRoute.routeId);
                  if (routeToUpdateIndex !== -1) {
                    if (!updatedState[routeToUpdateIndex].stopsId.includes(stopStatus.stopId)) {
                      updatedState[routeToUpdateIndex].stopsId.push(stopStatus.stopId);
                      updatedState[routeToUpdateIndex].specimenCount += 1;
                      updatedState[routeToUpdateIndex].impactPointsCount += stopFromQuery.impactPoints;
                    }
                  } else {
                    updatedState.push({
                      routeId: localStorageRoute.routeId,
                      routeName: routeFromQuery.name as JSON,
                      specimenCount: 1,
                      impactPointsCount: stopFromQuery.impactPoints,
                      stopsId: [stopStatus.stopId],
                    });
                  }
                  return updatedState;
                });
                // update mutation array
                createRouteStopUserStatusMutations.push(createRouteStopUserStatus({
                  variables: {
                    routeStopId: stopStatus.stopId,
                  }
                }));
              } else {
                // if user has completed this stop and saved in the database in the past, add into update mutation array
                // updateRouteStopUserStatusMutations.push(updateRouteStopUserStatus(
                //   {
                //     variables: {
                //       routeStopId: stopStatus.stopId,
                //       createdByUserId: currentUser.id,
                //       tries: stopFromQuery.currentUserTries + 1
                //     }
                //   }
                // ));
              }
            }
          }
          );
        });

      // update mutation array  
      localStorageSpecimenObserved.forEach((specimenId) => {
        createUserSpecimanMutation.push(
          createUserSpeciman({
            variables: {
              specimenId,
              userId: currentUser.id,
            },
          })
        );
      });

      const mutations = [...createRouteStopUserStatusMutations, ...createUserSpecimanMutation, ...updateRouteStopUserStatusMutations];
      mutations.length > 0 && Promise.all(mutations).then((res) => {
        if (res) {
          // reset localStorage
          localStorage.removeItem('quizProgress');
          localStorage.removeItem('specimenObserved');
        }
      }).catch((error: any) => {
        if (error) {
          console.error('progress save error', error); // we intentionally skip user notification for the error
        }
      });
    }
  }, [loading, routesUserStatusData, createRouteStopUserStatus, createUserSpeciman, updateRouteStopUserStatus, currentUser?.id]);

  console.log('RoutesProgressSaved component rendered');
  console.log('routesUserStatusData', routesUserStatusData);
  /* */
  /* Render */
  /* */
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop='static' /* prevent closing modal when clicked outside it*/
      size='lg'
      className='rounded-modal'
    >
      <Modal.Header closeButton className='mr-3 mt-3 p-0' />
      <Modal.Body className='pt-0 mt-0 text-center'>
        {localStorageQuizProgress.current.length > 0
          ?
          loading
            ? <div className="loading-relative-position my-2" />
            : <div className='my-2'>
              <h1 className='text-center'>Your progress has been saved.</h1>
              {updatedRoutes.map((route, index) => {
                return (
                  <div className='text-center' key={index}>
                    <span className='font-weight-bold'>
                      <MultilingualText value={route.routeName} />:
                    </span>
                    <span className='ml-1'>
                      +{route.impactPointsCount} <LeavesIcon color='#60b6aa' size={25} className='ml-1 my-auto' /> +{route.specimenCount} <SpecimensIcon width={20} height={30} stroke="#60b6aa" className='ml-1 my-auto' />
                    </span>
                  </div>
                );
              })}
            </div>
          : null
        }

        <NextPreviousButton
          onClick={() => setShowModal(false)}
          buttonTextId='general.close'
        />

      </Modal.Body>
    </Modal>
  );
};

export default RoutesProgressSaved;
