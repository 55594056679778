import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { isFloat } from '../helpers/utils-typescript';
import { User } from 'services/graphqltypes';

const BIOREGION_KNOWLEDGE_STATS = gql`
  query bioregionKnowledgeStats(
    $bioregionGid: Int
    $userId: UUID!
    $territoryId: UUID
  ) {
    user(id: $userId) {
      id
      userSpecimen(
        filter: { specimen: { territoryId: { equalTo: $territoryId }, type: {in: [TREE, GROUP_OF_TREES] } } }
      ) {
        aggregates {
          distinctCount {
            specimenId
          }
        }
      }
      userPoi: userSpecimen(
        filter: { specimen: { territoryId: { equalTo: $territoryId }, type: {in: [POI] } } }
      ) {
        aggregates {
          distinctCount {
            specimenId
          }
        }
      }
      userSpecieses(
        filter: { species: { bioregionGid: { equalTo: $bioregionGid } } }
      ) {
        aggregates {
          distinctCount {
            speciesId
          }
        }
      }
    }
    specimen(
      filter: {
        type: {in: [TREE, GROUP_OF_TREES] }
        status: { equalTo: APPROVED_PUBLISHED }
        territoryId: { equalTo: $territoryId }
      }
    ) {
      totalCount
    }
    poi: specimen(
      filter: {
        type: {in: [POI] }
        status: { equalTo: APPROVED_PUBLISHED }
        territoryId: { equalTo: $territoryId }
      }
    ) {
      totalCount
    }
    specieses(
      filter: {
        bioregionGid: { equalTo: $bioregionGid }
        isPublished: { equalTo: true }
      }
    ) {
      totalCount
    }
  }
`;

interface BioregionalKnowledgeStats {
  speciesTotalCount: number;
  specimenTotalCount: number;
  poiTotalCount: number;
  userSpeciesTotalCount: number;
  userSpecimenTotalCount: number;
  userPoiTotalCount: number;
}

const useBioregionalknowledgeStats = ({ user }: { user: User }) => {
  const { loading, error, data } = useQuery(BIOREGION_KNOWLEDGE_STATS, {
    variables: {
      territoryId: user?.currentTerritory?.id,
      bioregionGid: user?.bioregionGid,
      userId: user?.id
    },
    skip: !user?.bioregionGid && !user?.id
  });
  const handleError = useErrorHandler();

  const bioregionKnowlegeStats = useMemo(() => {
    const bioregionStats: BioregionalKnowledgeStats = {
      speciesTotalCount: 0, specimenTotalCount: 0,
      userSpeciesTotalCount: 0, userSpecimenTotalCount: 0,
      userPoiTotalCount: 0, poiTotalCount: 0
    };

    if (loading) {
      return bioregionStats;
    }
    if (error) {
      handleError(error);
      return bioregionStats;
    }

    bioregionStats.specimenTotalCount = data?.specimen?.totalCount;
    bioregionStats.poiTotalCount = data?.poi?.totalCount;
    bioregionStats.speciesTotalCount = data?.specieses?.totalCount;
    bioregionStats.userSpecimenTotalCount = Number(data?.user?.userSpecimen?.aggregates?.distinctCount?.specimenId);
    bioregionStats.userPoiTotalCount = Number(data?.user?.userPoi?.aggregates?.distinctCount?.specimenId);
    bioregionStats.userSpeciesTotalCount = Number(data?.user?.userSpecieses?.aggregates?.distinctCount?.speciesId);

    return bioregionStats;
  }, [error, handleError, loading, data]);

  const speciesPercentage = useMemo(() => {
    const { userSpeciesTotalCount, speciesTotalCount } = bioregionKnowlegeStats;
    const percentage = (userSpeciesTotalCount / speciesTotalCount) * 100;
    if (isNaN(percentage)) {
      return 0;
    } // progress bar will show animation like effect if return 0 when there is no data.
    return isFloat(percentage) ? percentage.toFixed(1) : percentage;
  }, [bioregionKnowlegeStats]);

  const specimenPercentage = useMemo(() => {
    const { userSpecimenTotalCount, specimenTotalCount } = bioregionKnowlegeStats;
    const percentage = (userSpecimenTotalCount / specimenTotalCount) * 100;
    if (isNaN(percentage)) {
      return 0;
    } // progress bar will show animation like effect if return 0 when there is no data.
    return isFloat(percentage) ? percentage.toFixed(1) : percentage;
  }, [bioregionKnowlegeStats]);

  const poiPercentage = useMemo(() => {
    const { userPoiTotalCount, poiTotalCount } = bioregionKnowlegeStats;
    const percentage = (userPoiTotalCount / poiTotalCount) * 100;
    if (isNaN(percentage)) {
      return 0;
    } // progress bar will show animation like effect if return 0 when there is no data.
    return isFloat(percentage) ? percentage.toFixed(1) : percentage;
  }, [bioregionKnowlegeStats]);

  return { loading, error, bioregionKnowlegeStats, speciesPercentage, specimenPercentage, poiPercentage };

};

export default useBioregionalknowledgeStats;
