import React, { useCallback, useState } from 'react';
import IntlMessages from 'helpers/IntlMessages';
import { CardImg } from 'reactstrap';
import { Formik, FormikValues } from 'formik';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { EDIT_USER_PROFILE_NAME } from 'services/user';
import { OnboardingProps } from './Onboarding';
import * as Yup from 'yup';
import CustomSpinner from 'components/common/CustomSpinner';
import { useMutation } from '@apollo/client';

const WelcomeOnBoard = ({ userInfo, modalState }: OnboardingProps): React.ReactElement => {
  const intl = useIntl();
  const [clickedSubmit, setClickedSubmit] = useState(false);

  /* */
  /* Queries and mutations */
  /* */
  const [updateUserName] = useMutation(EDIT_USER_PROFILE_NAME);

  /* validation schema */
  const validationSchema = Yup.object().shape(
    {
      name: Yup.string().required(intl.formatMessage({ id: 'general.required' })).min(2, intl.formatMessage({ id: 'general.too-short' })),
    });

  const handleSubmitForm = useCallback((values: FormikValues) => {
    try {
      updateUserName({ variables: { name: values.name, id: userInfo.currentUser.id } }).then(() => {
        modalState.setShowModal(false);
      });
    } catch (e) {
      console.log('Error updating user\'s name', e);
    }
  }, [modalState, updateUserName, userInfo]);

  return (
    <div className="wizard-basic-step">
      <div className="d-flex justify-content-center mt-3 mb-1">
        <h1 className="mt-4 font-weight-bolder accent-color text-uppercase">
          <IntlMessages id="onboarding.welcome" />
        </h1>
      </div>
      <div className="d-flex justify-content-center">
        <CardImg
          src="/assets/img/welcome_on_board_logo.png"
          alt="welcome on board image"
          className="w-50 w-sm-100"
        />
      </div>
      <div className="mx-2 mx-xs-5 mt-4">
        <Formik
          initialValues={{ name: '', }}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
        >
          {({ errors, handleSubmit, isValid, dirty, handleBlur, handleChange, values, isSubmitting }) => (
            <Form
              className={`needs-validation ${dirty && isValid ? 'was-validated' : ''}`}
              onSubmit={handleSubmit}
              noValidate>
              <FormGroup className="has-float-label" style={{ position: 'relative' }}>
                <Form.Label>
                  <IntlMessages id="onboarding.how-to-call-user" />
                </Form.Label>
                <input
                  className={`form-control ${clickedSubmit && errors.name ? 'is-invalid' : ''}`}
                  name="name"
                  id="name"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                />
                {clickedSubmit && errors.name && (
                  <div className="invalid-feedback d-inline" >{errors.name}</div>
                )}
              </FormGroup>
              <div className="justify-content-center wizard-buttons mt-4">
                <Button
                  color="primary"
                  type='submit'
                  disabled={isSubmitting || (clickedSubmit && !isValid)}
                  onClick={() => { !isSubmitting && setClickedSubmit(true); }}>
                  {isSubmitting ?
                    <CustomSpinner />
                    :
                    <IntlMessages id="onboarding.start" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </div>
  );
};
export default WelcomeOnBoard;
