import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Ginko = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="ginko" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7"  xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {` 
	        .circle-ginko{fill:#FFFFFF;stroke:#60B6AA;stroke-miterlimit:10;}
            .path-ginko{fill:#60B6AA;} 
        `}
      </style>
      <circle className="circle-ginko" cx="16.3" cy="16.3" r="15.7" />
      <path className="path-ginko" d="M6.5,18.6c-0.7,0-0.7-0.5-0.8-1c-0.1-0.5-0.3-1.1-0.1-1.6c0.2-0.7,0.3-0.9,1.1-0.7c0.1,0,0.3,0,0.5,0
	c-0.4-0.1-0.8-0.3-1.2-0.4c-0.4-0.1-0.6-0.4-0.5-0.8c0-0.2,0-0.4,0-0.7c-0.1-0.6,0.2-1.4,0.8-1.6c0.5-0.1,0.8-0.3,1.1-0.7
	c0-0.1,0.3-0.1,0.4,0c0.4,0.2,0.8,0.3,1.2,0.6c0.7,0.4,1.4,0.8,2,1.2c1,0.7,2,1.4,3.1,2.2c0.2,0.1,0.4,0.3,0.7,0.2
	c-0.2-0.2-0.3-0.4-0.5-0.5c-1.5-1.2-2.7-2.6-3.7-4.2c-0.2-0.3-0.3-0.6-0.5-0.8c-0.6-0.9-0.2-2.4,1.2-2.7c0.2,0,0.4,0,0.6,0.2
	c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0.1,0,0.1,0c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.5,0.1-0.6,0.5-0.8
	c0.7-0.4,1.3-0.3,2,0c0.2,0.1,0.4,0.3,0.6,0.4C16,6.7,16,6.8,16.1,6.8c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0-0.1
	c0.1-0.7,0.7-0.9,1.3-0.5c0.5,0.3,1.1,0.4,1.6,0.2c0.4-0.1,0.7,0,0.8,0.3c0.2,0.5,0.7,0.6,1.1,0.7c0.2,0.1,0.4,0.2,0.5,0.3
	c0.2,0.1,0.4,0.2,0.5,0.2c1.1,0.1,1.5,0.8,1,1.8c-0.1,0.3-0.3,0.6-0.6,0.9c-0.9,1-1.9,2-2.8,3c-0.6,0.7-1.2,1.4-1.6,2.3
	c-0.3,0.9-0.2,1.7,0.3,2.4c1.1,1.6,2.3,3.3,3.4,4.9c0.7,1,1.5,2,2.3,2.9c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.3-0.4,0.3-0.6,0.3
	c-0.3,0-0.3-0.2-0.4-0.4c-0.1-0.3-0.3-0.6-0.5-0.9c-1.4-2.2-2.9-4.3-4.6-6.3c-0.2-0.2-0.3-0.4-0.5-0.5c-0.4-0.4-0.9-0.5-1.4-0.3
	c-1,0.3-1.8,0.9-2.5,1.6c-1.2,1.3-2.3,2.7-3.5,4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0.2-0.7,0.2-1-0.1c-0.3-0.3-0.7-0.6-0.8-1.1
	c0-0.2-0.3-0.3-0.4-0.4C7.1,22.6,7,22.1,7,21.5c0-0.2,0-0.4-0.3-0.4c-0.1,0-0.1,0-0.2-0.1C6.2,20.5,6,19.9,6,19.2
	c0-0.2,0.3-0.3,0.4-0.4C6.4,18.7,6.5,18.7,6.5,18.6z"/>
    </svg>
  );
};

export default Ginko;
