import { Position, Viewport, viewport } from "@placemarkio/geo-viewport";
import { BBox } from 'geojson';
import TreeMapIcon from "assets/icons/mapping-icons/TreeMapIcon";
import MapAttribution from "components/Map/MapAttribution";
import InfoModal from "components/Modal/InfoModal";
import ContextHelp from "components/common/ContextHelp";
import DesignByListItem from "components/common/DesignByListItem";
import InputSearchFilter, { SearchInputField } from "components/common/InputSearchFilter";
import NotificationManager from "components/common/react-notifications/NotificationManager";
import { Option } from "components/custom/CustomReactSelect";
import { UserContext } from "contexts/user/UserContextProvider";
import IntlMessages from "helpers/IntlMessages";
import { getTranslatedString } from "helpers/utils-typescript";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import CircleSlice8Icon from "mdi-react/CircleSlice8Icon";
import CrosshairsGpsIcon from "mdi-react/CrosshairsGpsIcon";
import FilterVariantIcon from "mdi-react/FilterVariantIcon";
import MapLegendIcon from "mdi-react/MapLegendIcon";
import ViewGridOutlineIcon from "mdi-react/ViewGridOutlineIcon";
import SpecimenCard, { SpecimanCardProp } from 'pages/Bioregion/SpecimenCard';
import { MapMarkerNumber } from "pages/Commons/Quest/RouteMap";
import { culturePoiValues, getOsmTagIconAndName, naturePoiValues, OsmTagType, usePoisOptions } from "pages/Mapping/SelectPoi";
import { statusTagColorsForLearner, statusTagColorsForMentor } from "pages/Commons/CommonsCard";
import { GeoJson, Map, Marker, Overlay } from 'pigeon-maps';
import { maptiler } from 'pigeon-maps/providers';
import React, { Fragment, ReactElement, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Badge, Collapse, Dropdown } from "react-bootstrap";
import * as geolocated from 'react-geolocated';
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Speciman, User } from "services/graphqltypes";

type ViewType = 'all' | 'noCommons';

const ViewTypeSwitchButton = ({ viewType, setViewType, switchValues, isDisabled }: {
  viewType: ViewType,
  setViewType: React.Dispatch<SetStateAction<ViewType>>,
  switchValues: [
    { value: ViewType, label: string | ReactElement },
    { value: ViewType, label: string | ReactElement }
  ],
  isDisabled?: boolean,
}) => {

  return (
    <button className={`btn p-0 ml-auto my-auto ${isDisabled && 'disabled'}`}
      style={{
        borderColor: isDisabled ? '#8f8f8f' : '#60b6aa',
      }}
    >
      <div className={`flex-nowrap d-flex btn-group-toggle ${isDisabled && 'disabled'}`} data-toggle="buttons">
        {switchValues.map((switchValue) => {
          const labelColorClassName = switchValue.value === viewType
            ? isDisabled ? 'btn-dark' : 'btn-primary'
            : isDisabled ? 'btn-link text-muted' : 'btn-link'

          return (
            <label
              className={`btn px-2 ${labelColorClassName}`}
              style={{
                transition: 'width 0.5s ease-in-out, color 0.5s ease-in-out',
                minWidth: '2.5rem',
                cursor: isDisabled ? 'auto' : 'pointer'
              }}
            >
              <input
                disabled={isDisabled}
                type="radio"
                name="viewType"
                id={switchValue.value}
                value={switchValue.value}
                onClick={() => { setViewType(switchValue.value); }}
              />
              {switchValue.label}
            </label>
          )
        })}
      </div>
    </button>
  )
};

type CategoryFiltersProps = {
  filterBySpecimenCategoryOsmTag: Option;
  setFilterBySpecimenCategoryOsmTag: React.Dispatch<SetStateAction<Option>>;
}

const CategoryFilters = ({ filterBySpecimenCategoryOsmTag, setFilterBySpecimenCategoryOsmTag }: CategoryFiltersProps) => {
  const intl = useIntl();
  /* Category type filter */
  const poiTypeOptions: { label: string, value: 'all' | 'nature' | 'culture' }[] = useMemo(() => {
    return [
      { label: intl.formatMessage({ id: 'species.ALL' }), value: 'all' },
      { label: intl.formatMessage({ id: 'specimen.nature' }), value: 'nature', 'icon': null },
      { label: intl.formatMessage({ id: 'specimen.culture' }), value: 'culture', 'icon': null },
    ];
  }, [intl]);
  const [filterBySpecimenCategoryType, setFilterBySpecimenCategoryType] = useState(poiTypeOptions[0]); // all category are default

  // update osmTagOptions on change of type
  const naturalPoiOptions = usePoisOptions('nature', true);
  const culturePoiOptions = usePoisOptions('culture', true);
  const allOptions = usePoisOptions('all', true);
  const opiOsmTagOptions = useMemo(() => {
    switch (filterBySpecimenCategoryType?.value) {
      case 'all':
        return allOptions;
      case 'nature':
        return naturalPoiOptions;
      case 'culture':
        return culturePoiOptions;
      default:
        return allOptions;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBySpecimenCategoryType]); // do not add the missing dependencies here, causes infinite loop; lint rule must be disabled (or fixed in another way)

  // update default all category option for osmTag filter on change of type, (all category option of culture and nature has different value, this make filter speciman easier)
  useEffect(() => { setFilterBySpecimenCategoryOsmTag(opiOsmTagOptions[0]) }, [opiOsmTagOptions, setFilterBySpecimenCategoryOsmTag])

  return (
    <Fragment>
      {/* Category type filter */}
      <Dropdown className='mr-2'>
        <Dropdown.Toggle variant="outline-dark" id="speciesCategoryFilterTest" className='py-1 px-3 flex-row d-flex align-items-center'>
          <div className="mx-1">
            {filterBySpecimenCategoryType.label}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {poiTypeOptions.map((poiType, index) => {
            return (
              <Dropdown.Item
                key={index}
                // href={poiOption.value ? `#specimen-${poiOption.value}` : '#specimen-all'}
                onClick={() => {
                  setFilterBySpecimenCategoryType(poiType);
                }}
              >
                {poiType.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      {/* Category osmTag filter */}
      {filterBySpecimenCategoryType.value !== 'all' &&
        <Dropdown className='mr-2'>
          <Dropdown.Toggle variant="outline-dark" id="speciesCategoryFilterTest" className='py-1 px-3 flex-row d-flex align-items-center'>
            <div className='d-flex align-items-center'>
              {filterBySpecimenCategoryOsmTag.icon}
              <div className="mx-1">
                {filterBySpecimenCategoryOsmTag.label}
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {opiOsmTagOptions.map((poiOsmTag, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  // href={poiOption.value ? `#specimen-${poiOption.value}` : '#specimen-all'}
                  onClick={() => setFilterBySpecimenCategoryOsmTag({ ...poiOsmTag })}
                >
                  <div className='d-flex align-items-center'>
                    {poiOsmTag.icon}
                    <div className="mx-1">
                      {poiOsmTag.label}
                    </div>
                  </div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      }
    </Fragment>
  )
}

type SpecimenTabLayoutProps = {
  setShowCreateSpecimenModal: React.Dispatch<SetStateAction<boolean>>;
  locationBoundingBox: BBox;
  locationGeojson: JSON;
  isCurrentUserMentor: boolean;
  user: User | null,
  territorySlug: string;
  draftSpecimens: Speciman[],
  myContributionSpecimens: Speciman[],
  nonObservedSpecimens: Speciman[],
  observedSpecimens: Speciman[],
  reviewPendingSpecimens: Speciman[],
  /** True if it is used in Bioregion Identity Page, determines the URL user will be redirected to after clicking SpecimenCard */
  inBI?: boolean,
  /** Where are we rendering this component? */
  variant: 'ecoregion' | 'commons' | 'my-bioregional-identity'
  /** Context help shown next to the "Create new" button */
  contextHelpId: string;
}

const SpecimensTabLayout = ({
  setShowCreateSpecimenModal,
  locationBoundingBox,
  locationGeojson,
  isCurrentUserMentor,
  territorySlug,
  user,
  draftSpecimens,
  myContributionSpecimens,
  nonObservedSpecimens,
  observedSpecimens,
  reviewPendingSpecimens,
  inBI,
  variant,
  contextHelpId,
}: SpecimenTabLayoutProps) => {

  const intl = useIntl();
  const currentLang = useSelector((state: any) => state.settings.locale);
  const currentLocation = useLocation();
  const currentUser = useContext(UserContext)['currentUser'];

  /* */
  /* States */
  /* */
  const [layout, setLayout] = useState<'grid' | 'map'>('grid');
  const [showSearchField, setShowSearchField] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [debouncedSearchFieldValue, setDebouncedSearchFieldValue] = useState('');

  const [clickedSpecimenCard, setClickedSpecimenCard] = useState<Speciman | null>();
  const [popoverPayload, setPopoverPayload] = useState<any>();
  const [mapInitialCenterZoom, setMapInitialCenterZoom] = useState<Viewport>({ center: [null, null], zoom: null });
  const [mapCenterZoom, setMapCenterZoom] = useState<Viewport>({ center: [null, null], zoom: null });
  const [getCurrentLocationButtonClicked, setGetCurrentLocationButtonClicked] = useState(false);
  const [mapButtonOffset, setMapButtonOffset] = useState([null, null])

  const [showInfoModal, setShowInfoModal] = useState(false);

  const [draftsIsCollapsed, setDraftsIsCollapsed] = useState(true);
  const [underReviewIsCollapsed, setUnderReviewIsCollapsed] = useState(true);
  const [discoveredIsCollapsed, setDiscoveredIsCollapsed] = useState(false);
  const [lockedIsCollapsed, setLockedIsCollapsed] = useState(true);

  const [discoveredSpecimenViewType, setDiscoveredSpecimenViewType] = useState<ViewType>('all')
  const [nonDiscoveredSpecimenViewType, setNonDiscoveredSpecimenViewType] = useState<ViewType>('all')

  /* */
  /* Filters*/
  /* */

  /* Category OsmTag filter */
  const [filterBySpecimenCategoryOsmTag, setFilterBySpecimenCategoryOsmTag] = useState<Option>();


  /* */
  /* Filter specimen by search input and category filter*/
  /* */
  const [
    filteredDraftSpecimens,
    filteredMyContributionSpecimens,
    filteredNonObservedSpecimensBySearch,
    filteredObservedSpecimensBySearch,
    filteredReviewPendingSpecimens,
    noSpecimenToShowOnGrid,
    noSpecimenToShowOnMap
  ] = useMemo(
    () => {
      const specimensFilteredByCategory = [
        draftSpecimens,
        myContributionSpecimens,
        nonObservedSpecimens,
        observedSpecimens,
        reviewPendingSpecimens,
      ].map((specimensSection) => {
        const filteredSpecimens = specimensSection.filter(specimen => {
          // enable filter by category
          let categoryFilter = false;
          switch (filterBySpecimenCategoryOsmTag?.value) {
            case 'all':
              categoryFilter = true
              break;
            case 'nature':
              categoryFilter = naturePoiValues.includes(specimen.osmTags);
              break;
            case 'culture':
              categoryFilter = culturePoiValues.includes(specimen.osmTags);
              break;
            case specimen.osmTags:
              categoryFilter = true;
              break;
          }
          return categoryFilter;
        });
        return filteredSpecimens;
      })

      // filter out the specimens to show in each section based on search input
      if (!debouncedSearchFieldValue) {
        // if there is no filteredObservedSpecimens and filteredNonObservedSpecimens
        const noSpecimenToShowOnMap = specimensFilteredByCategory[2]?.length === 0 && specimensFilteredByCategory[3]?.length === 0;
        // if all filteredSpecimens's inner array is empty
        const noSpecimenToShowOnGrid = !specimensFilteredByCategory.some(specimensSection => specimensSection?.length > 0);
        return [...specimensFilteredByCategory, noSpecimenToShowOnGrid, noSpecimenToShowOnMap];
      }

      const searchedString = debouncedSearchFieldValue.toLowerCase().trim();
      const filteredSpecimens = specimensFilteredByCategory.map((specimensSection: Speciman[]) => {
        const filteredSpecimens = specimensSection?.filter((specimen: Speciman) => {
          // enable search by name, species name, species scientific name, code
          const nameIncludesSearchInput = getTranslatedString(specimen.name, currentLang).toLowerCase()
            .includes(searchedString);
          const speciesIncludesSearchInput = getTranslatedString(specimen.species?.name, currentLang).toLowerCase()
            .includes(searchedString);
          const speciesScientificNameIncludesSearchInput = specimen.species?.scientificName?.toLowerCase()
            .includes(searchedString);
          const codeIncludesSearchInput = specimen.code?.toLowerCase()
            .includes(searchedString);
          return nameIncludesSearchInput
            || speciesIncludesSearchInput
            || speciesScientificNameIncludesSearchInput
            || codeIncludesSearchInput
        });
        return filteredSpecimens;
      });

      // if there is no filteredObservedSpecimens and filteredNonObservedSpecimens
      const noSpecimenToShowOnMap = !([...filteredSpecimens[2], ...filteredSpecimens[3]].length > 0);
      // if all filteredSpecimens's inner array is empty
      const noSpecimenToShowOnGrid = !filteredSpecimens.some(specimensSection => specimensSection.length > 0);

      return [...filteredSpecimens, noSpecimenToShowOnGrid, noSpecimenToShowOnMap];
    }
    , [draftSpecimens,
      myContributionSpecimens,
      nonObservedSpecimens,
      observedSpecimens,
      reviewPendingSpecimens,
      debouncedSearchFieldValue,
      filterBySpecimenCategoryOsmTag?.value,
      currentLang]
  );

  /* */
  /* Filter specimen by view type */
  /* */
  const observedSpecimensToShow = useMemo(() => {
    return (filteredObservedSpecimensBySearch as Speciman[]).filter((specimen: Speciman) => {
      if (discoveredSpecimenViewType == 'all') {
        return true
      } else {
        return !specimen.territoryId
      }
    });
  }, [filteredObservedSpecimensBySearch, discoveredSpecimenViewType,])

  const nonObservedSpecimensToShow = useMemo(() => {
    return (filteredNonObservedSpecimensBySearch as Speciman[]).filter((specimen: Speciman) => {
      if (nonDiscoveredSpecimenViewType == 'all') {
        return true
      } else {
        return !specimen.territoryId
      }
    });
  }, [nonDiscoveredSpecimenViewType, filteredNonObservedSpecimensBySearch])

  /* */
  /* Get device current location */
  /* */
  const clickToCenterZoom = 20;
  const popoverMapCenterOffset = 0.00016;

  const { coords, isGeolocationAvailable, isGeolocationEnabled, positionError, getPosition } = geolocated.useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 120000, // 120 s
    },
    watchPosition: true
  });

  useEffect(() => {
    // get position if get current location button is clicked
    if (getCurrentLocationButtonClicked) {
      getPosition();
      if (coords) {
        setMapCenterZoom({ zoom: clickToCenterZoom, center: [coords.latitude, coords.longitude] });
      } else if (!isGeolocationAvailable) {
        NotificationManager.error(
          intl.formatMessage({ id: 'Geolocation service not available' }),
          null,
          3000,
          null,
          null,
          'filled'
        );
      } else if (!isGeolocationEnabled) {
        NotificationManager.error(
          intl.formatMessage({ id: 'bioregion.error-access-denied' }),
          null,
          3000,
          null,
          null,
          'filled'
        );
      } else if (positionError) {
        NotificationManager.error(
          `Problem with geolocation: ${positionError.message} (${positionError.code})`,
          null,
          3000,
          null,
          null,
          'filled'
        );
      }
      setGetCurrentLocationButtonClicked(false);
    }
  }, [intl,
    coords,
    setGetCurrentLocationButtonClicked,
    getPosition,
    getCurrentLocationButtonClicked,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError
  ]);

  /* */
  /* Map center and zoom based on the shape polygon size */
  /* */
  const setWidthHeightZoom = useCallback(node => {
    if (node !== null) {
      const mapWidth = node.clientWidth as number;
      const mapHeight = node.clientHeight as number;
      setMapInitialCenterZoom(viewport(locationBoundingBox as any, [mapWidth, mapHeight]));
      setMapButtonOffset([mapWidth - 45 - 10, 10]); //45px is the height and width of the button,10px is the padding to the border of the map 
    }
  }, [locationBoundingBox]);

  /* */
  /* Map provider */
  /* */
  const maptilerProvider = maptiler(import.meta.env.VITE_MAPTILER_API_KEY, 'topo');

  /**
   * 
   * @returns the content of the modal that is shown if a mentor clicks on a specimen that is already under review by another mentor
   */
  const specimenIsUnderReviewContent = () => {
    return (
      <div>
        <h1 className="mb-2 font-weight-bolder"><IntlMessages id='bioregion.form.under-review' /></h1>
        <div className="text-normal mb-3">
          <IntlMessages id='bioregion.specimen.underReview-description' />:
        </div>
        <div className="text-normal mb-2">
          <IntlMessages id='bioregion.specimen.reviewer' />:
        </div>
        <DesignByListItem
          user={clickedSpecimenCard?.reviewer}
          currentLang={currentLang}
        />
      </div>
    );
  };

  /**
   * @param speciman 
   * @returns the tag props for SpecimenCard based on if current user is author
   */
  const specimanCardTag = (speciman: Speciman) => {
    const tagForLearner = {
      show: true,
      colors: statusTagColorsForLearner,
      textOverride: {
        'TO_REVIEW': 'bioregion.specimen.waiting-for-review',
      }
    } as SpecimanCardProp['tag'];

    const tagForMentor = {
      show: true,
      colors: statusTagColorsForMentor,
    } as SpecimanCardProp['tag'];

    // if current user is both mentor and author, set the tag from author point of view
    if (currentUser.id === speciman.createdByUser.id) {
      return tagForLearner;
    } else { return tagForMentor; }
  };

  return (
    <Fragment>
      <div className='d-flex mx-1 align-items-center'>
        {/* switch between map and list view */}
        <ViewGridOutlineIcon
          className='clickable mr-2'
          size={30}
          color={layout === 'grid' ? '#60B6AA' : 'grey'}
          onClick={() => setLayout('grid')}
        />
        <MapLegendIcon
          className='clickable mr-2'
          size={30}
          color={layout === 'map' ? '#60B6AA' : 'grey'}
          onClick={() => setLayout('map')}
        />

        {/* search input for screen bigger than 576px */}
        <InputSearchFilter
          setDebouncedSearchFieldValue={setDebouncedSearchFieldValue}
          setShowSearchField={setShowSearchField}
          showSearchField={showSearchField}
        />

        {/* category filter for screen bigger than 768px */}
        <div className="d-none d-md-flex">
          <CategoryFilters
            setFilterBySpecimenCategoryOsmTag={setFilterBySpecimenCategoryOsmTag}
            filterBySpecimenCategoryOsmTag={filterBySpecimenCategoryOsmTag}
          />
        </div>

        {/* category filter for screen smaller than 768px */}
        <FilterVariantIcon
          className='clickable mr-2 d-md-none'
          size={30}
          color={showFilter ? '#60B6AA' : 'grey'}
          onClick={() => setShowFilter((preState) => !preState)}
        />
        <div className="ml-auto">
          <ContextHelp contextHelpId={contextHelpId} variant="outline" />

          {/* create new Specimen button */}
          <button className="btn btn-primary ml-3 align-self-center text-truncate" onClick={() => { setShowCreateSpecimenModal(true); }}>
            {intl.formatMessage({ id: 'specimen.new-poi' })}
          </button>
        </div>
      </div>

      {/* search input for screen smaller than 576px*/}
      <SearchInputField
        screenSize='mobile'
        setDebouncedSearchFieldValue={setDebouncedSearchFieldValue}
        showSearchField={showSearchField}
      />

      {/* category filter for screen smaller than 768px */}
      {showFilter &&
        <div className="d-flex mt-2 justify-content-end d-md-none">
          <CategoryFilters
            setFilterBySpecimenCategoryOsmTag={setFilterBySpecimenCategoryOsmTag}
            filterBySpecimenCategoryOsmTag={filterBySpecimenCategoryOsmTag}
          />
        </div>
      }

      {/* Map */}
      {
        layout === 'map' &&
        <Fragment>

          <div
            ref={setWidthHeightZoom}
            /* ref callback receives a reference to the rendered DOM element as its only argument. it is s called right after the attached DOM element is created, and again with null when the element is removed.https://julesblom.com/writing/ref-callback-use-cases */
            style={{ height: '76vh', borderRadius: '0.75rem', overflow: 'hidden' }}
            className={`mt-2 ${noSpecimenToShowOnMap && 'overlay-container'}`}>

            {/* Nothing to show text overlay */}
            {noSpecimenToShowOnMap &&
              <div className='overlay-text h1 text-center' style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                <IntlMessages id='general.loading-no-data' />
              </div>
            }

            {(mapInitialCenterZoom.zoom > 0) &&
              <Map
                provider={maptilerProvider}
                attribution={<MapAttribution />}
                dprs={[1, 2]}
                center={mapCenterZoom.center}
                zoom={mapCenterZoom.zoom}
                defaultCenter={[mapInitialCenterZoom.center[1], mapInitialCenterZoom.center[0]]}
                defaultZoom={mapInitialCenterZoom.zoom}
                onClick={() => setPopoverPayload(null)}
                onBoundsChanged={({ center, zoom, initial }) => {
                  !initial && setMapCenterZoom({ center, zoom });
                }}
              >
                {/* Get current location button */}
                <Overlay
                  style={{ transform: `translate(${mapButtonOffset[0]}px, ${mapButtonOffset[1]}px)`, zIndex: '1' }}
                >
                  <div
                    className='btn btn-sm btn-shadow rounded-circle p-0 d-flex'
                    style={{
                      width: '45px', height: '45px',
                      backgroundColor: 'rgba(245,245,245,0.8)',
                      borderWidth: '2px',
                    }}
                    onClick={() => { setGetCurrentLocationButtonClicked(true); setPopoverPayload(null) }}
                  >
                    <CrosshairsGpsIcon size={'1.5rem'} color='#60b6aa' className='my-auto mx-auto' />
                  </div>
                </Overlay>

                {/* GeoJson shape of commons */}
                {locationGeojson &&
                  <GeoJson
                    svgAttributes={{
                      strokeWidth: '3',
                      stroke: 'grey',
                    }}
                    data={{
                      type: 'FeatureCollection',
                      features: [{
                        type: 'Feature',
                        geometry: locationGeojson
                      }]
                    }}
                  />
                }

                {/* Specimen Markers - not-yet-observed */}
                {(filteredNonObservedSpecimensBySearch as Speciman[])?.length > 0 && (filteredNonObservedSpecimensBySearch as Speciman[]).map((speciman: Speciman) => {
                  return (
                    <Marker
                      key={speciman.id}
                      offset={speciman.type !== 'POI' ? [0, 12] : [0, -20]} // with this size of marker, this positions the point of the marker so that it doesn't drift when zooming out 
                      width={30}
                      anchor={[speciman.coordinates?.x, speciman.coordinates?.y]}
                      onClick={({ anchor }) => {
                        const adjustAnchor = [anchor[0] - popoverMapCenterOffset, anchor[1]] as Position;
                        setPopoverPayload({ payload: speciman, anchor: adjustAnchor });
                        setMapCenterZoom(() => {
                          return {
                            zoom: clickToCenterZoom,
                            center: adjustAnchor
                          };
                        });
                      }}
                    >
                      {speciman.type !== 'POI' // for trees, we keep the original pin icon
                        ? <TreeMapIcon size={30} color={speciman.isNatureMonument ? 'orange' : 'grey'} />
                        : <MapMarkerNumber
                          number={getOsmTagIconAndName(speciman.osmTags as OsmTagType, '30px').icon}
                          color='white'
                          borderColor='grey'
                          width='40px'
                          height='40px'
                          blackAndWhite // makes the icon desaturated (meaning "not observed yet")
                        />
                      }
                    </Marker>
                  );
                })}

                {/* Specimen Markers - observed */}
                {(filteredObservedSpecimensBySearch as Speciman[])?.length > 0 && (filteredObservedSpecimensBySearch as Speciman[]).map((speciman: Speciman) => {
                  return (
                    <Marker
                      key={speciman.id}
                      offset={speciman.type !== 'POI' ? [0, 12] : [0, -20]} // with this size of marker, this positions the point of the marker so that it doesn't drift when zooming out 
                      width={30}
                      anchor={[speciman.coordinates?.x, speciman.coordinates?.y]}
                      color='#60b6aa'
                      onClick={({ anchor }) => {
                        const adjustAnchor = [anchor[0] - popoverMapCenterOffset, anchor[1]] as Position;
                        setPopoverPayload({ payload: speciman, anchor: adjustAnchor });
                        setMapCenterZoom(() => {
                          return {
                            zoom: clickToCenterZoom,
                            center: adjustAnchor
                          };
                        });
                      }}
                    >
                      {speciman.type !== 'POI' // for trees, we keep the original pin icon
                        ? <TreeMapIcon size={30} />
                        : <MapMarkerNumber
                          number={getOsmTagIconAndName(speciman.osmTags as OsmTagType, '30px').icon}
                          color='white'
                          borderColor='#60b6aa' // ARK green
                          width='40px'
                          height='40px'
                        />
                      }
                    </Marker>
                  );
                })}

                { /* Device current position marker */}
                {coords &&
                  <Marker
                    anchor={[coords.latitude, coords.longitude]}
                    offset={[-5, -20]} // this offset seems to put center of the marker right onto the anchor coordinates
                  >
                    <CircleSlice8Icon size='20px' color="#3988f3" style={{ zIndex: 3 }} />
                  </Marker>
                }

                { /* render popover with the tree info */}
                {popoverPayload &&
                  <Overlay anchor={popoverPayload.anchor} offset={[150, 180]}>
                    <div style={{ width: '300px' }}>
                      <SpecimenCard
                        speciman={popoverPayload.payload}
                        isCurrentUserMentor={isCurrentUserMentor}
                        goBackUrl={currentLocation}
                        showNav={true}
                        territorySlug={territorySlug}
                        inBI={inBI}
                      />
                    </div>
                  </Overlay>
                }

              </Map>
            }
          </div>
        </Fragment>
      }

      {/* grid view */}
      {
        layout === 'grid' &&
        <Fragment>
          {currentUser.id === user?.id &&
            <Fragment>
              { /* Drafts */}
              {(filteredDraftSpecimens as Speciman[]).length > 0 &&
                <div className="mb-md-3 mt-md-4">

                  {/* collapseToggle */}
                  <div className='d-flex flex-row collapseToggle py-2 align-items-center'
                    onClick={() => {
                      setDraftsIsCollapsed(!draftsIsCollapsed);
                    }}
                    aria-controls="collapseDraft"
                    aria-expanded={!draftsIsCollapsed || !!debouncedSearchFieldValue}>
                    {!draftsIsCollapsed || !!debouncedSearchFieldValue
                      ? <ChevronDownIcon size={35} />
                      : <ChevronRightIcon size={35} />
                    }
                    <h4 className="my-0 d-flex">
                      <IntlMessages id='bioregion.species-my-drafts' />
                      <Badge className='badge-primary badge-collapse-section align-self-center'>{(filteredDraftSpecimens as Speciman[]).length}</Badge>
                    </h4>
                  </div>

                  {/* collapseContent */}
                  <Collapse in={!draftsIsCollapsed || !!debouncedSearchFieldValue}>
                    <div id='collapseDraft'>
                      <div className='row'>
                        {(filteredDraftSpecimens as Speciman[]).map((speciman: Speciman) => {
                          return (
                            <div key={speciman.id} className='col-12 col-md-6 col-xl-3 col-xxl-2 pb-4'>
                              <SpecimenCard
                                speciman={speciman}
                                isCurrentUserMentor={isCurrentUserMentor}
                                goBackUrl={currentLocation}
                                territorySlug={territorySlug}
                                inBI={inBI}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Collapse>
                </div>
              }

              { /* Under review */}
              {((isCurrentUserMentor && (filteredReviewPendingSpecimens as Speciman[]).length > 0) || (filteredMyContributionSpecimens as Speciman[]).length > 0) &&
                < div className="mb-md-3 mt-md-3">
                  {/* collapseToggle */}
                  <div className='d-flex flex-row collapseToggle py-2 align-items-center'
                    onClick={() => {
                      setUnderReviewIsCollapsed(!underReviewIsCollapsed);
                    }}
                    aria-controls="collapseUnderReview"
                    aria-expanded={!underReviewIsCollapsed || !!debouncedSearchFieldValue}
                  >
                    {!underReviewIsCollapsed || !!debouncedSearchFieldValue
                      ? <ChevronDownIcon size={35} />
                      : <ChevronRightIcon size={35} />
                    }
                    <h4 className="my-0 d-flex">
                      <IntlMessages id='bioregion.form.under-review' />
                      <Badge className='badge-primary badge-collapse-section align-self-center'>
                        {isCurrentUserMentor ? (filteredReviewPendingSpecimens as Speciman[]).length : (filteredMyContributionSpecimens as Speciman[]).length}
                      </Badge>
                    </h4>
                  </div>


                  {/* collapseContent */}
                  <Collapse in={!underReviewIsCollapsed || !!debouncedSearchFieldValue}>
                    <div id='collapseUnderReview'>
                      <div className='row'>

                        { //* for mentor *//
                          isCurrentUserMentor ?
                            ((filteredReviewPendingSpecimens as Speciman[]).length > 0 && (filteredReviewPendingSpecimens as Speciman[]).map((speciman: Speciman) => {
                              return (
                                <div key={speciman.id} className='col-12 col-md-6 col-xl-3 col-xxl-2 pb-4'>

                                  <SpecimenCard
                                    speciman={speciman}
                                    tag={specimanCardTag(speciman)}
                                    isCurrentUserMentor={isCurrentUserMentor}
                                    setShowInfoModal={setShowInfoModal}
                                    setClickedSpecimenCard={setClickedSpecimenCard}
                                    goBackUrl={currentLocation}
                                    territorySlug={territorySlug}
                                    inBI={inBI}
                                  />
                                </div>
                              );
                            }))

                            //* for other user *//
                            : (filteredMyContributionSpecimens as Speciman[]).length > 0 && (filteredMyContributionSpecimens as Speciman[]).map((speciman: Speciman) => {
                              return (
                                <div key={speciman.id} className='col-12 col-md-6 col-xl-3 col-xxl-2 pb-4'>
                                  <SpecimenCard
                                    speciman={speciman}
                                    tag={specimanCardTag(speciman)}
                                    isCurrentUserMentor={isCurrentUserMentor}
                                    goBackUrl={currentLocation}
                                    territorySlug={territorySlug}
                                    inBI={inBI}
                                  />
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </Collapse>

                </div>
              }
            </Fragment>
          }

          { /* Discovered */}
          {(filteredObservedSpecimensBySearch as Speciman[])?.length > 0 &&
            <div className="mb-md-3 mt-md-3">
              {/* collapseToggle */}
              <div className='d-flex flex-wrap flex-row'>
                <div className='d-flex flex-row collapseToggle py-2 align-items-center flex-grow-1'
                  onClick={() => {
                    setDiscoveredIsCollapsed(!discoveredIsCollapsed);
                  }}
                  aria-controls="collapseDiscovered"
                  aria-expanded={!discoveredIsCollapsed || !!debouncedSearchFieldValue}
                >
                  {!discoveredIsCollapsed || !!debouncedSearchFieldValue
                    ? <ChevronDownIcon size={35} />
                    : <ChevronRightIcon size={35} />
                  }
                  <h4 className="my-0 d-flex">
                    <IntlMessages id='bioregion.specimen-species-discovered' />
                    <Badge className='badge-primary badge-collapse-section align-self-center'>
                      {(observedSpecimensToShow as Speciman[]).length}
                    </Badge>
                  </h4>
                </div>

                {/* switch button for All / Without Commons, disabled when content collapsed */}
                {/* this is to filter specimens mapped in this ecoregion but outside of any commons */}
                {/* only relevant when viewing specimens in an Ecoregion */}
                {variant === 'ecoregion' && !discoveredIsCollapsed &&
                  <ViewTypeSwitchButton
                    setViewType={setDiscoveredSpecimenViewType}
                    viewType={discoveredSpecimenViewType}
                    switchValues={[
                      { value: 'all', label: <IntlMessages id='profile.upcoming-activities-all' /> },
                      { value: "noCommons", label: <IntlMessages id='ecoregion.specimen-tab-view-switch.no-commons' /> }
                    ]}
                    isDisabled={discoveredIsCollapsed}
                  />
                }

              </div>
              {/* collapseContent */}
              <Collapse in={!discoveredIsCollapsed || !!debouncedSearchFieldValue}>
                <div id='collapseDiscovered'>
                  <div className='row mt-2'>
                    {(observedSpecimensToShow as Speciman[]).map((speciman: Speciman) => {
                      return (
                        <div key={speciman.id} className='col-12 col-md-6 col-xl-3 col-xxl-2 pb-4'>
                          <SpecimenCard
                            speciman={speciman}
                            isCurrentUserMentor={isCurrentUserMentor}
                            goBackUrl={currentLocation}
                            territorySlug={territorySlug}
                            inBI={inBI}
                          />
                        </div>
                      );
                    })}
                    {observedSpecimensToShow.length === 0 &&
                      <h5 className="col-12 pl-5 text-muted">
                        <IntlMessages id='general.loading-no-data' />
                      </h5>}
                  </div>
                </div>
              </Collapse>
            </div>
          }

          { /* Locked */}
          {(filteredNonObservedSpecimensBySearch as Speciman[])?.length > 0 &&
            <div className="mb-md-5 mt-md-3">
              <div className="d-flex flex-row flex-wrap">
                {/* collapseToggle */}
                <div className='d-flex flex-row collapseToggle py-2 align-items-center position-relative flex-grow-1'
                  onClick={() => {
                    setLockedIsCollapsed(!lockedIsCollapsed);
                  }}
                  aria-controls="collapseLocked"
                  aria-expanded={!lockedIsCollapsed || !!debouncedSearchFieldValue}
                >
                  {!lockedIsCollapsed || !!debouncedSearchFieldValue
                    ? <ChevronDownIcon size={35} />
                    : <ChevronRightIcon size={35} />
                  }
                  <h4 className="my-0 d-flex">
                    <IntlMessages id='bioregion.specimen-species-locked' />
                    <Badge className='badge-primary badge-collapse-section align-self-center'>
                      {(nonObservedSpecimensToShow as Speciman[]).length}
                    </Badge>
                  </h4>
                </div>

                {/* switch button, disabled when content collapsed */}
                {/* this is to filter specimens mapped in this ecoregion but outside of any commons */}
                {/* only relevant when viewing specimens in an Ecoregion */}
                {variant === 'ecoregion' && !lockedIsCollapsed &&
                  <ViewTypeSwitchButton
                    setViewType={setNonDiscoveredSpecimenViewType}
                    viewType={nonDiscoveredSpecimenViewType}
                    switchValues={[
                      { value: 'all', label: <IntlMessages id='profile.upcoming-activities-all' /> },
                      { value: "noCommons", label: <IntlMessages id='ecoregion.specimen-tab-view-switch.no-commons' /> }
                    ]}
                    isDisabled={lockedIsCollapsed}
                  />
                }
              </div>

              {/* collapseContent */}
              <Collapse in={!lockedIsCollapsed || !!debouncedSearchFieldValue}>
                <div id='collapseLocked'>
                  <div className='row mt-2'>
                    {(nonObservedSpecimensToShow as Speciman[]).map((speciman: Speciman) => {
                      return (
                        <div key={speciman.id} className='col-12 col-md-6 col-xl-3 col-xxl-2 pb-4'>
                          <SpecimenCard
                            speciman={speciman}
                            isCurrentUserMentor={isCurrentUserMentor}
                            goBackUrl={currentLocation}
                            territorySlug={territorySlug}
                            inBI={inBI}
                          />
                        </div>
                      );
                    })
                    }
                  </div>
                </div>
              </Collapse>
            </div>
          }

          { /* Nothing to show */}
          {noSpecimenToShowOnGrid &&
            <h1 className="mt-4 d-flex justify-content-center align-items-center">
              <IntlMessages id='general.loading-no-data' />
            </h1>
          }
        </Fragment>
      }

      <InfoModal
        description={specimenIsUnderReviewContent()}
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
      />
    </Fragment>
  )
}

export default SpecimensTabLayout;
