/*                                                                           */
/* Renders a modal that allows to search through all species on the ARK and  */
/* add the selected one to the currently viewed ecoregion, or create         */
/* a new one, if the species doesn't exist yet.                              */
/*                                                                           */

// TODO this component is not multilingual
import { gql, useMutation } from '@apollo/client';
import NotificationManager from 'components/common/react-notifications/NotificationManager';
import CustomReactSelect, { Option } from 'components/custom/CustomReactSelect';
import IntlMessages from 'helpers/IntlMessages';
import { getUiTranslation } from 'helpers/utils-typescript';
import useSpeciesesOptions from 'hooks/useSpeciesesOptions';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import React, { SetStateAction, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Species } from 'services/graphqltypes';

const CREATE_ECOREGION_SPECIES = gql`
mutation createEcoregionSpecies($ecoregionGid: Int, $speciesId: UUID) {
  createEcoregionSpecies(
    input: {
      ecoregionSpecies: { ecoregionGid: $ecoregionGid, speciesId: $speciesId }
    }
  ) {
    ecoregionSpecies {
      id
      ecoregionGid
    }
    query {
      specieses(
        filter: {
          ecoregionSpecieses: {
            some: { ecoregionGid: { equalTo: $ecoregionGid } }
          }
        }
        orderBy: SCIENTIFIC_NAME_ASC
      ) {
        nodes {
          id
          slug
          name
          scientificName
          family
          category
          coverPictureUrl
          isPublished
          createdByUserId
        }
      }
    }
  }
}
`;

/** Renders a modal that allows to search through all species on the ARK and add a selected one to the currently viewed ecoregion, or create a new one, if the species doesn't exist yet. */
export const AddSpeciesModal = ({
  showAddSpeciesModal,
  setShowAddSpeciesModal,
  currentLang,
  ecoregionGid,
  ecoregionName,
  refetch,
}: {
  showAddSpeciesModal: boolean;
  setShowAddSpeciesModal: React.Dispatch<SetStateAction<boolean>>;
  currentLang: string;
  /** ecoregionGid of the ecoregion the species will be added to // created in */
  ecoregionGid: number;
  /** Ecoregion name is shown in the title of the modal */
  ecoregionName: string | React.ReactElement;
  /** Function to refetch list of species after a new species is created */
  refetch: () => void;
}): React.ReactElement => {
  const intl = useIntl();
  const history = useHistory();
  const currentLocation = useLocation();

  const {
    speciesesOptions: allSpeciesOptions,
    specieses
  } = useSpeciesesOptions(null, null, !showAddSpeciesModal); // fetch all published species from all ecoregions, but only when the modal is shown

  const {
    speciesesOptions: currentEcoregionSpeciesesOptions
  } = useSpeciesesOptions(ecoregionGid, null, !showAddSpeciesModal, true); // fetch all species including drafts from current ecoregion, but only when the modal is shown

  const speciesesOptions = useMemo(() => {

    const speciesesOptions = allSpeciesOptions?.map((option: Option) => {
      if (currentEcoregionSpeciesesOptions.some(
        (currentEcoregionSpeciesOption: Option) => currentEcoregionSpeciesOption.value === option.value)
      ) {
        return { ...option, tag: `${option.tag} (${intl.formatMessage({ id: 'species.already-exists' })})`, isDisabled: true }; // the isDisabled property is used to disable the option in the dropdown
      } else { return option; }
    });
    return speciesesOptions;
  }, [allSpeciesOptions, currentEcoregionSpeciesesOptions, intl]);

  const [createEcoregionSpecies] = useMutation(CREATE_ECOREGION_SPECIES);
  const [selectedExistingSpecies, setSelectedExistingSpecies] = useState({} as Species);
  const [selectedOption, setSelectedOption] = useState<Option>(null);
  const [createNewSpeciesName, setCreateNewSpeciesName] = useState('');

  if (!showAddSpeciesModal) return (<></>);
  return (
    <Modal
      show={showAddSpeciesModal}
      className="rounded-modal"
      backdrop="static" /* prevent closing modal when clicked outside it*/
      onHide={() => setShowAddSpeciesModal(false)}
    >
      <Modal.Header closeButton className="mr-3 mt-3 p-0" />
      <Modal.Body className="pt-0 mt-0">
        <h3 className="font-weight-bold mb-2">{intl.formatMessage({ id: 'species.add-species-into' }, { ecoregionName: ecoregionName })}</h3>
        <div className="mt-4 mb-2 d-flex flex-column">
          <h5>{intl.formatMessage({ id: 'species.search-all-species' })}</h5>
          <h5>{intl.formatMessage({ id: 'species.if-you-cant-find' }, { i: chunks => <i>{chunks}</i> }) /* https://formatjs.io/docs/react-intl/components/#rich-text-formatting */}</h5>
        </div>

        {/* Dropdown */}
        <div className="mt-3 d-flex flex-column">
          <CustomReactSelect
            isCreatable
            isClearable
            name="selectSpecies"
            hasIcon={true}
            options={speciesesOptions}
            placeholder={getUiTranslation('general.select-or-type-to-search', currentLang)}
            // function will be called by onChange
            handleChange={(option: Option) => {
              setSelectedExistingSpecies(specieses?.find((species: Species) => species.id === option?.value));
              setSelectedOption(option);
              setCreateNewSpeciesName(null);
            }}
            // onCreateOption will be called with the input value when a new option is created, and onChange will not be called.
            onCreateOption={(inputValue: string) => {
              setSelectedOption({
                value: inputValue,
                label: inputValue,
                icon: <PlusCircleIcon color='#EFB700' size={30} />
              });
              setCreateNewSpeciesName(inputValue);
              setSelectedExistingSpecies(null);
            }}
            selected={selectedOption}
          />
        </div>

        {/* Buttons */}
        <div className='mt-3 d-flex justify-content-around'>

          {/* Cancel */}
          <button className=" mx-auto btn btn-outline-primary mt-3"
            onClick={() => {
              setSelectedExistingSpecies(null);
              setCreateNewSpeciesName(null);
              setSelectedOption(null);
              setShowAddSpeciesModal(false);
            }}
          >
            <IntlMessages id={'general.cancel'} />
          </button>

          {/* Confirm */}
          <button className=" mx-auto btn btn-primary mt-3"
            disabled={!selectedOption?.value}
            onClick={() => {
              selectedExistingSpecies && (
                createEcoregionSpecies({
                  variables: {
                    ecoregionGid: ecoregionGid,
                    speciesId: selectedExistingSpecies.id
                  }
                }).then(() => {
                  refetch(); // refetch list of species
                  NotificationManager.success(
                    intl.formatMessage({ id: 'general.saved' }),
                    null,
                    3000,
                    null,
                    null,
                    'filled'
                  );
                  setShowAddSpeciesModal(false);
                  setSelectedOption(null);
                  setSelectedExistingSpecies(null);
                  history.push({
                    pathname: `${location.pathname}/species/${selectedExistingSpecies.slug}`,
                    state: {
                      from: currentLocation,
                    }
                  });
                }).catch((error) => {
                  if (error.toString().includes('Conflict')) {
                    // in this case the species was already in the ecoregion -> business as usual
                    setShowAddSpeciesModal(false);
                    setSelectedExistingSpecies(null);
                    setSelectedOption(null);
                    history.push({
                      pathname: `${location.pathname}/species/${selectedExistingSpecies.slug}`,
                      state: {
                        from: currentLocation,
                      }
                    });
                  }
                  console.log(error);
                })
              );
              if (createNewSpeciesName) {
                setShowAddSpeciesModal(false);
                setCreateNewSpeciesName(null);
                setSelectedOption(null);
                history.push({
                  pathname: '/species/new',
                  state: {
                    ecoregionGid: ecoregionGid,
                    from: currentLocation.pathname,
                    speciesName: createNewSpeciesName
                  }
                });
              }
            }}>
            <IntlMessages id='general.confirm' />
          </button>
        </div>

      </Modal.Body>
    </Modal >

  );
};
