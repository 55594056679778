import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Elm = ({ style, size = 45, className }: IconProps): React.ReactElement => {
  return (
    <svg version="1.1" id="elm" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
            .circle-elm{fill:#FFFFFF;stroke:#60B6AA;stroke-miterlimit:10;}
            .path-circle-elm{fill:#60B6AA;}
        `}
      </style>
      <circle className="circle-elm" cx="16.3" cy="16.3" r="15.7" />
      <path className="path-circle-elm" d="M19.9,11.3c-0.2-0.2-0.4-0.5-0.7-0.5c-0.3,0-0.5-0.1-0.7-0.4c-0.4-0.6-0.6-0.6-0.9,0.1
	c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1,0.2-0.1,0.4-0.3,0.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.5-0.4-0.8,0.1
	c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c-0.2-0.2-0.4-0.3-0.6-0.5
	c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0.2-0.1,0.3-0.2,0.5c0,0,0,0-0.1,0.1c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.2-0.1-0.3-0.1
	c-0.1,0-0.1,0.2-0.2,0.3c0,0.1,0,0.2-0.1,0.3c-0.7-0.6-0.7-0.6-0.9-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.1,0-0.2-0.1
	C9.4,8.8,8.9,8.7,8.6,8.2C8.4,8.1,8.2,8,8,7.9c-0.3-0.1-0.4,0-0.3,0.3c0.2,0.5,0.4,1,0.5,1.5c0.1,0.2,0.4,0.4,0.2,0.7
	c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.6,0.9c-0.1,0.1-0.2,0.2-0.3,0.3C9,11.7,9,11.9,9.1,12.1c-0.1,0.1-0.3,0.2-0.3,0.2
	C9,12.6,9.1,12.8,9.2,13C9.2,13.1,9,13.3,9,13.4c0,0.2,0.2,0.3,0.4,0.5C9.3,14,9.2,14,9,14.1c0,0.1,0.1,0.1,0.1,0.2
	c-0.1,0-0.2,0-0.3,0c0.2,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
	c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.3,0.3,0.4,0.4c-0.5,0.2-0.5,0.3-0.1,0.7c0,0,0,0,0.1,0c0.3,0.1,0.2,0.3,0.2,0.5
	c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.3,0.4,0.5,0.5c-0.2,0.2-0.1,0.4,0.2,0.5c0.2,0.1,0.3,0.3,0.5,0.4c-0.3,0.2-0.1,0.4,0.1,0.6
	c0.2,0.2,0.4,0.4,0.6,0.5c-0.1,0.1-0.4,0.3-0.3,0.4c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3c-0.4,0.3-0.4,0.4,0.1,0.6
	c0.2,0.1,0.5,0.3,0.7,0.4c-0.3,0.3-0.3,0.4,0.1,0.5c0.3,0.1,0.8,0.2,0.8,0.7c0,0.4,0.2,0.4,0.4,0.5c0.1,0.1,0.3,0.1,0.4,0.2
	c0.1,0,0.2,0.1,0.2,0.2c0,0.3,0.3,0.3,0.5,0.4c0.3,0.1,0.5,0.1,0.8,0.2c0,0-0.1,0.1-0.1,0.1c0.1,0.2,0.2,0.4,0.4,0.4
	c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.2,0.1,0.3,0.2c0.4,0.2,0.7,0.7,1.3,0.6c0.4,0,0.8-0.2,1.2,0.2c0.1,0.1,0.4-0.1,0.6,0
	c0.8,0.3,1.5-0.1,2.2-0.4c0.5-0.3,0.8-0.8,1.1-1.3c0.2-0.3,0.3-0.5,0.7-0.6c0.1,0,0.2-0.2,0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.4-0.2-0.7-0.4-1-0.6c0.2-0.7,0.5-1.4,0.7-2.1c0.1-0.4,0.2-0.8,0.2-1.2c0.1-0.7-0.3-1.2-0.2-1.9c0-0.4-0.3-0.8-0.5-1.2
	c-0.1-0.1-0.2-0.1-0.4-0.2c0-0.1-0.1-0.3-0.1-0.4c0.1-0.7-0.4-1.2-0.7-1.7c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.2-0.6-0.5-0.8-0.9
	c-0.1-0.3-0.4-0.6-0.6-0.8C20.9,12.3,20.4,11.8,19.9,11.3z M22.8,15.1L22.8,15.1C22.7,15.1,22.8,15,22.8,15.1
	C22.8,15,22.8,15.1,22.8,15.1L22.8,15.1z M20.9,13.1C20.9,13.1,20.9,13.1,20.9,13.1C20.9,13.2,20.9,13.2,20.9,13.1
	C20.9,13.2,20.9,13.2,20.9,13.1z M8.7,9.2C8.8,9.2,8.9,9.3,9,9.4c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1l1,1.1L10,10.8l-0.8-0.2
	c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.4,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1L8.6,9.5
	c0-0.1-0.1-0.2-0.1-0.2c0.1,0.1,0.2,0.2,0.3,0.3C8.7,9.4,8.7,9.3,8.7,9.2z M9.5,11.1c0.2,0.1,0.3,0.1,0.6,0.1c0.1,0,0.1,0,0.2,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0C9.6,11.4,9.6,11.3,9.5,11.1L9.5,11.1z M9.8,12.6
	C9.8,12.6,9.8,12.6,9.8,12.6L9.8,12.6c0.6,0.3,1.1,0.3,1.7,0.4c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.1,0-0.2,0.1-0.2,0.1c-0.6-0.1-1.1-0.1-1.7-0.2c-0.1,0-0.2,0-0.4,0c0,0,0,0,0-0.1C9.9,12.9,9.9,12.7,9.8,12.6z M10.4,16.5l0.1,0
	c0.3,0,0.7,0,1,0l0,0c1.3,0.1,2.7,0.1,4,0.2c0.2,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1C16,17,16,17,16,17
	c-0.6,0-1.2,0-1.8-0.1c-0.6,0-1.2-0.1-1.7-0.1c-0.6,0-1.1-0.1-1.7-0.1c-0.1,0-0.1,0-0.2,0C10.5,16.7,10.5,16.6,10.4,16.5z M15,15.8
	c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.8,0-1.2,0c-0.8-0.1-1.5-0.1-2.3-0.2c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.2
	c0,0-0.1-0.1-0.1-0.1c0.3,0,0.5,0,0.8,0.1c1,0.1,2.1,0.2,3.2,0.3c0.2,0,0.3,0,0.5-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.2,0
	C15,15.7,15,15.8,15,15.8z M14.1,15C14.2,15,14.2,15.1,14.1,15C14.2,15.1,14.2,15.1,14.1,15c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0
	c-0.2,0-0.5,0-0.8,0c-0.8-0.1-1.6-0.2-2.4-0.2c-0.2,0-0.4,0-0.6-0.1c0-0.1-0.1-0.3-0.2-0.4l0,0c0.1,0,0.2,0,0.3,0.1
	c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.3,0,0.6,0,0.9,0C14.1,14.9,14.1,15,14.1,15z M10.6,17.1
	C10.6,17.1,10.6,17.1,10.6,17.1C10.6,17.1,10.7,17.2,10.6,17.1c0.1,0.1,0.1,0.2,0,0.2c0,0,0,0,0,0C10.6,17.2,10.6,17.2,10.6,17.1z
	 M14.7,23.1c0-0.1-0.1-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.1,1.2-0.1c1.5,0,3-0.1,4.5-0.8
	c0.2-0.1,0.4-0.1,0.6-0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.4,0.1
	c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0.4-1.7,0.6-2.6,0.6c-0.5,0-1,0.1-1.4,0.1c-0.4,0-0.8,0-1.3,0.1c0,0,0,0-0.1,0
	C14.8,23.1,14.8,23.1,14.7,23.1z M15.7,24c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c0.6-0.1,1.2-0.2,1.8-0.2c1.3-0.1,2.5-0.4,3.6-0.8
	c0.3-0.1,0.6-0.2,0.9-0.3c0,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.2,0.1-0.4,0.1-0.6,0.2
	c-1.1,0.4-2.3,0.8-3.5,0.9c-0.5,0-1,0.1-1.5,0.2C16.1,24,15.9,24,15.7,24z M20.7,21.3c-0.1,0-0.1,0-0.2,0.1
	c-0.2,0.1-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.1-0.5,0.1-0.8,0.1l-0.5,0.1c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.3,0.1-0.5,0.1
	c-0.7,0-1.5,0-2.2,0c-0.4,0-0.9,0-1.3,0c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.1-0.1-0.2-0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
	c-0.1,0-0.1-0.1-0.2-0.1c0.2,0,0.4,0,0.6,0c0.6,0,1.2,0,1.7,0c0.6,0,1.2,0,1.9,0c0.2,0,0.3,0,0.4-0.1c0,0,0.1,0,0.1,0
	c0.2,0,0.4-0.1,0.7-0.1c0.5-0.1,0.9-0.2,1.4-0.3c0.1,0,0.2,0,0.3,0C20.7,21.1,20.7,21.2,20.7,21.3z M19.6,20.4c0,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1,0.1c-0.9,0.5-2,0.5-3,0.5l0,0c-0.8,0-1.6,0-2.3,0c-0.3,0-0.6,0-0.9,0c0-0.1-0.1-0.2-0.2-0.3c0.4,0,0.8,0,1.3,0
	c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.2-0.1c0.4,0,0.8-0.1,1.2-0.3c0.2-0.1,0.5-0.1,0.7-0.2C19.4,20.2,19.5,20.3,19.6,20.4
	C19.6,20.3,19.6,20.3,19.6,20.4C19.6,20.3,19.6,20.3,19.6,20.4z M23.8,24C23.8,24,23.8,24,23.8,24C23.8,24,23.8,24,23.8,24
	c0.1,0.1,0.1,0.3,0,0.5c-0.2,0.4-0.4,0.8-0.7,1.2c-0.1,0.1-0.3,0.2-0.4,0.2c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
	c0.3-0.3,0.5-0.7,0.7-1.1l0-0.1c0.1-0.2,0.2-0.3,0.3-0.5C23.7,24.1,23.8,24,23.8,24z M24,23.9l-0.2-0.1c0,0,0,0,0,0
	c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0.1-0.1,0.1l-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.5-0.5,1-0.9,1.4
	c-0.4,0.4-1,0.8-1.7,1.1c0,0,0,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1c0.8-0.2,1.6-0.6,2.1-1.1
	c0,0,0,0,0,0c0.3-0.3,0.6-0.7,0.7-1.1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0c-1.1,0.9-2.5,1.6-4.4,2c0,0,0,0,0,0
	c-0.1-0.1-0.2-0.2-0.4-0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c1.3-0.1,3.6-0.9,4.6-1.9l0,0c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0
	c-0.3,0.1-0.5,0.2-0.8,0.3c-0.6,0.2-1.2,0.5-1.8,0.7c-0.6,0.2-1.3,0.3-1.9,0.5c-0.3,0.1-0.5,0.1-0.8,0.2c0,0,0-0.1,0-0.1
	c0-0.1-0.1-0.2-0.2-0.3c2-0.2,3.8-0.8,5.6-1.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.3-0.4-0.5-0.7-0.7c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.5-0.5-0.9-0.9-1.3-1.2c-0.2-0.1-0.4-0.3-0.6-0.2
	c-0.4,0-0.8,0.1-1.3,0.2c-0.7,0.1-1.4,0.2-2.1,0.2c-0.9,0-1.8,0-2.7,0c-0.3,0-0.5,0-0.8,0c-0.1-0.1-0.2-0.1-0.2-0.2
	c0.1,0,0.1,0,0.2,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0,0.3,0c2.1,0.1,4.4,0.3,6.5-0.3c0,0,0,0,0,0c0,0,0,0,0-0.1
	c-0.1-0.3-0.3-0.5-0.7-0.5c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.6,0.1-1.3,0.1-1.9,0.2l-0.2,0c-0.1,0-0.1,0-0.2,0
	c-0.2,0-0.4,0-0.6,0c-0.8,0-1.5-0.1-2.2-0.1c-0.3,0-0.5,0-0.8,0c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0.1,0c0.9,0.2,1.8,0.2,2.7,0.2c0.4,0,0.8,0,1.2,0c0.6,0,1.2,0,1.8-0.1c0.3,0,0.5-0.1,0.8-0.1c0,0,0.1,0,0.1,0
	c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.2-0.5-0.2
	c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-1.3,0-2.6,0.1-4,0.1c-0.5,0-0.9,0-1.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0-0.2-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.2c1.4,0.3,2.9,0.2,4.3,0.1c0.6,0,1.3-0.1,2-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.4-0.5c-0.4-0.1-0.6-0.4-0.8-0.7c0-0.1-0.1-0.1-0.1-0.2
	c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.2-0.6-0.5-0.9-0.7c-0.2-0.1-0.3-0.3-0.5-0.5
	c-0.1-0.1-0.2-0.2-0.2-0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.1-0.4,0.1l0,0
	c-0.2,0-0.4,0-0.6,0c-0.5-0.1-1.1-0.1-1.6-0.2c-0.1,0-0.3,0-0.4-0.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0c0.3,0,0.5,0.1,0.8,0.1
	c0.7,0.1,1.5,0.3,2.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1l-0.2-0.3c-0.3-0.3-0.5-0.7-0.8-1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
	c-0.1,0-0.2,0-0.3-0.1l-0.1,0c0,0,0,0,0,0c-0.4,0.2-0.8,0.2-1.3,0.1c-0.1,0-0.2,0-0.4,0c0-0.1,0.1-0.2,0-0.4l1.2,0.2c0,0,0,0,0.1,0
	c0,0,0,0,0,0l0.1-0.2c0,0,0-0.1,0-0.1c-0.5-0.4-0.6-0.9-0.7-1.5c-0.1-0.2-0.1-0.4-0.2-0.6c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0
	l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0.1,0.8,0.4,1.6,1.2,2.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.1-0.9-0.3-1.6-0.6-2.1
	c0,0,0,0,0.1,0c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0,0.1c0.1,0.3,0.2,0.7,0.3,1
	c0,0.7,0.4,1.1,0.9,1.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.1-1-0.3-2.1-0.9-3.1c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.6,0.3,1.2,0.4,1.9c0.1,0.5,0.2,1,0.7,1.3c0,0,0,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0.1-1.4-0.4-2.5-0.9-3.7c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
	c0,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0,0.2,0.1,0.3,0.1,0.4c0.5,1,0.8,2.1,0.8,3.3c0,0.6,0.4,1,0.9,1.3c0,0,0,0,0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.6,0.1-1.2-0.1-1.9c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.4-0.2-0.7-0.3-1.1
	c0-0.1-0.1-0.3-0.1-0.5c0,0,0,0,0,0c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.1,0.4,0.1c0,0,0,0,0,0l0,0.1c0.1,0.7,0.3,1.4,0.5,2
	c0.3,0.8,0.6,1.8,0.4,2.8c-0.1,0.4,0.1,0.7,0.6,1.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8,0-1.2
	c-0.2-1.3-0.5-2.6-1.1-4.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.3,0.1,0.4
	c0.5,1.4,1.1,3.1,0.9,5c-0.1,0.5,0.2,0.9,0.7,1.4c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0-0.5,0-0.7c0-0.5,0-0.9,0-1.3
	c-0.2-1.1-0.3-2.2-0.5-3.3C17.1,11.7,17,11.4,17,11c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2
	c0,0,0.1,0.1,0.1,0.1c0,0.2,0.1,0.4,0.1,0.5c0.1,0.5,0.2,1.1,0.3,1.6c0,0.2,0.1,0.4,0.1,0.6c0.2,1.1,0.4,2.2,0.2,3.3
	c-0.1,0.5,0.1,0.9,0.6,1.3c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0.1-0.5,0.1-0.8
	c-0.1-0.5-0.1-1-0.1-1.5c0-0.7,0-1.5-0.2-2.2c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c-0.1-0.5-0.2-1-0.3-1.5c0-0.1,0-0.1,0-0.2
	c0.2,0.2,0.4,0.3,0.7,0.3c0,0,0,0,0,0l-0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.2,0,0.4,0.1,0.7c0.1,0.5,0.1,1,0.2,1.5
	c0.1,0.7,0.1,1.2,0.2,1.8c0,0.4,0,0.8,0,1.1c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1c-0.1,0.6,0,1.1,0.5,1.4c0,0,0,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0.4-1.1,0.1-5.9-0.5-7.7c0,0.1,0.1,0.1,0.2,0.2l0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.6,2.5,0.6,5.1,0.4,7.3
	c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0.7-1.9,0.7-5,0.2-7
	c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c0,0.4,0.1,0.8,0.2,1.2c0.2,1.5,0.2,2.9-0.1,4.1c-0.1,0.3-0.1,0.6-0.2,0.9
	c-0.1,0.4,0,0.6,0.3,0.8c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0.6-1.9,0.7-3.9,0.3-6.2c0.1,0.2,0.2,0.3,0.4,0.4
	c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0.3,0,0.5,0,0.7c0,0.5,0.1,1,0.1,1.5c0,1.1-0.1,2.2-0.5,3.6c-0.1,0.4,0,0.7,0.3,1
	c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0.1-0.3,0.1-0.4c0.1-0.3,0.2-0.6,0.2-0.9l0-0.2c0.2-1.1,0.4-2.3,0.6-3.4
	c0,0.1,0,0.1,0,0.2l0,0c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0l0,0
	c0,0,0,0.1,0,0.2l-0.8,4.3c-0.1,0.4,0,0.7,0.2,1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
	c0.2-1.3,0.5-2.3,1-3.1c0.1-0.1,0.1-0.2,0.1-0.3c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0,0.2
	c-0.6,0.9-0.9,2-0.9,3.5c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.4,0.3
	C24,23.9,24,23.9,24,23.9z M24.1,20.5c0.1,0,0.1,0,0.2,0.1c0,0,0,0.1,0,0.1l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.4,1.5
	c0,0-0.1,0-0.1,0c0.1-0.2,0.1-0.5,0.2-0.7c-0.1,0.3-0.2,0.6-0.3,1l0,0c0-0.3,0-0.7,0.2-1.1c0.1-0.2,0.2-0.5,0.3-0.7
	c0.1-0.2,0.2-0.4,0.2-0.6c0,0.1,0,0.2,0,0.2C24.1,20.4,24.1,20.4,24.1,20.5z"/>
    </svg>
  );
};
export default Elm;


