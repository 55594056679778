import React from 'react';
import IconProps from './IconProps';

const SustainableBuildingsIcon = ({ style, color = '#80C8C3', size, className }: IconProps): React.ReactElement => {
  return (
    <svg version="1.1" id="sustainableBuildings" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" style={style} fill={color} width={size}
      height={size} className={className}>
      <g>
        <line x1="193.3" y1="47.7" x2="193.3" y2="671.5" />
        <path d="M193.3,702.4c-17.1,0-30.9-13.8-30.9-30.9V47.7c0-17.1,13.8-30.9,30.9-30.9s30.9,13.8,30.9,30.9v623.8
		C224.2,688.6,210.3,702.4,193.3,702.4z"/>
      </g>
      <g>
        <line x1="315.8" y1="50.4" x2="315.8" y2="205.2" />
        <path d="M315.8,236.1c-17.1,0-30.9-13.8-30.9-30.9V50.4c0-17.1,13.8-30.9,30.9-30.9s30.9,13.8,30.9,30.9v154.8
		C346.7,222.3,332.9,236.1,315.8,236.1z"/>
      </g>
      <g>
        <line x1="806.7" y1="315.7" x2="806.7" y2="698.7" />
        <path d="M806.7,729.6c-17.1,0-30.9-13.8-30.9-30.9v-383c0-17.1,13.8-30.9,30.9-30.9s30.9,13.8,30.9,30.9v383
		C837.6,715.8,823.7,729.6,806.7,729.6z"/>
      </g>
      <g>
        <path d="M30.9,470.7c-9,0-18-3.9-24.1-11.5c-10.7-13.3-8.7-32.8,4.6-43.5l469.1-379c13.3-10.7,32.7-8.6,43.5,4.6
		c10.7,13.3,8.7,32.8-4.6,43.5l-469.1,379C44.6,468.4,37.8,470.7,30.9,470.7z"/>
      </g>
      <g>
        <path d="M969.1,470.7c-6.8,0-13.7-2.3-19.4-6.9l-469.1-379c-13.3-10.7-15.4-30.2-4.6-43.5
		c10.7-13.2,30.2-15.4,43.5-4.6l469.1,379c13.3,10.7,15.4,30.2,4.6,43.5C987,466.7,978.1,470.7,969.1,470.7z"/>
      </g>
      <g>
        <path d="M389.5,914c-113.1,0-227.1-75-227.1-242.5c0-17.1,13.8-30.9,30.9-30.9s30.9,13.8,30.9,30.9
		c0,172,137.7,180.7,165.3,180.7c100.4,0,139.6-62.4,163-99.7l3.1-4.9c23.8-37.6,30.7-100.7,17.4-160.8
		c-24.9-112.8-11.3-189.5,4.6-237.4c-95.5,47.9-193.9,122.8-231.1,214.5c-22.3,55-20.6,112.3,5.1,170.2c6.9,15.6-0.1,33.9-15.7,40.8
		c-15.6,6.9-33.9-0.1-40.8-15.7c-32.7-73.9-34.7-147.4-5.8-218.5c64.9-160.1,259.6-249,317.8-272.4c4.3-1.9,14.7-6.2,28.4-2.5
		l13.8,3.7l8.7,14.9c7.6,16.2,1.1,30.5-6.5,47.1c-15.7,34.2-48.2,105.2-18.1,242c16.8,76.4,7.1,155.8-25.6,207.2l-2.9,4.6
		C580.7,823.9,524.1,914,389.5,914z"/>
      </g>
      <g>
        <path d="M427.6,914c-13.1,0-25.2-8.4-29.4-21.5c-15.9-49.5-28.7-126.3,0.2-210.6c11.1-32.4,27.5-62.9,48.7-90.6
		c10.4-13.5,29.8-16.1,43.3-5.8c13.5,10.4,16.1,29.8,5.8,43.3c-17.1,22.3-30.3,46.9-39.3,73C433.4,770.2,444,833,457,873.7
		c5.2,16.3-3.8,33.6-20,38.8C433.9,913.5,430.7,914,427.6,914z"/>
      </g>
      <g>
        <path d="M586.5,983.2c-109,0-145.3-39.1-191.2-88.6c-11.6-12.5-10.9-32.2,1.6-43.8c12.5-11.6,32-10.9,43.6,1.6
		c42.7,46,64,69,146,69c54.9,0,98.5-16.1,129.8-48c59.9-61,59.7-162.7,59.5-174c-0.3-17.1,13.3-31.2,30.3-31.5
		c17.5,0.2,31.2,13.2,31.5,30.3c0.4,22.9-1.2,141.2-77.2,218.5C717.1,960.8,658.6,983.2,586.5,983.2z"/>
      </g>
      <g>
        <path d="M315.8,78.6H193.3c-17.1,0-30.9-13.8-30.9-30.9s13.8-30.9,30.9-30.9h122.5c17.1,0,30.9,13.8,30.9,30.9
		S332.9,78.6,315.8,78.6z"/>
      </g>
    </svg>

  );
};

export default SustainableBuildingsIcon;
