import ActivitiesIcon from 'assets/icons/menuIcon/activities.svg?react';
import CommonsIcon from 'assets/icons/menuIcon/commons.svg?react';
import CommunitiesIcon from 'assets/icons/menuIcon/communities.svg?react';
import EcoregionIcon from 'assets/icons/menuIcon/ecoregion.svg?react';
import LeaderboardIcon from 'assets/icons/menuIcon/leaderboard.svg?react';
import LibraryIcon from 'assets/icons/menuIcon/library.svg?react';
import MeIcon from 'assets/icons/menuIcon/me.svg?react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import {
  addContainerClassname,
  changeDefaultClassnames,
  changeSelectedMenuHasSubItems,
  setContainerClassnames
} from '../../redux/actions';

// Put the above imported icons components into an object
const icons = {
  MeIcon: MeIcon,
  ActivitiesIcon: ActivitiesIcon,
  CommunitiesIcon: CommunitiesIcon,
  LibraryIcon: LibraryIcon,
  LeaderboardIcon: LeaderboardIcon,
  EcoregionIcon: EcoregionIcon,
  CommonsIcon: CommonsIcon,
};

// Returns the icon we want depending on the name prop that you pass
const Icon = (props) => {
  const { name } = props;
  const TheIcon = icons[name] ? icons[name] : MeIcon; // fallback if mess is passed
  return <TheIcon {...props} />;
};

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.menuItems = this.props.menuItems;
    this.name = this.props.name;
    this.state = {
      selectedParentMenu: '',
      viewingParentMenu: '',
      collapsedMenus: [],
      menuItems: this.menuItems,
    };
  }

  handleWindowResize = (event) => {
    if (event && !event.isTrusted) {
      return;
    }
    const { containerClassnames } = this.props;
    const nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.props.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.props.selectedMenuHasSubItems
    );
  };

  handleDocumentClick = (e) => {
    const container = this.wrapper;
    let isMenuClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('menu-button') || e.target.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    }
    if (container.current.contains(e.target) || container === e.target || isMenuClick) {
      return;
    }
    this.setState({
      viewingParentMenu: ''
    });
    this.toggle();
  };

  getMenuClassesForResize = (classes) => {
    const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
    let nextClasses = classes.split(' ').filter((x) => x !== '');
    const windowWidth = window.innerWidth;
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile');
    } else if (windowWidth < subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x) => x !== 'menu-mobile');
      if (nextClasses.includes('menu-default') && !nextClasses.includes('menu-sub-hidden')) {
        nextClasses.push('menu-sub-hidden');
      }
    } else {
      nextClasses = nextClasses.filter((x) => x !== 'menu-mobile');
      if (nextClasses.includes('menu-default') && nextClasses.includes('menu-sub-hidden')) {
        nextClasses = nextClasses.filter((x) => x !== 'menu-sub-hidden');
      }
    }
    return nextClasses;
  };

  toggle = () => {
    const hasSubItems = this.getIsHasSubItem();
    this.props.changeSelectedMenuHasSubItems(hasSubItems);
    const { containerClassnames, menuClickCount } = this.props;
    const currentClasses = containerClassnames
      ? containerClassnames.split(' ').filter((x) => x !== '')
      : '';
    let clickIndex = -1;

    if (!hasSubItems) {
      if (this.name === 'settings') {
        clickIndex = 3;
      } else if (currentClasses.includes('menu-default') && (menuClickCount % 4 === 0 || menuClickCount % 4 === 3)) {
        clickIndex = 1;
      } else if (currentClasses.includes('menu-sub-hidden') && (menuClickCount === 2 || menuClickCount === 3)) {
        clickIndex = 0;
      } else if (currentClasses.includes('menu-hidden') || currentClasses.includes('menu-mobile')) {
        clickIndex = 0;
      }
    } else if (currentClasses.includes('menu-sub-hidden') && menuClickCount === 3) {
      clickIndex = 2;
    } else if (currentClasses.includes('menu-hidden') || currentClasses.includes('menu-mobile')) {
      clickIndex = 0;
    }
    if (clickIndex >= 0) {
      this.props.setContainerClassnames(clickIndex, containerClassnames, hasSubItems);
    }
  };

  handleProps = () => {
    this.addEvents();
  };

  addEvents = () => {
    ['click', 'touchstart', 'touchend'].forEach((event) =>
      document.addEventListener(event, this.handleDocumentClick, true)
    );
  };

  removeEvents = () => {
    ['click', 'touchstart', 'touchend'].forEach((event) =>
      document.removeEventListener(event, this.handleDocumentClick, true)
    );
  };

  setSelectedLiActive = (callback) => {
    const oldli = document.querySelector('.sub-menu  li.active');
    if (oldli != null) {
      oldli.classList.remove('active');
    }

    const oldliSub = document.querySelector('.third-level-menu  li.active');
    if (oldliSub != null) {
      oldliSub.classList.remove('active');
    }

    /* set selected parent menu */
    const selectedSublink = document.querySelector('.third-level-menu  a.active');
    if (selectedSublink != null) {
      selectedSublink.parentElement.classList.add('active');
    }

    const selectedlink = document.querySelector('.sub-menu  a.active');
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add('active');
      this.setState(
        {
          selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute('data-parent')
        },
        callback
      );
    } else {
      const selectedParentNoSubItem = document.querySelector('.main-menu  li a.active');
      if (selectedParentNoSubItem != null) {
        this.setState(
          {
            selectedParentMenu: selectedParentNoSubItem.getAttribute('data-flag')
          },
          callback
        );
      } else if (this.state.selectedParentMenu === '') {
        const pathname = this.props.location.pathname;
        let selectedPath = this.menuItems[0].id;
        if ((pathname.includes('event') && !pathname.includes('community'))
          || pathname.includes('course') || pathname.includes('book') || pathname.includes('payment')
          || pathname.includes('activity') || pathname.includes('join')) {
          selectedPath = this.menuItems.find((item) => item.id === 'events').id;
        } else if (pathname.includes('community') && !pathname.includes('leaderboard')) {
          selectedPath = this.menuItems.find((item) => item.id === 'communities').id;
        } else if (pathname.includes('library')) {
          selectedPath = this.menuItems.find((item) => item.id === 'library').id;
        } else if (pathname.includes('leaderboard')) {
          selectedPath = this.menuItems.find((item) => item.id === 'leaderboard').id;
        } else if (pathname.includes('commons')) {
          selectedPath = this.menuItems.find((item) => item.id === 'commons').id;
        } else if (pathname.includes('ecoregion')) {
          selectedPath = this.menuItems.find((item) => item.id === 'ecoregions').id;
        }
        this.setState(
          {
            selectedParentMenu: selectedPath
          },
          callback
        );
      }
    }
  };

  setHasSubItemStatus = () => {
    const hasSubmenu = this.getIsHasSubItem();
    this.props.changeSelectedMenuHasSubItems(hasSubmenu);
    this.toggle();
  };

  getIsHasSubItem = () => {
    const { selectedParentMenu } = this.state;
    const menuItem = this.menuItems.find((x) => x.id === selectedParentMenu);
    if (menuItem) {
      return !!(menuItem && menuItem.subs && menuItem.subs.length > 0);
    }
    return false;
  };


  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSelectedLiActive(this.setHasSubItemStatus);

      window.scrollTo(0, 0);
    }
    this.handleProps();


  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
    this.handleProps();
    this.setSelectedLiActive(this.setHasSubItemStatus);
  }

  componentWillUnmount() {
    this.removeEvents();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  openSubMenu = (e, menuItem) => {
    const selectedParent = menuItem.id;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    this.props.changeSelectedMenuHasSubItems(hasSubMenu);
    if (!hasSubMenu) {
      this.setState({
        viewingParentMenu: selectedParent,
        selectedParentMenu: selectedParent
      });
      this.toggle();
    } else {
      // Has SubMenu
      e.preventDefault();

      const { containerClassnames, menuClickCount } = this.props;
      const currentClasses = containerClassnames
        ? containerClassnames.split(' ').filter((x) => x !== '')
        : '';

      if (!currentClasses.includes('menu-mobile')) {
        if (currentClasses.includes('menu-sub-hidden') && (menuClickCount === 2 || menuClickCount === 0)) {
          this.props.setContainerClassnames(3, containerClassnames, hasSubMenu);
        } else if (currentClasses.includes('menu-hidden') && (menuClickCount === 1 || menuClickCount === 3)) {
          this.props.setContainerClassnames(2, containerClassnames, hasSubMenu);
        } else if (
          currentClasses.includes('menu-default') &&
          !currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
          this.props.setContainerClassnames(0, containerClassnames, hasSubMenu);
        }
      } else {
        this.props.addContainerClassname('sub-show-temporary', containerClassnames);
      }
      this.setState({
        viewingParentMenu: selectedParent
      });
    }
  };

  toggleMenuCollapse = (e, menuKey) => {
    e.preventDefault();
    const { collapsedMenus } = this.state;
    if (collapsedMenus.indexOf(menuKey) > -1) {
      this.setState({
        collapsedMenus: collapsedMenus.filter((x) => x !== menuKey)
      });
    } else {
      collapsedMenus.push(menuKey);
      this.setState({
        collapsedMenus
      });
    }
    return false;
  };


  render() {
    const { selectedParentMenu, viewingParentMenu, collapsedMenus, menuItems } = this.state;
    return (
      <div className="sidebar" ref={this.wrapper}>
        <div className="main-menu rounded-0">
          <div className="scroll">
            <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false }}>
              <Nav vertical className="list-unstyled">
                {menuItems &&
                  menuItems.map((item) => {
                    return (
                      <NavItem
                        key={item.id}
                        className={classnames({
                          active:
                            (selectedParentMenu === item.id && viewingParentMenu === '') ||
                            viewingParentMenu === item.id
                        })}
                      >
                        {item.newWindow ? (
                          <a href={item.to} className="blue-fill-on-hover" rel="noopener noreferrer" target="_blank">
                            {
                              item.mdiIcon ? <Icon name={item.mdiIcon}
                                color='transparent'
                                size='2.5rem'
                                style={{
                                  strokeWidth: '0.5px', stroke: ((selectedParentMenu === item.id && viewingParentMenu === '') ||
                                    viewingParentMenu === item.id) ? '#60b6aa' : 'black'
                                }}
                                className="mb-1" /> :
                                item.svgIcon // menu entry can specify either svgIcon (must have component, top of this page) or cssIcon
                                  ? <Icon name={item.svgIcon}
                                    fill={
                                      ((selectedParentMenu === item.id && viewingParentMenu === '') ||
                                        viewingParentMenu === item.id) ? '#60b6aa' : ''}
                                    width="30%" className="mb-1" />
                                  : <i className={item.cssIcon} />
                            }
                            <IntlMessages id={item.label} />
                          </a>
                        ) : (
                          <NavLink to={item.to} className="blue-fill-on-hover" onClick={(e) => this.openSubMenu(e, item)} data-flag={item.id}>
                            {
                              item.mdiIcon ? <Icon name={item.mdiIcon}
                                color='transparent'
                                size='2.5rem'
                                style={{
                                  strokeWidth: '0.5px', stroke: ((selectedParentMenu === item.id && viewingParentMenu === '') ||
                                    viewingParentMenu === item.id) ? '#60b6aa' : 'black'
                                }}
                                className="mb-1" /> :
                                item.svgIcon
                                  ? <Icon name={item.svgIcon}
                                    fill={
                                      ((selectedParentMenu === item.id && viewingParentMenu === '') ||
                                        viewingParentMenu === item.id) ? '#60b6aa' : ''}
                                    width="30%" className="mb-1" />
                                  : <i className={item.cssIcon} />
                            }
                            <IntlMessages id={item.label} />
                          </NavLink>
                        )}
                      </NavItem>
                    );
                  })}
              </Nav>
            </PerfectScrollbar>
          </div>
        </div>

        <div className="sub-menu rounded-0">
          <div className="scroll">
            <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false }}>
              {menuItems &&
                menuItems.map((item) => {
                  return (
                    <Nav
                      key={item.id}
                      className={classnames({
                        'd-block':
                          (this.state.selectedParentMenu === item.id && this.state.viewingParentMenu === '') ||
                          this.state.viewingParentMenu === item.id
                      })}
                      data-parent={item.id}
                    >
                      {item.subs &&
                        menuItems.map((sub, index) => {
                          return (
                            <NavItem
                              key={`${item.id}_${index}`}
                              className={`${sub.subs && sub.subs.length > 0 ? 'has-sub-item' : ''}`}
                            >
                              {sub.newWindow ? (
                                <a href={sub.to} rel="noopener noreferrer" target="_blank">
                                  <i className={sub.icon} /> <IntlMessages id={sub.label} />
                                </a>
                              ) : sub.subs && sub.subs.length > 0 ? (
                                <>
                                  <NavLink
                                    className={`rotate-arrow-icon opacity-50 ${collapsedMenus.indexOf(`${item.id}_${index}`) === -1 ? ''
                                      : 'collapsed'}`}
                                    to={sub.to}
                                    id={`${item.id}_${index}`}
                                    onClick={(e) => this.toggleMenuCollapse(e, `${item.id}_${index}`)}
                                  >
                                    <i className="simple-icon-arrow-down" /> <IntlMessages id={sub.label} />
                                  </NavLink>

                                  <Collapse isOpen={collapsedMenus.indexOf(`${item.id}_${index}`) === -1}>
                                    <Nav className="third-level-menu">
                                      {menuItems.map((thirdSub, thirdIndex) => {
                                        return (
                                          <NavItem key={`${item.id}_${index}_${thirdIndex}`}>
                                            {thirdSub.newWindow ? (
                                              <a href={thirdSub.to} rel="noopener noreferrer" target="_blank">
                                                <i className={thirdSub.icon} /> <IntlMessages id={thirdSub.label} />
                                              </a>
                                            ) : (
                                              <NavLink to={thirdSub.to}>
                                                <i className={thirdSub.icon} /> <IntlMessages id={thirdSub.label} />
                                              </NavLink>
                                            )}
                                          </NavItem>
                                        );
                                      })}
                                    </Nav>
                                  </Collapse>
                                </>
                              ) : (
                                <NavLink to={sub.to}>
                                  <i className={sub.icon} /> <IntlMessages id={sub.label} />
                                </NavLink>
                              )}
                            </NavItem>
                          );
                        })}
                    </Nav>
                  );
                })}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  menuItems: PropTypes.array,
  name: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  currentUser: PropTypes.any,
};

const mapStateToProps = ({ menu }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  } = menu;

  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  };
};
export default withRouter(
  connect(mapStateToProps, {
    setContainerClassnames,
    addContainerClassname,
    changeDefaultClassnames,
    changeSelectedMenuHasSubItems
  })(Sidebar)
);
