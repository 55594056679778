import * as React from "react";
const SvgSpring = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:dc": "http://purl.org/dc/elements/1.1/", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:svg": "http://www.w3.org/2000/svg", xmlns: "http://www.w3.org/2000/svg", viewBox: "1.5 1 11.5 11", id: "svg5705", ...props }, /* @__PURE__ */ React.createElement("metadata", { id: "metadata5711" }, /* @__PURE__ */ React.createElement("rdf:RDF", null, /* @__PURE__ */ React.createElement("cc:Work", { "rdf:about": "" }, /* @__PURE__ */ React.createElement("dc:format", null, "image/svg+xml"), /* @__PURE__ */ React.createElement("dc:type", { "rdf:resource": "http://purl.org/dc/dcmitype/StillImage" }), /* @__PURE__ */ React.createElement("dc:title", null)))), /* @__PURE__ */ React.createElement("defs", { id: "defs5709" }), /* @__PURE__ */ React.createElement("g", { transform: "translate(3.7007359,-1042.5117)", id: "layer1" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  fillOpacity: 1,
  stroke: "#ffffff",
  strokeWidth: 0.60000002,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path4333", d: "m 6,13 a 3,3 0 1 1 -6,0 3,3 0 1 1 6,0 z", transform: "matrix(1.1666667,0,0,1.1666667,0,1033.6955)" }), /* @__PURE__ */ React.createElement("path", { style: {
  display: "inline",
  fill: "none",
  stroke: "#ffffff",
  strokeWidth: 3.5,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path4327-3", d: "m 6.875,1048.8622 a 3.375,3.3750069 0 1 1 -6.75,0 3.375,3.3750069 0 1 1 6.75,0 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  display: "inline",
  fill: "none",
  stroke: "#7abcec",
  strokeWidth: 1.5,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path4327", d: "m 6,13 a 3,3 0 1 1 -6,0 3,3 0 1 1 6,0 z", transform: "matrix(1.125,0,0,1.1250023,0.125,1034.2372)" })));
export default SvgSpring;
