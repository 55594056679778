import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const ExploringOtter = ({ style, size = 45, className }: IconProps): React.ReactElement => {
  return (
    <svg version="1.1" id="exploring_otter" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" height={size} className={className} style={style}>
      <style type="text/css">
        {`    
	       .circle-exploring-otter{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
           .path-exploring-otter{fill:#FBBF2D;} 
        `}
      </style>
      <circle className="circle-exploring-otter" cx="16.3" cy="16.3" r="15.7" />
      <path className="path-exploring-otter" d="M3.7,14.4C3.8,14.4,4,14.5,4,14.6c0.2,0.6,0.8,0.5,1.3,0.6c1,0,1.8,0.5,2.4,1.2c0.6,0.7,1.3,1.2,2.1,1.5
	c0.5,0.2,0.7,0.4,0.4,0.9c-0.3,0.5-0.6,1-1.2,1c-0.2,0-0.3,0.3-0.5,0.4c0.2,0.1,0.3,0.3,0.5,0.3c0.3,0,0.7,0,1-0.2
	c0.7-0.5,1.4-1.1,2.1-1.7c0.3-0.2,0.6-0.5,0.9-0.6c1.8-0.3,3.3,0.3,4.8,1c0,0.1,0,0.1,0,0.2c-0.4,0.1-0.7,0.2-1.1,0.3
	c-0.4,0.2-1,0.3-0.9,0.9c0,0.1,0.7,0.2,1.1,0.3c0.7,0.1,1.2-0.1,1.8-0.5c0.6-0.4,1.3-0.7,1.9-1.1c0.2-0.1,0.4-0.1,0.6,0.1
	c0.3,0.3,0.8,0.6,1.2,0.7c1.3,0.3,2.7,0.2,3.9-0.4c0.8-0.5,1.7-0.7,2.6-0.2c-0.1-0.6-0.4-1.1-1.2-1.4c-1.7-0.9-3.5-0.8-5.3-0.3
	c-0.2,0-0.3,0.1-0.5,0.2c0-0.3,0-0.5,0-0.7c0-1.3-0.4-2.4-1.4-3.2c-2.1-1.8-4.9-2.2-7.3-0.7c-1.4,0.9-2.6,0.8-3.9,0
	c-1-0.6-2-1.1-3.2-0.7c-0.5,0.1-0.9,0.4-1.3,0.6c-0.5,0.3-0.9,0.7-1.3,1.1C3.7,14.2,3.7,14.3,3.7,14.4z"/>
    </svg>

  );
};

export default ExploringOtter;
