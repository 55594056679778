import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const IndustryInnovationInfrastructureIcon = ({ style, size = 100, className }: IconProps):
    React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="IndustryInnovationInfrastructure" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className}  style={style}>
      <style type="text/css">
        {`
           .industry-innovation-infrastructure{fill - rule:evenodd;clip-rule:evenodd;fill:#ED6F2C;}
           .st1{fill:#FFFFFF;}
        `}
      </style>
      <g id="rect8528-63-2-4-3-6-0-4">
        <path className="industry-innovation-infrastructure" d="M13.3,0.7h48.6c6.8,0,12.4,5.6,12.4,12.4v48.7c0,6.8-5.6,12.4-12.4,12.4H13.3c-6.8,0-12.4-5.6-12.4-12.4V13.1
		C0.9,6.3,6.5,0.7,13.3,0.7z"/>
      </g>
      <g id="text6065-3-2-9-6-6-5-0-0">
        <g id="path2197">
          <path className="st1" d="M18.6,13.2V7h0.9v6.2H18.6z"/>
        </g>
        <g id="path2199">
          <path className="st1" d="M22.1,13.2l-1.4-3.8v3.8h-0.9V7h0.8l1.4,3.8V7h0.9v6.2H22.1z"/>
        </g>
        <g id="path2201">
          <path className="st1" d="M24.8,7c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1c-0.2,0.2-0.6,0.4-1.1,0.4h-1.4
			V7H24.8L24.8,7z M25.4,11.7V8.5c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4v4.6h0.4C25.1,12.4,25.4,12.2,25.4,11.7
			L25.4,11.7z"/>
        </g>
        <g id="path2203">
          <path className="st1" d="M29.4,12.6c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1
			c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4V7h0.9v4.8c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0.1s0,0.1,0.1,0.2s0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2s0.1-0.3,0.1-0.4V7h0.9v4.8c0,0.1,0,0.3,0,0.4
			C29.5,12.4,29.5,12.5,29.4,12.6L29.4,12.6z"/>
        </g>
        <g id="path2205">
          <path className="st1" d="M30.9,11.7c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0
			s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5S30,9.3,30,8.8V8.5c0-0.4,0.1-0.8,0.4-1.1s0.6-0.4,1-0.4c0.4,0,0.8,0.1,1.1,0.4
			S33,8.1,33,8.5v0.3h-0.9V8.5c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2
			C31,8.2,31,8.4,31,8.6v0.3V9c0,0.1,0,0.2,0,0.3s0.1,0.1,0.3,0.2c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5
			c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5
			c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L30.9,11.7L30.9,11.7z"/>
        </g>
        <g id="path2207">
          <path className="st1" d="M33.9,13.2V7.8h-1V7h3v0.8h-1v5.4H33.9z"/>
        </g>
        <g id="path2209">
          <path className="st1" d="M36.1,13.2V7h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.5c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9l-0.8-2.6
			H37v2.7H36.1L36.1,13.2z M38.1,9.6c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V8.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H37v1.9h0.6
			C37.8,9.8,37.9,9.7,38.1,9.6L38.1,9.6z"/>
        </g>
        <g id="path2211">
          <path className="st1" d="M40.1,13.2v-2.7L39,7h0.9l0.7,2.4L41.4,7h0.9L41,10.6v2.7H40.1z"/>
        </g>
        <g id="path2213">
          <path className="st1" d="M41.7,13.9v-1.5h0.9v0.9L41.7,13.9z"/>
        </g>
        <g id="path2215">
          <path className="st1" d="M44,13.2V7h0.9v6.2H44z"/>
        </g>
        <g id="path2217">
          <path className="st1" d="M47.6,13.2l-1.4-3.8v3.8h-0.9V7h0.8l1.4,3.8V7h0.9v6.2H47.6z"/>
        </g>
        <g id="path2219">
          <path className="st1" d="M50.9,13.2l-1.4-3.8v3.8h-0.9V7h0.8l1.4,3.8V7h0.9v6.2H50.9z"/>
        </g>
        <g id="path2221">
          <path className="st1" d="M52.3,7.6c0.1-0.2,0.3-0.3,0.5-0.4C53.1,7.1,53.3,7,53.5,7c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
			C54.8,7.7,54.9,7.9,55,8c0,0.1,0.1,0.3,0.1,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4V8.7c0-0.2,0-0.3,0-0.5C52.1,7.9,52.2,7.7,52.3,7.6L52.3,7.6z M54,8.1
			c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1S53.1,8,53,8.1c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1s0,0,0,0.1
			c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0s0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.6
			c0-0.1,0-0.2,0-0.2C54.1,8.2,54.1,8.2,54,8.1L54,8.1z"/>
        </g>
        <g id="path2223">
          <path className="st1"
            d="M56.4,13.2L55.1,7H56l0.7,4.3L57.5,7h0.9l-1.3,6.2C57.2,13.2,56.4,13.2,56.4,13.2z"/>
        </g>
        <g id="path2225">
          <path className="st1"
            d="M58,13.2L59.4,7h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1L59,13.2H58z M59.4,11.1h0.8l-0.4-2.2L59.4,11.1z"/>
        </g>
        <g id="path2227">
          <path className="st1" d="M62.2,13.2V7.8h-1V7h3v0.8h-1.1v5.4H62.2z"/>
        </g>
        <g id="path2229">
          <path className="st1" d="M64.3,13.2V7h0.9v6.2H64.3z"/>
        </g>
        <g id="path2231">
          <path className="st1" d="M65.8,7.6c0.1-0.2,0.3-0.3,0.5-0.4C66.6,7.1,66.8,7,67.1,7c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
			c0.1,0.1,0.2,0.3,0.3,0.5s0.1,0.3,0.1,0.5v3.2c0,0.2,0,0.3,0,0.5s-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1S66,13,66,12.9c-0.1-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4V8.7c0-0.2,0-0.3,0-0.5C65.7,7.9,65.7,7.7,65.8,7.6L65.8,7.6z M67.6,8.1
			c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1
			s0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0s0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.6
			c0-0.1,0-0.2,0-0.2C67.7,8.2,67.6,8.2,67.6,8.1L67.6,8.1z"/>
        </g>
        <g id="path2233">
          <path className="st1" d="M71.4,13.2L70,9.5v3.8h-0.9V7H70l1.4,3.8V7h0.9v6.2H71.4z"/>
        </g>
        <g id="path2235">
          <path className="st1"
            d="M18.3,21.8l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1l-0.2,1.3H18.3z M19.7,19.6h0.8l-0.4-2.2L19.7,19.6z"/>
        </g>
        <g id="path2237">
          <path className="st1" d="M24.2,21.8L22.8,18v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8H25v6.2H24.2z"/>
        </g>
        <g id="path2239">
          <path className="st1" d="M26.8,15.6c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.2c0,0.4-0.1,0.8-0.4,1.1s-0.6,0.4-1.1,0.4h-1.4v-6.2
			H26.8L26.8,15.6z M27.4,20.2V17c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4V21h0.4C27.2,21,27.4,20.7,27.4,20.2z"/>
        </g>
        <g id="path2241">
          <path className="st1" d="M29.6,21.8v-6.2h0.9v6.2H29.6z"/>
        </g>
        <g id="path2243">
          <path className="st1" d="M33.2,21.8L31.8,18v3.8h-0.9v-6.2h0.8l1.4,3.8v-3.8H34v6.2H33.2z"/>
        </g>
        <g id="path2245">
          <path className="st1" d="M34.3,21.8v-6.2h2.6v0.8h-1.7v1.9h1.5v0.8h-1.5v2.7L34.3,21.8L34.3,21.8z"/>
        </g>
        <g id="path2247">
          <path className="st1" d="M37.1,21.8v-6.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9
			l-0.8-2.7H38v2.7H37.1z M39.1,18.2c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V17c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H38v1.9h0.6
			C38.8,18.4,38.9,18.3,39.1,18.2L39.1,18.2z"/>
        </g>
        <g id="path2249">
          <path className="st1"
            d="M40.2,21.8l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1l-0.2,1.3H40.2z M41.5,19.6h0.8L42,17.4L41.5,19.6z"/>
        </g>
        <g id="path2251">
          <path className="st1" d="M44.5,20.2c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0
			s0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.6-0.2s-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
			c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
			c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
			c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5
			c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L44.5,20.2L44.5,20.2z
			"/>
        </g>
        <g id="path2253">
          <path className="st1" d="M47.5,21.8v-5.4h-1v-0.8h3v0.8h-1.1v5.4L47.5,21.8L47.5,21.8z"/>
        </g>
        <g id="path2255">
          <path className="st1" d="M49.6,21.8v-6.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9
			l-0.8-2.7h-0.5v2.7H49.6z M51.6,18.2c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V17c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9
			h0.6C51.3,18.4,51.5,18.3,51.6,18.2L51.6,18.2z"/>
        </g>
        <g id="path2257">
          <path className="st1" d="M55.5,21.1c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1
			s-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.8h0.9v4.8c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4v-4.8h0.9v4.8c0,0.1,0,0.3,0,0.4
			C55.5,20.9,55.6,21,55.5,21.1L55.5,21.1z"/>
        </g>
        <g id="path2259">
          <path className="st1" d="M59.1,20.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.3,0.2
			c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4s-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5
			V17c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1.1,0.4
			c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9V17c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0.2
			c-0.1,0.1-0.1,0.2-0.1,0.4v3.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2s0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2s0-0.1,0-0.2V20
			h0.9L59.1,20.2z"/>
        </g>
        <g id="path2261">
          <path className="st1" d="M60.2,21.8v-5.4h-1v-0.8h3v0.8H61v5.4L60.2,21.8L60.2,21.8z"/>
        </g>
        <g id="path2263">
          <path className="st1" d="M64.9,21.1c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1
			s-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.4v-4.8h0.9v4.8c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4v-4.8h0.9v4.8
			c0,0.1,0,0.3,0,0.4C65,20.9,65,21,64.9,21.1L64.9,21.1z"/>
        </g>
        <g id="path2265">
          <path className="st1" d="M65.6,21.8v-6.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9
			L67,19.1h-0.5v2.7H65.6z M67.6,18.2c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V17c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9
			h0.6C67.3,18.4,67.4,18.3,67.6,18.2L67.6,18.2z"/>
        </g>
        <g id="path2267">
          <path className="st1" d="M71.5,20.9v0.9h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5V19h-1.5v1.8H71.5L71.5,20.9z"/>
        </g>
      </g>
      <g id="text6075-0-6-9-2-5-9-0-9">
        <g id="path2270">
          <path className="st1" d="M8.4,14.2c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.2,0-0.5,0-0.8v-3c0-0.3,0-0.6,0.1-0.9c0-0.1,0.1-0.3,0.2-0.5
			c0.1-0.2,0.3-0.4,0.5-0.7c0.4-0.6,1.1-0.8,2.2-0.8c1,0,1.8,0.3,2.3,1c0.6,0.6,0.8,1.4,0.8,2.3v2.4c0,0.6-0.1,1.1-0.3,1.7l-2.8,7.5
			H9l2.5-6.2c-0.4,0.1-0.7,0.1-0.9,0.1c-0.5,0-0.9-0.1-1.3-0.4C8.9,14.9,8.6,14.6,8.4,14.2L8.4,14.2z M11.4,8.8
			c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.2,0.5-0.2,0.8v2.4c0,0.3,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.3
			c0.1-0.2,0.2-0.5,0.2-0.9V10c0-0.3-0.1-0.6-0.2-0.9C12.1,8.9,11.8,8.8,11.4,8.8L11.4,8.8z"/>
        </g>
      </g>
      <g id="path7454">
        <path className="st1" d="M38.7,50c-0.1,0-0.2,0-0.3-0.1l-9-5.3c-0.2-0.1-0.3-0.3-0.3-0.6V33.7c0-0.2,0.1-0.4,0.3-0.6l9-5.2
		c0.2-0.1,0.4-0.1,0.6,0l9,5.3c0.2,0.1,0.3,0.3,0.3,0.6v10.4c0,0.2-0.1,0.4-0.3,0.6L39,50C38.9,50,38.8,50,38.7,50z M30.3,43.8
		l8.4,4.9l8.4-4.8v-9.7l-8.4-4.9l-8.4,4.8V43.8z"/>
      </g>
      <g id="path7452">
        <path className="st1" d="M47.8,65.7c-0.1,0-0.2,0-0.3-0.1l-9-5.2c-0.2-0.1-0.3-0.3-0.3-0.6V49.4c0-0.2,0.1-0.4,0.3-0.6l9-5.2
		c0.2-0.1,0.4-0.1,0.6,0l9,5.2c0.2,0.1,0.3,0.3,0.3,0.6v10.4c0,0.2-0.1,0.4-0.3,0.6l-9,5.2C48,65.6,47.9,65.7,47.8,65.7z M39.4,59.4
		l8.4,4.8l8.4-4.8v-9.7l-8.4-4.8l-8.4,4.8V59.4z"/>
      </g>
      <g id="path7450">
        <path className="st1" d="M29.7,65.6c-0.1,0-0.2,0-0.3-0.1l-9-5.2C20.1,60.2,20,60,20,59.8V49.4c0-0.2,0.1-0.4,0.3-0.6l9-5.2
		c0.2-0.1,0.4-0.1,0.6,0l9,5.2c0.2,0.1,0.3,0.3,0.3,0.6v10.4c0,0.2-0.1,0.4-0.3,0.6l-9,5.2C29.9,65.6,29.8,65.6,29.7,65.6z
		 M21.3,59.4l8.4,4.8l8.4-4.8v-9.7l-8.4-4.8l-8.4,4.8V59.4z"/>
      </g>
      <g id="path7448">
        <path className="st1" d="M29.7,65.7C29.7,65.7,29.7,65.7,29.7,65.7c-0.4,0-0.6-0.3-0.6-0.6V44c0-0.2,0.1-0.4,0.3-0.6l8.9-5.2
		c0.2-0.1,0.4-0.1,0.6,0l18.2,10.5c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6l-18,10.4c-0.2,0.1-0.4,0.1-0.6,0l-8.1-4.7l0,9.4
		C30.3,65.4,30,65.7,29.7,65.7z M30.3,54.2l8.5,4.9l16.7-9.7l-16.9-9.8l-8.3,4.8V54.2z"/>
      </g>
      <g id="path7446">
        <rect x="38.1" y="38.8" className="st1" width="1.3" height="10.5"/>
      </g>
      <g id="path7414-8">
        <path className="st1" d="M38.6,38.9l9.1-5.3l-9-5.2l-9.1,5.3L38.6,38.9z"/>
        <path className="st1" d="M38.6,39.5c-0.1,0-0.2,0-0.3-0.1l-8.9-5.2C29.2,34.2,29,34,29,33.7c0-0.2,0.1-0.4,0.3-0.6l9.1-5.3
		c0.2-0.1,0.4-0.1,0.6,0l9,5.2c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6l-9.1,5.3C38.8,39.5,38.7,39.5,38.6,39.5z M31,33.7
		l7.6,4.4l7.8-4.5l-7.7-4.4L31,33.7z"/>
      </g>
      <g id="path7414-8-8">
        <path className="st1" d="M29.7,54.6l9.1-5.3l-9-5.2l-9.1,5.3L29.7,54.6z"/>
        <path className="st1" d="M29.7,55.3c-0.1,0-0.2,0-0.3-0.1l-9-5.2c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.2,0.1-0.4,0.3-0.6l9.1-5.3
		c0.2-0.1,0.4-0.1,0.6,0l9,5.2c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6L30,55.2C29.9,55.2,29.8,55.3,29.7,55.3z M22,49.4
		l7.7,4.5l7.8-4.5l-7.7-4.5L22,49.4z"/>
      </g>
      <g id="path7414-8-1">
        <path className="st1" d="M38.7,59.8l9.1,5.3V54.7l-9.1-5.3V59.8z"/>
        <path className="st1" d="M47.8,65.7c-0.1,0-0.2,0-0.3-0.1l-9.1-5.3C38.1,60.2,38,60,38,59.8V49.4c0-0.2,0.1-0.4,0.3-0.6
		c0.2-0.1,0.4-0.1,0.6,0l9.1,5.3c0.2,0.1,0.3,0.3,0.3,0.6V65c0,0.2-0.1,0.4-0.3,0.6C48,65.6,47.9,65.7,47.8,65.7z M39.3,59.4
		l7.8,4.5V55l-7.8-4.5V59.4z"/>
      </g>
    </svg>
  );
};

export default IndustryInnovationInfrastructureIcon;
