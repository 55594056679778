import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const NoPovertyIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
            .no-poverty{fill - rule:evenodd;clip-rule:evenodd;fill:#E62933;}
            .st1{fill:#FFFFFF;}
            .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
         `}
      </style>
      <path id="rect8528-7" className="no-poverty" d="M13,0.4h49C68.9,0.4,74.5,6,74.5,13V62c0,6.9-5.6,12.5-12.5,12.5H13C6.1,74.6,0.5,69,0.5,62
	V12.9C0.5,6,6.1,0.4,13,0.4z"/>
      <g id="text6065">
        <path id="path1875" className="st1" d="M23.9,13.3l-1.4-3.8v3.8h-0.9V7h0.8l1.4,3.8V7h0.9v6.2H23.9z"/>
        <path id="path1877" className="st1" d="M25.7,7.6c0.1-0.2,0.3-0.3,0.5-0.4C26.5,7.1,26.7,7,27,7s0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
		c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
		c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.2-0.1-0.3-0.1-0.4s0-0.2,0-0.4V8.7c0-0.2,0-0.3,0-0.5C25.6,7.9,25.6,7.8,25.7,7.6z M27.5,8.1c-0.1-0.2-0.3-0.2-0.5-0.2
		c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0.1,0.1,0.3
		c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.6c0-0.1,0-0.2,0-0.2
		C27.6,8.3,27.5,8.2,27.5,8.1z"/>
        <path id="path1879" className="st1" d="M21.7,21.9v-6.2h1.4l0,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.1,0.1,0.1,0.3,0.1,0.5v0.9V18c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.3,0.1-0.5,0.1h-0.1
		h-0.4v2.4L21.7,21.9L21.7,21.9z M23.5,18.4c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2V17c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		h-0.5v2.1H23c0.1,0,0.2,0,0.3,0C23.4,18.6,23.5,18.5,23.5,18.4z"/>
        <path id="path1881" className="st1" d="M25.6,16.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2s0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.1c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1s-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C25.4,16.5,25.5,16.4,25.6,16.3z
		 M27.3,16.7c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.1
		c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2v-3.2c0-0.1,0-0.2,0-0.2C27.4,16.9,27.4,16.8,27.3,16.7L27.3,16.7z"/>
        <path id="path1883" className="st1" d="M30.1,21.9l-1.3-6.2h0.9l0.7,4.3l0.7-4.3h0.9l-1.3,6.2H30.1z"/>
        <path id="path1885" className="st1"
          d="M35.4,21v0.9h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5V21L35.4,21L35.4,21z"/>
        <path id="path1887" className="st1" d="M35.8,21.9v-6.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3
		l0.9,2.8h-0.9l-0.8-2.7h-0.5v2.7H35.8z M37.8,18.3c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3v-0.6c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C37.5,18.4,37.6,18.4,37.8,18.3L37.8,18.3z"/>
        <path id="path1889" className="st1" d="M40.1,21.9v-5.4h-1v-0.8h3v0.8h-1v5.4H40.1z"/>
        <path id="path1891" className="st1"
          d="M43.3,21.9v-2.7l-1.2-3.5h0.9l0.7,2.4l0.7-2.4h0.9l-1.2,3.5v2.7H43.3z"/>
      </g>
      <g id="text6075">
        <path id="path1894" className="st1" d="M11.1,21.6V8.9L9,10.5V8.2l2.1-1.5h2.1v14.9H11.1z"/>
      </g>
      <path id="path6660" className="st2" d="M16.5,35.9c0,1.1-0.9,2-2,2l0,0c-1.1,0-2-0.9-2-2l0,0c0-1.1,0.9-2,2-2l0,0
	C15.6,33.8,16.5,34.7,16.5,35.9L16.5,35.9z"/>
      <path id="path6660-6" className="st2"
        d="M33.9,37c0,1.1-0.9,2-2,2l0,0c-1.1,0-2-0.9-2-2s0.9-2,2-2C33,35,33.9,35.9,33.9,37L33.9,37z"/>
      <path id="path6660-1" className="st2"
        d="M45.4,37c0,1.1-0.9,2-2,2l0,0c-1.1,0-2-0.9-2-2s0.9-2,2-2C44.5,35,45.4,35.9,45.4,37L45.4,37z"
      />
      <path id="path6660-7" className="st2" d="M61.6,35.9c0,1.1-0.9,2-2,2s-2-0.9-2-2l0,0c0-1.1,0.9-2,2-2l0,0C60.7,33.9,61.6,34.8,61.6,35.9
	z"/>
      <path id="path6660-2" className="st2" d="M24.4,46.2c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6l0,0
	C23.6,44.6,24.4,45.3,24.4,46.2L24.4,46.2z"/>
      <path id="path6660-2-9" className="st2" d="M53.5,46.2c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6l0,0
	C52.7,44.6,53.5,45.3,53.5,46.2L53.5,46.2z"/>
      <path id="path6858" className="st2" d="M59.1,48.5v8.4c0,1.4-2.1,1.4-2.1,0V42.2c0-0.5-0.7-0.6-0.7,0v4.9c0,1.1-1.5,1.2-1.5,0v-6
	c0-1.2,0.9-2.3,2.3-2.3h5c1.4,0,2.3,1,2.3,2.3v6c0,1.2-1.5,1.1-1.5,0v-5c0-0.6-0.7-0.5-0.7,0v14.7c0,1.4-2.1,1.4-2.1,0v-8.4
	C60,48,59.1,48,59.1,48.5z"/>
      <path id="path6853" className="st2" d="M11.9,38.8c-1.4,0-2.3,1-2.3,2.3v6c0,0.4,0.2,0.7,0.4,0.8l-1.8,9.3c-0.1,0.5,0.6,0.6,0.7,0.1
	l1.7-9.4c0.3-0.1,0.5-0.4,0.5-0.8v-5c0-0.6,0.7-0.5,0.7,0v14.7c0,1.4,2.1,1.4,2.1,0v-8.3c0-0.5,0.8-0.4,0.8,0v8.4
	c0,1.4,2.1,1.4,2.1,0V42.2c0-0.5,0.7-0.6,0.7,0v4.9c0,1.1,1.5,1.2,1.5,0v-6c0-1.2-0.9-2.3-2.3-2.3H11.9L11.9,38.8z"/>
      <path id="path6660-7-6-5-9-4" className="st2" d="M31.5,51V57c0,1.5-2,1.4-2,0V51h-2l2.2-7.9c0.1-0.3-0.5-0.6-0.6-0.1l-1.3,4.3
	c-0.3,1-1.7,0.6-1.3-0.5l1.7-5.6c0.2-0.6,0.8-1.1,1.5-1.1H34c0.7,0,1.3,0.5,1.5,1.1l1.7,5.6c0.3,1.2-1,1.5-1.3,0.5l-1.3-4.3
	c-0.1-0.4-0.7-0.2-0.6,0.1l2.2,7.9h-2V57c0,1.4-2,1.5-2,0V51H31.5z"/>
      <path id="path6813" className="st2" d="M43.1,51V57c0,1.5-2,1.4-2,0V51h-2l2.2-7.9c0.1-0.3-0.5-0.6-0.6-0.1l-1.3,4.3
	c-0.3,1-1.7,0.6-1.3-0.5l1.7-5.6c0.2-0.6,0.8-1.1,1.5-1.1h4.3c0.7,0,1.3,0.5,1.5,1.1l1.8,5.5c0.3,1.2-1,1.5-1.3,0.5l-1.3-4.3
	c-0.1-0.4-0.7-0.2-0.6,0.1l2.2,7.9h-2v5.9c0,1.4-2,1.5-2,0V51L43.1,51L43.1,51z"/>
      <path id="path6833" className="st2" d="M52.2,54.6v2.5c0,1,1.4,1.2,1.4,0v-5.9c0-0.3,0.3-0.3,0.4,0l0.7,2c0.2,0.7,1,0.4,0.7-0.3
	l-1.5-3.7c-0.1-0.3-0.4-0.6-0.9-0.6h-2.8c-0.4,0-0.7,0.2-0.9,0.6L48,52.9c-0.3,0.7,0.5,1,0.7,0.3l0.7-2c0.1-0.3,0.4-0.3,0.4,0v5.9
	c0,1.2,1.4,1,1.4,0v-2.5C51.5,54.2,52.2,54.2,52.2,54.6L52.2,54.6z"/>
      <path id="path6660-7-6-5-9-4-3-7-8" className="st2" d="M22.4,54.8v2.5c0,0.9-1.3,1-1.3,0v-2.8h-0.9l0.8-2.9c0.1-0.5,0-1-0.2-1.4
	l-1.4-2.4C19,47,20,46.6,20.3,47.1l1,1.6c0.1,0.2,0.3,0.2,0.5,0.2h1.9c0.2,0,0.4,0,0.5-0.2l1-1.6c0.3-0.5,1.3-0.1,0.9,0.6l-1.4,2.4
	c-0.2,0.4-0.3,0.9-0.2,1.4l0.8,2.9h-0.9v2.8c0,1-1.3,0.9-1.3,0v-2.5C23.2,54.2,22.4,54.2,22.4,54.8z"/>
    </svg>

  );
};

export default NoPovertyIcon;
