/*                                                                                                        */
/* 1. Component to select poi inside formik                                                               */
/* 2. a array of objects of all pois with their icon. translation id, osmTags value                       */
/* 3. a function that take a osmTags value, and return an reactElement with corresponding icon and name   */
/*                                                                                                        */

import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import TreeIcon from 'assets/icons/poi/tree_colorful.svg?react';
import SpringIcon from 'assets/icons/poi/spring.svg?react';
import RockIcon from 'assets/icons/poi/rock.svg?react';
import PondIcon from 'assets/icons/poi/pond.svg?react';
import WaterfallIcon from 'assets/icons/poi/waterfall.svg?react';
import CaveIcon from 'assets/icons/poi/cave.svg?react';
import CliffIcon from 'assets/icons/poi/cliff.svg?react';
import DryMeadowIcon from 'assets/icons/poi/dry_meadow.svg?react';
import SwampIcon from 'assets/icons/poi/swamp.svg?react';
import BirdHideIcon from 'assets/icons/poi/bird_hide.svg?react';
import ViewpointIcon from 'assets/icons/poi/viewpoint.svg?react';
import BenchIcon from 'assets/icons/poi/bench.svg?react';
import PlaygroundIcon from 'assets/icons/poi/playground.svg?react';
import ToiletsIcon from 'assets/icons/poi/toilets.svg?react';
import RestaurantIcon from 'assets/icons/poi/restaurant.svg?react';
import MuseumIcon from 'assets/icons/poi/museum.svg?react';
import HuntingStandIcon from 'assets/icons/poi/hunting_stand.svg?react';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import IntlMessages from 'helpers/IntlMessages';
import { SpecimenType } from 'services/graphqltypes';
import { Option } from 'components/custom/CustomReactSelect';
import { useIntl } from 'react-intl';

export type OsmTagType =
  | 'natural=tree'
  | 'natural=rock'
  | 'natural=pond'
  | 'natural=spring'
  | 'waterway=waterfall'
  | 'natural=cave_entrance'
  | 'natural=cliff'
  | 'natural=dry_meadow'
  | 'natural=swamp'
  | 'leisure=bird_hide'
  | 'tourism=viewpoint'
  | 'amenity=bench'
  | 'leisure=playground'
  | 'amenity=toilets'
  | 'amenity=restaurant'
  | 'tourism=museum'
  | 'amenity=hunting_stand'
  | 'other';

type PoiType = {
  type: string;
  icon: JSX.Element;
  value: OsmTagType;
  labelTranslationId: string;
}

export const pois: PoiType[] = [
  { type: 'nature', icon: <TreeIcon height={'2rem'} fill='#336600' />, value: 'natural=tree', labelTranslationId: "mapping.poi-natural=tree" },
  { type: 'nature', icon: <RockIcon height={'2rem'} />, value: 'natural=rock', labelTranslationId: "mapping.poi-natural=rock" },
  { type: 'nature', icon: <PondIcon height={'2rem'} />, value: 'natural=pond', labelTranslationId: "mapping.poi-natural=pond" },
  { type: 'nature', icon: <SpringIcon height={'2rem'} />, value: 'natural=spring', labelTranslationId: "mapping.poi-natural=spring" },
  { type: 'nature', icon: <WaterfallIcon height={'2rem'} stroke='white' />, value: 'waterway=waterfall', labelTranslationId: "mapping.poi-waterway=waterfall" },
  { type: 'nature', icon: <CaveIcon height={'2rem'} />, value: 'natural=cave_entrance', labelTranslationId: "mapping.poi-natural=cave_entrance" },
  { type: 'nature', icon: <CliffIcon height={'2rem'} />, value: 'natural=cliff', labelTranslationId: "mapping.poi-natural=cliff" },
  { type: 'nature', icon: <DryMeadowIcon height={'2rem'} />, value: 'natural=dry_meadow', labelTranslationId: "mapping.poi-natural=dry_meadow" },
  { type: 'nature', icon: <SwampIcon height={'2rem'} />, value: 'natural=swamp', labelTranslationId: "mapping.poi-natural=swamp" },
  { type: 'culture', icon: <BirdHideIcon height={'2rem'} />, value: 'leisure=bird_hide', labelTranslationId: "mapping.poi-leisure=bird_hide" },
  { type: 'culture', icon: <ViewpointIcon height={'2rem'} />, value: 'tourism=viewpoint', labelTranslationId: "mapping.poi-tourism=viewpoint" },
  { type: 'culture', icon: <BenchIcon height={'2rem'} />, value: 'amenity=bench', labelTranslationId: "mapping.poi-amenity=bench" },
  { type: 'culture', icon: <PlaygroundIcon height={'2rem'} />, value: 'leisure=playground', labelTranslationId: "mapping.poi-leisure=playground" },
  { type: 'culture', icon: <ToiletsIcon height={'2rem'} />, value: 'amenity=toilets', labelTranslationId: "mapping.poi-amenity=toilets" },
  { type: 'culture', icon: <RestaurantIcon height={'2rem'} />, value: 'amenity=restaurant', labelTranslationId: "mapping.poi-amenity=restaurant" },
  { type: 'culture', icon: <MuseumIcon height={'2rem'} />, value: 'tourism=museum', labelTranslationId: "mapping.poi-tourism=museum" },
  { type: 'culture', icon: <HuntingStandIcon height={'2rem'} />, value: 'amenity=hunting_stand', labelTranslationId: "mapping.poi-amenity=hunting_stand" },
  { type: 'culture', icon: <MapMarkerIcon size={'2rem'} color={'#0E8517'} />, value: 'other', labelTranslationId: "mapping.poi-other" }
];

export const naturePoiValues = ['natural=tree', 'natural=rock', 'natural=pond', 'natural=spring', 'waterway=waterfall', 'natural=cave_entrance', 'natural=cliff', 'natural=dry_meadow', 'natural=swamp'];

export const culturePoiValues = ['leisure=bird_hide', 'tourism=viewpoint', 'amenity=bench', 'leisure=playground', 'amenity=toilets', 'amenity=restaurant', 'tourism=museum', 'amenity=hunting_stand', 'other'];

/**
 * Custom hook to generate options for Points of Interest (POIs) based on type and default option.
 * @param type The type of POIs to return: 'nature', 'culture', or 'all'.
 * @param defaultAllOption Whether to include an "All" option at the beginning of the list.
 * @returns An array of Option objects containing label, value, and icon for each POI.
 */
export const usePoisOptions = (type: 'nature' | 'culture' | 'all', defaultAllOption: boolean) => {
  const intl = useIntl()
  const filteredPois = pois.filter(poi => type === 'all' || poi.type === type);

  // Memoize the transformed POI options
  const poisOptions = useMemo(() =>
    filteredPois.map(poi => ({
      label: intl.formatMessage({ id: poi.labelTranslationId }),
      value: poi.value,
      icon: React.cloneElement(poi.icon, {
        height: '1.25rem',
        width: '1.25rem',
        size: '1.25rem', // height and width for local svg icon, size for mdi icon
        className: 'mr-1'
      })
    } as Option)),
    [filteredPois, intl]
  );

  // Memoize the final array with the optional "all" option
  return useMemo(() => {
    if (defaultAllOption) {
      return [
        {
          label: intl.formatMessage({ id: 'species.ALL' }),
          value: type
        },
        ...poisOptions
      ];
    }
    return poisOptions;
  }, [poisOptions, defaultAllOption, type, intl]);
}

/**
 *  Generates icon, name, and combined icon-name elements for a given OSM tag.
 * @param osmTag - The OSM tag type to generate elements for.
 * @param size - The size for the icon (height and width).default value 1.25rem
 * @param classForName - CSS class for the name element.default value ml-2
 * @returns Object containing icon, name, and combined icon-name elements.
 */
export const getOsmTagIconAndName = (
  osmTag: OsmTagType,
  size: string = '1.25rem', // Default height if not provided
  classForName: string = 'ml-2'): {
    icon: React.ReactElement | null,
    name: React.ReactElement | null,
    iconName: React.ReactElement | null
  } => {
  const poi = pois.find(poi => poi.value === osmTag);
  let iconElement
  let name
  let iconName

  // error handling and fallback icon for Osm tag that is not on ark, (i.e., imported nature monuments)
  if (!poi) {
    console.warn(`POI type not found: ${osmTag}`);
    const fallbackPoiIcon = pois.find(poi => poi.value === 'other').icon;
    iconElement = React.cloneElement(fallbackPoiIcon, { height: size, width: size, size: size });
    name = <div className={classForName} > {osmTag.split('=')[1]}</div>;
    iconName =
      <div className='d-flex align-items-center'>
        {iconElement}
        {name}
      </div>
  } else {
    const { icon, labelTranslationId } = poi;
    iconElement = React.cloneElement(icon, { height: size, width: size, size: size }) // height and width for local svg icon, size for mdi icon
    name = <div className={classForName} > <IntlMessages id={labelTranslationId} /> </div>
    iconName = (
      <div className='d-flex align-items-center'>
        {iconElement}
        {name}
      </div>
    )
  }

  return { icon: iconElement, name, iconName };
}

const SelectPoi = ({ setShowPoiCategories, triggerValidateOnChange, className }: {
  setShowPoiCategories?: React.Dispatch<React.SetStateAction<boolean>>,
  triggerValidateOnChange?: boolean,
  className?: string,
}) => {
  const intl = useIntl();
  const { setFieldValue, validateForm } = useFormikContext();

  return (
    <div className={`row justify-content-around no-gutters ${className}`}>
      {pois.map((poi) => (
        <div className='col-4 d-flex p-1' key={poi.value}>
          <button
            type='button'
            className='btn-block btn btn-primary px-0 py-2'
            style={{ borderRadius: '10px' }}
            onClick={async () => {
              await Promise.all([
                setFieldValue('type', poi.value === 'natural=tree' ? 'TREE' : 'POI' as SpecimenType),
                setFieldValue('osmTags', poi.value as OsmTagType),
              ]); // wait for the field value to be changed before manually trigger re-validation of the sibling field
              triggerValidateOnChange && validateForm();
              setShowPoiCategories?.(false);
            }}
            aria-label={`Select ${intl.formatMessage({ id: poi.labelTranslationId })} POI`} //enhancing the accessibility for screen reader
          >
            <div className='d-flex flex-column align-items-center'>
              <div className='my-2'>
                {poi.icon}
              </div>
              <div>
                <IntlMessages id={poi.labelTranslationId} />
              </div>
            </div>

          </button>
        </div>
      ))
      }
    </div >
  )
}

export default SelectPoi;