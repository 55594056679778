import React from 'react';
import IconProps from './IconProps';

const CraftsmanshipDesignIcon = ({ style, color = '#868686', size, className }: IconProps): React.ReactElement<SVGSVGElement> => {
  return (
    <svg id="craftsmanshipDesignIcon" data-name="craftsmanshipDesignIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={style} fill={color} width={size} height={size} className={className}>
      <path d="M644.6,439.1l324-324c12-12,12-31.6,0-43.6c-12-12-31.6-12-43.6,0l-324,324l-76-76
		c22.9-87.7-1.7-179.9-66.5-244.6C389.2,5.5,288.1-17.8,195.1,14l-46.8,16L304,185.7L208,281.7L52.3,125.9l-16,46.8
		c-31.8,93.1-8.5,194,60.8,263.4c64.7,64.7,156.8,89.3,244.6,66.5l6.3,6.3L40.1,816.8L223.3,1000l307.9-307.9l219.7,219.7
		c25.3,25.2,58.4,37.9,91.6,37.9c33.2,0,66.4-12.6,91.6-37.9c50.5-50.5,50.5-132.7,0-183.2L644.6,439.1z M340.6,438.6
		c-70.7,24.2-147.3,6.5-199.8-46.1c-39.7-39.7-59.5-93.1-56.4-147.3L208,368.9l183.2-183.2L267.6,62c54.6-3,107.6,16.7,147.3,56.4
		c52.6,52.6,70.2,129.2,46.1,199.8l-6.2,18.2l102.7,102.6l-29.2,29.2l-69.8-69.8l-66.7,66.7l-32.9-32.9L340.6,438.6z M223.3,912.8
		l-95.9-95.9l331-331l96,95.9L223.3,912.8z M890.5,868.2c-26.4,26.4-69.5,26.5-96,0L574.8,648.5l66.7-66.7L571.8,512l29.2-29.2
		l289.5,289.5C917,798.7,917,841.7,890.5,868.2z"/>
    </svg>
  );
};

export default CraftsmanshipDesignIcon;
