import { Colxx } from 'components/custom/CustomBootstrap';
import { UserContext } from 'contexts/user/UserContextProvider';
import IntlMessages from 'helpers/IntlMessages';
import { getAuthorizeURI, isWechat, setLoginPayload } from 'helpers/helper';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Alert, Card, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { NavLink, Redirect, useLocation } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import SocialLoginOptions from './components/SocialLoginOptions';

const Login = ({ history }: any) => {
  const { alertId, variant } = history.location?.state || {};
  const { isAuthenticated, loading } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (alertId) {
      setShow(true);
    }
  }, [alertId]);

  if (isWechat) {
    const redeemCode = new URLSearchParams(location.search).get('redeemCode');
    let redirectURL;
    if (redeemCode) {
      redirectURL = `/profile?redeemCode=${redeemCode}`;
    } else if (history.location.state?.from) {
      redirectURL = history.location.state.from;
    } else {
      redirectURL = '/profile';
    }
    setLoginPayload('social-login');
    window.location.href = `${getAuthorizeURI('wechat', redirectURL)}`;
    return <div className="loading" />;
  }

  if (loading) { return <div className="loading" />; }

  return !isAuthenticated ? (
    <div className="modal-dialog-centered mt-2 mb-2">
      <div className="container">
        <Row>
          <Colxx xxs="12" md="10" className="mx-auto my-auto">
            <Card className="auth-card">
              <div className="position-relative image-side d-none d-lg-block">
              </div>
              <div className="form-side">
                <div className="alert">
                  <Alert variant={variant || 'danger'} show={show} onClose={() => setShow(false)} dismissible>
                    <IntlMessages id={alertId} />
                  </Alert>
                </div>
                <NavLink to="/" className="ml-5 mb-4 white d-flex justify-content-center">
                  <span className="logo-single" />
                </NavLink>
                <SocialLoginOptions isMobileBrowserChecker={isMobile} isWechatChecker={isWechat} />
                <h2 className="mt-5 mb-4">
                  <IntlMessages id="user.login-with-email" />
                </h2>
                <LoginForm />
              </div>
            </Card>
          </Colxx>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to={'/profile'} />
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
