import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const InfoIcon = ({ style, size = 26, className, id }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg"
      style={style}  width={size} height={size} className={className} id={id}>
      <path d="M14 29C21.732 29 28 22.5081 28 14.5C28 6.49187 21.732 0 14 0C6.26801 0 0 6.49187 0 14.5C0 22.5081 6.26801 29 14 29Z" fill="#60B6AA"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9446 21.5484V11.479H12.0557V11.479H10.1114V13.4929H12.0557L12.0557 21.5484H10.1113V23.5623H17.8891V21.5484H15.9446Z" fill="white"/>
      <ellipse cx="14.0001" cy="7.45139" rx="1.94444" ry="2.01389" fill="white"/>
    </svg>

  );
};

export default InfoIcon;
