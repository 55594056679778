import english from './locales/en.json';
import german from './locales/de_DE.json';
import chinese from './locales/zh_CN.json';
import turkish from './locales/tr_TR.json';
import italian from './locales/it_IT.json';
import korean from './locales/ko_KR.json';
import russian from './locales/ru_RU.json';
import spanish from './locales/es_ES.json';
import french from './locales/fr_FR.json';
import dutch from './locales/nl_NL.json';
import georgian from './locales/ka_KA.json';
import greek from './locales/el_EL.json';
import czech from './locales/cs.json';

// Define the type for AvailableLanguages
export interface TranslationStrings {
  [id: string]: {
      defaultMessage: string;
      description?: string;
  };
}

type AvailableLanguagesType = {
  [key: string]: TranslationStrings;
};

// uses ISO-639-1 codes standard
const availableLanguages: AvailableLanguagesType = {
  'en': english,
  'zh-CN': chinese,
  'de': german,
  'cs': czech,
  'tr': turkish,
  'it': italian,
  'ko': korean,
  'ru': russian,
  'es': spanish,
  'fr': french,
  'nl': dutch,
  'ka': georgian,
  'el': greek,
};

export default availableLanguages;
