import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const Oak = ({ style, size = 45, className }: IconProps): React.ReactElement =>{
  return (
    <svg version="1.1" id="oak" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`  
            .circle-oak{fill:#FFFFFF;stroke:#60B6AA;stroke-miterlimit:10;}
            .path-oak{fill:#60B6AA;} 
       `}
      </style>
      <circle className="circle-oak" cx="16.3" cy="16.3" r="15.7" />
      <g>
        <path className="path-oak" d="M23,23.2c-0.6,0.4-1.3,0.4-2,0.3c-0.8-0.1-1.6,0-2.3,0.4c-0.2,0.1-0.5,0.2-0.7,0c-0.3-0.2-0.2-0.5-0.1-0.8
		c0.1-0.2,0.3-0.5,0.5-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1-0.1-0.1c-0.2,0-0.4,0-0.6,0.1c-0.7,0.2-1.4,0.5-2,0.8
		c-0.5,0.2-0.9,0.2-1.1-0.1c-0.3-0.4-0.2-0.9,0.1-1.3c0.2-0.3,0.5-0.5,0.7-0.7c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
		c-0.4,0.1-0.9,0.2-1.3,0.4c-0.7,0.2-1.3,0.5-2,0.6c-0.4,0.1-0.9,0.1-1.3,0c-0.5-0.1-0.6-0.5-0.4-0.9c0.3-0.5,0.7-0.9,1.2-1.2
		c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.2,0.2-0.5-0.1-0.6c-0.3-0.1-0.6-0.3-0.9-0.3c-0.7-0.2-1.3-0.3-2-0.4c-0.3-0.1-0.6-0.2-0.9-0.3
		c-0.6-0.3-0.6-0.9-0.1-1.2c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.2,0.7-0.4,1-0.6c0.3-0.2,0.3-0.5,0-0.7c-0.3-0.2-0.6-0.3-0.9-0.4
		c-0.7-0.4-1.4-0.7-2.1-1.2c-0.9-0.6-0.8-1.4,0.2-1.8c0.2-0.1,0.3-0.1,0.5-0.1c0.3-0.1,0.4-0.4,0.2-0.6C7.9,9.5,7.6,9.2,7.4,8.9
		C7,8.4,7,7.8,7.5,7.3c0.5-0.5,1.2-0.6,1.7-0.1C9.5,7.3,9.8,7.6,10,7.8c0.3,0.3,0.5,0.2,0.6-0.2c0.1-0.3,0.3-0.7,0.5-0.9
		c0.4-0.4,0.7-0.4,1.2,0c0.6,0.6,1,1.3,1.3,2C13.7,9,13.9,9.4,14,9.7c0.3,0.6,0.6,0.6,1,0c0.2-0.4,0.4-0.9,0.6-1.3
		C15.8,8.2,16,8,16.3,7.8c0.3-0.2,0.6-0.1,0.7,0.2c0.2,0.4,0.3,0.9,0.5,1.3c0.1,0.5,0.2,1.1,0.4,1.6c0.1,0.3,0.2,0.6,0.4,0.9
		c0.2,0.3,0.4,0.4,0.7,0.1c0.3-0.3,0.5-0.7,0.8-1.1c0.3-0.3,0.6-0.6,1-0.9c0.4-0.3,0.8-0.1,0.9,0.4c0.1,0.5,0.1,1,0,1.5
		c-0.1,0.6-0.4,1.2-0.6,1.9c-0.1,0.3-0.2,0.7-0.3,1c0,0.2,0.1,0.3,0.1,0.5c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.3,0.5-0.5
		c0.2-0.2,0.4-0.4,0.7-0.4c0.3-0.1,0.6,0,0.9,0.3c0.2,0.3,0.2,0.5,0.1,0.8c-0.3,0.7-0.5,1.3-0.8,2c-0.1,0.3-0.1,0.6-0.2,0.8
		c0,0,0.1,0,0.1,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.5-0.4,0.8-0.5c0.2-0.1,0.5-0.1,0.7,0.1c0.2,0.2,0.1,0.5,0,0.7
		c-0.2,0.5-0.4,1.1-0.5,1.6c-0.1,0.4,0.1,0.8,0,1.2c-0.1,0.5-0.2,1-0.3,1.5c0,0.1-0.1,0.1-0.2,0.2c-1.2-1.4-1.5-1.7-0.7-3.4
		c0,0-0.1,0-0.1,0c-0.2,0.5-0.5,1-0.7,1.5c-0.3-0.2-0.7-0.5-1-0.7c-0.2-0.2-0.4-0.5-0.3-0.7c0-0.5,0.2-1,0.3-1.6
		c0.1-0.3,0.2-0.5,0.3-0.8c-0.5,0.6-0.9,1.7-0.9,2.7c-0.7-0.4-1.3-0.7-1.3-1.6c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.6-0.1-1.1,0.1-1.7
		c0.2-0.7,0.4-1.3,0.6-2c0,0-0.1,0-0.1,0c-0.3,0.8-0.7,1.6-0.8,2.5c-0.1,0.9,0,1.7,0.1,2.7c-0.3-0.2-0.6-0.5-0.8-0.7
		c-0.5-0.5-1.1-1.1-1.6-1.6c-0.1-0.1-0.2-0.4-0.2-0.5c0.1-0.5,0.2-1,0.3-1.5c0.2-0.8,0.3-1.6-0.1-2.4c-0.3-0.7-0.4-1.3-0.3-2
		c-0.3,0.5-0.3,1.1-0.2,1.7c0.1,0.3,0.1,0.6,0.2,0.9c0.2,0.5,0.1,1,0,1.5c-0.2,0.6-0.3,1.2-0.5,1.8c-0.4-0.4-0.8-0.8-1.2-1.2
		c-0.8-0.9-1.5-1.8-2.2-2.7c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.9-0.1-1.8-0.1-2.6c0,0-0.1,0-0.1,0c0,0.9,0.1,1.7,0.1,2.6c0,0-0.1,0-0.1,0
		c-0.8-0.8-1.6-1.6-2.4-2.4c0,0,0,0,0,0c0.7,0.8,1.4,1.6,2.1,2.4c0,0,0,0.1,0,0.1c-0.6,0-1.1,0-1.7-0.1c0,0,0,0.1,0,0.1
		c0.5,0,1,0,1.6,0.1c0.2,0,0.4,0.1,0.5,0.2c0.7,0.9,1.5,1.8,2.2,2.7c0.5,0.6,1,1.1,1.5,1.7c-0.9,0.1-1.7,0.3-2.6-0.2
		c-0.3-0.2-0.7-0.2-1-0.2c-0.6,0-1.2,0.1-1.8,0.2c0.6,0,1.2,0,1.7,0.1c0.3,0,0.7,0.1,1,0.2c0.8,0.4,1.6,0.6,2.4,0.4
		c0.5-0.1,0.8,0,1.2,0.4c0.5,0.6,1.1,1.1,1.7,1.7c-0.5-0.1-0.9-0.2-1.2-0.2c-0.8,0-1.6,0-2.4,0.1c-0.7,0.1-1.3,0.6-1.8,1.1
		c1.1-0.9,2.4-1.1,3.7-1c1.3,0.2,2.6,0.2,3.6,1.3c-0.3,0.1-0.7,0.2-1,0.2c-1.2,0.1-1.2,0.1-2.4,0.9c0.1,0,0.1,0,0.1,0
		c0,0,0.1,0,0.1,0c1-0.7,2.2-0.5,3.3-0.7c0.6-0.1,0.9,0.3,1.3,0.6c0.3,0.2,0.4,0.5,0.7,0.8c-0.6,0-1.1,0-1.6,0c0,0,0,0.1,0,0.1
		c0.2,0,0.3,0,0.5,0C21.8,22.2,22.5,22.5,23,23.2L23,23.2z"/>
        <path className="path-oak" d="M23,23.2c0.1-0.1,0.2-0.1,0.3-0.2c0.5,0.7,1,1.3,1.6,2c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0,0.3,0,0.5
		c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.3-0.4-0.5C23.8,24.6,23.4,23.9,23,23.2C23,23.2,23,23.2,23,23.2z"/>
      </g>
    </svg>
  );
};

export default Oak;
