import React, { useEffect } from 'react';
import { resetPassword } from 'services/auth';
import { FormLabel, FormGroup, Form, Button } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import IntlMessages from 'helpers/IntlMessages';
import { getCodeFromError, getFieldError } from 'helpers/errors';
import { useLocation, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ResetSchema = yup.object().shape({
  newpassword: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

const ResetForm = ({ setAlert, setShow }) => {
  const history = useHistory();
  const intl = useIntl();
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('user_id');
  const token = new URLSearchParams(search).get('token');
  useEffect(() => {
    if (!(userId && token)) {
      history.push('/login', { alertId: 'user.reset-access-denial', variant: 'danger' });
    }
  }, [userId, token, history]);
  async function handleSubmit(values, { setFieldError, setSubmitting, setFieldValue }) {
    if (values.newpassword === values.password) {
      try {
        const success = await resetPassword(userId, token, values.password);
        setSubmitting(false);
        // Success: refetch
        if (success) {
          history.push('/login', { alertId: 'user.reset-success', variant: 'success' });
        } else {
          setAlert('user.reset-token-incorrect');
          setShow(true);
          setFieldValue('password', '', false);
        }
      } catch (e) {
        console.error(e);
        const code = getCodeFromError(e);
        const [fieldname, messageId] = getFieldError(code);
        const errMessage = intl.formatMessage({ id: messageId });
        if (errMessage) setFieldError(fieldname, errMessage);
        setFieldValue('password', '', false);
      }
    } else {
      const errMessage = <IntlMessages id="user.password-not-match" />;
      setFieldError('password', errMessage);
      setFieldValue('password', '', false);
    }
  }
  return (
    <div>
      <Formik
        initialValues={{
          newpassword: '',
          password: '',
        }}
        validationSchema={ResetSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup className="form-group has-float-label">
              <FormLabel>
                <IntlMessages id="user.new-password" />
              </FormLabel>
              <Field
                type="password"
                name="newpassword"
                id="newpassword"
                placeholder="New Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newpassword}
                className={errors.newpassword ? 'form-control is-invalid' : 'form-control'}
              />
              {touched.newpassword && errors.newpassword ? (
                <div className="invalid-feedback">{errors.newpassword}</div>
              ) : null}
            </FormGroup>

            <FormGroup className="form-group has-float-label">
              <FormLabel>
                <IntlMessages id="user.confirm-new-password" />
              </FormLabel>
              <Field
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={errors.password ? 'form-control is-invalid' : 'form-control'}
              />
              {touched.password && errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
            </FormGroup>

            <div className="submit-container">
              <div className="mb-3">
                <Button block variant="primary" type="submit" disabled={isSubmitting}>
                  <span className="label">
                    <IntlMessages id="user.change-password" />
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ResetForm.propTypes = {
  setAlert: PropTypes.func,
  setShow: PropTypes.func,
};
export default ResetForm;
