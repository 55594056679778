import IconProps from '../LearningPathsIcons/IconProps';

const LeavesIcon = ({ style, size = 30, className, color = '#A0A0A0', strokeWidth = '2' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path d="M3.88135 10.5264C6.40766 12.7369 11.9656 18.9264 13.9866 26.0001" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" />
      <path d="M15.2497 21.8947C15.3549 19.5789 14.5549 14.1263 10.5128 10.8421C6.47073 7.55787 2.51283 6.73682 1.03915 6.73682C0.933886 9.89471 0.948713 14.9469 2.3023 18.1052C4.19706 22.5263 6.72331 24.1052 10.5128 25.0526" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" />
      <path d="M10.5129 10.8421C11.9866 8.21053 16.5129 2.75789 22.8287 2C22.8287 2.31579 22.8287 3.38947 22.8287 5.15789C22.8287 7.36842 23.4603 13.0526 21.5656 15.8947C19.6708 18.7368 16.1972 20 15.2498 19.6842" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" />
      <path d="M13.9866 14.9473C14.8104 13.0316 17.1005 8.70734 19.6708 6.73682" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" />
    </svg>
  );
};

export default LeavesIcon;
