import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const PartnershipForTheGoalsIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="partnershipForTheGoals" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
           .partnership-for-the-goals{fill - rule:evenodd;clip-rule:evenodd;fill:#1E3765;}
           .st1{fill:#FFFFFF;}
         `}
      </style>
      <g id="rect8528-63-2-4-3-6-0-4-3-6-3-7-4-9-4">
        <path className="partnership-for-the-goals" d="M61.7,1c6.8,0,12.3,5.5,12.3,12.3v48.5c0,6.8-5.5,12.3-12.3,12.3H13.3C6.5,74.2,1,68.6,1,61.8V13.3
		C1,6.5,6.5,1,13.3,1C13.3,1,61.7,1,61.7,1z"/>
      </g>
      <g id="text6065-3-2-9-6-6-5-5-2-9-7-0">
        <g id="path2628">
          <path className="st1" d="M24.7,13.5V7.4h1.4l0,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
			c0.1,0.1,0.1,0.3,0.1,0.5v0.9v0.1c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.3,0.1-0.5,0.1
			h-0.1h-0.4v2.4H24.7L24.7,13.5z M26.6,10.1c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.8c0-0.2,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5v2.1h0.4c0.1,0,0.2,0,0.3,0C26.5,10.2,26.6,10.2,26.6,10.1L26.6,10.1z"/>
        </g>
        <g id="path2630">
          <path className="st1"
            d="M27.7,13.5l1.4-6.2h0.7l1.4,6.2h-0.9L30,12.2h-1.1l-0.2,1.3H27.7z M29,11.4h0.8l-0.4-2.2L29,11.4z"/>
        </g>
        <g id="path2632">
          <path className="st1" d="M31.7,13.5V7.4h1.6c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8H34l-0.8-2.7
			h-0.5v2.7H31.7z M33.6,9.9c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V8.8c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2h-0.6v1.9h0.6
			C33.3,10.1,33.5,10.1,33.6,9.9L33.6,9.9z"/>
        </g>
        <g id="path2634">
          <path className="st1" d="M35.9,13.5V8.2h-1V7.4h3v0.8h-1v5.4L35.9,13.5L35.9,13.5z"/>
        </g>
        <g id="path2636">
          <path className="st1" d="M40.4,13.5l-1.4-3.8v3.8h-0.9V7.4H39l1.4,3.8V7.4h0.9v6.2H40.4z"/>
        </g>
        <g id="path2638">
          <path className="st1"
            d="M44.4,12.7v0.9h-2.7V7.4h2.7v0.8h-1.8V10h1.5v0.9h-1.5v1.8L44.4,12.7L44.4,12.7z"/>
        </g>
        <g id="path2640">
          <path className="st1" d="M44.8,13.5V7.4h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.6c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9
			l-0.8-2.7h-0.5v2.7H44.8z M46.8,9.9c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3V8.8c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9
			h0.6C46.5,10.1,46.7,10.1,46.8,9.9L46.8,9.9z"/>
        </g>
        <g id="path2642">
          <path className="st1" d="M49,12c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0
			c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1V12v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.6-0.2s-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1V8.9c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
			c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9V8.9c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
			c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
			c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5s-0.1,0.3-0.4,0.6
			c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2L49,12L49,12L49,12z"/>
        </g>
        <g id="path2644">
          <path className="st1" d="M51.7,13.5V7.4h0.9V10h1.1V7.4h0.9v6.2h-0.9v-2.8h-1.1v2.8H51.7z"/>
        </g>
        <g id="path2646">
          <path className="st1" d="M55.5,13.5V7.4h0.9v6.2H55.5z"/>
        </g>
        <g id="path2648">
          <path className="st1" d="M57.1,13.5V7.4h1.4l0,0c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
			c0.1,0.1,0.1,0.3,0.1,0.5v0.9v0.1c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.3,0.1-0.5,0.1
			h-0.1H58v2.4H57.1L57.1,13.5z M59,10.1c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2V8.8c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H58
			v2.1h0.4c0.1,0,0.2,0,0.3,0C58.8,10.2,58.9,10.2,59,10.1L59,10.1z"/>
        </g>
        <g id="path2650">
          <path className="st1" d="M61.3,12c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2s0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0
			c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1V12v-0.4v-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1V8.9c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
			c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9V8.9c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
			c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
			c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5s-0.1,0.3-0.4,0.6
			c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2L61.3,12L61.3,12L61.3,12z"/>
        </g>
        <g id="path2652">
          <path className="st1" d="M24.7,22.1v-6.2h2.6v0.8h-1.8v1.9h1.5v0.8h-1.5v2.7L24.7,22.1L24.7,22.1z"/>
        </g>
        <g id="path2654">
          <path className="st1" d="M27.9,16.5c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2s0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
			c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C27.7,16.8,27.8,16.6,27.9,16.5L27.9,16.5z M29.5,17
			c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1
			s0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2s0-0.1,0-0.2
			v-3.2c0-0.1,0-0.2,0-0.2C29.6,17.1,29.6,17.1,29.5,17L29.5,17z"/>
        </g>
        <g id="path2656">
          <path className="st1" d="M31.3,22.1v-6.2h1.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V18c0,0.6-0.2,1-0.7,1.3l0.9,2.8h-0.9
			l-0.8-2.7h-0.5v2.7H31.3L31.3,22.1z M33.2,18.5c0,0,0.1-0.1,0.1-0.2s0-0.2,0-0.3v-0.6c0-0.2,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.2-0.4-0.2h-0.6v1.9h0.6C33,18.6,33.1,18.6,33.2,18.5L33.2,18.5z"/>
        </g>
        <g id="path2658">
          <path className="st1" d="M36.5,22.1v-5.4h-1v-0.8h3v0.8h-1v5.4C37.4,22.1,36.5,22.1,36.5,22.1z"/>
        </g>
        <g id="path2660">
          <path className="st1" d="M38.8,22.1v-6.2h0.9v2.6h1.1v-2.6h0.9v6.2h-0.9v-2.8h-1.1v2.8H38.8z"/>
        </g>
        <g id="path2662">
          <path className="st1" d="M45.1,21.2v0.9h-2.7v-6.2h2.7v0.8h-1.8v1.8h1.5v0.8h-1.5v1.8H45.1z"/>
        </g>
        <g id="path2664">
          <path className="st1" d="M49.2,21.4c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7-0.1
			c-0.2-0.1-0.4-0.2-0.5-0.4s-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0-0.5v-3.1c0-0.1,0-0.3,0-0.4s0.1-0.4,0.3-0.6c0.2-0.3,0.5-0.4,1.1-0.4
			c0.2,0,0.5,0,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3,0,0.5v0.2h-0.9v-0.2c0,0,0-0.1,0-0.1
			s0-0.1,0-0.1c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0-0.5,0.2s-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.2v3.1
			c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.5,0.2s0.4,0,0.4-0.2c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1
			c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v-1.1h-0.7v-0.8h1.6v1.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2C49.4,21.3,49.4,21.4,49.2,21.4
			C49.3,21.3,49.3,21.4,49.2,21.4L49.2,21.4z"/>
        </g>
        <g id="path2666">
          <path className="st1" d="M50.4,16.5c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2s0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
			c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.5v3.2c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4v-3.2c0-0.2,0-0.3,0-0.5C50.3,16.8,50.3,16.6,50.4,16.5L50.4,16.5z M52.2,17
			c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.2c0,0,0,0.1,0,0.2v3.2c0,0,0,0,0,0.1
			s0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
			c0,0,0-0.1,0-0.2v-3.2c0-0.1,0-0.2,0-0.2C52.2,17.1,52.2,17.1,52.2,17L52.2,17z"/>
        </g>
        <g id="path2668">
          <path className="st1"
            d="M53.5,22.1l1.4-6.2h0.7l1.4,6.2h-0.9l-0.2-1.3h-1.1l-0.2,1.3H53.5z M54.9,19.9h0.8l-0.4-2.2L54.9,19.9z"/>
        </g>
        <g id="path2670">
          <path className="st1" d="M60.1,22.1h-2.6v-6.2h0.9v5.3h1.7V22.1L60.1,22.1z"/>
        </g>
        <g id="path2672">
          <path className="st1" d="M61,20.6c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2s0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0
			c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.1v-0.4V20c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-1v-0.3c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1-0.4
			c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1v0.3h-0.9v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
			c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2s-0.1,0.3-0.1,0.5v0.3v0.1c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.3,0.2
			c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.2,0.3,0.3,0.6,0.3,1v0.5c0,0.2,0,0.4,0,0.5
			c0,0.1-0.1,0.3-0.4,0.6c-0.2,0.2-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1v-0.2h0.9L61,20.6L61,20.6z"/>
        </g>
      </g>
      <g id="text6075-0-6-9-2-5-9-1-4-7-6-6">
        <g id="path2675">
          <path className="st1" d="M9.7,22.1V9.6l-2.1,1.6V8.9l2.1-1.5h2.1v14.8H9.7z"/>
        </g>
        <g id="path2677">
          <path className="st1" d="M14.9,11.1H13V7.4h6.3v1.9l-3.5,12.8h-2.3l3.6-12.8h-2.3V11.1z"/>
        </g>
      </g>
      <g id="path8491">
        <path className="st1" d="M29.4,55.2c-5.3,0-9.5-4.3-9.5-9.5c0-5.3,4.3-9.5,9.5-9.5s9.5,4.3,9.5,9.5C38.9,50.9,34.6,55.2,29.4,55.2z
		 M29.4,38.1c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S33.6,38.1,29.4,38.1z"/>
      </g>
      <g id="path8491-5">
        <path className="st1" d="M38,48.8c-5.3,0-9.5-4.3-9.5-9.5s4.3-9.5,9.5-9.5c5.3,0,9.5,4.3,9.5,9.5S43.3,48.8,38,48.8z M38,31.8
		c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S42.2,31.8,38,31.8z"/>
      </g>
      <g id="path8491-6">
        <path className="st1" d="M43.3,65.2c-5.3,0-9.5-4.3-9.5-9.5s4.3-9.5,9.5-9.5s9.5,4.3,9.5,9.5S48.6,65.2,43.3,65.2z M43.3,48.1
		c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S47.5,48.1,43.3,48.1z"/>
      </g>
      <g id="path8491-0">
        <path className="st1" d="M46.6,55c-5.3,0-9.5-4.3-9.5-9.5c0-5.3,4.3-9.5,9.5-9.5c5.3,0,9.5,4.3,9.5,9.5C56.1,50.7,51.9,55,46.6,55z
		 M46.6,37.9c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6C54.2,41.3,50.8,37.9,46.6,37.9z"/>
      </g>
      <g id="path8491-52">
        <path className="st1" d="M32.6,65.3c-5.3,0-9.5-4.3-9.5-9.5c0-5.3,4.3-9.5,9.5-9.5s9.5,4.3,9.5,9.5C42.2,61,37.9,65.3,32.6,65.3z
		 M32.6,48.2c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S36.8,48.2,32.6,48.2z"/>
      </g>
    </svg>

  );
};

export default PartnershipForTheGoalsIcon;
