// this Modal is used in SpecimensTab, will show to people [has no territoryId && visiting his own SpecimensTab].
import { DocumentNode, gql, useMutation } from '@apollo/client';
import CustomSpinner from 'components/common/CustomSpinner';
import CustomReactSelect, { Option } from 'components/custom/CustomReactSelect';
import { UserContext } from 'contexts/user/UserContextProvider';
import IntlMessages from 'helpers/IntlMessages';
import useCommonsOptions from 'hooks/useCommonsOptions';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Territory } from 'services/graphqltypes';

const UPDATE_USER_TERRITORY = gql`
mutation updateUserTerritory ($currentTerritoryId: UUID!, $userId: UUID!) {
  updateUser(
    input: { patch: { currentTerritoryId: $currentTerritoryId }, id: $userId }
  ) {
    user {
      id
      currentTerritoryId
    }
  }
}
`;


const JoinACommonsModal = ({
  showJoinACommonsModal,
  setShowJoinACommonsModal,
  userCommons,
  refetchQuery, // we need to trigger an useQuery refetch from the inside of this modal
  onHideAction, // currently used in MappingList to set the newly selected territoryId and trigger data refetch
}: {
  showJoinACommonsModal: boolean,
  setShowJoinACommonsModal: React.Dispatch<SetStateAction<boolean>>,
  userCommons?: Territory
  refetchQuery?: { queryConfig: { query: DocumentNode, variables?: {} }, queryName?: string },
  onHideAction?: Function,
}): React.ReactElement => {
  const intl = useIntl();
  const [displaySpinner, setDisplaySpinner] = useState(false);

  // get the list of commons options
  const { commonsOptions } = useCommonsOptions();
  const [selectedCommonsOption, setSelectedCommonsOption] = useState(
    commonsOptions?.find((commons: Option) => commons.value === userCommons?.id)
  );

  useEffect(() => {
    setSelectedCommonsOption(commonsOptions?.find((commons: Option) => commons.value === userCommons?.id));
  }, [commonsOptions, userCommons]);

  // get current userID
  const currentUser = useContext(UserContext)['currentUser'];

  const [updateUserTerritory] = useMutation(UPDATE_USER_TERRITORY, {
    refetchQueries: [
      refetchQuery?.queryConfig,
      refetchQuery?.queryName,
    ]
  });

  return (
    <Modal
      show={showJoinACommonsModal}
      onHide={() => {
        setShowJoinACommonsModal(false);
        onHideAction && onHideAction(null); // if closed without getting a new Commons, must set null -> user redirected to /profile
      }} /* prevent hiding the modal on the create edit specimen page */
      className="rounded-modal"
      backdrop="static" /* prevent closing modal when clicked outside it*/
    >
      <Modal.Header closeButton className="mr-3 mt-3 p-0" />
      <Modal.Body className="pt-0 mt-0">
        <h1 className="mb-4 mt-2 font-weight-bolder"><IntlMessages id='bioregion.join-a-commons' /></h1>
        <div className="mb-4 d-flex flex-column">
          <p className='h5'>
            <IntlMessages id='bioregion.join-a-commons.explain' />
          </p>
          <p className='h5'>
            <IntlMessages id='bioregion.join-a-commons.explain-2' />
          </p>
          <p className='mt-4 h5'>
            <IntlMessages id='bioregion.join-a-commons.dropdown-explain' />
          </p>
          <p className='h5 font-weight-bolder'>
            <IntlMessages id='bioregion.join-a-commons.dropdown' />
          </p>
          <CustomReactSelect
            name="selectCommons"
            hasIcon={false}
            options={commonsOptions}
            placeholder={intl.formatMessage({ id: 'general.select-or-type-to-search' })}  //
            selected={selectedCommonsOption}
            handleChange={(option: Option) => {
              setSelectedCommonsOption(commonsOptions?.find((commons: Option) => commons.value === option?.value));
            }}
          />
        </div>
        <div className='mt-5 d-flex justify-content-around'>
          <button className=" mx-auto btn btn-outline-primary mb-3"
            onClick={() => {
              setShowJoinACommonsModal(false);
              onHideAction && onHideAction(null); // if closed without getting a new Commons, must set null -> user redirected to /profile
            }}
          >
            <IntlMessages id={'onboarding.later'} />
          </button>
          <button className=" mx-auto btn btn-primary mb-3"
            disabled={!selectedCommonsOption}
            onClick={() => {
              setDisplaySpinner(true);
              updateUserTerritory({
                variables: {
                  currentTerritoryId: selectedCommonsOption?.value,
                  userId: currentUser?.id,
                },
              }).then(() => {
                setDisplaySpinner(false);
                setShowJoinACommonsModal(false);
                onHideAction && onHideAction(selectedCommonsOption?.value); // pass new territory id to MappingList -> refetch
              });
            }}>
            {displaySpinner ?
              <CustomSpinner />
              :
              <IntlMessages id={'general.confirm'} />}
          </button>
        </div>


      </Modal.Body>
    </Modal >

  );
};

export default JoinACommonsModal;
