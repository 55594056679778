import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
const EmailAlreadyExists = 'EMTKN';
const UsernameAlreadyExists = 'NUNIQ';
const UserNameNotAllowed = '23514';
const UserNamePasswordIncorrect = 'CREDS';
const WeakPassword = 'WEAKP';
const VerifyInvalidInput = '22P02';

export class ExtraError extends Error {
  messageId: string;
  redirect?: string;
  reload?: boolean;
  constructor(message: string, messageId: string, redirect?: string, reload?: boolean) {
    super(message);
    this.messageId = messageId;
    this.redirect = redirect;
    this.reload = reload ? reload : false;
  }
}
function extractError(_error: null): null;
function extractError(_error: Error): Error;
function extractError(_error: ApolloError): GraphQLError;
function extractError(_error: GraphQLError): GraphQLError;
function extractError(_error: null | Error | ApolloError | GraphQLError): null | Error | GraphQLError;
function extractError(error: null | Error | ApolloError | GraphQLError): null | Error | GraphQLError {
  return (
    (error &&
      'graphQLErrors' in error &&
      error.graphQLErrors &&
      error.graphQLErrors.length &&
      error.graphQLErrors[0]) ||
    (Array.isArray(error) && error[0]) ||
    error
  );
}

function getExceptionFromError(error: null | Error | ApolloError | GraphQLError): Error | null {
  const graphqlError: GraphQLError = extractError(error) as GraphQLError;
  const exception = graphqlError && graphqlError.extensions && graphqlError.extensions.exception as Error;
  return exception || graphqlError || error;
}

export function getCodeFromError(error: null | Error | ApolloError | GraphQLError): null | string {
  const err = getExceptionFromError(error) as any; // I have to admit I don't understand - Error type doesn't have 'code' property - needs to be checked
  return (err && err['code']) || null;
}

export function getFieldError(code: string | null, fields?: Array<string>): Array<string> {
  if (code) {
    switch (code) {
      case UserNamePasswordIncorrect:
        return ['password', 'user.password-incorrect'];
      case WeakPassword:
        return ['password', 'user.password-weak'];
      case EmailAlreadyExists:
        return ['email', 'user.email-exist'];
      case UsernameAlreadyExists:
        if (fields && fields[0] === 'username') {
          return ['username', 'user.username-exist'];
        }
        break;
      case UserNameNotAllowed:
        return ['username', 'user.username-invalid'];
      case VerifyInvalidInput:
        return ['userEmailId', 'user.email-invalid'];
    }
  }
  return ['', ''];
}
