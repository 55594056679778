import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const FarsightedEagle = ({ style, size = 45, className }: IconProps): React.ReactElement => {
	return (
		<svg version="1.1" id="farsighted_eagle" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" height={size} className={className} style={style}>
			<style type="text/css">
				{` 
	        .circle-farsighted-eagle{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-farsighted-eagle{fill:#FBBF2D;} 
        `}
			</style>
			<circle className="circle-farsighted-eagle" cx="16.3" cy="16.3" r="15.7" />
			<g>
				<g>
					<path className="path-farsighted-eagle" d="M27,10.9c-0.2-0.4-0.3-0.1-0.5,0.1c0-0.7,0-1.2,0-1.8c0,0-0.1,0-0.1,0c-0.1,0.3-0.2,0.7-0.3,1
			c0,0-0.1,0-0.1,0c0-0.5-0.1-1-0.1-1.5c-0.1,0-0.1,0-0.2,0c0,0.2,0,0.5-0.1,0.7c-0.1,0.7-0.3,1.3-0.9,1.7c-1.1,0.6-2.1,1.4-3.1,2.1
			c-0.9,0.5-1.5,1.3-2,2.2c-0.4,0.8-1.1,1.6-1.6,2.4c-0.2,0.3-0.4,0.2-0.6,0.1c-0.8-0.5-1.1-1.3-1.3-2.1c-0.2-0.7-0.5-1.4-0.8-2
			c-0.5-1-1.3-1.7-2.3-2.2c-1.3-0.7-2.6-1.5-3.9-2.3c0,0-0.1,0-0.2,0C9,9.5,9.2,9.8,9.4,10.3C8.5,9.8,7.8,9.2,7.2,8.5
			c-0.1,0.4,0,0.6,0.5,1.2c0.3,0.3,0.7,0.6,1,0.9c0,0,0,0.1-0.1,0.1c-0.2-0.1-0.4-0.1-0.5-0.2C7.6,10.1,7,9.6,6.4,9.2
			C6.3,9.1,6.1,9,6,8.9c0.2,1,1,1.5,1.6,2.1c0,0,0,0.1-0.1,0.1c-0.6-0.3-1.1-0.6-1.7-0.9c0.3,1,1.4,1,2.1,1.6
			c-0.5,0.2-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-1.1-0.5c0.1,0.6,0.5,0.8,0.9,1c0.4,0.2,0.8,0.4,1.3,0.6c0,0.1,0,0.1,0,0.2
			c-0.4-0.1-0.8-0.1-1.2-0.2c0.3,0.7,1,0.5,1.5,0.9C8.1,13.8,8,13.9,7.9,14c0.2,0.1,0.4,0.1,0.7,0.2c-0.2,0.1-0.3,0.2-0.6,0.3
			c0.5,0.1,0.9,0.2,1.3,0.2c0,0.2-0.1,0.3-0.1,0.4c0.3,0.1,0.7,0,0.5,0.5c0.2,0.2,0.7-0.1,0.5,0.5c0.2,0.1,0.4,0.1,0.6,0.2
			c-0.1,0.1-0.1,0.2-0.1,0.2c0.2,0.4,0.3,0.8,0.5,1.2c0,0-0.1,0-0.1,0.1c0.1,0.1,0.2,0.1,0.4,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
			c0.1,0.1,0.2,0.1,0.4,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0,0.3-0.1,0.5c0.1,0,0.1,0,0.2,0.1
			c0,0.1-0.1,0.3-0.1,0.4c0.1,0,0.3,0,0.4,0.1c0,0.2-0.1,0.3-0.1,0.4c0.3,0.1,0.3,0.1,0.3,0.7c0.1,0,0.3-0.1,0.4-0.1
			c0,0.1,0,0.3,0.1,0.4c0.2,0.1,0.7-0.3,0.6,0.4c0.5-0.4,0.9-0.7,1.2-0.9c0,0,0.1,0.1,0.1,0.1c-0.4,0.9-1,1.8-1.8,2.4
			c-0.3,0.2-0.6,0.4-0.9,0.6c-0.2,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.2,0.4,0.1,0.7c0.5,0.4,0.9,0.5,1.5,0.6c0.4,0.1,0.9,0.2,1.3,0.1
			c0.4,0,0.7-0.2,1.1-0.3c0.3-0.1,0.7-0.2,1-0.4c0.4-0.2,0.5-0.5,0.2-0.9c-0.2-0.3-0.3-0.6-0.5-0.9c0.1,0,0.1-0.1,0.2-0.1
			c0.1,0.1,0.3,0.2,0.4,0.3c0.4,0.4,0.8,0.9,1.1,1.3c0,0,0.1,0.1,0.1,0.1c0.3-0.1,0.4,0,0.4,0.2c0,0.2-0.2,0.3-0.3,0.5
			c-0.2,0.2-0.4,0.3-0.6,0.5c0,0,0.1,0.1,0.1,0.1c0.3-0.1,0.6-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.1c0.1,0,0.3,0.3,0.4,0
			c0,0,0.2,0,0.2,0c0.2-0.1,0.3-0.2,0.5-0.4c-0.4-0.2-0.7-0.3-1.1-0.5c0.7-0.3,1.3-0.6,1.9-0.8c0-0.1,0-0.3,0-0.5
			c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.2-0.5,0.1-0.6-0.3c-0.2-0.6-0.5-1.1-0.6-1.7c-0.1-0.4-0.2-0.9-0.1-1.4c0.1-0.6,0.6-0.8,1.2-0.7
			c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.3,0,0.5-0.1c0,0.1,0.1,0.2,0.3,0.4c0.1-0.2,0.2-0.4,0.1-0.5c-0.2-0.7-0.4-1.3-1.2-1.7
			c-1-0.4-1-0.6-0.4-1.5c0.7-1,1.7-1.5,2.8-2c0.4-0.2,0.9-0.3,1.3-0.5c0.1-0.1,0.5,0,0.3-0.4c0,0,0.1-0.1,0.2-0.2
			c0.1-0.1,0.3-0.2,0.5-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c0.3-0.2,0.5-0.4,0.8-0.6c0,0-0.1,0-0.1-0.1c0-0.1,0-0.3,0.1-0.4
			c0.2-0.5,0.4-0.9,0.6-1.4C27,11,27,10.9,27,10.9z"/>
				</g>
			</g>
		</svg>

	);
};

export default FarsightedEagle;
