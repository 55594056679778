import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Species, SpeciesType } from 'services/graphqltypes';

export const LIST_OF_SPECIES_FRAGMENT = gql`
fragment listOfSpeciesFragment on Species {
  bioregionGid
  category
  coverPictureUrl
  createdByUserId
  family
  id
  isPublished
  name
  scientificName
  slug
}`;

const LOAD_SPECIESES = gql`
${LIST_OF_SPECIES_FRAGMENT}
query useListOfSpecies(
  $category: SpeciesType
  $ecoregionGid: Int
  $isPublished: Boolean
  ) {
  specieses(
    filter: {
      category: { equalTo: $category }
      ecoregionSpecieses: { some: { ecoregionGid: { equalTo: $ecoregionGid } } }
      isPublished: { equalTo: $isPublished }
    }
    orderBy: SCIENTIFIC_NAME_ASC
  ) {
    nodes {
      ...listOfSpeciesFragment
    }
  }
}
`;

const useSpecieses = (
  /** Fetch species from a specific ecoregion. If not passed, fetches all species across all ecoregions */
  ecoregionGid?: number,
  /** Fetch species from a specific category. If not passed, fetches all categories of species. */
  category?: SpeciesType,
  /** Fetch species that is only published. If not passed, fetches all species. */
  isPublished?: boolean,
  /** If true, query is skipped (not executed) */
  skip?: boolean,
) => {
  const { loading, error, data, refetch } = useQuery(LOAD_SPECIESES, {
    variables: {
      ecoregionGid: ecoregionGid,
      category: category,
      isPublished: isPublished
    },
    skip: skip,
  });

  // array of specieses
  const specieses: Array<Species> = useMemo(() => {
    return data?.specieses?.nodes;
  }, [data]);

  return { loading, error, specieses, refetch };
};

export default useSpecieses;
