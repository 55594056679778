import React from 'react';
import IconProps from '../../LearningPathsIcons/IconProps';

const WiseOwl = ({ style, size = 45, className }: IconProps): React.ReactElement=>{
  return (
    <svg version="1.1" id="wise_owl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 32.7 32.7" xmlSpace="preserve" height={size} className={className}  style={style}>
      <style type="text/css">
        {`
            .circle-wise-owl{fill:#FFFFFF;stroke:#FBBF2D;stroke-miterlimit:10;}
            .path-wise-owl{fill:#FBBF2D;} 
        `}
      </style>
      <circle className="circle-wise-owl" cx="16.3" cy="16.3" r="15.7"/>
      <g>
        <g>
          <path className="path-wise-owl" d="M7.4,24.2c0-0.2,0-0.5,0-0.7c0.5-0.3,1-0.7,1.6-1c-0.1,0-0.1,0-0.2,0c0-0.2-0.1-0.4-0.1-0.6
			c0.1-0.3,0.3-0.5,0.4-0.8c0-0.1,0.1-0.2,0.2-0.2c0.5,0,1,0,1.5,0c0-0.1,0.1-0.2,0.1-0.3c-0.2,0-0.3,0-0.4-0.1
			c-0.3,0.1-0.7,0.2-1,0.4c0,0,0-0.1-0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0.4-0.8,0.8-1.6,1.3-2.4c0.1-0.2,0.3-0.4,0.6-0.2
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0.1-0.2,0.2-0.3,0.2-0.5c0.2,0,0.3,0,0.4,0c0,0.2-0.1,0.3-0.1,0.5
			c0,0,0,0,0.1,0c0.1-0.2,0.2-0.4,0.2-0.6c0.3,0,0.5,0,0.8,0c0,0,0,0.1,0,0.1c0.2,0,0.4,0,0.7,0c0-0.1,0-0.2,0-0.3
			c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1-1,0.5-1.8,0.7-2.7c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.3,1.1-0.5,1.6c-0.1,0.2-0.3,0.3-0.4,0.4
			c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.3,0.1-0.6,0.2-0.9c-0.4,0.4,0,1.2-0.9,1.5c0-0.3,0-0.5,0-0.7c0-0.1,0-0.2,0-0.2
			c0.1-0.2,0.2-0.4,0.2-0.5c-0.1,0-0.1,0-0.2,0c0-0.2,0-0.5-0.1-0.8c-0.5,0.4-1,0.7-1.5,1.1c0.2-0.9,0.8-1.3,1.4-1.7
			c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.7-0.4,0.9-0.7c0.6-0.9,1.5-1.4,2.3-2
			c0.6-0.4,1.2-0.9,1.8-1.3c0.1-0.1,0.1-0.2,0.2-0.4c0.2-0.3,0.3-0.6,0.5-1c0-0.2,0-0.5,0-0.8c0-0.2,0-0.6-0.4-0.6
			c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0.4-0.1,0.7-0.1,1.1-0.2c0.3,0,0.5,0,0.8-0.1C19.4,7,20,6.9,20.6,6.9
			c0.5,0.1,0.9-0.1,1.3-0.3c0.2,0,0.4,0,0.7,0c0.1,0.1,0.2,0.2,0.4,0.4c-0.1,0-0.2,0.1-0.5,0.2c0.4,0,0.6,0,0.9,0.1
			c-0.1,0.2-0.2,0.3-0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.2c0,0.3-0.1,0.4-0.1,0.7c0,0-0.1,0-0.3,0.1c0.1,0.4,0.3,0.8,0.3,1.1
			c0.1,1.7-0.4,3.4-1.1,5c-0.2,0.4-0.4,0.8-0.5,1.2c-0.1,0.2-0.1,0.5-0.3,0.7c-0.5,1-1.1,1.9-2,2.6c-0.3,0.2-0.6,0.6-0.8,1
			c-0.2,0.3-0.4,0.8-0.2,1c0.3,0.3,0.5,0.7,0.9,0.8c0.1,0,0.3,0.2,0.3,0.3c0.1,0.3,0.3,0.3,0.5,0.2c0.7-0.1,1.2,0.2,1.5,0.9
			c-0.2,0-0.3-0.1-0.5-0.1c0,0,0,0.1,0,0.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.5-0.1-0.8-0.2c-0.7-0.2-1-0.1-1.3,0.5
			c-0.1-0.2-0.2-0.3-0.2-0.5c-0.3,0.1-0.7,0.1-1,0.2c0,0.1,0,0.3,0,0.5c0,0-0.1,0-0.1,0c-0.2-0.4-0.3-0.8-0.1-1.3
			c-0.9-0.2-1.4-0.6-1.4-0.9c-0.2,0-0.3,0-0.4,0.1c-0.5,0-0.9,0.4-0.9,0.9c-0.1,0.6-0.3,1.2-0.4,1.7c0,0-0.1-0.1-0.1-0.1
			c-0.2,0-0.4-0.1-0.7-0.1c0-0.1,0-0.3-0.1-0.4c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.3,0-0.4,0-0.6c-0.1,0-0.2,0-0.3,0.1c0-0.2,0-0.4,0-0.4
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0.7-0.4,1.5-0.6,2.3c-0.1,0.5-0.3,0.6-0.7,0.6c-1-0.1-2-0.3-2.6-1.3C7.6,24.5,7.5,24.4,7.4,24.2z
			 M20.7,9.1c0.2-0.3,0.4-0.4,0.4-0.6c0-0.1-0.2-0.2-0.3-0.4c-0.1,0.1-0.3,0.3-0.4,0.4C20.3,8.7,20.5,8.9,20.7,9.1z M19.4,9.2
			c-0.1,0-0.2,0-0.3,0c0.1,0.4,0.1,0.7,0.2,1.1c0.1,0,0.1,0,0.2,0C19.4,9.9,19.4,9.6,19.4,9.2z M21.7,9.6c-0.4,0.3-0.7,0.6-1.1,0.9
			C21,10.2,21.8,10.3,21.7,9.6z"/>
        </g>
        <g>
          <path className="path-wise-owl" d="M9.7,20.4c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1-0.1-0.1-0.1c0.5-1,0.9-2.1,1.8-3c0,0.2,0,0.4,0,0.5
			C10.6,18.8,10.2,19.6,9.7,20.4z"/>
        </g>
        <g>
          <path className="path-wise-owl" d="M11.9,16.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c0.2-0.2,0.3-0.3,0.5-0.5c0.1,0,0.1,0,0.2,0.1C12,16.7,11.9,16.8,11.9,16.9z"/>
        </g>
        <g>
          <path className="path-wise-owl" d="M10.9,16.9c-0.2,0.2-0.4,0.4-0.5,0.5c0-0.7,0.9-1.5,1.6-1.7c-0.3,0.3-0.5,0.5-0.7,0.7
			C11.1,16.6,11,16.7,10.9,16.9z"/>
        </g>
        <g>
          <path className="path-wise-owl" d="M10.9,16.9c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0,0.2,0,0.3,0,0.5c-0.2,0.2-0.3,0.3-0.5,0.5
			c-0.1,0-0.3,0.1-0.5,0.2C10.8,17.1,10.8,17,10.9,16.9z"/>
        </g>
      </g>
    </svg>
  );
};

export default WiseOwl;
