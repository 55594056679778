import {
  defaultAppLocale,
  defaultDirection,
  localeOptions,
} from '../constants/defaultValues';

 
export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
 
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

 
export const getCurrentLanguage = () => {
  let language = defaultAppLocale;
  let browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
  browserLanguage =
    // en-US, en-GB, en-AU, ... will all end up with browserLanguage = en
    (browserLanguage.includes('en') && 'en') ||
    (browserLanguage.includes('zh') && 'zh-CN') ||
    (browserLanguage.includes('de') && 'de') ||
    defaultAppLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0
        ? localStorage.getItem('currentLanguage')
        : browserLanguage;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentLanguage -> error', error);
  }
  return language;
};
 
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentLanguage -> error', error);
  }
};

export const updateMultilingualJson = (currentJson, newString, currentLanguage) => {

  const newMultilingualJson = { ...currentJson };
  newMultilingualJson[currentLanguage] = newString;

  // add defaultLocale key in case it does not exist yet
  // replace it with English in case user is adding English
  if (!newMultilingualJson['defaultLocale'] || currentLanguage === 'en') {
    newMultilingualJson['defaultLocale'] = currentLanguage;
  }

  return newMultilingualJson;
};

export const getPictureLocaleUrl = (pictureurl) => {
  let url = '';
  const currentLang = getCurrentLanguage();
  try {
    url = pictureurl[currentLang]
      ? pictureurl[currentLang]
      : pictureurl[pictureurl['defaultLocale']];
  } catch {
    console.log('Cannot get picture url for current lang');
  }
  return url;
};

export const getSameDay = (startTime, endTime) => {
  startTime = new Date(startTime);
  endTime = new Date(endTime);
  return (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth() &&
    startTime.getDate() === endTime.getDate()
  );
};

export const getHours = (startTime, endTime) => {
  startTime = new Date(startTime);
  endTime = new Date(endTime);
  const duration = ((endTime - startTime) / 3600000);
  return (Math.round(duration) !== duration)
    ? duration.toFixed(2)
    : duration;
};

 
