import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import PrivateRoute from './PrivateRoute';
import routerConfig from './routerConfig';
import ErrorFallback from './components/common/ErrorFallback';
import * as Sentry from '@sentry/react';

const SentryRoute = Sentry.withSentryRouting(Route);
const SentryPrivateRoute = Sentry.withSentryRouting(PrivateRoute);

const errorHandler = (error, info) => {
  console.error(error);
  console.log(info);
};
export default function RouterMap() {
  return (
    <Router>
      <Sentry.ErrorBoundary fallback={ErrorFallback} onError={errorHandler} onReset={() => { }}>
        <Switch>
          {routerConfig.map((route, i) =>
            route.private ? (
              <SentryPrivateRoute
                key={i}
                path={route.path}
                exact
                layout={route.layout}
                component={route.component}
                redirect={route.redirect}
              />
            ) : (
              <SentryRoute
                key={i}
                path={route.path}
                exact
                render={(props) =>
                  route.layout ? (
                    <route.layout {...props}>
                      <route.component {...Object.assign({}, props)} />
                    </route.layout>
                  ) : (
                    <route.component {...Object.assign({}, props)} />
                  )
                }
              />
            )
          )}
        </Switch>
      </Sentry.ErrorBoundary>
    </Router>
  );
}
