import React from 'react';
import IconProps from '../LearningPathsIcons/IconProps';

const ClimateActionIcon = ({ style, size = 100, className }: IconProps): React.ReactElement<SVGSVGElement>=>{
  return (
    <svg viewBox="0 0 75 75" version="1.1" id="climateAction" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      xmlSpace="preserve" width={size} height={size} className={className} style={style}>
      <style type="text/css">
        {`
           .climate-action{fill - rule:evenodd;clip-rule:evenodd;fill:#4A783F;}
           .st1{fill:#FFFFFF;}
        `}
      </style>
      <path id="rect8528-63-2-4-3-6-0-4-3-6-3" className="climate-action" d="M58.9,1.6c8.1,0,14.6,6.5,14.6,14.6v42.9c0,8.1-6.5,14.6-14.6,14.6H16.1
	c-8.1,0-14.6-6.5-14.6-14.6V13.8C1.5,7.1,7,1.6,13.7,1.6L58.9,1.6z"/>
      <g id="text6065-3-2-9-6-6-5-5">
        <path id="path2462" className="st1" d="M26.8,12.5c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		C24.1,13.3,24,13.1,24,13c0-0.1,0-0.3,0-0.5V9.4c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9V9.4c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.4-0.1
		c0-0.1,0.1-0.2,0.1-0.2s0-0.1,0-0.2v-0.3h0.9L26.8,12.5z"/>
        <path id="path2464" className="st1" d="M30,14h-2.6V8h0.9v5.2H30V14z"/>
        <path id="path2466" className="st1" d="M30.2,14V8h0.9V14H30.2z"/>
        <path id="path2468" className="st1" d="M34.8,14v-3.7L34,12.9h-0.4l-0.9-2.6V14h-0.9V8h0.8l1.2,3.3L34.9,8h0.8V14
		C35.7,14,34.8,14,34.8,14z"/>
        <path id="path2470" className="st1" d="M35.9,14L37.2,8H38l1.4,6.1h-0.9l-0.2-1.3h-1.1l-0.2,1.3L35.9,14L35.9,14z M37.2,11.9H38
		l-0.4-2.2L37.2,11.9z"/>
        <path id="path2472" className="st1" d="M40.1,14V8.8h-1V8H42v0.8h-1v5.3L40.1,14L40.1,14z"/>
        <path id="path2474" className="st1"
          d="M45.1,13.2V14h-2.6V8h2.6v0.8h-1.7v1.8h1.5v0.9h-1.5v1.8L45.1,13.2L45.1,13.2z"/>
        <path id="path2476" className="st1" d="M23.6,22.4l1.4-6.1h0.7l1.4,6.1h-0.9l-0.2-1.3h-1.1l-0.2,1.3H23.6z M24.9,20.3h0.8l-0.4-2.2
		L24.9,20.3z"/>
        <path id="path2478" className="st1" d="M30.3,20.9c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.2-0.3-0.2-0.5s0-0.3,0-0.5v-3.1c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.5-0.4
		c0.2-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v0.3h-0.9v-0.3c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v3.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2
		c0.2,0,0.3,0,0.4-0.1c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.2v-0.3h0.9L30.3,20.9z"/>
        <path id="path2480" className="st1"
          d="M31.7,22.4v-5.3h-1v-0.8h2.9v0.8h-1v5.3C32.6,22.4,31.7,22.4,31.7,22.4z"/>
        <path id="path2482" className="st1" d="M34.1,22.4v-6.1h0.9v6.1H34.1z"/>
        <path id="path2484" className="st1" d="M35.9,16.9c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1
		c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.3,0,0.5V21c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.2,0.5
		c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.4V18c0-0.2,0-0.3,0-0.5C35.7,17.2,35.8,17,35.9,16.9
		L35.9,16.9z M37.6,17.4c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2V21
		c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.2
		c0,0,0-0.1,0-0.2v-3.1c0-0.1,0-0.2,0-0.2C37.7,17.5,37.6,17.5,37.6,17.4L37.6,17.4z"/>
        <path id="path2486" className="st1" d="M41.5,22.4l-1.4-3.7v3.7h-0.9v-6.1h0.9l1.4,3.7v-3.7h0.9v6.1H41.5z"/>
      </g>
      <g id="text6075-0-6-9-2-5-9-1">
        <path id="path2489" className="st1" d="M8.6,22.5V10.1l-2.1,1.6V9.5L8.6,8h2v14.5H8.6z"/>
        <path id="path2491" className="st1" d="M18.5,19.2v0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.5c-0.2,0.7-0.6,1.3-1.2,1.7
		c-0.5,0.4-1.2,0.6-1.9,0.6c-0.6,0-1.1-0.2-1.7-0.5c-0.5-0.3-0.9-0.7-1-1.1c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4
		c0-0.1,0-0.2,0-0.4c0-0.1,0-0.3,0-0.6v-0.8h2.1v1.2c0,0.2,0.1,0.4,0.2,0.6s0.4,0.4,0.8,0.4c0.4,0,0.6-0.1,0.8-0.4s0.2-0.6,0.2-1
		v-2.2c0-0.3,0-0.5-0.2-0.6C16.1,16.1,16,16,15.9,16c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0h-0.4V14h0.4c0.9,0,1.3-0.4,1.3-1.2
		v-1.7c0-0.2,0-0.3,0-0.4c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.3-0.2s-0.2-0.1-0.4-0.1c-0.3,0-0.6,0.1-0.8,0.3
		c-0.2,0.2-0.2,0.5-0.2,0.8v1.1h-2.1V11c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.3-0.9,2.2-0.9c0.5,0,1,0.1,1.5,0.4c0.5,0.2,0.8,0.6,1,0.9
		c0.2,0.3,0.4,0.7,0.4,1s0.1,0.6,0.1,1v1.7c0,0.9-0.4,1.7-1.2,2.1c0.5,0.3,0.8,0.7,1,1c0.2,0.3,0.3,0.7,0.3,1.3L18.5,19.2L18.5,19.2
		z"/>
      </g>
      <path id="path8205-2" className="st1" d="M38.9,33.7c-10.5-0.1-21,4.7-27.2,13.7c11.9,17.5,40.4,19.1,52.9,0
	C58.5,38.2,48.7,33.8,38.9,33.7z M38.2,36.3c0.5,0,1.1,0,1.4,0.1c0.2,0,0.6,0.1,0.9,0.2c0.6,0.1,1.5,0.3,1.8,0.5
	c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0.1c0,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.2,0.2
	c0.1,0,0.3,0.1,0.4,0.2c0,0,0.3,0.3,0.6,0.5c0.5,0.4,1.8,1.6,1.8,1.7c0,0,0.2,0.3,0.5,0.7c0.2,0.3,0.5,0.7,0.5,0.7s0,0.1,0.1,0.2
	c0.4,0.4,1.1,2.4,1.1,3c0,0.7-0.6,0.9-1,0.4c-0.6-0.7-1.2,0-0.7,0.9c0.1,0.1,0.1,0.2,0,0.3c-0.1,0.2-0.9,0.6-1.1,0.6s-0.7,0.5-0.7,1
	c-0.1,0.4,0,1.1,0.3,1.3c0.1,0.1,0.2,0.6,0.1,0.6c-0.1,0-0.6-0.4-1-0.7c-0.3-0.2-0.4-0.5-0.9-1.5c0-0.1-0.1-0.4-0.2-0.5
	c-0.1-0.4-0.5-0.8-0.9-0.8c-0.6,0-0.7,0.1-0.7,0.8c0,0.4,0,0.6-0.2,0.8c-0.2,0.3-0.2,0.3-0.5,0.1c-0.5-0.4-0.6-0.5-0.7-0.5
	c0,0-0.2,0-0.2-0.1c0-0.1-0.2-0.1-0.4-0.1c-0.5,0-1.1-0.4-1.4-0.9c-0.1-0.2-0.2-0.5-0.2-0.5c0-0.1-0.9-0.7-1-0.7
	c0,0-0.3-0.1-0.5-0.3c-0.6-0.4-0.8-0.3-0.8,0c0,0.2,0.1,0.4,0.8,1c0.8,0.8,1,1.2,0.7,1.5c-0.1,0.2-0.7,0.5-0.9,0.5
	c0,0-0.4-0.3-0.8-0.7c-0.5-0.6-0.8-0.7-0.9-0.7c-0.1,0-0.2,0.1-0.2,0.3c0,0.1,0,0.3,0,0.4s0.1,0.2,0.1,0.3c0,0.3,0.8,1.4,1.1,1.4
	c0,0,0.2,0.1,0.3,0.2c0.3,0.3,0.2,0.6-0.3,1.1c-0.4,0.4-0.4,0.4-0.5,1.2c0,0.4-0.1,0.8-0.1,0.9c0,0-0.1,0.2-0.1,0.4
	c0,0.1-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.4-0.3,0.6c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.4,0.6-0.6,0.6
	c0,0-0.1,0-0.1,0.1c0,0-0.2,0.1-0.5,0.1c-0.4,0-0.5,0-0.6-0.2l0,0c-0.1-0.1-0.1-0.4-0.1-0.9c0-0.5,0-0.7-0.2-0.9
	c-0.1-0.1-0.2-0.4-0.3-0.5c0-0.1,0-0.3-0.1-0.5c0-0.1-0.1-0.3-0.4-0.4c-0.2-0.1-0.3-0.2-0.3-0.2s-0.2-0.3-0.4-0.5
	C30,51,30,51,29.5,51.1c-0.2,0-0.5,0.1-0.6,0.2c0,0-0.3,0.1-0.5,0.1c-0.3,0-0.3,0-0.5-0.3c-0.1-0.2-0.2-0.4-0.2-0.5s0-0.3-0.1-0.5
	c-0.2-0.4-0.3-1.6-0.4-2.7c0-0.9,0-0.9,0.2-1.4c0.1-0.2,0.3-0.5,0.3-0.5c0-0.1,0.7-0.3,0.8-0.3c0,0,0.3,0,0.6,0.1
	c0.3,0,0.7,0.1,0.8,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.1s0.2,0,0.2,0.1c0,0,0.2,0.1,0.4,0.1
	c0.6,0.1,1.5-0.4,1.5-0.9c0-0.4-0.3-0.6-0.8-0.6c-0.2,0-0.4,0-0.5-0.1c0-0.1-0.3-0.1-0.5-0.1s-0.4-0.1-0.5-0.2c-0.3-0.2-1-0.3-1.3,0
	c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.5,0.1c-0.1,0.1-0.5,0.1-0.7,0.2l-0.5,0l0-0.3c0-0.2,0.1-0.3,0.5-0.3
	c0.9-0.1,1.3-0.3,1.4-0.6c0-0.2,0.1-0.3,0.6-0.7c0,0,0.3-0.1,0.6-0.2c0.5-0.1,0.5-0.1,1.2,0c0.8,0.2,1.1,0.1,1.5-0.3
	c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.3-0.3,0.1-0.5c-0.2-0.2-0.8,0-1.2,0.3c-0.2,0.3-0.3,0.3-0.7,0.3c-0.5,0-1.3-0.3-1.3-0.5
	c-0.1-0.4,0.6-0.8,1.8-0.9c0.5,0,1-0.1,1.1-0.1c0.1-0.1,1.4-0.2,1.4-0.1c0,0-0.2,0.2-0.4,0.3c-0.3,0.2-0.4,0.2-0.4,0.5
	c0,0.2,0,0.3,0.2,0.4c0.2,0,1-0.3,1.4-0.6c0.1-0.1,0.4-0.2,0.5-0.2c0.1,0,0.2-0.1,0.3-0.3c0.1-0.2,0-0.3-0.2-0.6
	c-0.5-0.4-0.7-0.7-0.7-1.1c0-0.6-0.1-0.7-1.6-0.7c-0.7,0-1.3,0-1.1,0c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3-0.1
	c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.5-0.1c0.2-0.1,0.5-0.2,0.8-0.2s0.7-0.1,1-0.2C37.1,36.4,37.7,36.3,38.2,36.3z M30.5,39.5
	c0.3,0,0.3,0.4-0.1,0.7c-0.4,0.3-0.7,0.3-0.7,0.1C29.7,40.1,30.3,39.5,30.5,39.5z M29.6,41.4c0,0,0.1,0,0.1,0c0.2,0,0.1,0.1-0.5,0.7
	c-0.8,0.7-0.9,0.8-0.9,0.6c0,0,0.1-0.3,0.2-0.6C28.9,41.7,29.3,41.4,29.6,41.4z M48.8,46.3c0,0,0.1,0,0.2,0.1c0.2,0.2,0.2,2.6,0,2.6
	c-0.1,0-0.2,0-0.2-0.1c-0.4-0.7-0.5-1.5-0.3-1.8c0.1-0.1,0.1-0.3,0.1-0.5C48.7,46.3,48.7,46.2,48.8,46.3z M40.8,48.5
	c0.1,0,0.1,0,0.1,0s0,0-0.1,0.1c-0.1,0-0.1,0-0.1,0C40.6,48.6,40.7,48.5,40.8,48.5z M46.9,49.7C46.9,49.7,46.9,49.7,46.9,49.7
	c0.2,0,0.2,0.1,0.2,0.1c0,0.1-0.2,0.1-0.2,0C46.7,49.8,46.8,49.7,46.9,49.7z M36.7,50.4c0.1,0,0,0.4-0.1,0.7
	c-0.1,0.1-0.2,0.4-0.2,0.5c0,0.2-0.1,0.1-0.1-0.3s0-0.6,0.2-0.7C36.6,50.5,36.7,50.4,36.7,50.4z M48.1,50.4c0.1,0,0.2,0.1,0.3,0.4
	c0.1,0.3,0.1,0.4,0,0.7c-0.3,0.6-0.5,1.1-0.7,1.4c-0.1,0.1-0.2,0.4-0.3,0.5c0,0.1-0.1,0.2-0.1,0.2c0,0-0.2,0.2-0.3,0.4
	c-0.1,0.2-0.3,0.4-0.4,0.4c0,0-0.1,0-0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c-1,0-1.2,0-1.2-0.1c0-0.2-0.4-0.2-0.6,0
	c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.2,0-0.2-0.3c0-0.1,0-0.3-0.1-0.5c-0.1-0.1-0.1-0.4-0.1-0.7c0-0.6,0.2-1.1,0.7-1.5
	c0.2-0.2,1.5-0.6,1.7-0.6c0.3,0,1.7-0.5,1.9-0.7C47.9,50.4,48,50.4,48.1,50.4z M44.2,56c0.1,0,0.2,0,0.3,0.1c0,0.1,0,0.1,0,0.1
	s-0.1,0-0.2,0.1c-0.1,0-0.3,0.2-0.5,0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0-0.1,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.2c0,0.1-0.2,0.1-0.2,0.1
	s-0.2,0-0.3,0.1c-0.2,0.1-0.9,0.3-1.7,0.5c-0.7,0.1-3.5,0.2-3.8,0.1l0,0c-0.1,0-0.4-0.1-0.7-0.1c-0.6-0.1-0.7-0.1-0.7-0.5
	c0-0.3,0.5-0.9,0.8-0.9c0.1,0,0.2,0,0.3-0.1c0.4-0.3,2.5-0.1,3.4,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0.1
	c0.3,0.1,0.9,0.2,1.1,0.2c0.1,0,0.3-0.2,0.6-0.4C43,56.3,43.7,56,44.2,56z"/>
    </svg>
  );
};

export default ClimateActionIcon;
